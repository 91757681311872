import React, { useState, useEffect, useRef } from 'react';
import loader from '../../../../assets/img/loader.svg';
import FilterEmployees from './FilterEmployees';
import Api from '../../../../_helpers/Api';
import AddOrEditEmployee from './AddOrEditEmployee';
import EmployeesOptions from './EmployeesOptions';
import { useAuthDataContext } from '../../../../_providers/Auth';
import { useMessagesContext } from '../../../../_providers/Messages';
import { trans } from '../../../../_providers/Translation';
import NoDataFound from '../../../partials/NoDataFound';
import Error from '../../../modals/messages/Error';
import Question from '../../../modals/messages/Question';
import EmployeeFreeDays from './EmployeeFreeDays';

let timeout;

function Employees(props) {

    const auth = useAuthDataContext();
    const messages = useMessagesContext();

    const partnerId = props.id;

    const modalAddOrEditRef = useRef(null);
    const errorModalRef = useRef(null);
    const deleteModalRef = useRef(null);
    const freeDaysModalRef = useRef(null);

    const [employees, setEmployees] = useState({
        loading: true,
        data: [],
        update: false,
        // search
        name: '',
        roleId: ''
    });

    const [roles, setRoles] = useState([]);

    useEffect(() => {
        loadEmployees();
    }, [employees.update]);

    useEffect(() => {
        loadRoles();
    }, []);

    const loadEmployees = () => {

        setEmployees(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'partners/employees/all?partner_id=' + partnerId
            + '&name=' + employees.name
            + '&role_id=' + employees.roleId;

        Api.get(url)
            .then(res => {
                setEmployees(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setEmployees(prev => ({
                    ...prev,
                    loading: false
                }));
            });

    }

    const loadRoles = () => {
        Api.get('partners/employees/roles')
            .then(res => {
                setRoles(res.data);
            });
    }

    const handleAdd = () => {

        if (props.partner) {
            if (props.partner.active_employees_count >= props.partner.max_employees) {
                return errorModalRef.current.open();
            }
        }

        modalAddOrEditRef.current.add();
    }

    const handleCreate = partner => {

        // let data = partners.data.concat(partner);

        // setPartners(prev => ({
        //     ...prev,
        //     data: data
        // }));

        setEmployees(prev => ({
            ...prev,
            update: Math.random().toString()
        }));

        if (typeof props.handleRefresh === 'function') {
            props.handleRefresh();
        }
    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id);
    }

    const handleUpdate = employee => {

        let data = employees.data.map(i => {
            if (i.id == employee.id) i = employee;
            return i;
        });

        setEmployees(prev => ({
            ...prev,
            data: data
        }));

        if (typeof props.handleRefresh === 'function') {
            props.handleRefresh();
        }
    }

    // delete
    const handleDelete = id => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleDestroy = id => {
        Api.post('partners/employees/remove', {
            id: id
        }).then(res => {
            if (res.data.success) {
                // let data = employees.data.filter(i => {
                //     return i.id !== id;
                // });
                // setEmployees(prev => ({
                //     ...prev,
                //     data: data
                // }));

                if (typeof props.handleRefresh === 'function') {
                    props.handleRefresh();
                }
            }
        });
    }

    const handleOpenChat = id => {

        let user = employees.data.filter(i => {
            return i.id === id;
        })[0];

        messages.openChat(user.id, user.type);
    }

    const handleShowFreeDays = id => {
        freeDaysModalRef.current.show(id);
    }

    const handleSearch = (key, val) => {

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setEmployees(prev => ({
                ...prev,
                [key]: val,
                update: Math.random().toString()
            }));
        }, 300);

    }

    const handleRefresh = () => {
        setEmployees(prev => ({
            ...prev,
            update: Math.random().toString()
        }));
    }

    return (

        <>

            <Error
                ref={errorModalRef}
                mainMessage={trans('messages.maxEmployeesReached')}
            />

            <Question
                ref={deleteModalRef}
                mainMessage={trans('headings.confirmDelete')}
            />

            <AddOrEditEmployee
                ref={modalAddOrEditRef}
                partnerId={partnerId}
                roles={roles}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <EmployeeFreeDays
                ref={freeDaysModalRef}
            />

            <div className="top-part">
                <div className="left">
                    <FilterEmployees
                        roles={roles}
                        handleSearch={handleSearch}
                    />
                </div>
                <div className="right">
                    {(auth.isManager()) &&
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.add')}
                        </button>
                    }
                </div>
            </div>

            {employees.loading
                ?
                <img src={loader} alt="loading" />
                :
                employees.data.length === 0
                    ?
                    <NoDataFound />
                    :
                    <>
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{trans('labels.picture')}</th>
                                        <th>{trans('labels.name')}</th>
                                        <th>{trans('labels.lastname')}</th>
                                        <th>{trans('labels.role')}</th>
                                        {!auth.isAdmin() &&
                                            <>
                                                <th>{trans('labels.email')}</th>
                                                <th>{trans('labels.phone')}</th>
                                                <th>{trans('labels.address')}</th>
                                                <th>{trans('labels.active')}</th>
                                                <th>{trans('labels.startDate')}</th>
                                                <th>{trans('labels.leaveDate')}</th>
                                            </>
                                        }
                                        <th className="options">{trans('labels.options')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employees.data.map(e =>
                                        <tr key={'e' + e.id}>
                                            <td><img src={e.avatar} alt='' width={40} height={40} style={{ objectFit: 'cover' }} /></td>
                                            <td>{e.name}</td>
                                            <td>{e.lastname}</td>
                                            <td>{e.role ? e.role.translation.name : ''}</td>
                                            {!auth.isAdmin() &&
                                                <>
                                                    <td>{e.email}</td>
                                                    <td>{e.phone_formatted}</td>
                                                    <td>{e.address}</td>
                                                    <td>{e.active ? trans('labels.yes') : trans('labels.no')}</td>
                                                    <td>{e.start_date}</td>
                                                    <td>{e.leave_date}</td>
                                                </>
                                            }
                                            <td className="options">
                                                <EmployeesOptions
                                                    id={e.id}
                                                    handleEdit={handleEdit}
                                                    handleOpenChat={handleOpenChat}
                                                    handleDelete={handleDelete}
                                                    handleShowFreeDays={handleShowFreeDays}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
            }
        </>

    )
}

export default Employees;