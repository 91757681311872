import React, { createContext, useState, useMemo, useContext, useEffect } from "react";
import Api from '../_helpers/Api';

export const AuthDataContext = createContext({});

const initialAuthData = {
    isLogged: false,
    data: {},
    booted: false,
};

let interval;

const AuthDataProvider = props => {
    const [authData, setAuthData] = useState(initialAuthData);
    const [neccesarySettings, setNeccesarySettings] = useState([]);
    const [booted, setBooted] = useState(false);

    const getAuthData = () => {
        let currentAuthData = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : initialAuthData;

        setAuthData(currentAuthData);
        setBooted(true);

        // if (currentAuthData.isLogged) {
        //     refresh();
        // }

    }

    const loadData = async (retry = 0) => {
        return await Api.get('profile')
            .then(response => {

                let state = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : initialAuthData;
                let profile = response.data;

                if (profile.is_employee) {
                    if (!profile.active || !profile.partner.active) {
                        return logout();
                    }
                }

                if (state && state.data && state.data.user) {
                    state.data.user = profile;
                }

                setAuthData(state);

                localStorage.setItem('auth', JSON.stringify(state));

                return true;

            }).catch(err => {

                if (retry > 2) {
                    return logout();
                } else {
                    return loadData(retry + 1);
                }

            });
    }

    const refresh = async () => {
        loadData();
    }

    const login = (newAuthData) => {

        let data = Object.assign({}, newAuthData);

        let expiresIn = data.expires_in;

        if (expiresIn > 0) {
            let expiresAt = new Date(new Date().getTime() + (expiresIn * 1000));
            let expiresAtTimestamp = expiresAt.getTime();

            data.expires_at = expiresAt;
            data.expires_at_timestamp = expiresAtTimestamp;
        }

        let state = {
            isLogged: true,
            data: data
        }

        localStorage.setItem('auth', JSON.stringify(state));

        setAuthData(state);
    }

    const refreshToken = () => {
        Api.post('refresh')
            .then(response => {
                login(response.data);
            })
            .catch(error => {
                // logout();
            });
    }

    const logout = async (redirect = true) => {
        return await Api.post('logout')
            .finally(() => {
                // setAuthData(initialAuthData);
                localStorage.clear();

                if (redirect) {
                    window.location = '/';
                }
            });
    }

    const setSettings = data => {
        if (authData.data && authData.data.user) {
            let newData = Object.assign({}, authData.data);

            newData.user.settings = data;

            login(newData);
        }
    }

    const loadNeccesarySettings = async (bool = true) => {
        return await Api.get('partners/settings/neccesary')
            .then(res => {
                setNeccesarySettings(res.data);

                if (bool) {
                    return true;
                }

                return res.data;
            });
    }

    const user = () => {
        if (authData.data && authData.data.user) {
            return authData.data.user;
        }
        return null;
    }

    const id = () => {
        if (user()) {
            return user().id;
        }
        return null;
    }

    const type = () => {
        if (user()) {
            return user().type;
        }
        return null;
    }

    const token = () => {
        if (authData.data) {
            return authData.data.access_token;
        }
        return null;
    }

    const partner = () => {
        if (authData.data && authData.data.user && authData.data.user.partner) {
            return authData.data.user.partner;
        }
        return null;
    }

    const partnerId = () => {
        if (authData.data && authData.data.user) {
            return authData.data.user.partner_id;
        }
        return null;
    }

    const setting = key => {
        if (user() && user().settings) {
            return user().settings[key] || null;
        }

        return null;
    }

    const lang = () => {
        if (setting('language')) {
            return setting('language').code;
        }

        return null;
    }

    const langId = () => {
        if (setting('language')) {
            return setting('language').id;
        }

        return null;
    }

    const phoneCode = () => {
        if (setting('language')) {
            return setting('language').phone_code;
        }

        return null;
    }

    const module = key => {
        if (user() && user().modules && Array.isArray(user().modules)) {
            return user().modules.includes(key);
        }

        return false;
    }

    const access = key => {

        if (isSuperAdmin()) {
            return true;
        }

        if (user() && user().access && typeof user().access === 'object') {
            return user().access[key] || false;
        }

        return false;
    }

    const permission = key => {
        return module(key) && access(key);
    }

    const isAdmin = () => {
        if (user()) {
            return Boolean(user().is_administrator);
        }
        return false;
    }

    const isSuperAdmin = () => {
        if (user()) {
            return Boolean(user().is_administrator && user().superadmin);
        }
        return false;
    }

    const isEmployee = () => {
        if (user()) {
            return Boolean(user().is_employee);
        }
        return false;
    }

    const isManager = () => {
        if (user() && user().role) {
            return user().role.name === 'manager';
        }
        return false;
    }

    const isOperator = () => {
        if (user() && user().role) {
            return user().role.name === 'operator';
        }
        return false;
    }

    const onStorageUpdate = e => {
        const { key, newValue } = e;

        if (key === null) {
            logout();
        }

        if (key === 'auth') {
            // console.log(JSON.parse(newValue))

            setAuthData(JSON.parse(newValue));

            // window.location.reload();
        }
    }

    useMemo(() => {
        getAuthData();
    }, []);

    useMemo(() => {

        clearInterval(interval);

        if (authData.isLogged) {
            let expiringTime = authData?.data?.expires_at_timestamp;

            if (expiringTime) {
                interval = setInterval(() => {
                    let currentTime = new Date().getTime();

                    let ttl = (expiringTime - currentTime) / 1000;

                    if (ttl <= 300) {
                        // console.log('refreshToken')
                        refreshToken();
                    }

                }, 30000);
            }
        }

        return () => {
            clearInterval(interval);
        }
    }, [authData]);

    useEffect(() => {
        window.addEventListener("storage", onStorageUpdate);
        return () => {
            window.removeEventListener("storage", onStorageUpdate);
        };
    }, []);

    const auth = useMemo(() => ({
        ...authData,
        user,
        id,
        type,
        token,
        partner,
        partnerId,
        setting,
        setSettings,
        neccesarySettings,
        loadNeccesarySettings,
        lang,
        langId,
        phoneCode,
        access,
        module,
        permission,
        isAdmin,
        isSuperAdmin,
        isEmployee,
        isManager,
        isOperator,
        login,
        loadData,
        refresh,
        logout
    }), [authData, neccesarySettings]);

    if (booted) {
        return <AuthDataContext.Provider value={auth} {...props} />;
    }

    return null;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;