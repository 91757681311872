import React from 'react';

function Input(props) {

    return (
        <div className="custom-input">
            <input {...props} className={[props.error ? 'validate' : '', props.className].join(' ')} />
            {props.helperText &&
                <div className="helper-text">
                    {props.helperText}
                </div>
            }
        </div>
    )
}

export default Input;