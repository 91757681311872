import React, { useState, useRef } from 'react'
import Menu from '@material-ui/core/Menu'
import { useHistory } from 'react-router-dom'
import { trans } from '../../../../_providers/Translation';

//images
import menuIcon from '../../../../assets/img/icons/table-menu.png'
import editIcon from '../../../../assets/img/icons/edit.png'

//modals
import Api from '../../../../_helpers/Api'

function Index(props) {

    const [table, setTable] = useState({
        options: null
    })

    const handleTableMenu = e => {

        setTable(table => {
            return {
                ...table,
                options: e.currentTarget
            }
        })
    }

    const handleCloseMenu = () => {
        setTable(table => {
            return {
                ...table,
                options: null
            }
        })
    }

    const handleContinue = () => {
        handleCloseMenu();

        Api.post('reservations/continue', {
            id: props.id
        }).then(res => {
            props.handleRefresh(res.data.id);
        })
    }

    const handleReplicate = () => {
        handleCloseMenu();

        Api.post('reservations/replicate', {
            id: props.id
        }).then(res => {
            props.handleRefresh(res.data.id);
        })
    }

    const handleReclamation = () => {
        handleCloseMenu();

        Api.post('reservations/reclamation', {
            id: props.id
        }).then(res => {
            props.handleRefresh(res.data.id);
        })
    }

    return (
        <>
            <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />

            <Menu
                anchorEl={table.options}
                keepMounted
                open={Boolean(table.options)}
                onClose={handleCloseMenu}
            >

                {props.data.can_make_continuation &&
                    <div className="menu-option" onClick={handleContinue}>
                        <img src={editIcon} alt="edit" />
                        {trans('buttons.continuation')}
                    </div>
                }

                <div className="menu-option" onClick={handleReplicate}>
                    <img src={editIcon} alt="edit" />
                    {trans('buttons.dublicate')}
                </div>

                {props.data.can_make_reclamation &&
                    <div className="menu-option" onClick={handleReclamation}>
                        <img src={editIcon} alt="edit" />
                        {trans('buttons.reclamation')}
                    </div>
                }

            </Menu>
        </>
    )
}

export default Index;
