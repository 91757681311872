import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from 'react-router-dom';
import { fromInfo, trans } from '../../../../_providers/Translation';
import Skeleton from 'react-loading-skeleton';
import Help from '../../../partials/Help';

function FilterTabs(props) {
    const history = useHistory();

    const [tabs, setTabs] = useState({
        ripple: true
    })

    const handleTabs = (e, tab) => {

        if (tab === 'manuals') {

            let url = '/manuals'
                + '?category_id=' + (props.categoryId || '')
                // + '&brand_id=' + (props.brandId || '')
                + '&model_id=' + (props.modelId || '')
                + '&model=' + (props.model || '')
                + '&reservation_id=' + props.reservationId
                + '&reference_code=' + props.referenceCode

            history.push(url)
        } else {
            props.handleTab(tab);
        }

    };

    return (

        <Tabs
            value={props.tab}
            onChange={handleTabs}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs"
        >
            <Tab value="info" label={<div>{trans('tabs.workInfo')} {props.tab === 'info' && <Help modalStyle={{ maxWidth: '800px' }}>{fromInfo('reservations.task')}</Help>}</div>} disableRipple={tabs.ripple} />
            <Tab value="parts" label={<div>{trans('tabs.parts')} ({props.tabsCount.partsCount}) {props.tab === 'parts' && <Help>{fromInfo('reservations.parts')}</Help>}</div>} disableRipple={tabs.ripple} disabled={!props.editMode} />
            <Tab value="images" label={<div>{trans('tabs.images')} ({props.tabsCount.imagesCount}) {props.tab === 'images' && <Help>{fromInfo('reservations.images')}</Help>}</div>} disableRipple={tabs.ripple} disabled={!props.editMode} />
            <Tab value="todo" label={<div>{trans('tabs.todo')} ({props.tabsCount.todosCount}) {props.tab === 'todo' && <Help>{fromInfo('reservations.todo')}</Help>}</div>} disableRipple={tabs.ripple} disabled={!props.editMode} />
            <Tab value="comments" label={<div>{trans('tabs.comments')} ({props.tabsCount.commentsCount}) {props.tab === 'comments' && <Help>{fromInfo('reservations.comments')}</Help>}</div>} disableRipple={tabs.ripple} disabled={!props.editMode} />
            <Tab value="manuals" label={<div>{trans('tabs.manuals')} ({props.tabsCount.manualsCount})</div>} disableRipple={tabs.ripple} disabled={!props.editMode} />
            <Tab value="invoices" label={<div>{trans('tabs.invoices')} ({props.tabsCount.invoicesCount}) {props.tab === 'invoices' && <Help>{fromInfo('reservations.invoices')}</Help>}</div>} disableRipple={tabs.ripple} disabled={!props.editMode} />
            <Tab value="history" label={<div>{trans('tabs.history')} {props.tab === 'history' && <Help>{fromInfo('reservations.history')}</Help>}</div>} disableRipple={tabs.ripple} disabled={!props.editMode} />
        </Tabs>

    )
}

export default FilterTabs;