import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import { useAuthDataContext } from '../../../../_providers/Auth';
import Dropdown from '../../../partials/Dropdown';
import { useLanguageContext } from '../../../../_providers/Language';
import MultiSelect from "react-multi-select-component";
import moment from 'moment';

function AddOrEdit(props, ref) {

    const auth = useAuthDataContext();
    const langs = useLanguageContext();

    const initialData = {
        country_id: 100,
        country: {
            name: 'България',
        },
        active: 1,
        settings: {},
        selectedWorkDays: [],
        max_employees: null,
    }

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: initialData,
        loading: false,
        checked: false,
        branches: [],
        timezones: [],
    });

    const [validations, setValidations] = useState(null);

    const licensesRef = useRef(null);
    const countriesRef = useRef(null);

    const weekDays = [
        {
            label: trans('dates.weekdaysObj.monday'),
            value: 1,
        },
        {
            label: trans('dates.weekdaysObj.tuesday'),
            value: 2,
        },
        {
            label: trans('dates.weekdaysObj.wednesday'),
            value: 3,
        },
        {
            label: trans('dates.weekdaysObj.thursday'),
            value: 4,
        },
        {
            label: trans('dates.weekdaysObj.friday'),
            value: 5,
        },
        {
            label: trans('dates.weekdaysObj.saturday'),
            value: 6,
        },
        {
            label: trans('dates.weekdaysObj.sunday'),
            value: 7,
        },
    ];

    useImperativeHandle(ref, () => ({
        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: initialData
            }));
            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                data: initialData,
                loading: false
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            Api.get(`partners/find?id=${popup.editId}`)
                .then(res => {

                    let data = res.data;

                    data.selectedWorkDays = [];

                    if (Array.isArray(data.work_days)) {
                        data.work_days.map(dayId => {
                            let day = weekDays.find(day => Number(day.value) === Number(dayId));

                            if (day) {
                                data.selectedWorkDays.push(day)
                            }
                        });
                    }

                    setPopup(prev => ({
                        ...prev,
                        data: data,
                        checked: data.website ? true : false,
                    }));

                });
        }

    }, [popup.edit]);

    useEffect(() => {
        Api.get('branches/all')
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    branches: res.data
                }))
            });
    }, []);

    useEffect(() => {
        Api.get('settings/timezones')
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    timezones: res.data
                }))
            })
    }, []);

    useEffect(() => {
        if (!popup.checked) {
            setPopup(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    website: null
                }
            }));
        }
    }, [popup.checked]);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            data: initialData,
            checked: false,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);

        if (licensesRef.current) {
            licensesRef.current.reset();
        }

        if (countriesRef.current) {
            countriesRef.current.reset();
        }
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'partners/edit';
        } else {
            url = 'partners/add';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    props.handleUpdate(res.data)
                } else {
                    props.handleCreate(res.data);
                }

                handleHide(e);

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleCheckboxChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: Number(!prev.data[name])
            }
        }));
    }

    const handleSettingsInputChange = e => {
        let name = e.target.name.split('[')[1].replace(']', '').trim();
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                settings: {
                    ...prev.data.settings,
                    [name]: val
                }
            }
        }));
    }

    const handleBranchChange = e => {

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                license_id: null,
                max_employees: null
            }
        }));

        handleInputChange(e);

        licensesRef.current.reset();
    }

    const handleLicenseChange = data => {
        if (data?.id && data.id != popup.data.license_id) {
            setPopup(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    max_employees: data?.max_employees || prev.data.max_employees,
                    license_id: data.id,
                    license: data,
                }
            }));
        }
    }

    const handleCountryChange = data => {
        if (data?.id && data.id != popup.data.country_id) {
            setPopup(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    country_id: data.id,
                    country: data,
                }
            }));
        }
    }

    const handleCheck = (e) => {
        setPopup(prev => ({
            ...prev,
            checked: !prev.checked,
        }));
    }

    const handleWorkDaysChange = data => {
        data = data.sort((a, b) => {
            return a.value - b.value;
        });

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                selectedWorkDays: data
            }
        }))
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form className={`${popup.modal ? 'show' : ''} popup-primary medium`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {popup.edit ? trans('headings.editCompanyInfo') : trans('headings.addCompanyInfo')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container x2">
                    <div className="col">
                        <label htmlFor="name">
                            {trans('labels.name')}
                        </label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            value={popup.data && popup.data.name ?
                                popup.data.name
                                :
                                ''
                            }
                            className={validations && validations.name ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="eik">
                            {trans('labels.eik')}
                        </label>
                        <input
                            id="eik"
                            type="text"
                            name="eik"
                            value={popup.data && popup.data.eik ?
                                popup.data.eik
                                :
                                ''
                            }
                            className={validations && validations.eik ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container x2">
                    <div className="col">
                        <label htmlFor="email">
                            {trans('labels.email')}
                        </label>
                        <input
                            id="email"
                            type="text"
                            name="email"
                            value={popup.data && popup.data.email ?
                                popup.data.email
                                :
                                ''
                            }
                            className={validations && validations.email ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="phone">
                            {trans('labels.phone')}
                        </label>
                        <input
                            id="phone"
                            type="text"
                            name="phone"
                            value={popup.data && popup.data.phone ?
                                popup.data.phone
                                :
                                ''
                            }
                            className={validations && validations.phone ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>

                </div>

                <br />

                <div className="popup-container x2">
                    <div className="col">
                        <label>
                            {trans('labels.country')}
                        </label>
                        <Dropdown
                            ref={countriesRef}
                            url="countries/all"
                            inputIdName="country_id"
                            inputValue={popup.data?.country?.translation?.name || popup.data?.country?.name || ''}
                            inputIdValue={popup.data && popup.data.country_id ? Number(popup.data.country_id) : ''}
                            renderInputText={data => {
                                return data?.translation?.name || data.name;
                            }}
                            renderText={data => {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}><img src={data.flag} alt="" style={{ height: 18, marginRight: '10px' }} /> {data?.translation?.name || data.name}</div>
                                )
                            }}
                            onChange={handleCountryChange}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="address">
                            {trans('labels.address')}
                        </label>
                        <input
                            id="address"
                            type="text"
                            name="address"
                            value={popup.data && popup.data.address ?
                                popup.data.address
                                :
                                ''
                            }
                            className={validations && validations.address ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container x2">
                    <div className="col">
                        <label htmlFor="lat">
                            {trans('labels.latitude')}
                        </label>
                        <input
                            id="lat"
                            type="text"
                            name="lat"
                            value={popup.data && popup.data.lat ?
                                popup.data.lat
                                :
                                ''
                            }
                            className={validations && validations.lat ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                        <a href="https://www.google.com/maps" target="_blank" className="link" style={{ marginTop: '3px' }}>
                            {trans('labels.takeIt')}
                        </a>
                    </div>
                    <div className="col">
                        <label htmlFor="lon">
                            {trans('labels.longitude')}
                        </label>
                        <input
                            id="lon"
                            type="text"
                            name="lon"
                            value={popup.data && popup.data.lon ?
                                popup.data.lon
                                :
                                ''
                            }
                            className={validations && validations.lon ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                        <a href="https://www.google.com/maps" target="_blank" className="link" style={{ marginTop: '3px' }}>
                            {trans('labels.takeIt')}
                        </a>
                    </div>
                </div>

                {!auth.isAdmin() &&
                    <>
                        <br />

                        <div className="popup-container">
                            <div className="col">
                                <label htmlFor="image">
                                    {trans('labels.workDays')}
                                </label>
                                <MultiSelect
                                    options={weekDays}
                                    value={popup.data.selectedWorkDays}
                                    onChange={handleWorkDaysChange}
                                    hasSelectAll={false}
                                    disableSearch={true}
                                    className="dropdown-top"
                                    overrideStrings={{
                                        "selectSomeItems": trans('labels.choose'),
                                        "allItemsAreSelected": trans('labels.allSelected'),
                                        "selectAll": trans('labels.selectAll'),
                                        "search": trans('labels.search')
                                    }}
                                />

                                {popup.data.selectedWorkDays.map(day =>
                                    <input key={'wd-' + day.value} type="hidden" name="work_days[]" value={day.value} />
                                )}
                            </div>
                        </div>
                    </>
                }

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label htmlFor="image">
                            {trans('labels.logo')}
                        </label>
                        <input type="file" name="image" className={validations && validations.image ? 'validate' : ''} />
                    </div>
                </div>

                {auth.isAdmin() &&
                    <>
                        <br />

                        <div className="popup-container x2">
                            <div className="col">
                                <label>
                                    {trans('labels.branch')}
                                </label>
                                <select
                                    name="branch_id"
                                    value={popup.data && popup.data.branch_id ? popup.data.branch_id : ''}
                                    onChange={handleBranchChange}
                                >
                                    <option value="" disabled>
                                        {trans('random.select')}
                                    </option>
                                    {popup.branches.map(c => {
                                        return <option
                                            key={'br-' + c.id}
                                            value={c.id}
                                        >
                                            {c.translation.name}
                                        </option>
                                    })}
                                </select>
                            </div>
                            <div className="col">
                                <label>
                                    {trans('labels.license')}
                                </label>
                                <Dropdown
                                    ref={licensesRef}
                                    url="branches/licenses"
                                    inputIdName="license_id"
                                    inputIdValue={popup.data && popup.data.license_id ? Number(popup.data.license_id) : ''}
                                    filterable={false}
                                    params={{
                                        id: popup.data ? popup.data.branch_id : ''
                                    }}
                                    requiredParams={['id']}
                                    renderInputText={data => {
                                        return data.translation && data.translation.name;
                                    }}
                                    renderText={data => {
                                        return (
                                            <table className="transparent" style={{ fontSize: '12px' }}>
                                                <thead>
                                                    <tr className="x2">
                                                        <td>
                                                            ID:
                                                        </td>
                                                        <td>
                                                            {data.id}
                                                        </td>
                                                    </tr>
                                                    <tr className="x2">
                                                        <td>
                                                            {trans('tables.name')}:
                                                        </td>
                                                        <td>
                                                            {data.translation && data.translation.name}
                                                        </td>
                                                    </tr>
                                                    <tr className="x2">
                                                        <td>
                                                            {trans('tables.maxEmployees')}:
                                                        </td>
                                                        <td>
                                                            {data.max_employees}
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>
                                        )
                                    }}
                                    onChange={handleLicenseChange}
                                />
                            </div>
                        </div>

                        <br />

                        <div className="popup-container x2">
                            <div className="col">
                                <label>
                                    {trans('labels.expiresAt')}
                                </label>
                                <input
                                    type="date"

                                    name="license_expires_at"
                                    value={popup.data && popup.data.license_expires_at ?
                                        moment(popup.data.license_expires_at).format('YYYY-MM-DD')
                                        :
                                        ''
                                    }
                                    className={validations && validations.license_expires_at ? 'validate' : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col">
                                <label>
                                    {trans('labels.maxEmployees')}
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    step={1}
                                    name="max_employees"
                                    value={popup.data && popup.data.max_employees ?
                                        popup.data.max_employees
                                        :
                                        ''
                                    }
                                    className={validations && validations.max_employees ? 'validate' : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <br />
                        <div className="popup-container x2">
                            <div className="col">
                                <label>
                                    {trans('labels.language')}
                                </label>

                                <select
                                    name="settings[lang_id]"
                                    value={popup.data && popup.data.settings && popup.data.settings.lang_id ? popup.data.settings.lang_id : ''}
                                    className={validations && validations.lang_id ? 'validate' : ''}
                                    onChange={handleSettingsInputChange}
                                >
                                    <option value=""></option>
                                    {langs.map(t =>
                                        <option key={'lang-' + t.id} value={t.id}>{t.name}</option>
                                    )}
                                </select>
                            </div>

                            <div className="col">
                                <label>
                                    {trans('labels.timeZone')}
                                </label>

                                <select
                                    name="settings[timezone]"
                                    value={popup.data && popup.data.settings && popup.data.settings.timezone ? popup.data.settings.timezone : ''}
                                    className={validations && validations.timezone ? 'validate' : ''}
                                    onChange={handleSettingsInputChange}
                                >
                                    <option value=""></option>
                                    {popup.timezones.map((t, i) =>
                                        <option key={'tz-' + i} value={t}>{t}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <br />

                        <div className="popup-container">
                            <div className='col'>
                                <label htmlFor="vcu">
                                    {trans('labels.voicecallUrl')}
                                </label>
                                <input
                                    id="vcu"
                                    type="text"
                                    name="call_center_url"
                                    onChange={handleInputChange}
                                    value={popup?.data?.call_center_url || ''}
                                    className={validations && validations.call_center_url ? 'validate' : ''}
                                />
                            </div>
                        </div>

                        <br />

                        <div className="popup-container">
                            <div className="col">
                                <div className="checkbox-primary">
                                    <label htmlFor="active">
                                        {trans('labels.active')}
                                        <input
                                            id="active"
                                            type="checkbox"
                                            name="active"
                                            value="1"
                                            checked={popup.data
                                                ? Number(popup.data.active) === 1 ? true : false
                                                : true
                                            }
                                            className={validations && validations.active ? 'validate' : ''}
                                            onChange={handleCheckboxChange}
                                        />
                                        <div className="checkmark"></div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div className="popup-container">
                            <div className="col">
                                <div className="checkbox-primary">
                                    <label htmlFor="superpartner">
                                        {trans('labels.superPartner')}
                                        <input
                                            id="superpartner"
                                            type="checkbox"
                                            name="superpartner"
                                            value="1"
                                            checked={popup.data && popup.data.superpartner
                                                ? true
                                                : false
                                            }
                                            className={validations && validations.superpartner ? 'validate' : ''}
                                            onChange={handleCheckboxChange}
                                        />
                                        <div className="checkmark"></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* <br />
                            <div className="popup-container">
                                <div className="col">
                                    <div className="checkbox-primary">
                                        <label htmlFor="invoice_with_vat">
                                            {trans('labels.invoiceWithVat')}
                                            <input
                                                id="invoice_with_vat"
                                                type="checkbox"
                                                name="invoice_with_vat"
                                                value="1"
                                                checked={popup.data && popup.data.invoice_with_vat
                                                    ? true
                                                    : false
                                                }
                                                className={validations && validations.invoice_with_vat ? 'validate' : ''}
                                                onChange={handleCheckboxChange}
                                            />
                                            <div className="checkmark"></div>
                                        </label>
                                    </div>
                                </div>
                            </div> */}

                        <br />

                        <div className='popup-container'>
                            <div className='col'>
                                <div className="checkbox-primary">
                                    <label htmlFor="checkboxweb">
                                        {trans('labels.site')}
                                        <input
                                            id="checkboxweb"
                                            type="checkbox"
                                            name="checkboxweb"
                                            onChange={handleCheck}
                                            value={1}
                                            checked={popup.checked}
                                        />
                                        <div className="checkmark"></div>
                                    </label>
                                </div>
                                <br />
                                {popup.checked &&
                                    <div className='row'>
                                        <div className='col'>
                                            <label htmlFor="web">
                                                {trans('labels.website')}
                                            </label>
                                            <input
                                                id='web'
                                                type="text"
                                                name="website"
                                                placeholder='https://www.example.com'
                                                onChange={handleInputChange}
                                                // value="1"
                                                value={popup.data && popup.data.website ?
                                                    popup.data.website
                                                    : ''}
                                                className={validations && validations.website ? 'validate' : ''}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);