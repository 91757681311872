import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import Api from '../../../_helpers/Api';
import loader from '../../../assets/img/loader.svg';
import { trans } from '../../../_providers/Translation'
import { useWindowSize } from '../../../_helpers/WindowSizeHook';
import Chart from "react-google-charts";
import Filter from './partials/FilterStats';

let timeout;

function Stats(props, ref) {

    const history = useHistory();
    const location = useLocation();
    const windowSize = useWindowSize();
    const searchParams = new URLSearchParams(location.search);

    const [state, setState] = useState({
        loading: true,
        data: {
            dates: [],
            employees: []
        },
        filter: {},
        setFilter: false,
        update: false,
        employees: [],
        summary: true,
        allowDetails: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                status: searchParams.get('status') || '',
                start: searchParams.get('start') || '',
                end: searchParams.get('end') || '',
                employee_id: searchParams.get('employee_id') || '',
                group: searchParams.get('group') || '',
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    useEffect(() => {
        Api.get('partners/employees/all')
            .then(res => {
                setState(table => ({
                    ...table,
                    employees: res.data
                }))
            })
    }, [])

    useEffect(() => {

        if (!state.data.dates) {
            return;
        }

        let label = state.data.group === 'month' ? trans('random.month') : trans('random.year');

        let chart;

        // обобщена 
        if (state.summary) {
            chart = [
                [label, trans('random.calls')],
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label, d.total
                ];

                chart.push(arr);
            });
        }
        // по служители
        else {
            let chartArr = [label];

            state.data.employees.map(c => {
                chartArr.push(c.name)
            });

            chart = [
                chartArr
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label,
                ];

                state.data.employees.map(c => {
                    arr.push(c.dates[d.date].total);
                });

                chart.push(arr);
            });

            // console.log(chart);

        }

        setState(prev => ({
            ...prev,
            chart: chart
        }))

    }, [state.data, state.summary])

    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'accounts/voicecalls';

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    // chart: chart,
                    filter: {
                        ...prev.filter,
                        group: res.data.group,
                        start: res.data.start,
                        end: res.data.end
                    },
                    summary: res.data.employees.length === 0 ? true : prev.summary,
                    allowDetails: res.data.employees.length === 0 ? false : true,
                    loading: false
                }));
            });
    }

    const handleSearch = (key, val, delay = 300) => {

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
                setFilter: new Date().getTime()
            }));
        }, delay);

    }

    const toggleSummaryData = val => {
        setState(prev => ({
            ...prev,
            summary: val
        }));
    }

    const getColumns = () => {
        let arr = [];

        state.data.methods.map((m, i) => {
            arr.push({
                type: 'NumberFormat',
                column: i + 1,
                options: {
                    // prefix: '$',
                    negativeParens: false,
                }
            })
        });

        return arr;
    }

    const showVoiceCalls = (row, col) => {

        let date = state.data.dates[row];
        let employee = state.data.employees[col - 1];

        if (state.summary) {
            if (date) {
                toList(null, date.start_date, date.end_date);
            }
        } else {
            if (date && employee) {
                toList(employee.id, date.start_date, date.end_date);
            }
        }
    }

    const toList = (employeeId, start, end) => {
        let url = '/voicecalls?tab=list';

        let params = {
            'employee_id': employeeId || '',
            'start': start || '',
            'end': end || '',
            'status': state.filter.status || ''
        };

        Object.entries(params).map(p => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + p[0] + '=' + p[1];
        });

        history.push(url);
    }

    const calcHeight = () => {
        let height = 50 + state.data.employees.length * 40;

        if (height < 350) {
            height = 350;
        }

        return height;
    }

    return (
        <>
            <Filter
                allowDetails={state.allowDetails}
                filter={state.filter}
                summary={state.summary}
                employees={state.employees}
                handleSearch={handleSearch}
                toggleSummaryData={toggleSummaryData}
            />

            <br />

            {state.loading
                ?
                <img className="loader" src={loader} />
                :
                <>
                    <div
                        className="chart"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#fff',
                            padding: '5px'
                        }}>
                        <Chart
                            width={window.innerWidth <= 1024 ? '900px' : '99%'}
                            // height={'350px'}
                            height={calcHeight() + 'px'}
                            chartType="Bar"
                            className="animated-chart-vertical animated-chart-start clickable-chart"
                            loader={'...'}
                            data={state.chart}
                            options={{
                                // Material design options
                                chart: {
                                    title: trans('headings.voiceCalls'),
                                    subtitle: state.data.currency,
                                },

                                legend: {
                                    position: state.summary ? 'none' : 'left'
                                },
                                allowHtml: true,
                                showRowNumber: true,
                            }}
                            // custom attributes
                            rootProps={{ 'data-testid': '2' }}
                            chartEvents={[
                                {
                                    eventName: "ready",
                                    callback: ({ chartWrapper, google }) => {
                                        const chartEl = chartWrapper.getChart().container;
                                        setTimeout(() => {
                                            chartEl.classList.remove('animated-chart-start')
                                        }, 100)
                                    },
                                },
                                {
                                    eventName: "select",
                                    callback: ({ chartWrapper, google }) => {
                                        const chartEl = chartWrapper.getChart();
                                        const selection = chartEl.getSelection()[0];

                                        console.log(selection);

                                        if (selection) {
                                            showVoiceCalls(selection.row, selection.column);
                                        }

                                    },
                                }
                            ]}
                        // formatters={getColumns()}
                        />
                    </div>

                    <br />
                    <div className="table-scroller">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {trans('tables.employee')}
                                    </th>
                                    {state.data.dates.map((d, i) =>
                                        <th key={'th-' + i} className="right">
                                            {d.label}
                                        </th>
                                    )}
                                    <th className="right">
                                        {trans('tables.total')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.data.employees.map(c =>
                                    <tr key={'c-' + c.id}>
                                        <td>
                                            {c.name} {c.lastname}
                                        </td>
                                        {state.data.dates.map((d, i) =>
                                            <td
                                                key={'td-' + i}
                                                className="right clickable"
                                                onClick={() => toList(c.id, d.start_date, d.end_date)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {c.dates[d.date].total}
                                                <br />
                                                {c.dates[d.date].duration_formatted}
                                            </td>
                                        )}
                                        <td
                                            className="right clickable"
                                            onClick={() => toList(c.id)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {c.total}
                                            <br />
                                            {c.duration_formatted}
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <td>
                                        {trans('tables.total')}
                                    </td>
                                    {state.data.dates.map((d, i) =>
                                        <td
                                            key={'totals-' + i}
                                            className="right clickable"
                                            onClick={() => toList(null, d.start_date, d.end_date)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {d.total}
                                            <br />
                                            {d.duration_formatted}
                                        </td>
                                    )}
                                    <td
                                        className="right clickable"
                                        onClick={() => toList()}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {state.data.total}
                                        <br />
                                        {state.data.duration_formatted}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </>
            }

        </>
    )
}

export default forwardRef(Stats);