import React, { useRef } from 'react';
import Options from './Options';

//modals
import Question from '../../../../modals/messages/Question';
import Error from '../../../../modals/messages/Error';
import EditPart from '../modals/AddEditPart';

//images
import loader from '../../../../../assets/img/loader.svg';
import ReactTooltip from 'react-tooltip';
import Api from '../../../../../_helpers/Api';
import checkIcon from '../../../../../assets/img/icons/check.png';
import crossIcon from '../../../../../assets/img/icons/close.png';
import { trans } from '../../../../../_providers/Translation'

function TableStock(props) {

    //refs
    const questionModalRef = useRef(null);
    const errorModalRef = useRef(null);

    const reservation = props.reservation;

    const handleDeletePart = id => {
        let modal = questionModalRef.current;

        modal.open(id);

        modal.onSuccess(() => {
            props.handleDeletePart(id);
        })
    }


    return (

        props.loading
            ?
            <img src={loader} alt="loading" />
            :
            <>
                <Question
                    ref={questionModalRef}
                    mainMessage={trans('headings.question12')}
                />

                <Error
                    ref={errorModalRef}
                    mainMessage={trans('headings.error3')}
                />

                {props.data.length == 0
                    ?
                    <div className="alert warning">
                        {trans('messages.noResultsFound')}
                    </div>
                    :
                    <div className="table-scroller">
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>{trans('tables.name')}</th>
                                    <th>{trans('tables.partNumber')}</th>
                                    <th className="center">{trans('tables.quantity')}</th>
                                    <th>{trans('tables.grossPrice')}</th>
                                    {props.reservation.can_modify && props.reservation.can_modify_parts &&
                                        <th className="options">{trans('tables.options')}</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.map(row =>
                                    <tr key={'p-' + row.id}>
                                        <td>
                                            {row.id}
                                        </td>
                                        <td>
                                            {row.storage.part.name}
                                        </td>
                                        <td>
                                            {row.storage.part.number || '-'}
                                        </td>
                                        <td className="center">
                                            {row.quantity}
                                        </td>

                                        <td>
                                            {row.gross_price_format} {row.currency.name}
                                        </td>
                                        {props.reservation.can_modify && props.reservation.can_modify_parts && (row.editable || row.deletable) &&
                                            <td>
                                                <Options
                                                    id={row.id}
                                                    deletable={row.deletable}
                                                    handleDeletePart={handleDeletePart}
                                                />
                                            </td>
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                }


            </>

    )
}

export default TableStock;