import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';

//images
import menuIcon from '../../../assets/img/icons/table-menu.png';
import editIcon from '../../../assets/img/icons/edit.png';
import deleteIcon from '../../../assets/img/icons/delete.png';

function Index(props) {

    const [table, setTable] = useState({
        options: null
    });

    const handleTableMenu = e => {
        setTable(table => {
            return {
                ...table,
                options: e.currentTarget
            }
        });
    };

    const handleCloseMenu = () => {
        setTable(table => {
            return {
                ...table,
                options: null
            }
        });
    };

    const handleEdit = (id,section_id) => {
        handleCloseMenu();
        props.handleEdit(id,section_id);
    }

    return (
        <>
            <img style={{marginLeft:'96%',marginRight:'0'}} src={editIcon} alt="menu" className="menu pointer" onClick={() => handleEdit(props.id,props.section_id)} />
        </>
    )
}

export default Index;
