import React, { useState, useRef } from 'react'
import Menu from '@material-ui/core/Menu'

//images
import menuIcon from '../../../../../assets/img/icons/table-menu.png'
import deleteIcon from '../../../../../assets/img/icons/delete.png'
import editIcon from '../../../../../assets/img/icons/edit.png'
import { trans } from '../../../../../_providers/Translation'

function Index(props) {

    const [table, setTable] = useState({
        options: null
    })

    const handleTableMenu = e => {

        setTable(table => {
            return {
                ...table,
                options: e.currentTarget
            }
        })
    }

    const handleCloseMenu = () => {
        setTable(table => {
            return {
                ...table,
                options: null
            }
        })
    }

    const handleDelete = () => {
        props.handleDeletePart(props.id)
        handleCloseMenu()
    }

    const handleEdit = () => {
        props.handleEditPart(props.id)
        handleCloseMenu()
    }

    return (
        <>
            <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />
            <Menu
                anchorEl={table.options}
                keepMounted
                open={Boolean(table.options)}
                onClose={handleCloseMenu}
            >

                {props.editable
                    ?
                    <div className="menu-option" onClick={handleEdit}>
                        <img src={editIcon} alt="delete" />
                        {trans('buttons.edit')}
                    </div>
                    :
                    ''
                }

                {props.deletable
                    ?
                    <div className="menu-option" onClick={handleDelete}>
                        <img src={deleteIcon} alt="delete" />
                        {trans('buttons.delete')}
                    </div>
                    :
                    ''
                }

            </Menu>
        </>
    )
}

export default Index;
