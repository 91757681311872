import React, { useEffect } from 'react';
import Options from './Options';
import loader from '../../../../assets/img/loader.svg';
import ReactTooltip from 'react-tooltip';
import NoDataFound from '../../../partials/NoDataFound';
import ClickAwayListener from 'react-click-away-listener';
import { trans } from '../../../../_providers/Translation'

function Table(props) {

    const handleShowQR = e => {
        document.querySelectorAll('.clickable-tooltip').forEach(e => {
            e.classList.remove('show');
        });

        let tip = e.target.getAttribute('data-for');

        document.getElementById(tip).classList.add('show');
    }

    const handleHideQR = () => {
        ReactTooltip.hide();
    }

    const handlePrint = id => {
        let img = document.querySelector('img[data-for="' + id + '"]');

        if (img) {
            let src = img.getAttribute('src');

            let w = window.open('', '_blank');

            w.document.write('<img src="' + src + '" />');
            w.document.close();

            w.focus();
            w.print();
        }
    }

    return (

        props.loading
            ?
            <img src={loader} alt="loading" />
            :
            props.parts.length === 0
                ?
                <NoDataFound />
                :
                <>
                    <div className="table-scroller">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '50px' }}>ID</th>
                                    <th>{trans('tables.category')}</th>
                                    <th>{trans('tables.name')}</th>
                                    <th>{trans('tables.partNumber')}</th>
                                    <th className="center">{trans('tables.image')}</th>
                                    <th className="center">{trans('tables.qrCode')}</th>
                                    <th className="options">{trans('tables.options')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.parts.map(part =>
                                    <tr key={'part-' + part.id}>
                                        <td style={{ width: '50px' }}>{part.id}</td>
                                        <td>{part.category && part.category.name ? part.category.name : '-'}</td>
                                        <td>{part.name}</td>
                                        <td>{part.number}</td>
                                        <td className="center">
                                            <img data-tip data-for={'img-' + part.id} src={part.picture} alt="" height={25} />
                                            <ReactTooltip
                                                id={'img-' + part.id}
                                                effect="solid"
                                                border={true}
                                                borderColor="#ccc"
                                                backgroundColor="white"
                                                arrowColor="white"
                                            >
                                                <img src={part.picture} alt="" style={{ maxHeight: '200px' }} />
                                            </ReactTooltip>
                                        </td>
                                        <td className="center">
                                            <img
                                                data-tip
                                                data-for={'qr-' + part.id}
                                                src={part.qrcode_url}
                                                alt=""
                                                height={25}
                                                data-event="click"
                                                onClick={handleShowQR}
                                                style={{ cursor: 'pointer' }}
                                            />

                                            <ReactTooltip
                                                id={'qr-' + part.id}
                                                effect="solid"
                                                border={true}
                                                borderColor="#ccc"
                                                backgroundColor="white"
                                                arrowColor="white"
                                                className="clickable-tooltip"
                                                delayShow={300}
                                                delayHide={0}
                                                clickable
                                                isCapture
                                                globalEventOff="click"
                                            >
                                                <img src={part.qrcode_url} alt="" />
                                                <button className="button-small" style={{ width: '100%' }} onClick={e => handlePrint('qr-' + part.id)}>
                                                    {trans('buttons.print')}
                                                </button>
                                            </ReactTooltip>
                                        </td>
                                        <td>
                                            <Options
                                                id={part.id}
                                                handleEdit={props.handleEdit}
                                                handleDelete={props.handleDelete}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>


                </>
    )
}

export default Table;