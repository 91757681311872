import React from 'react';
import { useAuthDataContext } from '../../../../_providers/Auth';
import Dropdown from '../../../partials/Dropdown';
import { trans } from '../../../../_providers/Translation'

function Filter(props) {

    const auth = useAuthDataContext();

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    const handleDate = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    const handleUser = data => {
        let val = data.id || ''

        if (data.id || data.name === '') {
            props.handleSearch('user_id', val);
        }
    }

    const handleSelectMobileFilter = e => {
        let selectedValue = e.target.value
        let fields = [...document.querySelectorAll('.filter-standard .col')]
        fields.map(field => {
            if (field.getAttribute('data-id') == selectedValue) field.style.display = 'flex'
            else if (field.getAttribute('data-id')) field.style.display = 'none'
        })
    }

    return (
        <form className="filter-standard schedule-filter">
            <div className="col select-filter-col">
                <label>
                    {trans('filters.filterBy')}
                </label>
                <select onChange={handleSelectMobileFilter}>
                    <option value="1" selected>
                        {trans('labels.contractor')}
                    </option>
                    <option value="2">
                        {trans('labels.from')}
                    </option>
                    <option value="3">
                        {trans('labels.to')}
                    </option>
                    <option value="4">
                        {trans('labels.search')}
                    </option>

                </select>
            </div>

            {auth.isManager()
                ?
                <div className="col" data-id="1">
                    <label htmlFor="from">
                        {trans('labels.contractor')}
                    </label>
                    <Dropdown
                        url="partners/employees/all"
                        inputIdValue={props.data.user_id ? Number(props.data.user_id) : ''}
                        renderText={data => {
                            return `${data.name} ${data.lastname}`;
                        }}
                        onChange={handleUser}
                    />
                </div>
                :
                ''
            }

            <div className="col" data-id="2">
                <label htmlFor="from">
                    {trans('labels.from')}
                </label>
                <input
                    id="from"
                    type="date"
                    name="date"
                    value={props.data.date}
                    onChange={handleDate}
                />
            </div>
            <div className="col" data-id="3">
                <label htmlFor="to">
                    {trans('labels.to')}
                </label>
                <input
                    id="to"
                    type="date"
                    name="deadline"
                    value={props.data.deadline}
                    onChange={handleDate}
                />
            </div>

            <div className="col" data-id="4">
                <label htmlFor="search">{trans('labels.search')}</label>
                <input
                    id="search"
                    type="search"
                    name="text"
                    value={props.data.text}
                    onChange={handleSearch}
                />
            </div>
        </form>
    )
}

export default Filter;