import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Api from '../../../../../_helpers/Api'
import { trans } from '../../../../../_providers/Translation';

//images
import docIcon from '../../../../../assets/img/icons/document.png'
import closeIcon from '../../../../../assets/img/icons/close.png'
import loader from '../../../../../assets/img/loader.svg'
import deleteIcon from '../../../../../assets/img/icons/delete-red.png';

//modals
import SuccessModal from '../../../../modals/messages/SuccessOld'

function AddEditTodo(props, ref) {

    const inputFileRef = useRef(null);

    const initialValidations = {
        date: false,
        deadline: false,
        title: false,
        text: false,

    }

    const defaultData = {
        tags: [
            {
                employee: {}
            }
        ],
        repeat: false,
        repeat_after: 1
    };

    const [addTodo, setaddTodo] = useState({
        validations: initialValidations,
        overlay: false,
        modal: false,
        successModalBlur: false,
        successModalPopup: false,
        params: null,
        editData: defaultData,
        priorities: [],
        alarm: [],
        employees: []
    })

    const editMode = addTodo.params

    let params = useParams().id

    const history = useHistory()

    const handleCancel = e => {
        if (e) e.preventDefault()

        setaddTodo(addTodo => ({
            ...addTodo,
            modal: false,
            alarm: []
        }))

        setTimeout(() => {
            setaddTodo(addTodo => ({
                ...addTodo,
                overlay: false,
                params: null,
                editData: defaultData
            }))
        }, 50)

        document.getElementById('form').reset();

    }

    useImperativeHandle(ref, () => ({
        open: (params) => {
            setaddTodo(addTodo => ({
                ...addTodo,
                overlay: true,
                params: params,
            }))

            setTimeout(() => {
                setaddTodo(addTodo => ({
                    ...addTodo,
                    modal: true,
                }))
            }, 50)
        },
        close: () => handleCancel()
    }))

    useEffect(() => {
        Api.get('todos/priorities')
            .then(res => {
                setaddTodo(addTodo => ({
                    ...addTodo,
                    priorities: res.data,
                }))
            })

        loadEmployees();
    }, [])

    useEffect(() => {
        if (editMode) {
            Api.get(`todos/find?id=${addTodo.params}`)
                .then(res => {
                    setaddTodo(addTodo => ({
                        ...addTodo,
                        editData: res.data,
                        alarm: res.data.alarms,
                    }))
                })
        }
    }, [addTodo.overlay])

    const handleAddTodo = e => {
        e.preventDefault()

        setaddTodo(addTodo => ({
            ...addTodo,
            validations: initialValidations,
            loading: true
        }))

        let data = new FormData(e.target)

        data.append('reservation_id', params)

        if (addTodo.editData.file) {
            data.append('file', addTodo.editData.file);
        }

        let url = editMode ? `todos/edit` : `reservations/todos/add?reservation_id=${params}`

        if (editMode) data.append('id', addTodo.params)

        Api.post(url, data)
            .then(() => {
                setaddTodo(addTodo => ({
                    ...addTodo,
                    loading: false,
                    successModalBlur: true,
                }))
                setTimeout(() => {
                    setaddTodo(addTodo => ({
                        ...addTodo,
                        successModalPopup: true,
                    }))
                }, 300)
                props.callback()
                history.push(`?tab=todo&indicator=${new Date().getTime()}`)
            })
            .catch(error => {
                let _err = error.response
                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {
                        setaddTodo(addTodo => ({
                            ...addTodo,
                            validations: {
                                ...addTodo.validations,
                                [input]: true
                            },
                            loading: false,
                        }))
                    }
                }
            })
    }

    const handleAcceptCallback = () => {
        setaddTodo(addTodo => ({
            ...addTodo,
            successModalPopup: false,
        }))
        setTimeout(() => {
            setaddTodo(addTodo => ({
                ...addTodo,
                successModalBlur: false,
            }))
        }, 300)
        handleCancel()
    }

    const handlePriorytySelect = e => {
        let id = e.target.value

        setaddTodo(addTodo => ({
            ...addTodo,
            editData: {
                ...addTodo.editData,
                priority: {
                    ...addTodo.editData.priority,
                    id: id
                }
            }
        }))
    }

    const handleAdd = () => {

        let period = {
            id: Math.random().toString().substring(3),
            was_recently_created: true,
        };

        setaddTodo(prev => ({
            ...prev,
            alarm: prev.alarm.concat(period)
        }));

    }

    const handleRemove = id => {

        let filtered = addTodo.alarm.filter(p => {
            return Number(p.id) !== Number(id);
        });

        setaddTodo(prev => ({
            ...prev,
            alarm: filtered
        }));

    }

    const handleAlarmChange = (e, i, field) => {
        const val = e.target.value

        let alarms = addTodo.alarm

        alarms[i][field] = val

        setaddTodo(prev => ({
            ...prev,
            alarm: alarms
        }))
    }

    const handleEmployeeChange = e => {
        let val = e.target.value;

        setaddTodo(prev => ({
            ...prev,
            editData: {
                ...prev.editData,
                tags: [
                    {
                        employee: {
                            ...prev.editData.tags[0].employee,
                            id: val
                        }
                    }
                ]
            }
        }));

    }

    const loadEmployees = () => {
        let url = 'partners/employees/all?active=1&sort=name';

        Api.get(url)
            .then(res => {
                setaddTodo(prev => ({
                    ...prev,
                    employees: res.data,
                }));
            });
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let file = e.target.files[0];

        if (!file) {
            return;
        }

        let url = URL.createObjectURL(file);

        setaddTodo(prev => ({
            ...prev,
            editData: {
                ...prev.editData,
                file: file,
                filename: file.name,
                file_url: url
            }
        }));

        inputFileRef.current.value = '';
    }

    const handleDetach = () => {

        if (editMode) {
            Api.post('todos/delete-file', {
                id: addTodo.params,
            }).then(res => {
                if (res.data.success) {
                    removeFile();
                }
            });
        } else {
            removeFile();
        }

    }

    const removeFile = () => {
        setaddTodo(prev => ({
            ...prev,
            editData: {
                ...prev.editData,
                file: null,
                filename: null,
            }
        }));
    }

    return (
        <div className={`${addTodo.overlay ? 'visible' : ''} overlay`}>
            <SuccessModal
                mainMessage={trans('headings.success7')}
                secondaryMessage={trans('subheadings.success7')}
                blur={addTodo.successModalBlur}
                popup={addTodo.successModalPopup}
                acceptCallback={handleAcceptCallback}
            />

            <form id="form" onSubmit={handleAddTodo} className={`${addTodo.modal ? 'show' : ''} popup-primary large`}>
                {editMode || addTodo.editData ?
                    <>
                        <h2 className="head">
                            <img src={docIcon} alt="document" className="document-icon" />
                            {trans('headings.addTodo')}
                            <img src={closeIcon} alt="close" className="close-icon" onClick={handleCancel} />
                        </h2>

                        <div className="scrollable" style={{ maxHeight: '70vh' }}>
                            <div className="popup-container x2">

                                <div className="col">
                                    <label htmlFor="heading">
                                        {trans('labels.heading')}
                                    </label>
                                    <input
                                        className={addTodo.validations.title ? 'validate' : ''}
                                        type="text"
                                        id="heading"
                                        name="title"
                                        defaultValue={addTodo.editData && addTodo.editData.title}
                                    />
                                </div>

                                <div className="col">
                                    <label htmlFor="priority">
                                        {trans('labels.priority')}
                                    </label>
                                    <select
                                        value={addTodo.editData.priority && addTodo.editData.priority.id}
                                        id="priority"
                                        onChange={handlePriorytySelect}
                                        name="priority_id"
                                    >
                                        {addTodo.priorities.length > 0 && addTodo.priorities.map(priority =>
                                            <option key={priority.id} value={priority.id}>
                                                {priority.translation.name}
                                            </option>
                                        )}
                                    </select>
                                </div>

                            </div>

                            <br />

                            <div className='popup-container x2'>

                                <div className="col">
                                    <label htmlFor="post-date">
                                        {trans('labels.setTo')}
                                    </label>
                                    <input
                                        className={addTodo.validations.date ? 'validate' : ''}
                                        name="date"
                                        type="datetime-local"
                                        id="post-date"
                                        defaultValue={addTodo.editData && addTodo.editData.date_ymd}
                                    />
                                </div>

                                <div className="col">
                                    <label htmlFor="deadline">
                                        {trans('labels.deadline')}
                                    </label>
                                    <input
                                        className={addTodo.validations.deadline ? 'validate' : ''}
                                        name="deadline"
                                        type="datetime-local"
                                        id="deadline"
                                        defaultValue={addTodo.editData && addTodo.editData.deadline_ymd}
                                    />
                                </div>

                            </div>

                            <br />

                            <div className='popup-container'>
                                <div className='col'>
                                    <label>
                                        {trans('labels.contractor')}
                                    </label>

                                    <select
                                        name='employees[]'
                                        value={addTodo.editData && addTodo.editData.tags && addTodo.editData.tags[0].employee.id
                                            ?
                                            addTodo.editData.tags[0].employee.id
                                            :
                                            ''}
                                        onChange={handleEmployeeChange}
                                    >
                                        {addTodo.employees.map(p =>
                                            <option key={'emp-' + p.id} value={p.id}>
                                                {p.name} {p.lastname}
                                            </option>
                                        )}
                                    </select>

                                </div>
                            </div>

                            <br />

                            <div style={{ 'overflow': 'auto', 'max-height': '210px' }}>
                                {addTodo.alarm.map((p, idx) => (
                                    <React.Fragment key={'alarm-' + p.id}>
                                        <div className='popup-container x2'>
                                            <div className="col">
                                                <label>
                                                    {trans('labels.notify')}
                                                </label>
                                                <div className='row'>
                                                    <input type="hidden" name="period_id[]" value={p.id} />
                                                    <input
                                                        type="datetime-local"
                                                        name="alarm[]"
                                                        className={addTodo.validations && addTodo.validations[`alarm.${idx}`] ? 'validate' : ''}
                                                        value={p.date_ymd}
                                                        onChange={e => handleAlarmChange(e, idx, 'date_ymd')}
                                                    />
                                                    <div style={{ paddingLeft: '5px', paddingTop: '5px' }}>
                                                        <a href="javascript:void(0)" onClick={() => handleRemove(p.id)}>
                                                            <img src={deleteIcon} />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <label>
                                                    {trans('labels.notifyVia')}
                                                </label>
                                                <select
                                                    value={p.type}
                                                    name='type[]'
                                                    onChange={e => handleAlarmChange(e, idx, 'type')}
                                                >
                                                    <option value='1'>{trans('random.systemNotification')}</option>
                                                    <option value='2'>{trans('labels.email')}</option>
                                                    <option value='3'>{trans('labels.message')}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>

                            <button className='button-small' type="button" onClick={() => handleAdd()}>
                                {trans('buttons.addNotification')}
                            </button>

                            <br />

                            <div className="popup-container">
                                <div className="col">
                                    <label htmlFor="content">
                                        {trans('labels.content')}
                                    </label>
                                    <textarea
                                        id="content"
                                        name="text"
                                        className={addTodo.validations.text ? 'validate' : ''}
                                        defaultValue={addTodo.editData && addTodo.editData.text}
                                    >

                                    </textarea>
                                </div>
                            </div>

                            <br />

                            {addTodo.editData.file
                                &&
                                <div className="row" style={{ marginBottom: '10px' }}>
                                    <div className="col">
                                        <label>
                                            <a href={addTodo.editData.file_url} target="_blank">
                                                {addTodo.editData.filename || 'n/a'}
                                            </a>
                                        </label>
                                    </div>
                                    <div className="col auto">
                                        <img onClick={handleDetach} src={deleteIcon} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                            }

                            <button className='button-small' type="button" onClick={handleShowAttach}>
                                {trans('buttons.uploadfile')}
                            </button>

                            <input ref={inputFileRef} type="file" onChange={handleAttach} hidden />

                        </div>

                        <div className="footer align-center">
                            {addTodo.loading ?
                                <img src={loader} alt="loading" />
                                :
                                <>
                                    <button onClick={handleCancel}>
                                        {trans('buttons.cancel')}
                                    </button>
                                    <button className="button-primary" type="submit">
                                        {trans('buttons.save')}
                                    </button>
                                </>
                            }
                        </div>
                    </>
                    :
                    <img src={loader} alt="loading" />
                }
            </form>
        </div>
    )
}

export default forwardRef(AddEditTodo)