import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Api from '../../../../_helpers/Api';
import Pagination from '../../../Pagination';
import Filter from '../../invoices/partials/Filter';
import loader from '../../../../assets/img/loader.svg';
import NoDataFound from '../../../partials/NoDataFound';
import Options from '../../reservation/partials/Options';
import FilterReservations from './FilterReservations';
import FilterMails from './FilterMails';
import { trans } from '../../../../_providers/Translation';

let timeout;

function Mails(props) {

    const params = useParams();
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const tabs = [
        {
            id: 'invoices',
            name: trans('tabs.invoices'),
        },
        {
            id: 'sendreservations',
            name: trans('labels.sentReservations'),
        },
    ];

    const tab = searchParams.get('type');

    const [state, setState] = useState({
        loading: true,
        page: 1,
        data: [],
        total: 0,
        pages: 0,
        filter: {
            type: tabs.indexOf(tab) > -1 ? tab : tabs[0].id
        },
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                type: tab || tabs[0].id
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    // useEffect(() => {
    //     console.log(state.filter);
    // }, [state.filter]);

    useEffect(() => {
        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'partners/relationships/mails' + '?related_partner_id=' + params.id + '&page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {

                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                }))
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            })
    }

    const handleTab = val => {

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                type: String(val),
            },
            page: 1,
            setFilter: Math.random().toString(),
            loading: true
        }))

    }

    const refreshData = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    //Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
            page: 1,
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: Math.random().toString() }));
    }

    return (
        <>
            <FilterMails
                tab={state.filter.type}
                tabs={tabs}
                handleTab={handleTab}
            />

            {state.loading
                ?
                <img src={loader} alt="loading" />
                :
                state.data.length === 0
                    ?
                    <NoDataFound />
                    :
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    {trans('tables.from')}
                                </th>
                                <th>
                                    {trans('tables.to')}
                                </th>
                                <th>
                                    {trans('tables.topic')}
                                </th>
                                <th>
                                    {trans('tables.refNo')}
                                </th>
                                <th>
                                    {trans('tables.info')}
                                </th>
                                <th>
                                    {trans('tables.employee')}
                                </th>
                                <th>
                                    {trans('tables.date')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.data.map(r =>
                                <tr key={r.id}>
                                    <td>
                                        {r.from}
                                    </td>
                                    <td>
                                        {r.to}
                                    </td>
                                    <td>
                                        {r.subject}
                                    </td>
                                    <td>
                                        {r.reservation
                                            ?
                                            <a
                                                href={'/reservations/edit/' + r.reservation_id} target="_blank"
                                                className="btn"
                                                style={{ background: r.reservation.color, color: r.reservation.text_color }}
                                            >
                                                {r.reservation.reference_code}
                                            </a>
                                            :
                                            '-'
                                        }
                                    </td>
                                    <td>
                                        {r.url
                                            ?
                                            <a href={r.url} target="_blank">
                                                {r.text}
                                            </a>
                                            :
                                            r.text
                                        }
                                    </td>
                                    <td>
                                        {r.employee
                                            ?
                                            `${r.employee.name} ${r.employee.lastname}`
                                            :
                                            '-'
                                        }
                                    </td>
                                    <td>
                                        {r.date}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
            }

            {!state.loading
                ?
                <Pagination
                    pages={state.pages}
                    page={state.page}
                    handlePage={handlePage}
                />
                :
                ''
            }
        </>
    )
}

export default Mails;