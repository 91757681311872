import React, { useEffect, useState, useRef } from 'react'
import Table from './partials/Table'
import Api from '../../../_helpers/Api'
import Pagination from '../../Pagination'
import { useHistory, useLocation } from 'react-router-dom'
import { trans } from '../../../_providers/Translation';

//modals
import Upload from './partials/Upload'
import Filter from './partials/Filter'
import Skeleton from 'react-loading-skeleton'
import Help from '../../partials/Help'
import HelpInfo from './partials/HelpInfo'

let timeout;

function BankTransactions() {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const modalUploadRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                start_date: searchParams.get('start_date') || '',
                end_date: searchParams.get('end_date') || '',
                text: searchParams.get('text') || '',
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {

        if (state.setFilter) {
            let url = '?page=' + state.page;

            Object.entries(state.filter).map(filter => {
                if (filter[1]) {
                    url += '&' + filter[0] + '=' + filter[1];
                }
            });

            history.push(url);
        }

    }, [state.setFilter]);


    useEffect(() => {
        if (state.update) {
            loadData()
        }
    }, [state.update])

    //read
    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }))

        let url = 'banktransactions/all?page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }))
            })
            .catch(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }))
            })
    }

    // create
    const handleAdd = () => {
        modalUploadRef.current.show()
    }

    // delete
    const handleDelete = id => {
        Api.post('state/delete', {
            id: id
        })
            .then(res => {
                if (res.data.success) {
                    refreshData();
                }
            })
    }

    // Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },

        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const refreshData = () => {
        setState(prev => ({
            ...prev,
            update: Math.random().toString()
        }))
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    return (
        <>
            <Upload
                ref={modalUploadRef}
                refreshData={refreshData}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.bankOperations')} <Help><HelpInfo /></Help>
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.bankOperations')}
                        </p>
                    </div>
                    <div className="right">
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.uploadFile')}
                        </button>
                    </div>
                </div>

                <Filter
                    filter={state.filter}
                    handleSearch={handleSearch}
                />

                {state.loading
                    ?
                    <Skeleton height={35} count={10} />
                    :
                    <>
                        <Table
                            data={state.data}
                            loading={state.loading}
                        />

                        <Pagination
                            pages={state.pages}
                            page={state.page}
                            handlePage={handlePage}
                        />


                    </>
                }

            </div>
        </>
    )
}

export default BankTransactions