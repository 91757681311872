import React, { useEffect, useState, useRef } from 'react';
import Table from './partials/Table';
import Api from '../../../_helpers/Api';
import { trans } from '../../../_providers/Translation'

//modals
import AddOrEdit from './partials/AddOrEdit';
import Question from '../../modals/messages/Question';
import Filter from './partials/Filter';

function SmsPackage() {

    const modalAddOrEditRef = useRef(null);
    const modalQuestionRef = useRef(null);

    const [smsPackage, setSmsPackage] = useState({
        loading: true,
        data: [],
        update: false,
    });

    console.log(smsPackage.data)

    useEffect(() => {
        loadPackages();
    }, [smsPackage.update]);

    //read
    const loadPackages = () => {

        setSmsPackage(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'sms-packets/all';

        Api.get(url)
            .then(res => {
                setSmsPackage(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setSmsPackage(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add();
    }

    const handleCreate = license => {

        // let data = partners.data.concat(partner);

        // setPartners(prev => ({
        //     ...prev,
        //     data: data
        // }));

        setSmsPackage(prev => ({
            ...prev,
            update: Math.random().toString()
        }));

    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id);
    }

    const handleUpdate = license => {

        let data = smsPackage.data.map(i => {
            if (i.id == license.id) i = license;
            return i;
        });

        setSmsPackage(prev => ({
            ...prev,
            data: data
        }));
    }

    // delete
    const handleDelete = id => {
        Api.post('sms-packets/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                let data = smsPackage.data.filter(i => {
                    return i.id !== id;
                });
                setSmsPackage(prev => ({
                    ...prev,
                    data: data
                }));
            }
        });
    }

    // Question modal
    const handleOpenQuestion = id => {
        modalQuestionRef.current.open(id);
    }

    return (
        <>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
                callback={handleDelete}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.smsPackages')}
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.smsPackages')}
                        </p>
                    </div>
                    <div className="right">
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.create')}
                        </button>
                    </div>
                </div>

                <Table
                    packages={smsPackage.data}
                    loading={smsPackage.loading}
                    handleEdit={handleEdit}
                    handleDelete={handleOpenQuestion}
                />

            </div>
        </>
    )
}

export default SmsPackage;