import axios from 'axios';
import * as Constants from '../_config/app';

const Api = axios.create({
    baseURL: Constants.App.api
});

Api.interceptors.request.use(config => {

    const token = localStorage.getItem('auth') ? (JSON.parse(localStorage.getItem('auth')).data.access_token) : '';

    config.params = config.params || {};

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
});

Api.interceptors.response.use(response => response, (error) => {
    // console.log(error.response);

    if (error.response && error.response.status) {
        if (error.response.status === 401) {
            if (window.location.pathname.indexOf('login') === -1) {

                let storage = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null;

                let redirectUrl = '/login/partners';

                if (storage?.data?.user?.is_administrator) {
                    redirectUrl = '/login/admin';
                }

                localStorage.clear();

                window.location = redirectUrl + '?redirect=' + encodeURIComponent(window.location.pathname + window.location.search);
            }
        }
    }

    return Promise.reject(error);
});

export default Api;