import React from 'react';

function Filter(props) {

    return (
        <form className="filter-standard">

            <div className="col">
                <label>
                    Име
                </label>
                <input
                    type="search"
                    name="name"
                    onKeyUp={(e) => props.handleSearch(e.target.name, e.target.value)}
                    onChange={(e) => props.handleSearch(e.target.name, e.target.value)}
                />
            </div>

        </form>
    )
}

export default Filter;