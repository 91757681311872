import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useLanguageContext } from '../../../../_providers/Language';

function SecondaryTabs(props) {

    const languages = useLanguageContext();

    const [tabs, setTabs] = useState({
        tab: 1,
        ripple: true
    })
    const handleTabs = (e, newValue) => {
        setTabs(tabs => {
            return {
                ...tabs,
                tab: newValue,
            }
        });

        props.callbackValue(newValue);
    };

    return (
        <Tabs
            value={tabs.tab}
            onChange={handleTabs}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
        >

            {languages.map(lang =>
                <Tab key={lang.id} value={lang.id} label={lang.name} disableRipple={tabs.ripple} />
            )}
        </Tabs>
    )
}

export default SecondaryTabs;