import React, { useEffect, useState } from 'react';
import Options from './Options';
import MainOptions from './MainOptions';
import Api from '../../../../_helpers/Api'
import { trans } from '../../../../_providers/Translation';
import Dropdown from '../../../partials/Dropdown';

//images
import notFound from '../../../../assets/img/not-found.png'
import { useAuthDataContext } from '../../../../_providers/Auth';
import { useLocation, useHistory, Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import * as Constants from '../../../../_config/app';
import ReactTooltip from 'react-tooltip';
import infoIcon from '../../../../assets/img/icons/info-black.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import checkIcon from '../../../../assets/img/icons/check.png';
import { TYPE } from '../../../../_constants/Reservation';
import Status from './Status';

function Table(props) {

    const auth = useAuthDataContext();

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selected = searchParams.get('id') ? Number(searchParams.get('id')) : null;

    const [table, setTable] = useState({
        employees: [],
        operators: []
    })

    useEffect(() => {
        Api.get('partners/employees/technicians')
            .then(res => {
                setTable(table => ({
                    ...table,
                    employees: res.data
                }))
            })

        Api.get('partners/employees/managersandoperators')
            .then(res => {
                setTable(table => ({
                    ...table,
                    operators: res.data
                }))
            })
    }, [])

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    const handleFilterByEmployee = (e, performer) => {
        e.stopPropagation();

        if (performer.type === 'App\\Employee') {
            props.handleSearch('employee_id', performer.id);
        }

    }

    const handleBasicStatusChange = data => {
        props.handleSearch('status', data.join(','));
    }

    const handleInitialStatusChange = data => {
        props.handleSearch('initial_status', data.join(','));
    }

    const handleFinalStatusChange = data => {
        props.handleSearch('final_status', data.join(','));
    }

    const handleToggleRow = rowId => {

        document.querySelectorAll('.collapsable-table tr.active').forEach(el => {
            el.classList.remove('active');
        });

        let rows = document.querySelectorAll(`tr[data-row="${rowId}"]`)

        for (let i = 0; i < rows.length; i++) {
            if (rows[i].classList.contains('active')) {
                rows[i].classList.remove('active');
            } else {
                rows[i].classList.add('active')
            }
        }

        searchParams.set('id', rowId);

        history.replace('?' + searchParams.toString());

    }

    const contains = (row, id) => {
        return row.items.filter(r => {
            return Number(r.id) === Number(id);
        }).length;
    }

    const handleRefresh = id => {
        props.handleRefresh();
        handleToggleRow(id);
    }

    const handleShow = reservation => {
        history.push('/reservations/edit/' + reservation.id);
    }

    const handleSelectMobileFilter = e => {
        let selectedValue = e.target.value
        let fields = [...document.querySelectorAll('.filter-standard .col')]
        fields.map(field => {
            if (field.getAttribute('data-id') == selectedValue) field.style.display = 'flex'
            else if (field.getAttribute('data-id')) field.style.display = 'none'
        })
    }

    const status = String(props.filter.status).split(',');
    const initialStatus = String(props.filter.initial_status).split(',');
    const finalStatus = String(props.filter.final_status).split(',');

    return (

        <>
            <form className="filter-standard" id="main-filter">
                <div className="col select-filter-col">
                    <label>
                        {trans('filters.filterBy')}
                    </label>
                    <select onChange={handleSelectMobileFilter}>
                        <option value="1" selected>
                            {trans('filters.type')}
                        </option>
                        <option value="2">
                            {trans('filters.employee')}
                        </option>
                        <option value="3">
                            {trans('labels.createdFrom')}
                        </option>
                        <option value="4">
                            {trans('labels.status')}
                        </option>
                        <option value="5">
                            {trans('labels.from')}
                        </option>
                        <option value="6">
                            {trans('labels.to')}
                        </option>
                        <option value="7">
                            {trans('labels.search')}
                        </option>
                    </select>
                </div>
                {auth.isEmployee() &&
                    <>
                        <div className="col" data-id="1">
                            <label>
                                {trans('filters.type')}
                            </label>
                            <select
                                name="res_type_id"
                                value={props.filter.res_type_id}
                                onChange={handleSearch}
                            >
                                <option value="" selected>
                                    {trans('labels.all')}
                                </option>
                                <option value={TYPE.ADDRESS}>
                                    {trans('labels.addressRepair')}
                                </option>
                                <option value={TYPE.ON_SITE_REPAIR}>
                                    {trans('labels.onSiteRepair')}
                                </option>
                            </select>
                        </div>
                        <div className="col" data-id="2">
                            <label>
                                {trans('filters.employee')}
                            </label>
                            <select
                                name="employee_id"
                                value={props.filter.employee_id}
                                onChange={handleSearch}
                            >
                                <option value="" selected>
                                    {trans('random.selectEmployee')}
                                </option>
                                {table.employees.map(employee =>
                                    <option key={'emp-' + employee.id} value={employee.id}>
                                        {employee.name} {employee.lastname}
                                    </option>
                                )}
                            </select>
                        </div>
                        <div className="col" data-id="3">
                            <label>
                                {trans('labels.createdFrom')}
                            </label>
                            <select
                                name="added_by"
                                value={props.filter.added_by}
                                onChange={handleSearch}
                            >
                                <option value="" selected>
                                    {trans('random.selectEmployee')}
                                </option>
                                {table.operators.map(employee =>
                                    <option key={'op-' + employee.id} value={employee.id}>
                                        {employee.name} {employee.lastname}
                                    </option>
                                )}
                            </select>
                        </div>
                    </>
                }
                <div className="col" data-id="4">
                    <label>
                        {trans('labels.status')}
                    </label>
                    {/* <select
                        name="status"
                        value={props.filter.status}
                        onChange={handleSearch}
                    >
                        <option value="" selected>
                            {trans('random.selectStatus')}
                        </option>
                        <option value="pending">
                            {trans('random.pendding')}
                        </option>
                        <option value="closed">
                            {trans('random.closed')}
                        </option>
                    </select> */}

                    <Status
                        status={status}
                        initialStatus={initialStatus}
                        finalStatus={finalStatus}
                        onBasicStatusChange={handleBasicStatusChange}
                        onInitialStatusChange={handleInitialStatusChange}
                        onFinalStatusChange={handleFinalStatusChange}
                    />
                </div>
                <div className="col" data-id="5">
                    <label>
                        {trans('labels.from')}
                    </label>
                    <input
                        type="date"
                        name="date"
                        value={props.filter.date}
                        onChange={handleSearch}
                    />
                </div>
                <div className="col" data-id="6">
                    <label>
                        {trans('labels.to')}
                    </label>
                    <input
                        type="date"
                        name="deadline"
                        value={props.filter.deadline}
                        onChange={handleSearch}
                    />
                </div>
                <div className="col" data-id="7">
                    <label>
                        {trans('labels.search')}
                    </label>
                    <input
                        className="field-big"
                        type="search"
                        name="search"
                        placeholder={trans('random.reservationSearch')}
                        value={props.filter.search}
                        onChange={handleSearch}
                    />
                </div>
            </form>

            {props.loading
                ?
                <Skeleton height={45} count={10} />
                :
                props.data.length === 0
                    ?
                    <div className="not-found">
                        <img src={notFound} alt="Not Found" />
                        <p>
                            {trans('messages.noResultsFound')}
                        </p>
                    </div>
                    :
                    <>
                        <div className="table-scroller">
                            <table className="collapsable-table">
                                <thead>
                                    <tr>
                                        {/* <th style={{ width: '100px' }}>Номер</th> */}
                                        <th>{trans('tables.clientCompany')}</th>
                                        <th>{trans('tables.address')}</th>
                                        <th className="center">{trans('tables.online')}</th>
                                        <th className="right">{trans('tables.finalPrice')}</th>
                                        <th className="right">{trans('tables.paidPrice')}</th>
                                        <th className="options">{trans('tables.options')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data.map(row =>
                                        <React.Fragment key={'r-' + row.master.id}>
                                            <tr
                                                data-row={row.master.id}
                                                className={contains(row, selected) ? 'active' : ''}
                                                onClick={() => handleToggleRow(row.master.id)}
                                            >
                                                {/* <td style={{ width: '100px' }}>
                                                    <Link
                                                        to={`/reservations/edit/${row.master.id}`}
                                                        className="btn"
                                                        style={{ background: row.master.color, color: row.master.text_color }}
                                                    >
                                                        {row.master.reference_code}
                                                    </Link>
                                                </td> */}
                                                <td>
                                                    {row.customer.name} {row.customer.lastname} {row.contractor && `(${row.contractor.name})`}
                                                </td>
                                                <td>
                                                    {row.master.res_type_id === TYPE.ON_SITE_REPAIR ? trans('labels.onSiteRepair') : row.master.full_address}
                                                </td>
                                                <td className="center">
                                                    {row.has_online
                                                        ?
                                                        <img src={checkIcon} height="12" />
                                                        :
                                                        <img src={closeIcon} height="12" />
                                                    }
                                                </td>
                                                <td className="right">
                                                    {row.total_price} {row.currency}
                                                </td>
                                                <td className="right">
                                                    {row.paid_price} {row.currency}
                                                </td>
                                                <td className="options">
                                                    <MainOptions
                                                        id={row.master.id}
                                                        data={row.master}
                                                        handleRefresh={handleRefresh}
                                                    />
                                                </td>
                                            </tr>
                                            <tr
                                                className={['collapsable', contains(row, selected) ? 'active' : ''].join(' ')}
                                                data-row={row.master.id}
                                            >
                                                <td colSpan="6">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '40px' }}>ID</th>
                                                                <th>{trans('tables.number')}</th>
                                                                <th>{trans('tables.setTo')}</th>
                                                                <th>{trans('tables.clientCompany')}</th>
                                                                <th>{trans('tables.visitDate')}</th>
                                                                <th>{trans('tables.visitHour')}</th>
                                                                <th>{trans('tables.initialStatus')}</th>
                                                                <th>{trans('tables.finalStatus')}</th>
                                                                {/* <th className="right">{trans('tables.finalPrice')}</th>
                                                                <th className="right">{trans('tables.paidPrice')}</th> */}
                                                                <th className="options">{trans('tables.options')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {row.items.map(r =>
                                                                <tr
                                                                    key={'rr-' + r.id} data-row={row.id}
                                                                >
                                                                    <td style={{ width: '40px' }} onClick={() => handleShow(r)}>
                                                                        {r.id}
                                                                    </td>
                                                                    <td onClick={() => handleShow(r)}>
                                                                        <Link
                                                                            to={`/reservations/edit/${r.id}`}
                                                                            className="btn"
                                                                            style={{ background: r.color, color: r.text_color }}
                                                                        >
                                                                            {r.reference_code}
                                                                        </Link>
                                                                    </td>
                                                                    <td onClick={() => handleShow(r)}>

                                                                        {r.performer
                                                                            ?
                                                                            r.performer.type === 'App\\Employee'
                                                                                ? `${r.performer.name} ${r.performer.lastname}`
                                                                                : `${r.performer.name}`
                                                                            :
                                                                            '-'
                                                                        }

                                                                    </td>
                                                                    <td onClick={() => handleShow(r)}>
                                                                        {r.customer.name} {r.customer.lastname} {r.contractor && `(${r.contractor.name})`}
                                                                    </td>
                                                                    <td onClick={() => handleShow(r)}>
                                                                        {r.visit_date
                                                                            ? `${r.visit_date_dmy}`
                                                                            : '-'
                                                                        }
                                                                    </td>
                                                                    <td onClick={() => handleShow(r)}>
                                                                        {r.visitperiod
                                                                            ? `${r.visitperiod.period}`
                                                                            : '-'
                                                                        }
                                                                    </td>
                                                                    <td onClick={() => handleShow(r)}>
                                                                        {r.initialstatus && r.initialstatus.translation.name}
                                                                    </td>
                                                                    <td onClick={() => handleShow(r)}>
                                                                        {r.finalstatus && r.finalstatus.translation.name}
                                                                    </td>
                                                                    {/* <td onClick={() => handleShow(r)} className="right">
                                                                        <label className="flex">
                                                                            {r.total_price_formatted}  {r.currency ? r.currency.name : ''}
                                                                            <img className="info-icon" data-tip data-for={'price-info-' + r.id} src={infoIcon} />
                                                                        </label>
                                                                        <ReactTooltip
                                                                            id={'price-info-' + r.id}
                                                                            effect="solid"
                                                                            multiline
                                                                        >
                                                                            <span>
                                                                                {trans('random.net')} {r.total_price_formatted}
                                                                                <br />
                                                                                {trans('random.tax')} {r.total_vat_price_formatted}
                                                                                <br />
                                                                                {trans('random.gross')} {r.total_gross_price_formatted}
                                                                            </span>
                                                                        </ReactTooltip>

                                                                    </td>
                                                                    <td onClick={() => handleShow(r)} className="right">
                                                                        {r.paid_price_formatted} {r.currency ? r.currency.name : ''}
                                                                    </td> */}
                                                                    <td>
                                                                        <Options
                                                                            id={r.id}
                                                                            data={r}
                                                                            masterId={row.master.id}
                                                                            handleShow={handleShow}
                                                                            handleRefresh={handleRefresh}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </>
            }

        </>

    )
}

export default Table;