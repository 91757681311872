import React, { useRef, useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import loader from '../../../assets/img/loader.svg';
import Api from '../../../_helpers/Api';
import { useMessagesContext } from '../../../_providers/Messages';
import { trans, plural } from '../../../_providers/Translation';

let timeout;

function Employees(props) {

    const messages = useMessagesContext();

    const ref = useRef(null);

    const [state, setState] = useState({
        loading: true,
        list: [],
        search: '',
        update: false,
        key: Math.random().toString(),
        initLoad: true
    });

    useEffect(() => {
        loadData();
    }, [state.update]);

    useEffect(() => {
        if (!state.initLoad) {
            handleUpdate();
        }
    }, []);

    const loadData = (page = 1) => {
        let url = 'partners/employees/all?active=1&sort=name&except_current_user=1';

        if (state.search) {
            url += '&name=' + state.search;
        }

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    list: res.data,
                    loading: false,
                    initLoad: false
                }));
            });
    }

    const handleSearch = e => {
        let val = e.target.value;

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                search: val,
            }));

            handleUpdate();

        }, 500);
    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            list: [],
            loading: true,
            update: new Date().getTime(),
            key: Math.random().toString()
        }));
    }

    const handleOpenChat = (id, type) => {
        messages.openChat(id, type);
    }

    return (
        <div className={`show messages-module`}>
            <div className="head">
                {trans('header.employees')}
            </div>
            <div className="body">
                <div className="scroll" ref={ref} key={state.key}>
                    {state.loading
                        ?
                        <img src={loader} alt="loading" />
                        :
                        state.list.length === 0
                            ?
                            <p className="no-data">{trans('messages.noEmployees')}</p>
                            :
                            <ul>
                                {state.list.map(el =>
                                    <li key={'e-' + el.id} onClick={() => handleOpenChat(el.id, el.type)}>
                                        <picture>

                                            <img src={el.avatar} alt="avatar" />
                                        </picture>
                                        <div className="right">
                                            <h3>
                                                {el.name} {el.lastname}
                                            </h3>
                                        </div>
                                    </li>
                                )}
                            </ul>
                    }
                </div>
                <input
                    type="search"
                    placeholder="Търсене"
                    onChange={handleSearch}
                />
            </div>
        </div>
    )
}

export default Employees;