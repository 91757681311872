import React, { useEffect } from 'react'
import { trans } from '../../../../_providers/Translation';
import Dropdown from '../../../partials/Dropdown';
import inIcon from '../../../../assets/img/icons/phone/incomming.png';
import outIcon from '../../../../assets/img/icons/phone/outgoing.png';
import missIcon from '../../../../assets/img/icons/phone/missed.png';
import cancelIcon from '../../../../assets/img/icons/phone/canceled.png';

function Filter(props) {

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    const handleSelectMobileFilter = e => {
        let selectedValue = e.target.value
        let fields = [...document.querySelectorAll('.filter-standard .col')]
        fields.map(field => {
            if (field.getAttribute('data-id') == selectedValue) field.style.display = 'flex'
            else if (field.getAttribute('data-id')) field.style.display = 'none'
        })
    }

    const handleStatus = data => {

        let val = data.id || '';

        props.handleSearch('status', val);
    }

    useEffect(() => {
        console.log(props.filter.status)
    }, [props.filter.status])

    return (
        <>
            <div className="filter-standard">
                <div className="col select-filter-col">
                    <label>
                        {trans('filters.filterBy')}
                    </label>
                    <select onChange={handleSelectMobileFilter}>

                        <option value="1">
                            {trans('filters.status')}
                        </option>
                        <option value="2">
                            {trans('filters.employee')}
                        </option>
                        <option value="3">
                            {trans('filters.fromDate')}
                        </option>
                        <option value="4">
                            {trans('filters.toDate')}
                        </option>
                        <option value="5">
                            {trans('filters.phone')}
                        </option>

                    </select>
                </div>

                <div className="col" data-id="1">
                    <label htmlFor="status">
                        {trans('filters.status')}
                    </label>

                    <Dropdown
                        inputIdName="status_id"
                        inputIdValue={props.filter.status || ''}
                        data={[
                            {
                                id: '',
                                name: trans('labels.all')
                            },
                            {
                                id: 'incomming',
                                name: trans('labels.incomming'),
                                icon: inIcon
                            },
                            {
                                id: 'outgoing',
                                name: trans('labels.outgoing'),
                                icon: outIcon
                            },
                            {
                                id: 'missed',
                                name: trans('labels.missed'),
                                icon: missIcon
                            },
                            {
                                id: 'canceled',
                                name: trans('labels.canceled'),
                                icon: cancelIcon
                            }
                        ]}
                        renderInputText={data => {
                            return data.name;
                        }}
                        renderText={data => {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '20px', height: '20px', marginRight: '10px' }}>
                                        {data.icon && <img src={data.icon} style={{ width: '100%', height: '100%' }} />}
                                    </div>
                                    <span>{data.name}</span>
                                </div>
                            )
                        }}
                        filterable={false}
                        onChange={data => {
                            handleStatus(data)
                        }}
                    />

                </div>

                <div className="col" data-id="2">
                    <label>
                        {trans('labels.employee')}
                    </label>
                    <select
                        name="employee_id"
                        value={props.filter.employee_id}
                        onChange={handleSearch}
                    >
                        <option value="" selected>
                            {trans('random.selectEmployee')}
                        </option>
                        {props.employees.map(employee =>
                            <option key={'emp-' + employee.id} value={employee.id}>
                                {employee.name} {employee.lastname}
                            </option>
                        )}
                    </select>
                </div>

                <div className="col" data-id="3">
                    <label htmlFor="start">
                        {trans('filters.fromDate')}
                    </label>
                    <input
                        type="date"
                        name="start"
                        onChange={handleSearch}
                        value={props.filter.start}
                    />
                </div>

                <div className="col" data-id="4">
                    <label htmlFor="end">
                        {trans('filters.toDate')}
                    </label>
                    <input
                        type="date"
                        name="end"
                        onChange={handleSearch}
                        value={props.filter.end}
                    />
                </div>

                <div className="col" data-id="5">
                    <label htmlFor="">
                        {trans('filters.phone')}
                    </label>
                    <input
                        type="text"
                        name="phone"
                        onChange={handleSearch}
                        value={props.filter.phone}
                    />
                </div>

            </div>
        </>
    )
}

export default React.memo(Filter, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})