import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import { useAuthDataContext } from '../../../../_providers/Auth';
import Api from '../../../../_helpers/Api';
import DesignModal from '../modals/DesignModal'

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import deleteIcon from '../../../../assets/img/icons/delete-red.png';
import { trans } from '../../../../_providers/Translation';

function WebDesign(props, ref) {

    const auth = useAuthDataContext();

    const designImgRef = useRef(null);

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        design: [],
        partner: [],
        checked: [],
        allImages: [],
        check: false,
        loading: false,
        onSave: null,
    });

    const [validations, setValidations] = useState(null);

    const [state, setState] = useState({
        loading: true,
        design: [],
        partner: [],
        update: false,
    })

    useEffect(() => {
        loadDesigns();
    }, [state.update]);

    const loadDesigns = () => {
        Api.get('website/design')
            .then(res => {

                let images = [];

                res.data.map(d => {
                    images = images.concat(d.images);
                });

                setState(prev => ({
                    ...prev,
                    design: res.data,
                    allImages: images
                }));

                loadPartnerDesigns(res.data);
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const loadPartnerDesigns = (design) => {
        Api.get('website/design/find?partner_id=' + auth.user().partner_id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    partner: res.data,
                }));
                loadData(design, res.data)
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const loadData = (design, partner) => {
        let check = design.map(item => {
            if (partner.design_id == item.id) {
                item.check = true
            }
            else {
                item.check = false
            }
            return item;
        })
        setPopup(prev => ({
            ...prev,
            partner: partner,
            design: check
        }));
        setState(prev => ({
            ...prev,
            loading: false,
        }))
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'website/design/set?partner_id=' + auth.user().partner_id;

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                if (typeof popup.onSave === 'function') {
                    popup.onSave();
                }

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                handlePartner(auth.user().partner_id)

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }
            });
    }

    const findIndex = id => {
        let index = popup.design.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    const handlePartner = (id) => {
        props.handlePartner(id);
    }

    const handleCheck = (e, id) => {

        let index = findIndex(id);
        let check = popup.design[index]

        for (let i = 0; i < popup.design.length; i++) {
            let checked = popup.design[i];
            checked['check'] = false;
            setPopup(prev => ({
                ...prev,
                design: checked
            }));
        }

        if (check) {
            let checked = popup.design;
            check['check'] = e.target.checked;
            checked[index] = check;
            setPopup(prev => ({
                ...prev,
                design: checked
            }));
        }

    }

    const handleImage = (imageId) => {
        let modal = designImgRef.current;

        let index = state.allImages.findIndex(i => Number(i.id) === Number(imageId));

        if (index) {
            modal.show(index);
        } else {
            modal.show(0);
        }

    }

    return (
        <>
            {
                state.loading
                    ?
                    <div className="footer align-center">
                        <img src={loader} alt="loading" />
                    </div>
                    :
                    <>
                        <DesignModal
                            ref={designImgRef}
                            images={state.allImages}
                        />

                        <form id="form"
                            onSubmit={handleSave}
                            style={{ width: 'fit-content', marginRight: 'auto', marginLeft: 'auto' }}
                        >
                            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>

                                <h2 style={{ textAlign: 'center' }} className="head">
                                    {trans('headings.selectDesign')}*
                                </h2>

                                <br />
                                <div style={{ overflow: 'auto', fontSize: '13px', maxHeight: '500px', marginTop: '5px' }} className='design-images'>
                                    {popup.design.map((c, idx) =>
                                        <div key={c.id} style={{ maxWidth: '220px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className='image-col'>
                                            <img onClick={c.images[0] ? () => handleImage(c.images[0].id) : ''} src={c.images[0] ? c.images[0].images : ''} />
                                            {c.images[0] && c.images[0].images}
                                            <div className="checkbox-primary">
                                                <label htmlFor={c.id}>
                                                    <input
                                                        id={c.id}
                                                        type="checkbox"
                                                        name="design_id"
                                                        onChange={e => handleCheck(e, c.id)}
                                                        value={c.id}
                                                        checked={c.check}
                                                    />
                                                    <div className="checkmark"></div>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <br />
                                <div style={{ fontSize: '20px', textAlign: 'center' }}>
                                    *{trans('random.exampleImages')}
                                </div>
                                <br />
                            </div>
                            <div className="footer align-center">
                                {popup.loading ?
                                    <img src={loader} alt="loading" />
                                    :
                                    <>
                                        <button style={{ marginLeft: 'auto', marginRight: 'auto' }} className="button button-primary" type="submit">
                                            {trans('buttons.save')}
                                        </button>
                                    </>
                                }
                            </div>
                        </form>
                    </>
            }
        </>
    )
}

export default forwardRef(WebDesign);