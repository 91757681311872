import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useAuthDataContext } from '../../../_providers/Auth';
import { trans } from '../../../_providers/Translation';

function FilterTabs(props) {
    const auth = useAuthDataContext();

    const [tabs, setTabs] = useState({
        ripple: true
    })

    const handleTabs = (e, newValue) => {
        props.handleTab(newValue);
    };

    return (
        <Tabs
            value={props.tab}
            onChange={handleTabs}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
        >
            <Tab value="design" label={trans('tabs.design')} disableRipple={tabs.ripple} />
            {props.design_id && <Tab value="page" label={trans('tabs.pages')} disableRipple={tabs.ripple} />}
        </Tabs>
    )
}

export default FilterTabs;