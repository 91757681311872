import React, { useState, useImperativeHandle, forwardRef, useEffect, useCallback } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Api from '../../_helpers/Api'
import L, { LatLng, marker } from "leaflet";
import 'leaflet-routing-machine'
import '../../assets/L.Routing.OpenRouteService'
import { trans } from '../../_providers/Translation';

//images
import closeIcon from '../../assets/img/icons/close.png'
import markerImg from '../../assets/img/leeflet/marker-icon.png';
import greenWorkerImg from '../../assets/img/leeflet/worker_green.png';
import orangeWorkerImg from '../../assets/img/leeflet/worker_orange.png';
import redWorkerImg from '../../assets/img/leeflet/worker_red.png';
import redMarkerImg from '../../assets/img/leeflet/marker-icon-red.png';
import yellowMarkerImg from '../../assets/img/leeflet/marker-icon-yellow.png';

import '../../assets/css/modals/employees.css'
import '../../assets/css/leeflet.css';
import '../../assets/css/leaflet-routing-machine.css';
import { Maps } from '../../_config/maps';
import Skeleton from 'react-loading-skeleton';
import { useSocketContext } from "../../_providers/Socket";
import { useAuthDataContext } from '../../_providers/Auth';
import '../../assets/Leaflet.fullscreen';
import '../../assets/css/leaflet.fullscreen.css';

let timeout;
let listen = false;

let cc = {};

const markerIcon = L.icon({
    iconUrl: markerImg,
    // shadowUrl: 'leaf-shadow.png',
    iconSize: [25, 41], // size of the icon
});

const greenMarker = L.icon({
    iconUrl: greenWorkerImg,
    // shadowUrl: 'leaf-shadow.png',
    iconSize: [56, 56], // size of the icon
});

const orangeMarker = L.icon({
    iconUrl: orangeWorkerImg,
    // shadowUrl: 'leaf-shadow.png',
    iconSize: [56, 56], // size of the icon
});

const redMarker = L.icon({
    iconUrl: redWorkerImg,
    // shadowUrl: 'leaf-shadow.png',

    iconSize: [56, 56], // size of the icon
});

const redMarkerIcon = L.icon({
    iconUrl: redMarkerImg,
    // shadowUrl: 'leaf-shadow.png',
    iconSize: [25, 41], // size of the icon
});

const yellowMarkerIcon = L.icon({
    iconUrl: yellowMarkerImg,
    // shadowUrl: 'leaf-shadow.png',
    iconSize: [25, 41], // size of the icon
});

const router = new L.Routing.openrouteservice(Maps.openrouteservice);

function UpcomingReservations(props, ref) {

    let supermap;

    const history = useHistory()
    const location = useLocation();
    const auth = useAuthDataContext();
    const listener = useSocketContext();

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        loading: false,
        employeeId: null,
        date: null,
        employee: {},
        data: [],
        distances: {},
        selected: null,
        dataLoaded: false,
        selected: null,
        fromProps: true,
        update: false,
        refreshFromSocket: false,
    });

    const [map, setMap] = useState(null);
    const [mapId, setMapId] = useState(Math.random().toString().substring(3));
    const [layerGroup, setLayerGroup] = useState(null);
    const [predictLayer, setPredictLayer] = useState(null);
    const [flyToBounds, setFlyToBounds] = useState(true);
    const [fitBounds, setFitBounds] = useState(false);

    const [markers, setMarkers] = useState({
        reservations: [],
        employee: null,
        random: []
    });

    useImperativeHandle(ref, () => ({
        open: (_id = null, _date = null) => {

            if (_id) {
                setState(prev => ({
                    ...prev,
                    employeeId: _id,
                    fromProps: false,
                }));
            }

            if (!props.date) {
                setState(prev => ({
                    ...prev,
                    date: _date
                }));
            }

            handleOpen()
        },
        close: () => {
            handleClose()
        }
    }));


    const loadData = useCallback((fromSocket = false) => {

        // console.log(state.employeeId);

        if (!state.employeeId) {
            return;
        }

        // console.log('load upcoming reservations...');

        setState(prev => ({
            ...prev,
            loading: fromSocket ? false : true,
            data: fromSocket ? prev.data : [],
            selected: fromSocket ? prev.selected : null,
            dataLoaded: false,
        }));

        setFlyToBounds(
            fromSocket ? false : true
        );

        // console.log(fromSocket);

        let url = 'reservations/upcoming?employee_id=' + state.employeeId;

        if (state.date) {
            url += '&start=' + state.date;
        }

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    employee: res.data.employee,
                    data: res.data.reservations,
                }));
            }).finally(() => {

                setState(prev => ({
                    ...prev,
                    loading: false,
                }));

                setTimeout(() => {
                    setState(prev => ({
                        ...prev,
                        loading: false,
                        dataLoaded: true
                    }));
                }, 100);
            });
    }, [state.employeeId, state.date, state.update]);

    useEffect(() => {

        // if (state.dataLoaded) {

        let cId = 'upcoming-reservations-map-' + mapId;

        let container = document.getElementById(cId);

        // if (container.classList.contains('ready')) {
        //     console.log('ready')
        //     return;
        // }

        supermap = L.map(cId, {
            center: [42.70060440808085, 23.318481445312504],
            zoom: 10,
            layers: [
                L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                })
            ],
            fullscreenControl: true,
        });

        container.classList.add('ready');

        setLayerGroup(L.layerGroup().addTo(supermap));
        setPredictLayer(L.layerGroup().addTo(supermap));

        setMap(supermap);
        // }

    }, []);

    // useEffect(() => {
    //     if (!state.fromProps) {
    //         return;
    //     }

    //     console.log(props.id)

    //     if (props.id) {
    //         setState(prev => ({
    //             ...prev,
    //             employeeId: props.id,
    //             date: props.date,
    //         }));
    //     }
    // }, [props.id, props.date]);

    useEffect(() => {
        loadData();

        if (predictLayer) {
            predictLayer.clearLayers();

            setMarkers(prev => ({
                ...prev,
                random: []
            }));
        }

    }, [state.employeeId, state.date]);

    useEffect(() => {
        setFlyToBounds(true);
        setFitBounds(false);
    }, [state.employeeId, state.date, state.selected]);

    useEffect(() => {
        if (state.refreshFromSocket) {
            loadData(true);
        }
    }, [state.refreshFromSocket, loadData]);

    const loadCallback = e => {

        // loadData(true);

        console.log('upcoming reservations change...');

        setState(prev => ({
            ...prev,
            refreshFromSocket: new Date().getTime()
        }));

    };

    useEffect(() => {
        if (listen) {
            return;
        }

        console.log('listening upcoming reservations...');

        listener.private(`partners.${auth.partnerId()}`)
            .listen('EmployeePositionChanged', loadCallback);

        listen = true;

        return () => {
            console.log('stop listening...');

            setState(prev => ({
                ...prev,
                refreshFromSocket: false
            }));

            listener.connector.socket.removeListener('EmployeePositionChanged', loadCallback);
            listen = false;
        }

    }, []);

    // data markers
    useEffect(() => {
        if (map) {

            console.log('change data markers');

            let _markers = [];

            let employee = state.employee;

            if (employee) {
                if (auth.module('gps') && employee.lat && employee.lon) {

                    let icon;

                    if (!employee.gps) {
                        icon = redMarker;
                    } else {
                        if (employee.currentreservation) {
                            icon = orangeMarker;
                        } else {
                            icon = greenMarker;
                        }
                    }

                    let marker = L.marker([employee.lat, employee.lon], {
                        icon: icon,
                        employee_id: employee.id,
                    });

                    // _markers.push(marker);

                    let name = trans('random.currentPosition');

                    marker.bindPopup(name, {
                        autoClose: false,
                        closeOnClick: false,
                        autoPan: false
                    });

                    setMarkers(prev => ({
                        ...prev,
                        employee: marker
                    }));
                } else {
                    setMarkers(prev => ({
                        ...prev,
                        employee: null
                    }));
                }
            } else {
                setMarkers(prev => ({
                    ...prev,
                    employee: null
                }));
            }

            if (state.data.length) {
                state.data.map(e => {
                    if (e.lat && e.lon) {
                        let marker = L.marker([e.lat, e.lon], {
                            icon: markerIcon,
                            reservation_id: e.id,
                            start_id: e.start_id,
                            start_lat: e.start_lat,
                            start_lon: e.start_lon,
                        });

                        _markers.push(marker);

                        let name = `${e.full_address}, Реф. №${e.reference_code}`;

                        marker.bindPopup(name, {
                            autoClose: false,
                            closeOnClick: false,
                            autoPan: false
                        });
                    }

                });

            }

            setMarkers(prev => ({
                ...prev,
                reservations: _markers
            }));

        }
    }, [map, state.data]);

    // сетване на маркерите
    useEffect(() => {
        if (map && layerGroup) {
            layerGroup.clearLayers();

            // let allMarkers = getAllMarkers();

            let group = [];

            // if (allMarkers.length) {

            console.log('fit to markers');

            let employeeMarker = getEmployeeMarker();

            if (employeeMarker) {
                group.push(employeeMarker);
            }

            if (state.selected) {
                let reservationMarker = getReservationMarker(state.selected);

                if (reservationMarker) {
                    group.push(reservationMarker);
                }

                markers.random.forEach(m => {
                    group.push(m);
                });
            } else {
                group = [...markers.reservations, ...group];
            }

            if (group.length > 0) {
                group.forEach(m => {
                    m.addTo(layerGroup);
                    m.openPopup();
                });

                if (flyToBounds || !fitBounds) {
                    group = new L.featureGroup(group);

                    map.fitBounds(group.getBounds(), {
                        padding: [50, 50]
                    });

                    setFitBounds(true);
                }

            }

            // }
        }
    }, [markers]);

    // сетване на пътя и маркери от-до
    useEffect(() => {

        let id = state.selected;

        if (!id) {
            return;
        }

        console.log('set random markers');

        predictLayer.clearLayers();

        let marker1 = getPredictMarker(id);
        let marker2 = getReservationMarker(id);

        let reservation = getReservation(id);

        let predictMarker = null;
        let startMarker = null;

        if (marker1) {

            // къде би трябвало да се намира техника
            let predictLat = marker1._latlng.lat;
            let predictLon = marker1._latlng.lng;

            predictMarker = L.marker([predictLat, predictLon], {
                icon: yellowMarkerIcon,
            });

            predictMarker.bindPopup(trans('random.expectingPosition'), {
                autoClose: false,
                closeOnClick: false,
                autoPan: false,
            });

            // predictMarker.addTo(predictLayer);

        }

        if (reservation) {
            // стартова позиция на техника
            let startLat = reservation.employee_lat;
            let startLon = reservation.employee_lon;

            if (startLat && startLon) {
                startMarker = L.marker([startLat, startLon], {
                    icon: redMarkerIcon,
                });

                startMarker.bindPopup(trans('random.startingPosition'), {
                    autoClose: false,
                    closeOnClick: false,
                    autoPan: false
                });

                // startMarker.addTo(predictLayer);
            }

        }

        // дистанция от стартовия маркер до резервацията
        let distances = Object.assign({}, state.distances);
        // let employeeCoords = markers.employee.getLatLng();

        let employeeCoords;

        if (startMarker) {
            employeeCoords = startMarker.getLatLng();
        } else if (predictMarker) {
            employeeCoords = predictMarker.getLatLng();
        }

        let reservationCoords;

        if (marker2) {
            reservationCoords = marker2.getLatLng();
        }

        if (auth.module('gps') && employeeCoords && reservationCoords) {

            getRoute(employeeCoords, reservationCoords, (route) => {

                if (!route) {
                    return;
                }

                // let r = Math.floor(Math.random() * 255);
                // let g = Math.floor(Math.random() * 255);
                // let b = Math.floor(Math.random() * 255);

                // let color = "rgb(" + r + " ," + g + "," + b + ")";

                let line = L.Routing.line(route, {
                    // styles: [{
                    //     color: color,
                    //     opacity: 0.5,
                    //     weight: 3
                    // }]
                }).addTo(predictLayer);

                let distance = route.summary.totalDistance / 1000;
                let mins = route.summary.totalTime / 60;

                id = Number(id);

                if (!distances[id]) {
                    distances[id] = {};
                }

                distances[id].distance = distance;
                distances[id].duration = mins;

                setState(prev => ({
                    ...prev,
                    distances: distances
                }));

            });

        }

        let allMarkers = [];

        if (predictMarker) {
            allMarkers.push(predictMarker);
        }

        if (startMarker) {
            allMarkers.push(startMarker);
        }

        setMarkers(prev => ({
            ...prev,
            random: allMarkers,
        }));

    }, [state.selected, state.data]);

    // при показване на попъпа
    useEffect(() => {
        if (state.overlay && state.modal && map) {
            map.invalidateSize();
        }
    }, [state.overlay, state.modal, map]);

    const handleOpen = () => {

        setState(prev => ({
            ...prev,
            overlay: true,
            update: new Date().getTime()
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)

    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                dataLoaded: false,
            }))
        }, 50)

        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    }

    const handleSelect = id => {
        setState(prev => ({
            ...prev,
            selected: id
        }));
    }

    const getAllMarkers = () => {

        let array = [];

        if (markers.employee) {
            array.push(markers.employee);
        }

        markers.reservations.map(m => {
            array.push(m);
        });

        markers.random.map(m => {
            array.push(m);
        });

        return array;
    }

    const getReservationMarker = id => {
        return markers.reservations.filter(marker => {
            return Number(marker.options.reservation_id) === Number(id);
        })[0];
    }

    const getEmployeeMarker = () => {
        return markers.employee;
    }

    const getPredictMarker = id => {
        let marker = getReservationMarker(id);

        if (marker) {
            if (marker.options.start_id) {
                return getReservationMarker(marker.options.start_id);
            }

            // if (marker.options.start_lat && marker.options.start_lon) {
            //     let newMarker = L.marker([marker.options.start_lat, marker.options.start_lon], {
            //         icon: markerIcon,
            //     });

            //     return newMarker;
            // }
        }

        // return getEmployeeMarker();

        return false;
    }

    const getReservation = id => {
        return state.data.find(r => Number(r.id) === Number(id));
    }

    const getRoute = (fromLatLon, toLatLon, callback) => {

        let key = String(fromLatLon.lat) + String(fromLatLon.lng) + String(toLatLon.lat) + String(toLatLon.lng);

        if (cc[key]) {
            return callback(cc[key]);
        }

        let waypoints = [];

        waypoints.push({ latLng: fromLatLon });
        waypoints.push({ latLng: toLatLon });

        router.route(waypoints, (err, routes) => {
            if (err) {
                console.log(err);
            } else {
                cc[key] = routes[0];

                return callback(routes[0]);
            }
        });

        return callback(null);
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>

            <div className={`${state.modal ? 'show' : ''} popup-primary`}>

                {state.loading
                    ?
                    <Skeleton
                        className="head"
                        height={50}
                    />
                    :
                    <h2 className="head">
                        {trans('random.uppcomingReservations')} {state.employee.name} {state.employee.lastname} {trans('random.uppcomingReservations2')}
                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleClose} />
                    </h2>
                }

                <div className="scrollable" style={{ maxHeight: '65vh' }}>
                    <div id="map-wrapper">

                        <div className="left">

                            {state.loading
                                ?
                                <Skeleton
                                    // height={590}
                                    style={{
                                        minHeight: '400px',
                                    }}
                                />
                                :
                                state.data.length === 0
                                    ?
                                    <div className="alert info">
                                        {trans('messages.noReservations')}
                                    </div>
                                    :
                                    <ul>
                                        {state.data.map((r, i) =>
                                            <li
                                                key={'r-' + r.id}
                                                onClick={() => handleSelect(r.id)}
                                                className={state.selected && state.selected === r.id ? 'active' : ''}
                                            >
                                                <span>
                                                    {i + 1}. {r.full_address}
                                                </span>
                                                <div className="extra">
                                                    <p>
                                                        <span>№:</span>
                                                        <span>{r.reference_code}</span>
                                                    </p>
                                                    <p>
                                                        <span>{trans('random.visitHour')}:</span>
                                                        <span>{r.visitperiod ? r.visitperiod.period : '-'}</span>
                                                    </p>
                                                    <p>
                                                        <span>{trans('random.client')}:</span>
                                                        <span>{r.customer.name} {r.customer.lastname}</span>
                                                    </p>
                                                    <p>
                                                        <span>{trans('random.phone')}:</span>
                                                        <span>{r.customer.phone}</span>
                                                    </p>
                                                    <p>
                                                        <span>{trans('random.category')}:</span>
                                                        <span>{r.appliancecategory && r.appliancecategory ? r.appliancecategory.name : '-'}</span>
                                                    </p>
                                                    <p>
                                                        <span>{trans('random.make')}:</span>
                                                        <span>{r.appliancebrand ? r.appliancebrand.name : '-'}</span>
                                                    </p>
                                                    <p>
                                                        <span>{trans('random.model')}:</span>
                                                        <span>{r.appliancemodel ? r.appliancemodel.name : '-'}</span>
                                                    </p>
                                                    {auth.module('gps') &&
                                                        <p>
                                                            <span>{trans('random.distanceTime')}:</span>
                                                            <span>{state.distances[Number(r.id)]
                                                                ?
                                                                state.distances[Number(r.id)].distance.toFixed(2) + ' ' + trans('random.km')
                                                                + ' / '
                                                                + Math.ceil(state.distances[Number(r.id)].duration) + ' ' + trans('random.min')
                                                                :
                                                                '- / -'}</span>
                                                        </p>
                                                    }
                                                    {auth.module('gps') &&
                                                        <p>
                                                            <span>{trans('random.remainingDistanceTime')}:</span>
                                                            <span style={{ whiteSpace: 'nowrap' }}>
                                                                {r.remaining_distance ? (r.remaining_distance.toFixed(2) + ' ' + trans('random.km')) : '-'}  / {r.remaining_time_to_visit ? (Math.ceil(r.remaining_time_to_visit) + ' ' + trans('random.min')) : '-'}
                                                            </span>
                                                        </p>
                                                    }
                                                    <p>
                                                        <span>{trans('random.status')}</span>
                                                        <span style={{ whiteSpace: 'nowrap' }}>
                                                            {r.end_work_at
                                                                ?
                                                                trans('random.endWorks')
                                                                :
                                                                r.start_work_at
                                                                    ?
                                                                    trans('random.startWorks')
                                                                    :
                                                                    r.is_employee_travels
                                                                        ?
                                                                        trans('random.travels')
                                                                        :
                                                                        trans('random.notTravels')
                                                            }
                                                        </span>
                                                    </p>
                                                </div>
                                            </li>
                                        )}
                                    </ul>

                            }
                        </div>

                        <div className="right">
                            <div id={'upcoming-reservations-map-' + mapId} className="map"></div>
                        </div>

                    </div>
                </div>

                <div className="footer align-center">
                    <button onClick={handleClose}>
                        {trans('buttons.close')}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default React.memo(forwardRef(UpcomingReservations), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});