import React, { useState, useImperativeHandle, forwardRef } from 'react';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import { trans } from '../../../../_providers/Translation';

function DNS(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
    });

    useImperativeHandle(ref, () => ({
        show: () => {
            handleShow();
        },
        hide: () => {
            handleHide();
        },
    }));

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const getDomainInfo = () => {
        return {
            __html: trans('random.websiteDomainInfo', {
                ns1: 'ns1-189-34.superdnsserver.net',
                ns2: 'ns2-189-34.superdnsserver.net'
            })
        }
    }


    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <div className={`${popup.modal ? 'show' : ''} popup-primary large`}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('random.DNSInfo')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <br />

                <div className="alert info" dangerouslySetInnerHTML={getDomainInfo()} />


                <div className="footer align-center">
                    <button className="button button-primary" onClick={handleHide}>
                        {trans('buttons.close')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(DNS);