import React, { useRef, useState, useEffect, useLayoutEffect, useCallback } from 'react';
import Api from '../../_helpers/Api';
import InfiniteScroll from 'react-infinite-scroller';
import { useAuthDataContext } from '../../_providers/Auth';
import loader from '../../assets/img/loader.svg';
import checkIcon from '../../assets/img/icons/check.png';
import { useMessagesContext } from '../../_providers/Messages';
import { useSocketContext } from '../../_providers/Socket';
import { trans } from '../../_providers/Translation';

let scrollInterval;

function MessagePanel(props) {
    const auth = useAuthDataContext();
    const messages = useMessagesContext();

    const width = 300;
    const margin = 50;
    const number = props.number || 0;
    const position = number * (width + margin) + margin;

    const ref = useRef(null);
    const formRef = useRef(null);

    const [state, setState] = useState({
        user: null,
        loading: true,
        list: [],
        pages: 0,
        count: 0,
        unread: 0,
        hasMore: true,
        initScroll: true,
        scroll: false,
        hidden: false
    });

    useEffect(() => {
        let el = ref.current;

        if (el) {
            el.addEventListener('scroll', e => {

                setState(prev => ({
                    ...prev,
                    initScroll: false
                }));

                if (el.scrollTop + el.offsetHeight === el.scrollHeight) {
                    console.log('scrolled to bottom');

                    clearInterval(scrollInterval);
                }
            });
        }

    }, [ref]);

    useEffect(() => {
        loadUser();
        loadUnread();
        loadData();
    }, [props.user]);

    useEffect(() => {

        if (state.loading) {
            return;
        }

        messages.fromSocket.map(m => {

            let condition = Number(m.author.id) === Number(props.user.id) && m.author.type === props.user.type;

            if (m.reservation_id) {
                condition = condition && Number(m.reservation_id) === Number(props.user.reservationId);
            }

            if (condition) {
                let exists = inList(m.id);

                if (!exists) {
                    setState(prev => ({
                        ...prev,
                        list: prev.list.concat(m),
                        initScroll: Math.random().toString(),
                    }));

                    if (!state.hidden) {
                        markAsRead(m.id);
                    } else {
                        loadUnread();
                    }
                }
            }
        });
    }, [messages.fromSocket]);

    useEffect(() => {
        // messages.read.map(m => {
        //     let newData = [...state.list];
        //     let index = findIndex(m.id);

        //     if (newData[index]) {
        //         newData[index].read_at = m.read_at;

        //         setState(prev => ({
        //             ...prev,
        //             list: newData
        //         }));
        //     }
        // });

        setState(prev => ({
            ...prev,
            list: prev.list.map(msg => {
                messages.read.map(m => {
                    if (Number(msg.id) === Number(m.id)) {
                        msg.read_at = m.read_at;
                    }
                })

                return msg;
            })
        }));
    }, [messages.read]);

    // маркира непрочетените съобщения като прочетени
    // при разпъване на панела
    useEffect(() => {
        if (state.hidden) {
            return;
        }

        const unread = [...state.list].filter(m => m.read_at === null);

        unread.map(m => {
            markAsRead(m.id);
        });
    }, [state.hidden]);

    useEffect(() => {
        if (state.initScroll) {
            // scrollInterval = setInterval(() => {
            scrollToBottom();
            // }, 100);
        }
    }, [state.initScroll]);

    const scrollToBottom = () => {
        let el = ref.current;

        if (el) {
            el.scrollTop = el.scrollHeight;
        }
    }

    const shouldScroll = useCallback(() => {
        let el = ref.current;

        if (el) {
            if (el.scrollTop + el.offsetHeight === el.scrollHeight) {
                return true;
            }
        }

        console.log(el.scrollTop + el.offsetHeight, el.scrollHeight)

        return false;
    }, [ref, state.list])


    const loadUser = () => {
        let url = 'profiles/find?id=' + props.user.id + '&type=' + props.user.type;

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    user: res.data
                }));
            });
    }

    const loadUnread = () => {
        let url = 'messages/unread-from-user?subject_id=' + props.user.id + '&subject_type=' + props.user.type + '&reservation_id=' + props.user.reservationId;

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    unread: res.data
                }));
            });
    }

    const loadData = (page = 1) => {
        let url = 'messages/get?page=' + page + '&subject_id=' + props.user.id + '&subject_type=' + props.user.type + '&reservation_id=' + props.user.reservationId;

        Api.get(url)
            .then(res => {

                messages.loadCount();
                loadUnread();

                let filtered = res.data.items.filter(m => {
                    if (inList(m.id)) {
                        return false;
                    }

                    return true;
                });

                setState(prev => ({
                    ...prev,
                    list: [...filtered, ...prev.list],
                    pages: res.data.pages,
                    hasMore: res.data.has_more,
                    loading: false,
                }));

                if (Number(page) === 1) {
                    scrollToBottom();
                }

            });

    }

    const markAsRead = id => {
        Api.post('messages/mark-as-read', {
            id: id
        }).then(() => {
            setState(prev => ({
                ...prev,
                list: prev.list.map(m => {
                    if (m.id === id) {
                        m.read_at = true;
                    }

                    return m;
                })
            }));

            messages.loadCount();
            loadUnread();
        });
    }

    const inList = id => {
        let exists = state.list.filter(o => {
            return Number(o.id) === Number(id);
        })[0];

        return exists;
    }

    const findIndex = id => {
        return state.list.findIndex(m => {
            return Number(id) === Number(m.id);
        });
    }

    const handleClose = () => {
        messages.closeChat(props.user.chatId);
    }

    const handleToggle = () => {
        setState(prev => ({
            ...prev,
            hidden: !prev.hidden
        }));
    }

    const handleChangeText = e => {
        messages.whisper(props.user.id, props.user.type, auth.user());
    }

    const handleClickText = e => {
        if (!e.shiftKey && e.key === 'Enter') {
            sendMessage(e.target.closest('form'));
        }
    }

    const handleSubmit = e => {
        e.preventDefault();

        sendMessage(e.target);
    }

    const sendMessage = form => {
        let data = new FormData(form);

        data.append('receiver_id', props.user.id);
        data.append('receiver_type', props.user.type);

        if (props.user.reservationId) {
            data.append('reservation_id', props.user.reservationId);
        }

        form.reset();

        Api.post('messages/send', data)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    list: prev.list.concat(res.data),
                    initScroll: Math.random().toString(),
                }));

                messages.setLastMessage(res.data);

            });
    }

    const createMarkup = html => {
        return {
            __html: String(html).replaceAll("\n", "<br/>")
        }
    }

    return (
        <div className={['message-panel', state.hidden ? 'hidden' : ''].join(' ')} style={{ right: position }}>
            <div className="head" onClick={handleToggle}>
                {state.unread > 0
                    ?
                    <span className="count">
                        {state.unread}
                    </span>
                    :
                    ''
                }

                <span className="name">
                    {state.user
                        ? `${state.user.name} ${state.user.lastname}`
                        : ''
                    }
                </span>
                <span className="close" onClick={handleClose}>
                    X
                </span>
            </div>

            <div className="body" ref={ref}>
                {state.loading
                    ?
                    <img src={loader} alt="loading" />
                    :
                    <InfiniteScroll
                        element="ul"
                        initialLoad={false}
                        pageStart={1}
                        loadMore={loadData}
                        hasMore={state.hasMore}
                        loader={<img key="loader" src={loader} alt="loading" />}
                        useWindow={false}
                        isReverse={true}
                        getScrollParent={() => ref.current}
                    >
                        {state.list.map(m =>
                            <div
                                key={m.id}
                                className={['content', (m.author.id !== auth.id() || m.author.type !== auth.type()) ? 'reverse' : ''].join(' ')}
                            >
                                <div className="wrap">
                                    <div className="avatar">
                                        <img src={m.author.avatar} />
                                    </div>
                                    <div className="cloud" dangerouslySetInnerHTML={createMarkup(m.message)} />

                                </div>
                                <div className="time">
                                    {(m.author.id === auth.id() && m.author.type === auth.type()) && m.read_at
                                        ? <img src={checkIcon} title="прочетено" />
                                        : ''
                                    }
                                    <span className="timer" data-date={m.created_at}>
                                        {m.published}
                                    </span>
                                </div>
                            </div>
                        )}
                    </InfiniteScroll>
                }

                {messages.whispers[props.user.fullId]
                    ?
                    <div className="whisper">{messages.whispers[props.user.fullId].name} пише...</div>
                    :
                    ''
                }

            </div>

            <div className="input">
                <form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
                    <textarea
                        type="text"
                        name="message"
                        placeholder={trans("random.writeMessage")}
                        defaultValue=""
                        onChange={handleChangeText}
                        onKeyUp={handleClickText}
                    />
                </form>
            </div>
        </div>
    )
}

export default MessagePanel;