import React, { useCallback, useEffect, useRef, useState } from "react";
import L from "leaflet";
import { useAuthDataContext } from '../../../../_providers/Auth';
import { trans } from '../../../../_providers/Translation';

//images
import markerImg from '../../../../assets/img/leeflet/marker-icon.png';
import greenMarkerImg from '../../../../assets/img/leeflet/worker_green.png';
import orangeMarkerImg from '../../../../assets/img/leeflet/worker_orange.png';
import grayMarkerImg from '../../../../assets/img/leeflet/worker_gray.png';
import redMarkerImg from '../../../../assets/img/leeflet/worker_red.png';
import greenMarker2Img from '../../../../assets/img/leeflet/worker_green_bg.png';
import orangeMarker2Img from '../../../../assets/img/leeflet/worker_orange_bg.png';
import redMarker2Img from '../../../../assets/img/leeflet/worker_red_bg.png';
import Api from "../../../../_helpers/Api";
import { useHistory, useLocation } from "react-router-dom";
import '../../../../assets/css/modals/employees.css';
import { useMessagesContext } from "../../../../_providers/Messages";
import { useSocketContext } from "../../../../_providers/Socket";
import '../../../../assets/Leaflet.fullscreen';
import '../../../../assets/css/leaflet.fullscreen.css';

const style = {
    width: '100%',
    height: '270px'
};

const markerIcon = L.icon({
    iconUrl: markerImg,
    // shadowUrl: 'leaf-shadow.png',

    iconSize: [25, 41], // size of the icon
});

const greenMarker = L.icon({
    iconUrl: greenMarkerImg,
    // shadowUrl: 'leaf-shadow.png',

    iconSize: [56, 56], // size of the icon
});

const orangeMarker = L.icon({
    iconUrl: orangeMarkerImg,
    // shadowUrl: 'leaf-shadow.png',

    iconSize: [56, 56], // size of the icon
});

const grayMarker = L.icon({
    iconUrl: grayMarkerImg,
    // shadowUrl: 'leaf-shadow.png',

    iconSize: [56, 56], // size of the icon
});

const redMarker = L.icon({
    iconUrl: redMarkerImg,
    // shadowUrl: 'leaf-shadow.png',

    iconSize: [56, 56], // size of the icon
});

const greenMarker2 = L.icon({
    iconUrl: greenMarker2Img,
    // shadowUrl: 'leaf-shadow.png',

    iconSize: [56, 56], // size of the icon
});

const orangeMarker2 = L.icon({
    iconUrl: orangeMarker2Img,
    // shadowUrl: 'leaf-shadow.png',

    iconSize: [56, 56], // size of the icon
});

const redMarker2 = L.icon({
    iconUrl: redMarker2Img,
    // shadowUrl: 'leaf-shadow.png',

    iconSize: [56, 56], // size of the icon
});


let listen = false;

function LocationMap(props) {

    const auth = useAuthDataContext();
    const messages = useMessagesContext();
    const listener = useSocketContext();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const mapRef = useRef(null);

    const [map, setMap] = useState(null);
    const [partner, setPartner] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [employeesMarkers, setEmployeesMarkers] = useState([]);
    const [isMarkerOpen, setIsMarkerOpen] = useState(false);
    const [layerGroup, setLayerGroup] = useState(null);
    const [flyToBounds, setFlyToBounds] = useState(true);

    const [state, setState] = useState({
        refresh: false
    });

    useEffect(() => {

        loadPartner();
        loadEmployees();

        let m = L.map("location-map", {
            center: [42.70060440808085, 23.318481445312504],
            zoom: 10,
            layers: [
                L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                })
            ],
            fullscreenControl: true,
        });

        var legend = L.control({ position: 'bottomright' });

        legend.onAdd = function (map) {

            let div = L.DomUtil.create('div', 'legend');

            let statuses = [
                {
                    name: trans('random.free'),
                    class: 'free'
                },
                {
                    name: trans('random.busy'),
                    class: 'busy'
                },
                {
                    name: trans('random.gpsoff'),
                    class: 'gps'
                },
                {
                    name: trans('random.autogps'),
                    class: 'auto'
                },
                {
                    name: trans('random.manualgps'),
                    class: 'manual'
                }
            ];

            statuses.map(s => {
                div.innerHTML += '<p><span class="' + s.class + '"></span>' + s.name + '</p> ';
            });


            return div;
        };

        legend.addTo(m);

        // m.on('click', handleMapClick);

        setLayerGroup(L.layerGroup().addTo(m));

        setMap(m);

    }, []);

    useEffect(() => {
        if (state.refresh) {
            loadEmployees();
        }
    }, [state.refresh]);

    const loadCallback = e => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime()
        }));
    };

    useEffect(() => {

        if (!partner || listen) {
            return;
        }

        listener.private(`partners.${partner.id}`)
            .listen('EmployeePositionChanged', loadCallback);

        listen = true;

        return () => {
            console.log('stop listening...');

            setState(prev => ({
                ...prev,
                refresh: false
            }));

            listener.connector.socket.removeListener('EmployeePositionChanged', loadCallback);
            listen = false;
        }
    }, [partner]);

    useEffect(() => {
        if (map && partner) {
            if (partner.lat && partner.lon) {
                let marker = L.circle([partner.lat, partner.lon], {
                    color: 'red',
                    fillColor: '#f03',
                    fillOpacity: 0.5,
                    radius: 50
                }).addTo(map);

                marker.bindPopup(partner.name)
            }
        }
    }, [map, partner]);

    useEffect(() => {
        if (map) {
            layerGroup.clearLayers();

            let markers = [];

            if (employees.length) {
                employees.map(e => {

                    if (e.lat && e.lon) {

                        let icon;

                        if (!e.gps) {
                            if (e.allow_auto_gps_changes) {
                                icon = redMarker;
                            } else {
                                icon = redMarker2;
                            }
                        } else {
                            if (e.currentreservation) {
                                if (e.allow_auto_gps_changes) {
                                    icon = orangeMarker;
                                } else {
                                    icon = orangeMarker2
                                }
                            } else {
                                if (e.allow_auto_gps_changes) {
                                    icon = greenMarker;
                                } else {
                                    icon = greenMarker2;
                                }
                            }
                        }

                        let marker = L.marker([e.lat, e.lon], {
                            icon: icon
                        }).addTo(layerGroup);

                        markers.push(marker);

                        let content = L.DomUtil.create('div');

                        let text = '';

                        text += `<div class="head">`;

                        text += `
                            <span class="label ${e.currentreservation ? 'busy' : 'free'}">
                                ${e.currentreservation ? trans('random.busy') : trans('random.free')}
                            </span>
                        `;

                        text += `
                            <span class="name">
                                ${e.name} ${e.lastname}
                            </span>
                        `;

                        text += `
                            <span class="chat" data-id="${e.id}" data-type="${e.type}"></span>
                        `;

                        text += `</div>`;

                        if (e.currentreservation) {
                            text += `
                                <div class="body">
                                    <p>${e.currentreservation.full_address}</p>
                                </div>
                            `;
                        }

                        text += `
                            <a 
                                href="javascript:void(0)" 
                                class="show-upcoming" 
                                data-id="${e.id}"
                            >
                                ${trans('buttons.viewReservations')}
                            </a>
                        `;

                        content.innerHTML = text;

                        // L.DomEvent.addListener(content.querySelector('.chat'), 'click', e => {
                        //     handleStartChat(e);
                        // });

                        L.DomEvent.addListener(content.querySelector('.show-upcoming'), 'click', e => {
                            handleShowUpcomingReservations(e);
                        });

                        let popup = marker.bindPopup(content, {
                            autoClose: false,
                            closeOnClick: false,
                            className: 'custom'
                        });

                        popup.on('popupopen', e => {
                            setIsMarkerOpen(Math.random().toString());
                        })

                        // marker.openPopup();
                    }
                });

                if (markers.length && flyToBounds) {
                    let group = new L.featureGroup(markers);

                    map.flyToBounds(group.getBounds());
                }

                setEmployeesMarkers(markers);
            }
        }
    }, [map, employees]);

    const loadPartner = () => {
        Api.get('partners/find?id=' + auth.user().partner_id)
            .then(res => {
                setPartner(res.data);
            });
    }

    const loadEmployees = () => {
        if (!auth.module('gps')) {
            return;
        }

        Api.get('partners/employees/technicians/location')
            .then(res => {
                setEmployees(res.data);
                setFlyToBounds(false);
            });
    }


    const handleStartChat = useCallback(e => {
        let t = e.currentTarget;
        let id = t.getAttribute('data-id');
        let type = t.getAttribute('data-type');

        messages.openChat(id, type);
    }, [messages]);

    useEffect(() => {
        document.querySelectorAll('#location-map .chat').forEach(el => {
            el.addEventListener('click', handleStartChat);
        });

        return () => {
            document.querySelectorAll('#location-map .chat').forEach(el => {
                el.removeEventListener('click', handleStartChat);
            });
        };
    }, [employeesMarkers, isMarkerOpen, handleStartChat]);

    const handleShowUpcomingReservations = e => {
        let t = e.currentTarget;
        let id = t.getAttribute('data-id');

        searchParams.set('show_upcoming_reservations', id);

        let url = '?' + searchParams.toString();

        history.replace(url);
    }

    const handleMapClick = e => {
        console.log(e.latlng);
    }

    return (
        <>
            <div ref={mapRef} id="location-map" style={style}></div>
        </>
    )
}

export default LocationMap;
