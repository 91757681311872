import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import Success from '../../../modals/messages/Success';
import Error from '../../../modals/messages/Error';
import deleteIcon from '../../../../assets/img/icons/delete-red.png';

function AddOrEdit(props, ref) {

    const inputFileRef = useRef(null);

    const initialData = {
        blob: null,
        filename: null,
        file_url: null
    }

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        loading: false,
        data: initialData,
        id: null,
        edit: false,
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            handleShow(id);
        },
        hide: () => {
            handleHide();
        }
    }));

    useEffect(() => {
        if (popup.edit) {
            loadData();
        }
    }, [popup.edit]);

    const loadData = () => {
        if (!popup.id) {
            return;
        }

        Api.get(`contractors/files/find?id=${popup.id}`)
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    data: res.data,
                }));
            });
    }

    const handleShow = (id = null) => {
        setPopup(prev => ({
            ...prev,
            id: id,
            edit: id ? new Date() : null,
        }));

        show();
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            id: null,
            data: null,
            edit: false,
        }));

        setValidations(null);

        hide().then(() => {
            if (typeof props.onClose === 'function') {
                props.onClose();
            }
        });

    }

    const show = async () => {
        return new Promise((resolve, reject) => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
            }));

            setTimeout(() => {
                setPopup(prev => ({
                    ...prev,
                    overlay: true,
                    modal: true
                }));

                return resolve();
            }, 50);
        });
    }

    const hide = async () => {
        return new Promise((resolve, reject) => {
            setPopup(prev => ({
                ...prev,
                modal: false,
                loading: false,
            }));

            setTimeout(() => {
                setPopup(prev => ({
                    ...prev,
                    overlay: false,
                    modal: false,
                }));

                return resolve();
            }, 50);
        });
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'contractors/files/' + (popup.id ? 'edit' : 'add');

        let data = new FormData(e.target);

        data.append('contractor_id', props.contractorId);

        if (popup.id) {
            data.append('id', popup.id);
        }

        if (popup.data?.blob) {
            data.append('file', popup.data.blob);
        }

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (popup.edit) {
                    props.handleUpdate(res.data)
                } else {
                    props.handleCreate(res.data);
                }

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let file = e.target.files[0];

        if (!file) {
            return;
        }

        let url = URL.createObjectURL(file);

        file = {
            blob: file,
            filename: file.name,
            file_url: url
        };

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                ...file
            }
        }));

        inputFileRef.current.value = '';
    }

    const handleDetach = () => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                ...initialData
            }
        }));
    }

    return (
        <>

            <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
                <form className={`${popup.modal ? 'show' : ''} popup-primary small`} onSubmit={handleSave}>

                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />

                        {popup.edit ? trans('headings.editFile') : trans('headings.addFile')}

                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.name')}
                            </label>
                            <input
                                value={popup.data && popup.data.name ? popup.data.name : ''}
                                type="text"
                                name="name"
                                onChange={handleInputChange}
                                className={validations && validations.name ? 'validate' : ''}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            {popup.data?.file_url &&
                                <div className="row" style={{ marginBottom: '10px', alignItems: 'center' }}>
                                    <div className="col" style={{ width: 'calc(100% - 50px)' }}>
                                        <label style={{ display: 'block', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                            <a href={popup.data.file_url} target="_blank">
                                                {popup.data.filename || 'n/a'}
                                            </a>
                                        </label>
                                    </div>
                                    {/* <div className="col auto" style={{ width: '30px' }}>
                                        <img onClick={e => handleDetach()} src={deleteIcon} style={{ cursor: 'pointer' }} />
                                    </div> */}
                                </div>
                            }

                            <button className={['button-small', validations && validations.file ? 'validate' : ''].join(' ')} type="button" onClick={handleShowAttach}>
                                {trans('buttons.uploadfile')}
                            </button>

                            <input ref={inputFileRef} type="file" onChange={handleAttach} hidden />

                        </div>
                    </div>

                    <div className="footer align-center">
                        {popup.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="button" onClick={handleHide}>
                                    {trans('buttons.cancel')}
                                </button>
                                <button className="button button-primary" type="submit">
                                    {trans('buttons.save')}
                                </button>
                            </>
                        }
                    </div>
                </form>
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit);