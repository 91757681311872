import React, { useState } from 'react';
import Map from "./LocationMap";
import { fromInfo, trans } from '../../../../_providers/Translation';

//styles
import '../../../../assets/css/leeflet.css';
import Help from '../../../partials/Help';

function Location() {

    return (
        <div className="col full-width">
            <h3>
                {trans('random.employeesLocation')} <Help modalStyle={{ maxWidth: '800px' }}> {fromInfo('dashboard.map')}</Help>
            </h3>
            <div className="location">
                <div>
                    <Map />
                </div>
            </div>
        </div>
    )
}

export default Location;