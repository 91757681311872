import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../../../_helpers/Api';
import MultiSelect from "react-multi-select-component";

//images
import docIcon from '../../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../../assets/img/icons/close.png';
import loader from '../../../../../../assets/img/loader.svg';
import Ckeditor from '../../../../../partials/Ckeditor';
import { trans } from '../../../../../../_providers/Translation';

function MobileApp(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: null,
        loading: false,
        onSave: null
    });

    const [reservationStatuses, setReservationStatuses] = useState([]);
    const [selectedReservationStatuses, setSelectedReservationStatuses] = useState([]);

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: data => {
            setPopup(prev => ({
                ...prev,
                loading: false,
                data: data,
            }));

            const selectedStatuses = [];

            data.strict_reservation_parts_extended.map(m => {
                selectedStatuses.push({
                    label: m.name,
                    value: m.id
                });
            });

            setSelectedReservationStatuses(selectedStatuses);

            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    useEffect(() => {
        if (!popup.overlay) {
            return;
        }

        Api.get('partners/final-statuses')
            .then(res => {
                if (Array.isArray(res.data)) {
                    const data = [];

                    res.data.map(m => {
                        data.push({
                            label: m.name,
                            value: m.id
                        });
                    });

                    setReservationStatuses(data);
                }
            });
    }, [popup.overlay])

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'partners/settings/edit-app';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave();
                }

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = (e, onlyDigits = false) => {
        let name = e.target.name;
        let val = e.target.value;

        if (onlyDigits) {
            val = val.replace(/[^0-9]/g, '');
        }

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleReservationStatusChange = data => {
        setSelectedReservationStatuses(data);
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary medium`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.mobileApp')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('settings.mobileApp.strictReservationFinalStatus')}
                        </label>
                        <select
                            name="strict_reservation_final_status"
                            value={popup.data && typeof popup.data.strict_reservation_final_status !== 'undefined' ? popup.data.strict_reservation_final_status : 0}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('settings.mobileApp.strictReservationImages')}
                        </label>
                        <select
                            name="strict_reservation_images_count"
                            value={popup.data && popup.data.strict_reservation_images_count > 0 ? 1 : 0}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                    <div className="col">
                        <label>
                            {trans('labels.count')}
                        </label>

                        <input
                            type="number"
                            step="1"
                            min="0"
                            name="strict_reservation_images_count"
                            value={popup.data && popup.data.strict_reservation_images_count ? popup.data.strict_reservation_images_count : ''}
                            className={validations && validations.strict_reservation_images_count ? 'validate' : ''}
                            onChange={handleInputChange}
                            disabled={Number(popup.data?.strict_reservation_images_count) === 0}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('settings.mobileApp.strictReservationParts')}
                        </label>
                        <MultiSelect
                            options={reservationStatuses}
                            value={selectedReservationStatuses}
                            onChange={handleReservationStatusChange}
                            hasSelectAll={false}
                            disableSearch={true}
                            // className="dropdown-top"
                            overrideStrings={{
                                "selectSomeItems": trans('labels.choose'),
                                "allItemsAreSelected": trans('labels.allSelected'),
                                "selectAll": trans('labels.selectAll'),
                                "search": trans('labels.search')
                            }}
                        />

                        {selectedReservationStatuses.map(s =>
                            <input key={'app-' + s.value} type="hidden" name="strict_reservation_parts[]" value={s.value} />
                        )}
                    </div>

                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('settings.mobileApp.strictReservationReport')}
                        </label>
                        <select
                            name="strict_reservation_report"
                            value={popup.data && popup.data.strict_reservation_report > 0 ? 150 : 0}
                            onChange={handleInputChange}
                        >
                            <option value="150">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                    <div className="col">
                        <label>
                            {trans('labels.characters')}
                        </label>

                        <input
                            type="number"
                            step="1"
                            min="0"
                            name="strict_reservation_report"
                            value={popup.data && popup.data.strict_reservation_report ? popup.data.strict_reservation_report : ''}
                            className={validations && validations.strict_reservation_report ? 'validate' : ''}
                            onChange={handleInputChange}
                            disabled={Number(popup.data?.strict_reservation_report) === 0}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('settings.mobileApp.strictReservationModel')}
                        </label>
                        <select
                            name="strict_reservation_model"
                            value={popup.data && popup.data.strict_reservation_model > 0 ? 10 : 0}
                            onChange={handleInputChange}
                        >
                            <option value="10">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                    <div className="col">
                        <label>
                            {trans('labels.characters')}
                        </label>

                        <input
                            type="number"
                            step="1"
                            min="0"
                            name="strict_reservation_model"
                            value={popup.data && popup.data.strict_reservation_model ? popup.data.strict_reservation_model : ''}
                            className={validations && validations.strict_reservation_model ? 'validate' : ''}
                            onChange={handleInputChange}
                            disabled={Number(popup.data?.strict_reservation_model) === 0}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('settings.mobileApp.strictReservationPayments')}
                        </label>
                        <select
                            name="strict_reservation_payments"
                            value={popup.data && typeof popup.data.strict_reservation_payments !== 'undefined' ? popup.data.strict_reservation_payments : 0}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('settings.mobileApp.allowReservationPaymentChange')}
                        </label>
                        <select
                            name="allow_reservation_payment_change"
                            value={popup.data && typeof popup.data.allow_reservation_payment_change !== 'undefined' ? popup.data.allow_reservation_payment_change : 0}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('settings.mobileApp.strictReservationInvoiceSending')}
                        </label>
                        <select
                            name="strict_reservation_invoice_sending"
                            value={popup.data && typeof popup.data.strict_reservation_invoice_sending !== 'undefined' ? popup.data.strict_reservation_invoice_sending : 0}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('settings.mobileApp.strictTodoCompletion')}
                        </label>
                        <select
                            name="strict_todo_completion"
                            value={popup.data && typeof popup.data.strict_todo_completion !== 'undefined' ? popup.data.strict_todo_completion : 0}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('settings.mobileApp.strictReservationCheckFromDate')}
                        </label>
                        <input
                            type="date"
                            name="strict_reservation_check_from_date"
                            value={popup.data && popup.data.strict_reservation_check_from_date ? popup.data.strict_reservation_check_from_date : ''}
                            onChange={handleInputChange}
                        />

                    </div>
                </div>

                <br />

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(MobileApp);