import React, { useEffect, useState, useRef } from 'react';
import Table from './partials/Table';
import Api from '../../../_helpers/Api';
import Pagination from '../../Pagination';
import { useHistory, useLocation } from 'react-router-dom';
import { trans } from '../../../_providers/Translation';

//modals
import AddOrEdit from './partials/AddOrEdit';
import Question from '../../modals/messages/Question';
import Filter from './partials/Filter';

let timeout;

function Partners() {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const modalAddOrEditRef = useRef(null);
    const modalQuestionRef = useRef(null);
    const removeLicenseModalRef = useRef(null);

    const [partners, setPartners] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
    });

    let page = searchParams.get('page') || 1;
    let search = searchParams.get('search') || '';
    let active = searchParams.get('active') || '';
    let superpartner = searchParams.get('superpartner') || '';

    useEffect(() => {
        setPartners(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                search: searchParams.get('search') || '',
                active: searchParams.get('active') || '',
                superpartner: searchParams.get('superpartner') || '',
            },
            update: new Date().getTime()
        }));
    }, [page, search, active, superpartner]);

    useEffect(() => {

        if (partners.setFilter) {

            searchParams.set('page', partners.page);

            Object.entries(partners.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            history.push('?' + searchParams.toString());
        }

    }, [partners.setFilter]);


    useEffect(() => {
        if (partners.update) {
            loadPartners()
        }
    }, [partners.update])

    //read
    const loadPartners = () => {

        setPartners(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'partners/all?page=' + partners.page;

        Object.entries(partners.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setPartners(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }));
            }).catch(err => {
                setPartners(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add();
    }

    const handleCreate = partner => {

        // let data = partners.data.concat(partner);

        // setPartners(prev => ({
        //     ...prev,
        //     data: data
        // }));

        setPartners(prev => ({
            ...prev,
            update: Math.random().toString()
        }));

    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id);
    }

    const handleUpdate = partner => {

        // let data = partners.data.map(i => {
        //     if (i.id == partner.id) i = partner;
        //     return i;
        // });

        // setPartners(prev => ({
        //     ...prev,
        //     data: data
        // }));

        handleRefresh();
    }

    // delete
    const handleDelete = id => {
        let modal = modalQuestionRef.current;

        modal.open(id);
        modal.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleDestroy = id => {
        Api.post('partners/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                // let data = partners.data.filter(i => {
                //     return i.id !== id;
                // });
                handleRefresh();
            }
        });
    }

    const handleRemoveLicense = id => {
        let modal = removeLicenseModalRef.current;

        modal.open(id);
        modal.onSuccess(() => {
            removeLicense(id);
        });
    }

    const removeLicense = id => {
        Api.post('partners/remove-license', {
            id: id
        }).then(res => {
            if (res.data.success) {
                // let data = partners.data.filter(i => {
                //     return i.id !== id;
                // });
                handleRefresh();
            }
        });
    }

    const handleSearch = (key, val) => {

        clearTimeout(timeout);

        setPartners(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setPartners(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);

    }

    const handleRefresh = () => {
        setPartners(prev => ({
            ...prev,
            update: Math.random().toString()
        }));
    }

    const handlePage = page => {
        setPartners(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }


    return (
        <>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}

            />

            <Question
                ref={removeLicenseModalRef}
                mainMessage={trans('headings.question15')}
            />


            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.partners')}
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.partners')}
                        </p>
                    </div>
                    <div className="right">
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.add')}
                        </button>
                    </div>
                </div>

                <Filter
                    handleSearch={handleSearch}
                    filter={partners.filter}
                />

                <Table
                    partners={partners.data}
                    loading={partners.loading}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleRefresh={handleRefresh}
                    handleRemoveLicense={handleRemoveLicense}
                />

                <Pagination
                    pages={partners.pages}
                    page={partners.page}
                    handlePage={handlePage}
                />

            </div>
        </>
    )
}

export default Partners;