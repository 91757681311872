import React, { useEffect } from 'react';
import Options from './OptionsVanUsed';
import { Link } from 'react-router-dom';
import notFound from '../../../../assets/img/not-found.png'
import ReactTooltip from 'react-tooltip';
import { trans } from '../../../../_providers/Translation';

function TableVanUsed(props) {

    return (

        props.parts.length === 0
            ?
            <div className="not-found">
                <img src={notFound} alt="Not Found" />
                <p>
                    {trans('messages.noResultsFound')}.
                </p>
            </div>
            :
            <>
                <div className="table-scroller">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>{trans('tables.reservation')}</th>
                                <th>{trans('labels.van')}</th>
                                <th>{trans('tables.name')}</th>
                                <th>{trans('tables.address')}</th>
                                <th>{trans('tables.partNumber')}</th>
                                <th className="center">{trans('tables.quantity')}</th>
                                <th>{trans('tables.grossPrice')}</th>
                                <th>{trans('tables.row')}</th>
                                <th>{trans('tables.box')}</th>
                                <th>{trans('tables.usedOn')}</th>
                                <th className="center">{trans('tables.image')}</th>
                                <th className="options">{trans('tables.options')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.parts.map(p =>
                                <tr key={'p-' + p.id}>
                                    <td>
                                        {p.id}
                                    </td>
                                    {/* <td>{p.part.category.name}</td> */}

                                    <td>
                                        {p.reservation ?
                                            <Link
                                                to={`/reservations/edit/${p.reservation.id}?tab=parts`}
                                                className="btn"
                                                style={{ background: p.reservation.color, color: p.reservation.text_color }}
                                            >
                                                {p.reservation.reference_code} (ID: {p.reservation_id})
                                            </Link>
                                            :
                                            '-'}
                                    </td>

                                    <td>
                                        {p.storage && p.storage.van
                                            ?
                                            `${p.storage.van.reg_no} ${p.storage.van.employee ? `(${p.storage.van.employee.name} ${p.storage.van.employee.lastname})` : ''}`
                                            :
                                            ''
                                        }
                                    </td>

                                    <td>
                                        {p.storage.part.name}
                                    </td>

                                    <td>
                                        {p.reservation
                                            ?
                                            p.reservation.full_address
                                            :
                                            ''
                                        }
                                    </td>

                                    <td>
                                        {p.storage.part.number}
                                    </td>
                                    <td className="center">
                                        {p.quantity}
                                    </td>

                                    <td>{p.gross_price_format} {p.currency.name}</td>

                                    <td>
                                        {p.shelve}
                                    </td>
                                    <td>
                                        {p.box}
                                    </td>

                                    <td>{p.used_date_dmy}</td>

                                    <td className="center">
                                        <img data-tip data-for={'img-' + p.id} src={p.storage.picture} alt="" height={25} />
                                        <ReactTooltip
                                            id={'img-' + p.id}
                                            effect="solid"
                                            border={true}
                                            borderColor="#ccc"
                                            backgroundColor="white"
                                            arrowColor="white"
                                        >
                                            <img src={p.storage.picture} alt="" style={{ maxHeight: '200px' }} />
                                        </ReactTooltip>
                                    </td>

                                    <td>
                                        <Options
                                            id={p.id}
                                            handleHistory={props.handleHistory}
                                            handleBackInStock={props.handleBackInStock}
                                            handleBackInStockFromStorage={props.handleBackInStockFromStorage}
                                            handleHideBackInStock={props.handleHideBackInStock}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </div>

            </>
    )
}

export default TableVanUsed;