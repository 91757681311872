import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Api from '../../../../_helpers/Api';
import Pagination from '../../../Pagination';
import loader from '../../../../assets/img/loader.svg';
import NoDataFound from '../../../partials/NoDataFound';
import Options from './Options';
import Filter from './Filter';
import checkIcon from '../../../../assets/img/icons/check.png';
import { trans } from '../../../../_providers/Translation'
import Question from '../../../modals/messages/Question';
import AddOrEdit from './AddOrEdit';
import moment from 'moment';
import Tabs from './Tabs';

let timeout;

function Index(props, ref) {

    const params = useParams();
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const status = searchParams.get('status') || '';
    // const date = searchParams.get('date') || '';
    // const deadline = searchParams.get('deadline') || '';
    const search = searchParams.get('search') || '';

    const tabs = ['active', 'deleted'];

    const modalRef = useRef(null)
    const deleteModalRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        page: 1,
        data: [],
        total: 0,
        pages: 0,
        filter: {

        },
        setFilter: false,
        update: false,
    });

    useImperativeHandle(ref, () => ({
        add: () => {
            handleAdd();
        }
    }));

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                status: searchParams.get('status') || tabs[0],
                // date: date,
                // deadline: deadline,
                search: search
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    // useEffect(() => {
    //     console.log(state.filter);
    // }, [state.filter]);

    useEffect(() => {
        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'contractors/files?contractor_id=' + params.id + '&page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {

                if (res.data.pages > 0 && state.page > res.data.pages) {
                    return handlePage({
                        selected: res.data.pages - 1
                    });
                }

                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                    loading: false
                }));

            });
    }

    const refreshData = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    // create
    const handleAdd = () => {
        modalRef.current.add();
    }

    const handleCreate = () => {
        setState(prev => ({
            ...prev,
            update: Math.random().toString()
        }))
    }

    // update
    const handleEdit = id => {
        modalRef.current.edit(id)
    }

    const handleUpdate = row => {

        let updated = state.data.map(i => {
            if (i.id == row.id) {
                i = row
            }
            return i
        });

        setState(prev => ({
            ...prev,
            data: updated,
        }))
    }

    // delete
    const handleDestroy = id => {

        let url = 'contractors/files/delete';

        Api.post(url, {
            id: id
        }).then(res => {
            if (res.data.success) {
                refreshData();
            }
        })
    }

    // Question modal
    const handleDelete = id => {
        deleteModalRef.current.open(id);

        deleteModalRef.current.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleRestore = id => {

        let url = 'contractors/files/restore';

        Api.post(url, {
            id: id
        }).then(res => {
            if (res.data.success) {
                refreshData();
            }
        })
    }

    //Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
            page: 1,
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: Math.random().toString() }));
    }


    const handleTab = val => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                status: val,
            },
            page: 1,
            setFilter: Math.random().toString(),
            loading: true
        }))
    }

    return (
        <>

            <AddOrEdit
                ref={modalRef}
                contractorId={params.id}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={deleteModalRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
            />

            <Filter
                filter={state.filter}
                handleSearch={handleSearch}
            />

            <Tabs
                tab={state.filter.status}
                handleTab={handleTab}
            />

            {state.loading
                ?
                <img src={loader} alt="loading" />
                :
                state.data.length === 0
                    ?
                    <NoDataFound />
                    :
                    <>
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '40px' }}>
                                            ID
                                        </th>
                                        <th>
                                            {trans('tables.name')}
                                        </th>
                                        <th>
                                            {trans('tables.date')}
                                        </th>
                                        <th>
                                            {trans('tables.file')}
                                        </th>
                                        {state.filter.status === 'deleted' &&
                                            <>
                                                <th>
                                                    {trans('tables.deletedBy')}
                                                </th>
                                                <th style={{ textAlign: 'center' }}>
                                                    {trans('tables.deletedAt')}
                                                </th>
                                            </>
                                        }
                                        <th className="options">
                                            {trans('tables.options')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.map(r =>
                                        <tr
                                            key={'r-' + r.id}
                                        >
                                            <td style={{ width: '40px' }}>
                                                {r.id}
                                            </td>
                                            <td>
                                                {r.name}
                                            </td>
                                            <td>
                                                {moment(r.date).format('DD.MM.YYYY')}
                                            </td>
                                            <td>
                                                <a className="btn" href={r.file_url} style={{ margin: '0 3px' }}>
                                                    {r.filename}
                                                </a>
                                            </td>
                                            {state.filter.status === 'deleted' &&
                                                <>
                                                    <td>
                                                        {r.deletedby?.full_name}
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        {r.deleted_at ? moment(r.deleted_at).format('DD.MM.YYYY') : ''}
                                                    </td>
                                                </>
                                            }
                                            <td>
                                                <Options
                                                    id={r.id}
                                                    data={r}
                                                    handleEdit={handleEdit}
                                                    handleDelete={handleDelete}
                                                    handleRestore={handleRestore}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            pages={state.pages}
                            page={state.page}
                            handlePage={handlePage}
                        />
                    </>
            }

        </>
    )
}

export default forwardRef(Index);