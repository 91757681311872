import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';

//images
import arrowLeft from '../../../../../assets/img/icons/arrow-left.png';
import arrowRight from '../../../../../assets/img/icons/arrow-right.png';
import closeIcon from '../../../../../assets/img/icons/close.png';

function Images(props, ref) {

    const imageRef = useRef(null);

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        images: null,
        current: 0,
        loading: false,
    });

    useImperativeHandle(ref, () => ({
        show: index => {
            handleShow(index);
        },
        hide: () => {
            handleHide();
        }
    }));

    useEffect(() => {
        setPopup(prev => ({
            ...prev,
            images: props.images
        }));
    }, [props.images]);

    const handleShow = index => {
        setPopup(prev => ({
            ...prev,
            overlay: true,
            current: index
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const prevImage = e => {
        let current = popup.current;
        let total = popup.images.length;

        current--;

        if (current < 0) {
            current = total - 1;
        }

        loadImage(current);
    }

    const nextImage = e => {

        let current = popup.current;
        let total = popup.images.length;

        current++;

        if (current > total - 1) {
            current = 0;
        }

        loadImage(current);
    }

    const loadImage = index => {
        setPopup(prev => ({
            ...prev,
            current: index
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <img className='exit-modal' onClick={handleHide} src={closeIcon} />
            <div className='popup-container design-container large'>

                <img src={arrowLeft} style={{ marginLeft: 'auto', marginRight: 'auto' }} onClick={prevImage} className="arrow-left" />

                <div className="scroller">
                    <img ref={imageRef} src={popup.images && popup.images[popup.current] ? popup.images[popup.current].picture : ''} />
                </div>

                <img src={arrowRight} style={{ marginLeft: 'auto', marginRight: 'auto' }} onClick={nextImage} className="arrow-right" />

            </div>
        </div>
    )
}

export default forwardRef(Images);