import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { trans } from '../../../_providers/Translation'

//images
import successIcon from '../../../assets/img/modals/messages/success.png';

function Success(props, ref) {

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        loading: false,
        params: null,
        onClose: null
    })

    const handleClose = () => {

        setState(prev => ({
            ...prev,
            loading: true,
        }))

        handleCancel();

        if (props.callback && typeof props.callback === 'function') {
            return props.callback();
        } else if (state.onClose && typeof state.onClose === 'function') {
            return state.onClose();
        }

    }

    const handleCancel = () => {
        setState(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }))
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }));
        }, 100);
    }

    useImperativeHandle(ref, () => ({
        open: () => {

            setState(prev => ({
                ...prev,
                overlay: true,
            }));

            setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    modal: true,
                }))
            }, 50);
        },
        close: () => {
            handleCancel();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <div className={`box success ${state.modal ? 'show' : ''}`}>
                <img src={successIcon} alt="success" />
                <h3>
                    {props.mainMessage}
                </h3>
                <p>
                    {props.secondaryMessage}
                </p>
                <div className="buttons">
                    <button className="button button-primary" onClick={handleClose}>
                        {trans('buttons.ok')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Success);