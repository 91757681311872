import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import { trans } from '../../../../_providers/Translation';

//images
import menuIcon from '../../../../assets/img/icons/table-menu.png';
import editIcon from '../../../../assets/img/icons/edit.png';
import deleteIcon from '../../../../assets/img/icons/delete.png';

function Index(props) {

    const [table, setTable] = useState({
        options: null
    });

    const handleTableMenu = e => {

        setTable(table => {
            return {
                ...table,
                options: e.currentTarget
            }
        });
    };

    const handleCloseMenu = () => {
        setTable(table => {
            return {
                ...table,
                options: null
            }
        });
    };

    const handleEdit = id => {
        handleCloseMenu();

        props.handleEdit(id);
    }

    const handleAccept = id => {
        handleCloseMenu();

        props.handleAccept(id);
    }

    const handleDecline = id => {
        handleCloseMenu();

        props.handleDecline(id);
    }

    const handleDelete = id => {
        handleCloseMenu();

        props.handleDelete(id);
    }

    const handleShowSettings = id => {
        handleCloseMenu();

        props.handleShowSettings(id);
    }

    return (
        <>
            <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />
            <Menu
                id={'menu-' + props.id}
                anchorEl={table.options}
                keepMounted
                open={Boolean(table.options)}
                onClose={handleCloseMenu}
            >
                {props.data.acceptable &&
                    <div>
                        <div className="menu-option" onClick={() => handleAccept(props.id)}>
                            <img src={editIcon} alt="edit" />
                            {trans('buttons.accept')}
                    </div>
                        <div className="menu-option" onClick={() => handleDecline(props.id)}>
                            <img src={editIcon} alt="edit" />
                            {trans('buttons.cancel')}
                        </div>
                    </div>
                }
                {props.data.editable &&
                    <div className="menu-option" onClick={() => handleEdit(props.id)}>
                        <img src={editIcon} alt="edit" />
                        {trans('buttons.edit')}
                    </div>
                }
                {props.data.settingable &&
                    <div className="menu-option" onClick={() => handleShowSettings(props.id)}>
                        <img src={editIcon} alt="edit" />
                        {trans('buttons.settings')}
                    </div>
                }
                <div className="menu-option" onClick={() => handleDelete(props.id)}>
                    <img src={deleteIcon} alt="delete" />
                    {trans('buttons.remove')}
                </div>
            </Menu>
        </>
    )
}

export default Index;
