import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { plural, trans } from '../../../_providers/Translation';

//images
import errorIcon from '../../../assets/img/modals/messages/error.png';

function MaxActiveEmployeesExceededError(props, ref) {

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        loading: false,
        onClose: null
    })

    const handleClose = () => {

        setState(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }));
        }, 100);

        if (props.callback && typeof props.callback === 'function') {
            return props.callback();
        } else if (state.onClose && typeof state.onClose === 'function') {
            return state.onClose();
        }

    }

    useImperativeHandle(ref, () => ({
        open: () => {
            setState(prev => ({
                ...prev,
                overlay: true,
            }));

            setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    modal: true,
                }))
            }, 50);
        },
        close: () => {
            handleClose();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <div className={`box error ${state.modal ? 'show' : ''}`}>

                <img src={errorIcon} alt="error" />

                <h3>
                    {trans('messages.maxActiveEmployeesExceeded')}
                </h3>

                <p>
                    {plural('random.maxActiveEmployeesExceeded', props.count, {
                        count: props.count
                    })}
                </p>

                <div className="buttons">
                    <button className="button button-primary" onClick={handleClose}>
                        ОК
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(MaxActiveEmployeesExceededError);