import React, { useState, useEffect, useRef } from 'react'
import Api from '../../../../_helpers/Api'
import { useParams, useHistory, Link } from 'react-router-dom'
import style from '../../../../assets/css/invoices.css'
import { trans } from '../../../../_providers/Translation';

//modals
import AddInvoice from './modals/AddInvoice'

//partials
import InvoicesTable from '../../../partials/InvoicesTable'

function Invoices(props) {

    const newInvoiceModalRef = useRef(null);

    const [state, setState] = useState({
        data: [],
        loading: true,
        update: false
    })

    const params = useParams()
    const history = useHistory()

    const id = params.id;

    useEffect(() => {
        loadData()
    }, [state.update])

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        Api.get(`reservations/invoices?id=${id}`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    loading: false,
                }))
            })

    }

    const refreshData = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));

        props.refreshData();
    }

    const handleAdd = () => {
        newInvoiceModalRef.current.open(id)
    }

    const handleDelete = () => {
        history.push(`?tab=invoices&indicator=${new Date().getTime()}`)
    }


    return (
        <>
            <AddInvoice
                ref={newInvoiceModalRef}
                data={props.data}
                refreshData={refreshData}
            />

            {props.data.can_modify && props.data && props.data.can_generate_invoice &&
                <button className="button button-add extra-button" onClick={handleAdd}>
                    {trans('buttons.newDocument')}
                </button>
            }

            <InvoicesTable
                loading={state.loading}
                invoices={state.data}
                canModify={props.data.can_modify}
                refreshData={refreshData}
                handleDelete={handleDelete}
            />

        </>
    )
}

export default Invoices