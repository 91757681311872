import React, { useEffect, useState } from 'react';
import { trans } from '../../../_providers/Translation';

import { useHistory, useLocation, Link } from 'react-router-dom';
import Api from '../../../_helpers/Api';
import style from '../../../assets/css/accounts.css'
import Skeleton from 'react-loading-skeleton';
import notFound from '../../../assets/img/not-found.png'
import { useAuthDataContext } from '../../../_providers/Auth';
import Pagination from '../../Pagination';
import SetPaymentMethod from './partials/SetPaymentMethod';
import FilterTabs from './partials/FilterTabs';

let timeout;

function UnpaidReservations() {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [state, setState] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
    });

    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                //
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    useEffect(() => {
        Api.get('payments/methods')
            .then(res => {
                setPaymentMethods(res.data);
            });
    }, []);

    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'accounts/unpaid-reservations?page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });


        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                    loading: false
                }));
            });
    }

    const handleSearch = (key, val, delay = 300) => {

        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            }
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                setFilter: new Date().getTime()
            }));
        }, delay);

    }

    const handleRefresh = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, update: new Date().getTime() }));
    }


    return (
        <>
            <div className="wrapper">

                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.unpaidReservations')}
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.unpaidReservations')}
                        </p>
                    </div>
                    <div className="right">

                    </div>

                </div>

                {/* <FilterTabs
                    tab={'unpaid-reservations'}

                /> */}


                {state.loading
                    ?
                    <Skeleton height={45} count={10} />
                    :
                    state.data.length === 0
                        ?
                        <div className="not-found">
                            <img src={notFound} alt="Not Found" />
                            <p>
                                {trans('messages.noResultsFound')}
                            </p>
                        </div>
                        :
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>{trans('tables.client')}</th>
                                        <th>{trans('tables.address')}</th>
                                        <th>{trans('tables.refNo')}</th>
                                        <th>{trans('tables.visitDate')}</th>
                                        <th className="right">{trans('tables.grossPrice')}</th>
                                        <th style={{ width: '20%' }}>{trans('tables.status')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.map(c =>
                                        <tr key={c.id}>
                                            <td>
                                                {c.id}
                                            </td>
                                            <td>
                                                {c.reservation?.customer
                                                    ?
                                                    `${c.reservation?.customer?.name} ${c.reservation?.customer?.lastname}`
                                                    :
                                                    ''
                                                }
                                            </td>
                                            <td>
                                                {c.reservation?.full_address}
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/reservations/edit/${c.reservation?.id}`}
                                                    className="btn"
                                                    style={{ background: c.reservation?.color, color: c.reservation?.text_color }}
                                                >
                                                    {c.reservation?.reference_code}
                                                </Link>
                                            </td>
                                            <td>
                                                {c.reservation?.visit_date_dmy}
                                            </td>
                                            <td className="right">
                                                {c.reservation?.total_gross_price_formatted || c.price_formatted} {c.currency && c.currency.name}
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                <SetPaymentMethod
                                                    paymentId={c.id}
                                                    selected={c.paymentmethod}
                                                    methods={paymentMethods}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                }

                {!state.loading
                    ?
                    <Pagination
                        page={state.page}
                        pages={state.pages}
                        handlePage={handlePage}
                    />
                    :
                    ''
                }
            </div>


        </>
    )
}

export default UnpaidReservations;