import React, { useState, useEffect, useRef } from 'react';
import { trans } from '../../../_providers/Translation'

//partials
import Filter from './partials/Filter';
import Table from './partials/Table';
import SecondaryTabs from './partials/SecondaryTabs';
import AddOrEdit from './partials/modals/AddOrEdit';
import { useHistory, useLocation } from 'react-router-dom';
import Api from '../../../_helpers/Api';
import Pagination from '../../Pagination';
import Question from '../../modals/messages/Question';
import Success from '../../modals/messages/Success';
import Calendar from './partials/Calendar';
import moment from 'moment';
import Help from '../../partials/Help';
import HelpInfo from './partials/HelpInfo';

let timeout;

function Todo() {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const modalAddOrEditRef = useRef(null);
    const modalQuestionRef = useRef(null);
    const notifyModalRef = useRef(null);

    const tabs = ['all', 'awaiting', 'completed', 'fail', 'calendar'];

    const [todo, setTodo] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
    })


    useEffect(() => {

        let status = searchParams.get('status');

        setTodo(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                status: tabs.indexOf(status) > -1 ? status : tabs[0],
                date: searchParams.get('date') || '',
                deadline: searchParams.get('deadline') || '',
                text: searchParams.get('text') || '',
                user_id: searchParams.get('user_id') || '',
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (todo.setFilter) {
            let url = '?page=' + todo.page;

            Object.entries(todo.filter).map(filter => {
                if (filter[1]) {
                    url += '&' + filter[0] + '=' + filter[1];
                }
            });

            history.push(url);
        }

    }, [todo.setFilter]);

    useEffect(() => {
        if (todo.update) {
            loadTodos()
        }
    }, [todo.update]);

    useEffect(() => {
        if (todo.filter.status === 'calendar') {
            handleRangeChange(
                moment().startOf('month').format('YYYY-MM-DD'),
                moment().endOf('month').format('YYYY-MM-DD')
            );
        }
    }, [todo.filter.status]);

    const loadTodos = () => {

        setTodo(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'todos/all?page=' + todo.page;

        Object.entries(todo.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setTodo(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }));
            }).catch(err => {
                setTodo(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleAdd = () => {
        modalAddOrEditRef.current.add();
    }

    const handleCreate = todo => {
        setTodo(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id);
    }

    const handleUpdate = newData => {

        // let data = todo.data.map(i => {
        //     if (i.id == newData.id) {
        //         i = newData;
        //     }
        //     return i;
        // });

        // setTodo(prev => ({
        //     ...prev,
        //     data: data
        // }));

        refreshData();
    }

    // delete
    const handleDelete = id => {

        modalQuestionRef.current.open(id);

        modalQuestionRef.current.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleDestroy = id => {
        Api.post('todos/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                let data = todo.data.filter(i => {
                    return i.id !== id;
                });
                setTodo(prev => ({
                    ...prev,
                    data: data
                }));
            }
        });
    }

    const handleRead = id => {

        Api.post('todos/mark-as-read', {
            id: id
        }).then(res => {
            let newData = todo.data.map(each => {
                if (each.id == id) {
                    each = res.data;
                }

                return each;
            });

            setTodo(prev => ({
                ...prev,
                data: newData,
            }));
        });

    }

    const handleComplete = id => {
        Api.post('todos/mark-as-completed', {
            id: id
        }).then(res => {
            let newData = todo.data.map(each => {
                if (each.id == id) {
                    each = res.data;
                }

                return each;
            });

            setTodo(prev => ({
                ...prev,
                data: newData,
            }));
        })
    }

    const handleTogglePause = id => {
        Api.post('todos/toggle-pause', {
            id: id
        }).then(res => {
            let newData = todo.data.map(each => {
                if (each.id == id) {
                    each = res.data;
                }

                return each;
            });

            setTodo(prev => ({
                ...prev,
                data: newData,
            }));
        })
    }

    const handleSearch = (key, val, interval = 300) => {

        clearTimeout(timeout);

        setTodo(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
            page: 1,
        }));

        timeout = setTimeout(() => {
            setTodo(prev => ({
                ...prev,
                setFilter: new Date().getTime()
            }));
        }, interval);

    }

    const handleRangeChange = (start, end) => {
        setTodo(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                date: start,
                deadline: end
            },
            page: 1,
            setFilter: new Date().getTime()
        }));
    }

    const handlePage = page => {
        setTodo(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    const handleTab = tab => {

        setTodo(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                status: tab
            },
            page: 1,
            setFilter: new Date().getTime()
        }));
    }

    const handleNotify = () => {
        Api.post('todos/send-notifications')
            .then(res => {
                notifyModalRef.current.open();
            });
    }

    const refreshData = () => {
        setTodo(prev => ({
            ...prev,
            update: new Date().getTime()
        }))
    }

    return (
        <div className="wrapper">

            <Success
                ref={notifyModalRef}
                mainMessage={trans('headings.success1')}
            />

            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
            />

            <div className="top-part">
                <div className="left">
                    <h1 className="page-heading">
                        {trans('headings.todos')} <Help><HelpInfo /></Help>
                    </h1>
                    <p className="page-subheading">
                        {trans('subheadings.todos')}
                    </p>
                </div>
                <div className="right" style={{
                    display: 'flex'
                }}>
                    <button className="button-add" onClick={handleAdd}>
                        {trans('buttons.todo')}
                    </button>
                    {/* <button onClick={handleNotify} style={{
                        marginLeft: '10px'
                    }}>
                        Изпрати известие
                    </button> */}
                </div>
            </div>

            <Filter
                data={todo.filter}
                handleSearch={handleSearch}
            />

            <SecondaryTabs
                tab={todo.filter.status}
                handleTab={handleTab}
            />

            {todo.filter.status === 'calendar'
                ?
                <Calendar
                    data={todo.data}
                    start={todo.filter.date}
                    loading={todo.loading}
                    handleRead={handleRead}
                    handleSearch={handleSearch}
                    handleRangeChange={handleRangeChange}
                />
                :
                <>
                    <Table
                        data={todo.data}
                        loading={todo.loading}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        handleRead={handleRead}
                        handleComplete={handleComplete}
                        handleTogglePause={handleTogglePause}
                        refreshData={refreshData}
                    />

                    {!todo.loading
                        ?
                        <Pagination
                            pages={todo.pages}
                            page={todo.page}
                            handlePage={handlePage}
                        />
                        :
                        ''
                    }
                </>
            }


        </div>
    )
}

export default Todo;