import React, { useRef } from 'react';
import NoDataFound from '../../../partials/NoDataFound';
import EditExpense from '../../expenses/partials/AddOrEditExpense';
import { trans } from '../../../../_providers/Translation';

function Table(props) {

    const editExpenseRef = useRef(null);

    const handleEditExpense = id => {
        editExpenseRef.current.edit(id);
    }

    return (

        props.data.length === 0
            ?
            <NoDataFound />
            :
            <>
                <EditExpense
                    ref={editExpenseRef}
                />

                <div className="table-scroller">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: '50px' }}>ID</th>
                                <th>{trans('tables.date')}</th>
                                <th>{trans('tables.price')}</th>
                                <th>{trans('tables.orderer')}</th>
                                <th>{trans('tables.info')}</th>
                                <th>{trans('tables.file')}</th>
                                <th className="center">{trans('tables.expense')} ID</th>
                                <th>{trans('tables.creationDate')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.map(t =>
                                <tr key={'t-' + t.id}>
                                    <td style={{ width: '50px' }}>{t.id}</td>
                                    <td>{t.date_dmy}</td>
                                    <td>{t.expense_id ? '-' : ''}{t.amount} {t.currency && t.currency.name}</td>
                                    <td>{t.payee}</td>
                                    <td>{t.info}</td>
                                    <td>{t.file.filename}</td>
                                    <td className="center">
                                        {t.expense
                                            ?
                                            <a
                                                className="btn"
                                                onClick={() => handleEditExpense(t.expense_id)}
                                            >
                                                {t.expense_id}
                                            </a>
                                            :
                                            ''
                                        }
                                    </td>
                                    <td>
                                        {t.created_at_dmy}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>


            </>
    )
}

export default Table;