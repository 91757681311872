import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Api from '../../../../_helpers/Api';
import Dropdown from '../../../partials/Dropdown';
import { fromInfo, trans } from '../../../../_providers/Translation'
import Help from '../../../partials/Help';

function Filter(props) {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [state, setState] = useState({
        employees: [],
        selectedEmployees: {},
        groups: []
    });

    useEffect(() => {
        Api.get('partners/employees/technicians')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    employees: res.data
                }));
            });

        Api.get('partners/groups/all')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    groups: res.data
                }));
            });
    }, []);

    useEffect(() => {
        let selectedEmployees = {};

        if (Array.isArray(props.data.employees)) {
            props.data.employees.map(id => {
                selectedEmployees[id] = 1;
            });
        }

        setState(prev => ({
            ...prev,
            selectedEmployees: selectedEmployees
        }));

    }, [props.data.employees]);

    const handleWeek = name => {
        // alert(name)
        let date = calcWeekStart(name);

        props.handleSearch('start', date);
    }

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    const handleCategory = data => {

        let val = data.id || '';

        if (data.id || data.name == '') {
            props.handleSearch('category_id', val);
        }

    }

    const calcWeekStart = name => {

        let interval;

        let date = new Date();

        if (props.data.start) {
            date = new Date(props.data.start);
        }

        switch (name) {
            case 'previous':
                interval = date.getTime() - (60 * 60 * 24 * 7 * 1000);
                break;
            case 'this':
                interval = new Date().getTime();
                break;
            case 'next':
                interval = date.getTime() + (60 * 60 * 24 * 7 * 1000);
                break;
        }

        let beforeOneWeek = new Date(interval)
        let day = beforeOneWeek.getDay();
        let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1)
        let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday))

        let _year = lastMonday.getFullYear();
        let _month = (lastMonday.getMonth() + 1).toString().padStart(2, 0);
        let _day = lastMonday.getDate().toString().padStart(2, 0);

        let fulldate = [_year, _month, _day].join('-');

        return fulldate;
    }

    const handleCheckEmployee = e => {
        let id = e.target.value;

        let selected = Object.assign({}, state.selectedEmployees);

        if (selected[id]) {
            delete selected[id];
        } else {
            selected[id] = 1;
        }

        setState(prev => ({
            ...prev,
            selectedEmployees: selected
        }));

        // props.handleSearch('employees', Object.keys(selected));
        props.handleEmployees(Object.keys(selected))
    }

    const handleGroup = e => {
        let id = e.target.value;

        props.handleGroup(id);
    }

    const handleSelectMobileFilter = e => {
        let selectedValue = e.target.value
        let fields = [...document.querySelectorAll('.filter-standard .col')]
        fields.map(field => {
            if (field.getAttribute('data-id') == selectedValue) field.style.display = 'flex'
            else if (field.getAttribute('data-id')) field.style.display = 'none'
        })
    }

    return (
        <>
            <div className="filter-standard schedule-filter" style={{ paddingBottom: 0 }}>
                <div className="col select-filter-col">
                    <label>
                        {trans('filters.filterBy')}
                    </label>
                    <select onChange={handleSelectMobileFilter}>
                        <option value="1" selected>
                            {trans('calendar.week')}
                        </option>
                        <option value="2">
                            {trans('labels.dateFrom')}
                        </option>
                        <option value="3">
                            {trans('labels.finalDate')}
                        </option>
                        <option value="4">
                            {trans('labels.category')}
                        </option>
                        <option value="5">
                            {trans('labels.search')}
                        </option>
                        <option value="6">
                            {trans('labels.technicianClose')}
                        </option>
                    </select>
                </div>
                <div className="col" style={{ marginTop: 'auto' }} data-id="1">
                    <div className="row no-margin">
                        <button className="green" onClick={() => handleWeek('previous')}>{trans('random.lastWeek')}</button>
                        <button className="blue" onClick={() => handleWeek('this')}>{trans('random.currentWeek')}</button>
                        <button className="yellow" onClick={() => handleWeek('next')}>{trans('random.nextWeek')}</button>
                    </div>
                </div>

                <div className="col" data-id="2">
                    <label>
                        {trans('labels.dateFrom')}
                    </label>
                    <input
                        name="start"
                        type="date"
                        value={props.data.start}
                        onChange={handleSearch}
                    />
                </div>

                <div className="col" data-id="3">
                    <label>
                        {trans('labels.finalDate')}
                    </label>
                    <input
                        // name="end"
                        type="date"
                        value={props.data.end}
                        // onChange={handleSearch}
                        disabled
                    />
                </div>

                <div className="col" data-id="4">
                    <label>
                        {trans('labels.category')}
                    </label>

                    <Dropdown
                        inputIdValue={Number(props.data.category_id)}
                        url="appliances/categories/all"
                        renderText={data => {
                            return data.name
                        }}
                        onChange={data => {
                            handleCategory(data)
                        }}
                    />
                </div>


                <div className="col" data-id="5">
                    <label>
                        {trans('labels.search')}
                    </label>
                    <input
                        name="search"
                        type="search"
                        value={props.data.search}
                        onChange={handleSearch}
                    />
                </div>

                <div className="col" style={{ marginTop: 'auto', position: 'relative' }} data-id="6">
                    <button onClick={props.showMap}>
                        {trans('labels.technicianClose')}
                    </button>
                    <Help style={{ position: 'absolute', top: '-9px', right: 0 }} position="bottom-right">{fromInfo('schedule.map')}</Help>
                </div>

            </div>
            <div className="filter-standard schedule-filter">
                <div className="col" style={{ position: 'relative' }}>
                    <select
                        name="group_id"
                        value={props.data.group_id}
                        onChange={handleGroup}
                        style={{ height: '22px', appearance: 'none' }}
                    >
                        <option value="">{trans('labels.technicianGroup')}</option>
                        {state.groups.map(g =>
                            <option key={'g-' + g.id} value={g.id}>
                                {g.name}
                            </option>
                        )}
                    </select>
                    <Help style={{ position: 'absolute', top: '-9px', right: '-5px' }}>{fromInfo('schedule.selectEmployee')}</Help>
                </div>
                <div className="col" style={{ paddingTop: '3px' }}>
                    <div className="row no-margin">
                        {state.employees.map(e =>
                            <label key={'e-' + e.id} className="flex">
                                <input
                                    type="checkbox"
                                    value={e.id}
                                    checked={state.selectedEmployees[e.id] ? true : false}
                                    onClick={handleCheckEmployee}
                                />
                                <span>
                                    {e.name} {e.lastname}
                                </span>
                            </label>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Filter;