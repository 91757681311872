import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import infoIcon from '../../../../assets/img/icons/info-black.png';
import loader from '../../../../assets/img/loader.svg';

import AppliancesCategoriesPricing from '../../../partials/AppliancesCategoriesPricing';
import Subscription from './Subscription';
import SubscriptionOptions from './SubscriptionOptions';
import Question from '../../../modals/messages/Question';
import Success from '../../../modals/messages/Success';
import Help from '../../../partials/Help';
import ActivateSubscription from './ActivateSubscription';
import Warranty from './Warranty';
import ActivateWarranty from './ActivateWarranty';
import WarrantyOptions from './WarrantyOptions';

function AddOrEdit(props, ref) {

    const initialPopupData = {
    }

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: initialPopupData,
        types: [],
        labourPriceTypes: [],
        labourPrices: {},
        subscriptions: [],
        warranties: [],
        statuses: [],
        loading: false,
        dataLoading: false,
    });

    const [validations, setValidations] = useState(null);

    const activateSubscriptionModalRef = useRef(null);
    const subscriptionModalRef = useRef(null);
    const activateWarrantyModalRef = useRef(null);
    const warrantyModalRef = useRef(null);
    const deleteModalRef = useRef(null);
    const successModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                loading: false
            }));

            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    useEffect(() => {
        Api.get('contractors/types')
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    types: res.data
                }));
            });

        Api.get('settings/labour-price-types')
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    labourPriceTypes: res.data
                }));
            });
    }, []);

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            setPopup(prev => ({
                ...prev,
                dataLoading: true,
            }));

            Api.get(`contractors/find?id=${popup.editId}`)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data,
                        dataLoading: false,
                    }));
                });
        }

        loadPrices();
        loadSubscriptions();
        loadWarranties();

    }, [popup.edit]);

    const loadPrices = () => {
        // Api.get(`contractors/categories/prices?id=${Number(popup.editId)}`)
        //     .then(res => {
        //         setPopup(prev => ({
        //             ...prev,
        //             prices: res.data
        //         }));
        //     });


        Api.get('partners/categories/prices?contractor_id=' + Number(popup.editId))
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    labourPrices: res.data
                }));
            });

    }

    const loadSubscriptions = () => {
        if (!popup.editId) {
            return;
        }

        Api.get(`contractors/subscriptions?id=${Number(popup.editId)}`)
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    subscriptions: res.data
                }));
            });
    }

    const loadWarranties = () => {
        if (!popup.editId) {
            return;
        }

        Api.get(`contractors/warranties?id=${Number(popup.editId)}`)
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    warranties: res.data
                }));
            });
    }

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            data: initialPopupData,
        }));

        show();
    }

    const handleHide = e => {
        e.preventDefault();

        hide();

        setPopup(prev => ({
            ...prev,
            data: initialPopupData,
            edit: false,
            editId: null,
        }));
    }

    const show = async () => {
        return new Promise((resolve, reject) => {
            setPopup(prev => ({
                ...prev,
                overlay: true
            }));

            setTimeout(() => {
                setPopup(prev => ({
                    ...prev,
                    overlay: true,
                    modal: true
                }));

                return resolve();
            }, 50);
        });
    }

    const hide = async () => {
        return new Promise((resolve, reject) => {
            setPopup(prev => ({
                ...prev,
                modal: false,
                loading: false,
            }));

            setTimeout(() => {
                setPopup(prev => ({
                    ...prev,
                    overlay: false,
                    modal: false,
                }));

                return resolve();
            }, 50);
        });
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'contractors/edit';
        } else {
            url = 'contractors/add';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    props.handleUpdate(res.data)
                } else {
                    props.handleCreate(res.data);
                }

                hide();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleCheckboxChange = e => {
        let name = e.target.name;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: !prev.data[name]
            }
        }));
    }

    const handleTypeChange = e => {
        let val = e.target.value;
        let type = popup.types.find(t => Number(t.id) === Number(val));

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                type_id: type.id,
                type: type
            }
        }));
    }

    const handleLabourPriceChange = e => {
        let name = e.target.name.split('[')[1].replace(']', '');
        let val = e.target.value;

        if (name.length) {

            setPopup(prev => ({
                ...prev,
                labourPrices: {
                    ...prev.labourPrices,
                    [name]: {
                        ...prev.labourPrices[name],
                        default: val
                    }
                }

            }));
        } else {

            setPopup(prev => ({
                ...prev,
                labourPrices: {
                    ...prev.labourPrices,
                    default: val
                }
            }));
        }


    }

    const isSubscription = () => {
        return Boolean(popup?.data?.type?.is_subscription);
    }

    const handleAddSubscription = e => {
        e.preventDefault();

        let modal = subscriptionModalRef.current;

        hide().then(() => {
            modal.open();
        });
    }

    const handleEditSubscription = id => {
        let modal = subscriptionModalRef.current;

        hide().then(() => {
            modal.open(id);
        });
    }

    const handleDeleteSubscription = id => {
        let modal = deleteModalRef.current;

        hide().then(() => {
            modal.open(id);
        })

        modal.onSuccess(() => {
            handleDestroySubscription(id);
        });

        modal.onClose(() => {
            show();
        });

    }

    const handleDestroySubscription = id => {
        Api.post('contractors/subscriptions/delete', {
            id: id
        }).then(res => {
            loadSubscriptions();
        });
    }

    const handleActivateSubscription = id => {
        let modal = activateSubscriptionModalRef.current;

        hide().then(() => {
            modal.open(id);
        });

    }

    const handleRenewSubscription = id => {
        Api.post('contractors/subscriptions/renew', {
            id: id
        }).then(res => {
            let modal = successModalRef.current;

            hide().then(() => {
                modal.open();
            });

            modal.onClose(() => {
                show();
            });

            loadSubscriptions();
        });
    }

    const canAddSubscription = () => {
        if (!isSubscription()) {
            return false;
        }

        return popup?.data?.id ? true : false;
    }

    const handleSaveSubscription = () => {
        loadSubscriptions();
    }


    const handleAddWarranty = e => {
        e.preventDefault();

        let modal = warrantyModalRef.current;

        hide().then(() => {
            modal.open();
        });
    }

    const handleEditWarranty = id => {
        let modal = warrantyModalRef.current;

        hide().then(() => {
            modal.open(id);
        });
    }

    const handleDeleteWarranty = id => {
        let modal = deleteModalRef.current;

        hide().then(() => {
            modal.open(id);
        })

        modal.onSuccess(() => {
            handleDestroyWarranty(id);
        });

        modal.onClose(() => {
            show();
        });

    }

    const handleDestroyWarranty = id => {
        Api.post('contractors/warranties/delete', {
            id: id
        }).then(res => {
            loadWarranties();
        });
    }

    const handleActivateWarranty = id => {
        let modal = activateWarrantyModalRef.current;

        hide().then(() => {
            modal.open(id);
        });

    }

    const canAddWarranty = () => {
        return popup?.data?.id ? true : false;
    }

    const handleSaveWarranty = () => {
        loadWarranties();
    }

    const selectedLabourPriceIsFixed = () => {
        let type = getSelectedLabourPrice();

        if (type) {
            return type.is_fixed;
        }

        return null;
    }

    const selectedLabourPriceIsDiagnosticAndLabour = () => {
        let type = getSelectedLabourPrice();

        if (type) {
            return type.is_diagnostic_and_labour;
        }

        return null;
    }

    const selectedLabourPriceIsHourly = () => {
        let type = getSelectedLabourPrice();

        if (type) {
            return type.is_hourly;
        }

        return null;
    }

    const selectedLabourPriceIsCategory = () => {
        let type = getSelectedLabourPrice();

        if (type) {
            return type.is_category;
        }

        return null;
    }

    const getSelectedLabourPrice = () => {
        if (popup.data && popup.data.labour_price_type_id) {
            let type = popup.labourPriceTypes.filter(t => Number(t.id) === Number(popup.data.labour_price_type_id))[0];

            if (type) {
                return type;
            }
        }

        return null;
    }

    return (
        <>
            <ActivateSubscription
                ref={activateSubscriptionModalRef}
                onClose={show}
                onSave={loadSubscriptions}
            />

            <Subscription
                ref={subscriptionModalRef}
                contractorId={popup?.data?.id || null}
                onClose={show}
                onSave={handleSaveSubscription}
            />

            <ActivateWarranty
                ref={activateWarrantyModalRef}
                onClose={show}
                onSave={loadWarranties}
            />

            <Warranty
                ref={warrantyModalRef}
                contractorId={popup?.data?.id || null}
                onClose={show}
                onSave={handleSaveWarranty}
            />

            <Question
                ref={deleteModalRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
            />

            <Success
                ref={successModalRef}
                mainMessage={trans('messages.success')}
            />

            <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
                <form className={`${popup.modal ? 'show' : ''} popup-primary extra-large`} onSubmit={handleSave}>
                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />

                        {popup.edit ? trans('headings.editContractors') : trans('headings.addContractors')}

                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>

                    {popup.dataLoading
                        ?
                        <img src={loader} alt="loading" />
                        :
                        <div className="row popup-container x2 " style={{ padding: '0 10px 0 0' }}>

                            {/* col1 */}
                            <div className="col">

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.type')}
                                        </label>
                                        <select
                                            name="type_id"
                                            className={validations && validations.type_id ? 'validate' : ''}
                                            value={popup.data ? popup.data.type_id : ''}
                                            onChange={e => handleTypeChange(e)}
                                        >
                                            <option selected disabled></option>
                                            {popup.types.map(t =>
                                                <option
                                                    key={'t-' + t.id}
                                                    value={t.id}
                                                >
                                                    {t?.translation?.name || t.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.name')}
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={popup.data && popup.data.name ?
                                                popup.data.name
                                                :
                                                ''
                                            }
                                            className={validations && validations.name ? 'validate' : ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.eik')}
                                        </label>
                                        <input
                                            type="text"
                                            name="eik"
                                            value={popup.data && popup.data.eik ?
                                                popup.data.eik
                                                :
                                                ''
                                            }
                                            className={validations && validations.eik ? 'validate' : ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.vatNo')}
                                        </label>
                                        <input
                                            type="text"
                                            name="vat_number"
                                            value={popup.data && popup.data.vat_number ?
                                                popup.data.vat_number
                                                :
                                                ''
                                            }
                                            className={validations && validations.vat_number ? 'validate' : ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.mol')}
                                        </label>
                                        <input
                                            type="text"
                                            name="mol"
                                            value={popup.data && popup.data.mol ?
                                                popup.data.mol
                                                :
                                                ''
                                            }
                                            className={validations && validations.mol ? 'validate' : ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.address')}
                                        </label>
                                        <input
                                            type="text"
                                            name="address"
                                            value={popup.data && popup.data.address ?
                                                popup.data.address
                                                :
                                                ''
                                            }
                                            className={validations && validations.address ? 'validate' : ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.postCode')}
                                        </label>
                                        <input
                                            type="text"
                                            name="postcode"
                                            value={popup.data && popup.data.postcode ?
                                                popup.data.postcode
                                                :
                                                ''
                                            }
                                            className={validations && validations.postcode ? 'validate' : ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.phone')}
                                        </label>
                                        <input
                                            type="text"
                                            name="phone"
                                            value={popup.data && popup.data.phone ?
                                                popup.data.phone
                                                :
                                                ''
                                            }
                                            className={validations && validations.phone ? 'validate' : ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.splitEmail')}
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            value={popup.data && popup.data.email ?
                                                popup.data.email
                                                :
                                                ''
                                            }
                                            className={validations && validations.email ? 'validate' : ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.splitEmail2')}
                                        </label>
                                        <input
                                            type="text"
                                            name="accounting_email"
                                            value={popup.data && popup.data.accounting_email ?
                                                popup.data.accounting_email
                                                :
                                                ''
                                            }
                                            className={validations && validations.accounting_email ? 'validate' : ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="checkbox-primary">
                                        <label htmlFor="send_reports">
                                            {trans('labels.sendReport')}
                                            <input
                                                id="send_reports"
                                                type="checkbox"
                                                name="send_reports"
                                                value="1"
                                                checked={popup.data
                                                    ? popup.data.send_reports ? true : false
                                                    : true
                                                }
                                                className={validations && validations.send_reports ? 'validate' : ''}
                                                onChange={handleCheckboxChange}
                                            />
                                            <div className="checkmark"></div>
                                        </label>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="checkbox-primary">
                                        <label htmlFor="charge">
                                            {isSubscription() ? trans('labels.taxSubscription') : trans('labels.taxReservation')}
                                            <input
                                                id="charge"
                                                type="checkbox"
                                                name="charge"
                                                value="1"
                                                checked={popup.data
                                                    ? popup.data.charge ? true : false
                                                    : true
                                                }
                                                className={validations && validations.charge ? 'validate' : ''}
                                                onChange={handleCheckboxChange}
                                            />
                                            <div className="checkmark"></div>
                                        </label>
                                    </div>
                                </div>


                                {/* <div className="row">
                                    <div className="checkbox-primary">
                                        <label htmlFor="repair_warranty">
                                            {trans('labels.repairWarranty')}
                                            <input
                                                id="repair_warranty"
                                                type="checkbox"
                                                name="repair_warranty"
                                                value="1"
                                                checked={popup.data
                                                    ? popup.data.repair_warranty ? true : false
                                                    : false
                                                }
                                                className={validations && validations.repair_warranty ? 'validate' : ''}
                                                onChange={handleCheckboxChange}
                                            />
                                            <div className="checkmark"></div>
                                        </label>
                                    </div>
                                </div> */}

                                <div className="row">
                                    <div className="checkbox-primary">
                                        <label htmlFor="active">
                                            {trans('labels.active')}
                                            <input
                                                id="active"
                                                type="checkbox"
                                                name="active"
                                                value="1"
                                                checked={popup.data
                                                    ? popup.data.active ? true : false
                                                    : true
                                                }
                                                className={validations && validations.active ? 'validate' : ''}
                                                onChange={handleCheckboxChange}
                                            />
                                            <div className="checkmark"></div>
                                        </label>
                                    </div>
                                </div>


                            </div>


                            {/* col2 */}
                            <div className="col">

                                {isSubscription()
                                    ?
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <label className="flex">
                                                    {trans('labels.subscriptions')}
                                                </label>
                                                {popup.subscriptions.length === 0
                                                    ?
                                                    <div className="alert warning" style={{ margin: 0 }}>
                                                        {canAddSubscription()
                                                            ?
                                                            trans('messages.noAddedSubscriptions')
                                                            :
                                                            trans('messages.addSubscriptionsMsg')
                                                        }
                                                    </div>
                                                    :
                                                    <div className="scrollable" style={{ padding: '0 10px 0 0', maxHeight: '800px', overflow: 'auto' }}>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        {trans('tables.refNo')}
                                                                    </th>
                                                                    <th>
                                                                        {trans('tables.type')}
                                                                    </th>
                                                                    <th>
                                                                        {trans('tables.appliance')}
                                                                    </th>
                                                                    <th>
                                                                        {trans('tables.activatedAt')}
                                                                    </th>
                                                                    <th>
                                                                        {trans('tables.expiresAt')}
                                                                    </th>
                                                                    <th>
                                                                        {trans('tables.options')}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {popup.subscriptions.map(sub =>
                                                                    <tr key={'sub-' + sub.id}>
                                                                        <td>
                                                                            {sub.ref}
                                                                        </td>
                                                                        <td>
                                                                            {sub?.type?.name}
                                                                        </td>
                                                                        <td>
                                                                            {sub?.appliancecategory?.name}
                                                                        </td>
                                                                        <td>
                                                                            {sub.activated_at_dmy || '-'}
                                                                        </td>
                                                                        <td>
                                                                            {sub.expires_at_dmy || '-'}
                                                                        </td>
                                                                        <td>
                                                                            <SubscriptionOptions
                                                                                id={sub.id}
                                                                                data={sub}
                                                                                handleEdit={handleEditSubscription}
                                                                                handleDelete={handleDeleteSubscription}
                                                                                handleActivate={handleActivateSubscription}
                                                                                handleRenew={handleRenewSubscription}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {canAddSubscription() &&
                                            <div className="row">
                                                <button
                                                    className="button-add button-small"
                                                    onClick={handleAddSubscription}
                                                >
                                                    {trans('buttons.add')}
                                                </button>
                                            </div>
                                        }
                                    </>
                                    :
                                    <div className="row">
                                        <div className="col">
                                            {/* <label className="flex">
                                                {trans('labels.pricing')} <Help style={{ marginLeft: '5px' }}>{trans('labels.showInfo')}</Help>
                                            </label> */}

                                            <div className="row">
                                                <div className="col">
                                                    <label>
                                                        {trans('labels.labourPriceType')}
                                                    </label>

                                                    <select
                                                        name="labour_price_type_id"
                                                        value={popup.data ? popup.data.labour_price_type_id : ''}
                                                        className={validations && validations.labour_price_type_id ? 'validate' : ''}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value=""></option>
                                                        {popup.labourPriceTypes.map(t =>
                                                            <option key={'lt-' + t.id} value={t.id}>{t.translation.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>

                                            {/* фиксирана */}
                                            <div style={{ display: selectedLabourPriceIsFixed() ? 'block' : 'none' }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            {trans('labels.labourPriceStandard')}
                                                        </label>

                                                        <input
                                                            type="number"
                                                            step="0.01"
                                                            min="0"
                                                            name="prices[3][]"
                                                            value={popup.labourPrices && popup.labourPrices[3] ? popup.labourPrices[3].default : ''}
                                                            className={validations && validations.labour_price_standard ? 'validate' : ''}
                                                            onChange={handleLabourPriceChange}
                                                        />

                                                    </div>
                                                </div>

                                                {/* <label>
                                    По категории и марки
                                </label> */}
                                                <div className="scrollable" style={{ maxHeight: '200px', padding: '0 10px 0 0' }}>
                                                    <AppliancesCategoriesPricing
                                                        prices={popup.labourPrices[3] ? popup.labourPrices[3].categories : []}
                                                        name="prices[3]"
                                                        brandsName="prices_brands[3]"
                                                    />
                                                </div>

                                            </div>

                                            {/* диагностика и труд */}
                                            <div style={{ display: selectedLabourPriceIsDiagnosticAndLabour() ? 'block' : 'none' }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            {trans('labels.labourPriceDiagnostic')}
                                                        </label>

                                                        <input
                                                            type="number"
                                                            step="0.01"
                                                            min="0"
                                                            name="prices[4][]"
                                                            value={popup.labourPrices && popup.labourPrices[4] ? popup.labourPrices[4].default : ''}
                                                            className={validations && validations.labour_price_diagnostic ? 'validate' : ''}
                                                            onChange={handleLabourPriceChange}
                                                        />

                                                    </div>
                                                </div>

                                                {/* <label>
                                        По категории и марки
                                    </label> */}
                                                {/* <div className="scrollable" style={{ maxHeight: '200px', padding: '0 10px 0 0' }}> */}
                                                <AppliancesCategoriesPricing
                                                    prices={popup.labourPrices[4] ? popup.labourPrices[4].categories : []}
                                                    name="prices[4]"
                                                    brandsName="prices_brands[4]"
                                                />
                                                {/* </div> */}

                                                <hr />

                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            {trans('labels.labourPriceContinue')}
                                                        </label>

                                                        <input
                                                            type="number"
                                                            step="0.01"
                                                            min="0"
                                                            name="prices[5][]"
                                                            value={popup.labourPrices && popup.labourPrices[5] ? popup.labourPrices[5].default : ''}
                                                            className={validations && validations.labour_price ? 'validate' : ''}
                                                            onChange={handleLabourPriceChange}
                                                        />

                                                    </div>
                                                </div>

                                                {/* <label>
                                        По категории и марки
                                    </label> */}
                                                {/* <div className="scrollable" style={{ maxHeight: '200px', padding: '0 10px 0 0' }}> */}
                                                <AppliancesCategoriesPricing
                                                    prices={popup.labourPrices[5] ? popup.labourPrices[5].categories : []}
                                                    name="prices[5]"
                                                    brandsName="prices_brands[5]"
                                                />
                                                {/* </div> */}

                                                <hr />

                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            {trans('labels.labourPriceInstall')}
                                                        </label>

                                                        <input
                                                            type="number"
                                                            step="0.01"
                                                            min="0"
                                                            name="prices[7][]"
                                                            value={popup.labourPrices && popup.labourPrices[7] ? popup.labourPrices[7].default : ''}
                                                            className={validations && validations.labour_price_installation ? 'validate' : ''}
                                                            onChange={handleLabourPriceChange}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="row"></div>

                                                {/* <label>
                                        По категории и марки
                                    </label> */}
                                                {/* <div className="scrollable" style={{ maxHeight: '200px', padding: '0 10px 0 0' }}> */}
                                                <AppliancesCategoriesPricing
                                                    prices={popup.labourPrices[7] ? popup.labourPrices[7].categories : []}
                                                    name="prices[7]"
                                                    brandsName="prices_brands[7]"
                                                />
                                                {/* </div> */}
                                            </div>


                                            {/* почасово */}
                                            <div style={{ display: selectedLabourPriceIsHourly() ? 'block' : 'none' }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            {trans('labels.labourPriceHourlyStandard')}
                                                        </label>

                                                        <input
                                                            type="number"
                                                            step="0.01"
                                                            min="0"
                                                            name="prices[1][]"
                                                            value={popup.labourPrices && popup.labourPrices[1] ? popup.labourPrices[1].default : ''}
                                                            // className={validations && validations.labour_price_hourly ? 'validate' : ''}
                                                            onChange={handleLabourPriceChange}
                                                        />

                                                    </div>
                                                </div>

                                                {/* <label>
                                        По категории и марки
                                </label> */}
                                                <div className="scrollable" style={{ maxHeight: '200px', padding: '0 10px 0 0' }}>
                                                    <AppliancesCategoriesPricing
                                                        prices={popup.labourPrices[1] ? popup.labourPrices[1].categories : []}
                                                        name="prices[1]"
                                                        brandsName="prices_brands[1]"
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="row">
                                    <div className="col">
                                        <label className="flex">
                                            {trans('labels.warranties')}
                                        </label>
                                        {popup.warranties.length === 0
                                            ?
                                            <div className="alert warning" style={{ margin: 0 }}>
                                                {canAddWarranty()
                                                    ?
                                                    trans('messages.noAddedWarranties')
                                                    :
                                                    trans('messages.addWarrantiesMsg')
                                                }
                                            </div>
                                            :
                                            <div className="scrollable" style={{ padding: '0 10px 0 0', maxHeight: '800px', overflow: 'auto' }}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {trans('tables.refNo')}
                                                            </th>
                                                            <th>
                                                                {trans('tables.appliance')}
                                                            </th>
                                                            <th>
                                                                {trans('tables.activatedAt')}
                                                            </th>
                                                            <th>
                                                                {trans('tables.expiresAt')}
                                                            </th>
                                                            <th>
                                                                {trans('tables.options')}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {popup.warranties.map(sub =>
                                                            <tr key={'sub-' + sub.id}>
                                                                <td>
                                                                    {sub.ref}
                                                                </td>
                                                                <td>
                                                                    {sub?.appliancecategory?.name}
                                                                </td>
                                                                <td>
                                                                    {sub.activated_at_dmy || '-'}
                                                                </td>
                                                                <td>
                                                                    {sub.expires_at_dmy || '-'}
                                                                </td>
                                                                <td>
                                                                    <WarrantyOptions
                                                                        id={sub.id}
                                                                        data={sub}
                                                                        handleEdit={handleEditWarranty}
                                                                        handleDelete={handleDeleteWarranty}
                                                                        handleActivate={handleActivateWarranty}
                                                                    // handleRenew={handleRenewSubscription}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {canAddWarranty() &&
                                    <div className="row">
                                        <button
                                            className="button-add button-small"
                                            onClick={handleAddWarranty}
                                        >
                                            {trans('buttons.add')}
                                        </button>
                                    </div>
                                }

                            </div>

                        </div>
                    }

                    <div className="footer align-center">
                        {popup.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="button" onClick={handleHide}>
                                    {trans('buttons.cancel')}
                                </button>
                                <button className="button button-primary" type="submit">
                                    {trans('buttons.save')}
                                </button>
                            </>
                        }
                    </div>
                </form >
            </div>
        </>
    )
}

export default forwardRef(AddOrEdit);