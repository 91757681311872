import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import ReactTooltip from 'react-tooltip';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import MultiSelect from "react-multi-select-component";
import { useAuthDataContext } from '../../../../_providers/Auth';
import { useParams } from 'react-router-dom';
import { trans } from '../../../../_providers/Translation';
import Error from '../../../modals/messages/Error';
import infoIcon from '../../../../assets/img/icons/info-black.png';
import Help from '../../../partials/Help';

function AddOrEditEmployee(props, ref) {

    const params = useParams();
    const auth = useAuthDataContext();

    const defaultData = {
        active: true,
        selectedPerms: [],
        selectedAppliances: []
    };

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: defaultData,
        perms: [],
        appliances: [],
        loading: false,
    });

    const [validations, setValidations] = useState(null);

    const maxEmployeesErrorModal = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: defaultData,
            }));
            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                loading: false,
                data: defaultData,
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            Api.get(`partners/employees/find?id=${popup.editId}`)
                .then(res => {

                    let data = res.data;
                    let perms = res.data.perms || [];
                    let appliances = res.data.appliances || [];
                    let selectedPerms = [];
                    let selectedAppliances = [];

                    perms.map(m => {
                        selectedPerms.push({
                            label: m.translation ? m.translation.name : '',
                            value: m.id
                        });
                    });

                    data.selectedPerms = selectedPerms;

                    appliances.map(m => {
                        selectedAppliances.push({
                            label: m.translation ? m.translation.name : m.name,
                            value: m.id
                        });
                    });

                    data.selectedAppliances = selectedAppliances;

                    setPopup(prev => ({
                        ...prev,
                        data: data,
                    }));
                });
        }
    }, [popup.edit]);

    useEffect(() => {
        Api.get('partners/employees/permissions?partner_id=' + params.id)
            .then(res => {

                let data = [];

                res.data.map(m => {
                    data.push({
                        label: m.translation ? m.translation.name : m.name,
                        value: m.id
                    });
                });

                setPopup(prev => ({
                    ...prev,
                    perms: data
                }));
            });


        Api.get('partners/categories/all?partner_id=' + params.id)
            .then(res => {

                let data = [];

                res.data.map(m => {
                    data.push({
                        label: m.name,
                        value: m.id
                    });
                });

                // console.log(data);

                setPopup(prev => ({
                    ...prev,
                    appliances: data
                }));
            });
    }, []);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        if (e) {
            e.preventDefault();
        }

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();


        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'partners/employees/edit';
        } else {
            url = 'partners/employees/add';
        }

        let data = new FormData(e.target);

        data.append('partner_id', props.partnerId);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    props.handleUpdate(res.data)
                } else {
                    props.handleCreate(res.data);
                }

                handleHide(e);

                // if (popup.editId === auth.id()) {
                auth.refresh();
                // }

                document.getElementById('employee-form').reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        if (input === 'max_employees_reached') {
                            handleHide();

                            let modal = maxEmployeesErrorModal.current;

                            modal.open();
                            modal.onClose(() => {
                                handleShow();
                            });
                        }

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleDateChange = e => {
        let name = e.target.name + '_ymd';
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleActiveChange = e => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                active: !prev.data.active
            }
        }));
    }

    const handlePermChange = data => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                selectedPerms: data
            }
        }));
    }

    const handleAppliancesChange = data => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                selectedAppliances: data
            }
        }));
    }

    const getRole = () => {

        let role = props.roles.filter(r => Number(r.id) === Number(popup.data.role_id))[0];

        if (role) {
            return role.name;
        }

        return null;
    }

    function createMarkup(text) {
        return { __html: text };
    }

    return (
        <>
            <Error
                ref={maxEmployeesErrorModal}
                mainMessage={trans('messages.maxEmployeesReached')}
            />

            <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
                <form id="employee-form" className={`${popup.modal ? 'show' : ''} popup-primary large`} onSubmit={handleSave}>
                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />

                        {popup.edit ? trans('headings.editEmployee') : trans('headings.addEmployee')}

                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>

                    <div className="popup-container x2">
                        <div className="col">
                            <label htmlFor="name">
                                {trans('labels.name')}
                            </label>
                            <input
                                id="name"
                                type="text"
                                name="name"
                                value={popup.data && popup.data.name ? popup.data.name : ''}
                                className={validations && validations.name ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="col">
                            <label htmlFor="lastname">
                                {trans('labels.lastname')}
                            </label>
                            <input
                                id="lastname"
                                type="text"
                                name="lastname"
                                value={popup.data && popup.data.lastname ?
                                    popup.data.lastname
                                    :
                                    ''
                                }
                                className={validations && validations.lastname ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container x2">
                        <div className="col">
                            <label htmlFor="role">
                                {trans('labels.role')}
                            </label>
                            <select
                                id="role"
                                name="role_id"
                                className={validations && validations.role_id ? 'validate' : ''}
                                value={popup.data && popup.data.role_id ? popup.data.role_id : ''}
                                onChange={handleInputChange}
                            >
                                {props.roles.map(r =>
                                    <option
                                        key={'r-' + r.id}
                                        value={r.id}
                                    >
                                        {r.translation.name}
                                    </option>
                                )}
                            </select>
                        </div>

                        <div className="col">

                            <label htmlFor="email">
                                {trans('labels.email')}
                            </label>
                            <input
                                id="email"
                                type="text"
                                name="email"
                                value={popup.data && popup.data.email ?
                                    popup.data.email
                                    :
                                    ''
                                }
                                className={validations && validations.email ? 'validate' : ''}
                                onChange={handleInputChange}
                            />

                        </div>

                    </div>

                    {!popup.edit &&
                        <>
                            <br />
                            <div className="popup-container x2">
                                <div className="col">

                                    <label htmlFor="password">
                                        {trans('labels.password')}
                                    </label>
                                    <input
                                        id="password"
                                        type="password"
                                        name="password"
                                        className={validations && validations.password ? 'validate' : ''}
                                    />

                                </div>

                                <div className="col">

                                    <label htmlFor="password_confirmation">
                                        {trans('labels.repeatPassword')}
                                    </label>
                                    <input
                                        id="password_confirmation"
                                        type="password"
                                        name="password_confirmation"
                                        className={validations && validations.password_confirmation ? 'validate' : ''}
                                    />

                                </div>

                            </div>
                        </>
                    }

                    <br />

                    <div className="popup-container x2">
                        <div className="col">

                            <label htmlFor="address">
                                {trans('labels.address')}
                            </label>
                            <input
                                id="address"
                                type="text"
                                name="address"
                                value={popup.data && popup.data.address ?
                                    popup.data.address
                                    :
                                    ''
                                }
                                className={validations && validations.address ? 'validate' : ''}
                                onChange={handleInputChange}
                            />

                        </div>

                        <div className="col">

                            <label htmlFor="phone">
                                {trans('labels.phone')}
                            </label>
                            <div style={{ display: 'flex' }}>
                                <div style={{ background: '#f2f2f2', height: '100%', display: 'flex', alignItems: 'center', fontSize: '12px', padding: '0 5px', borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px' }}>
                                    {auth.phoneCode()}
                                </div>
                                <input
                                    value={popup.data && popup.data.phone ? popup.data.phone : ''}
                                    type="text"
                                    className={validations && validations.phone ? 'validate' : ''}
                                    name="phone"
                                    onChange={handleInputChange}
                                />
                            </div>

                        </div>
                    </div>

                    <br />

                    <div className="popup-container x2">
                        <div className="col">

                            <label htmlFor="work_time_from">
                                {trans('labels.workTimeFrom')}
                            </label>
                            <input
                                id="work_time_from"
                                type="time"
                                name="work_time_from"
                                value={popup.data && popup.data.work_time_from ?
                                    popup.data.work_time_from
                                    :
                                    ''
                                }
                                className={validations && validations.work_time_from ? 'validate' : ''}
                                onChange={handleInputChange}
                            />

                        </div>

                        <div className="col">
                            <label htmlFor="work_time_to">
                                {trans('labels.workTimeTo')}
                            </label>
                            <input
                                id="work_time_to"
                                type="time"
                                name="work_time_to"
                                value={popup.data && popup.data.work_time_to ?
                                    popup.data.work_time_to
                                    :
                                    ''
                                }
                                className={validations && validations.work_time_to ? 'validate' : ''}
                                onChange={handleInputChange}
                            />

                        </div>
                    </div>

                    <br />

                    <div className="popup-container x2">

                        <div className="col">

                            <label htmlFor="max_reservations">
                                {trans('labels.maxReservations')}
                            </label>

                            <input
                                id="max_reservations"
                                type="number"
                                min="0"
                                step="1"
                                name="max_reservations"
                                value={popup.data && popup.data.max_reservations ?
                                    popup.data.max_reservations
                                    :
                                    ''
                                }
                                className={validations && validations.max_reservations ? 'validate' : ''}
                                onChange={handleInputChange}
                            />

                        </div>

                        <div className="col">
                            <label className="flex">
                                {trans('labels.allowAutoGPS')}
                                <Help position="right-center" style={{ marginLeft: '5px' }}>
                                    <div dangerouslySetInnerHTML={createMarkup(trans('random.allowAutoGPS'))} />
                                </Help>
                            </label>
                            <select
                                name="allow_auto_gps_changes"
                                value={popup.data?.allow_auto_gps_changes !== null ? Number(popup.data?.allow_auto_gps_changes) : 1}
                                className={validations && validations.max_reservations ? 'validate' : ''}
                                onChange={handleInputChange}
                            >
                                <option value={1}>
                                    {trans('labels.yes')}
                                </option>
                                <option value={0}>
                                    {trans('labels.no')}
                                </option>
                            </select>
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label htmlFor="image">
                                {trans('labels.picture')}
                            </label>
                            <input type="file" name="image" className={validations && validations.image ? 'validate' : ''} />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container x2">

                        <div className="col">
                            <label htmlFor="voicecall_phone">
                                {trans('labels.voiceCallPhone')}
                            </label>

                            <input
                                id="voicecall_phone"
                                type="text"
                                name="voicecall_phone"
                                value={popup.data && popup.data.voicecall_phone ?
                                    popup.data.voicecall_phone
                                    :
                                    ''
                                }
                                className={validations && validations.voicecall_phone ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="col">
                            <label htmlFor="leaving_date">
                                {trans('labels.leaveDate')}
                            </label>

                            <input
                                id="leaving_date"
                                type="date"
                                name="leaving_date"
                                value={popup.data && popup.data.leaving_date_ymd ?
                                    popup.data.leaving_date_ymd
                                    :
                                    ''
                                }
                                className={validations && validations.leaving_date ? 'validate' : ''}
                                onChange={handleDateChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">

                            <div className="checkbox-primary">
                                <label htmlFor="active">
                                    {trans('labels.active')}
                                    <input
                                        id="active"
                                        type="checkbox"
                                        name="active"
                                        value="1"
                                        checked={popup.data
                                            ? popup.data.active ? true : false
                                            : true
                                        }
                                        className={validations && validations.active ? 'validate' : ''}
                                        onChange={handleActiveChange}
                                    />
                                    <div className="checkmark"></div>
                                </label>

                            </div>
                        </div>
                    </div>

                    {getRole() === 'technician'
                        ?
                        <>
                            <br />
                            <div className="popup-container">
                                <div className="col">
                                    <label>
                                        {trans('labels.employeeAppliances')}
                                    </label>

                                    <MultiSelect
                                        options={popup.appliances}
                                        value={popup.data.selectedAppliances}
                                        onChange={handleAppliancesChange}
                                        hasSelectAll={false}
                                        disableSearch={true}
                                        className="dropdown-top"
                                        overrideStrings={{
                                            "selectSomeItems": trans('labels.choose'),
                                            "allItemsAreSelected": trans('labels.allSelected'),
                                            "selectAll": trans('labels.selectAll'),
                                            "search": trans('labels.search')
                                        }}
                                    />

                                    {popup.data.selectedAppliances.map(s =>
                                        <input key={'app-' + s.value} type="hidden" name="appliances[]" value={s.value} />
                                    )}
                                </div>
                            </div>
                        </>
                        :
                        ''
                    }

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.accessTo')}
                            </label>

                            <MultiSelect
                                options={popup.perms}
                                value={popup.data.selectedPerms}
                                onChange={handlePermChange}
                                hasSelectAll={false}
                                disableSearch={true}
                                className="dropdown-top"
                                overrideStrings={{
                                    "selectSomeItems": trans('labels.choose'),
                                    "allItemsAreSelected": trans('labels.allSelected'),
                                    "selectAll": trans('labels.selectAll'),
                                    "search": trans('labels.search')
                                }}
                            />

                            {popup.data.selectedPerms.map(s =>
                                <input key={'m-' + s.value} type="hidden" name="perms[]" value={s.value} />
                            )}
                        </div>
                    </div>

                    <div className="footer align-center">
                        {popup.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="button" onClick={handleHide}>
                                    {trans('buttons.cancel')}
                                </button>
                                <button className="button button-primary" type="submit">
                                    {trans('buttons.save')}
                                </button>
                            </>
                        }
                    </div>
                </form>
            </div>
        </>
    )
}

export default forwardRef(AddOrEditEmployee);