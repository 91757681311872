import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';
import PreviewTabs from './PreviewTabs';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import { trans } from '../../../../_providers/Translation';

let previewTimeout;

function AddOrEdit(props, ref) {

    const placeholders = typeof props.placeholders === 'object' ? props.placeholders : {};

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        id: null,
        data: {
            text: '',
        },
        tab: 'text',
        preview: {
            text: ''
        },
        loading: false,
        update: false,
        onSave: null
    });

    const [statuses, setStatuses] = useState([]);

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        edit: id => {
            setPopup(prev => ({
                ...prev,
                id: id,
                update: new Date().getTime()
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    useEffect(() => {
        if (popup.id) {
            Api.get('parts-status-sms/find?id=' + popup.id)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data
                    }))
                });
        }
    }, [popup.update]);

    useEffect(() => {
        Api.get('storage/statuses')
            .then(res => {
                setStatuses(res.data);
            });
    }, []);

    useEffect(() => {
        clearTimeout(previewTimeout);

        previewTimeout = setTimeout(() => {
            Api.get('parts-status-sms/preview', {
                params: {
                    text: popup.data.text
                }
            }).then(res => {
                setPopup(prev => ({
                    ...prev,
                    preview: res.data
                }));
            });
        }, 300);

    }, [popup.data.text]);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false,
                id: null,
                data: {},
                preview: {}
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'parts-status-sms/' + (popup.id ? 'edit' : 'add');

        let data = new FormData(e.target);

        if (popup.id) {
            data.append('id', popup.id);
        }

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave(res.data);
                }

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }


    const handleInputChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleInsertPlaceholder = placeholder => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                text: (prev.data.text || '').trim().concat(' ', placeholder)
            },
            tab: 'text'
        }));
    }

    const handleTab = tab => {
        setPopup(prev => ({
            ...prev,
            tab: tab
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form className={`${popup.modal ? 'show' : ''} popup-primary medium`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {popup.id ? trans('headings.editSms') : trans('headings.addSms')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.status')}
                        </label>
                        <select
                            name="status_id"
                            className={validations && validations.status_id ? 'validate' : ''}
                            value={popup.data.status_id ? popup.data.status_id : ''}
                            onChange={handleInputChange}
                        >
                            {statuses.map(s =>
                                <option key={'is-' + s.id} value={s.id}>
                                    {s.translation ? s.translation.name : s.name}
                                </option>
                            )}
                        </select>
                    </div>


                </div>

                <br />

                <PreviewTabs
                    tab={popup.tab}
                    handleTab={handleTab}
                />

                <div className="popup-container" style={{ display: popup.tab === 'text' ? 'initial' : 'none' }}>
                    <div className="col">
                        <label>
                            {trans('labels.text')}
                        </label>
                        <textarea
                            name="text"
                            className={validations && validations.text ? 'validate' : ''}
                            value={popup.data.text ? popup.data.text : ''}
                            placeholder={trans('random.partsSmsExample')}
                            onChange={handleInputChange}
                        >
                        </textarea>
                    </div>
                </div>

                <div className="popup-container" style={{ display: popup.tab === 'preview' ? 'initial' : 'none' }}>
                    <div className="col">
                        <label>
                            {trans('labels.preview')}
                        </label>
                        <textarea
                            value={popup.preview.text ? popup.preview.text : ''}
                            disabled
                        >
                        </textarea>
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.keywords')}
                        </label>
                        <div className="alert info">
                            <table style={{ backgroundColor: 'transparent' }}>
                                {Object.entries(placeholders).map((placeholder, i) =>
                                    <tr key={i}>
                                        <td>
                                            <b
                                                style={{ cursor: 'pointer' }}
                                                onClick={e => handleInsertPlaceholder(placeholder[0])}
                                            >
                                                {placeholder[0]}
                                            </b>
                                        </td>
                                        <td>
                                            {placeholder[1]}
                                        </td>

                                    </tr>
                                )}
                            </table>

                        </div>
                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);