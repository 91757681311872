import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import { useAuthDataContext } from '../../../../_providers/Auth';
import ImageModal from '../modals/ImageModal';
import LangTabs from '../modals/LangTabs';


//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import deleteIcon from '../../../../assets/img/icons/delete-red.png';
import { trans } from '../../../../_providers/Translation';

function WebPageOptions(props, ref) {

    const imageRef = useRef(null)

    const auth = useAuthDataContext();

    const [files, setFiles] = useState({
        data: []
    })

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        id: null,
        section_id: null,
        data: [],
        lang: [],
        edit: [],
        file: [],
        loading: true,
        update: false,
        onSave: null,
        tab: 1
    });

    let id;

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        edit: (id, section_id) => {
            setPopup(prev => ({
                ...prev,
                id: id,
                section_id: section_id,
                update: new Date().getTime()
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    useEffect(() => {
        if (popup.id) {
            Api.get('website/page/content?section_id=' + popup.section_id + '&partner_id=' + auth.user().partner_id)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data
                    }))
                });
        }
        loadPageItems();
    }, [popup.update]);

    const tabsValue = newValue => {
        setPopup(popup => ({
            ...popup,
            tab: newValue,
        }))
    }

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            edit: [],
            loading: false,
        }));

        setFiles({
            data: []
        })

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false,
                id: null,
                data: []
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = `website/content/set?partner_id=${auth.user().partner_id}&page_id=${popup.id}&section_id=${popup.section_id}`;

        let data = new FormData(e.target);

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {
                setFiles({
                    data: []
                })

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave();
                }

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleAttachFiles = (e, id, index) => {
        let attachments = Array.from(e.target.files)
        attachments.map((t, i) => {
            let tmppath = URL.createObjectURL(e.target.files[i]);
            attachments[i] = { t, tmppath }
        })
        setFiles(prev => ({
            ...prev,
            data: [...prev.data,
            ...attachments
            ]
        }))
    }

    const handleDetachFile = (index, id, lang) => {
        if (id) {
            Api.get(`website/content/delete?id=${id}`);
        }
        let filtered = files && files.data.filter((f, i) => {
            return i !== index;
        })
        setFiles(prev => ({
            ...prev,
            data: filtered
        }))
    }

    const FileSize = size => {
        return size / 1000
    }

    const handleOpen = (e, id) => {
        e.preventDefault();
        let input = document.getElementById(`data=${id}`);
        input.click();
    }

    const loadPageItems = e => {
        Api.get('website/content/find?page_id=' + popup.id + '&section_id=' + popup.section_id + '&partner_id=' + auth.user().partner_id)
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    loading: false,
                    edit: res.data
                }));
                loadImages(res.data)

            }).catch(err => {
                setPopup(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const loadValue = (content_id) => {

        let filterContent = popup.edit.filter(t => {
            return t.page_content_id == content_id;
        });

        let filtered = filterContent[0];

        return filtered && filtered.content;
    }

    const loadImages = data => {
        let isImage = data.filter(t => {
            return t.content_type.content_type_id == 2 || t.content_type.content_type_id == 3;
        });
        isImage.map(t => {
            setFiles(prev => ({
                ...prev,
                data: [...prev.data,
                    t
                ]
            }))
        })
    }

    const openModal = image => {
        let modal = imageRef.current;

        modal.show(image);
    }

    const handleOpenImage = (e) => {
        e.preventDefault();
        let input = document.getElementById(`image`);
        input.click();
    }

    const handleAttachFile = (e) => {
        let attachments = Array.from(e.target.files)
        attachments.map((t, i) => {
            let tmppath = URL.createObjectURL(e.target.files[i]);
            attachments[i] = { t, tmppath }
        })
        setFiles(prev => ({
            ...prev,
            data: attachments
        }))
    }

    const handleDetachImage = (index, id, lang) => {
        if (id) {
            Api.get(`website/content/delete?id=${id}`);
        }
        let filtered = popup && popup.file.filter((f, i) => {
            return i !== index;
        })
        setFiles(prev => ({
            ...prev,
            data: filtered
        }))
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary large`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.editContent')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>


                {popup.data.length === 0
                    ?
                    <p>{trans('messages.noContent')}</p>
                    :
                    <>
                        <ImageModal
                            ref={imageRef}
                        />
                        <div style={{ maxHeight: '600px', overflow: 'scroll' }} className='popup-container'>
                            <div className="col">

                                {popup.data && popup.data.map(t =>
                                    <div className='row' style={{ flexWrap: 'wrap', marginBottom: '0px' }}>
                                        {
                                            t.content.name == 'title' ?
                                                <>
                                                    <div className='col'>
                                                        <label>{t.translation.name}</label>
                                                        <input
                                                            type='text'
                                                            name={`content_id[${t.id}][]`}
                                                            defaultValue={loadValue(t.id)}
                                                        />

                                                    </div>
                                                </>

                                                : t.content.name == 'text' ?
                                                    <>
                                                        <div className='col'>
                                                            <label>{t.translation.name}</label>
                                                            <textarea
                                                                type='text'
                                                                name={`content_id[${t.id}][]`}
                                                                defaultValue={loadValue(t.id)}
                                                            />
                                                        </div>
                                                    </>

                                                    : t.content.name == 'images' ?
                                                        <>

                                                            <div className='col'>
                                                                <label>{t.translation.name}</label>
                                                                <div>
                                                                    <input
                                                                        id={`data=${t.id}`}
                                                                        type="file"
                                                                        className='webImage'
                                                                        onChange={e => handleAttachFiles(e, t.id)}
                                                                        accept="image/*"
                                                                        name={`content_id[${t.id}][images][]`}
                                                                        multiple
                                                                        hidden
                                                                    />
                                                                    <button className='button button-add' onClick={e => handleOpen(e, t.id)}>
                                                                        {trans('buttons.add')}
                                                                    </button>
                                                                </div>

                                                                <div style={{ overflow: 'auto', fontSize: '13px', maxHeight: '160px', marginTop: '5px' }}>
                                                                    {files.data.map((a, i) =>
                                                                        <div key={'key-' + i}>
                                                                            <div className='row' style={{ alignItems: 'center' }}>
                                                                                {a.image && <img src={a.image} onClick={() => openModal(a.image)} className='imageWeb' />}
                                                                                {a.tmppath && a.tmppath && <img src={a.tmppath} onClick={() => openModal(a.tmppath)} className='imageWeb' />}
                                                                                <div style={{ maxWidth: '220px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className="name">
                                                                                    {a.t && a.t.name ? a.t.name : 'image'}
                                                                                </div>
                                                                                <div style={{ paddingRight: '10px', marginLeft: 'auto', textAlign: 'right' }}>
                                                                                    {a.t && a.t.size ? Math.round(FileSize(a.t.size)) + ' KB' : ''}
                                                                                </div>
                                                                                <img
                                                                                    src={deleteIcon}
                                                                                    alt=""
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => handleDetachFile(i, a.id, t.id)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>

                                                        : t.content.name == 'image' ?

                                                            <>
                                                                <div className='col'>
                                                                    <label>{t.translation.name}</label>
                                                                    <div>
                                                                        <input
                                                                            id='image'
                                                                            type="file"
                                                                            className='webImage'
                                                                            onChange={e => handleAttachFile(e, t.id)}
                                                                            accept="image/*"
                                                                            name={`content_id[${t.id}][images][]`}
                                                                            hidden
                                                                        />
                                                                        <button className='button button-add' onClick={e => handleOpenImage(e, t.id)}>
                                                                            {trans('buttons.add')}
                                                                        </button>
                                                                    </div>

                                                                    <div style={{ overflow: 'auto', fontSize: '13px', maxHeight: '160px', marginTop: '5px' }}>
                                                                        {files.data.map((a, i) =>
                                                                            <div key={'key-' + i}>
                                                                                <div className='row' style={{ alignItems: 'center' }}>
                                                                                    {a.image && <img src={a.image} onClick={() => openModal(a.image)} className='imageWeb' />}
                                                                                    {a.tmppath && a.tmppath && <img src={a.tmppath} onClick={() => openModal(a.tmppath)} className='imageWeb' />}
                                                                                    <div style={{ maxWidth: '220px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className="name">
                                                                                        {a.t && a.t.name ? a.t.name : 'image'}
                                                                                    </div>
                                                                                    <div style={{ paddingRight: '10px', marginLeft: 'auto', textAlign: 'right' }}>
                                                                                        {a.t && a.t.size ? Math.round(FileSize(a.t.size)) + ' KB' : ''}
                                                                                    </div>
                                                                                    <img
                                                                                        src={deleteIcon}
                                                                                        alt=""
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => handleDetachImage(i, a.id, t.id)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>

                                                            : ''}
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                }


                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(WebPageOptions);