import React from 'react';
import Options from './Options';
import loader from '../../../../assets/img/loader.svg';
import NoDataFound from '../../../partials/NoDataFound';

function Table(props) {

    return (

        props.loading
            ?
            <img src={loader} alt="loading" />
            :
            props.suppliers.length === 0
                ?
                <NoDataFound />
                :
                <>
                    <div className="table-scroller">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '50px' }}>ID</th>
                                    <th>Име</th>
                                    <th className="options">Опции</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.suppliers.map(supplier =>
                                    <tr key={'supplier-' + supplier.id}>
                                        <td style={{ width: '50px' }}>{supplier.id}</td>
                                        <td>{supplier.name}</td>
                                        <td>
                                            <Options
                                                id={supplier.id}
                                                handleEdit={props.handleEdit}
                                                handleDelete={props.handleDelete}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
    )
}

export default Table;