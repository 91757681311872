import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from 'react-router-dom';
import { trans } from '../../../../_providers/Translation';

function FilterTabs(props) {
    const history = useHistory();

    const [tabs, setTabs] = useState({
        tab: props.tab,
        ripple: true
    })

    const handleTabs = (e, newValue) => {
        setTabs(prev => ({
            ...prev,
            tab: newValue
        }))

        setTimeout(() => {
            history.push('/expenses/' + newValue)
        }, 250)
    };

    return (
        <Tabs
            value={tabs.tab}
            onChange={handleTabs}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
        >
            <Tab value="" label={trans('tabs.expenses')} disableRipple={tabs.ripple} />
            <Tab value="categories" label={trans('tabs.categories')} disableRipple={tabs.ripple} />
        </Tabs>
    )
}

export default FilterTabs;