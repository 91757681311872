import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import Success from '../../../modals/messages/Success';
import Error from '../../../modals/messages/Error';
import Dropdown from '../../../partials/Dropdown';

function Warranty(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        loading: false,
        data: null,
        id: null,
        edit: false,
    });

    const [validations, setValidations] = useState(null);

    const successModalRef = useRef(null);
    const errorModalRef = useRef(null);
    const inputModelsRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id);
        },
        hide: () => {
            handleHide();
        }
    }));

    useEffect(() => {
        if (popup.edit) {
            loadData();
        }
    }, [popup.edit]);

    const loadData = () => {
        if (!popup.id) {
            return;
        }

        Api.get(`contractors/warranties/find?id=${popup.id}`)
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    data: res.data,
                }));
            });
    }

    const handleShow = (id = null) => {
        setPopup(prev => ({
            ...prev,
            id: id,
            edit: id ? new Date() : null,
        }));

        show();
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            id: null,
            data: null,
            edit: false,
        }));

        setValidations(null);

        hide().then(() => {
            if (typeof props.onClose === 'function') {
                props.onClose();
            }
        });

    }

    const show = async () => {
        return new Promise((resolve, reject) => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
            }));

            setTimeout(() => {
                setPopup(prev => ({
                    ...prev,
                    overlay: true,
                    modal: true
                }));

                return resolve();
            }, 50);
        });
    }

    const hide = async () => {
        return new Promise((resolve, reject) => {
            setPopup(prev => ({
                ...prev,
                modal: false,
                loading: false,
            }));

            setTimeout(() => {
                setPopup(prev => ({
                    ...prev,
                    overlay: false,
                    modal: false,
                }));

                return resolve();
            }, 50);
        });
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'contractors/warranties/' + (popup.id ? 'edit' : 'add');

        let data = new FormData(e.target);

        data.append('contractor_id', props.contractorId);

        if (popup.id) {
            data.append('id', popup.id);
        }

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof props.onSave === 'function') {
                    props.onSave(res.data);
                }

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleTypeChange = data => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                type: data
            }
        }));
    }

    const handleApplianceChange = data => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                appliancecategory: data,
                appliancebrand: {},
                appliancemodel: {}
            }
        }));

        if (inputModelsRef.current) {
            inputModelsRef.current.reset();
        }
    }

    const handleBrandChange = data => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                appliancebrand: data,
                appliancemodel: {}
            }
        }));

        if (inputModelsRef.current) {
            inputModelsRef.current.reset();
        }
    }

    const handleModelChange = data => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                appliancemodel: data,
            }
        }));
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    return (
        <>
            <Success
                ref={successModalRef}
                mainMessage={trans('headings.success6')}
            />

            <Error
                ref={errorModalRef}
                mainMessage={trans('headings.error3')}
            />

            <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
                <form className={`${popup.modal ? 'show' : ''} popup-primary medium`} onSubmit={handleSave}>
                    <input type="hidden" name="contractor_id" value={popup.id} />

                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />

                        {trans('headings.warranty')}
                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.category')}
                            </label>
                            <Dropdown
                                inputName=""
                                inputValue={popup.data?.appliancecategory?.name || ''}
                                inputIdName="appliance_category_id"
                                inputIdValue={popup.data?.appliancecategory?.id || ''}
                                url="partners/categories/all"
                                params={{
                                    id: popup.data?.appliancecategory?.id || null,
                                }}
                                inputClassName={validations && validations.appliance_category_id ? 'validate' : ''}
                                onChange={handleApplianceChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container x2">
                        <div className="col">
                            <label>
                                {trans('labels.make')}
                            </label>
                            <Dropdown
                                inputName=""
                                inputValue={popup.data?.appliancebrand?.name || ''}
                                inputIdName="appliance_brand_id"
                                inputIdValue={popup.data?.appliancebrand?.id || ''}
                                url="partners/brands/all"
                                params={{
                                    id: popup.data?.appliancebrand?.id || null,
                                }}
                                inputClassName={validations && validations.appliance_brand_id ? 'validate' : ''}
                                onChange={handleBrandChange}
                            />
                        </div>

                        <div className="col">
                            <label>
                                {trans('labels.model')}
                            </label>

                            <Dropdown
                                ref={inputModelsRef}
                                inputName="appliance_model"
                                inputIdName="appliance_model_id"
                                inputValue={popup.data?.appliancemodel && popup.data.appliancemodel.name ? popup.data.appliancemodel.name : ''}
                                inputIdValue={popup.data?.appliancemodel && popup.data.appliancemodel.id ? Number(popup.data.appliancemodel.id) : ''}
                                inputClassName={validations?.appliance_model_id ? 'validate' : ''}
                                url="appliances/models/all"
                                params={{
                                    category_id: popup.data?.appliancecategory && popup.data.appliancecategory.id ? Number(popup.data.appliancecategory.id) : null,
                                    brand_id: popup.data?.appliancebrand && popup.data.appliancebrand.id ? Number(popup.data.appliancebrand.id) : null,
                                }}
                                requiredParams={['category_id', 'brand_id']}
                                onChange={handleModelChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.serialNo')}
                            </label>
                            <input
                                value={popup.data && popup.data.serial_no ? popup.data.serial_no : ''}
                                type="text"
                                name="serial_no"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="col">
                            <label>
                                {trans('labels.productCode')}
                            </label>
                            <input
                                type="text"
                                name="product_code"
                                value={popup.data && popup.data.product_code ? popup.data.product_code : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="footer align-center">
                        {popup.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="button" onClick={handleHide}>
                                    {trans('buttons.cancel')}
                                </button>
                                <button className="button button-primary" type="submit">
                                    {trans('buttons.save')}
                                </button>
                            </>
                        }
                    </div>
                </form>
            </div>
        </>
    )
}

export default forwardRef(Warranty);