import React, { createContext, useContext, useMemo, useState, useEffect } from 'react'
import { useAuthDataContext } from './Auth'
import Api from '../_helpers/Api'
import { useSocketContext } from './Socket'
import sound from '../assets/voice-2.ogg'

export const MessagesContext = createContext({})

let whispers = {};
let whispersTimeout = {};

const MessagesProvider = props => {

    const auth = useAuthDataContext();
    const listener = useSocketContext();
    const userId = auth.id();
    const userType = auth.type();
    const partnerId = auth.partnerId();

    const audio = new Audio(sound);

    const [state, setState] = useState({
        fromSocket: [],
        read: [],
        whispers: {},
        openChats: [],
        count: 0,
        lastMessage: null,
    });

    const loadCount = (syncTabs = true) => {
        Api.get('messages/total')
            .then(res => {
                setCount(res.data);

                if (syncTabs) {
                    localStorage.setItem('Messages_loadCount', res.data);
                }
            });
    }

    const setCount = count => {
        setState(prev => ({
            ...prev,
            count: count
        }))
    }

    const decrementCount = () => {
        setState(prev => ({
            ...prev,
            count: prev.count > 1 ? prev.count - 1 : 0
        }))
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            fromSocket: [],
            count: 0,
        }));
    }

    const openChat = (id, type, reservationId = '') => {

        if (!auth.access('livechat')) {
            return;
        }

        let fullUserId = id + '.' + type;

        let chat = {
            chatId: Math.random().toString().substring(3),
            id: id,
            type: type,
            reservationId: reservationId,
            fullId: fullUserId
        };

        let openChats = [...state.openChats];

        let exists = openChats.filter(c => c.fullId === fullUserId && c.reservationId === reservationId).length;

        if (!exists) {

            if (openChats.length > 2) {
                openChats.pop();
            }

            openChats = [...openChats, chat];

            setState(prev => ({
                ...prev,
                openChats: openChats
            }));
        }

    }

    const closeChat = id => {
        let filtered = state.openChats.filter(c => {
            return c.chatId !== id;
        });

        setState(prev => ({
            ...prev,
            openChats: filtered
        }));
    }

    const whisper = (id, type, user) => {
        listener.private(`whispers.${id}.${type}`)
            .whisper('typing', {
                user: user,
            });
    }

    const setLastMessage = msg => {
        setState(prev => ({
            ...prev,
            lastMessage: msg
        }));
    }

    const onStorageUpdate = e => {
        const { key, newValue } = e;

        // console.log(key, newValue);

        if (key === 'Messages_loadCount') {
            setCount(newValue);
        }
    }

    useMemo(() => {
        if (userId) {
            loadCount();

            listener.private(`messages.${userId}.${userType}`)
                .listen('MessageReceived', message => {
                    // console.log(message);

                    setState(prev => ({
                        ...prev,
                        fromSocket: prev.fromSocket.concat(message.message),
                        // count: prev.count + 1
                    }));

                    loadCount();

                    audio.play();
                })
                .listen('MessageRead', message => {
                    // console.log(message);

                    setState(prev => ({
                        ...prev,
                        read: prev.read.concat(message.message),
                    }));
                });

            listener.private(`reservation-messages.${partnerId}`)
                .listen('MessageReceived', message => {
                    // console.log(message);

                    // let fromMe = Number(message.message.author.id) === Number(userId) && message.message.author.type === userType;

                    let shouldReceive;

                    if (message.message.receiver) {
                        shouldReceive = Number(message.message.receiver.id) === Number(userId) && message.message.receiver.type === userType;
                    } else {
                        shouldReceive = true;
                    }

                    setState(prev => ({
                        ...prev,
                        fromSocket: prev.fromSocket.concat(message.message),
                        // count: shouldReceive ? prev.count + 1 : prev.count
                    }));

                    if (shouldReceive) {
                        loadCount();

                        audio.play();
                    }
                });

            listener.private(`whispers.${userId}.${userType}`)
                .listenForWhisper('typing', e => {

                    let id = e.user.id + '.' + e.user.type;

                    whispers[id] = e.user;

                    setState(prev => ({
                        ...prev,
                        whispers: whispers
                    }));

                    if (whispersTimeout[id]) {
                        clearTimeout(whispersTimeout[id]);
                    }

                    whispersTimeout[id] = setTimeout(() => {

                        delete whispers[id];

                        setState(prev => ({
                            ...prev,
                            whispers: whispers
                        }));

                    }, 1000);
                });

        }
    }, []);

    useEffect(() => {
        window.addEventListener("storage", onStorageUpdate);
        return () => {
            window.removeEventListener("storage", onStorageUpdate);
        };
    }, []);

    const data = useMemo(() => ({
        ...state, loadCount, decrementCount, whisper, clear, openChat, closeChat, setLastMessage
    }), [state]);

    return <MessagesContext.Provider value={data} {...props} />
}

export const useMessagesContext = () => useContext(MessagesContext);

export default MessagesProvider;