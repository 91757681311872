import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import Success from '../../../modals/messages/Success';
import Error from '../../../modals/messages/Error';

function SendMessage(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        loading: false,
    });

    const [validations, setValidations] = useState(null);

    const successModalRef = useRef(null);
    const errorModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id);
        },
        hide: () => {
            handleHide();
        }
    }));

    const handleShow = (id) => {
        setPopup(prev => ({
            ...prev,
            overlay: true,
            id: id,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            id: null,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'contractors/mails/send';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (res.data.success) {
                    successModalRef.current.open();
                } else {
                    errorModalRef.current.open();
                }

                document.getElementById('form').reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    return (
        <>
            <Success
                ref={successModalRef}
                mainMessage={trans('headings.success6')}
            />

            <Error
                ref={errorModalRef}
                mainMessage={trans('headings.error3')}
            />

            <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
                <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary medium`} onSubmit={handleSave}>
                    <input type="hidden" name="contractor_id" value={popup.id} />

                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />

                        {trans('headings.sendingMessage')}
                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.heading')}
                            </label>
                            <input
                                type="text"
                                name="subject"
                                className={validations && validations.name ? 'validate' : ''}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.message')}
                            </label>
                            <textarea
                                name="message"
                                className={validations && validations.message ? 'validate' : ''}
                            >
                            </textarea>
                        </div>
                    </div>

                    <div className="footer align-center">
                        {popup.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="button" onClick={handleHide}>
                                    {trans('buttons.cancel')}
                                </button>
                                <button className="button button-primary" type="submit">
                                    {trans('buttons.send')}
                                </button>
                            </>
                        }
                    </div>
                </form>
            </div>
        </>
    )
}

export default forwardRef(SendMessage);