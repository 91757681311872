import React from 'react'
import ReactPaginate from 'react-paginate';

function Pagination(props) {
    return (
        props.pages > 1 ?
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                // breakClassName={'break-me'}
                pageCount={props.pages}
                initialPage={props.page - 1}
                forcePage={props.page - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={props.handlePage}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                disableInitialCallback={true}
            />
            :
            ''

    )
}

export default Pagination