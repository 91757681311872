import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import Table from './partials/TableVanStock';
import TableSummary from './partials/TableVanStockSummary';
import Api from '../../../_helpers/Api';
import Pagination from '../../Pagination';
import { useHistory, useLocation } from 'react-router-dom';

//modals
import AddOrEdit from './partials/AddOrEdit';
import Question from '../../modals/messages/Question';
import Filter from './partials/Filter';
import History from './partials/History';
import TransferToOffice from './partials/TransferToOffice';

import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../_providers/Translation';

let timeout;

function VanStock(props, ref) {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const modalAddOrEditRef = useRef(null);
    const modalQuestionRef = useRef(null);
    const modalHistoryRef = useRef(null);
    const modalTransferToOfficeRef = useRef(null);

    const [parts, setParts] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {
            stock: 'van',
            instock: '1',
        },
        setFilter: false,
        update: false,
    });

    useImperativeHandle(ref, () => ({
        add: () => {
            handleAdd();
        }
    }));

    useEffect(() => {
        setParts(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                category_id: searchParams.get('category_id') || '',
                name: searchParams.get('name') || '',
                number: searchParams.get('number') || '',
                supplier_id: searchParams.get('supplier_id') || '',
                summary: searchParams.get('summary') || 0,
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {
        if (parts.setFilter) {
            searchParams.set('page', parts.page);

            Object.entries(parts.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            history.push('?' + searchParams.toString());
        }

    }, [parts.setFilter]);

    useEffect(() => {
        if (parts.update) {
            loadParts();
        }
    }, [parts.update])

    const loadParts = () => {

        let url = 'storage/stock-parts?page=' + parts.page;

        Object.entries(parts.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        setParts(prev => ({
            ...prev,
            loading: true
        }));

        Api.get(url)
            .then(res => {
                setParts(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }));
            }).catch(err => {
                setParts(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add('van');
    }

    const handleCreate = part => {
        handleRefresh();
    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id);
    }

    const handleUpdate = part => {
        handleReload();
    }

    // delete
    const handleDelete = id => {
        Api.post('storage/remove-part', {
            id: id
        }).then(res => {
            if (res.data.success) {
                // let data = parts.data.filter(i => {
                //     return i.id !== id;
                // });
                handleRefresh();
            }
        });
    }

    const handleRefresh = () => {
        setParts(prev => ({
            ...prev,
            page: 1,
            update: new Date().getTime()
        }));
    }

    const handleReload = () => {
        setParts(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    // Question modal
    const handleOpenQuestion = id => {
        modalQuestionRef.current.open(id);
    }

    // Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setParts(prev => ({
            ...prev,
            loading: true,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setParts(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const handlePage = page => {
        setParts(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    const handleHistory = id => {
        modalHistoryRef.current.show(id);

        // history.replace('?subject_id=' + id + '&subject_type=' + 'App\\Storage');
    }

    const handleTransferToOffice = id => {
        modalTransferToOfficeRef.current.show(id);
    }

    return (
        <>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <History
                ref={modalHistoryRef}
            />

            <TransferToOffice
                ref={modalTransferToOfficeRef}
                handleRefresh={handleRefresh}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
                callback={handleDelete}
            />

            <Filter
                filter={parts.filter}
                showSummary={true}
                handleSearch={handleSearch}
            />

            <div className="pannel">
                <h3>
                    {trans('random.vansPartsInStock')}
                </h3>

                {parts.loading
                    ?
                    <Skeleton height={45} count={10} />
                    :
                    <>
                        {Number(parts.filter.summary) === 1
                            ?
                            <TableSummary
                                parts={parts.data}
                                filter={parts.filter}
                            />
                            :
                            <Table
                                parts={parts.data}
                                filter={parts.filter}
                                handleEdit={handleEdit}
                                handleDelete={handleOpenQuestion}
                                handleHistory={handleHistory}
                                handleReload={handleReload}
                                handleTransferToOffice={handleTransferToOffice}
                            />
                        }

                        <Pagination
                            pages={parts.pages}
                            page={parts.page}
                            handlePage={handlePage}
                        />
                    </>
                }
            </div>

        </>
    )
}

export default forwardRef(VanStock);