import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { useAuthDataContext } from '../_providers/Auth';

function ProtectedRoute({ component: Component, ...rest }) {

    const auth = useAuthDataContext();

    return (
        <Route
            {...rest}
            render={props => {
                if (auth.isLogged) {
                    if (typeof rest.access !== 'undefined') {
                        if (!rest.access) {
                            return <h1>Unauthorized</h1>
                        }
                    }
                    return <Component {...props} />
                } else {
                    return <Redirect to="/login/admin" />
                }
            }}
        />
    )
}

export default ProtectedRoute;
