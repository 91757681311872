import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import Api from '../../../_helpers/Api'
import { fromInfo, trans } from '../../../_providers/Translation';

//partials
import Table from './partials/Table'
import Pagination from '../../Pagination';
import Help from '../../partials/Help';
import SelectReservationType from './partials/modals/SelectReservationType';

let timeout;

function Index() {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const selectReservationTypeModalRef = useRef(null);

    const [reservations, setReservations] = useState({
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {

        },
        loading: true,
        setFilter: false,
        update: false,
    })

    useEffect(() => {

        let page = searchParams.get('page') || 1;

        let filter = {
            res_type_id: searchParams.get('res_type_id') || '',
            employee_id: searchParams.get('employee_id') || '',
            added_by: searchParams.get('added_by') || '',
            status: searchParams.get('status') || '',
            initial_status: searchParams.get('initial_status') || '',
            final_status: searchParams.get('final_status') || '',
            date: searchParams.get('date') || '',
            deadline: searchParams.get('deadline') || '',
            search: searchParams.get('search') || '',
        };

        let shouldUpdate = (JSON.stringify(reservations.filter) !== JSON.stringify(filter) || page !== reservations.page);

        setReservations(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: filter,
            update: shouldUpdate ? new Date().getTime() : prev.update
        }))

    }, [location.search])

    useEffect(() => {

        if (reservations.setFilter) {
            let url = '?page=' + reservations.page;

            Object.entries(reservations.filter).map(filter => {
                if (filter[1]) {
                    url += '&' + filter[0] + '=' + filter[1];
                }
            });

            history.push(url);
        }

    }, [reservations.setFilter]);

    useEffect(() => {
        if (reservations.update) {
            loadData()
        }
    }, [reservations.update])


    const loadData = () => {

        let url = 'reservations/all?page=' + reservations.page;

        Object.entries(reservations.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.post(url)
            .then(res => {
                setReservations(reservations => ({
                    ...reservations,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                    loading: false,
                }))
            })
    }

    const handleSearch = (key, val) => {

        clearTimeout(timeout);

        setReservations(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setReservations(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);

    }

    const handlePage = page => {
        setReservations(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }))
    }

    const handleRefresh = () => {
        setReservations(prev => ({
            ...prev,
            update: new Date().getTime()
        }))
    }

    const handleAddAddressReservation = () => {

        if (isMobile()) {
            return showSelectReservationTypeModal();
        }

        history.push('/reservations/address/add');
    }

    const handleAddServiceReservation = () => {

        if (isMobile()) {
            return showSelectReservationTypeModal();
        }

        history.push('/reservations/service/add');
    }

    const showSelectReservationTypeModal = () => {

        let modal = selectReservationTypeModalRef.current;

        if (modal) {
            modal.show();
        }
    }

    const isMobile = () => {
        return window.innerWidth <= 1200;
    }

    return (
        <>

            <SelectReservationType
                ref={selectReservationTypeModalRef}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.reservations')} ({reservations.total}) <Help>{fromInfo('reservations')}</Help>
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.reservations')}
                        </p>
                    </div>
                    <div className="right" style={{ display: 'flex' }}>
                        <Link>
                            <button className="button button-add" onClick={handleAddAddressReservation}>
                                {trans('buttons.addAddressReservation')}
                            </button>
                        </Link>
                        <Link>
                            <button className="button button-add" onClick={handleAddServiceReservation} style={{ marginLeft: '10px' }}>
                                {trans('buttons.addServiceReservation')}
                            </button>
                        </Link>
                    </div>
                </div>

                <Table
                    loading={reservations.loading}
                    data={reservations.data}
                    filter={reservations.filter}
                    handlePage={handlePage}
                    handleSearch={handleSearch}
                    handleRefresh={handleRefresh}
                />

                {!reservations.loading
                    ?
                    <Pagination
                        page={reservations.page}
                        pages={reservations.pages}
                        handlePage={handlePage}
                    />
                    :
                    ''
                }

            </div>
        </>
    )
}

export default Index