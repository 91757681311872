import React from 'react';

//images
import successIcon from '../../../assets/img/modals/messages/success.png';

function Success(props) {

    const handleOk = () => {
        props.acceptCallback(false);
    }

    return (
        <div className={`${props.blur ? 'visible' : ''} overlay`}>
            <div className={`box success ${props.popup ? 'show' : ''}`}>
                <img src={successIcon} alt="success" />
                <h3>
                    {props.mainMessage}
                </h3>
                <p>
                    {props.secondaryMessage}
                </p>
                <div className="buttons">
                    <button className="button button-primary" onClick={handleOk}>
                        ОК
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Success;