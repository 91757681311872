import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

function HelpModal(props) {

    const ref = useRef(null)

    const isOpen = props.isOpen;

    const [state, setState] = useState({
        x: props.x,
        y: props.y
    });

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const rect = ref.current?.getBoundingClientRect();

        // console.log(rect)

        const width = rect.width;
        const height = rect.height;

        let x = props.x;
        let y = props.y;

        let position = props.position || 'bottom-left';

        switch (position) {
            case 'bottom-left':
                x = x;
                y = y + 14;
                break;
            case 'bottom-right':
                x = x - width + 14;
                y = y + 14;
                break;
            case 'top-left':
                x = x;
                y = y - height;
                break;
            case 'top-right':
                x = x - width;
                y = y - height;
                break;
            case 'left-center':
                x = x + 14;
                y = y - (height / 2) + 7;
                break;
            case 'right-center':
                x = x - width - 7;
                y = y - (height / 2) + 7;
                break;
        }

        setState(prev => ({
            ...prev,
            y: y,
            x: x
        }));

    }, [props.x, props.y]);

    return ReactDOM.createPortal(
        <div ref={ref} className={['help-modal', isOpen ? 'visible' : 'hidden'].join(' ')} style={{ ...(props.style || {}), left: state.x, top: state.y }}>
            {props.children}
        </div>,
        document.body
    )
}

export default HelpModal;