import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';
import { useCurrencyContext } from '../../../../_providers/Currency';
import { trans } from '../../../../_providers/Translation'

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import { useLanguageContext } from '../../../../_providers/Language';
import MultiSelect from "react-multi-select-component";
import Langs from './Langs';
import Currencies from './Currencies';

function AddOrEdit(props, ref) {

    const languages = useLanguageContext();
    const currencies = useCurrencyContext();

    const defaultData = {
        langs: {},
        selectedModules: [],
        prices_keyed: {},
        additional_employee_prices_keyed: {}
    };

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: defaultData,
        loading: false,
        branches: [],
        tab: languages[0] ? languages[0].id : 0,
        currencyTab: currencies[0] ? currencies[0].id : 0
    });

    const [modules, setModules] = useState([]);
    const [periods, setPeriods] = useState([]);

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: defaultData
            }));
            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                data: defaultData,
                loading: false
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            Api.get(`licenses/find?id=${popup.editId}`)
                .then(res => {

                    let data = res.data;
                    let modules = res.data.modules || [];
                    let selectedModules = [];

                    modules.map(m => {
                        selectedModules.push({
                            label: m.translation ? m.translation.name : '',
                            value: m.id
                        });
                    });

                    data.selectedModules = selectedModules;

                    setPopup(prev => ({
                        ...prev,
                        data: data,
                    }));
                });
        }

    }, [popup.edit]);

    useEffect(() => {
        if (languages.length) {
            setPopup(prev => ({ ...prev, tab: languages[0].id }));
        }
    }, [languages, popup.edit]);

    useEffect(() => {
        if (currencies.length) {
            setPopup(prev => ({ ...prev, currencyTab: currencies[0].id }));
        }
    }, [currencies, popup.edit]);

    useEffect(() => {
        Api.get('branches/all')
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    branches: res.data
                }))
            });

        Api.get('licenses/periods')
            .then(res => {
                setPeriods(res.data);
            });
    }, []);

    useEffect(() => {

        if (!popup.data.branch_id) {
            return;
        }

        Api.get('branches/modules?id=' + popup.data.branch_id)
            .then(res => {

                let data = [];

                res.data.map(m => {
                    data.push({
                        label: m.translation ? m.translation.name : m.name,
                        value: m.id
                    });
                });

                setModules(data);
            });
    }, [popup.data.branch_id]);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'licenses/edit';
        } else {
            url = 'licenses/add';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    props.handleUpdate(res.data)
                } else {
                    props.handleCreate(res.data);
                }

                handleHide(e);

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleBranchChange = e => {
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                branch_id: val,
                selectedModules: []
            }
        }));
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleLangChange = (e, langId) => {
        let name = e.target.name;
        let val = e.target.value;

        let langs = {};

        if (popup.data.langs) {
            langs = Object.assign({}, popup.data.langs);
        }

        if (!langs[langId]) {
            langs[langId] = {};
        }

        langs[langId].name = val;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                langs: langs
            }
        }));
    }

    const handleModulesChange = data => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                selectedModules: data
            }
        }));
    }

    const handlePriceChange = (currencyId, periodId, price) => {

        let prices = Object.assign({}, popup.data.prices_keyed);

        if (!prices[currencyId]) {
            prices[currencyId] = {};
        }

        if (!prices[currencyId][periodId]) {
            prices[currencyId][periodId] = 0;
        }

        prices[currencyId][periodId] = price;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                prices_keyed: prices
            }
        }));
    }

    const handleAdditionalEmployeePriceChange = (currencyId, price) => {
        let prices = Object.assign({}, popup.data.additional_employee_prices_keyed);

        if (!prices[currencyId]) {
            prices[currencyId] = {};
        }

        prices[currencyId] = price;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                additional_employee_prices_keyed: prices
            }
        }));
    }

    const tabsValue = newValue => {
        setPopup(popup => ({
            ...popup,
            tab: newValue,
        }))
    }

    const handleCurrencyTabChange = newValue => {
        setPopup(popup => ({
            ...popup,
            currencyTab: newValue,
        }))
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary medium`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {popup.edit ? trans('headings.editLicense') : trans('headings.addLicense')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="scrollable" style={{ maxHeight: '65vh' }}>
                    <Langs
                        tab={popup.tab}
                        callbackValue={tabsValue}
                    />
                    <div className="popup-container">
                        <div className="col">

                            {languages.map(lang =>
                                <div key={'l' + lang.id} className="field-primary" style={{ display: popup.tab === lang.id ? 'block' : 'none' }}>
                                    <label>
                                        {trans('labels.name')}
                                    </label>
                                    <input
                                        type="text"
                                        name={`lang[${lang.id}]`}
                                        value={popup.data && popup.data.langs && popup.data.langs[lang.id] ?
                                            popup.data.langs[lang.id].name
                                            :
                                            ''
                                        }
                                        onChange={e => handleLangChange(e, lang.id)}
                                        className={validations && validations[`lang.${lang.id}`] ? 'validate' : ''}
                                    />
                                </div>
                            )}

                        </div>
                    </div>

                    <br />

                    <div className="popup-container x2">
                        <div className="col">
                            <label>
                                {trans('labels.branch')}
                            </label>
                            <select
                                name="branch_id"
                                className={validations && validations.branch_id ? 'validate' : ''}
                                value={popup.data && popup.data.branch_id ? popup.data.branch_id : ''}
                                onChange={handleBranchChange}
                            >
                                <option value="">{trans('random.select')}</option>
                                {popup.branches.map(c => {
                                    return <option
                                        key={'br-' + c.id}
                                        value={c.id}
                                    >{c.translation.name}</option>
                                })}
                            </select>

                        </div>

                        <div className="col">
                            <label>
                                {trans('labels.maxEmployees')}
                            </label>
                            <input
                                id="max_employees"
                                type="number"
                                name="max_employees"
                                min="1"
                                step="1"
                                value={popup.data && popup.data.max_employees ?
                                    popup.data.max_employees
                                    :
                                    ''
                                }
                                className={validations && validations.max_employees ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.modulesIncluded')}
                            </label>

                            <MultiSelect
                                options={modules}
                                value={popup.data.selectedModules}
                                onChange={handleModulesChange}
                                hasSelectAll={false}
                                disableSearch={true}
                                overrideStrings={{
                                    "selectSomeItems": !popup.data?.branch_id ? trans('labels.selectBranch') : trans('labels.select'),
                                    "allItemsAreSelected": trans('labels.allSelected'),
                                    "selectAll": trans('labels.selectAll'),
                                    "search": trans('labels.search')
                                }}
                            // className="dropdown-top"
                            />

                            {popup.data.selectedModules.map(s =>
                                <input key={'m-' + s.value} type="hidden" name="modules[]" value={s.value} />
                            )}
                        </div>

                    </div>

                    <br />

                    <Currencies
                        tab={popup.currencyTab}
                        callbackValue={handleCurrencyTabChange}
                    />

                    {currencies.map(c =>
                        <div key={'c' + c.id} className="field-primary" style={{ display: popup.currencyTab === c.id ? 'block' : 'none' }}>
                            {periods.map(p =>
                                <React.Fragment key={'period-' + p.id}>
                                    <br />

                                    <div className="popup-container">
                                        <div className="col">

                                            <label>
                                                {trans('labels.priceFor')} {p.validation} {trans('labels.months')}
                                            </label>
                                            <input
                                                id="price"
                                                type="number"
                                                name={`prices[${c.id}][${p.id}]`}
                                                min="0"
                                                step="0.01"
                                                value={popup.data && popup.data.prices_keyed && popup.data.prices_keyed[c.id] && popup.data.prices_keyed[c.id][p.id] ?
                                                    popup.data.prices_keyed[c.id][p.id]
                                                    :
                                                    ''
                                                }
                                                className={validations && validations.prices && validations.prices[c.id][p.id] ? 'validate' : ''}
                                                onChange={e => handlePriceChange(c.id, p.id, e.target.value)}
                                            />

                                        </div>

                                    </div>
                                </React.Fragment>
                            )}

                            <br />

                            <div className="popup-container">
                                <div className="col">
                                    <label>
                                        {trans('labels.additionalEmployeePrice')}
                                    </label>
                                    <input
                                        type="number"
                                        name={`additional_employee_prices[${c.id}]`}
                                        min="0"
                                        step="0.01"
                                        value={popup.data && popup.data.additional_employee_prices_keyed && popup.data.additional_employee_prices_keyed[c.id] ?
                                            popup.data.additional_employee_prices_keyed[c.id]
                                            :
                                            ''
                                        }
                                        className={validations && validations.additional_employee_prices && validations.additional_employee_prices[c.id] ? 'validate' : ''}
                                        onChange={e => handleAdditionalEmployeePriceChange(c.id, e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}


                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);