import React, { useState, useEffect, useRef } from 'react'
import Api from '../../../../_helpers/Api'
import { useParams, useHistory } from 'react-router-dom'
import { useAuthDataContext } from '../../../../_providers/Auth'
import { trans } from '../../../../_providers/Translation';

//images
import deleteIcon from '../../../../assets/img/icons/delete-red.png'
import editIcon from '../../../../assets/img/icons/edit-yellow.png'
import loader from '../../../../assets/img/loader.svg'

//modals
import QuestionModal from '../../../modals/messages/Question'
import EditComment from './modals/EditComment'

function Comments(props) {
    const auth = useAuthDataContext()

    let params = useParams().id
    const history = useHistory()

    //refs
    const questionModalRef = useRef(null)
    const editCommentRef = useRef(null)

    const [comments, setComments] = useState({
        data: {
            fromCustomer: [],
            fromEmployees: []
        },
        loading: true,
    })

    useEffect(() => {
        Api.get(`reservations/comments?id=${params}`)
            .then(res => {

                let fromCustomer = res.data.filter(c => c.employee_id === null);
                let fromEmployees = res.data.filter(c => c.employee_id !== null);

                setComments(comments => ({
                    ...comments,
                    data: {
                        fromCustomer: fromCustomer,
                        fromEmployees: fromEmployees
                    },
                    loading: false,
                }))
            })
    }, [])

    const handleEdit = (comment) => {
        editCommentRef.current.open(comment)
    }

    const handleSend = e => {

        e.preventDefault()

        let url = 'reservations/comments/add';

        let formData = new FormData(e.target);

        formData.append('id', params);

        if (document.getElementById('comment').value == '') return

        Api.post(url, formData)
            .then(res => {
                setComments(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        fromEmployees: [res.data, ...prev.data.fromEmployees]
                    }
                }))

                history.push(`?tab=comments&indicator=${new Date().getTime()}`)

            });

        document.getElementById('comment').value = ''

    }

    const handleUpdate = e => {
        e.preventDefault()

        let url = 'reservations/comments/edit';

        let formData = new FormData(e.target);

        Api.post(url, formData)
            .then(res => {
                let newData = comments.data.fromEmployees.map(c => {
                    if (Number(c.id) === Number(res.data.id)) {
                        c = res.data;
                    }

                    return c;
                });

                setComments(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        fromEmployees: newData
                    }
                }));
            });
    }

    const handleDelete = id => questionModalRef.current.open(id)

    const handleAcceptDelete = id => {
        Api.post('reservations/comments/delete', {
            id: id
        }).then(() => {
            let newData = comments.data.fromEmployees.filter(comment => {
                return comment.id !== id
            })

            setComments(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    fromEmployees: newData
                }
            }))

            history.push(`?tab=comments&indicator=${new Date().getTime()}`)
        })
    }

    const createMarkup = html => {
        return {
            __html: String(html).replaceAll("\n", "<br/>")
        }
    }

    return (
        comments.loading ?
            <img src={loader} alt="loading" />
            :
            <div className="row comments">
                <QuestionModal
                    mainMessage={trans('headings.question3')}
                    secondaryMessage={trans('subheadings    .confirmDelete')}
                    ref={questionModalRef}
                    callback={handleAcceptDelete}
                />
                <EditComment
                    ref={editCommentRef}
                    handleUpdate={handleUpdate}
                />
                <div className="col">
                    <h5>
                        {trans('random.fromClient')}
                    </h5>
                    {comments.data.fromCustomer.length == 0 ?
                        <div className="panel-wrapper no-comments">
                            <p>
                                {trans('messages.noCommentsFromClient')}
                            </p>
                        </div>
                        :
                        comments.data.fromCustomer.map(comment =>
                            <div key={'c-' + comment.id} className="panel-wrapper">
                                <h3>
                                    {comment.author && comment.author.name} {comment.author && comment.author.lastname}
                                </h3>
                                <time>
                                    {comment.created_dmy}
                                </time>
                                <p>
                                    {comment.comment}
                                </p>
                            </div>
                        )}

                </div>
                <div className="col">
                    <h5>
                        {trans('random.fromEmployee')}
                    </h5>
                    {props.data.can_modify &&
                        <form onSubmit={handleSend}>
                            <textarea id="comment" name="comment" placeholder={trans('random.commentPlaceholder')}></textarea>
                            <button>
                                {trans('buttons.comment')}
                            </button>
                        </form>
                    }
                    {comments.data.fromEmployees.length == 0 ?
                        <div className="panel-wrapper no-comments">
                            <p>
                                {trans('messages.noCommentsFromEmployee')}
                            </p>
                        </div>
                        :
                        comments.data.fromEmployees.map(comment =>
                            <div key={'c-' + comment.id} className="panel-wrapper">
                                {comment.editable &&
                                    <div className="options">
                                        <img
                                            src={editIcon}
                                            alt="edit"
                                            onClick={() => handleEdit(comment)}
                                        />
                                        <img
                                            src={deleteIcon}
                                            alt="delete"
                                            onClick={() => handleDelete(comment.id)}
                                        />
                                    </div>}
                                <h3>
                                    {comment.author && comment.author.name} {comment.author && comment.author.lastname}
                                </h3>
                                <time>
                                    {comment.created_dmy}
                                </time>
                                <p dangerouslySetInnerHTML={createMarkup(comment.comment)} />
                            </div>
                        )}

                </div>
            </div>
    )
}

export default Comments