import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import Api from '../../../../_helpers/Api'
import closeIcon from '../../../../assets/img/icons/close.png'
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../../../partials/NoDataFound';
import { trans } from '../../../../_providers/Translation';
import Pagination from '../../../Pagination';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function PartsStats(props, ref) {

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        filter: {
            categoryId: null,
            start: null,
            end: null,
        },
        tab: 'ordered'
    });

    const [ordered, setOrdered] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
    });

    const [stock, setStock] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
    });

    useImperativeHandle(ref, () => ({
        open: (categoryId, start, end, tab = null) => {

            setState(prev => ({
                ...prev,
                filter: {
                    categoryId: Number(categoryId),
                    start: start,
                    end: end,
                },
                tab: ['ordered', 'stock'].indexOf(tab) > -1 ? tab : 'ordered'
            }));

            handleOpen()
        },
        close: () => {
            handleClose()
        }
    }));

    useEffect(() => {
        if (state.filter.start && state.filter.end) {
            loadOrderedParts();
            loadStockParts();
        }
    }, [state.filter]);

    useEffect(() => {
        loadOrderedParts();
    }, [ordered.page]);

    useEffect(() => {
        loadStockParts();
    }, [stock.page]);

    const loadOrderedParts = () => {

        setOrdered(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'accounts/ordered-parts-with-invoices-list'
            + '?category_id=' + state.filter.categoryId
            + '&start=' + state.filter.start
            + '&end=' + state.filter.end
            + '&page=' + ordered.page

        Api.get(url)
            .then(res => {
                setOrdered(prev => ({
                    ...prev,
                    data: res.data.items,
                    pages: res.data.pages,
                    total: res.data.total,
                    loading: false
                }));
            }).finally(() => {
                setOrdered(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    const loadStockParts = () => {

        setStock(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'accounts/stock-parts-with-invoices-list'
            + '?category_id=' + state.filter.categoryId
            + '&start=' + state.filter.start
            + '&end=' + state.filter.end
            + '&page=' + stock.page

        Api.get(url)
            .then(res => {
                setStock(prev => ({
                    ...prev,
                    data: res.data.items,
                    pages: res.data.pages,
                    total: res.data.total,
                    loading: false
                }));
            }).finally(() => {
                setStock(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    const handleOpen = () => {

        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)

    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 50)
    }

    const handleOrderedPage = page => {
        setOrdered(prev => ({ ...prev, page: page.selected + 1 }));
    }

    const handleStockPage = page => {
        setStock(prev => ({ ...prev, page: page.selected + 1 }));
    }

    const handleTab = (e, tab) => {
        setState(prev => ({
            ...prev,
            tab: tab
        }));
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>

            <div className={`${state.modal ? 'show' : ''} popup-primary`}>

                <h2 className="head">
                    {trans('headings.incomes')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleClose} />
                </h2>

                <div className="scrollable">
                    <div className="filter-standard" style={{ paddingTop: 0 }}>

                        <div className="col">
                            <label htmlFor="start">
                                {trans('labels.from')}
                            </label>
                            <input
                                type="date"
                                value={state.filter.start}
                                disabled
                            />
                        </div>

                        <div className="col">
                            <label htmlFor="start">
                                {trans('labels.to')}
                            </label>
                            <input
                                type="date"
                                value={state.filter.end}
                                disabled
                            />
                        </div>
                    </div>

                    <Tabs
                        value={state.tab}
                        onChange={handleTab}
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs"
                    >
                        <Tab value="ordered" label="Части за клиенти" disableRipple />
                        <Tab value="stock" label="Стокови части" disableRipple />
                    </Tabs>


                    {state.tab === 'ordered'
                        ?
                        ordered.loading
                            ?
                            <Skeleton height={45} count={5} />
                            :
                            ordered.data.length === 0
                                ?
                                <NoDataFound />
                                :
                                <>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    {trans('tables.partName')}
                                                </th>
                                                <th>
                                                    {trans('tables.number')}
                                                </th>
                                                <th className="center">
                                                    {trans('tables.quantity')}
                                                </th>
                                                <th>
                                                    {trans('tables.poNumber')}
                                                </th>
                                                <th>
                                                    {trans('tables.paymentDate')}
                                                </th>
                                                <th>
                                                    {trans('tables.grossPrice')}
                                                </th>
                                                <th>
                                                    {trans('tables.paymentMethod')}
                                                </th>
                                                <th>
                                                    {trans('tables.refNo')}
                                                </th>
                                                <th>
                                                    {trans('tables.address')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ordered.data.map(d =>
                                                <tr key={d.id}>
                                                    <td>
                                                        {d.name}
                                                    </td>
                                                    <td>
                                                        {d.number}
                                                    </td>
                                                    <td className="center">
                                                        {d.quantity}
                                                    </td>
                                                    <td>
                                                        {d.po_number}
                                                    </td>
                                                    <td>
                                                        {d.invoice.paid_at_dmy}
                                                    </td>
                                                    <td>
                                                        {d.invoicerow?.total_gross_price_formatted} {d.invoice.currency && d.invoice.currency.name}
                                                    </td>
                                                    <td>
                                                        {d.invoice.paymentmethod
                                                            ?
                                                            d.invoice.paymentmethod.translation
                                                                ? d.invoice.paymentmethod.translation.name
                                                                : d.invoice.paymentmethod.name
                                                            :
                                                            ''
                                                        }
                                                    </td>
                                                    <td>
                                                        {d.reservation
                                                            ?
                                                            <a
                                                                href={'/reservations/edit/' + d.reservation_id} target="_blank"
                                                                className="btn"
                                                                style={{ background: d.reservation.color, color: d.reservation.text_color }}
                                                            >
                                                                {d.reservation.reference_code}
                                                            </a>
                                                            :
                                                            ''
                                                        }
                                                    </td>
                                                    <td>
                                                        {d.reservation && d.reservation.full_address}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <Pagination
                                        pages={ordered.pages}
                                        page={ordered.page}
                                        handlePage={handleOrderedPage}
                                    />
                                </>
                        :
                        stock.loading
                            ?
                            <Skeleton height={45} count={5} />
                            :
                            stock.data.length === 0
                                ?
                                <NoDataFound />
                                :
                                <>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    {trans('tables.partName')}
                                                </th>
                                                <th>
                                                    {trans('tables.number')}
                                                </th>
                                                <th className="center">
                                                    {trans('tables.quantity')}
                                                </th>
                                                <th>
                                                    {trans('tables.poNumber')}
                                                </th>
                                                <th>
                                                    {trans('tables.paymentDate')}
                                                </th>
                                                <th>
                                                    {trans('tables.grossPrice')}
                                                </th>
                                                <th>
                                                    {trans('tables.paymentMethod')}
                                                </th>
                                                <th>
                                                    {trans('tables.refNo')}
                                                </th>
                                                <th>
                                                    {trans('tables.address')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {stock.data.map(d =>
                                                <tr key={d.id}>
                                                    <td>
                                                        {d.storage?.part?.name}
                                                    </td>
                                                    <td>
                                                        {d.storage?.part?.number}
                                                    </td>
                                                    <td className="center">
                                                        {d.quantity}
                                                    </td>
                                                    <td>
                                                        {d.po_number}
                                                    </td>
                                                    <td>
                                                        {d.invoice?.paid_at_dmy}
                                                    </td>
                                                    <td>
                                                        {d.invoicerow?.total_gross_price_formatted} {d.invoice?.currency && d.invoice?.currency?.name}
                                                    </td>
                                                    <td>
                                                        {d.invoice?.paymentmethod
                                                            ?
                                                            d.invoice.paymentmethod.translation
                                                                ? d.invoice.paymentmethod.translation.name
                                                                : d.invoice.paymentmethod.name
                                                            :
                                                            ''
                                                        }
                                                    </td>
                                                    <td>
                                                        {d.reservation
                                                            ?
                                                            <a
                                                                href={'/reservations/edit/' + d.reservation_id} target="_blank"
                                                                className="btn"
                                                                style={{ background: d.reservation.color, color: d.reservation.text_color }}
                                                            >
                                                                {d.reservation.reference_code}
                                                            </a>
                                                            :
                                                            ''
                                                        }
                                                    </td>
                                                    <td>
                                                        {d.reservation && d.reservation.full_address}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <Pagination
                                        pages={stock.pages}
                                        page={stock.page}
                                        handlePage={handleStockPage}
                                    />
                                </>
                    }
                </div>

                <div className="footer align-center">
                    <button onClick={handleClose}>
                        {trans('buttons.close')}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default React.memo(forwardRef(PartsStats), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});