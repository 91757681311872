import React, { useRef, useState, useEffect } from 'react';
import Api from '../../../../../_helpers/Api';
import Skeleton from 'react-loading-skeleton';
import Options from './AddressOptions';
import NoDataFound from '../../../../partials/NoDataFound';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from '../../../../Pagination';
import Question from '../../../../modals/messages/Question';
import { trans } from '../../../../../_providers/Translation';
import AddOrEdit from './modals/Address';

function Addresses(props) {

    const history = useHistory();
    const location = useLocation();

    const modalAddOrEditRef = useRef(null);
    const modalQuestionRef = useRef(null);

    const [addresses, setAddresses] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        refresh: null,
    });

    useEffect(() => {
        loadAddresses()
    }, [addresses.page, addresses.refresh])

    const loadAddresses = () => {

        setAddresses(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'addresses/all?page=' + addresses.page;

        Api.get(url)
            .then(res => {
                setAddresses(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                }));

                if (res.data.pages > 0 && addresses.page > res.data.pages) {
                    refresh();
                }

            }).catch(err => {
                setAddresses(prev => ({
                    ...prev,
                    loading: false
                }));
            });

    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add();
    }

    const handleCreate = address => {
        refresh(1);

        if (typeof props.handleRefresh === 'function') {
            props.handleRefresh();
        }
    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id);
    }

    const handleUpdate = address => {

        let data = addresses.data.map(i => {
            if (i.id == address.id) i = address;
            return i;
        });

        setAddresses(prev => ({
            ...prev,
            data: data
        }));

    }

    // delete
    const handleDelete = id => {
        let modal = modalQuestionRef.current;

        modal.open();

        modal.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleDestroy = id => {
        Api.post('addresses/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh();

                if (typeof props.handleRefresh === 'function') {
                    props.handleRefresh();
                }
            }
        });
    }

    const handlePage = page => {
        setAddresses(prev => ({
            ...prev,
            page: page.selected + 1,
        }));
    }

    const refresh = (page) => {
        setAddresses(prev => ({
            ...prev,
            page: page || prev.page,
            refresh: new Date
        }))
    }

    return (
        <div style={{ width: '100%' }}>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
            />

            <div className="head">
                <h4>{trans('headings.addresses')}</h4>

                <button
                    className="button button-add button-small"
                    onClick={handleAdd}
                >
                    {trans('buttons.add')}
                </button>
            </div>

            {addresses.loading
                ?
                <Skeleton height={35} count={10} />
                :
                <>
                    {addresses.data.length === 0
                        ?
                        <NoDataFound />
                        :
                        <>

                            <div className="table-scroller">
                                <table className="settings">
                                    <thead>
                                        <tr>
                                            <td style={{ width: '50px' }}>ID</td>
                                            {/* <td>{trans('tables.country')}</td>
                                <td>{trans('tables.region')}</td>
                                <td>{trans('tables.town')}</td>
                                <td>{trans('tables.hood')}</td>
                                <td>{trans('tables.street')}</td> */}
                                            <td>{trans('tables.name')}</td>
                                            <td>{trans('tables.postCode')}</td>
                                            <td>{trans('tables.price')}</td>
                                            <td className="options">{trans('tables.options')}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addresses.data.map(address =>
                                            <tr key={'address-' + address.id}>
                                                <td style={{ width: '50px' }}>{address.id}</td>
                                                {/* <td>{address.country}</td>
                                    <td>{address.region}</td>
                                    <td>{address.city}</td>
                                    <td>{address.neighbourhood ? address.neighbourhood : trans('tables.notSelected')}</td>
                                    <td>{address.street ? address.street : trans('tables.notSelected')}</td> */}
                                                <td>{address.name}</td>
                                                <td>{address.postcode}</td>
                                                <td>{address.price ? address.price : trans('tables.notSelected')}</td>
                                                <td>
                                                    <Options
                                                        id={address.id}
                                                        handleEdit={handleEdit}
                                                        handleDelete={handleDelete}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <Pagination
                                pages={addresses.pages}
                                page={addresses.page}
                                handlePage={handlePage}
                            />

                        </>
                    }
                </>
            }
        </div>
    )

}

export default Addresses