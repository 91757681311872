import React, { useEffect, useState, useRef } from 'react';
import { trans } from '../../../_providers/Translation';

import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import Api from '../../../_helpers/Api';
import Chart from "react-google-charts";
import loader from '../../../assets/img/loader.svg';
import Filter from './partials/FilterIncomes';
import '../../../assets/css/accounts.css'
import { useWindowSize } from '../../../_helpers/WindowSizeHook';
import { useAuthDataContext } from '../../../_providers/Auth';
import EmployeeIncomes from './partials/EmployeeIncomes';

let timeout;

function Incomes() {

    const auth = useAuthDataContext();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const windowSize = useWindowSize();
    const searchParams = new URLSearchParams(location.search);

    const employeeIncomesRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: {
            dates: [],
            methods: [],
            employees: []
        },
        filter: {},
        setFilter: false,
        update: false,
        employees: [],
        summary: false,
        key: true,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                start: searchParams.get('start') || '',
                end: searchParams.get('end') || '',
                employee_id: searchParams.get('employee_id') || '',
                group: searchParams.get('group') || '',
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    useEffect(() => {
        if (auth.isAdmin()) {
            return;
        }

        Api.get('partners/employees/technicians')
            .then(res => {
                setState(table => ({
                    ...table,
                    employees: res.data
                }))
            })
    }, [])

    useEffect(() => {

        if (!state.data.dates) {
            return;
        }

        let label = state.data.group === 'month' ? trans('random.month') : trans('random.year');

        let chart;

        // обобщена 
        if (state.summary) {
            chart = [
                [label, trans('random.income')],
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label, d.gross
                ];

                chart.push(arr);
            });
        }
        // по метод на плащане
        else {
            let chartArr = [label];

            state.data.methods.map(c => {
                chartArr.push(c.translation ? c.translation.name : c.name)
            });

            chart = [
                chartArr
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label,
                ];

                state.data.methods.map(c => {
                    arr.push(c.dates[d.date].gross);
                });

                chart.push(arr);
            });

        }

        setState(prev => ({
            ...prev,
            chart: chart
        }))

    }, [state.data, state.summary])

    useEffect(() => {
        console.log(windowSize)
    }, [windowSize.width]);

    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'accounts/incomes';

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    // chart: chart,
                    filter: {
                        ...prev.filter,
                        group: res.data.group,
                        start: res.data.start,
                        end: res.data.end
                    },
                    loading: false
                }));
            });
    }

    const handleSearch = (key, val, delay = 300) => {

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
                setFilter: new Date().getTime()
            }));
        }, delay);

    }

    const toggleSummaryData = val => {
        setState(prev => ({
            ...prev,
            summary: val
        }));
    }

    const showIncomesByEmployee = id => {
        employeeIncomesRef.current.open(id, state.filter.start, state.filter.end);
    }

    const getColumns = () => {
        let arr = [];

        state.data.methods.map((m, i) => {
            arr.push({
                type: 'NumberFormat',
                column: i + 1,
                options: {
                    // prefix: '$',
                    negativeParens: false,
                }
            })
        });

        return arr;
    }

    return (
        <>

            {!auth.isAdmin() &&
                <EmployeeIncomes
                    ref={employeeIncomesRef}
                />
            }

            <Filter
                filter={state.filter}
                summary={state.summary}
                employees={state.employees}
                handleSearch={handleSearch}
                toggleSummaryData={toggleSummaryData}
            />

            <br />

            {state.loading
                ?
                <img className="loader" src={loader} />
                :
                <>
                    <div
                        className="chart"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#fff',
                            padding: '5px'
                        }}>
                        <Chart
                            width={window.innerWidth <= 1024 ? '900px' : '99%'}
                            height={'350px'}
                            chartType="Bar"
                            className="animated-chart-vertical animated-chart-start"
                            loader={'...'}
                            data={state.chart}
                            options={{
                                // Material design options
                                chart: {
                                    title: trans('headings.incomes'),
                                    subtitle: state.data.currency,
                                },

                                legend: {
                                    position: 'left'
                                },
                                allowHtml: true,
                                showRowNumber: true,
                            }}
                            // custom attributes
                            rootProps={{ 'data-testid': '2' }}
                            chartEvents={[
                                {
                                    eventName: "ready",
                                    callback: ({ chartWrapper, google }) => {
                                        const chartEl = chartWrapper.getChart().container;
                                        setTimeout(() => {
                                            chartEl.classList.remove('animated-chart-start')
                                        }, 100)
                                    },
                                }
                            ]}
                            formatters={getColumns()}
                        />
                    </div>

                    <br />

                    {state.data.employees.length > 0
                        ?
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            {trans('tables.employee')}
                                        </th>
                                        {state.data.methods.map(m =>
                                            <th key={'em-' + m.id} className="right">
                                                {m.translation ? m.translation.name : m.name}
                                            </th>
                                        )}
                                        <th className="right">
                                            {trans('tables.total')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.employees.map(e =>
                                        <tr
                                            key={'e-' + e.id}
                                            onClick={() => showIncomesByEmployee(e.id)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <td>
                                                {e.name} {e.lastname}
                                            </td>
                                            {state.data.methods.map(m =>
                                                <td className="right">
                                                    {e.payment_methods[m.id]
                                                        ?
                                                        <span>
                                                            {e.payment_methods[m.id].gross} {state.data.currency}
                                                        </span>
                                                        :
                                                        '0.00'
                                                    }
                                                </td>
                                            )}
                                            <td className="right">
                                                {e.gross} {state.data.currency}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>
                                            {trans('tables.total')}
                                        </td>
                                        {state.data.methods.map(m =>
                                            <td key={'pm-' + m.id} className="right">
                                                <span>
                                                    {m.gross_formatted} {state.data.currency}
                                                </span>
                                            </td>
                                        )}
                                        <td className="right">
                                            {state.data.gross_formatted} {state.data.currency}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        :
                        ''
                    }

                </>
            }

        </>
    )
}

export default Incomes;