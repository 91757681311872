import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import AppliancesCategoriesPricing from '../../../partials/AppliancesCategoriesPricing';

function Settings(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: {},
        loading: false,
        id: null,
        priceTypes: [],
        invoicePeriods: [],
        appliancesCategoriesPrices: []
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        open: id => {
            handleShow(id);
        },
        hide: () => {
            handleHide();
        }
    }));

    useEffect(() => {
        if (
            selectedPriceIsCategory() &&
            popup.appliancesCategoriesPrices.length === 0
        ) {
            Api.get('partners/relationships/settings/categories/prices?related_partner_id=' + popup.id)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        appliancesCategoriesPrices: res.data
                    }));
                });
        }
    }, [popup.data.price_type_id, popup.priceTypes]);

    const handleShow = (id) => {

        loadData(id);
        loadPriceTypes();
        loadInvoicingPeriods();

        setPopup(prev => ({
            ...prev,
            overlay: true,
            id: id
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const loadData = id => {
        Api.get('partners/relationships/show?related_partner_id=' + id)
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    data: res.data
                }));
            });
    }

    const loadPriceTypes = () => {
        Api.get('partners/relationships/price-types')
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    priceTypes: res.data
                }));
            });
    }

    const loadInvoicingPeriods = () => {
        Api.get('partners/relationships/invoicing-periods')
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    invoicePeriods: res.data
                }));
            });
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            data: {},
            appliancesCategoriesPrices: [],
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();


        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'partners/relationships/settings/edit';

        let data = new FormData(e.target);

        data.append('related_partner_id', popup.id);

        Api.post(url, data)
            .then(res => {

                if (res.data.success) {
                    props.handleRefresh();
                }

                handleHide(e);

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleCheckboxChange = e => {
        let name = e.target.name;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: !prev.data[name]
            }
        }));
    }

    const getSelectedPrice = () => {
        if (popup.data && popup.data.price_type_id) {
            let type = popup.priceTypes.filter(t => Number(t.id) === Number(popup.data.price_type_id))[0];

            if (type) {
                return type;
            }
        }

        return null;
    }

    const selectedPriceIsCategory = () => {
        let type = getSelectedPrice();

        if (type) {
            return type.is_category;
        }

        return null;
    }

    const selectedPriceIsFixed = () => {
        let type = getSelectedPrice();

        if (type) {
            return type.is_fixed;
        }

        return null;
    }

    const selectedPriceIsPercent = () => {
        let type = getSelectedPrice();

        if (type) {
            return type.is_percent;
        }

        return null;
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary medium`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.companySettings')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.invoicePeriod')}
                        </label>
                        <select
                            name="invoice_period_id"
                            value={popup.data && popup.data.invoice_period_id ? popup.data.invoice_period_id : ''}
                            onChange={handleInputChange}
                        >
                            {popup.invoicePeriods.map(p =>
                                <option
                                    key={'ip-' + p.id}
                                    value={p.id}
                                >
                                    {p.translation ? p.translation.name : p.name}
                                </option>
                            )}
                        </select>
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.timeForPaing')}
                        </label>
                        <input
                            type="number"
                            min="0"
                            step="1"
                            name="payment_period"
                            value={popup.data && popup.data.payment_period ? popup.data.payment_period : ''}
                            onChange={handleInputChange}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label className="flex">
                            <input
                                type="checkbox"
                                name="allow_reassign"
                                value="1"
                                checked={popup.data && Number(popup.data.allow_reassign) === 1 ? true : false}
                                onChange={handleCheckboxChange}
                            />
                            <span>
                                {trans('labels.allowReservations')}
                            </span>
                        </label>
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.maxReservations')}
                        </label>
                        <input
                            type="number"
                            min="0"
                            step="1"
                            name="max_reservations"
                            value={popup.data && popup.data.max_reservations ? popup.data.max_reservations : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.pricingMethod')}
                        </label>
                        <select
                            name="price_type_id"
                            value={popup.data && popup.data.price_type_id ? popup.data.price_type_id : ''}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled />
                            {popup.priceTypes.map(t =>
                                <option key={'pt-' + t.id} value={t.id}>
                                    {t.translation && t.translation.name}
                                </option>
                            )}
                        </select>
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    {selectedPriceIsPercent()
                        ?
                        <div className="col">
                            <label>
                                {trans('labels.companyTax')}
                            </label>

                            <input
                                type="number"
                                step="0.01"
                                min="0"
                                max="100"
                                name="price"
                                value={popup.data ? popup.data.price : ''}
                                className={validations && validations.price ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        :
                        selectedPriceIsFixed()
                            ?
                            <div className="col">
                                <label>
                                    {trans('labels.companyTaxPrice')}
                                </label>

                                <input
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    name="price"
                                    value={popup.data ? popup.data.price : ''}
                                    className={validations && validations.price ? 'validate' : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            :
                            selectedPriceIsCategory()
                                ?
                                <div className="col">
                                    <div className="scrollable" style={{ maxHeight: '200px', padding: '0 10px 0 0' }}>
                                        <AppliancesCategoriesPricing
                                            prices={popup.appliancesCategoriesPrices}
                                        />
                                    </div>
                                </div>
                                :
                                ''
                    }
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Settings);