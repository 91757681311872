import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { trans } from '../../../../../_providers/Translation'

//images
import docIcon from '../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../assets/img/icons/close.png';
import { Link } from 'react-router-dom';
import Api from '../../../../../_helpers/Api';

function View(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: null
    });

    useImperativeHandle(ref, () => ({
        open: data => {
            setPopup(prev => ({
                ...prev,
                data: data
            }));

            handleShow();
        },

        hide: () => {
            handleHide();
        }
    }));

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);

        markAsRead();
    }


    const markAsRead = () => {
        props.handleRead(popup.data.id);
    }

    const createMarkup = html => {
        return {
            __html: String(html).replaceAll("\n", "<br/>")
        }
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <div className={`${popup.modal ? 'show' : ''} popup-primary large`}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.viewTodo')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>
                <div className="popup-container-read-only">
                    <h3>
                        {popup.data ? popup.data.title : ''}
                    </h3>
                    <div className="row">
                        <ul className="col x2">
                            <li>
                                {trans('random.createdBy')}
                                <span>
                                    {popup.data && popup.data.author ? `${popup.data.author.name} ${popup.data.author.lastname}` : ''}
                                </span>
                            </li>
                            <li>
                                {trans('random.creationDate')}
                                <span>
                                    {popup.data ? popup.data.created_at_dmy : ''}
                                </span>
                            </li>
                            <li>
                                {trans('random.setTo')}
                                <span>
                                    {popup.data
                                        ? popup.data.tags.map(tag => {
                                            return tag.employee ? `${tag.employee.name} ${tag.employee.lastname}` : ''
                                        }).filter(each => each !== '').join(', ')
                                        : ''
                                    }
                                </span>
                            </li>
                        </ul>
                        <ul className="col x2">
                            <li>
                                {trans('random.prioroty')}
                                <span>
                                    {popup.data && popup.data.priority ? popup.data.priority.translation.name : ''}
                                </span>
                            </li>
                            <li>
                                {trans('random.dateFrom')}
                                <span>
                                    {popup.data ? popup.data.date_dmy : ''}
                                </span>
                            </li>
                            <li>
                                {trans('random.deadine')}
                                <span>
                                    {popup.data ? popup.data.deadline_dmy : ''}
                                </span>
                            </li>
                            {popup.data && popup.data.reservation
                                ?
                                <li>
                                    {trans('random.reservation')}
                                    <span>
                                        <Link to={`/reservations/edit/${popup.data.reservation_id}`}>{popup.data.reservation.reference_code}</Link>
                                    </span>
                                </li>
                                :
                                ''
                            }

                        </ul>
                    </div>
                    <hr />
                    <h4 className="row">
                        {trans('random.content')}
                    </h4>
                    <p className="row" dangerouslySetInnerHTML={createMarkup(popup.data ? popup.data.text : '')} />
                    <hr />
                    <div className="row">
                        <ul className="stepper">
                            <li className="checked">
                                <div className="holder">
                                    <h6>
                                        {trans('random.set')}
                                    </h6>
                                    <p>
                                        {popup.data ? popup.data.created_at_dmy : ''}
                                    </p>
                                </div>
                                <div className="progress">
                                    <span>

                                    </span>
                                </div>
                            </li>
                            {popup.data && popup.data.readable &&
                                <li className={popup.data && popup.data.read_at ? 'checked' : ''}>
                                    <div className="holder">
                                        <h6>
                                            {trans('random.seen')}
                                        </h6>
                                        {popup.data && popup.data.read_at
                                            ?
                                            <p>
                                                {popup.data ? popup.data.read_at_dmy : ''}
                                            </p>
                                            :
                                            ''
                                        }
                                    </div>
                                    <div className="progress">
                                        <span>

                                        </span>
                                    </div>
                                </li>
                            }
                            <li className={popup.data && popup.data.completed_at ? 'checked' : ''}>
                                <div className="holder">
                                    <h6>
                                        {trans('random.finished')}
                                    </h6>
                                    {popup.data && popup.data.completed_at
                                        ?
                                        <p>
                                            {popup.data.completed_at_dmy}
                                        </p>
                                        :
                                        ''
                                    }
                                </div>
                                <div className="progress">
                                    <span>

                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="footer align-center">
                    <button onClick={handleHide}>
                        {trans('buttons.close')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(View);