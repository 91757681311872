import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../../_helpers/Api';
import { trans } from '../../../../../_providers/Translation';

//images
import docIcon from '../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../assets/img/icons/close.png';
import loader from '../../../../../assets/img/loader.svg';
import { useLanguageContext } from '../../../../../_providers/Language';

function Notifications(props, ref) {

    const langs = useLanguageContext();

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: null,
        positions: [],
        loading: false,
        onSave: null
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: data => {
            setPopup(prev => ({
                ...prev,
                loading: false,
                data: data
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('settings/notification-positions')
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    positions: res.data
                }))
            })
    }, []);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'profiles/settings/edit';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave(res.data);
                }

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name.split('[')[1].replace(']', '');
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary small`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.notifications')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>
                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.notificationLocation')}
                        </label>

                        <select
                            name="data[notification_position_id]"
                            value={popup.data && popup.data.notification_position_id ? popup.data.notification_position_id : ''}
                            className={validations && validations.notification_position_id ? 'validate' : ''}
                            onChange={handleInputChange}
                        >
                            {popup.positions.map(el =>
                                <option key={el.id} value={el.id}>
                                    {el.name}
                                </option>
                            )}

                        </select>
                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.close')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Notifications);