import React, { useRef, useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import loader from '../../../assets/img/loader.svg';
import { useMessagesContext } from '../../../_providers/Messages';
import Api from '../../../_helpers/Api';
import { trans } from '../../../_providers/Translation';

let timeout;

function Messages(props) {

    const messages = useMessagesContext();

    const ref = useRef(null);

    const [state, setState] = useState({
        loading: true,
        list: [],
        page: 1,
        pages: 0,
        count: 0,
        hasMore: true,
        search: '',
        update: false,
        key: Math.random().toString(),
        initLoad: true
    });

    useEffect(() => {
        loadData();
    }, [state.update]);

    useEffect(() => {
        if (!state.initLoad) {
            handleUpdate();
        }
    }, [messages.count, messages.lastMessage]);

    const loadData = (page = 1) => {
        let url = 'messages/recent?page=' + page + '&name=' + state.search;

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    list: [...prev.list, ...res.data.items],
                    pages: res.data.pages,
                    hasMore: res.data.has_more,
                    loading: false,
                    initLoad: false
                }));
            });
    }

    const handleSearch = e => {
        let val = e.target.value;

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                search: val,
            }));

            handleUpdate();

        }, 500);
    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            list: [],
            loading: true,
            update: new Date().getTime(),
            key: Math.random().toString()
        }));
    }

    const handleOpenChat = (id, type, reservationId) => {
        messages.openChat(id, type, reservationId);
    }

    return (
        <div className={`show messages-module`}>
            <div className="head">
                {trans('headings.messages')}
            </div>
            <div className="body">
                <div className="scroll" ref={ref} key={state.key}>
                    {state.loading
                        ?
                        <img src={loader} alt="loading" />
                        :
                        state.list.length === 0
                            ?
                            <p className="no-data">{trans('messages.noMessages')}</p>
                            :
                            <InfiniteScroll
                                element="ul"
                                initialLoad={false}
                                pageStart={1}
                                loadMore={loadData}
                                hasMore={state.hasMore}
                                loader={<img key="loader" src={loader} alt="loading" />}
                                useWindow={false}
                                getScrollParent={() => ref.current}
                            >
                                {state.list.map(el =>
                                    <li key={'msg-' + el.id} onClick={() => handleOpenChat(el.receiver_subject.id, el.receiver_subject.type, el.reservation_id)}>
                                        <picture>
                                            {el.unread_count > 0
                                                ?
                                                <span>
                                                    {el.unread_count}
                                                </span>
                                                :
                                                ''
                                            }

                                            <img src={el.receiver_subject.avatar} alt="avatar" />
                                        </picture>
                                        <div className="right">
                                            <h3>
                                                {el.receiver_subject.name} {el.receiver_subject.lastname}

                                                <time className="timer" data-date={el.created_at}>
                                                    {el.published}
                                                </time>
                                            </h3>
                                            {el.reference_code
                                                ?
                                                <span style={{ display: 'block', fontSize: '10px', marginTop: '3px' }}>
                                                    <a className="btn" style={{ padding: '2px 7px', fontSize: '9px', background: el.reservation_color, color: el.reservation_text_color }}>
                                                        {trans('random.refNo')} {el.reference_code}
                                                    </a>
                                                </span>
                                                :
                                                ''}
                                            <p>
                                                {el.message}
                                            </p>
                                        </div>
                                    </li>
                                )}
                            </InfiniteScroll>
                    }
                </div>
                <input
                    type="search"
                    placeholder={trans('random.fromUser')}
                    onChange={handleSearch}
                />
            </div>
        </div>
    )
}

export default Messages;