import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import { useAuthDataContext } from '../../../../_providers/Auth';

import Api from '../../../../_helpers/Api';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import deleteIcon from '../../../../assets/img/icons/delete-red.png';
import { trans } from '../../../../_providers/Translation';

function WebSites(props, ref) {

    const auth = useAuthDataContext();

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: [],
        pages: [],
        checked: [],
        check: false,
        loading: false,
        onSave: null,
    });


    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: (data, pages) => {

            let check = pages.map(item => {
                if (data.find(c => c.page_id == item.id)) {
                    item.check = true
                }
                else {
                    item.check = false
                }
                return item;
            })

            setPopup(prev => ({
                ...prev,
                loading: false,
                data: data,
                pages: check
            }));
            handleShow();

        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            checked: [],
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'website/setpages?partner_id=' + auth.user().partner_id;

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave();
                }

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const findIndex = id => {
        let index = popup.pages.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    const handleCheck = (e, id) => {
        let index = findIndex(id);
        let check = popup.pages[index]
        if (check && check.type !== 0) {
            let checked = popup.pages;
            check['check'] = e.target.checked;
            checked[index] = check;
            setPopup(prev => ({
                ...prev,
                pages: checked
            }));
        }
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary small`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.websitePages')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <br />

                <div className='popup-container'>
                    <div className='col'>
                        {popup.pages.map((t, idx) =>
                            <div key={'page' + t.id} className='row'>
                                <div className="checkbox-primary">
                                    <label htmlFor={'page' + t.id}>
                                        {t.name}
                                        <input
                                            id={'page' + t.id}
                                            type="checkbox"
                                            name="page_id[]"
                                            onChange={e => handleCheck(e, t.id)}
                                            value={t.id}
                                            checked={t.check}
                                        />
                                        <div className="checkmark"></div>
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(WebSites);