import React, { useState, useImperativeHandle, useEffect, useRef, useCallback } from 'react';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import deleteIcon from '../../../../assets/img/icons/delete.png';
import Api from '../../../../_helpers/Api';
import loader from '../../../../assets/img/loader.svg';
import Dropdown from '../../../partials/Dropdown';
import Ckeditor from '../../../partials/Ckeditor';
import { trans } from '../../../../_providers/Translation'

function AddOrEdit(props, ref) {

    const defaultData = {
        repeat: false,
        repeat_after: 1,
        send_to_all: 0,
        receivers: [],
        original: [],
        selected: [],
        characters: 0,
        group: null,
        method: 'sms'
    };

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: defaultData,
        dataLoading: false,
        loading: false,
        url: null
    });

    const [validations, setValidations] = useState(null);

    const charactersPerSms = 160;

    useImperativeHandle(ref, () => ({
        add: (type) => {

            let data = defaultData;
            data.group = type;

            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: data,
                dataLoading: false
            }));

            handleShow();
        },
        edit: (id) => {

            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                data: defaultData,
                dataLoading: true,
                loading: false
            }));

            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            Api.get(`text-messages/find?id=${popup.editId}`)
                .then(res => {

                    let data = res.data;

                    data.original = data.receivers;
                    data.characters = data.text.length;

                    setPopup(prev => ({
                        ...prev,
                        data: data,
                        dataLoading: false,
                    }));
                });
        }

    }, [popup.edit]);

    useEffect(() => {
        if (popup.data.group) {

            let url;

            switch (popup.data.group) {
                case 'team':
                    url = 'partners/employees/all?active=1&sort=name';
                    break;
                case 'advert':
                    url = 'customers/all';
                    break;
            }

            setPopup(prev => ({
                ...prev,
                url: url,
            }));

        }
    }, [popup.data.group]);

    useEffect(() => {
        let data = [];

        popup.data.receivers.map(r => {
            if (r.user) {
                data.push(r.user);
            }
        });

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                selected: data,
            }
        }));

    }, [popup.data.receivers]);

    useEffect(() => {
        if (Number(popup.data.send_to_all) === 1) {
            setPopup(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    receivers: []
                }
            }));
        } else {
            setPopup(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    receivers: popup.data.original || []
                }
            }));
        }
    }, [popup.data.send_to_all]);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        if (e) {
            e.preventDefault();
        }

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setValidations(null)

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {

        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'text-messages/edit';
        } else {
            url = 'text-messages/add';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        data.append('type', popup.data.group);

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    props.handleUpdate(res.data)
                } else {
                    props.handleCreate(res.data);
                }

                handleHide();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));

        if (name === 'text') {
            let characters = val.length;

            setPopup(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    characters: characters
                }
            }));
        }
    }

    const handleCheckboxChange = e => {
        let name = e.target.name;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: typeof prev.data[name] === 'undefined' || Number(prev.data[name]) === 0 ? 1 : 0
            }
        }));
    }

    const handleDateChange = e => {
        let name = e.target.name + '_ymd';
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const getRepeatNumbers = () => {
        let n = [];

        for (let i = 1; i <= 31; i++) {
            n.push(i);
        }

        return n;
    }

    const handleChangeReceivers = data => {

        let receivers = [];

        data.map(user => {
            let receiver = {
                id: Math.random().toString().substring(3),
                user_id: user.id,
                user: user
            };

            receivers.push(receiver);
        });

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                receivers: receivers,
                original: receivers
            }
        }));

    }

    const handleRemoveReceiver = userId => {
        let receivers = [...popup.data.receivers];

        receivers = receivers.filter(r => {
            return Number(r.user_id) !== Number(userId);
        });

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                receivers: receivers,
                original: receivers
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form className={`${popup.modal ? 'show' : ''} popup-primary large`} autoComplete="off" onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {popup.edit ? trans('headings.edit-smsMessage') : trans('headings.add-smsMessage')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                {popup.dataLoading
                    ?
                    <img className="loader" src={loader} />
                    :
                    <>
                        {!popup.edit
                            ?
                            <div className="row">
                                <label className="flex">
                                    <input
                                        type="radio"
                                        name="method"
                                        value="sms"
                                        checked={popup.data && popup.data.method === 'sms' ? true : false}
                                        onChange={handleInputChange}
                                    />
                                    <span>
                                        Viber/WhatsApp/SMS
                                    </span>
                                </label>

                                <label className="flex" style={{ marginLeft: '10px' }}>
                                    <input
                                        type="radio"
                                        name="method"
                                        value="email"
                                        checked={popup.data && popup.data.method === 'email' ? true : false}
                                        onChange={handleInputChange}
                                    />
                                    <span>
                                        Email
                                    </span>
                                </label>
                            </div>
                            :
                            ''
                        }

                        <div className="popup-container x2">

                            <div className="col">
                                <label htmlFor="heading">
                                    {trans('labels.heading')}
                                </label>
                                <input
                                    name="subject"
                                    type="text"
                                    id="heading"
                                    className={validations && validations.subject ? 'validate' : ''}
                                    value={popup.data && popup.data.subject ? popup.data.subject : ''}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col">
                                <label htmlFor="send_at">
                                    {trans('labels.dateSent')}
                                </label>
                                <input
                                    name="send_at"
                                    type="datetime-local"
                                    id="send_at"
                                    className={validations && validations.send_at ? 'validate' : ''}
                                    value={popup.data && popup.data.send_at_ymd ? popup.data.send_at_ymd : ''}
                                    disabled={popup.data && Number(popup.data.send_now) === 1 ? true : false}
                                    onChange={handleDateChange}
                                />
                                {!popup.edit
                                    ?
                                    <label className="flex" style={{ marginTop: '7px' }}>
                                        <input
                                            type="checkbox"
                                            name="send_now"
                                            value="1"
                                            checked={popup.data && Number(popup.data.send_now) === 1 ? true : false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <span>
                                            {trans('labels.send-immediately')}
                                        </span>
                                    </label>
                                    :
                                    ''
                                }
                            </div>

                        </div>

                        <br />

                        <div className="popup-container">
                            <div className="col">
                                <label>
                                    {trans('labels.sendTo')}
                                </label>
                                {popup.data.method === 'sms'
                                    ?
                                    <Dropdown
                                        key={1}
                                        inputIdName="receivers"
                                        url={popup.url}
                                        multiple={true}
                                        loadDataOnFocus={true}
                                        selectedValues={popup.data.selected}
                                        inputPlaceholder={trans('labels.findUsers')}
                                        inputClassName={validations && validations.receivers ? 'validate' : ''}
                                        inputDisabled={popup.data && Number(popup.data.send_to_all) === 1 ? true : false}
                                        onChange={handleChangeReceivers}
                                        renderText={data => {
                                            return <span>{data.full_name} ({(data.phone_formatted || trans('labels.noPhone'))})</span>
                                        }}
                                    />
                                    :
                                    <Dropdown
                                        key={2}
                                        inputIdName="receivers"
                                        url={popup.url}
                                        multiple={true}
                                        loadDataOnFocus={true}
                                        selectedValues={popup.data.selected}
                                        inputPlaceholder={trans('labels.findUsers')}
                                        inputClassName={validations && validations.receivers ? 'validate' : ''}
                                        inputDisabled={popup.data && Number(popup.data.send_to_all) === 1 ? true : false}
                                        onChange={handleChangeReceivers}
                                        renderText={data => {
                                            return <span>{data.full_name} ({((popup.data.group === 'advert' ? data.email2 : data.email) || trans('labels.noEmail'))})</span>
                                        }}
                                    />
                                }


                                <label className="flex" style={{ marginTop: '7px' }}>
                                    <input
                                        type="checkbox"
                                        name="send_to_all"
                                        value="1"
                                        checked={popup.data && Number(popup.data.send_to_all) === 1 ? true : false}
                                        onChange={handleCheckboxChange}
                                    />
                                    <span>
                                        {trans('labels.sendAll')}
                                    </span>
                                </label>
                            </div>
                        </div>

                        {popup.data && Number(popup.data.send_to_all) === 0
                            ?
                            <>
                                <br />

                                <div className="popup-container">
                                    <div className="col">
                                        <label>
                                            {trans('labels.receiver')}:
                                        </label>
                                        {popup.data.receivers.length === 0
                                            ?
                                            <div className="alert warning" style={{ marginBottom: '0' }}>
                                                {Number(popup.data.send_to_all) === 1
                                                    ? trans('labels.allAvaible')
                                                    : trans('labels.noReceivers')
                                                }
                                            </div>
                                            :
                                            <div className="scrollable" style={{ padding: '0 10px 0 10px', maxHeight: '200px', background: '#f0f0f0', borderRadius: '3px' }}>
                                                {popup.data.receivers.map(r =>
                                                    <div key={'r-' + r.user_id} style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
                                                        <img src={deleteIcon} alt="del" style={{ cursor: 'pointer', marginRight: '3px' }} onClick={() => handleRemoveReceiver(r.user_id)} />

                                                        <span style={{ fontSize: '13px', color: '#9191a2' }}>
                                                            {r.user
                                                                ?
                                                                <>
                                                                    {r.user.full_name}
                                                                    ({
                                                                        popup.data.method === 'sms'
                                                                            ?
                                                                            (r.user.phone_formatted || trans('labels.noPhone'))
                                                                            :
                                                                            ((popup.data.group === 'advert' ? r.user.email2 : r.user.email) || trans('labels.noEmail'))
                                                                    })
                                                                </>
                                                                :
                                                                'n/a'
                                                            }

                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            ''
                        }


                        {popup.data && !popup.data.parent_id
                            ?
                            <>
                                <br />
                                <div className="popup-container">
                                    <div className="col">
                                        <label className="flex">
                                            <input
                                                type="checkbox"
                                                name="repeat"
                                                value="1"
                                                checked={popup.data && Number(popup.data.repeat) === 1 ? true : false}
                                                onChange={handleCheckboxChange}
                                            />
                                            <span>
                                                {trans('labels.repeat')}
                                            </span>
                                        </label>

                                    </div>
                                </div>
                            </>
                            :
                            ''
                        }

                        {popup.data && Number(popup.data.repeat) === 1
                            ?
                            <>
                                <br />
                                <div className="popup-container x2">
                                    <div className="col">
                                        <label>
                                            {trans('labels.repeatOn')}
                                        </label>
                                        <select
                                            name="repeat_after"
                                            className={validations && validations.repeat_after ? 'validate' : ''}
                                            value={popup.data && popup.data.repeat_after ? popup.data.repeat_after : ''}
                                            onChange={handleInputChange}
                                        >
                                            {getRepeatNumbers().map((n, i) => (
                                                <option key={'rn-' + i} value={n}>
                                                    {n}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col">
                                        <label>
                                            {trans('labels.period')}
                                        </label>
                                        <select
                                            name="repeat_period"
                                            className={validations && validations.repeat_period ? 'validate' : ''}
                                            value={popup.data && popup.data.repeat_period ? popup.data.repeat_period : ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value={1}>
                                                {popup.data && (!popup.data.repeat_after || Number(popup.data.repeat_after) === 1)
                                                    ? trans('labels.day')
                                                    : trans('labels.days')
                                                }
                                            </option>
                                            <option value={2}>
                                                {popup.data && (!popup.data.repeat_after || Number(popup.data.repeat_after) === 1)
                                                    ? trans('labels.week')
                                                    : trans('labels.weeks')
                                                }
                                            </option>
                                            <option value={3}>
                                                {popup.data && (!popup.data.repeat_after || Number(popup.data.repeat_after) === 1)
                                                    ? trans('labels.month')
                                                    : trans('labels.months')
                                                }
                                            </option>
                                            <option value={4}>
                                                {popup.data && (!popup.data.repeat_after || Number(popup.data.repeat_after) === 1)
                                                    ? trans('labels.year')
                                                    : trans('labels.years')
                                                }
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </>
                            :
                            ''
                        }

                        <br />
                        <div className="popup-container">
                            <div className="col">
                                <label htmlFor="text">
                                    {trans('labels.content')}
                                </label>
                                {popup.data.method === 'email'
                                    ?
                                    <Ckeditor
                                        adkey="text"
                                        name="text"
                                        value={popup.data && popup.data.text ? popup.data.text : ''}
                                    />
                                    :
                                    <>
                                        <textarea
                                            id="text"
                                            name="text"
                                            className={validations && validations.text ? 'validate' : ''}
                                            value={popup.data && popup.data.text ? popup.data.text : ''}
                                            onChange={handleInputChange}
                                        >
                                        </textarea>
                                        <div style={{ marginTop: '10px', fontSize: '10px' }}>
                                            <span>
                                                {trans('labels.characters')}: <span className="">{popup.data.characters}</span>
                                            </span>
                                            /
                                            <span>
                                                {trans('labels.smses')}: <span className="">{Math.ceil(popup.data.characters / charactersPerSms) || 1}</span>
                                            </span>
                                        </div>

                                    </>
                                }

                            </div>
                        </div>

                    </>
                }

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default React.forwardRef(AddOrEdit);