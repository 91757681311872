import React from 'react';
import Options from './OptionsUsed';
import { Link } from 'react-router-dom';
import notFound from '../../../../assets/img/not-found.png'
import Api from '../../../../_helpers/Api';
import { useNotificationsContext } from '../../../../_providers/Notifications';
import checkIcon from '../../../../assets/img/icons/check.png';
import ReactTooltip from 'react-tooltip';
import { trans } from '../../../../_providers/Translation';

function TableUsed(props) {

    const notifications = useNotificationsContext();

    const setPerformer = e => {
        let val = e.target.value;
        let rid = e.target.getAttribute('data-reservation-id');

        Api.post('reservations/set-performer', {
            id: Number(rid),
            employee_id: Number(val)
        }).then(res => {
            notifications.add(trans('messages.reservationContractorChange'));
            props.handleChangePerformer(rid, val);
        });
    }

    const handleTransferAll = e => {
        let date = document.getElementById('give-date').value;

        let ids = [];

        document.querySelectorAll('.give-cb').forEach(i => {
            if (i.checked) {
                ids.push(i.value);
            }
        });

        if (ids.length) {
            Api.post('ordered-parts/transfer', {
                ids: ids,
                date: date
            }).then(res => {
                props.handleReload();
            });
        }

    }

    const handleGiveChange = e => {
        let c = e.target.checked;

        document.querySelectorAll('.give-cb').forEach(i => {
            i.checked = c;
        });
    }

    return (

        props.parts.length === 0
            ?
            <div className="not-found">
                <img src={notFound} alt="Not Found" />
                <p>
                    {trans('messages.noResultsFound')}
                </p>
            </div>
            :
            <>
                <div className="table-scroller">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>{trans('tables.setTo')}</th>
                                <th>{trans('tables.reservation')}</th>
                                <th>{trans('tables.contractorCompany')}</th>
                                <th>{trans('tables.status')}</th>
                                <th>{trans('tables.name')}</th>
                                <th>{trans('tables.supplier')}</th>
                                <th>{trans('tables.address')}</th>
                                <th>{trans('tables.partNumber')}</th>
                                <th className="center">{trans('tables.location')}</th>
                                <th className="center">{trans('tables.quantity')}</th>
                                <th className="right">{trans('tables.grossPrice')}</th>
                                <th className="right">{trans('tables.addedOn')}</th>
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={handleGiveChange}
                                    />
                                </th>
                                <th className="options">{trans('tables.options')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.parts.map(p =>
                                <tr key={'p-' + p.id}>
                                    <td>
                                        {p.id}
                                    </td>
                                    {/* <td>{p.part.category.name}</td> */}

                                    <td>

                                        <select
                                            data-reservation-id={p.reservation_id}
                                            style={{ width: '100px' }}
                                            value={p.reservation && p.reservation.employee_id ? Number(p.reservation.employee_id) : ''}
                                            onChange={setPerformer}
                                        // disabled={p.position.name === 'van'}
                                        >
                                            <option value="" disabled></option>
                                            {props.performers.map((performer, i) =>
                                                <option
                                                    key={'performer-' + i}
                                                    value={Number(performer.id)}
                                                >
                                                    {performer.type === 'App\\Employee'
                                                        ?
                                                        `${performer.name} ${performer.lastname}`
                                                        :
                                                        performer.name
                                                    }

                                                </option>
                                            )}
                                        </select>

                                    </td>

                                    <td>
                                        {p.reservation ?
                                            <Link
                                                to={`/reservations/edit/${p.reservation.id}?tab=parts`}
                                                className="btn"
                                                style={{ background: p.reservation.color, color: p.reservation.text_color }}
                                            >
                                                {p.reservation.reference_code} (ID: {p.reservation_id})
                                            </Link>
                                            :
                                            '-'}
                                    </td>

                                    <td>
                                        {p.reservation && p.reservation.contractor
                                            ?
                                            p.reservation.contractor.name
                                            :
                                            ''
                                        }
                                    </td>

                                    <td>
                                        {p.status
                                            ?
                                            p.status.translation && p.status.translation.name
                                                ? p.status.translation.name
                                                : p.status.name
                                            :
                                            ''
                                        }
                                        <br />
                                        <span style={{ fontSize: '10px' }}>
                                            {p.status_updated_date}
                                        </span>
                                        {p.status_updated_before > 1
                                            ?
                                            <>
                                                <span
                                                    data-tip
                                                    data-for={'sb-' + p.id}
                                                    className="badge danger"
                                                    style={{ marginLeft: '5px' }}
                                                >
                                                    {p.status_updated_before}
                                                </span>
                                                <ReactTooltip
                                                    id={'sb-' + p.id}
                                                    effect="solid"
                                                >
                                                    {trans('random.lastUpdated')} {p.status_updated_before} {trans('labels.days')}
                                                </ReactTooltip>
                                            </>
                                            :
                                            ''
                                        }
                                    </td>

                                    <td>
                                        {p.name}
                                    </td>
                                    <td>
                                        {p.supplier ? p.supplier.name : ''}
                                        <br />
                                        {p.po_number ? `PO: ${p.po_number}` : ''}
                                    </td>

                                    <td>

                                        {p.reservation
                                            ?
                                            <>
                                                <span
                                                    data-tip
                                                    data-for={'rs-' + p.id}
                                                >
                                                    {(p.reservation.full_address || '').substring(0, 6)}
                                                </span>


                                                <ReactTooltip
                                                    id={'rs-' + p.id}
                                                    effect="solid"
                                                >
                                                    {p.reservation.full_address}
                                                </ ReactTooltip>
                                            </>
                                            :
                                            ''
                                        }

                                    </td>

                                    <td>
                                        {p.number}
                                    </td>
                                    <td className="center">
                                        {p.total_quantity > 0 ? trans('random.inStock') : trans('random.noStock')}
                                    </td>
                                    <td className="center">
                                        {p.quantity}
                                    </td>
                                    <td className="right">{p.gross_price_format} {p.currency.name}</td>
                                    <td className="right">{p.add_date}</td>
                                    <td>
                                        {p.transferable
                                            ?
                                            <input
                                                type="checkbox"
                                                className="give-cb"
                                                value={p.id}
                                            />
                                            :
                                            p.given
                                                ?
                                                <>
                                                    <img
                                                        src={checkIcon}
                                                        alt=""
                                                        height="8"
                                                        data-tip
                                                        data-for={'tooltip-p-' + p.id}
                                                    />
                                                    <ReactTooltip
                                                        id={'tooltip-p-' + p.id}
                                                        effect="solid"
                                                    >
                                                        <div style={{ textAlign: 'center' }}>
                                                            {trans('random.givenTo')}: {p.given_date_dmy}
                                                            <br />
                                                            {trans('random.accepted')}: {p.accept_date ? trans('labels.yes') : trans('labels.no')}
                                                            <br />
                                                            {trans('random.acceptedOn')}: {p.accept_date_dmy || 'n/a'}
                                                        </div>
                                                    </ReactTooltip>
                                                </>
                                                :
                                                ''
                                        }
                                    </td>

                                    <td>
                                        <Options
                                            id={p.id}
                                            deletable={p.deletable}
                                            editable={p.editable}
                                            handleEdit={props.handleEdit}
                                            handleDelete={props.handleDelete}
                                            handleHistory={props.handleHistory}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <br />
                <div className="actions">
                    <input
                        id="give-date"
                        type="datetime-local"
                    />
                    <button
                        onClick={handleTransferAll}
                    >
                        {trans("random.markUp")}
                    </button>

                </div>

            </>
    )
}

export default TableUsed;