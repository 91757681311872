import React, { useEffect, useState, useRef, useMemo } from 'react';
import { trans } from '../../../_providers/Translation';

import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import Api from '../../../_helpers/Api';
import Chart from "react-google-charts";
import loader from '../../../assets/img/loader.svg';
import Filter from './partials/FilterExpenses';
import style from '../../../assets/css/accounts.css'

let timeout;

function Expenses() {

    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [state, setState] = useState({
        loading: true,
        data: {
            dates: [],
            categories: []
        },
        filter: {},
        setFilter: false,
        update: false,
        summary: false,
        allowDetails: true,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                start: searchParams.get('start') || '',
                end: searchParams.get('end') || '',
                employee_id: searchParams.get('employee_id') || '',
                group: searchParams.get('group') || '',
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    useEffect(() => {

        if (!state.data.dates) {
            return;
        }

        let label = state.data.group === 'month' ? trans('random.month') : trans('random.year');

        let chart;

        // обобщена 
        if (state.summary) {
            chart = [
                [label, trans('random.expense')],
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label, d.total
                ];

                chart.push(arr);
            });
        }
        // по категории
        else {
            let chartArr = [label];

            state.data.categories.map(c => {
                chartArr.push(c.name)
            });

            chart = [
                chartArr
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label,
                ];

                state.data.categories.map(c => {
                    arr.push(c.dates[d.date].total);
                });

                chart.push(arr);
            });
        }

        setState(prev => ({
            ...prev,
            chart: chart
        }))

    }, [state.data, state.summary])

    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'accounts/expenses';

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    // chart: chart,
                    filter: {
                        ...prev.filter,
                        group: res.data.group,
                        start: res.data.start,
                        end: res.data.end
                    },
                    loading: false,
                    summary: res.data.categories.length === 0 ? true : prev.summary,
                    allowDetails: res.data.categories.length === 0 ? false : true
                }));
            });
    }

    const handleSearch = (key, val, delay = 300) => {

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
                setFilter: new Date().getTime()
            }));
        }, delay);

    }

    const toggleSummaryData = val => {
        setState(prev => ({
            ...prev,
            summary: val
        }));
    }

    const getColumns = () => {
        let arr = [];

        state.data.categories.map((m, i) => {
            arr.push({
                type: 'NumberFormat',
                column: i + 1,
                options: {
                    // prefix: '$',
                    negativeParens: false,
                }
            })
        });

        return arr;
    }

    const calcHeight = () => {
        let height = 50 + state.data.categories.length * 40;

        if (height < 350) {
            height = 350;
        }

        return height;
    }

    return (
        <>
            <Filter
                allowDetails={state.allowDetails}
                filter={state.filter}
                summary={state.summary}
                handleSearch={handleSearch}
                toggleSummaryData={toggleSummaryData}
            />

            <br />

            {state.loading
                ?
                <img className="loader" src={loader} />
                :
                <>
                    <div
                        className="chart"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#fff',
                            padding: '5px'
                        }}>
                        <Chart
                            width={window.innerWidth <= 1024 ? '900px' : '99%'}
                            height={calcHeight() + 'px'}
                            chartType="Bar"
                            className="animated-chart-vertical animated-chart-start"
                            loader={'...'}
                            data={state.chart}
                            options={{

                                // Material design options
                                chart: {
                                    title: trans('headings.expenses'),
                                    subtitle: state.data.currency,
                                },
                                colors: state.summary ? null : Array.isArray(state.data.categories) && state.data.categories.map(c => c.color),
                                legend: {
                                    position: 'left',
                                },
                                allowHtml: true,
                                showRowNumber: true,
                            }}
                            // custom attributes
                            rootProps={{ 'data-testid': '2' }}
                            chartEvents={[
                                {
                                    eventName: "ready",
                                    callback: ({ chartWrapper, google }) => {
                                        const chartEl = chartWrapper.getChart().container;
                                        setTimeout(() => {
                                            chartEl.classList.remove('animated-chart-start')
                                        }, 100)
                                    },
                                }
                            ]}
                            formatters={getColumns()}
                        />
                    </div>

                    <br />
                    <div className="table-scroller">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {trans('tables.category')}
                                    </th>
                                    {state.data.dates.map((d, i) =>
                                        <th key={'th-' + i} className="right">
                                            {d.label}
                                        </th>
                                    )}
                                    <th className="right">
                                        {trans('tables.total')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.data.categories.map(c =>
                                    <tr key={'c-' + c.id}>
                                        <td>
                                            {c.name}
                                        </td>
                                        {state.data.dates.map((d, i) =>
                                            <td key={'td-' + i} className="right">
                                                {c.dates[d.date].total_formatted}
                                            </td>
                                        )}
                                        <td className="right">
                                            {c.total_formatted}
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <td>
                                        {trans('tables.total')}
                                    </td>
                                    {state.data.dates.map((d, i) =>
                                        <td key={'totals-' + i} className="right">
                                            {d.total_formatted}
                                        </td>
                                    )}
                                    <td className="right">
                                        {state.data.total_formatted}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        {trans('tables.total_vat')}
                                    </td>
                                    {state.data.dates.map((d, i) =>
                                        <td key={'totals_vat-' + i} className="right">
                                            {d.total_vat_formatted}
                                        </td>
                                    )}
                                    <td className="right">
                                        {state.data.total_vat_formatted}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </>
            }

        </>
    )
}

export default Expenses;