import React, { useState, useEffect, useRef } from 'react'
import Api from '../../../../_helpers/Api'
import { useParams, useHistory, Link } from 'react-router-dom'
import { trans } from '../../../../_providers/Translation';

//modals
import AddEditModal from './modals/AddEditTodo'
import TodoModal from '../../todo/partials/modals/View'
import QuestionModal from '../../../modals/messages/Question'

//images
import loader from '../../../../assets/img/loader.svg'
import checkIcon from '../../../../assets/img/icons/check.png'
import notFound from '../../../../assets/img/not-found.png'


//partials
import TodoMenu from './TodoMenu'

function Todo(props) {
    const [table, setTable] = useState({
        options: null,
        data: [],
        loading: true,
    })

    let params = useParams().id
    const history = useHistory()

    //refs
    const addEditModalRef = useRef(null)
    const questionModalRef = useRef(null)
    const todoModalRef = useRef(null)

    useEffect(() => loadData(), [])

    const loadData = () => {
        Api.get(`reservations/todos?id=${params}`)
            .then(res => {
                setTable(table => ({
                    ...table,
                    data: res.data,
                    loading: false,
                }))
            })
    }

    const handleSuccessAdd = () => loadData()

    const handleView = data => todoModalRef.current.open(data)

    const handleDelete = id => questionModalRef.current.open(id)

    const handleConfirmDelete = id => {
        Api.post('reservations/todos/delete', {
            id: params,
            todo_id: id,
        }).then(() => {
            history.push(`?tab=todo&indicator=${new Date().getTime()}`)
        })

        let newData = table.data.filter(row => {
            return row.id != id
        })

        setTable(table => ({
            ...table,
            data: newData,
        }))
    }

    const handleAddTodo = () => addEditModalRef.current.open()

    const handleEdit = (id) => addEditModalRef.current.open(id)

    const handleRead = id => {

        Api.post('todos/mark-as-read', {
            id: id
        }).then(res => {
            let newData = table.data.map(each => {
                if (each.id == id) {
                    each = res.data;
                }

                return each;
            });

            setTable(prev => ({
                ...prev,
                data: newData,
            }));
        });

    }


    const handleComplete = id => {
        Api.post('todos/mark-as-completed', {
            id: id
        }).then(res => {
            let newData = table.data.map(each => {
                if (each.id == id) {
                    each = res.data;
                }

                return each;
            });

            setTable(prev => ({
                ...prev,
                data: newData,
            }))
        })

    }


    return (
        <>
            <AddEditModal
                ref={addEditModalRef}
                callback={handleSuccessAdd}
            />

            <TodoModal
                ref={todoModalRef}
                handleRead={handleRead}
            />

            <QuestionModal
                ref={questionModalRef}
                mainMessage={trans('headings.question6')}
                secondaryMesasge={trans('subheadings.confirmDelete')}
                callback={handleConfirmDelete}
            />

            {props.data.can_modify &&
                <button className="button button-add extra-button" onClick={handleAddTodo}>
                    {trans('buttons.newTodo')}
                </button>
            }
            {table.loading ?
                <img src={loader} alt="loading" />
                :
                table.data.length == 0 ?
                    <div className="not-found">
                        <img src={notFound} alt="Not Found" />
                        <p>
                            {trans('messages.noResultsFound')}
                        </p>
                    </div>
                    :
                    <div className="table-scroller">
                        <table>
                            <thead>
                                <tr>
                                    <th className="todo-check"></th>
                                    <th>ID</th>
                                    <th>{trans('tables.createdBy')}</th>
                                    <th>{trans('tables.setTo')}</th>
                                    <th>{trans('tables.reservation')}</th>
                                    <th>{trans('tables.heading')}</th>
                                    <th>{trans('tables.datePriority')}</th>
                                    <th>{trans('tables.deadline')}</th>
                                    {props.data.can_modify &&
                                        <th className="options">{trans('tables.options')}</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {table.data.map(each =>
                                    <tr key={each.id}>
                                        <td className="todo-check">{each.completed_at && <img src={checkIcon} alt="completed" />}</td>
                                        <td>{each.id}</td>
                                        <td>{each.author.name} {each.author.lastname}</td>
                                        <td>
                                            {each.tags.map(tag => {
                                                return tag.employee ? `${tag.employee.name} ${tag.employee.lastname}` : ''
                                            }).filter(each => each !== '').join(', ')}
                                        </td>
                                        <td>
                                            <Link to="?tab=info">{each.reservation.reference_code}</Link>
                                        </td>
                                        <td>{each.title}</td>
                                        <td>{each.date_dmy} / <p className="priority-holder"><span className={`priority ${each.priority.name == 'low' ? 'low' : each.priority.name == 'normal' ? 'medium' : 'heigh'}`}></span>{each.priority.translation.name}</p></td>
                                        <td>{each.deadline_dmy}</td>
                                        {props.data.can_modify &&
                                            <TodoMenu
                                                handleEdit={handleEdit}
                                                handleDelete={handleDelete}
                                                handleView={handleView}
                                                handleComplete={handleComplete}
                                                isCompleted={each.completed_at}
                                                canMarkAsCompleted={each.can_mark_as_completed}
                                                each={each}
                                                options={table.options}
                                            />
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
            }
        </>
    )
}

export default Todo