import React, { useEffect, useState, } from 'react';
import '../../../../assets/css/settings.css';
import Categories from './settings/Categories';
import Brands from './settings/Brands';
import Pricing from './settings/Pricing';
import Payments from './settings/Payments';
import VisitPeriods from './settings/VisitPeriods';
import ReservationTypes from './settings/ReservationTypes';
import ReservationStatuses from './settings/ReservationStatuses';
import StorageStatuses from './settings/StorageStatuses';
import Preferences from './settings/Preferences';
import Notifications from './settings/Notifications';
import Addresses from './settings/Addresses';
import Vans from './settings/Vans';
import Storages from './settings/Storages';
import { useAuthDataContext } from '../../../../_providers/Auth';
import Others from './settings/Others';
import { trans } from '../../../../_providers/Translation';
import { useHistory, useLocation } from 'react-router-dom';
import MobileApp from './settings/MobileApp';

function Settings(props) {

    const auth = useAuthDataContext();
    const location = useLocation();
    const history = useHistory();

    const tabs = [
        {
            id: 'categories',
            name: trans('tabs.appliancescategories'),
            component: Categories
        },
        {
            id: 'brands',
            name: trans('tabs.appliancesbrands'),
            component: Brands
        },
        {
            id: 'pricing',
            name: trans('tabs.pricing'),
            component: Pricing
        },
        {
            id: 'visitperiods',
            name: trans('tabs.visitperiods'),
            component: VisitPeriods
        },
        {
            id: 'reservationstypes',
            name: trans('tabs.reservationstypes'),
            component: ReservationTypes
        },
        {
            id: 'reservationsstatuses',
            name: trans('tabs.reservationsstatuses'),
            component: ReservationStatuses
        },
        {
            id: 'storagestatuses',
            name: trans('tabs.storagestatuses'),
            component: StorageStatuses
        },
        {
            id: 'storages',
            name: trans('tabs.storages'),
            component: Storages
        },
        {
            id: 'vans',
            name: trans('tabs.vans'),
            component: Vans
        },
        {
            id: 'payments',
            name: trans('tabs.payments'),
            component: Payments
        },
        // {
        //     id: 'preferences',
        //     name: 'Език и часова зона',
        //     component: Preferences
        // },
        {
            id: 'notifications',
            name: trans('tabs.notifications'),
            component: Notifications
        },
        {
            id: 'addresses',
            name: trans('tabs.addresses'),
            component: Addresses,
            access: auth.isManager()
        },
        {
            id: 'app',
            name: trans('headings.mobileApp'),
            component: MobileApp,
            access: auth.isManager()
        },
        {
            id: 'others',
            name: trans('tabs.others'),
            component: Others,
            access: auth.isManager()
        },
    ];

    const searchParams = new URLSearchParams(location.search);

    const tab = searchParams.get('sub');

    const [state, setState] = useState({
        tab: null,
        index: null
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            tab: tab || tabs[0].id,
            index: tab ? findTabIndex(tab) : 0
        }))
    }, [location.search])

    const handleTab = id => {

        let index = findTabIndex(id);

        setState(prev => ({
            ...prev,
            tab: id,
            index: index
        }));

        searchParams.set('sub', id);

        history.push('?' + searchParams.toString());
    }

    const findTabIndex = id => {
        let index = tabs.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    return (

        <div id="settings">

            <div className="tabs">
                {tabs.map(t =>
                    typeof t.access === 'undefined' || t.access
                        ?
                        <div
                            key={'tab-' + t.id}
                            className={['tab', state.tab === t.id ? 'active' : ''].join(' ')}
                            onClick={() => handleTab(t.id)}
                        >
                            {t.name}
                        </div>
                        :
                        ''
                )}
            </div>


            {tabs[state.index] && React.createElement(tabs[state.index].component, props)}

        </div>

    )
}

export default Settings;