import React, { useImperativeHandle, useState, forwardRef, useEffect } from 'react'
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

function NotReviewed(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
    });

    useImperativeHandle(ref, () => ({
        show: (id) => {
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();
        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <div className={`${popup.modal ? 'show' : ''} popup-primary`}>
                <h2 className="head">
                    {trans('headings.extraInfo')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>
                <div className="popup-container">
                    <div className="table-scroller">
                        <table>
                            <thead>
                                <tr>
                                    <th>{trans('tables.client')}</th>
                                    <th>{trans('tables.address')}</th>
                                    <th>{trans('tables.category')}</th>
                                    <th>{trans('tables.make')}</th>
                                    <th>{trans('tables.model')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.map(item => (
                                    <tr key={item.id}>
                                        <td>{item.customer ? item.customer.name : ''}</td>
                                        <td>{item.address}</td>
                                        <td>{item.appliancecategory && item.appliancecategory ? item.appliancecategory.name : ''}</td>
                                        <td>{item.appliancebrand ? item.appliancebrand.name : ''}</td>
                                        <td>{item.appliancemodel ? item.appliancemodel.name : ''}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="footer align-center">
                    <button className="button" onClick={handleHide}>
                        {trans('buttons.close')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(NotReviewed);