import React, { createContext, useContext, useMemo, useState, useEffect } from 'react'
import { useAuthDataContext } from './Auth'
import Api from '../_helpers/Api'
import { useSocketContext } from './Socket'
import sound from '../assets/voice-2.ogg'

export const NotificationsContext = createContext({})

const NotificationsProvider = props => {

    const auth = useAuthDataContext();
    const listener = useSocketContext();
    const userId = auth.id();
    const userType = auth.type();
    const partnerId = auth.partnerId();

    const audio = new Audio(sound);

    const [state, setState] = useState({
        latest: null,
        list: [],
        page: 1,
        pages: 0,
        count: 0,
        hasMore: true
    });

    const load = (page = 1) => {
        Api.get('notifications/get?page=' + page)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    list: [...prev.list, ...res.data.items],
                    page: page,
                    pages: res.data.pages,
                    hasMore: res.data.has_more
                }));
            })
    }

    const loadCount = () => {
        Api.get('notifications/total')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    count: res.data
                }))
            });
    }

    const add = text => {
        let data = {
            id: Math.random().toString(),
            text: text
        }

        setState(prev => ({
            ...prev,
            latest: data
        }));
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            list: [],
            count: 0,
        }));
    }

    useMemo(() => {

        if (userId) {
            load();
            loadCount();

            listener.private(`users.${userId}.${userType}`)
                .notification(notification => {

                    // console.log(notification)

                    setState(prev => {
                        return {
                            ...prev,
                            list: [notification, ...prev.list],
                            count: prev.count + 1,
                            latest: notification,
                        }
                    });

                    audio.play();
                });

            if (auth.isEmployee()) {
                listener.private(`users.${userId}.${userType}`)
                    .listen('EmployeeUpdated', () => {
                        auth.refresh();
                    });
                listener.private(`partners.${partnerId}`)
                    .listen('PartnerUpdated', () => {
                        auth.refresh();
                    });
            }
        }

    }, []);

    const data = useMemo(() => ({
        ...state, load, add, clear
    }), [state]);

    return <NotificationsContext.Provider value={data} {...props} />
}

export const useNotificationsContext = () => useContext(NotificationsContext);

export default NotificationsProvider;