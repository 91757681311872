import React, { useState, useEffect } from 'react';
import { trans } from '../../../../_providers/Translation';

//images
import addPartIcon from '../../../../assets/img/icons/add-part.png';
import removePartIcon from '../../../../assets/img/icons/remove-part.png';
import startTaskIcon from '../../../../assets/img/icons/start-task.png';
import finishTaskIcon from '../../../../assets/img/icons/finish-task.png';
import Api from '../../../../_helpers/Api';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

function ActivityLog() {

    const history = useHistory();

    const [state, setState] = useState({
        loading: true,
        data: [],
        tab: 'all'
    });

    useEffect(() => {
        loadData();
    }, [state.tab]);

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        Api.get('activities/all?group=' + state.tab)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items
                }));
            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleShowActivity = id => {
        history.replace('?show_activity=' + id);
    }

    const handleTab = tab => {
        setState(prev => ({
            ...prev,
            tab: tab
        }));
    }

    return (
        <div className="col activity-log">
            <h3>
                {trans('random.lastActivities')}
            </h3>
            <div className="logs">
                {state.loading
                    ?
                    <Skeleton height={50} count={4} />
                    :
                    <div className="scroller">
                        {state.data.length === 0
                            ?
                            <p className="no-data">{trans('messages.noSavedActivities')}</p>
                            :
                            <ul>
                                {state.data.map(a =>
                                    <li
                                        key={a.id}
                                        onClick={() => handleShowActivity(a.id)}
                                    >
                                        <img src={addPartIcon} alt="add part" />
                                        <p>
                                            {a.trans}
                                        </p>
                                        <span className="timer" data-date={a.created_at}>
                                            {a.date_text}
                                        </span>
                                    </li>
                                )}
                            </ul>
                        }
                    </div>
                }
                <div className="buttons-holder">
                    <button
                        className={['button', 'button-small', state.tab !== 'all' ? 'button-clear' : ''].join(' ')}
                        onClick={() => handleTab('all')}
                    >
                        {trans('buttons.all')}
                    </button>
                    <button
                        className={['button', 'button-small', state.tab !== 'reservation' ? 'button-clear' : ''].join(' ')}
                        onClick={() => handleTab('reservation')}
                    >
                        {trans('buttons.reservations')}
                    </button>
                    <button
                        className={['button', 'button-small', state.tab !== 'storage' ? 'button-clear' : ''].join(' ')}
                        onClick={() => handleTab('storage')}
                    >
                        {trans('buttons.storage')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ActivityLog;