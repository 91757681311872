import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';
import Api from '../../../_helpers/Api';
import loader from '../../../assets/img/loader.svg';
import Pagination from '../../Pagination';
import Filter from './partials/Filter';
import NoDataFound from '../../partials/NoDataFound';
import checkIcon from '../../../assets/img/icons/check.png';
import Send from './partials/Send';
import Options from './partials/Options';
import { trans } from '../../../_providers/Translation'
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import inIcon from '../../../assets/img/icons/phone/incomming.png';
import outIcon from '../../../assets/img/icons/phone/outgoing.png';
import missIcon from '../../../assets/img/icons/phone/missed.png';
import cancelIcon from '../../../assets/img/icons/phone/canceled.png';
import Legend from './partials/Legend';

let timeout;

function List(props, ref) {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    // const ref = useRef(null);
    const sendModalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: [],
        formattedData: [],
        employees: [],
        total: 0,
        pages: 0,
        page: searchParams.get('page') || 1,
        hasMore: true,
        filter: {},
        setFilter: false,
        update: false,
        locales: moment.locales(),
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            data: [],
            loading: true,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                status: searchParams.get('status') || '',
                employee_id: searchParams.get('employee_id') || '',
                start: searchParams.get('start') || '',
                end: searchParams.get('end') || '',
                phone: Number(searchParams.get('phone')) || '',
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    useEffect(() => {
        let prevDate;
        let index = 0;
        let rows = [];

        state.data.map(row => {
            let date = moment(row.date);

            let currDate = date.format('Y-M-d');

            let label = date.startOf('day').calendar(null, {
                lastDay: `[${trans('calendar.yesterday')}]`,
                sameDay: `[${trans('calendar.today')}]`,
                nextDay: `[${trans('calendar.tomorrow')}]`,
                lastWeek: 'L',
                nextWeek: 'L',
                sameElse: 'L'
            });

            // console.log(label)

            if (!prevDate || prevDate != currDate) {
                index++;
            }

            if (!rows[index]) {
                rows[index] = {
                    date: currDate,
                    label: label,
                    data: []
                };
            }

            rows[index].data.push(row);

            prevDate = currDate;
        });

        rows = Object.values(rows);

        setState(prev => ({
            ...prev,
            formattedData: rows
        }));

    }, [state.data]);

    useEffect(() => {
        Api.get('partners/employees/all?active=1')
            .then(res => {
                setState(table => ({
                    ...table,
                    employees: res.data
                }))
            })
    }, []);

    const loadData = (page = 1) => {
        let url = 'voicecall/all?page=' + page;

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: [...prev.data, ...res.data.items],
                    pages: res.data.pages,
                    total: res.data.total,
                    hasMore: res.data.has_more,
                    loading: false
                }));

            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleSearch = (key, val, delay = 300) => {

        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                setFilter: new Date().getTime(),
                page: 1,
            }));
        }, delay);

    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }))
    }

    const download = id => {
        let call = state.data.find(c => Number(c.id) === Number(id));

        if (call) {
            window.open(call.record_url);
        }

    }

    const send = id => {
        sendModalRef.current.show(id);
    }

    const getIcon = call => {
        let icon;

        if (call.finalstatus === 'missed') {
            icon = missIcon;
        } else if (call.finalstatus === 'canceled') {
            icon = cancelIcon;
        } else if (call.initialstatus === 'incomming') {
            icon = inIcon;
        } else if (call.initialstatus === 'outgoing') {
            icon = outIcon;
        }

        return icon;
    }


    return (
        <>

            <Send
                ref={sendModalRef}
            />

            <Filter
                filter={state.filter}
                employees={state.employees}
                handleSearch={handleSearch}
            />

            <Legend
                filter={state.filter}
                handleSearch={handleSearch}
            />

            {state.loading
                ?
                <img className="loader" src={loader} />
                :
                state.data.length === 0
                    ?
                    <NoDataFound />
                    :
                    <>
                        <InfiniteScroll
                            initialLoad={true}
                            pageStart={1}
                            loadMore={loadData}
                            hasMore={state.hasMore}
                            loader={<img key="loader" src={loader} alt="loading" />}
                            useWindow={true}
                        // getScrollParent={() => ref.current}
                        >
                            {state.formattedData.map(group =>
                                <React.Fragment key={'g-' + group.label}>
                                    <div className="divider">
                                        <span>
                                            {group.label}
                                        </span>
                                    </div>
                                    <div className="table-scroller">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="center" style={{ width: '50px' }}>
                                                        {trans('labels.status')}
                                                    </th>
                                                    <th style={{ width: '15%' }}>
                                                        {trans('labels.phone')}
                                                    </th>
                                                    <th style={{ width: '15%' }}>
                                                        {trans('labels.refNo')}
                                                    </th>
                                                    <th style={{ width: '15%' }}>
                                                        {trans('labels.client')}
                                                    </th>
                                                    <th style={{ width: '25%' }}>
                                                        {trans('labels.employee')}
                                                    </th>
                                                    <th style={{ width: '15%' }}>
                                                        {trans('labels.date')}
                                                    </th>
                                                    <th className="center">
                                                        {trans('labels.duration')}
                                                    </th>
                                                    <th className="options">
                                                        {trans('labels.options')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {group.data.map(e =>
                                                    <tr key={'e-' + e.id}>
                                                        <td className="center" style={{ width: '50px' }}>
                                                            <img src={getIcon(e)} height="24" style={{ verticalAlign: 'middle' }} />
                                                        </td>
                                                        <td style={{ width: '15%' }}>
                                                            {e.caller_id}
                                                        </td>
                                                        <td style={{ width: '15%' }}>
                                                            {e.reservation
                                                                ?
                                                                <Link
                                                                    to={`/reservations/edit/${e.reservation.id}`}
                                                                    className="btn"
                                                                    style={{ background: e.reservation.color, color: e.reservation.text_color }}
                                                                >
                                                                    {e.reservation.reference_code}
                                                                </Link>
                                                                :
                                                                ''
                                                            }
                                                        </td>
                                                        <td style={{ width: '15%' }}>
                                                            {e.reservation && e.reservation.customer
                                                                ?
                                                                [e.reservation.customer.name, e.reservation.customer.lastname].join(' ')
                                                                :
                                                                ''
                                                            }
                                                        </td>
                                                        <td style={{ width: '25%' }}>
                                                            {e.employee?.name} {e.employee?.lastname}
                                                        </td>
                                                        <td style={{ width: '15%' }}>
                                                            <span className="timer" data-date={e.date}>
                                                                {e.date_text}
                                                            </span>
                                                        </td>
                                                        <td className="center">
                                                            {e.duration_formatted}
                                                        </td>
                                                        <td className="right">
                                                            {e.record_url
                                                                ?
                                                                <Options
                                                                    id={e.id}
                                                                    handleDownload={download}
                                                                    handleSend={send}
                                                                />
                                                                :
                                                                ''
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                            )}

                        </InfiniteScroll>
                        {/* <Pagination
                                pages={state.pages}
                                page={state.page}
                                handlePage={handlePage}
                            /> */}
                    </>
            }

        </>
    )
}

export default forwardRef(List);