import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import Api from '../../../../../_helpers/Api'
import { trans } from '../../../../../_providers/Translation';

//images
import docIcon from '../../../../../assets/img/icons/document.png'
import closeIcon from '../../../../../assets/img/icons/close.png'
import loader from '../../../../../assets/img/loader.svg'
import { useAuthDataContext } from '../../../../../_providers/Auth'

//modals

function AddInvoice(props, ref) {

    const auth = useAuthDataContext();

    const initialValidations = {};

    const initialParams = {
        type_id: 1,
        diagnostic_charge: 0,
        labour_charge: 0,
        transport_charge: 0,
        parts_charge: 0,
        parts: [],
        stockparts: [],
        parts_qty: 0,
        parts_price: 0,
        parts_vat_price: 0,
        parts_gross_price: 0
    };

    const [state, setState] = useState({
        validations: initialValidations,
        overlay: false,
        modal: false,
        loading: false,
        data: null,
        parts: [],
        stockparts: [],
        params: initialParams
    })

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleOpen(id)
        },
        close: () => {
            handleClose()
        }
    }));

    useEffect(() => {

        if (props.data.id) {
            setState(prev => ({
                ...prev,
                data: props.data,
                parts: props.data.parts,
                stockparts: props.data.stockparts,
            }));
        }

    }, [props.data]);

    useEffect(() => {

        let parts = state.parts;
        let selectedParts = state.params.parts;

        let stockparts = state.stockparts;
        let selectedStockParts = state.params.stockparts;

        let qty = 0;
        let price = 0;
        let vatprice = 0;
        let grossprice = 0;

        parts.map(p => {
            if (selectedParts[p.id]) {
                qty += 1;
                price += p.converted_price;
                vatprice += p.converted_vat_price;
                grossprice += p.converted_gross_price;
            }
        });

        stockparts.map(p => {
            if (selectedStockParts[p.id]) {
                qty += 1;
                price += p.converted_price;
                vatprice += p.converted_vat_price;
                grossprice += p.converted_gross_price;
            }
        });

        price = price.toFixed(2);
        vatprice = vatprice.toFixed(2);
        grossprice = grossprice.toFixed(2);

        setState(prev => ({
            ...prev,
            params: {
                ...prev.params,
                parts_qty: qty,
                parts_price: price,
                parts_vat_price: vatprice,
                parts_gross_price: grossprice
            }
        }));

    }, [state.params.parts, state.params.stockparts]);

    const handleOpen = (id) => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            modal: false,
            params: initialParams
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 50)
    }

    const handleSave = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            validations: initialValidations,
            loading: true
        }))

        let url = 'invoices/create/' + types[state.params.type_id].url;

        let data = new FormData(e.target)

        data.append('reservation_id', state.data.id)

        Api.post(url, data)
            .then(res => {
                props.refreshData();
                handleClose();
            })
            .catch(error => {
                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {
                        setState(prev => ({
                            ...prev,
                            validations: {
                                ...prev.validations,
                                [input]: true
                            }
                        }))
                    }
                }
            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }))
            })
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setState(prev => ({
            ...prev,
            params: {
                ...prev.params,
                [name]: val
            }
        }));
    }

    const handleCheckboxChange = e => {
        let name = e.target.name;

        setState(prev => ({
            ...prev,
            params: {
                ...prev.params,
                [name]: !prev.params[name]
            }
        }));
    }

    const handlePartsCheckboxChange = e => {

        let id = e.target.value;

        setState(prev => ({
            ...prev,
            params: {
                ...prev.params,
                parts: {
                    ...prev.params.parts,
                    [id]: !prev.params.parts[id]
                }

            }
        }));
    }

    const handleStockPartsCheckboxChange = e => {

        let id = e.target.value;

        setState(prev => ({
            ...prev,
            params: {
                ...prev.params,
                stockparts: {
                    ...prev.params.stockparts,
                    [id]: !prev.params.stockparts[id]
                }

            }
        }));
    }

    const types = {
        1: {
            name: trans('random.proforma'),
            url: 'proforma'
        },
        2: {
            name: trans('random.invoice'),
            url: 'invoice'
        }
    };

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>

            <form onSubmit={handleSave} className={`${state.modal ? 'show' : ''} popup-primary large`}>

                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.createDocument')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleClose} />
                </h2>


                {!state.data
                    ?
                    <img src={loader} alt="loading" />
                    :
                    <>
                        <div className="popup-container">
                            <div className="row">
                                {Object.entries(types).map(t =>
                                    <div key={'type-' + t[0]} className="col auto">

                                        <div className="checkbox-primary">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="type_id"
                                                    value={t[0]}
                                                    checked={Number(state.params.type_id) === Number(t[0])}
                                                    onChange={handleInputChange}
                                                />
                                                <div className="checkmark"></div>
                                                {t[1].name}
                                            </label>
                                        </div>
                                    </div>

                                )}
                            </div>
                        </div>

                        <div className="table-scroller" style={{ padding: '0 10px 0 0' }}>
                            <h3 className="heading">{trans('random.toInclude')}</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th className="center" style={{ width: '40px' }}>

                                        </th>
                                        <th style={{ width: '30%' }}>
                                            {trans('tables.description')}
                                        </th>
                                        <th style={{ width: '40px' }}>
                                            {trans('tables.quantity')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.netPrice')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.tax')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.gross')}
                                        </th>
                                        <th style={{ width: '10%' }}>
                                            {trans('tables.document')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* диагностика */}
                                    <tr>
                                        <td className="center" style={{ width: '40px' }}>
                                            <input
                                                type="checkbox"
                                                name="diagnostic_charge"
                                                value="1"
                                                checked={state.params.diagnostic_charge}
                                                disabled={state.data.diagnosticinvoice || state.data.diagnostic_price === 0 ? true : false}
                                                // disabled={state.data.diagnostic_payable_price > 0 ? false : true}
                                                // disabled={state.data.diagnostic_price > 0 ? false : true}
                                                onChange={handleCheckboxChange}
                                            />
                                        </td>
                                        <td style={{ width: '30%' }}>
                                            {trans('tables.diagnosticPrice')}
                                        </td>
                                        <td style={{ width: '40px' }}>
                                            1
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            {state.data.diagnostic_net_price_formatted}
                                            {state.data.currency.name}
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            {state.data.diagnostic_vat_price_formatted}
                                            {state.data.currency.name}
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            {state.data.diagnostic_gross_price_formatted}
                                            {state.data.currency.name}
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            {state.data.diagnosticinvoice
                                                ?
                                                <div>
                                                    <a href={`${state.data.diagnosticinvoice.signed_url}}`} target="_blank">
                                                        {state.data.diagnosticinvoice.number}
                                                    </a>
                                                    <br />
                                                    <span>
                                                        {state.data.diagnosticinvoice.date_dmy}
                                                    </span>
                                                </div>
                                                :
                                                '-'
                                            }
                                        </td>
                                    </tr>
                                    {/* труд */}
                                    <tr>
                                        <td className="center" style={{ width: '40px' }}>
                                            <input
                                                type="checkbox"
                                                name="labour_charge"
                                                value="1"
                                                checked={state.params.labour_charge}
                                                disabled={state.data.labourinvoice || state.data.labour_price === 0 ? true : false}
                                                // disabled={state.data.labour_payable_price > 0 ? false : true}
                                                // disabled={state.data.labour_price > 0 ? false : true}
                                                onChange={handleCheckboxChange}
                                            />
                                        </td>
                                        <td style={{ width: '30%' }}>
                                            {trans('tables.labourPrice')}
                                        </td>
                                        <td style={{ width: '40px' }}>
                                            1
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            {state.data.labour_net_price_formatted}
                                            {state.data.currency.name}
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            {state.data.labour_vat_price_formatted}
                                            {state.data.currency.name}
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            {state.data.labour_gross_price_formatted}
                                            {state.data.currency.name}
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            {state.data.labourinvoice
                                                ?
                                                <div>
                                                    <a href={`${state.data.labourinvoice.signed_url}`} target="_blank">
                                                        {state.data.labourinvoice.number}
                                                    </a>
                                                    <br />
                                                    <span>
                                                        {state.data.labourinvoice.date_dmy}
                                                    </span>
                                                </div>
                                                :
                                                '-'
                                            }
                                        </td>
                                    </tr>
                                    {/* транспорт */}
                                    <tr>
                                        <td className="center" style={{ width: '40px' }}>
                                            <input
                                                type="checkbox"
                                                name="transport_charge"
                                                value="1"
                                                checked={state.params.transport_charge}
                                                disabled={state.data.transportinvoice || state.data.transport_price === 0 ? true : false}
                                                // disabled={state.data.transport_payable_price > 0 ? false : true}
                                                // disabled={state.data.transport_price > 0 ? false : true}
                                                onChange={handleCheckboxChange}
                                            />
                                        </td>
                                        <td style={{ width: '30%' }}>
                                            {trans('tables.transportPrice')}
                                        </td>
                                        <td style={{ width: '40px' }}>
                                            1
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            {state.data.transport_net_price_formatted}
                                            {state.data.currency.name}
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            {state.data.transport_vat_price_formatted}
                                            {state.data.currency.name}
                                        </td>
                                        <td style={{ width: '20%' }}>
                                            {state.data.transport_gross_price_formatted}
                                            {state.data.currency.name}
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            {state.data.transportinvoice
                                                ?
                                                <div>
                                                    <a href={`${state.data.transportinvoice.signed_url}`} target="_blank">
                                                        {state.data.transportinvoice.number}
                                                    </a>
                                                    <br />
                                                    <span>
                                                        {state.data.transportinvoice.date_dmy}
                                                    </span>
                                                </div>
                                                :
                                                '-'
                                            }
                                        </td>
                                    </tr>
                                    {/* части */}
                                    {state.data.parts_count > 0 || state.data.stockparts_count > 0
                                        ?
                                        <tr>
                                            <td className="center" style={{ width: '40px' }}>
                                                <input
                                                    type="checkbox"
                                                    name="parts_charge"
                                                    value="1"
                                                    checked={state.params.parts_charge}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </td>
                                            <td style={{ width: '30%' }}>
                                                {trans('tables.partsPrice')}
                                            </td>
                                            <td style={{ width: '40px' }}>
                                                {state.params.parts_qty}
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {state.params.parts_price}
                                                {state.data.currency.name}
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {state.params.parts_vat_price}
                                                {state.data.currency.name}
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {state.params.parts_gross_price}
                                                {state.data.currency.name}
                                            </td>
                                            <td style={{ width: '10%' }}>

                                            </td>
                                        </tr>
                                        :
                                        ''
                                    }

                                    {state.params.parts_charge ?
                                        <>
                                            <tr>
                                                <td colSpan="7" style={{ padding: '0' }}>
                                                    <h3 className="heading">
                                                        {trans('tables.parts')}
                                                    </h3>
                                                </td>
                                            </tr>

                                            {state.parts.map(p =>
                                                <tr key={'part-' + p.id}>
                                                    <td className="center" style={{ width: '40px' }}>
                                                        <input
                                                            type="checkbox"
                                                            name="parts[]"
                                                            value={p.id}
                                                            checked={state.params.parts[p.id]}
                                                            // disabled={(p.invoice && !p.invoice.credited) || !state.data.can_generate_parts_invoice ? true : false}
                                                            disabled={(p.invoice && !p.invoice.credited) ? true : false}
                                                            onChange={handlePartsCheckboxChange}
                                                        />
                                                    </td>
                                                    <td style={{ width: '30%' }}>
                                                        {p.name}
                                                    </td>
                                                    <td style={{ width: '40px' }}>
                                                        {p.quantity}
                                                    </td>
                                                    <td style={{ width: '20%' }}>
                                                        {p.converted_price_formatted}
                                                        {state.data.currency.name}
                                                    </td>
                                                    <td style={{ width: '20%' }}>
                                                        {p.converted_vat_price_formatted}
                                                        {state.data.currency.name}
                                                    </td>
                                                    <td style={{ width: '20%' }}>
                                                        {p.converted_gross_price_formatted}
                                                        {state.data.currency.name}
                                                    </td>
                                                    <td style={{ width: '10%' }}>
                                                        {p.invoice
                                                            ?
                                                            <div>
                                                                <a href={`${p.invoice.signed_url}`} target="_blank">
                                                                    {p.invoice.number}
                                                                </a>
                                                                <br />
                                                                <span>
                                                                    {p.invoice.date_dmy}
                                                                </span>
                                                            </div>
                                                            :
                                                            '-'
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                            {state.stockparts.map(p =>
                                                <tr key={'stockpart-' + p.id}>
                                                    <td className="center" style={{ width: '40px' }}>
                                                        <input
                                                            type="checkbox"
                                                            name="stockparts[]"
                                                            value={p.id}
                                                            checked={state.params.stockparts[p.id]}
                                                            disabled={p.invoice && !p.invoice.credited ? true : false}
                                                            onChange={handleStockPartsCheckboxChange}
                                                        />
                                                    </td>
                                                    <td style={{ width: '30%' }}>
                                                        {p.storage.part.name}
                                                    </td>
                                                    <td style={{ width: '40px' }}>
                                                        {p.quantity}
                                                    </td>
                                                    <td style={{ width: '20%' }}>
                                                        {p.converted_price_formatted}
                                                        {state.data.currency.name}
                                                    </td>
                                                    <td style={{ width: '20%' }}>
                                                        {p.converted_vat_price_formatted}
                                                        {state.data.currency.name}
                                                    </td>
                                                    <td style={{ width: '20%' }}>
                                                        {p.converted_gross_price_formatted}
                                                        {state.data.currency.name}
                                                    </td>
                                                    <td style={{ width: '10%' }}>
                                                        {p.invoice
                                                            ?
                                                            <div>
                                                                <a href={`${p.invoice.signed_url}`} target="_blank">
                                                                    {p.invoice.number}
                                                                </a>
                                                                <br />
                                                                <span>
                                                                    {p.invoice.date_dmy}
                                                                </span>
                                                            </div>
                                                            :
                                                            '-'
                                                        }
                                                    </td>
                                                </tr>
                                            )}

                                        </>
                                        :
                                        ''
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                }

                <div className="footer align-center">
                    {state.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button onClick={handleClose}>
                                {trans('buttons.cancel')}
                            </button>
                            {state.params && (state.params.diagnostic_charge || state.params.labour_charge || state.params.transport_charge || (state.params.parts_charge && state.params.parts_qty))
                                ?
                                <button className="button-primary" type="submit">
                                    {trans('buttons.create')}
                                </button>
                                :
                                ''
                            }
                        </>
                    }
                </div>

            </form>
        </div>
    )
}

export default forwardRef(AddInvoice)