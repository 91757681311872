import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../../_helpers/Api';

//images
import docIcon from '../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../assets/img/icons/close.png';
import loader from '../../../../../assets/img/loader.svg';
import { useAuthDataContext } from '../../../../../_providers/Auth';
import { validate, trans } from '../../../../../_providers/Translation';

function Auth(props, ref) {

    const auth = useAuthDataContext();

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: null,
        loading: false,
        onSave: null
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: () => {
            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));


    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'change-password';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave(res.data);
                }

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: err }
                        });
                    }
                }

            });
    }


    useEffect(() => {
        console.log(validations)
    }, [validations])

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form autoComplete="off" id="form" className={`${popup.modal ? 'show' : ''} popup-primary small`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.authSettings')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                {validations &&
                    <div className="alert danger">
                        {Object.entries(validations).map(v =>
                            v[1].map(r =>
                                <p>{validate(v[0], r)}</p>
                            )
                        )}
                    </div>
                }

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.email')}
                        </label>

                        <input
                            type="text"
                            value={auth.data.user.email}
                            disabled
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.currentPassword')}
                        </label>

                        <input
                            type="password"
                            name="old_password"
                            className={validations && validations.old_password ? 'validate' : ''}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.newPassword')}
                        </label>

                        <input
                            type="password"
                            name="new_password"
                            className={validations && validations.new_password ? 'validate' : ''}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.repeatNewPassword')}
                        </label>

                        <input
                            type="password"
                            name="new_password_confirmation"
                            className={validations && validations.new_password ? 'validate' : ''}
                        />
                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.close')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Auth);