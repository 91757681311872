import React, { useEffect, useState, useRef } from 'react'
import Api from '../../../_helpers/Api'
import Pagination from '../../Pagination'
import { useHistory, useLocation } from 'react-router-dom'

import PartsPages from '../../partials/PartsPages'
import Skeleton from 'react-loading-skeleton'
import NoDataFound from '../../partials/NoDataFound'
import Dropdown from '../../partials/Dropdown'
import { fromInfo, trans } from '../../../_providers/Translation'
import Help from '../../partials/Help'

let timeout;

function MostUsed(props) {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [parts, setParts] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setParts(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                from: searchParams.get('from') || '',
                to: searchParams.get('to') || '',
                category_id: searchParams.get('category_id') || '',
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {

        if (parts.setFilter) {
            let url = '?page=' + parts.page;

            Object.entries(parts.filter).map(filter => {
                if (filter[1]) {
                    url += '&' + filter[0] + '=' + filter[1];
                }
            });

            history.push(url);
        }

    }, [parts.setFilter]);

    useEffect(() => {
        if (parts.update) {
            loadParts()
        }
    }, [parts.update])

    const loadParts = () => {
        setParts(prev => ({
            ...prev,
            loading: true
        }))

        let url = 'ordered-parts/get-most-used?page=' + parts.page;

        Object.entries(parts.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setParts(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                    filter: {
                        ...prev.filter,
                        from: res.data.start,
                        to: res.data.end
                    }
                }))
            })
            .catch(() => {
                setParts(prev => ({
                    ...prev,
                    loading: false
                }))
            })
    }

    // Search
    const handleSearch = e => {
        let key = e.target.name;
        let val = e.target.value;

        clearTimeout(timeout);

        setParts(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },

        }));

        timeout = setTimeout(() => {
            setParts(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const handleCategory = data => {

        let val = data.id || '';

        if (data.id || data.name == '') {
            setParts(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    category_id: val,
                },
                page: 1,
                setFilter: new Date().getTime()
            }));
        }

    }

    const handlePage = page => {
        setParts(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    const handleSelectMobileFilter = e => {
        let selectedValue = e.target.value
        let fields = [...document.querySelectorAll('.filter-standard .col')]
        fields.map(field => {
            if (field.getAttribute('data-id') == selectedValue) field.style.display = 'flex'
            else if (field.getAttribute('data-id')) field.style.display = 'none'
        })
    }

    return (
        <>

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.mostUsedParts')} <Help>{fromInfo('parts.mostUsed')}</Help>
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.mostUsedParts')}
                        </p>
                    </div>

                </div>

                <PartsPages />

                <div className="filter-standard">
                    <div className="col select-filter-col">
                        <label>
                            {trans('filters.filterBy')}
                        </label>
                        <select onChange={handleSelectMobileFilter}>
                            <option value="1" selected>
                                {trans('labels.fromDate')}
                            </option>
                            <option value="2">
                                {trans('labels.toDate')}
                            </option>
                            <option value="3">
                                {trans('labels.category')}
                            </option>
                        </select>
                    </div>
                    <div className="col" data-id="1">
                        <label>
                            {trans('labels.fromDate')}
                        </label>
                        <input
                            type="date"
                            name="from"
                            onKeyUp={handleSearch}
                            onChange={handleSearch}
                            value={parts.filter.from}
                        />
                    </div>
                    <div className="col" data-id="2">
                        <label>
                            {trans('labels.toDate')}
                        </label>
                        <input
                            type="date"
                            name="to"
                            onKeyUp={handleSearch}
                            onChange={handleSearch}
                            value={parts.filter.to}
                        />
                    </div>
                    <div className="col" data-id="3">
                        <label>
                            {trans('labels.category')}
                        </label>
                        <Dropdown
                            inputIdValue={Number(parts.filter.category_id)}
                            url="partners/categories/all"
                            renderText={data => {
                                return data.name
                            }}
                            onChange={data => {
                                handleCategory(data);
                            }}
                        />
                    </div>
                </div>

                {parts.loading
                    ?
                    <Skeleton height={45} count={10} />
                    :
                    parts.data.length === 0
                        ?
                        <NoDataFound />
                        :
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '30px' }}>
                                            ID
                                        </th>
                                        <th>
                                            {trans('tables.name')}
                                        </th>
                                        <th>
                                            {trans('tables.number')}
                                        </th>
                                        <th className="center">
                                            {trans('tables.usedTimes')}
                                        </th>
                                        <th className="center">
                                            {trans('tables.quantity')}

                                        </th>
                                        <th className="right">
                                            {trans('tables.lastUsed')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {parts.data.map(p =>
                                        <tr key={p.id}>
                                            <td style={{ width: '30px' }}>
                                                {p.part.id}
                                            </td>

                                            <td>
                                                {p.part.name && p.part.name}
                                            </td>
                                            <td>
                                                {p.part.number}
                                            </td>
                                            <td className="center">
                                                {p.usages}
                                            </td>
                                            <td className="center">
                                                {p.total_quantity}
                                            </td>
                                            <td className="right">
                                                {p.last_usage_dmy}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <Pagination
                                pages={parts.pages}
                                page={parts.page}
                                handlePage={handlePage}
                            />
                        </>
                }
            </div>
        </>
    )
}

export default MostUsed