import React, { useEffect, useRef, useState } from 'react';
import FilterTabs from './partials/FilterTabs';
import Stats from './stats/Index';
import List from './list/Index';
import { useHistory, useLocation } from 'react-router-dom';
import Types from './types/Index';
import TypesIndex from './TypesIndex';
import Dashboard from './dashboard/Index';
import Calendar from './calendar/Index';
import { fromInfo, trans } from '../../../_providers/Translation';
import Help from '../../partials/Help';

function Contractors(props) {

    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search)

    const tabs = [
        {
            id: 'dashboard',
            name: trans('tabs.dashboard'),
            component: Dashboard,
        },
        {
            id: 'list',
            name: trans('tabs.list'),
            component: List,
            hasRef: true,
            help: <Help>{fromInfo('contractors.list')}</Help>
        },
        // {
        //     id: 'calendar',
        //     name: trans('tabs.calendar'),
        //     component: Calendar,
        //     hasRef: false
        // },
        {
            id: 'types',
            name: trans('tabs.types'),
            component: TypesIndex,
            hasRef: true,
            help: <Help>{fromInfo('contractors.types')}</Help>
        },
        {
            id: 'stats',
            name: trans('tabs.stats'),
            component: Stats,
            help: <Help>{fromInfo('contractors.stats')}</Help>
        },
    ];

    const tab = searchParams.get('tab') ? searchParams.get('tab') : tabs[0].id;

    const componentRef = useRef(null);

    const hasAddButton = searchParams.get('types') !== 'calendar';

    const [state, setState] = useState({
        tab: '',
        index: null,
        loading: true,
        data: null
    });

    useEffect(() => {

        setState(prev => ({
            ...prev,
            loading: true,
            tab: tab,
            index: findTabIndex(tab)
        }));

    }, [location.search]);

    const handleTab = id => {

        let index = findTabIndex(id);

        setState(prev => ({
            ...prev,
            tab: id,
            index: index
        }));

        history.push('?tab=' + id);
    }

    const findTabIndex = id => {
        let index = tabs.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    const handleAdd = () => {
        let modal = componentRef.current;

        if (modal) {
            modal.add();
        }
    }

    return (
        <div className="wrapper">
            <div className="top-part">
                <div className="left">
                    <h1 className="page-heading">
                        {trans('headings.contractors')}
                    </h1>
                    <p className="page-subheading">
                        {trans('subheadings.contractors')}
                    </p>
                </div>
                {tabs[state.index] && tabs[state.index].hasRef && hasAddButton
                    ?
                    <div className="right">
                        <button
                            className="button-add"
                            onClick={handleAdd}
                        >
                            {trans('buttons.add')}
                        </button>
                    </div>
                    :
                    ''
                }
            </div>

            <FilterTabs
                tabs={tabs}
                tab={state.tab}
                handleTab={handleTab}
            />

            {tabs[state.index] &&
                React.createElement(tabs[state.index].component, {
                    ref: componentRef
                })
            }

        </div>
    )
}

export default Contractors;