import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';
import SecondaryTab from './SecondaryTab';
import { useLanguageContext } from '../../../../_providers/Language';
import { trans } from '../../../../_providers/Translation'

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import Dropdown from '../../../partials/Dropdown';

function AddOrEdit(props, ref) {

    const languages = useLanguageContext();

    const defaultData = {
        langs: {}
    }

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: defaultData,
        loading: false,
        tab: 1,
        key: 1
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: defaultData,
                key: Math.random().toString()
            }));

            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                data: defaultData,
                loading: false
            }));

            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            Api.get(`parts/find?id=${popup.editId}`)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data,
                    }));
                });
        }

    }, [popup.edit]);


    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();


        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'parts/edit';
        } else {
            url = 'parts/add';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    props.handleUpdate(res.data)
                } else {
                    props.handleCreate(res.data);
                }

                handleHide(e);

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const tabsValue = newValue => {
        setPopup(popup => ({
            ...popup,
            tab: newValue,
        }))
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        //popup.data && popup.data.notificationsetting && popup.data.notificationsetting.min_quantity ?
        // popup.data.notificationsetting.min_quantity
        console.log(popup.data)
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                //[name]: val
                notificationsetting: {
                    ...prev.data.notificationsetting,
                    [name]: val
                }
            }
        }));
    }

    const handleChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        //popup.data && popup.data.notificationsetting && popup.data.notificationsetting.min_quantity ?
        // popup.data.notificationsetting.min_quantity
        console.log()
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleNameChange = e => {
        let id = e.target.name.split('[')[1].replace(']', '');
        let val = e.target.value;

        if (!popup.data.langs[id]) {
            popup.data.langs[id] = {};
        }

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                langs: {
                    ...prev.data.langs,
                    [id]: {
                        ...prev.data.langs[id],
                        name: val
                    }
                }
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary large`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {popup.edit ? trans('headings.editPart') : trans('headings.addPart')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>


                <div className="popup-container x2">

                    <div className="col">

                        <label htmlFor="">
                            {trans('labels.name')}
                        </label>
                        <input
                            type="text"
                            name="name"
                            className={validations && validations.name ? 'validate' : ''}
                            value={popup.data && popup.data.name ? popup.data.name : ''}
                            onChange={handleChange}
                        />

                    </div>

                    <div className="col">

                        <label htmlFor="">
                            {trans('labels.number')}
                        </label>
                        <input
                            type="text"
                            name="number"
                            className={validations && validations.number ? 'validate' : ''}
                            value={popup.data && popup.data.number ? popup.data.number : ''}
                            onChange={handleChange}
                        />

                    </div>

                </div>

                <br />

                <div className="popup-container x2">
                    <div className="col">
                        <label htmlFor="category_id">
                            {trans('labels.category')}
                        </label>
                        <Dropdown
                            key={popup.key}
                            inputName="category"
                            inputIdName="category_id"
                            inputValue={popup.data && popup.data.category ? popup.data.category.name : ''}
                            inputIdValue={popup.data && popup.data.category_id ? Number(popup.data.category_id) : ''}
                            inputClassName={validations && validations.category_id ? 'validate' : ''}
                            url="partners/categories/all"
                            renderText={data => {
                                return data.name
                            }}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="image">
                            {trans('labels.image')}
                        </label>
                        <input type="file" name="image" accept="image/*" />
                    </div>
                </div>

                <br />

                <div className="popup-container x2">
                    <div className="col">
                        <label htmlFor="">
                            {trans('labels.minQuantity')}
                        </label>
                        <input
                            type="number"
                            min="0"
                            step="1"
                            name="min_quantity"
                            className={validations && validations.min_quantity ? 'validate' : ''}
                            value={popup.data && popup.data.notificationsetting && popup.data.notificationsetting.min_quantity ?
                                popup.data.notificationsetting.min_quantity
                                :
                                ''
                            }
                            onChange={handleInputChange}
                        />
                    </div>

                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.close')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);