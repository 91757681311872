import React, { useEffect, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../../assets/css/calendar.css';

import moment from 'moment';
import { trans } from '../../../../_providers/Translation';
import View from './modals/View';

const localizer = momentLocalizer(moment);

localizer.formats.yearHeaderFormat = 'YYYY'

function Index(props) {

    const start = props.start ? new Date(props.start) : new Date();
    const viewModalRef = useRef(null);

    const [events, setEvents] = useState([]);

    useEffect(() => {
        let evnts = [];

        props.data.map(event => {
            evnts.push({
                title: event.title,
                start: moment(event.date),
                end: moment(event.deadline),
                allDay: false,
                ...event
            });
        });

        setEvents(evnts);

    }, [props.data])


    const handleRangeChange = range => {

        // console.log(range)

        let start;
        let end;

        if (Array.isArray(range)) {
            start = moment(range[0]).format('YYYY-MM-DD');
            end = moment(range[range.length - 1]).format('YYYY-MM-DD');
        } else if (typeof range === 'object') {
            start = moment(range.start).format('YYYY-MM-DD');
            end = moment(range.end).format('YYYY-MM-DD');
        }

        if (start && end) {
            props.handleRangeChange(start, end);
        }
    }

    const handleSelectEvent = data => {
        viewModalRef.current.open(data);
    }

    return (
        <>
            <View
                ref={viewModalRef}
                handleRead={props.handleRead}
            />

            <div style={{ height: '800px' }}>
                <Calendar
                    localizer={localizer}
                    defaultDate={start}
                    events={events}
                    views={['month', 'week', 'day']}
                    messages={{
                        next: trans('calendar.next'),
                        previous: trans('calendar.previous'),
                        today: trans('calendar.today'),
                        month: trans('calendar.month'),
                        week: trans('calendar.week'),
                        day: trans('calendar.day')
                    }}
                    popup
                    startAccessor="start"
                    endAccessor="end"
                    onRangeChange={handleRangeChange}
                    onSelectEvent={handleSelectEvent}
                />
            </div>
        </>
    )
}

export default Index;