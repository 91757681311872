import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Api from '../../../../_helpers/Api';
import Pagination from '../../../Pagination';
import Table from '../../../partials/InvoicesTable';
import Filter from '../../invoices/partials/Filter';
import FilterInvoices from './Filter';

let timeout;

function Index(props) {

    const params = useParams();
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const tabs = ['all', 'paid', 'unpaid'];
    const tab = searchParams.get('tab');

    const [state, setState] = useState({
        loading: true,
        page: 1,
        data: [],
        total: 0,
        pages: 0,
        filter: {
            status: tabs.indexOf(tab) > -1 ? tab : tabs[0],
        },
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                start_date: searchParams.get('start_date') || '',
                end_date: searchParams.get('end_date') || '',
                search: searchParams.get('search') || '',
                status: searchParams.get('status') || tabs[0],
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'contractors/invoices' + '?id=' + params.id + '&page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }))
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            })
    }

    const refreshData = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const handleTab = val => {

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                status: val,
            },
            page: 1,
            setFilter: Math.random().toString(),
            loading: true
        }))
    }

    //Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            }
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: Math.random().toString() }));
    }

    return (
        <>
            <Filter
                filter={state.filter}
                handleSearch={handleSearch}
            />

            <FilterInvoices
                tab={state.filter.status}
                handleTab={handleTab}
            />

            <Table
                invoices={state.data}
                loading={state.loading}
                canModify={true}
                refreshData={refreshData}
                showReservation={true}
                showPaidAll={state.filter.status === 'unpaid'}
                showUnpaidAll={state.filter.status === 'paid'}
            />

            {!state.loading
                ?
                <Pagination
                    pages={state.pages}
                    page={state.page}
                    handlePage={handlePage}
                />
                :
                ''
            }
        </>
    )
}

export default Index;