import React, { createContext, useContext, useMemo, useState } from 'react';
import Api from '../_helpers/Api';

export const CurrencyContext = createContext({});

const CurrencyProvider = props => {

    const [currencies, setCurrencies] = useState([]);

    useMemo(() => {
        Api.get('currencies')
            .then(res => {
                setCurrencies(res.data);
            });
    }, []);

    const data = useMemo(() => (currencies), [currencies]);

    return <CurrencyContext.Provider value={data} {...props} />;
}

export const useCurrencyContext = () => useContext(CurrencyContext);

export default CurrencyProvider;