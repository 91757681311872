import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Chart from 'react-google-charts';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';
import NoDataFound from '../../../partials/NoDataFound';


function Index(props) {

    const [state, setState] = useState({
        loading: true,
        chart: null,
        data: {
            dates: [],
            contractors: []
        },
        summary: false,
    });

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {

        if (!state.data.dates) {
            return;
        }

        let label = trans('calendar.month');

        let chart;

        // обобщена 
        if (state.summary) {
            chart = [
                [label, trans('random.reservationsCount')],
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label, d.total
                ];

                chart.push(arr);
            });
        }
        // по месеци
        else {
            let chartArr = [label];

            state.data.contractors.map(c => {
                chartArr.push(c.name)
            });

            chart = [
                chartArr
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label,
                ];

                state.data.contractors.map(c => {
                    arr.push(c.dates[d.date].total);
                });

                chart.push(arr);
            });

        }

        setState(prev => ({
            ...prev,
            chart: chart
        }))

    }, [state.data, state.summary])

    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        Api.get('contractors/stats/reservations/total')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }))
            });

    }

    const toggleSummaryData = val => {
        setState(prev => ({
            ...prev,
            summary: val
        }));
    }

    return (
        <>
            <div className="filter-standard">
                <div className="col" data-id="4" style={{ justifyContent: 'center' }}>
                    <div style={{ display: 'flex' }}>
                        <div className="col">
                            <label className="flex">
                                <input
                                    type="radio"
                                    checked={!state.summary}
                                    onClick={() => toggleSummaryData(false)}
                                />

                                <span>{trans('random.inDetail')}</span>
                            </label>
                        </div>

                        <div className="col">
                            <label className="flex">
                                <input
                                    type="radio"
                                    checked={state.summary}
                                    onClick={() => toggleSummaryData(true)}
                                />

                                <span>{trans('random.summarized')}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="chart"
                style={{ padding: '10px', background: '#fff' }}
            >
                {state.loading
                    ?
                    <Skeleton height={350} />
                    :
                    state.data.contractors.length === 0
                        ?
                        <NoDataFound />
                        :
                        <Chart
                            width={window.innerWidth <= 1024 ? '900px' : '100%'}
                            height={'350px'}
                            chartType="Bar"
                            className="animated-chart-vertical animated-chart-start"
                            loader={'...'}
                            data={state.chart}
                            options={{
                                // Material design options
                                chart: {
                                    title: trans('random.reservationsCount'),
                                    subtitle: trans('random.forLastYear')
                                },
                                legend: {
                                    position: 'left'
                                }

                            }}
                            chartEvents={[
                                {
                                    eventName: "ready",
                                    callback: ({ chartWrapper, google }) => {
                                        const chartEl = chartWrapper.getChart().container;
                                        setTimeout(() => {
                                            chartEl.classList.remove('animated-chart-start')
                                        }, 100)
                                    },
                                }
                            ]}
                        />
                }
            </div>
        </>

    )
}

export default Index;