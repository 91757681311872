import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../../_providers/Translation';

//images
import riseIcon from '../../../../assets/img/icons/rise.jpg';
import Api from '../../../../_helpers/Api';

function CurrentDataChart() {

    const [state, setState] = useState({
        loading: true,
        data: {}
    });

    useEffect(() => {
        Api.get('accounts/compared-data')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    loading: false
                }));
            });
    }, []);

    return (
        <div className="col current-data-chart">
            <h3>
                {trans('random.lastMonthStatistic')}
            </h3>
            <div className="chart">
                {state.loading
                    ?
                    <ul>
                        <li>
                            <Skeleton width={155} height={155} count={1} />
                        </li>
                        <li>
                            <Skeleton width={155} height={155} count={1} />
                        </li>
                        <li>
                            <Skeleton width={155} height={155} count={1} />
                        </li>
                        <li>
                            <Skeleton width={155} height={155} count={1} />
                        </li>
                    </ul>
                    :
                    <ul>
                        <li>
                            <div>
                                <img src={riseIcon} alt="rise" />
                                <h4>
                                    {state.data.incomes.diff}
                                    <span>
                                        %
                                </span>
                                </h4>
                                <p>
                                    {state.data.incomes.operator !== '<' ? trans('random.more') : trans('random.less')} {trans('random.incomeComparedTolastMonth')}
                                </p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src={riseIcon} alt="rise" />
                                <h4>
                                    {state.data.expenses.diff}
                                    <span>
                                        %
                            </span>
                                </h4>
                                <p>
                                    {state.data.expenses.operator !== '<' ? trans('random.more') : trans('random.less')} {trans('random.expenseComparedTolastMonth')}
                                </p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src={riseIcon} alt="rise" />
                                <h4>
                                    {state.data.addresses.diff}
                                    <span>
                                        %
                                    </span>
                                </h4>
                                <p>
                                    {state.data.addresses.operator !== '<' ? trans('random.more') : trans('random.less')} {trans('random.visitsComparedToLastMonth')}
                                </p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src={riseIcon} alt="rise" />
                                <h4>
                                    {state.data.reservations.diff}
                                    <span>
                                        %
                                </span>
                                </h4>
                                <p>
                                    {state.data.addresses.operator !== '<' ? trans('random.more') : trans('random.less')} {trans('random.ordersComparedToLastMonth')}
                                </p>
                            </div>
                        </li>
                    </ul>
                }

                {/* <h5>
                    * Lorem Ipsum е елементарен примерен текст, използван в печатарската и типографската индустрия. Lorem Ipsum е
                    индустриален стандарт от около 1500 година
                </h5> */}
            </div>
        </div>
    )
}

export default CurrentDataChart;