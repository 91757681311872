import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from "react-router-dom"
import '../../assets/css/aside.css'
import { useAuthDataContext } from '../../_providers/Auth'

//images
import asideArrowIcon from '../../assets/img/icons/aside-arrow.png'
import dashboardIcon from '../../assets/img/icons/dashboard.png'
import reservationIcon from '../../assets/img/icons/reservations.png'
import invoiceIcon from '../../assets/img/icons/fakturi.png'
import spravkiIcon from '../../assets/img/icons/spravki.png'
import belejkiIcon from '../../assets/img/icons/belejki.png'
import rukovodstvaIcon from '../../assets/img/icons/rukovodstva.png'
import dogovorniIcon from '../../assets/img/icons/dogovorni-kompanii.png'
import chastiIcon from '../../assets/img/icons/chasti.png'
import infoIcon from '../../assets/img/icons/info.png'
import adresiIcon from '../../assets/img/icons/adresi.png'
import klientiIcon from '../../assets/img/icons/klienti.png'
import usersIcon from '../../assets/img/icons/users.png'
import branshoveIcon from '../../assets/img/icons/branshove.png'
import urediIcon from '../../assets/img/icons/uredi.png'
import markiIcon from '../../assets/img/icons/marki.png'
import licenziIcon from '../../assets/img/icons/licenzi.png'
import razhodiIcon from '../../assets/img/icons/razhodi.png'
import kategoriiIcon from '../../assets/img/icons/kategorii.png'
import planIcon from '../../assets/img/icons/plan.png'
import settingsIcon from '../../assets/img/header/icons/settings.png'
import kontraktoriIcon from '../../assets/img/icons/kontraktori.png'
import bankoviIcon from '../../assets/img/icons/bankovi-operacii.png'
import smsIcon from '../../assets/img/icons/sms.png'
import websiteIcon from '../../assets/img/icons/website.png'
import callIcon from '../../assets/img/icons/call.png'
import bonusPolitikaIcon from '../../assets/img/icons/bonus.png'
import { trans } from '../../_providers/Translation'

function Aside() {
    const auth = useAuthDataContext()
    const location = useLocation()
    const menuRef = useRef(null);

    const [aside, setAside] = useState({
        aside: 0,
    })

    useEffect(() => {
        const path = location.pathname;
        const menu = menuRef.current;

        if (path === '/') {
            let el = menu.querySelector('li');

            if (el) {
                el.querySelector('a').click();
            }
        }
    }, [location.pathname]);

    const segment = index => {
        let path = location.pathname;
        let array = path.replace('/', '').split('/');
        let segment = array[index] || '';

        return segment;
    }

    const handleMenu = () => {
        setAside(prev => ({
            ...prev,
            aside: prev.aside ? 0 : 1
        }))
    }

    return (
        <aside ref={menuRef} className={[aside.aside ? '' : 'shorten', auth.isAdmin() ? 'extra' : ''].join(' ')}>
            <div className="short" onClick={handleMenu}>
                <img src={asideArrowIcon} className={aside.aside ? '' : 'rotated'} />
                <span>
                    {trans('nav.hide')}
                </span>
            </div>
            <ul>
                {auth.isAdmin() &&
                    <>
                        {auth.access('partners') &&
                            <li className={segment(0) === 'partners' ? 'active' : ''}>
                                <Link to="/partners">
                                    <i>
                                        <img src={usersIcon} alt="partners" />
                                    </i>
                                    <span>
                                        {trans('headings.services')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.access('branches') &&
                            <li className={segment(0) === 'branches' ? 'active' : ''}>
                                <Link to="/branches">
                                    <i>
                                        <img src={branshoveIcon} alt="branches" />
                                    </i>
                                    <span>
                                        {trans('headings.branches')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.access('licenses') &&
                            <li className={segment(0) === 'licenses' ? 'active' : ''}>
                                <Link to="/licenses">
                                    <i>
                                        <img src={licenziIcon} alt="licenses" />
                                    </i>
                                    <span>
                                        {trans('headings.licenses')}
                                    </span>
                                </Link>
                            </li>
                        }

                        {auth.access('licenses') &&
                            <li className={segment(0) === 'sms-package' ? 'active' : ''}>
                                <Link to="/sms-package">
                                    <i>
                                        <img src={smsIcon} alt="smsPackage" />
                                    </i>
                                    <span>
                                        {trans('headings.smsPackages')}
                                    </span>
                                </Link>
                            </li>
                        }

                        {auth.access('invoices') &&
                            <li className={segment(0) === 'invoices' ? 'active' : ''}>
                                <Link to="/invoices">
                                    <i>
                                        <img src={invoiceIcon} alt="invoices" />
                                    </i>
                                    <span>
                                        {trans('nav.invoices')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.access('accounts') &&
                            <li className={segment(0) === 'accounts' ? 'active' : ''}>
                                <Link to="/accounts/incomes">
                                    <i>
                                        <img src={spravkiIcon} alt="accounts" />
                                    </i>
                                    <span>
                                        {trans('nav.accounts')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.access('expenses') &&
                            <li className={segment(0) === 'expenses' ? 'active' : ''}>
                                <Link to="/expenses">
                                    <i>
                                        <img src={razhodiIcon} alt="expenses" />
                                    </i>
                                    <span>
                                        {trans('nav.expenses')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.access('banktransactions') &&
                            <li className={segment(0) === 'banktransactions' ? 'active' : ''}>
                                <Link to="/banktransactions">
                                    <i>
                                        <img src={bankoviIcon} alt="banktransactions" />
                                    </i>
                                    <span>
                                        {trans('nav.banktransactions')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.access('todos') &&
                            <li className={segment(0) === 'todo' ? 'active' : ''}>
                                <Link to="/todo">
                                    <i>
                                        <img src={belejkiIcon} alt="todo" />
                                    </i>
                                    <span>
                                        {trans('nav.todos')}
                                    </span>
                                </Link>
                            </li>
                        }

                        {auth.isSuperAdmin() &&
                            <>
                                <li className={segment(0) === 'admins' ? 'active' : ''}>
                                    <Link to="/admins">
                                        <i>
                                            <img src={usersIcon} alt="admins" />
                                        </i>
                                        <span>
                                            {trans('nav.users')}
                                        </span>
                                    </Link>
                                </li>
                                <li className={segment(0) === 'settings' ? 'active' : ''}>
                                    <Link to="/settings">
                                        <i>
                                            <img src={settingsIcon} alt="settings" />
                                        </i>
                                        <span>
                                            {trans('nav.settings')}
                                        </span>
                                    </Link>
                                </li>
                            </>
                        }
                    </>
                }

                {/* за сервизи */}
                {!auth.isAdmin() &&
                    <>
                        {(auth.isManager() || auth.permission('dashboard')) &&
                            <li className={segment(0) === 'dashboard' ? 'active' : ''}>
                                <Link to="/dashboard">
                                    <i>
                                        <img src={dashboardIcon} alt="dashboard" />
                                    </i>
                                    <span>
                                        {trans('nav.dashboard')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.permission('schedule') &&
                            <li className={segment(0) === 'schedule' ? 'active' : ''}>
                                <Link to="/schedule">
                                    <i>
                                        <img src={planIcon} alt="schedule" />
                                    </i>
                                    <span>
                                        {trans('nav.schedule')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.access('reservations') &&
                            <li className={segment(0) === 'reservations' ? 'active' : ''}>
                                <Link to="/reservations">
                                    <i>
                                        <img src={reservationIcon} alt="reservation" />
                                    </i>
                                    <span>
                                        {trans('nav.reservations')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.permission('parts') &&
                            <li className={segment(0) === 'parts' ? 'active' : ''}>
                                <Link to="/parts/storage">
                                    <i>
                                        <img src={chastiIcon} alt="parts" />
                                    </i>
                                    <span>
                                        {trans('nav.parts')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.permission('contractors') &&
                            <li className={segment(0) === 'contractors' ? 'active' : ''}>
                                <Link to="/contractors">
                                    <i>
                                        <img src={dogovorniIcon} alt="contractors" />
                                    </i>
                                    <span>
                                        {trans('nav.contractors')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.permission('partners') &&
                            <li className={segment(0) === 'relationships' ? 'active' : ''}>
                                <Link to="/relationships">
                                    <i>
                                        <img src={kontraktoriIcon} alt="relationships" />
                                    </i>
                                    <span>
                                        {trans('nav.relationships')}
                                    </span>
                                </Link>
                            </li>
                        }

                        {auth.permission('invoices') &&
                            <li className={segment(0) === 'invoices' ? 'active' : ''}>
                                <Link to="/invoices">
                                    <i>
                                        <img src={invoiceIcon} alt="invoices" />
                                    </i>
                                    <span>
                                        {trans('nav.invoices')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.permission('accounts') &&
                            <li className={segment(0) === 'accounts' ? 'active' : ''}>
                                <Link to="/accounts/incomes">
                                    <i>
                                        <img src={spravkiIcon} alt="accounts" />
                                    </i>
                                    <span>
                                        {trans('nav.accounts')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.permission('expenses') &&
                            <li className={segment(0) === 'expenses' ? 'active' : ''}>
                                <Link to="/expenses">
                                    <i>
                                        <img src={razhodiIcon} alt="expenses" />
                                    </i>
                                    <span>
                                        {trans('nav.expenses')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.permission('banktransactions') &&
                            <li className={segment(0) === 'banktransactions' ? 'active' : ''}>
                                <Link to="/banktransactions">
                                    <i>
                                        <img src={bankoviIcon} alt="banktransactions" />
                                    </i>
                                    <span>
                                        {trans('nav.banktransactions')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.permission('todos') &&
                            <li className={segment(0) === 'todo' ? 'active' : ''}>
                                <Link to="/todo">
                                    <i>
                                        <img src={belejkiIcon} alt="todo" />
                                    </i>
                                    <span>
                                        {trans('nav.todos')}
                                    </span>
                                </Link>
                            </li>
                        }

                        {auth.permission('sms') &&
                            <li className={segment(0) === 'sms' ? 'active' : ''}>
                                <Link to="/sms/main">
                                    <i>
                                        <img src={smsIcon} alt="sms" />
                                    </i>
                                    <span>
                                        {trans('nav.messages')}
                                    </span>
                                </Link>
                            </li>
                        }

                        {auth.permission('voicecalls') &&
                            <li className={segment(0) === 'voicecalls' ? 'active' : ''}>
                                <Link to="/voicecalls">
                                    <i>
                                        <img src={callIcon} alt="sms" />
                                    </i>
                                    <span>
                                        {trans('nav.voicecalls')}
                                    </span>
                                </Link>
                            </li>
                        }

                        {/* {(auth.isManager() || auth.isOperator()) &&
                            <li className={segment(0) === 'appliances' ? 'active' : ''}>
                                <Link to="/appliances">
                                    <i>
                                        <img src={urediIcon} alt="appliances" />
                                    </i>
                                    <span>
                                        {trans('nav.appliances')}
                                    </span>
                                </Link>
                            </li>
                        } */}

                        {auth.permission('manuals') &&
                            <li className={segment(0) === 'manuals' ? 'active' : ''}>
                                <Link to="/manuals">
                                    <i>
                                        <img src={rukovodstvaIcon} alt="manuals" />
                                    </i>
                                    <span>
                                        {trans('nav.manuals')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.permission('bonuspolicy') &&
                            <li className={segment(0) === 'bonuspolicies' ? 'active' : ''}>
                                <Link to="/bonuspolicies">
                                    <i>
                                        <img src={bonusPolitikaIcon} alt="bonuspolicies" />
                                    </i>
                                    <span>
                                        {trans('nav.bonuspolicies')}
                                    </span>
                                </Link>
                            </li>
                        }
                        {auth.isManager() &&
                            <li className={segment(0) === 'company' ? 'active' : ''}>
                                <Link to="/company">
                                    <i>
                                        <img src={infoIcon} alt="companny" />
                                    </i>
                                    <span>
                                        {trans('nav.company')}
                                    </span>
                                </Link>
                            </li>
                        }

                        {/* <li className={segment(0) === 'customers' ? 'active' : ''}>
                            <Link to="/customers">
                                <i>
                                    <img src={klientiIcon} alt="customers" />
                                </i>
                                <span>
                                    Клиенти
                                </span>
                            </Link>
                        </li> */}

                        {auth.permission('website') &&
                            <li className={segment(0) === 'website' ? 'active' : ''}>
                                <Link to="/website">
                                    <i>
                                        <img src={websiteIcon} alt="website" />
                                    </i>
                                    <span>
                                        {trans('nav.website')}
                                    </span>
                                </Link>
                            </li>
                        }

                    </>
                }

            </ul>
        </aside>
    )
}

export default Aside