import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../../../_helpers/Api';

//images
import docIcon from '../../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../../assets/img/icons/close.png';
import loader from '../../../../../../assets/img/loader.svg';
import deleteIcon from '../../../../../../assets/img/icons/delete-red.png';
import { useLanguageContext } from '../../../../../../_providers/Language';
import LanguagesTabs from './LanguagesTabs';
import { trans } from '../../../../../../_providers/Translation';

function StorageStatus(props, ref) {

    const languages = useLanguageContext();

    // const defaultData = {
    //     langs: {}
    // };

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        id: null,
        // data: defaultData,
        data: {},
        groups: [],
        tab: 1,
        loading: false,
        update: false,
        onSave: null
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        edit: id => {
            setPopup(prev => ({
                ...prev,
                id: id,
                tab: 1,
                update: new Date().getTime()
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    // useEffect(() => {
    //     Api.get('storage/groups')
    //         .then(res => {
    //             setPopup(prev => ({
    //                 ...prev,
    //                 groups: res.data
    //             }));
    //         });
    // }, []);

    useEffect(() => {
        if (popup.id) {
            Api.get('storage/statuses/find?id=' + popup.id)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data
                    }))
                });
        }
    }, [popup.update]);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
            data: null
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false,
                id: null,
                // data: defaultData
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'storage/statuses/' + (popup.id ? 'edit' : 'add');

        let data = new FormData(e.target);

        if (popup.id) {
            data.append('id', popup.id);
        }

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave(res.data);
                }

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }


    const handleInputChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleCheckboxChange = e => {
        let name = e.target.name;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: !prev.data[name]
            }
        }));
    }

    // const handleLangChange = e => {
    //     let name = e.target.name.split('[')[1].replace(']', '');
    //     let val = e.target.value;

    //     setPopup(prev => ({
    //         ...prev,
    //         data: {
    //             ...prev.data,
    //             langs: {
    //                 ...prev.data.langs,
    //                 [name]: {
    //                     ...prev.data.langs[name],
    //                     name: val
    //                 }
    //             }
    //         }
    //     }));
    // }

    // const tabsValue = newValue => {
    //     setPopup(popup => ({
    //         ...popup,
    //         tab: newValue,
    //     }))
    // }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary large`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {popup.id ? trans('headings.editPartStatus') : trans('headings.addPartStatus')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                {/* <LanguagesTabs
                    tab={popup.tab}
                    callbackValue={tabsValue}
                /> */}

                <div className="popup-container x2">
                    <div className="col">
                        {languages.map(lang =>
                            <div key={'l' + lang.id} className="row field-primary" style={{ display: popup.tab === lang.id ? 'block' : 'none' }}>
                                <label>
                                    {trans('labels.name')}
                                </label>
                                <input
                                    name='name'
                                    type="text"
                                    value={popup.data ? popup.data.name : ''}
                                    className={validations && validations.name ? 'validate' : ''}
                                    onChange={handleInputChange}
                                // name={`langs[${lang.id}]`}
                                // value={popup.data && popup.data.langs && popup.data.langs[lang.id] && popup.data.langs[lang.id].name ?
                                //     popup.data.langs[lang.id].name
                                //     :
                                //     ''
                                // }
                                // className={validations && validations[`langs.${lang.id}`] ? 'validate' : ''}
                                // onChange={handleLangChange}
                                />
                            </div>
                        )}
                    </div>

                    <div className="col">
                        <label>
                            {trans('labels.color')}
                        </label>
                        <input
                            type="color"
                            name="color"
                            className={validations && validations.checkbox ? 'validate' : ''}
                            value={popup.data && popup.data.color ? popup.data.color : ''}
                            onChange={handleInputChange}
                        />

                    </div>

                </div>

                <br />

                {/* <div className="popup-container x2">
                    <div className="col">
                        <label>
                            Група, в която да се показва
                        </label>
                        <select
                            name="parent_id"
                            className={validations && validations.checkbox ? 'validate' : ''}
                            value={popup.data && popup.data.parent_id ? popup.data.parent_id : ''}
                            onChange={handleInputChange}
                        >
                            <option value="">
                                За всички групи
                            </option>
                            {popup.groups.map(g =>
                                <option key={'g-' + g.id} value={g.id}>
                                    {g.translation ? g.translation.name : g.name}
                                </option>
                            )}
                        </select>
                    </div>

                    <div className="col">
                        <label>
                            Тип
                        </label>
                        <select
                            name="checkbox"
                            className={validations && validations.checkbox ? 'validate' : ''}
                            value={popup.data ? popup.data.checkbox : ''}
                            onChange={handleInputChange}
                        >
                            <option value="0">
                                Радио бутон
                            </option>
                            <option value="1">
                                Чекбокс
                            </option>
                        </select>
                    </div>
                </div>

                <br /> */}

                <div className="popup-container">
                    <div className="col">
                        <label className="flex">
                            <input
                                type="checkbox"
                                name="default"
                                value="1"
                                checked={popup.data && popup.data.default}
                                onChange={handleCheckboxChange}
                            />
                            <span>
                                {trans('random.byDefaultWhenAddingPart')}
                            </span>
                        </label>
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label className="flex">
                            <input
                                type="checkbox"
                                name="show_in_dashboard"
                                value="1"
                                checked={popup.data && popup.data.show_in_dashboard}
                                onChange={handleCheckboxChange}
                            />
                            <span>
                                {trans('random.visibleInContractorsDashboard')}
                            </span>
                        </label>
                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(StorageStatus);