import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import MultiSelect from "react-multi-select-component";

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import deleteIcon from '../../../../assets/img/icons/delete-red.png';
import loader from '../../../../assets/img/loader.svg';
import Dropdown from '../../../partials/Dropdown';
import { trans } from '../../../../_providers/Translation'

function AddOrEditManual(props, ref) {

    const inputFileRef = useRef(null);

    const initialData = {
        files: []
    }

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: initialData,
        loading: false,
        key: 1,
        tab: 1,
    });

    const [models, setModels] = useState({
        data: [],
        options: [],
        selected: []
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: initialData
            }));
            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                loading: false,
                data: initialData
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            Api.get(`appliances/manuals/find?id=${popup.editId}`)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data,
                        tab: 1
                    }));

                    // handleBrand(res.data.brand.id);

                    // let selected = [];

                    // res.data.models.map(m => {
                    //     selected.push({
                    //         label: m.name,
                    //         value: m.id
                    //     });
                    // });

                    // setModels(prev => ({ ...prev, selected: selected }));

                });
        } else {
            // setModels(prev => ({ ...prev, data: [], options: [], selected: [] }));
        }

    }, [popup.edit]);


    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true,
            key: Math.random().toString()
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {

        if (e) {
            e.preventDefault();
        }

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();


        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'appliances/manuals/edit';
        } else {
            url = 'appliances/manuals/add';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        popup.data.files.map(file => {
            if (file.blob) {
                data.append('files[]', file.blob);
            }
        });

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    props.handleUpdate(res.data)
                } else {
                    props.handleCreate(res.data);
                }

                handleHide();

                document.getElementById("manuals-form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    // const handleBrand = id => {
    //     if (id > 0) {

    //         Api.get(`appliances/models/all?brand_id=${id}`)
    //             .then(res => {

    //                 let options = [];

    //                 res.data.map(m => {
    //                     options.push({
    //                         label: m.name,
    //                         value: m.id
    //                     });
    //                 });

    //                 setModels(prev => ({ ...prev, data: res.data, options: options }));
    //             }).catch(err => {
    //                 setModels(prev => ({ ...prev, data: [], options: [] }));
    //             });
    //     } else {
    //         setModels(prev => ({ ...prev, data: [], options: [] }));
    //     }

    // }

    // const handleModel = model => {
    //     setModels(prev => ({ ...prev, selected: model }));
    // }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }

        }))
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let attached = e.target.files;

        let files = [];

        Array.from(attached).forEach(file => {
            if (!file) {
                return;
            }

            let url = URL.createObjectURL(file);

            files.push({
                blob: file,
                filename: file.name,
                file_url: url
            });

        });

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                files: prev.data.files.concat(files)
            }
        }));

        inputFileRef.current.value = '';
    }

    const handleDetach = index => {

        let file = popup.data.files[index];

        if (file) {
            if (popup.edit) {
                Api.post('appliances/manuals/delete-file', {
                    id: file.id
                }).then(res => {
                    if (res.data.success) {
                        removeFile(index);
                    }
                });
            } else {
                removeFile(index);
            }
        }

    }

    const removeFile = index => {

        let files = [...popup.data.files];

        files = files.filter((file, i) => i !== index);

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                files: files
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="manuals-form" className={`${popup.modal ? 'show' : ''} popup-primary small`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {popup.edit ? trans('headings.editManual') : trans('headings.addManual')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.category')}
                        </label>
                        <Dropdown
                            key={popup.key}
                            inputName="category"
                            inputIdName="category_id"
                            inputValue={popup.data && popup.data.category ? popup.data.category.name : ''}
                            inputIdValue={popup.data && popup.data.category_id ? Number(popup.data.category_id) : ''}
                            inputClassName={validations && validations.category_id ? 'validate' : ''}
                            url="appliances/categories/all"
                            renderText={data => {
                                return data.name
                            }}
                        // onChange={data => handleBrand(data.id)}
                        />
                    </div>

                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.make')}
                        </label>
                        <Dropdown
                            key={popup.key}
                            inputName="brand"
                            inputIdName="brand_id"
                            inputValue={popup.data && popup.data.brand ? popup.data.brand.name : ''}
                            inputIdValue={popup.data && popup.data.brand_id ? Number(popup.data.brand_id) : ''}
                            inputClassName={validations && validations.brand_id ? 'validate' : ''}
                            url="appliances/brands/all"
                            renderText={data => {
                                return data.name
                            }}
                        // onChange={data => handleBrand(data.id)}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.model')}
                        </label>
                        <input
                            type="text"
                            name="models"
                            className={validations && validations.models ? 'validate' : ''}
                            value={popup.data && popup.data.models ? popup.data.models : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        {Array.isArray(popup.data.files)
                            &&
                            popup.data.files.map((file, i) =>
                                <div key={'f-' + i} className="row" style={{ marginBottom: '10px', alignItems: 'center' }}>
                                    <div className="col" style={{ width: 'calc(100% - 50px)' }}>
                                        <label style={{ display: 'block', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                            <a href={file.file_url} target="_blank">
                                                {file.filename || 'n/a'}
                                            </a>
                                        </label>
                                    </div>
                                    <div className="col auto" style={{ width: '30px' }}>
                                        <img onClick={e => handleDetach(i)} src={deleteIcon} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                            )
                        }

                        <button className={['button-small', validations && validations.files ? 'validate' : ''].join(' ')} type="button" onClick={handleShowAttach}>
                            {trans('buttons.uploadfile')}
                        </button>

                        <input ref={inputFileRef} type="file" onChange={handleAttach} multiple hidden />

                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEditManual);