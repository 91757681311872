import React from 'react';
import { trans } from '../../../../_providers/Translation';
import moment from 'moment';

function Table(props) {
    return (
        <table>
            <thead>
                <tr>
                    <th>
                        {trans('tables.subscription')}
                    </th>
                    <th>
                        {trans('tables.activity')}
                    </th>
                    <th style={{ textAlign: 'center' }}>
                        {trans('tables.activityDate')}
                    </th>
                    <th style={{ textAlign: 'center' }}>
                        {trans('tables.expirationDate')}
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.data.map(r =>
                    <tr key={r.id}>
                        <td>
                            {r?.subscription?.ref} ({r?.subscription?.appliancecategory?.name})
                        </td>
                        <td>
                            {r?.action?.translation?.name}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            {moment(r.created_at).utc().format('DD.MM.YYYY')}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            {r.expires_at && moment(r.expires_at).utc().format('DD.MM.YYYY')}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}

export default Table;