import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import AuthDataProvider from './_providers/Auth';
import LanguageProvider from './_providers/Language'
import CurrencyProvider from './_providers/Currency'
import TranslationProvider from './_providers/Translation'

const history = require("history").createLocation;

ReactDOM.render(

    <Router history={history}>
        <AuthDataProvider>
            <LanguageProvider>
                <TranslationProvider>
                    <CurrencyProvider>
                        <App />
                    </CurrencyProvider>
                </TranslationProvider>
            </LanguageProvider>
        </AuthDataProvider>
    </Router>,
    document.getElementById('root')

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
