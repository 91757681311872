import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';

function EditPayee(props, ref) {

    const inputRef = useRef(null);

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: '',
        loading: false,
        onSave: null,
        onClose: null
    });

    useImperativeHandle(ref, () => ({
        show: text => {
            setPopup(prev => ({
                ...prev,
                loading: false,
                data: text
            }));

            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        },
        onClose: fn => {
            setPopup(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        if (e) {
            e.preventDefault();
        }

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);

        popup.onClose();
    }

    const handleSave = () => {
        let text = inputRef.current.value;

        popup.onSave(text);

        handleHide();
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: val
        }));
    }

    return (
        <>

            <div className={`${popup.overlay ? 'visible' : ''} overlay`}>

                <div className={[popup.modal ? 'show' : '', 'popup-primary', 'small'].join(' ')}>
                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />
                        {trans('headings.autoFind')}
                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>

                    <div className="popup-container">
                        <div className="col">
                            <input
                                ref={inputRef}
                                type="text"
                                value={popup.data}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="footer align-center">
                        {popup.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="button" onClick={handleHide}>
                                    {trans('buttons.cancel')}
                                </button>

                                <button className="button button-primary" type="submit" onClick={handleSave}>
                                    {trans('buttons.save')}
                                </button>

                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(EditPayee);