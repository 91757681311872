import React, { useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useAuthDataContext } from '../../../../_providers/Auth';
import { useHistory, useLocation } from 'react-router-dom';
import { trans } from '../../../../_providers/Translation';

function FilterTabs(props) {

    const auth = useAuthDataContext();
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const showClientTab = !auth.isAdmin();

    const [tabs, setTabs] = useState({
        tab: 'all',
        ripple: true
    });

    useEffect(() => {
        setTabs(prev => ({
            ...prev,
            tab: props.tab
        }))
    }, [props.tab]);

    const handleTabs = (e, newValue) => {

        if (newValue === 'unpaid-reservations') {
            return history.push('invoices/unpaid-reservations');
        }

        // setTabs(prev => ({
        //     ...prev,
        //     tab: newValue
        // }));

        // setTimeout(() => {
        //     if (newValue === 'unpaid-reservations') {
        //         return history.push('invoices/unpaid-reservations');
        //     }

        //     searchParams.set('status', newValue);

        //     return history.push('/invoices?' + searchParams.toString());
        // }, 200);


        props.handleTab(newValue);
    };

    const handleClientTab = (e, newValue) => {
        props.handleClientTab(newValue);
    };

    return (
        <>
            <Tabs
                value={tabs.tab}
                onChange={handleTabs}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs"
            >
                <Tab value="all" label={trans('tabs.all')} disableRipple={tabs.ripple} />
                <Tab value="paid" label={trans('tabs.paid')} disableRipple={tabs.ripple} />
                <Tab value="unpaid" label={trans('tabs.unpaid')} disableRipple={tabs.ripple} />
                {!auth.isAdmin() &&
                    <Tab value="unpaid-reservations" label={trans('tabs.unpaidReservation')} disableRipple={tabs.ripple} />
                }
            </Tabs>

            {showClientTab && props.tab === 'unpaid' &&
                <div className="small-tabs">
                    <Tabs
                        value={props.client}
                        onChange={handleClientTab}
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs"
                    >
                        <Tab value="company" label={trans('tabs.toCompany')} disableRipple={tabs.ripple} />
                        <Tab value="private" label={trans('tabs.toPerson')} disableRipple={tabs.ripple} />
                    </Tabs>
                </div>
            }
        </>
    )
}

export default FilterTabs;