import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useAuthDataContext } from '../../../../_providers/Auth';
import { trans } from '../../../../_providers/Translation';

function FilterTabs(props) {
    const auth = useAuthDataContext();

    const [tabs, setTabs] = useState({
        ripple: true
    })

    const handleTabs = (e, newValue) => {
        props.handleTab(newValue);
    };

    return (
        <Tabs
            value={props.tab}
            onChange={handleTabs}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs"
        >
            {props.showSettingsTab &&
                <Tab value="neccesary-settings" label={trans('tabs.home')} disableRipple={tabs.ripple} />
            }
            <Tab value="info" label={trans('tabs.mainInfo')} disableRipple={tabs.ripple} />
            <Tab value="employees" label={trans('tabs.employees')} disableRipple={tabs.ripple} />
            {auth.isManager() &&
                <Tab value="groups" label={trans('tabs.groups')} disableRipple={tabs.ripple} />
            }
            {auth.isManager() &&
                <Tab value="settings" label={trans('tabs.settings')} disableRipple={tabs.ripple} />
            }
        </Tabs>
    )
}

export default FilterTabs;