import React, { useState, useImperativeHandle, useEffect, useRef } from 'react';
import { trans, plural } from '../../../../../_providers/Translation'

//images
import deleteIcon from '../../../../../assets/img/icons/delete-red.png';
import docIcon from '../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../assets/img/icons/close.png';
import Api from '../../../../../_helpers/Api';
import loader from '../../../../../assets/img/loader.svg';
import { useAuthDataContext } from '../../../../../_providers/Auth';

function AddOrEdit(props, ref) {

    const auth = useAuthDataContext();
    const inputFileRef = useRef(null);

    const defaultData = {
        tags: [
            {
                employee: {}
            }
        ],
        repeat: false,
        repeat_after: 1
    };

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        alarm: [],
        data: defaultData,
        loading: false,
        employees: [],
    });

    const [priorities, setPriorities] = useState([]);
    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: defaultData
            }));

            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                data: defaultData,
                loading: false
            }));

            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    useEffect(() => {
        loadPriorities();

        if (auth.isAdmin()) {
            loadAdmins();
        } else {
            loadEmployees();
        }
    }, []);

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            Api.get(`todos/find?id=${popup.editId}`)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data,
                        alarm: res.data.alarms,
                    }));
                });
        }

    }, [popup.edit]);

    const loadEmployees = () => {
        let url = 'partners/employees/all?active=1&sort=name';

        Api.get(url)
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    employees: res.data,
                    loading: false,
                    initLoad: false
                }));
            });
    }

    const loadAdmins = () => {
        let url = 'admins/all';

        Api.get(url)
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    employees: res.data,
                    loading: false,
                    initLoad: false
                }));
            });
    }


    const loadPriorities = () => {
        Api.get('todos/priorities')
            .then(res => {
                setPriorities(res.data);
            });
    }


    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        if (e) {
            e.preventDefault();
        }

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
            alarm: []
        }));

        setValidations(null)

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }


    const handleSave = e => {

        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'todos/edit';
        } else {
            url = 'todos/add';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        if (popup.data.file) {
            data.append('file', popup.data.file);
        }

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    props.handleUpdate(res.data)
                } else {
                    props.handleCreate(res.data);
                }

                handleHide();

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleCheckboxChange = e => {
        let name = e.target.name;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: !prev.data[name]
            }
        }));
    }

    const handleEmployeeChange = e => {
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                tags: [
                    {
                        employee: {
                            ...prev.data.tags[0].employee,
                            id: val
                        }
                    }
                ]
            }
        }));

    }

    const handleDateChange = e => {
        let name = e.target.name + '_ymd';
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleAdd = () => {

        let alarm = {
            id: Math.random().toString().substring(3),
        };

        setPopup(prev => ({
            ...prev,
            alarm: prev.alarm.concat(alarm)
        }));
    }

    const handleRemove = id => {
        let filtered = popup.alarm.filter(p => {
            return Number(p.id) !== Number(id);
        });

        setPopup(prev => ({
            ...prev,
            alarm: filtered
        }));
    }

    const handleAlarmChange = (e, i, field) => {
        const val = e.target.value

        let alarms = popup.alarm

        alarms[i][field] = val

        setPopup(prev => ({
            ...prev,
            alarm: alarms
        }))
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let file = e.target.files[0];

        if (!file) {
            return;
        }

        let url = URL.createObjectURL(file);

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                file: file,
                filename: file.name,
                file_url: url
            }
        }));

        inputFileRef.current.value = '';
    }

    const handleDetach = () => {

        if (popup.edit) {
            Api.post('todos/delete-file', {
                id: popup.editId
            }).then(res => {
                if (res.data.success) {
                    removeFile();
                }
            });
        } else {
            removeFile();
        }

    }

    const removeFile = () => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                file: null,
                filename: null,
            }
        }));
    }

    const getRepeatNumbers = () => {
        let n = [];

        for (let i = 1; i <= 31; i++) {
            n.push(i);
        }

        return n;
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form className={`${popup.modal ? 'show' : ''} popup-primary large`} autoComplete="off" onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {popup.edit ? trans('headings.editTodo') : trans('headings.addTodo')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container x2">

                    <div className="col">
                        <label htmlFor="heading">
                            {trans('labels.heading')}
                        </label>
                        <input
                            name="title"
                            type="text"
                            id="heading"
                            className={validations && validations.title ? 'validate' : ''}
                            value={popup.data && popup.data.title ? popup.data.title : ''}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="priority">
                            {trans('labels.priority')}
                        </label>
                        <select
                            name="priority_id"
                            value={popup.data && popup.data.priority_id ? popup.data.priority_id : ''}
                            id="priority"
                            className={validations && validations.priority_id ? 'validate' : ''}
                            onChange={handleInputChange}
                        >
                            {priorities.map(p =>
                                <option key={'p-' + p.id} value={p.id}>
                                    {p.translation.name}
                                </option>
                            )}
                        </select>
                    </div>

                </div>

                <br />

                <div className="popup-container x2">

                    <div className="col">
                        <label htmlFor="date">
                            {trans('labels.setTo')}
                        </label>
                        <input
                            name="date"
                            type="datetime-local"
                            id="date"
                            className={validations && validations.date ? 'validate' : ''}
                            value={popup.data && popup.data.date_ymd ? popup.data.date_ymd : ''}
                            onChange={handleDateChange}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="deadline">
                            {trans('labels.deadline')}
                        </label>
                        <input
                            name="deadline"
                            type="datetime-local"
                            id="deadline"
                            className={validations && validations.deadline ? 'validate' : ''}
                            value={popup.data && popup.data.deadline_ymd ? popup.data.deadline_ymd : ''}
                            onChange={handleDateChange}
                        />
                    </div>

                </div>

                {popup.data && !popup.data.parent_id
                    ?
                    <>
                        <br />

                        <div className="popup-container">
                            <div className="col">
                                <label className="flex">
                                    <input
                                        type="checkbox"
                                        name="repeat"
                                        value="1"
                                        checked={popup.data && popup.data.repeat ? true : false}
                                        onChange={handleCheckboxChange}
                                    />
                                    <span>
                                        {trans('labels.repeat')}
                                    </span>
                                </label>

                            </div>
                        </div>
                    </>
                    :
                    ''
                }

                {popup.data && popup.data.repeat
                    ?
                    <>
                        <br />
                        <div className="popup-container x2">
                            <div className="col">
                                <label>
                                    {trans('labels.repeatOn')}
                                </label>
                                <select
                                    name="repeat_after"
                                    className={validations && validations.repeat_after ? 'validate' : ''}
                                    value={popup.data && popup.data.repeat_after ? popup.data.repeat_after : ''}
                                    onChange={handleInputChange}
                                >
                                    {getRepeatNumbers().map((n, i) => (
                                        <option key={'rn-' + i} value={n}>
                                            {n}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col">
                                <label>
                                    {trans('labels.period')}
                                </label>
                                <select
                                    name="repeat_period"
                                    className={validations && validations.repeat_period ? 'validate' : ''}
                                    value={popup.data && popup.data.repeat_period ? popup.data.repeat_period : ''}
                                    onChange={handleInputChange}
                                >
                                    <option value={1}>
                                        {plural('plural_periods.day', popup.data.repeat_after || 1)}
                                    </option>
                                    <option value={2}>
                                        {plural('plural_periods.week', popup.data.repeat_after || 1)}
                                    </option>
                                    <option value={3}>
                                        {plural('plural_periods.month', popup.data.repeat_after || 1)}
                                    </option>
                                    <option value={4}>
                                        {plural('plural_periods.year', popup.data.repeat_after || 1)}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </>
                    :
                    ''
                }

                {auth.isSuperAdmin() || !auth.isAdmin()
                    ?
                    <>
                        <br />

                        <div className='popup-container'>
                            <div className='col'>
                                <label>
                                    {trans('labels.contractor')}
                                </label>

                                <select
                                    name='employees[]'
                                    value={popup.data && popup.data.tags && popup.data.tags[0].employee.id
                                        ?
                                        popup.data.tags[0].employee.id
                                        :
                                        ''}
                                    onChange={handleEmployeeChange}
                                >
                                    {popup.employees.map(p =>
                                        <option key={'emp-' + p.id} value={p.id}>
                                            {p.name} {p.lastname}
                                        </option>
                                    )}
                                </select>

                            </div>
                        </div>
                    </>
                    :
                    ''
                }

                <br />

                <div style={{ overflow: 'auto', maxHeight: '210px' }}>
                    {popup.alarm.map((p, idx) => (
                        <React.Fragment key={'alarm-' + p.id}>
                            <div className='popup-container x2'>
                                <div className="col">
                                    <label>
                                        {trans('labels.notify')}
                                    </label>
                                    <div className='row'>
                                        <input type="hidden" name="period_id[]" value={p.id} />
                                        <input
                                            type="datetime-local"
                                            name="alarm[]"
                                            className={validations && validations[`alarm.${idx}`] ? 'validate' : ''}
                                            value={p.date_ymd}
                                            onChange={e => handleAlarmChange(e, idx, 'date_ymd')}
                                        />
                                        <div style={{ paddingLeft: '5px', paddingTop: '5px' }}>
                                            <a href="javascript:void(0)" onClick={() => handleRemove(p.id)}>
                                                <img src={deleteIcon} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <label>
                                        {trans('labels.notifyVia')}
                                    </label>
                                    <select
                                        value={p.type}
                                        name='type[]'
                                        onChange={e => handleAlarmChange(e, idx, 'type')}
                                    >
                                        <option value='1'>{trans('random.systemNotification')}</option>
                                        <option value='2'>{trans('labels.email')}</option>
                                        <option value='3'>Viber/WhatsApp/SMS</option>
                                    </select>
                                </div>
                            </div>
                            <br />
                        </React.Fragment>
                    ))}
                </div>

                <button className='button-small' type="button" onClick={handleAdd}>
                    {trans('buttons.addNotification')}
                </button>

                <br />
                <div className="popup-container">
                    <div className="col">
                        <label htmlFor="text">
                            {trans('labels.content')}
                        </label>
                        <textarea
                            id="text"
                            name="text"
                            className={validations && validations.text ? 'validate' : ''}
                            value={popup.data && popup.data.text ? popup.data.text : ''}
                            onChange={handleInputChange}
                        >

                        </textarea>
                    </div>
                </div>

                <br />

                {popup.data.file
                    &&
                    <div className="row" style={{ marginBottom: '10px' }}>
                        <div className="col">
                            <label>
                                <a href={popup.data.file_url} target="_blank">
                                    {popup.data.filename || 'n/a'}
                                </a>
                            </label>
                        </div>
                        <div className="col auto">
                            <img onClick={handleDetach} src={deleteIcon} style={{ cursor: 'pointer' }} />
                        </div>
                    </div>
                }

                <button className='button-small' type="button" onClick={handleShowAttach}>
                    {trans('buttons.uploadfile')}
                </button>

                <input ref={inputFileRef} type="file" onChange={handleAttach} hidden />

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default React.forwardRef(AddOrEdit);