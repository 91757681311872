import React, { useEffect, useState, useRef, useMemo } from 'react';
import { trans } from '../../../_providers/Translation';

import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import Api from '../../../_helpers/Api';
import Chart from "react-google-charts";
import loader from '../../../assets/img/loader.svg';
import Filter from './partials/FilterVat';
import { useAuthDataContext } from '../../../_providers/Auth';
import style from '../../../assets/css/accounts.css'
import * as Constants from '../../../_config/app';

let timeout;

function Vat() {

    const auth = useAuthDataContext();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [state, setState] = useState({
        loading: true,
        data: {},
        filter: {},
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                start: searchParams.get('start') || '',
                end: searchParams.get('end') || '',
                group: searchParams.get('group') || '',
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);


    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'accounts/vat-report';

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {

                let label = trans('random.month');

                let chart = [
                    [label, trans('tables.tax')],
                ];

                res.data.dates.map(d => {
                    let arr = [
                        d.label, d.vat
                    ];

                    chart.push(arr);
                })

                setState(prev => ({
                    ...prev,
                    data: res.data,
                    chart: chart,
                    filter: {
                        ...prev.filter,
                        group: res.data.group,
                        start: res.data.start,
                        end: res.data.end
                    },
                    loading: false
                }));
            });
    }

    const handleSearch = (key, val, delay = 300) => {

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handleExcel = () => {
        let url = `${Constants.App.api}accounts/export-vatreport?token=${auth.token()}`;

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        window.open(url, '_blank', 'noopener,noreferrer')

    }

    const getColumns = () => {
        let arr = [];

        arr.push({
            type: 'NumberFormat',
            column: 1,
            options: {
                // prefix: '$',
                negativeParens: false,
            }
        })

        return arr;
    }


    return (
        <>
            <Filter
                filter={state.filter}
                handleSearch={handleSearch}
            />

            <br />

            {state.loading
                ?
                <img className="loader" src={loader} />
                :
                <>
                    <div
                        className="chart"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#fff',
                            padding: '5px'
                        }}>
                        <Chart
                            width={window.innerWidth <= 1024 ? '900px' : '99%'}
                            height={'350px'}
                            chartType="Bar"
                            // className="animated-chart-vertical animated-chart-start"
                            loader={'...'}
                            data={state.chart}
                            options={{
                                // Material design options
                                chart: {
                                    title: trans('tables.tax'),
                                    subtitle: state.data.currency,
                                },
                                legend: {
                                    position: 'none'
                                },
                                allowHtml: true,
                                showRowNumber: true,
                            }}
                            // custom attributes
                            rootProps={{ 'data-testid': '2' }}
                            chartEvents={[
                                {
                                    eventName: "ready",
                                    callback: ({ chartWrapper, google }) => {
                                        const chartEl = chartWrapper.getChart().container;
                                        setTimeout(() => {
                                            chartEl.classList.remove('animated-chart-start')
                                        }, 100)
                                    },
                                }
                            ]}
                            formatters={getColumns()}
                        />
                    </div>
                    <br />
                    <button onClick={handleExcel}>{trans('buttons.download')}</button>
                    <br />
                    <div className="table-scroller">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {trans('tables.date')}
                                    </th>
                                    <th className="right">
                                        {trans('tables.income')}
                                    </th>
                                    <th className="right">
                                        {trans('tables.expense')}
                                    </th>
                                    <th className="right">
                                        {trans('tables.difference')}
                                    </th>
                                    <th className="right">
                                        {trans('tables.tax')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.data.dates.map((d, i) =>
                                    <tr key={'d-' + i}>
                                        <td>
                                            {d.label}
                                        </td>
                                        <td className="right">
                                            {d.incomes_formatted}
                                        </td>
                                        <td className="right">
                                            {d.expenses_formatted}
                                        </td>
                                        <td className="right">
                                            {d.diff_formatted}
                                        </td>
                                        <td className="right">
                                            {d.vat_formatted}
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td>
                                        {trans('tables.total')}
                                    </td>
                                    <td className="right">
                                        {state.data.total_incomes_formatted}
                                    </td>
                                    <td className="right">
                                        {state.data.total_expenses_formatted}
                                    </td>
                                    <td className="right">
                                        {state.data.total_diff_formatted}
                                    </td>
                                    <td className="right">
                                        {state.data.total_vat_formatted}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }

        </>
    )
}

export default Vat;