import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../../../partials/NoDataFound';
import Pagination from '../../../Pagination';
import { plural, trans } from '../../../../_providers/Translation';

function BonusPolicyPeriods(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        id: null,
        data: [],
        page: 1,
        pages: 0,
        total: 0,
        loading: true,
    });

    useImperativeHandle(ref, () => ({
        open: id => {
            handleShow(id);
        },
        hide: () => {
            handleHide();
        }
    }));

    useEffect(() => {
        loadData();
    }, [popup.id, popup.page]);

    const loadData = () => {

        if (!popup.id) {
            return;
        }

        setPopup(prev => ({
            ...prev,
            loading: true
        }));

        Api.get('partners/bonuspolicies/periods?id=' + popup.id + '&page=' + popup.page)
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }))
            })
            .finally(() => {
                setPopup(prev => ({
                    ...prev,
                    loading: false
                }));
            })
    }

    const handleShow = id => {

        setPopup(prev => ({
            ...prev,
            overlay: true,
            id: id
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        if (e) {
            e.preventDefault();
        }

        setPopup(prev => ({
            ...prev,
            modal: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false,
            }));
        }, 50);
    }

    const handlePage = page => {
        setPopup(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    const handleShowResults = (id, periodId) => {
        handleHide();

        props.handleShowResult(id, periodId);
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <div className={`${popup.modal ? 'show' : ''} popup-primary medium`}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.bonusPolicyChoosePeriod')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                {popup.loading
                    ?
                    <Skeleton height={45} count={5} />
                    :
                    popup.data.length === 0
                        ?
                        <NoDataFound />
                        :
                        <>
                            <div className="popup-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="center" style={{ width: '40px' }}>
                                                №
                                            </th>
                                            <th className="right">
                                                {trans('labels.fromDate')}
                                            </th>
                                            <th className="right">
                                                {trans('labels.toDate')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {popup.data.map((r, i) =>
                                            <tr
                                                key={r.id}
                                                onClick={() => handleShowResults(r.policy_id, r.id)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <td className="center" style={{ width: '40px' }}>
                                                    {i + 1}
                                                </td>
                                                <td className="right">
                                                    {r.from_dmy}
                                                </td>
                                                <td className="right">
                                                    {r.to_dmy}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                            <Pagination
                                pages={popup.pages}
                                page={popup.page}
                                handlePage={handlePage}
                            />
                        </>
                }

                <div className="footer align-center">
                    <button className="button" onClick={handleHide}>
                        {trans('buttons.close')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(BonusPolicyPeriods);