import React, { useRef, useState, useEffect } from 'react';
import { useAuthDataContext } from '../../../../../_providers/Auth';
import Api from '../../../../../_helpers/Api';
import editIcon from '../../../../../assets/img/icons/edit-yellow.png';
import Modal from './modals/Categories';
import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../../../_providers/Translation';
import { useLocation } from 'react-router-dom';
import Pagination from '../../../../Pagination';
import NoDataFound from '../../../../partials/NoDataFound';
import Options from './CategoriesOptions';
import Question from '../../../../modals/messages/Question';

function Categories(props) {

    const auth = useAuthDataContext();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search)

    const modalRef = useRef(null);
    const deleteModalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: searchParams.get('page') || 1,
        update: false,
    });

    useEffect(() => {
        loadSettings();
    }, [state.update]);

    const loadSettings = () => {
        Api.get(`appliances/categories/all?partner_id=${auth.partnerId()}&page=${state.page}`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                }));

                if (res.data.pages > 1 && res.data.pages < state.page) {
                    handlePage(res.data.pages);
                }

            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            page: page.selected + 1,
            update: new Date().getTime()
        }));
    }

    const handleAdd = () => {
        let modal = modalRef.current;

        modal.open();

        modal.onSave(data => {
            handleUpdate();

            if (typeof props.handleRefresh === 'function') {
                props.handleRefresh();
            }
        });
    }

    const handleEdit = id => {
        let modal = modalRef.current;

        modal.edit(id);

        modal.onSave(data => {
            handleUpdate();

            if (typeof props.handleRefresh === 'function') {
                props.handleRefresh();
            }
        });

    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const handleDelete = id => {
        let modal = deleteModalRef.current;

        modal.open();

        modal.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleDestroy = id => {
        Api.post('appliances/categories/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                handleUpdate();

                if (typeof props.handleRefresh === 'function') {
                    props.handleRefresh();
                }
            }
        });
    }

    return (
        <div style={{ width: '100%' }}>

            <div className="head">
                <h4>{trans('headings.categories')}</h4>

                <button
                    className="button button-add button-small"
                    onClick={handleAdd}
                >
                    {trans('buttons.add')}
                </button>
            </div>

            {state.loading
                ?
                <Skeleton height={35} count={10} />
                :
                <>
                    <Modal
                        ref={modalRef}
                    />

                    <Question
                        ref={deleteModalRef}
                        mainMessage={trans('headings.confirmDelete')}
                    />

                    {state.data.length === 0
                        ?
                        <NoDataFound />
                        :
                        <>
                            <div className="table-scroller">
                                <table className="settings">
                                    <thead>
                                        <tr>
                                            <td className="center" style={{ width: '100px' }}>
                                                {trans('labels.image')}
                                            </td>
                                            <td>
                                                {trans('labels.name')}
                                            </td>
                                            <td className="options center">
                                                {trans('labels.options')}
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.data.map(category =>
                                            <tr key={'c-' + category.id}>
                                                <td className="center" style={{ width: '100px' }}>
                                                    <img src={category.avatar} width={40} height={40} style={{ margin: '0', objectFit: 'cover', borderRadius: '3px' }} />
                                                </td>
                                                <td>
                                                    {category.name}
                                                </td>
                                                <td className="options center">
                                                    <Options
                                                        id={category.id}
                                                        handleEdit={handleEdit}
                                                        handleDelete={handleDelete}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <Pagination
                                pages={state.pages}
                                page={state.page}
                                handlePage={handlePage}
                            />

                        </>
                    }
                </>
            }
        </div>
    )

}

export default Categories