import React, { useEffect, useState, useRef } from 'react';
import Table from './partials/TableVanUsed';
import Api from '../../../_helpers/Api';
import Pagination from '../../Pagination';
import { useHistory, useLocation } from 'react-router-dom';

//modals
import Filter from './partials/Filter';
import History from './partials/History';

import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../_providers/Translation';
import Error from '../../modals/messages/Error';
import Question from '../../modals/messages/Question';

let timeout;

function VanUsed() {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const modalHistoryRef = useRef(null);
    const backInStockErrorModalRef = useRef(null);
    const backInStockQuestionModalRef = useRef(null);

    const [parts, setParts] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {
            stock: 'van',
            instock: '0',
        },
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setParts(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                category_id: searchParams.get('category_id') || '',
                name: searchParams.get('name') || '',
                number: searchParams.get('number') || '',
                supplier_id: searchParams.get('supplier_id') || '',
                customer_type: searchParams.get('customer_type') || '',
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {
        if (parts.setFilter) {
            searchParams.set('page', parts.page);

            Object.entries(parts.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            history.push('?' + searchParams.toString());
        }

    }, [parts.setFilter]);

    useEffect(() => {
        if (parts.update) {
            loadParts();
        }
    }, [parts.update])

    const loadParts = () => {

        let url = 'storage/used-parts?page=' + parts.page;

        Object.entries(parts.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        setParts(prev => ({
            ...prev,
            loading: true
        }));

        Api.get(url)
            .then(res => {
                setParts(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }));
            }).catch(err => {
                setParts(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleRefresh = () => {
        setParts(prev => ({
            ...prev,
            page: 1,
            update: new Date().getTime()
        }));
    }

    const handleReload = () => {
        setParts(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    // Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setParts(prev => ({
            ...prev,
            loading: true,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setParts(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const handlePage = page => {
        setParts(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    const handleHistory = id => {
        modalHistoryRef.current.show(id);
    }

    const handleBackInStock = id => {
        Api.post('storage/back-in-stock', {
            id: id
        }).then(res => {
            if (res.data.success) {
                handleRefresh();
            }
        })
    }

    const handleBackInStockFromStorage = id => {
        let modal = backInStockQuestionModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            backInStockFromStorage(id);
        });
    }

    const backInStockFromStorage = id => {
        Api.post('storage/back-in-stock-storage', {
            id: id
        }).then(res => {
            if (res.data.success) {
                handleRefresh();
            } else {
                backInStockErrorModalRef.current.open();
            }
        })
    }

    const handleHideBackInStock = id => {
        Api.post('storage/hide-back-in-stock', {
            id: id
        }).then(res => {
            if (res.data.success) {
                handleRefresh();
            }
        })
    }

    return (
        <>

            <History
                ref={modalHistoryRef}
            />

            <Question
                ref={backInStockQuestionModalRef}
                mainMessage={trans('headings.question15')}
            />

            <Error
                ref={backInStockErrorModalRef}
                mainMessage={trans('messages.backInStockQtyError')}
            />

            <Filter
                filter={parts.filter}
                showCustomerType={true}
                handleSearch={handleSearch}
            />

            <div className="pannel">
                <h3>
                    {trans('headings.usedVanParts')}
                </h3>

                {parts.loading
                    ?
                    <Skeleton height={45} count={10} />
                    :
                    <>

                        <Table
                            parts={parts.data}
                            filter={parts.filter}
                            handleHistory={handleHistory}
                            handleReload={handleReload}
                            handleBackInStock={handleBackInStock}
                            handleBackInStockFromStorage={handleBackInStockFromStorage}
                            handleHideBackInStock={handleHideBackInStock}
                        />

                        <Pagination
                            pages={parts.pages}
                            page={parts.page}
                            handlePage={handlePage}
                        />
                    </>
                }
            </div>

        </>
    )
}

export default VanUsed;