import React, { useState, useRef } from 'react'
import Menu from '@material-ui/core/Menu'
import { trans } from '../../../../_providers/Translation';

//images
import groupMailIcon from '../../../../assets/img/icons/group-mail.png'
import editIcon from '../../../../assets/img/icons/edit.png'
import deleteIcon from '../../../../assets/img/icons/delete.png'
import menuIcon from '../../../../assets/img/icons/table-menu.png'
import checkIcon from '../../../../assets/img/icons/check.png'

//modals
import QuestionModal from '../../../modals/messages/Question'

function TodoMenu(props) {
    const [menu, setMenu] = useState({
        options: props.options,
    })

    //refs
    const questionModalRef = useRef(null)

    const handleTableMenu = e => {
        setMenu(menu => {
            return {
                ...menu,
                options: e.currentTarget
            }
        })
    }

    const handleClose = () => {
        setMenu(menu => {
            return {
                ...menu,
                options: null
            }
        })
    }

    const handleComplete = id => questionModalRef.current.open(id)

    const confirmComplete = id => props.handleComplete(id)


    return (
        <td>
            <QuestionModal
                ref={questionModalRef}
                mainMessage={trans('headings.question2')}
                callback={confirmComplete}
            />
            <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />
            <Menu
                id="simple-menu"
                anchorEl={menu.options}
                keepMounted
                open={Boolean(menu.options)}
                onClose={handleClose}
            >
                <div className="menu-option" onClick={() => {
                    props.handleView(props.each)
                    handleClose()
                }
                }>
                    <img src={groupMailIcon} alt="group mail" />
                    {trans('buttons.view')}
                </div>
                {!props.isCompleted && props.canMarkAsCompleted && <div className="menu-option" onClick={() => {
                    handleComplete(props.each.id)
                    handleClose()
                }}>
                    <img src={checkIcon} alt="complete" />
                    {trans('buttons.completed')}
                </div>}
                <div className="menu-option" onClick={() => {
                    props.handleEdit(props.each.id)
                    handleClose()
                }
                }>
                    <img src={editIcon} alt="group mail" />
                    {trans('buttons.edit')}
                </div>
                <div className="menu-option" onClick={() => {
                    props.handleDelete(props.each.id)
                    handleClose()
                }
                }>
                    <img src={deleteIcon} alt="delete" />
                    {trans('buttons.delete')}
                </div>
            </Menu>
        </td>

    )
}

export default TodoMenu