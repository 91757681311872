import React from 'react';
import inIcon from '../../../../assets/img/icons/phone/incomming.png';
import outIcon from '../../../../assets/img/icons/phone/outgoing.png';
import missIcon from '../../../../assets/img/icons/phone/missed.png';
import cancelIcon from '../../../../assets/img/icons/phone/canceled.png';
import { trans } from '../../../../_providers/Translation';

function Legend(props) {

    const handleStatus = val => {
        if (props.filter?.status === val) {
            props.handleSearch('status', '');
        } else {
            props.handleSearch('status', val);
        }
    }

    const elStyle = {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 10px',
        background: '#eee',
        borderRadius: '16px',
        marginRight: '10px',
        cursor: 'pointer',
    }

    const imgStyle = {
        height: '24px',
        marginRight: '5px'
    }

    return (
        <div style={{ display: 'flex', fontSize: '12px', overflow: 'auto', marginBottom: '20px' }}>
            <div style={elStyle} className={['btn', props.filter?.status === 'incomming' ? 'active' : ''].join(' ')} onClick={() => handleStatus('incomming')}>
                <img src={inIcon} style={imgStyle} />
                <span>
                    {trans('labels.incomming')}
                </span>
            </div>
            <div style={elStyle} className={['btn', props.filter?.status === 'outgoing' ? 'active' : ''].join(' ')} onClick={() => handleStatus('outgoing')}>
                <img src={outIcon} style={imgStyle} />
                <span>
                    {trans('labels.outgoing')}
                </span>
            </div>
            <div style={elStyle} className={['btn', props.filter?.status === 'missed' ? 'active' : ''].join(' ')} onClick={() => handleStatus('missed')}>
                <img src={missIcon} style={imgStyle} />
                <span>
                    {trans('labels.missed')}
                </span>
            </div>
            <div style={elStyle} className={['btn', props.filter?.status === 'canceled' ? 'active' : ''].join(' ')} onClick={() => handleStatus('canceled')}>
                <img src={cancelIcon} style={imgStyle} />
                <span>
                    {trans('labels.canceled')}
                </span>
            </div>
        </div>
    )
}

export default Legend;