import React, { useEffect, useState, useRef } from 'react'
import Api from '../../../../_helpers/Api'
import { useParams, useHistory } from 'react-router-dom'
import { trans } from '../../../../_providers/Translation';

//images
import deleteIcon from '../../../../assets/img/icons/delete-red.png'
import imageIcon from '../../../../assets/img/icons/image.png'
import loader from '../../../../assets/img/loader.svg'
import notFound from '../../../../assets/img/not-found.png'

//modals
import QuestionModal from '../../../modals/messages/Question'
import PreviewModal from './modals/Images'

function Images(props) {

    const [images, setImages] = useState({
        data: [],
        loading: true
    })

    let params = useParams().id

    const history = useHistory()

    //refs
    const questionModalRef = useRef(null)
    const previewModalRef = useRef(null)

    useEffect(() => loadData(), [])

    const loadData = () => {
        setImages(images => ({
            ...images,
            loading: true,
        }));

        Api.get(`reservations/images?id=${params}`)
            .then(res => {
                setImages(images => ({
                    ...images,
                    data: res.data,
                    loading: false,
                }))
            })
    }

    const handleDelete = id => questionModalRef.current.open(id)

    const handleAcceptDelete = id => {
        Api.post('reservations/images/delete', {
            id: params,
            image_id: id,
        }).then(() => {
            history.push(`?tab=images&indicator=${new Date().getTime()}`)
        })

        let newData = images.data.filter(image => {
            return image.id != id
        })

        setImages(images => ({
            ...images,
            data: newData
        }))
    }

    const handleUpload = e => {
        e.preventDefault()

        let formData = new FormData(e.target)

        Api.post('reservations/images/add', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(() => {
            history.push(`?tab=images&indicator=${new Date().getTime()}`)
            loadData()
        })

    }

    const preview = index => {
        previewModalRef.current.show(index);
    }

    return (
        <>
            <QuestionModal
                mainMessage={trans('headings.question4')}
                secondaryMessage={trans('subheadings.confirmDelete')}
                ref={questionModalRef}
                callback={handleAcceptDelete}
            />

            <PreviewModal
                ref={previewModalRef}
                images={images.data}
            />

            {images.loading ?
                <img src={loader} alt="loading" />
                :

                <ul className="panel-wrapper row add-images">
                    {images.data.map((image, i) =>
                        <li key={image.id}>
                            <img
                                src={deleteIcon}
                                alt="delete"
                                onClick={() => handleDelete(image.id)}
                                className="delete"
                            />
                            <img
                                src={image.picture}
                                alt={image.id}
                                className="image"
                                onClick={e => preview(i)}
                                style={{ cursor: 'pointer' }}
                            />
                        </li>
                    )}
                    <form onSubmit={handleUpload}>
                        {props.data.can_modify &&
                            <li className="new-image">
                                <img src={imageIcon} alt="upload" />
                                <p>
                                    {trans('random.newImage')}
                                </p>
                                <button id="imageUpload" className="button button-add">
                                    {trans('buttons.select')}
                                </button>
                                <input type="text" name="id" value={params} />
                                <input type="file" id="files" name="images[]" multiple onChange={e => document.getElementById('imageUpload').click(e)} />
                            </li>
                        }
                    </form>
                </ul>
            }
        </>
    )
}

export default Images