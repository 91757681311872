import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import Chart from 'react-google-charts';
import Api from '../../../../_helpers/Api';
import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../../_providers/Translation';

function Finances() {

    const [state, setState] = useState({
        loading: true,
        data: {
            dates: []
        },
        chart: null,
    });

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        Api.get('accounts/profit')
            .then(res => {

                let label = [trans('random.month'), trans('random.income'), trans('random.expense'), trans('random.difference')];

                let chart = [
                    label
                ];

                let fake = [
                    label
                ];

                res.data.dates.map(d => {
                    let arr = [
                        d.label,
                        d.incomes,
                        d.expenses,
                        d.diff
                    ];

                    let empty = [
                        d.label, 0, 0, 0
                    ];

                    fake.push(empty);
                    chart.push(arr);
                });

                setState(prev => ({
                    ...prev,
                    data: res.data,
                    chart: chart
                }));

                // setTimeout(() => {
                //     setState(prev => ({
                //         ...prev,
                //         chart: chart
                //     }))
                // }, 1000);


            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }))
            });

    }

    return (
        <div className="col current-data-chart">
            <h3>
                {trans('random.financeDifference')}
            </h3>
            <div className="chart">

                {state.loading || !state.data.dates.length
                    ?
                    <Skeleton height={240} count={1} />
                    :
                    <Chart
                        width={'99%'}
                        height={'240px'}
                        chartType="Bar"
                        className="animated-chart-vertical animated-chart-start"
                        loader={'...'}
                        data={state.chart}
                        options={{
                            // Material design options
                            chart: {
                                // title: 'Company Performance',
                                // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                            },
                            legend: {
                                position: 'none',
                                // position: 'right',
                                // alignment: 'end'
                            },
                            hAxis: {
                                format: 'currency'
                            },
                            colors: ['#FBBD08', '#E825FF', '#0ED8A6'],
                            // animation: {
                            // startup: true,
                            // easing: 'out',
                            // duration: 1500,
                            // },
                        }}
                        chartEvents={[
                            {
                                eventName: "ready",
                                callback: ({ chartWrapper, google }) => {
                                    const chartEl = chartWrapper.getChart().container;
                                    setTimeout(() => {
                                        chartEl.classList.remove('animated-chart-start')
                                    }, 100)
                                },
                            }
                        ]}
                    />
                }
            </div>
        </div>

    )
}

export default Finances;