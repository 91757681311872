import React, { useEffect, useState, useRef } from 'react';
import Table from './partials/Table';
import Api from '../../../_helpers/Api';
//modals
import AddOrEdit from './partials/AddOrEdit';
import Question from '../../modals/messages/Question';
import Filter from './partials/Filter';
import PartsPages from '../../partials/PartsPages';
import { fromInfo, trans } from '../../../_providers/Translation';
import Help from '../../partials/Help';

function Suppliers() {

    const modalAddOrEditRef = useRef(null);
    const modalQuestionRef = useRef(null);

    const [suppliers, setSuppliers] = useState({
        loading: true,
        data: [],
        update: false,
        // search
        name: ''
    });

    useEffect(() => {
        loadSuppliers();
    }, [suppliers.update]);

    //read
    const loadSuppliers = () => {

        setSuppliers(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'suppliers/all?name=' + suppliers.name;

        Api.get(url)
            .then(res => {
                setSuppliers(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setSuppliers(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add();
    }

    const handleCreate = supplier => {

        // let data = partners.data.concat(partner);

        // setPartners(prev => ({
        //     ...prev,
        //     data: data
        // }));

        setSuppliers(prev => ({
            ...prev,
            update: Math.random().toString()
        }));

    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id);
    }

    const handleUpdate = supplier => {

        let data = suppliers.data.map(i => {
            if (i.id == supplier.id) i = supplier;
            return i;
        });

        setSuppliers(prev => ({
            ...prev,
            data: data
        }));
    }

    // delete
    const handleDelete = id => {
        Api.post('suppliers/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                let data = suppliers.data.filter(i => {
                    return i.id !== id;
                });
                setSuppliers(prev => ({
                    ...prev,
                    data: data
                }));
            }
        });
    }

    // Question modal
    const handleOpenQuestion = id => {
        modalQuestionRef.current.open(id);
    }

    let timeout;

    const handleSearch = (key, val) => {

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setSuppliers(prev => ({
                ...prev,
                [key]: val,
                update: Math.random().toString()
            }));
        }, 300);

    }

    return (
        <>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
                callback={handleDelete}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.suppliers')} <Help>{fromInfo('suppliers')}</Help>
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.suppliers')}
                        </p>
                    </div>
                    <div className="right">
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.add')}
                        </button>
                    </div>
                </div>

                <PartsPages />

                <Filter
                    handleSearch={handleSearch}
                />

                <Table
                    suppliers={suppliers.data}
                    loading={suppliers.loading}
                    handleEdit={handleEdit}
                    handleDelete={handleOpenQuestion}
                />

            </div>
        </>
    )
}

export default Suppliers;