import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react';
import Api from '../../../../../_helpers/Api'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Pagination from '../../../../Pagination';
import { trans } from '../../../../../_providers/Translation';

//images
import docIcon from '../../../../../assets/img/icons/document.png';
import checkIcon from '../../../../../assets/img/icons/check.png';
import closeIcon from '../../../../../assets/img/icons/close.png';
import loader from '../../../../../assets/img/loader.svg';
import NewPart from './AddEditPart';


//modals

let timeOut

function AddParts(props, ref) {
    const params = useParams();
    const id = params.id;

    const history = useHistory()
    const location = useLocation()

    const reservation = props.reservation;
    const statuses = props.statuses;

    const modalNewPartRef = useRef(null);

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        data: [],
        checkedParts: {},
        loading: false,
        loadingButtons: false,
        name: null,
        number: null,
        pages: 0,
        page: new URLSearchParams(location.search).get('page') || 1,
        updateData: false,
    })

    useImperativeHandle(ref, () => ({
        open: () => {
            handleOpen();
        },
        close: () => {
            handleClose()
        }
    }))

    useEffect(() => {
        if (state.updateData) {
            loadData();
        }
    }, [state.updateData]);

    const handleOpen = () => {
        setState(state => ({
            ...state,
            overlay: true,
        }))

        setTimeout(() => {
            setState(state => ({
                ...state,
                modal: true,
            }))
        }, 50);
    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(state => ({
            ...state,
            modal: false,
        }))

        setTimeout(() => {
            setState(state => ({
                ...state,
                overlay: false,
                params: null,
            }))
        }, 50)
    }

    const handleSearch = (e) => {
        let name = e.target.name;
        let val = e.target.value;


        setState(prev => ({
            ...prev,
            [name]: val
        }));

        if (name === 'number') {
            clearTimeout(timeOut)

            setState(prev => ({
                ...prev,
                loading: true,
            }));

            timeOut = setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    updateData: new Date().getTime()
                }));
            }, 300);
        }

    }

    const loadData = () => {

        if (!state.number || state.number === '') {

            setState(prev => ({
                ...prev,
                loading: false,
                data: [],
                pages: 0
            }));

            return;
        }

        setState(prev => ({
            ...prev,
            loading: true
        }));

        Api.get(`storage/find-stock-part?number=${state.number}&page=${state.page}`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    pages: res.data.pages
                }))
            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }))
            })
    }

    const handlePage = page => {
        setState(state => ({ ...state, page: page.selected + 1, updateData: new Date().getTime() }));
    }

    // const handleCheckParts = id => {
    //     let parts = Object.assign({}, state.checkedParts);

    //     if (parts[id]) {
    //         delete parts[id];
    //     } else {
    //         parts[id] = 1;
    //     }

    //     setState(state => ({
    //         ...state,
    //         checkedParts: parts
    //     }))
    // }

    // const handleSend = () => {

    //     if (Object.values(state.checkedParts).length === 0) {
    //         return;
    //     }

    //     setState(state => ({
    //         ...state,
    //         loadingButtons: true
    //     }))

    //     Api.post('reservations/parts/add', {
    //         id: id,
    //         parts: Object.keys(state.checkedParts)
    //     }).then(() => {
    //         history.push(`?tab=parts&indicator=${new Date().getTime()}`)

    //         loadData();
    //         props.refreshData()

    //         handleClose()
    //     }).finally(() => {
    //         setState(prev => ({
    //             ...prev,
    //             checkedParts: {},
    //             loadingButtons: false
    //         }));
    //     });
    // }

    const handleAddNewPart = () => {

        let modal = modalNewPartRef.current;

        handleClose();

        modal.show(state.name, state.number);

        modal.onCancel(() => {
            handleOpen();
        });

        modal.onSave(() => {
            history.push(`?tab=parts&indicator=${new Date().getTime()}`)

            setState(prev => ({
                ...prev,
                name: null,
                number: null
            }));

            props.refreshData();
        });
    }

    const highlight = (string, search) => {
        if (!string) {
            return string;
        }

        let regex = new RegExp(search, 'gi');

        let str = string.replace(regex, str => {
            return '<b style="color: #0088ff">' + str + '</b>';
        });

        return str;
    }

    const createMarkup = str => {
        return { __html: str };
    }

    return (

        <>
            <NewPart
                ref={modalNewPartRef}
                reservation={reservation}
                statuses={statuses}
            />

            <div className={`${state.overlay ? 'visible' : ''} overlay`}>
                <div className={`${state.modal ? 'show' : ''} popup-primary large`}>
                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />
                        {trans('headings.addPart')}

                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleClose} />
                    </h2>
                    <div className="popup-container x2">

                        <div className="col">
                            <label>
                                {trans('labels.name')}
                            </label>
                            <input
                                type="search"
                                name="name"
                                value={state.name || ''}
                                onChange={handleSearch}
                            />
                        </div>

                        <div className="col">
                            <label>
                                {trans('labels.number')}
                            </label>
                            <input
                                type="search"
                                name="number"
                                value={state.number || ''}
                                onChange={handleSearch}
                            />
                        </div>

                    </div>

                    <br />

                    <div className="popup-container-read-only">
                        {state.loading ?
                            <img src={loader} alt="loading" />
                            :
                            state.data.length > 0
                                ?
                                <>
                                    <div className="table-scroller">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>{trans('tables.name')}</th>
                                                    <th>{trans('tables.number')}</th>
                                                    <th>{trans('tables.category')}</th>
                                                    <th className="center">{trans('tables.quantity')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.data.map(row =>
                                                    <tr key={'part-' + row.id}>
                                                        <td>
                                                            {row.part_id}
                                                        </td>
                                                        <td>
                                                            <span dangerouslySetInnerHTML={createMarkup(
                                                                highlight(row.part.name, state.name)
                                                            )} />
                                                        </td>
                                                        <td>
                                                            <span dangerouslySetInnerHTML={createMarkup(
                                                                highlight(row.part.number, state.number)
                                                            )} />
                                                        </td>
                                                        <td>
                                                            {row.part.category ? row.part.category.name : ''}
                                                        </td>
                                                        <td className="center">
                                                            {row.quantity > 0
                                                                ?
                                                                <img src={checkIcon} height={12} />
                                                                :
                                                                <img src={closeIcon} height={12} />
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination
                                        pages={state.pages}
                                        page={state.page}
                                        handlePage={handlePage}
                                    />
                                </>
                                :
                                ''
                        }
                    </div>

                    <div className="footer align-center">
                        {state.loadingButtons ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button onClick={handleClose}>
                                    {trans('buttons.cancel')}
                                </button>

                                <button onClick={handleAddNewPart} className="button button-primary">
                                    {trans('buttons.add')}
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(AddParts)