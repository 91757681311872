import React, { useEffect, useRef, useState } from 'react';
import Options from './Options';
import PaymentOptions from './PaymentOptions';
import loader from '../../../../assets/img/loader.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import NoDataFound from '../../../partials/NoDataFound';
import closeIcon from '../../../../assets/img/icons/close.png';
import checkIcon from '../../../../assets/img/icons/check.png';
import infoIcon from '../../../../assets/img/icons/info-black.png';
import Question from '../../../modals/messages/Question';
import Success from '../../../modals/messages/Success';
import Error from '../../../modals/messages/Error';
import Api from '../../../../_helpers/Api';
import { useAuthDataContext } from '../../../../_providers/Auth';
import Pagination from '../../../Pagination';
import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../../_providers/Translation';
import ReactTooltip from 'react-tooltip';

function Table(props) {

    const auth = useAuthDataContext();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selected = searchParams.get('id') ? Number(searchParams.get('id')) : null;

    const [active, setActive] = useState(null);

    const [payments, setPayments] = useState({
        loading: true,
        data: [],
        filter: {},
        page: 1,
        update: false,
    });

    const paymentQuestionModalRef = useRef(null);
    const paymentSuccessModalRef = useRef(null);
    const paymentErrorModalRef = useRef(null);
    const invoiceQuestionModalRef = useRef(null);
    const deleteQuestionModalRef = useRef(null);

    useEffect(() => {
        setActive(selected);
    }, [selected]);

    useEffect(() => {
        if (!active) {
            return;
        }

        setPayments(prev => ({
            ...prev,
            page: 1,
            loading: true,
            update: new Date().getTime()
        }));
    }, [active]);

    useEffect(() => {
        if (payments.update) {
            loadPayments();
        }
    }, [payments.update]);

    const loadPayments = () => {
        if (!active) {
            return;
        }

        setPayments(prev => ({
            ...prev,
            loading: true
        }));


        let url = 'partners/payments?partner_id=' + active + '&page=' + payments.page;

        Api.get(url)
            .then(res => {
                setPayments(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }))
            }).catch(err => {
                setPayments(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleToggleRow = rowId => {

        document.querySelectorAll('.collapsable-table tr.active').forEach(el => {
            el.classList.remove('active');
        });

        let rows = document.querySelectorAll(`tr[data-row="${rowId}"]`)

        for (let i = 0; i < rows.length; i++) {
            if (rows[i].classList.contains('active')) {
                rows[i].classList.remove('active');
            } else {
                rows[i].classList.add('active')
            }
        }

        searchParams.set('id', rowId);

        history.replace('?' + searchParams.toString());

    }

    const handleMarkAsPaid = id => {
        let modal = paymentQuestionModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            markAsPaid(id);
        });
    }

    const markAsPaid = id => {
        Api.post('payments/mark-as-paid', {
            payment_id: id
        }).then(res => {
            if (res.data.success) {
                paymentSuccessModalRef.current.open();

                refreshPayments();
            } else {
                paymentErrorModalRef.current.open();
            }
        }).catch(err => {
            paymentErrorModalRef.current.open();
        });
    }

    const handleMakeInvoice = id => {
        let modal = invoiceQuestionModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            makeInvoice(id);
        });
    }

    const makeInvoice = id => {
        Api.post('payments/invoice', {
            payment_id: id
        }).then(res => {
            refreshPayments();
        });
    }

    const handleDelete = id => {
        let modal = deleteQuestionModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            deletePayment(id);
        });
    }

    const deletePayment = id => {
        Api.post('payments/delete', {
            payment_id: id
        }).then(res => {
            refreshPayments();
        });
    }

    const handlePage = page => {
        setPayments(prev => ({ ...prev, page: page.selected + 1, update: new Date().getTime() }));
    }

    const refreshPayments = () => {
        setPayments(prev => ({ ...prev, update: new Date().getTime() }));
    }

    const handleCopy = id => {
        let el = document.getElementById('extra-info-' + id);

        let text = el.querySelector('.info');

        text.select();

        document.execCommand('copy');
    }

    return (

        props.loading
            ?
            <img src={loader} alt="loading" />
            :
            props.partners.length === 0
                ?
                <NoDataFound />
                :
                <>
                    <Question
                        ref={paymentQuestionModalRef}
                        mainMessage={trans('headings.question7')}
                        secondaryMessage={trans('subheadings.question7')}
                    />

                    <Success
                        ref={paymentSuccessModalRef}
                        mainMessage={trans('headings.success8')}
                    />

                    <Error
                        ref={paymentErrorModalRef}
                        mainMessage={trans('headings.error3')}
                    />

                    <Question
                        ref={invoiceQuestionModalRef}
                        mainMessage={trans('headings.question8')}
                    />

                    <Question
                        ref={deleteQuestionModalRef}
                        mainMessage={trans('headings.confirmDelete')}
                    />

                    <div className="table-scroller">
                        <table className="collapsable-table">
                            <thead>
                                <tr>
                                    <th style={{ width: '50px' }}>ID</th>
                                    <th>{trans('tables.name')}</th>
                                    <th>{trans('tables.eik')}</th>
                                    <th>{trans('tables.website')}</th>
                                    <th>{trans('tables.email')}</th>
                                    <th>{trans('tables.phone')}</th>
                                    <th>{trans('tables.address')}</th>
                                    <th className="center">{trans('tables.active')}</th>
                                    <th className="center">{trans('labels.superpartner')}</th>
                                    <th className="right">{trans('tables.licenseDeadline')}</th>
                                    <th className="center">{trans('tables.payments')}</th>
                                    <th className="center">{trans('tables.info')}</th>
                                    <th className="options">{trans('tables.options')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.partners.map(partner =>
                                    <React.Fragment key={'partner-' + partner.id}>
                                        <tr
                                            data-row={partner.id}
                                            className={partner.id === selected ? 'active' : ''}
                                            onClick={() => handleToggleRow(partner.id)}
                                        >
                                            <td style={{ width: '50px' }}>{partner.id}</td>
                                            <td>
                                                <Link to={'/partners/' + partner.id}>{partner.name}</Link>
                                            </td>
                                            <td>
                                                {partner.eik ? partner.eik : '-'}
                                            </td>
                                            <td>{partner.website
                                                ?
                                                <a target='_blank' href={partner.website}>
                                                    {partner.website}
                                                </a>
                                                :
                                                trans('messages.notSelected')
                                            }
                                            </td>
                                            <td>{partner.email}</td>
                                            <td>{partner.phone}</td>
                                            <td>{partner.address}</td>
                                            <td className="center">
                                                {partner.active
                                                    ?
                                                    <img src={checkIcon} height="12" />
                                                    :
                                                    <img src={closeIcon} height="12" />
                                                }
                                            </td>
                                            <td className="center">
                                                {partner.superpartner
                                                    ?
                                                    <img src={checkIcon} height="12" />
                                                    :
                                                    <img src={closeIcon} height="12" />
                                                }
                                            </td>
                                            <td className="right">{partner.license_expires_at_dmy}</td>
                                            <td className="center">
                                                <span className="badge">
                                                    {partner.payments_count}
                                                </span>
                                            </td>
                                            <td className="center">
                                                <img className="info-icon" data-tip data-for={'extra-info-' + partner.id} data-event="click" src={infoIcon} />
                                                <ReactTooltip
                                                    id={'extra-info-' + partner.id}
                                                    effect="solid"
                                                    clickable
                                                    multiline
                                                    isCapture
                                                >
                                                    <div style={{ textAlign: 'left' }}>
                                                        {trans('random.epay_url')}: <u>{partner.epay_receive_url}</u>
                                                        <br />
                                                        {trans('random.voicecall_url')}: <u>{partner.voicecall_url}</u>
                                                    </div>
                                                </ReactTooltip>
                                            </td>
                                            <td className="options">
                                                <Options
                                                    id={partner.id}
                                                    handleEdit={props.handleEdit}
                                                    handleDelete={props.handleDelete}
                                                    handleRemoveLicense={props.handleRemoveLicense}
                                                />
                                            </td>
                                        </tr>
                                        <tr
                                            className={['collapsable', partner.id === selected ? 'active' : ''].join(' ')}
                                            data-row={partner.id}
                                        >
                                            <td colSpan="13">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                ID
                                                            </th>
                                                            <th className="center">
                                                                {trans('tables.paymentMethod')}
                                                            </th>
                                                            <th>
                                                                {trans('tables.license')}
                                                            </th>
                                                            <th>
                                                                {trans('tables.smsPackage')}
                                                            </th>
                                                            <th className="right">
                                                                {trans('tables.price')}
                                                            </th>
                                                            <th className="right">
                                                                {trans('tables.eventDate')}
                                                            </th>
                                                            <th className="center">
                                                                {trans('tables.isPaid')}
                                                            </th>
                                                            <th className="right">
                                                                {trans('tables.paymentDate')}
                                                            </th>
                                                            <th className="center">
                                                                {trans('tables.invoice')}
                                                            </th>
                                                            <th className="options">{trans('tables.options')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {payments.loading
                                                            ?
                                                            <tr>
                                                                <td colSpan="10">
                                                                    <Skeleton count={5} height={45} />
                                                                </td>
                                                            </tr>
                                                            :
                                                            payments.data.length === 0
                                                                ?
                                                                <tr>
                                                                    <td colSpan="10">
                                                                        <div className="alert warning" style={{ margin: 0 }}>
                                                                            {trans('messages.noPayments')}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                <>
                                                                    {payments.data.map(p =>
                                                                        <tr key={'pay-' + p.id}>
                                                                            <td>
                                                                                {p.id}
                                                                            </td>
                                                                            <td className="center">
                                                                                {p.method && p.method.translation ? p.method.translation.name : '-'}
                                                                            </td>
                                                                            <td>
                                                                                {p.license && p.license.translation ? p.license.translation.name : '-'}
                                                                            </td>
                                                                            <td>
                                                                                {p.smspacket ? `${p.sms_count} броя (${p.smspacket?.type})` : '-'}
                                                                            </td>
                                                                            <td className="right">
                                                                                {p.price_formatted} {p.currency ? p.currency.name : ''}
                                                                            </td>
                                                                            <td className="right">
                                                                                {p.created_at_dmy || '-'}
                                                                            </td>
                                                                            <td className="center">
                                                                                {p.paid
                                                                                    ?
                                                                                    <img src={checkIcon} height="12" />
                                                                                    :
                                                                                    <img src={closeIcon} height="12" />
                                                                                }
                                                                            </td>
                                                                            <td className="right">
                                                                                {p.paid_at_dmy || '-'}
                                                                            </td>
                                                                            <td className="center">
                                                                                {p.invoice
                                                                                    ?
                                                                                    <a href={p.invoice.signed_url} target="_blank">
                                                                                        {p.invoice.number}
                                                                                    </a>
                                                                                    :
                                                                                    '-'
                                                                                }
                                                                            </td>
                                                                            <td className="options">
                                                                                {!p.invoiced &&
                                                                                    <PaymentOptions
                                                                                        id={p.id}
                                                                                        data={p}
                                                                                        handleMarkAsPaid={handleMarkAsPaid}
                                                                                        handleMakeInvoice={handleMakeInvoice}
                                                                                        handleDelete={handleDelete}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    <tr>
                                                                        <td colSpan="10">
                                                                            <Pagination
                                                                                pages={payments.pages}
                                                                                page={payments.page}
                                                                                handlePage={handlePage}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
    )
}

export default Table;