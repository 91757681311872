import React, { useEffect } from 'react';
import { trans } from '../../../_providers/Translation'

function Part(props) {

    return (
        <table>
            <thead>
                <tr>
                    <th>{trans('tables.activity')}</th>
                    <th>{trans('tables.date')}</th>
                    <th>{trans('tables.employee')}</th>
                    <th>{trans('tables.category')}</th>
                    <th>{trans('tables.name')}</th>

                </tr>
            </thead>
            <tbody>
                {props.data.map((h, i) => (
                    <tr key={i} className={[h.actual ? 'actual' : h.selected ? 'selected' : '']}>
                        <td>
                            {h.event}
                        </td>
                        <td>{h.date}</td>
                        <td>{h.causer && `${h.causer.name} ${h.causer.lastname}`}</td>
                        <td>{h.subject.category ? h.subject.category.name : '-'}</td>
                        <td>{h.subject.translation ? h.subject.translation.name : '-'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default Part;