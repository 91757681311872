import React from 'react';
import { trans } from '../../../../_providers/Translation';

function FilterEmployees(props) {

    return (
        <form className="filter-standard">

            <div className="col">
                <label>
                    {trans('labels.name')}
                </label>
                <input
                    type="search"
                    name="name"
                    onKeyUp={(e) => props.handleSearch(e.target.name, e.target.value)}
                    onChange={(e) => props.handleSearch(e.target.name, e.target.value)}
                />
            </div>

            <div className="col">
                <label>
                    {trans('labels.role')}
                </label>
                <select
                    name="role_id"
                    onKeyUp={(e) => props.handleSearch('roleId', e.target.value)}
                    onChange={(e) => props.handleSearch('roleId', e.target.value)}
                >
                    <option value="">{trans('labels.all')}</option>
                    {props.roles.map(r =>
                        <option key={'r-' + r.id} value={r.id}>
                            {r.translation.name}
                        </option>
                    )}
                </select>
            </div>

        </form>
    )
}

export default FilterEmployees;