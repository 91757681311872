import React from 'react';
import { trans } from '../../../../_providers/Translation'

function Filter(props) {

    return (
        <form className="filter-standard">

            {props.branches.length
                ?
                <div className="col">
                    <label>
                        {trans('labels.branch')}
                    </label>
                    <select
                        name="branch_id"
                        value={props.filter?.branch_id || ''}
                        onChange={e => props.handleSearch(e.target.name, e.target.value)}
                    >
                        <option value="">{trans('random.select')}</option>
                        {props.branches.map(c => {
                            return <option
                                key={'br-' + c.id}
                                value={c.id}
                            >{c.translation.name}</option>
                        })}
                    </select>
                </div>
                :
                ''
            }

        </form>
    )
}

export default Filter;