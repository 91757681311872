import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

//modals
import Filter from './partials/Filter';

import '../../../assets/css/storage.css';
import PartsPages from '../../partials/PartsPages';
import Used from './Used';
import OfficeStock from './OfficeStock';
import VanUsed from './VanUsed';
import VanStock from './VanStock';
import { trans } from "../../../_providers/Translation"
let timeout;

function Storage() {

    const params = useParams();

    const tabId = params.id;

    const tabs = [
        {
            id: '',
            component: Used,
        },
        {
            id: 'office',
            component: OfficeStock,
            hasRef: true,
        },
        {
            id: 'van-used',
            component: VanUsed,
        },
        {
            id: 'van',
            component: VanStock,
            hasRef: true,
        },
    ];

    const tab = tabId ? tabId : tabs[0].id;

    const componentRef = useRef(null);

    const [state, setState] = useState({
        tab: '',
        index: null,
        loading: true,
        data: null
    });

    useEffect(() => {

        setState(prev => ({
            ...prev,
            loading: true,
            tab: tab,
            index: findTabIndex(tab)
        }));

    }, [tabId]);


    const findTabIndex = id => {
        let index = tabs.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    const handleAdd = () => {
        let modal = componentRef.current;

        if (modal) {
            modal.add();
        }
    }

    return (
        <>
            <div id="dashboard" className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.parts')}
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.parts')}
                        </p>
                    </div>
                    <div className="right">
                        {tabs[state.index] && tabs[state.index].hasRef
                            ?
                            <button className="button button-add" onClick={handleAdd}>
                                {trans('buttons.add2')}
                            </button>
                            :
                            ''
                        }
                    </div>
                </div>

                <PartsPages />

                {tabs[state.index] &&
                    React.createElement(tabs[state.index].component, {
                        ref: componentRef
                    })
                }

            </div>
        </>
    )
}

export default Storage;