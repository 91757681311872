import React, { useRef, useState, useEffect } from 'react';
import { useAuthDataContext } from '../../../../../_providers/Auth';
import Api from '../../../../../_helpers/Api';
import editIcon from '../../../../../assets/img/icons/edit-yellow.png';
import Modal from './modals/VisitPeriods';
import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../../../_providers/Translation';
import NoDataFound from '../../../../partials/NoDataFound';

function VisitPeriods(props) {

    const auth = useAuthDataContext();

    const modalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: [],
        update: false,
    });

    useEffect(() => {
        loadSettings();
    }, [state.update]);

    const loadSettings = () => {
        Api.get('reservations/visitperiods?partner_id=' + auth.partnerId())
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const showModal = () => {
        let modal = modalRef.current;

        modal.show(state.data);

        modal.onSave(() => {
            handleUpdate();

            if (typeof props.handleRefresh === 'function') {
                props.handleRefresh();
            }
        });
    }

    return (
        <div style={{ width: '100%' }}>
            <div className="head">
                <h4>{trans('headings.visitPeriods')}</h4>
                <a onClick={showModal}>
                    <img src={editIcon} alt="" />
                </a>
            </div>

            {state.loading
                ?
                <Skeleton height={35} count={10} />
                :
                <>

                    <Modal
                        ref={modalRef}
                    />

                    {state.data.length === 0
                        ?
                        <NoDataFound />
                        :
                        <div className="table-scroller">
                            <table className="settings">
                                <thead>
                                    <tr>
                                        <td>
                                            {trans('labels.from')}
                                        </td>
                                        <td>
                                            {trans('labels.to')}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.map(t =>
                                        <tr key={t.id}>
                                            <td>
                                                {t.from}
                                            </td>
                                            <td>
                                                {t.to}
                                            </td>

                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default VisitPeriods