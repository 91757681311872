import React, { useRef } from 'react';
import { trans } from '../../_providers/Translation';
import loader from '../../assets/img/loader.svg';
import checkIcon from '../../assets/img/icons/check.png'
import { useAuthDataContext } from '../../_providers/Auth'
import InvoiceOptions from './InvoiceOptions'
import InvoicePayment from './InvoicePayment'
import Api from '../../_helpers/Api';
import Success from '../modals/messages/Success'
import Question from '../modals/messages/Question'
import { Link } from 'react-router-dom';
import notFound from '../../assets/img/not-found.png'

function InvoicesTable(props) {

    const auth = useAuthDataContext()

    const selectRef = useRef(null);
    const successSendModalRef = useRef(null);
    const questionModalRef = useRef(null);
    const paymentModalRef = useRef(null);

    const handleAction = () => {
        let action = selectRef.current.value;
        let selected = [];

        document.querySelectorAll('.selected:checked').forEach(el => {
            selected.push(Number(el.value));
        })

        let question = questionModalRef.current;

        switch (action) {
            case 'send':
                question.open();
                question.onSuccess(() => {
                    send(selected);
                });
                break;
            case 'mark-as-paid':
                markAsPaid(selected);
                break;
            case 'mark-as-unpaid':
                question.open();
                question.onSuccess(() => {
                    markAsUnpaid(selected);
                });
                break;
        }
    }

    const send = ids => {
        Api.post('invoices/send', {
            ids: ids,
        }).then(() => {
            let modal = successSendModalRef.current;

            modal.open();
            modal.onClose(() => {
                if (typeof props.refreshData === 'function') {
                    props.refreshData();
                }
            });
        })
    }

    const markAsPaid = ids => {
        let modal = paymentModalRef.current;

        modal.open(ids);
        modal.onSuccess(() => {
            let modal = successSendModalRef.current;

            modal.open();
            modal.onClose(() => {
                if (typeof props.refreshData === 'function') {
                    props.refreshData();
                }
            });
        });
    }

    const markAsUnpaid = ids => {
        Api.post('invoices/mark-as-unpaid', {
            id: ids,
        }).then(() => {
            let modal = successSendModalRef.current;

            modal.open();
            modal.onClose(() => {
                if (typeof props.refreshData === 'function') {
                    props.refreshData();
                }
            });
        })
    }

    return (
        <>
            <Question
                ref={questionModalRef}
                mainMessage={trans('headings.question15')}
            />

            <Success
                ref={successSendModalRef}
                mainMessage={trans('messages.success')}
            />

            <InvoicePayment
                ref={paymentModalRef}
            />

            {props.loading ?
                <img src={loader} alt="loading" />
                :
                props.invoices.length === 0 ?
                    <div className="not-found">
                        <img src={notFound} alt="Not Found" />
                        <p>
                            {trans('messages.noResultsFound')}
                        </p>
                    </div>
                    :
                    <>
                        <div className="table-scroller">
                            <table className="invoices">
                                <thead>
                                    <tr>
                                        <th style={{
                                            width: '20px'
                                        }}></th>
                                        <th>ID</th>
                                        <th>№</th>
                                        <th>{trans('tables.date')}</th>
                                        <th>{trans('tables.type')}</th>
                                        <th>{trans('tables.client')}</th>
                                        {props.showReservation &&
                                            <>
                                                <th>{trans('tables.clientCompany')}</th>
                                                <th>{trans('tables.reservation')}</th>
                                            </>
                                        }
                                        <th>{trans('tables.netPrice')}</th>
                                        <th>{trans('tables.tax')}</th>
                                        <th>{trans('tables.gross')}</th>
                                        <th className="center">{trans('tables.signed')}</th>
                                        <th className="center">{trans('tables.sent')}</th>
                                        <th className="todo-check">{trans('tables.paid')}</th>
                                        <th>{trans('buttons.view')}</th>
                                        {props.canModify &&
                                            <th className="options">{trans('tables.options')}</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.invoices.map(each =>
                                        <tr key={each.id}>
                                            <td style={{
                                                width: '20px'
                                            }}>
                                                <input
                                                    type="checkbox"
                                                    className="selected"
                                                    name="selected[]"
                                                    value={each.id}
                                                />
                                            </td>
                                            <td>{each.id}</td>
                                            <td>{each.no}</td>
                                            <td>{each.date_dmy}</td>
                                            <td>
                                                <span className={[
                                                    'label',
                                                    Number(each.type_id) === 1 ? 'pro' : Number(each.type_id) === 2 ? 'inv' : 'crn'
                                                ].join(' ')}>
                                                    {each.type}
                                                </span>
                                            </td>
                                            <td>

                                                {each.direction === 1
                                                    ?
                                                    each.customer?.full_name || each.client?.full_name
                                                    :
                                                    each.direction === 2
                                                        ?
                                                        <Link to={'/relationships/' + each.customer.id}>
                                                            {each.customer?.full_name}
                                                        </Link>
                                                        :
                                                        each.direction === 3
                                                            ?
                                                            each.client?.full_name
                                                            :
                                                            each.client?.full_name

                                                }
                                            </td>
                                            {props.showReservation &&
                                                <>
                                                    <td>
                                                        {each.contractor
                                                            ?
                                                            <Link to={'/contractors/' + each.contractor.id}>
                                                                {each.contractor.name}
                                                            </Link>
                                                            :
                                                            ''
                                                        }
                                                    </td>
                                                    <td>
                                                        {each.reservation
                                                            ?
                                                            <Link
                                                                to={'/reservations/edit/' + each.reservation_id}
                                                                className="btn"
                                                                style={{ background: each.reservation.color, color: each.reservation.text_color }}
                                                            >
                                                                {each.reservation.reference_code}
                                                            </Link>
                                                            :
                                                            ''
                                                        }
                                                    </td>
                                                </>
                                            }
                                            <td>
                                                {each.net_price_formatted} {each.currency && each.currency.name}
                                            </td>
                                            <td>{each.vat_price_formatted} {each.currency && each.currency.name}</td>
                                            <td>{each.total_gross_price_formatted} {each.currency && each.currency.name}</td>
                                            <td className="center">
                                                {each.signed_at_dmy || '-'}
                                            </td>
                                            <td className="center">
                                                <label className="badge">{Number(each.sent)}</label>
                                                {each.sent
                                                    ?
                                                    <span style={{ marginLeft: '5px' }}>{each.sent_at_dmy}</span>
                                                    :
                                                    ''
                                                }
                                            </td>
                                            <td className="todo-check">
                                                {each.is_paid
                                                    ?
                                                    <>
                                                        <img src={checkIcon} alt="completed" />
                                                        <span>{each.paid_at_dmy}</span>
                                                        {each.paymentmethod
                                                            ?
                                                            <span style={{ display: 'block', fontSize: '11px' }}>
                                                                {each.paymentmethod.translation ? each.paymentmethod.translation.name : each.paymentmethod.name}
                                                            </span>
                                                            :
                                                            ''
                                                        }
                                                    </>
                                                    :
                                                    ''}
                                            </td>
                                            <td>
                                                <a href={each.signed_url} target="_blank">
                                                    {trans('buttons.view')}
                                                </a>
                                            </td>
                                            {props.canModify &&
                                                <td className="options">
                                                    <InvoiceOptions
                                                        invoice={each}
                                                        refreshData={props.refreshData}
                                                        handleDelete={props.handleDelete}
                                                    />
                                                </td>
                                            }
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>

                        <br />
                        {props.canModify &&
                            <div className="actions wrapper">
                                <select
                                    ref={selectRef}
                                >
                                    <option></option>
                                    <option value="send">
                                        {trans('random.sentToClien')}
                                    </option>
                                    {props.showPaidAll &&
                                        <option value="mark-as-paid">
                                            {trans('random.markAllAsPaid')}
                                        </option>
                                    }
                                    {props.showUnpaidAll &&
                                        <option value="mark-as-unpaid">
                                            {trans('random.markAllAsUnpaid')}
                                        </option>
                                    }
                                </select>
                                <button
                                    onClick={handleAction}
                                >
                                    {trans('buttons.applyForSelected')}
                                </button>
                            </div>
                        }
                    </>

            }
        </>
    )
}

export default InvoicesTable;