

import React, { useState, useEffect, useRef } from 'react';
import '../../../assets/css/settings.css';
import Api from '../../../_helpers/Api';
import { trans } from '../../../_providers/Translation';

import Preferences from './settings/Preferences';
import Notifications from './settings/Notifications';
import Auth from './settings/Auth';
import { useAuthDataContext } from '../../../_providers/Auth';

function Settings(props) {

    const auth = useAuthDataContext();

    const tabs = [
        {
            id: 'auth',
            name: trans('tabs.login'),
            component: Auth
        },
        {
            id: 'preferences',
            name: trans('tabs.preferences'),
            component: Preferences,
            access: auth.isAdmin()
        },
        {
            id: 'notifications',
            name: trans('tabs.notifications'),
            component: Notifications
        },
    ];

    const [state, setState] = useState({
        tab: tabs[0].id,
        index: 0,
        loading: true,
        data: [],
        update: false,
    });

    useEffect(() => {
        loadSettings();
    }, [state.update]);

    const loadSettings = () => {
        Api.get('profiles/settings/all')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleUpdate = settings => {
        setState(prev => ({
            ...prev,
            data: settings
        }));

        auth.setSettings(settings);
    }

    const handleTab = id => {

        let index = findTabIndex(id);

        setState(prev => ({
            ...prev,
            tab: id,
            index: index
        }));
    }

    const findTabIndex = id => {
        let index = tabs.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    const renderProps = {
        data: state.data,
        loading: state.loading,
        handleUpdate: handleUpdate
    };


    return (

        <div className="wrapper">
            <div className="top-part">
                <div className="left">
                    <h1 className="page-heading">
                        {trans('headings.profile')}
                    </h1>
                    <p className="page-subheading">
                        {trans('subheadings.profile')}
                    </p>
                </div>
                <div className="right">

                </div>
            </div>

            <div id="settings">

                <div className="tabs">
                    {tabs.filter(t => {
                        return typeof t.access === 'undefined' || t.access
                    }).map(t =>
                        <div
                            key={'tab-' + t.id}
                            className={['tab', state.tab === t.id ? 'active' : ''].join(' ')}
                            onClick={() => handleTab(t.id)}
                        >
                            {t.name}
                        </div>
                    )}
                </div>
                {React.createElement(
                    tabs[state.index].component,
                    renderProps,
                )}
            </div>

        </div>

    )
}

export default Settings;