import React from 'react'
import { trans } from '../../../../_providers/Translation';
import Help from '../../../partials/Help';

const Filter = (props) => {

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    return (
        <>
            <form className="filter-standard">
                <div className='col'>
                    <label>{trans('labels.name')}</label>
                    <input
                        type='search'
                        name='name'
                        onChange={handleSearch}
                        onKeyUp={handleSearch}
                        value={props.filter.name}
                    />
                </div>
                <div className='col'>
                    <label>{trans('labels.type')}</label>
                    <select
                        onChange={handleSearch}
                        name='type_id'
                        value={props.filter.type_id}
                    >
                        <option value=''></option>
                        {props.types.map(i => (
                            <option key={'type-' + i.id} value={i.id}>{i?.translation?.name || i.name}</option>
                        ))}
                    </select>
                </div>
            </form>
        </>
    )
}
export default Filter;