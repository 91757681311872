import React, { useEffect, useState } from 'react';
import Options from './Options';
import loader from '../../../../assets/img/loader.svg';
import { Link } from 'react-router-dom';
import notFound from '../../../../assets/img/not-found.png'
import Skeleton from 'react-loading-skeleton';
import Api from '../../../../_helpers/Api';
import checkIcon from '../../../../assets/img/icons/check.png';
import ReactTooltip from 'react-tooltip';
import { trans } from '../../../../_providers/Translation'
import { useNotificationsContext } from '../../../../_providers/Notifications';

function TableReturned(props) {

    const notifications = useNotificationsContext();

    const [parts, setParts] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {

        },
        update: true,
    });

    useEffect(() => {
        if (parts.update) {
            loadParts();
        }
    }, [parts.update])

    const loadParts = () => {

        let url = 'storage/returned-stock-parts?page=' + parts.page;

        Object.entries(parts.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        setParts(prev => ({
            ...prev,
            loading: true
        }));

        Api.get(url)
            .then(res => {
                setParts(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }));
            }).catch(err => {
                setParts(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const accept = id => {
        Api.post('storage/accept-returned-stock-part', {
            id: id
        }).then(res => {
            if (res.data.success) {
                setParts(prev => ({
                    ...prev,
                    update: new Date()
                }));

                props.onAccept();
            }
        }).catch(err => {
            notifications.add('Нещо се обърка!');
        });
    }

    return (

        parts.data.length > 0
        &&
        <>
            <h3>
                {trans('headings.returnedParts')}
            </h3>

            <div className="table-scroller" style={{ marginBottom: '20px' }}>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>{trans('tables.name')}</th>
                            <th>{trans('tables.partNumber')}</th>
                            <th className="center">{trans('tables.quantity')}</th>
                            <th>{trans('tables.storage')}</th>
                            {/* <th>{trans('tables.row')}</th> */}
                            {/* <th>{trans('tables.box')}</th> */}
                            <th>{trans('tables.category')}</th>
                            <th>{trans('tables.grossPrice')}</th>
                            <th>{trans('tables.date')}</th>
                            <th>{trans('tables.comment')}</th>
                            <th className="center">{trans('tables.image')}</th>
                            <th className="options">{trans('tables.options')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {parts.data.map(p =>
                            <tr key={'p-' + p.id}>
                                <td>
                                    {p.id}
                                </td>
                                {/* <td>{p.part.category.name}</td> */}

                                <td>
                                    {p.part.name}
                                </td>

                                <td>
                                    {p.part.number}
                                </td>
                                <td className="center">
                                    {p.quantity}
                                </td>

                                <td>
                                    {p.storage?.name}
                                </td>
                                {/* <td>
                                    {p.shelve}
                                </td>
                                <td>
                                    {p.box}
                                </td> */}

                                <td>
                                    {p.part.category
                                        ?
                                        p.part.category.name
                                        :
                                        ''
                                    }
                                </td>

                                <td>{p.gross_price_format} {p.currency.name}</td>

                                <td>{p.given_date_dmy}</td>

                                <td>{p.return_comment}</td>

                                <td className="center">
                                    <img data-tip data-for={'img-' + p.id} src={p.picture} alt="" height={25} />
                                    <ReactTooltip
                                        id={'img-' + p.id}
                                        effect="solid"
                                        border={true}
                                        borderColor="#ccc"
                                        backgroundColor="white"
                                        arrowColor="white"
                                    >
                                        <img src={p.picture} alt="" style={{ maxHeight: '200px' }} />
                                    </ReactTooltip>
                                </td>

                                <td>
                                    <button className="button-primary button-small" onClick={e => accept(p.id)}>
                                        Приеми
                                    </button>
                                </td>

                                {/* <td>
                                    <Options
                                        id={p.id}
                                        deletable={p.deletable}
                                        editable={p.editable}
                                        transferable={p.transferable}
                                        canAcceptTransfer={p.can_accept_transfer}
                                        handleEdit={props.handleEdit}
                                        handleDelete={props.handleDelete}
                                        handleTransfer={props.handleTransfer}
                                        handleHistory={props.handleHistory}
                                    />
                                </td> */}
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>


        </>
    )
}

export default TableReturned;