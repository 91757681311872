import React, { useState, useImperativeHandle, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import { useAuthDataContext } from '../../../../_providers/Auth';
import Dropdown from '../../../partials/Dropdown';

function AddExisting(props, ref) {

    const auth = useAuthDataContext();

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: null,
        loading: false,
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: []
            }));
            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                data: null,
                loading: false
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            data: [],
            checked: false,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();


        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'partners/relationships/add';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                if (res.data.success) {
                    props.handleCreate();
                }

                handleHide(e);

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary medium`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {trans('headings.addingPartner')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container">
                    <div className="col">

                        <label htmlFor="name">
                            {trans('labels.name')}
                        </label>

                        <Dropdown
                            inputClassName="big"
                            inputPlaceholder="Търсене по име"
                            inputIdName="related_partner_id"
                            url="partners/relationships/find"
                            renderInputText={data => {
                                return data.name;
                            }}
                            renderText={data => {
                                return (
                                    <table className="transparent" style={{ fontSize: '14px', borderSpacing: '5px' }}>
                                        <tr className="x2">
                                            <td>
                                                {trans('tables.name')}:
                                            </td>
                                            <td>
                                                <b>{data.name}</b>
                                            </td>
                                        </tr>
                                        <tr className="x2">
                                            <td>
                                                {trans('tables.eik')}:
                                            </td>
                                            <td>
                                                {data.eik}
                                            </td>
                                        </tr>
                                        <tr className="x2">
                                            <td>
                                                {trans('tables.address')}:
                                            </td>
                                            <td>
                                                {data.address}
                                            </td>
                                        </tr>
                                        <tr className="x2">
                                            <td>
                                                {trans('tables.phone')}:
                                            </td>
                                            <td>
                                                {data.phone}
                                            </td>
                                        </tr>
                                        <tr className="x2">
                                            <td>
                                                {trans('tables.branch')}:
                                            </td>
                                            <td>
                                                {data.branch
                                                    ?
                                                    data.branch.translation ? data.branch.translation.name : ''
                                                    :
                                                    ''
                                                }
                                            </td>
                                        </tr>
                                    </table>
                                )
                            }
                            }
                        />
                    </div>

                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddExisting);