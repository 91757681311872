import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import Api from '../../../../../_helpers/Api';
import { ItemTypes } from './ItemTypes'
import Options from './StorageStatusesOptions';

const style = {
	padding: '0.5rem 1rem',
	marginBottom: '.5rem',
	backgroundColor: 'white',
	cursor: 'grab',
}

export const StorageStatusRow = (props) => {
	let index = props.index
	let t = props.data
	let id = props.id
	let data = props.state

	const handleChange = () => {
		let ids = [];

		data.map(c => {
			ids.push(c.id)
		});

		Api.post('storage/statuses/set-positions', { ids: ids });
	}

	const ref = useRef(null)

	const [, drop] = useDrop({
		accept: ItemTypes.CARD,
		drop(item) {
			handleChange()
		},
		hover(item, monitor) {

			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			// Time to actually perform the action
			props.moveCard(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex
		},
	})

	const [{ isDragging }, drag] = useDrag({
		item: { type: ItemTypes.CARD, id, index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

	const opacity = isDragging ? 0 : 1

	drag(drop(ref))

	return (
		<tr ref={ref} key={t.id} style={{ ...style, opacity }}>
			<td style={{ width: '100%' }}>
				<span className="color" style={{ background: t.color }}></span>
				{/* {t.translation ? t.translation.name : t.name} */}
				{t ? t.name : ''}
			</td>
			{/* <td>
				{t.checkbox ? 'Чекбокс' : 'Радио бутон'}
			</td>
			<td>
				{t.checkbox
					? 'Може да се избира комбинирано с други статуси'
					: 'Може да бъде избран само 1 статус от този тип'
				}
			</td> */}
			<td className="center">
				{t.editable &&
					<Options
						id={t.id}
						handleEdit={() => props.handleEdit(t.id)}
						handleDelete={() => props.handleDelete(t.id)}
					/>}
			</td>
		</tr>
	)
}
