import React, { useEffect, useState, useRef } from 'react';
import { trans } from '../../../_providers/Translation';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import Api from '../../../_helpers/Api';
import loader from '../../../assets/img/loader.svg';
import FilterTabs from './partials/FilterTabs';
import Pagination from '../../Pagination';
import AddOrEdit from './partials/AddOrEditCategory';
import Options from './partials/CategoryOptions';
import Question from '../../modals/messages/Question';
import NoDataFound from '../../partials/NoDataFound';

let timeout;

function Categories() {

    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const modalAddOrEditRef = useRef(null)
    const modalQuestionRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: searchParams.get('page') || 1,
        filter: {},
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            // filter: {
            //     ...prev.filter,
            // },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);


    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'expenses/categories/all?page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    pages: res.data.pages,
                    total: res.data.total,
                    loading: false
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add()
    }

    const handleCreate = () => {
        setState(prev => ({
            ...prev,
            update: Math.random().toString()
        }))
    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id)
    }

    const handleUpdate = row => {

        let updated = state.data.map(i => {
            if (i.id == row.id) {
                i = row
            }
            return i
        });

        setState(prev => ({
            ...prev,
            data: updated,
        }))
    }


    // delete
    const handleDestroy = id => {

        let url = 'expenses/categories/delete';

        Api.post(url, {
            id: id
        }).then(res => {
            if (res.data.success) {
                // let filtered = state.data.filter(i => {
                //     return i.id !== id
                // })

                setState(prev => ({
                    ...prev,
                    // data: filtered
                    setFilter: new Date().getTime()
                }))

            }
        })
    }

    // Question modal
    const handleDelete = id => {
        modalQuestionRef.current.open(id);

        modalQuestionRef.current.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleSearch = (key, val, delay = 300) => {

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
                setFilter: new Date().getTime()
            }));
        }, delay);

    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }))
    }

    return (
        <>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.question11')}
            />

            <div className="wrapper">

                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.expenses')}
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.expenseCategories')}
                        </p>
                    </div>
                    <div className="right">
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.add')}
                        </button>
                    </div>
                </div>

                <FilterTabs
                    tab="categories"
                />

                {state.loading
                    ?
                    <img className="loader" src={loader} />
                    :
                    <>
                        {state.data.length === 0
                            ?
                            <NoDataFound />
                            :
                            <>
                                <div className="table-scroller">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="color">
                                                    {trans('tables.color')}
                                                </th>
                                                <th>
                                                    {trans('tables.name')}
                                                </th>
                                                <th className="options">
                                                    {trans('tables.options')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.data.map(c =>
                                                <tr key={'c-' + c.id}>
                                                    <td className="color">
                                                        <span className="color" style={{
                                                            background: c.color
                                                        }}></span>
                                                    </td>
                                                    <td>
                                                        {c.name}
                                                    </td>
                                                    <td className="options">
                                                        <Options
                                                            id={c.id}
                                                            handleEdit={handleEdit}
                                                            handleDelete={handleDelete}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    pages={state.pages}
                                    page={state.page}
                                    handlePage={handlePage}
                                />
                            </>
                        }

                    </>
                }
            </div>
        </>
    )
}

export default Categories;