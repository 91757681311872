import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../../../partials/NoDataFound';
import Details from './BonusPolicyResultDetails';
import { plural, trans } from '../../../../_providers/Translation';

function BonusPolicyResult(props, ref) {

    const detailsModalRef = useRef(null);

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        id: null,
        periodId: null,
        data: [],
        policy: null,
        currency: null,
        loading: true,
        onClose: null,
    });

    useImperativeHandle(ref, () => ({
        open: (id, periodId) => {
            handleShow(id, periodId);
        },
        hide: () => {
            handleHide();
        },
        onClose: fn => {
            setPopup(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [popup.id, popup.periodId]);

    const loadData = () => {

        if (!popup.id || !popup.periodId) {
            return;
        }

        setPopup(prev => ({
            ...prev,
            loading: true
        }));

        Api.get('partners/bonuspolicies/results?id=' + popup.id + '&period_id=' + popup.periodId)
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    data: res.data.data || [],
                    policy: res.data.policy,
                    currency: res.data.currency
                }))
            })
            .finally(() => {
                setPopup(prev => ({
                    ...prev,
                    loading: false
                }));
            })
    }

    const handleShow = (id, periodId) => {

        setPopup(prev => ({
            ...prev,
            id: id,
            periodId: periodId
        }));

        show();
    }

    const handleHide = e => {
        if (e) {
            e.preventDefault();
        }

        hide();

        if (typeof popup.onClose === 'function') {
            popup.onClose();
        }
    }

    const show = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const hide = () => {
        setPopup(prev => ({
            ...prev,
            modal: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false,
            }));
        }, 50);
    }

    const handleShowResultDetails = (employeeId) => {
        hide();

        let modal = detailsModalRef.current;

        modal.open(popup.id, popup.periodId, employeeId);

        modal.onClose(() => {
            show();
        })
    }

    return (
        <>
            <Details
                ref={detailsModalRef}
            />
            <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
                <div className={`${popup.modal ? 'show' : ''} popup-primary medium`}>
                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />
                        {trans('headings.bonusPolicyResults')}
                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>

                    {popup.loading
                        ?
                        <Skeleton height={45} count={5} />
                        :

                        popup.data.length === 0
                            ?
                            <NoDataFound />
                            :
                            <div className="popup-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                {trans('labels.employee')}
                                            </th>
                                            <th className="center">
                                                {Number(popup.policy.type_id) === 1
                                                    ?
                                                    trans('labels.pointsCount')
                                                    :
                                                    trans('labels.completedReservationsCount')
                                                }
                                            </th>
                                            <th className="right">
                                                {trans('labels.reward')}
                                            </th>
                                            <th className="center">
                                                {trans('labels.details')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {popup.data.map(r =>
                                            <tr key={r.id}>
                                                <td>
                                                    {r.name} {r.lastname}
                                                </td>
                                                <td className="center">
                                                    {r.points || r.completed_reservations}
                                                </td>
                                                <td className="right">
                                                    {r.reward || '-'} {popup.currency && popup.currency.name}
                                                </td>
                                                <td className="center">
                                                    <a onClick={() => handleShowResultDetails(r.id)} style={{ cursor: 'pointer' }}>
                                                        {trans('labels.details')}
                                                    </a>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                    }

                    <div className="footer align-center">
                        <button className="button" onClick={handleHide}>
                            {trans('buttons.close')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(BonusPolicyResult);