import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Api from '../../_helpers/Api';
import { trans } from '../../_providers/Translation';

function InvoiceSign(props, ref) {

    const padRef = useRef(null);

    const [state, setState] = useState({
        id: null,
        overlay: false,
        modal: false,
        onSuccess: null,
    });

    useImperativeHandle(ref, () => ({
        open: id => {
            handleOpen(id)
        },
        close: () => {
            handleClose()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }));

    const handleOpen = (id) => {

        setState(prev => ({
            ...prev,
            id: id,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)

    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            id: null,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 50)

        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    }

    const handleClear = () => {
        let pad = padRef.current;

        pad.clear();
    }

    const handleSign = () => {
        let pad = padRef.current;

        let image = pad.toDataURL();

        console.log(image);

        Api.post('invoices/sign', {
            id: state.id,
            sign: image
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data);
            }
        });
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <div className={`${state.modal ? 'show' : ''} popup-primary medium`}>
                <SignatureCanvas
                    ref={padRef}
                    penColor='black'
                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                />

                <div className="footer align-center">
                    <button onClick={handleClose}>
                        {trans('buttons.cancel')}
                    </button>
                    <button onClick={handleClear}>
                        {trans('buttons.clear')}
                    </button>
                    <button className="button button-primary" onClick={handleSign}>
                        {trans('buttons.sign')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(InvoiceSign);