import React, { useRef, useState, useEffect } from 'react';
import { useAuthDataContext } from '../../../../../_providers/Auth';
import Api from '../../../../../_helpers/Api';
import editIcon from '../../../../../assets/img/icons/edit-yellow.png';
import Modal from './modals/Pricing';
import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../../../_providers/Translation';
import { Link, useHistory } from 'react-router-dom';

function Pricing(props) {

    const history = useHistory();
    const auth = useAuthDataContext();

    const modalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: {},
        labourPrices: {},
        update: false,
    });

    useEffect(() => {
        loadSettings();
    }, [state.update]);

    const loadSettings = () => {
        Api.get('partners/settings/all?id=' + auth.partnerId())
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data,
                    labourPrices: {}
                }));

                Api.get('partners/categories/prices')
                    .then(res => {
                        setState(prev => ({
                            ...prev,
                            labourPrices: res.data
                        }));
                    });
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });

    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const showModal = () => {
        let modal = modalRef.current;

        modal.show(state.data);

        modal.onSave(() => {
            handleUpdate();

            if (typeof props.handleRefresh === 'function') {
                props.handleRefresh();
            }
        });
    }

    const getPartsPriceProfitThreshold = () => {
        return state.data.parts_price_profit_threshold;
    }

    const hasPartsPriceProfitThreshold = () => {
        if (getPartsPriceProfitThreshold() !== null) {
            return true
        }

        return false;
    }

    return (
        <div style={{ width: '100%' }}>

            <div className="head">
                <h4>{trans('headings.pricing')}</h4>
                <a onClick={showModal}>
                    <img src={editIcon} alt="" />
                </a>
            </div>

            {state.loading
                ?
                <Skeleton height={35} count={10} />
                :
                <>
                    <Modal
                        ref={modalRef}
                    />

                    <div className="alert info">
                        <Link to={'/company?tab=settings&sub=categories'}>
                            {trans('settings.pricingInfo')}
                        </Link>
                    </div>

                    <div className="table-scroller">
                        <table className="settings">
                            <tbody>
                                <tr>
                                    <td>
                                        <b>{trans('labels.currency')}</b>
                                    </td>
                                    <td>
                                        {state.data.currency ? state.data.currency.name : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.dds')} %</b>
                                    </td>
                                    <td>
                                        {state.data.vat ? state.data.vat : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.incomeTax')} %</b>
                                    </td>
                                    <td>
                                        {state.data.profit_tax ? state.data.profit_tax : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                {hasPartsPriceProfitThreshold() &&
                                    <>
                                        <tr>
                                            <td>
                                                <b>{trans('labels.partsPriceProfitThreshold')}</b>
                                            </td>
                                            <td>
                                                {state.data?.parts_price_profit_threshold ? state.data.parts_price_profit_threshold : trans('labels.notSpecified')}
                                            </td>
                                            <td>
                                                {trans('random.partsPriceProfitThresholdInfo')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>{trans('labels.partsPriceProfitBelow', {
                                                    price: getPartsPriceProfitThreshold() + ' ' + state.data?.currency?.name
                                                })}</b>
                                            </td>
                                            <td>
                                                {state.data?.parts_price_profit_below_threshold ? state.data.parts_price_profit_below_threshold + '%' : trans('labels.notSpecified')}
                                            </td>
                                            <td>
                                                {trans('random.partsPriceProfitInfo')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>{trans('labels.partsPriceProfitAbove', {
                                                    price: getPartsPriceProfitThreshold() + ' ' + state.data?.currency?.name
                                                })}</b>
                                            </td>
                                            <td>
                                                {state.data?.parts_price_profit_above_threshold ? state.data.parts_price_profit_above_threshold + '%' : trans('labels.notSpecified')}
                                            </td>
                                            <td>
                                                {trans('random.partsPriceProfitInfo')}
                                            </td>
                                        </tr>
                                    </>
                                }
                                <tr>
                                    <td>
                                        <b>{trans('labels.transportPriceType')}</b>
                                    </td>
                                    <td>
                                        {state.data.transportpricetype ? state.data.transportpricetype.translation.name : trans('labels.notSpecified')}
                                    </td>
                                    <td>
                                        {state.data.transportpricetype ? state.data.transportpricetype.translation.description : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="indent">
                                        {state.data?.transportpricetype?.is_fixed ? trans('labels.transportPriceFixedPrice') : trans('labels.transportPriceStandard')}
                                    </td>
                                    <td>
                                        {state.data.transport_price ? state.data.transport_price : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.labourPriceType')}</b>
                                    </td>
                                    <td>
                                        {state.data.labourpricetype ? state.data.labourpricetype.translation.name : trans('labels.notSpecified')}
                                    </td>
                                    <td>
                                        {state.data.labourpricetype ? state.data.labourpricetype.translation.description : ''}
                                    </td>
                                </tr>
                                {/* {state.data.labourpricetype
                                    ?

                                    <>
                                        {Object.values(state.labourPrices).length && Object.values(state.labourPrices).map((p, i) =>
                                            <React.Fragment key={'lp-' + i}>
                                                <tr>
                                                    <td className="indent">
                                                        {p.price_type.translation && p.price_type.translation.name}
                                                    </td>
                                                    <td>
                                                        {p.default_formatted}
                                                    </td>
                                                    <td>
                                                        {p.price_type.translation && p.price_type.translation.description}
                                                    </td>
                                                </tr>
                                                {p.categories.length && p.categories.filter(c => c.price > 0).length
                                                    ?
                                                    <tr>
                                                        <td style={{ paddingLeft: '40px' }}>
                                                            {trans('labels.category')}
                                                        </td>
                                                        <td>
                                                            {trans('labels.price')}
                                                        </td>
                                                        <td>

                                                        </td>
                                                    </tr>
                                                    :
                                                    ''
                                                }
                                                {p.categories && p.categories.map(c =>
                                                    c.price > 0
                                                        ?
                                                        <React.Fragment key={'lpc-' + c.id}>
                                                            <tr>
                                                                <td style={{ paddingLeft: '40px' }}>
                                                                    {c?.translation?.name || c.name}
                                                                </td>
                                                                <td>
                                                                    {c.price_formatted}
                                                                </td>
                                                                <td>

                                                                </td>
                                                            </tr>
                                                            {c.brands_prices && c.brands_prices.map(b =>
                                                                <tr key={'lpb-' + b.id}>
                                                                    <td style={{ paddingLeft: '60px' }}>
                                                                        {b.brand && b.brand.name}
                                                                    </td>
                                                                    <td>
                                                                        {b.price_formatted}
                                                                    </td>
                                                                    <td>

                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </React.Fragment>
                                                        :
                                                        ''
                                                )}
                                            </React.Fragment>
                                        )}

                                    </>
                                    :
                                    ''
                                } */}
                                <tr>
                                    <td>
                                        <b>{trans('labels.recharge')}</b>
                                    </td>
                                    <td>
                                        {state.data.recharge ? trans('labels.yes') : trans('labels.no')}
                                    </td>
                                    <td>
                                        {trans('random.rechargeInfo')}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </>
            }
        </div>
    )

}

export default Pricing