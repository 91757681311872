import React from 'react';
import Options from './Options';
import loader from '../../../../assets/img/loader.svg';
import { trans } from '../../../../_providers/Translation';

function Table(props) {

    return (
        <>

            <div className="table-scroller">
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '50px' }}>ID</th>
                            <th>{trans('labels.name')}</th>
                            <th className="options">{trans('labels.options')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map(user =>
                            <tr key={'user-' + user.id}>
                                <td style={{ width: '50px' }}>
                                    {user.id}
                                </td>
                                <td>
                                    {user.name} {user.lastname}
                                </td>
                                <td>
                                    <Options
                                        id={user.id}
                                        handleEdit={props.handleEdit}
                                        handleDelete={props.handleDelete}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        </>
    )
}

export default Table;