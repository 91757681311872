import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import Dropdown from '../../../partials/Dropdown';
import { trans } from '../../../../_providers/Translation'
import { useAuthDataContext } from '../../../../_providers/Auth';

let timeout;

function AddOrEdit(props, ref) {

    const auth = useAuthDataContext();

    const initialPopupData = {
        part: {
            category_id: null
        },
        profit: 0,
        position_id: 1,
        shouldSelectPosition: false,
        shouldSelectVan: false,
        shouldSelectShelveAndBox: false,
        shouldSelectInStock: false,
        shouldSelectStorage: false,
        can_modify_category: true,
    };

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: initialPopupData,
        key: 1,
        loading: false,
    });

    const [vans, setVans] = useState([]);
    const [positions, setPositions] = useState([]);

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: stock => {

            let data = initialPopupData;

            if (stock === 'van') {
                data.shouldSelectVan = true;
            } else {
                data.shouldSelectShelveAndBox = true;
                data.shouldSelectInStock = true;
                data.shouldSelectStorage = true;
            }

            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: data,
                key: Math.random().toString()
            }));

            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                data: initialPopupData,
                loading: false
            }));

            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    useEffect(() => {
        loadVans();
        loadPositions();
    }, []);

    useEffect(() => {
        console.log(popup.data);
    }, [popup.data]);

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {

            Api.get(`storage/find-part?id=${popup.editId}`)
                .then(res => {

                    let data = res.data;

                    if (res.data.office_stock) {
                        data.shouldSelectPosition = true;
                    }

                    if (data.shouldSelectPosition) {

                        if (res.data.office_stock) {
                            data.shouldSelectShelveAndBox = true;
                            data.shouldSelectInStock = true;
                            data.shouldSelectStorage = true;
                        } else if (res.data.van_stock) {
                            data.shouldSelectVan = true;
                        }

                    }

                    setPopup(prev => ({
                        ...prev,
                        data: data,
                    }));
                });
        }

    }, [popup.edit]);

    useEffect(() => {
        let diff = Number(popup.data.price) - Number(popup.data.delivery_price);

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                profit: diff.toFixed(2)
            }
        }));

    }, [popup.data.delivery_price, popup.data.price]);

    const loadVans = () => {
        let url = 'vans/all?paginate=0';

        Api.get(url)
            .then(res => {
                setVans(res.data);
            });
    }

    const loadPositions = () => {
        let url = 'storage/positions';

        Api.get(url)
            .then(res => {
                setPositions(res.data);
            });
    }

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'storage/edit-part';
        } else {
            url = 'storage/add-part';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    if (typeof props.handleUpdate === 'function') {
                        props.handleUpdate(res.data);
                    }
                } else {
                    if (typeof props.handleCreate === 'function') {
                        props.handleCreate(res.data);
                    }
                }

                handleHide(e);

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {
                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleCategory = id => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                part: {
                    ...prev.data.part,
                    category_id: id
                }
            }
        }));
    }

    const handlePartChange = data => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                part: {
                    ...prev.data.part,
                    number: data.number || ''
                }
            }
        }));
    }

    const handlePositionChange = e => {
        let val = Number(e.target.value);

        let selected = positions.filter(p => p.id === val)[0];
        let shouldSelectVan = selected.name === 'van';
        let shouldSelectShelveAndBox = selected.name === 'office';
        let shouldSelectInStock = selected.name === 'office';
        let shouldSelectStorage = selected.name === 'office';

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                position_id: val,
                shouldSelectVan: shouldSelectVan,
                shouldSelectShelveAndBox: shouldSelectShelveAndBox,
                shouldSelectInStock: shouldSelectInStock,
                shouldSelectStorage: shouldSelectStorage
            }
        }))
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handlePartInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                part: {
                    ...prev.data.part,
                    [name]: val
                }
            }
        }));
    }

    const handleCheckboxChange = e => {
        let name = e.target.name;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: !prev.data[name]
            }
        }));
    }


    const handleVanChange = e => {
        let val = e.target.value;

        let van = vans.filter(v => Number(v.id) === Number(val))[0];

        if (van) {
            setPopup(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    van_id: van.id,
                    employee_id: van.employee_id
                }
            }));
        }

    }

    const handleStatusChange = id => {

        let statuses = props.statuses;
        let status = statuses.filter(e => e.id === id)[0];
        let selected = popup.data.selectedStatuses;

        if (status) {

            let exists = selected[id] ? true : false;

            // ако е чекбокс
            if (status.checkbox) {
                if (exists) {
                    delete selected[id];
                } else {
                    selected[id] = 1;
                }
            }
            // ако е радио
            else {
                statuses.filter(e => e.checkbox === 0 && e.id != id).map(e => {
                    if (selected[e.id]) {
                        delete selected[e.id];
                    }
                });

                if (exists) {
                    delete selected[id];
                } else {
                    selected[id] = 1;
                }
            }

            // console.log(selected);

            setPopup(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    selectedStatuses: selected
                }
            }))
        }

    }

    const handleDeliveryPriceChange = e => {
        let deliveryPrice = e.target.value;
        let price = null;
        let profit = null;

        let profitThreshold = getPartsPriceProfitThreshold();

        if (profitThreshold) {
            if (deliveryPrice < profitThreshold) {
                profit = getPartsPriceProfitBelowThreshold();
            } else if (deliveryPrice >= profitThreshold) {
                profit = getPartsPriceProfitAboveThreshold();
            }
        }

        if (profit) {
            price = calcPrice(deliveryPrice, profit);
        }

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                delivery_price: deliveryPrice,
                price: price !== null ? price : prev.data.price,
            }
        }));
    }

    const getPartsPriceProfitThreshold = () => {
        return auth.partner().settings.parts_price_profit_threshold;
    }

    const getPartsPriceProfitBelowThreshold = () => {
        return auth.partner().settings.parts_price_profit_below_threshold;
    }

    const getPartsPriceProfitAboveThreshold = () => {
        return auth.partner().settings.parts_price_profit_above_threshold;
    }

    const calcPrice = (price, profit) => {
        return (Number(price) + ((Number(profit) * Number(price)) / 100)).toFixed(2);
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary large`} autoComplete="off" onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {popup.edit ? trans('headings.editPart') : trans('headings.addPart2')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="scrollable" style={{ maxHeight: '70vh' }}>
                    <div className="popup-container x3">
                        <div className="col">
                            <label htmlFor="category_id">
                                {trans('labels.category')}
                            </label>
                            <Dropdown
                                key={popup.key}
                                inputName="category"
                                inputIdName="category_id"
                                inputValue={popup.data && popup.data.part && popup.data.part.category ? popup.data.part.category.name : ''}
                                inputIdValue={popup.data && popup.data.part ? popup.data.part.category_id : ''}
                                inputClassName={validations && validations.category_id ? 'validate' : ''}
                                url="partners/categories/all"
                                renderText={data => {
                                    return data.name
                                }}
                                onChange={data => {
                                    handleCategory(data.id)
                                }}
                                inputDisabled={popup.data && !popup.data.can_modify_category}
                            />
                        </div>

                        <div className="col">
                            <label htmlFor="">
                                {trans('labels.name')}
                            </label>
                            <Dropdown
                                key={popup.key}
                                inputName="name"
                                inputIdName="part_id"
                                inputValue={popup.data && popup.data.part && popup.data.part.name ? popup.data.part.name : ''}
                                inputIdValue={popup.data && popup.data.part_id ? popup.data.part_id : ''}
                                inputClassName={validations && validations.name ? 'validate' : ''}
                                inputPlaceholder="Търсене по име или номер"
                                url="parts/search"
                                params={{
                                    category_id: popup.data.part.category_id,
                                }}
                                requiredParams={['category_id']}
                                loadDataOnFocus={true}
                                renderInputText={data => {
                                    return data.name
                                }}
                                renderText={data => {
                                    return <div>{data.name} <br /> <span style={{ fontSize: '10px' }}>{data.number}</span></div>
                                }}
                                onChange={handlePartChange}
                            />
                        </div>

                        <div className="col">
                            <label>
                                {trans('labels.number')}
                            </label>
                            <input
                                type="text"
                                name="number"
                                className={validations && validations.number ? 'validate' : ''}
                                value={popup.data && popup.data.part && popup.data.part.number ? popup.data.part.number : ''}
                                onChange={handlePartInputChange}
                            />
                        </div>


                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label htmlFor="image">
                                {trans('labels.image')}
                            </label>
                            <input type="file" name="image" accept="image/*" />
                        </div>

                    </div>

                    <br />

                    <div className="popup-container x4">
                        <div className="col">
                            <label>
                                {trans('labels.supplier')}
                            </label>
                            <Dropdown
                                key={popup.key}
                                inputName="supplier"
                                inputIdName="supplier_id"
                                inputValue={popup.data && popup.data.supplier ? popup.data.supplier.name : ''}
                                inputIdValue={popup.data && popup.data.supplier_id ? popup.data.supplier_id : ''}
                                inputClassName={validations && validations.supplier_id ? 'validate' : ''}
                                url="suppliers/all"
                                renderText={data => {
                                    return data.name
                                }}

                            />
                        </div>

                        <div className="col">
                            <label>
                                {trans('labels.factureOfSupplier')}
                            </label>
                            <input
                                type="text"
                                name="po_number"
                                className={validations && validations.po_number ? 'validate' : ''}
                                value={popup.data && popup.data.po_number ? popup.data.po_number : ''}
                                onChange={handleInputChange}
                            />
                        </div>

                    </div>

                    <br />

                    <div className="popup-container x4">

                        <div className="col">
                            <label>
                                {trans('labels.deliveryPrice')}
                            </label>
                            <input
                                type="number"
                                min="0"
                                step="0.01"
                                name="delivery_price"
                                className={validations && validations.delivery_price ? 'validate' : ''}
                                value={popup.data && popup.data.delivery_price ? popup.data.delivery_price : ''}
                                onChange={handleDeliveryPriceChange}
                            />
                        </div>

                        <div className="col">
                            <label>
                                {trans('labels.salePrice')}
                            </label>
                            <input
                                type="number"
                                min="0"
                                step="0.01"
                                name="price"
                                className={validations && validations.price ? 'validate' : ''}
                                value={popup.data && popup.data.price ? popup.data.price : ''}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="col">
                            <label>
                                {trans('labels.profit')}
                            </label>
                            <input
                                type="number"
                                value={popup.data && popup.data.profit ? popup.data.profit : ''}
                                disabled
                            />
                        </div>

                        <div className="col">
                            <label>
                                {trans('labels.quantity')}
                            </label>
                            <input
                                type="number"
                                min="1"
                                step="1"
                                name="quantity"
                                className={validations && validations.quantity ? 'validate' : ''}
                                value={popup.data && popup.data.quantity ? popup.data.quantity : '1'}
                                onChange={handleInputChange}
                            />
                        </div>

                    </div>

                    {popup.data.shouldSelectPosition || popup.data.shouldSelectStorage || popup.data.shouldSelectShelveAndBox || popup.data.shouldSelectVan
                        ?
                        <>
                            <br />

                            <div className="popup-container x4">

                                {popup.data.shouldSelectPosition
                                    ?
                                    <div className="col">
                                        <label>{trans('labels.location')}</label>
                                        <select
                                            name="position_id"
                                            className={validations && validations.position_id ? 'validate' : ''}
                                            value={popup.data && popup.data.position_id}
                                            onChange={handlePositionChange}
                                        >
                                            {positions.map(p =>
                                                <option key={'position-' + p.id} value={p.id}>
                                                    {p.translation ? p.translation.name : p.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    :
                                    ''
                                }

                                {popup.data && popup.data.shouldSelectStorage
                                    ?
                                    <div className="col">
                                        <label>
                                            {trans('labels.storage')}
                                        </label>
                                        <Dropdown
                                            key={popup.key}
                                            inputName="storage"
                                            inputIdName="storage_id"
                                            inputValue={popup.data && popup.data.storage ? popup.data.storage.name : ''}
                                            inputIdValue={popup.data && popup.data.storage_id ? popup.data.storage_id : ''}
                                            inputClassName={validations && validations.storage_id ? 'validate' : ''}
                                            url="storages/all"
                                            renderText={data => {
                                                return data.name
                                            }}

                                        />
                                    </div>
                                    :
                                    ''
                                }

                                {popup.data && popup.data.shouldSelectShelveAndBox
                                    ?
                                    <>
                                        <div className="col">
                                            <label>
                                                {trans('labels.row')}
                                            </label>
                                            <input
                                                type="text"
                                                name="shelve"
                                                className={validations && validations.shelve ? 'validate' : ''}
                                                value={popup.data && popup.data.shelve ? popup.data.shelve : ''}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="col">
                                            <label>
                                                {trans('labels.box')}
                                            </label>
                                            <input
                                                type="text"
                                                name="box"
                                                className={validations && validations.box ? 'validate' : ''}
                                                value={popup.data && popup.data.box ? popup.data.box : ''}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </>
                                    :
                                    ''
                                }

                                {popup.data && popup.data.shouldSelectVan
                                    ?
                                    <div className="col">
                                        <label>{trans('labels.van')}</label>
                                        <select
                                            name="van_id"
                                            value={popup.data.van_id ? popup.data.van_id : ''}
                                            onChange={handleVanChange}
                                            className={validations && validations.van_id ? 'validate' : ''}
                                        >
                                            <option value="" disabled></option>
                                            {vans && vans.map(v => (
                                                <option key={'van-' + v.id} value={v.id}>
                                                    {v.reg_no} {v.employee && `(${v.employee.name} ${v.employee.lastname})`}
                                                </option>
                                            ))}
                                        </select>

                                        <input type="hidden" name="employee_id" value={popup.data.employee_id} />
                                    </div>
                                    :
                                    ''
                                }

                            </div>
                        </>
                        :
                        ''
                    }

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.comments')}
                            </label>
                            <input
                                type="text"
                                name="comment"
                                className={validations && validations.comment ? 'validate' : ''}
                                value={popup.data && popup.data.comment ? popup.data.comment : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.description')}
                            </label>
                            <textarea
                                name="description"
                                className={validations && validations.description ? 'validate' : ''}
                                value={popup.data && popup.data.description ? popup.data.description : ''}
                                onChange={handleInputChange}
                            >

                            </textarea>
                        </div>
                    </div>

                    <br />

                    {popup.data.shouldSelectInStock
                        ?
                        <label className="flex">
                            <input
                                type="checkbox"
                                name="in_stock"
                                value="1"
                                checked={popup.data && popup.data.in_stock ? true : false}
                                onChange={handleCheckboxChange}
                            />
                            <span>
                                {trans('random.inStockSec')}
                            </span>
                        </label>
                        :
                        ''
                    }

                    {/* old statuses */}
                    {/* <div className="popup-container">
                    <div className="col">
                        <label>Статус</label>
                        <br />
                        <div className="row" style={{ flexWrap: 'wrap' }}>
                            {props.statuses.filter(e => e.parent_id === popup.data.status_group_id || !e.parent_id).map(g =>

                                <div key={'s-' + g.id} className="col auto">
                                    <div className="checkbox-primary">
                                        <label htmlFor={`status-${g.id}`}>
                                            {g.translation ? g.translation.name : g.name}
                                            <input
                                                id={`status-${g.id}`}
                                                type={g.checkbox ? 'checkbox' : 'radio'}
                                                name="status_id[]"
                                                value={g.id}
                                                checked={popup.data
                                                    ? popup.data.selectedStatuses[g.id] ? true : false
                                                    : false
                                                }
                                                onChange={() => { }}
                                                onClick={e => handleStatusChange(g.id)}
                                            />
                                            <div className={`checkmark ${g.checkbox ? 'checkbox' : 'radio'}`}></div>
                                        </label>
                                    </div>
                                </div>

                            )}
                        </div>
                    </div>
                </div> */}
                </div>


                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div >
    )
}

export default forwardRef(AddOrEdit);