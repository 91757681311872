import React, { useState, useEffect, useRef } from 'react'
import Api from '../../../../../_helpers/Api'
import { useParams, useHistory } from 'react-router-dom'
import * as Constants from '../../../../../_config/app';

//partials
import Table from './Table'
import { fromInfo, trans } from '../../../../../_providers/Translation'

//modals
import Success from '../../../../modals/messages/Success'
import Error from '../../../../modals/messages/Error'
import loader from '../../../../../assets/img/loader.svg';
import AddParts from '../modals/AddParts'
import { useAuthDataContext } from '../../../../../_providers/Auth';
import infoIcon from '../../../../../assets/img/icons/info-black.png';
import ReactTooltip from 'react-tooltip';
import TableStock from './TableStock';
import Help from '../../../../partials/Help';

function Index(props) {

    const auth = useAuthDataContext();
    const params = useParams().id
    const history = useHistory()

    const reservation = props.data;

    const [parts, setParts] = useState({
        data: [],
        loading: true,
        refresh: null,
    });

    const [stockParts, setStockParts] = useState({
        data: [],
        loading: true,
        refresh: null,
    });

    //refs
    const partsModalRef = useRef(null)
    const quoteModalRef = useRef(null);
    const quoteErrorModalRef = useRef(null);

    useEffect(() => {
        loadData();
        loadStockData();
    }, []);

    const loadData = () => {

        // setParts(parts => ({
        //     ...parts,
        //     loading: true,
        // }))

        Api.get(`reservations/parts?id=${params}`)
            .then(res => {
                setParts(parts => ({
                    ...parts,
                    data: res.data,
                    loading: false,
                }))
            })
    }

    const loadStockData = () => {

        // setStockParts(parts => ({
        //     ...parts,
        //     loading: true,
        // }))

        Api.get(`reservations/stockparts?id=${params}`)
            .then(res => {
                setStockParts(parts => ({
                    ...parts,
                    data: res.data,
                    loading: false,
                }))
            })
    }


    const handleDeletePart = id => {
        Api.post('ordered-parts/delete', {
            id: id
        }).then(() => {
            loadData();
            // props.calculatePrice();
            props.refreshData();
        })
    }

    const handleRemoveStockPart = id => {
        Api.post('storage/detach-from-reservation', {
            used_id: id
        }).then(() => {
            loadStockData();
            // props.calculatePrice();
            props.refreshData();
        })
    }

    const handleAddPart = () => {
        partsModalRef.current.open()
    }

    const handleUpdatePart = data => {
        // let array = [...parts.data];

        // let index = array.findIndex(p => {
        //     return Number(data.id) === Number(p.id);
        // });

        // if (index !== null) {
        //     array[index] = data;

        //     setParts(prev => ({
        //         ...prev,
        //         data: array
        //     }));
        // }

        loadData();
        // props.calculatePrice();
        props.refreshData();
    }

    const handleSendQuote = () => {
        Api.post('reservations/parts/quote', {
            id: params
        }).then(res => {
            if (res.data.success) {
                quoteModalRef.current.open();
            } else {
                quoteErrorModalRef.current.open();
            }
        }).catch(err => {
            quoteErrorModalRef.current.open();
        });
    }

    const handleDownloadQuote = () => {
        window.open(Constants.App.api + 'reservations/parts/quote/download?id=' + params + '&token=' + auth.token());
    }

    const refreshData = () => {
        loadData();
        loadStockData();

        // props.calculatePrice();
        props.refreshData();
    }

    const refreshPartsData = () => {
        loadData();
        // props.calculatePrice();
        props.refreshData();
    }

    return (
        <>
            <Success
                ref={quoteModalRef}
                mainMessage={trans('headings.success9')}
            />

            <Error
                ref={quoteErrorModalRef}
                mainMessage={trans('headings.error3')}
            />

            <AddParts
                ref={partsModalRef}
                reservation={reservation}
                refreshData={refreshPartsData}
            />

            {parts.loading
                ?
                <img src={loader} alt="loading" />
                :
                <>

                    <div className="row">
                        <div className="col" style={{ marginRight: '5px' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan="2">
                                            {trans('tables.clientInfo')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {trans('tables.name')}

                                        </td>
                                        <td>
                                            {reservation.customer.name} {reservation.customer.lastname}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {trans('tables.contractorCompany')}
                                        </td>
                                        <td>
                                            {reservation.contractor
                                                ?
                                                reservation.contractor.name
                                                :
                                                ''
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {trans('tables.address')}
                                        </td>
                                        <td>
                                            {reservation.full_address}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {trans('tables.phone')}
                                        </td>
                                        <td>
                                            {reservation.phone_formatted}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {trans('tables.email')}
                                        </td>
                                        <td>
                                            {reservation.email}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div className="col" style={{ marginLeft: '5px' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan="2">
                                            {trans('tables.productInfo')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {trans('tables.category')}

                                        </td>
                                        <td>
                                            {reservation.appliancecategory && reservation.appliancecategory && reservation.appliancecategory.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {trans('tables.make')}

                                        </td>
                                        <td>
                                            {reservation.appliancebrand && reservation.appliancebrand.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {trans('tables.model')}

                                        </td>
                                        <td>
                                            {reservation.appliancemodel && reservation.appliancemodel.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {trans('tables.serialNo')}
                                        </td>
                                        <td>
                                            {reservation.serial_no}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {trans('tables.productCode')}
                                        </td>
                                        <td>
                                            {reservation.product_code}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {props.data.can_modify &&
                        <div className="row">
                            {reservation.can_modify_parts &&
                                <button className="button button-add" style={{ marginLeft: 'auto' }} onClick={handleAddPart}>
                                    {trans("buttons.newPart")}
                                </button>
                            }

                            {(parts.data.length > 0) &&
                                <>
                                    <button className="button" onClick={handleDownloadQuote}>
                                        {trans('buttons.downloadOffer')}
                                    </button>
                                    <button className="button" onClick={handleSendQuote}>
                                        {trans('buttons.sendOffer')}
                                    </button>
                                </>
                            }
                        </div>
                    }

                    <div className="pannel">
                        <h3>
                            {trans('headings.partsForClient')} <Help>{fromInfo('reservations.orderedParts')}</Help>
                            {/* <img
                                data-tip
                                data-for="parts-info"
                                src={infoIcon}
                                style={{ marginLeft: '5px', height: '12px' }}
                            />
                            <ReactTooltip
                                id="parts-info"
                                effect="solid"
                                place="right"
                            >
                                Това са части, които са потвърдени от клиента.
                            </ReactTooltip> */}
                        </h3>
                        <Table
                            reservation={props.data}
                            data={parts.data}
                            loading={parts.loading}
                            handleUpdatePart={handleUpdatePart}
                            handleDeletePart={handleDeletePart}
                            refreshData={loadData}
                        />
                    </div>


                    <div className="pannel">
                        <h3>
                            {trans('headings.usedVanParts')} <Help>{fromInfo('reservations.stockParts')}</Help>
                            {/* <img
                                data-tip
                                data-for="assigned-info"
                                src={infoIcon}
                                style={{ marginLeft: '5px', height: '12px' }}
                            />
                            <ReactTooltip
                                id="assigned-info"
                                effect="solid"
                                place="right"
                            >
                                Това са части, които все още не са потвърдени от клиента.
                            </ReactTooltip> */}
                        </h3>
                        <TableStock
                            reservation={props.data}
                            data={stockParts.data}
                            loading={stockParts.loading}
                            handleDeletePart={handleRemoveStockPart}
                            refreshData={loadStockData}
                        />

                    </div>
                </>
            }
        </>
    )
}

export default React.memo(Index, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});