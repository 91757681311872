import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import checkIcon from '../../../../assets/img/icons/check.png';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../../../partials/NoDataFound';
import Pagination from '../../../Pagination';
import { Link } from 'react-router-dom';
import { plural, trans } from '../../../../_providers/Translation';

function BonusPolicyResultDetails(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        id: null,
        periodId: null,
        employeeId: null,
        data: [],
        page: 1,
        pages: 0,
        total: 0,
        employee: null,
        policy: null,
        loading: true,
        onClose: null,
    });

    useImperativeHandle(ref, () => ({
        open: (id, periodId, employeeId) => {
            handleShow(id, periodId, employeeId);
        },
        hide: () => {
            handleHide();
        },
        onClose: fn => {
            setPopup(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [popup.id, popup.periodId, popup.employeeId, popup.page]);

    const loadData = () => {

        if (!popup.id || !popup.periodId || !popup.employeeId) {
            return;
        }

        setPopup(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'partners/bonuspolicies/results/details?id=' + popup.id
            + '&period_id=' + popup.periodId
            + '&employee_id=' + popup.employeeId
            + '&page=' + popup.page;

        Api.get(url)
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                    employee: res.data.employee,
                    policy: res.data.policy
                }))
            })
            .finally(() => {
                setPopup(prev => ({
                    ...prev,
                    loading: false
                }));
            })
    }

    const handleShow = (id, periodId, employeeId) => {

        setPopup(prev => ({
            ...prev,
            overlay: true,
            id: id,
            periodId: periodId,
            employeeId: employeeId
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        if (e) {
            e.preventDefault();
        }

        setPopup(prev => ({
            ...prev,
            modal: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false,
            }));
        }, 50);

        if (typeof popup.onClose === 'function') {
            popup.onClose();
        }
    }

    const handlePage = page => {
        setPopup(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <div className={`${popup.modal ? 'show' : ''} popup-primary`}>
                {popup.loading
                    ?
                    <Skeleton height={50} className="head" />
                    :
                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />
                        {trans('headings.bonusPolicyDetails', {
                            employee: popup.employee && popup.employee.name
                        })}
                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>
                }

                {popup.loading
                    ?
                    <Skeleton height={45} count={5} />
                    :
                    popup.data.length === 0
                        ?
                        <NoDataFound />
                        :
                        <div className="popup-container">
                            <div className="scrollable" style={{ width: '100%', padding: '0 10px 0 0' }}>

                                <table>
                                    <thead>
                                        <tr>
                                            <th className="center">
                                                ID
                                                {/* {trans('labels.reservation')} */}
                                            </th>
                                            <th>
                                                {trans('labels.refNo')}
                                            </th>
                                            <th>
                                                {trans('labels.customer')}
                                            </th>
                                            <th>
                                                {trans('labels.contractorCompany')}
                                            </th>
                                            <th>
                                                {trans('labels.address')}
                                            </th>
                                            <th>
                                                {trans('labels.initialStatus')}
                                            </th>
                                            <th>
                                                {trans('labels.finalStatus')}
                                            </th>
                                            {Number(popup.policy.type_id) === 1
                                                ?
                                                <th className="center">
                                                    {trans('labels.points')}
                                                </th>
                                                :
                                                ''
                                            }
                                            <th>
                                                {trans('labels.date')}
                                            </th>
                                            <th className="center">
                                                {trans('labels.customerRate')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {popup.data.map((r, i) =>
                                            <tr
                                                key={i}
                                            >
                                                <td className="center">
                                                    {r.reservation.id}
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/reservations/edit/${r.reservation.id}`}
                                                        className="btn"
                                                        style={{ background: r.reservation.color, color: r.reservation.text_color }}
                                                    >
                                                        {r.reservation.reference_code}
                                                    </Link>
                                                </td>
                                                <td>
                                                    {r.reservation.customer
                                                        ?
                                                        `${r.reservation.customer.name} ${r.reservation.customer.lastname}`
                                                        :
                                                        ''
                                                    }
                                                </td>
                                                <td>
                                                    {r.reservation.contractor
                                                        ?
                                                        r.reservation.contractor.name
                                                        :
                                                        ''
                                                    }
                                                </td>
                                                <td>
                                                    {r.reservation.full_address}
                                                </td>
                                                <td>
                                                    {r.reservation.initialstatus && r.reservation.initialstatus.translation ? r.reservation.initialstatus.translation.name : ''}
                                                </td>
                                                <td>
                                                    {r.reservation.finalstatus && r.reservation.finalstatus.translation ? r.reservation.finalstatus.translation.name : ''}
                                                </td>
                                                {Number(popup.policy.type_id) === 1
                                                    ?
                                                    <td className="center">
                                                        <span style={{ color: r.points > 0 ? 'green' : r.points < 0 ? 'red' : 'black' }}>
                                                            {r.points}
                                                        </span>
                                                    </td>
                                                    :
                                                    ''
                                                }
                                                <td>
                                                    {r.date}
                                                </td>
                                                <td className="center">
                                                    {r.customer_rate
                                                        ?
                                                        <img src={checkIcon} height="12" />
                                                        :
                                                        <img src={closeIcon} height="12" />
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                pages={popup.pages}
                                page={popup.page}
                                handlePage={handlePage}
                            />
                        </div>

                }

                <div className="footer align-center">
                    <button className="button" onClick={handleHide}>
                        {trans('buttons.close')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(BonusPolicyResultDetails);