import React, { useEffect, useState, useRef } from 'react';
import Table from './partials/Table';
import Api from '../../../_helpers/Api';
import { trans } from '../../../_providers/Translation'

//modals
import AddOrEdit from './partials/AddOrEdit';
import Question from '../../modals/messages/Question';

import loader from '../../../assets/img/loader.svg';


function Branches() {

    const modalAddOrEditRef = useRef(null);
    const modalQuestionRef = useRef(null);

    const [branches, setBranches] = useState({
        loading: true,
        data: []
    });

    useEffect(() => {
        loadBranches();
    }, []);

    // read
    const loadBranches = () => {

        setBranches(prev => ({
            ...prev,
            loading: true
        }));

        Api.get('branches/all')
            .then(res => {
                setBranches(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setBranches(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add();
    }

    const handleCreate = branch => {

        let data = branches.data.concat(branch);

        setBranches(prev => ({
            ...prev,
            data: data
        }));

    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id);
    }

    const handleUpdate = branch => {

        let data = branches.data.map(i => {
            if (i.id == branch.id) i = branch;
            return i;
        });

        setBranches(prev => ({
            ...prev,
            data: data
        }));
    }

    // delete
    const handleDelete = id => {
        Api.post('branches/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                let data = branches.data.filter(i => {
                    return i.id !== id;
                });
                setBranches(prev => ({
                    ...prev,
                    data: data
                }));
                modalQuestionRef.current.close();
            }
        });
    }

    // Question modal
    const handleOpenQuestion = id => {
        modalQuestionRef.current.open(id);
    }

    return (
        <>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
                callback={handleDelete}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.branches')}
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.branches')}
                        </p>
                    </div>
                    <div className="right">
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.branch')}
                        </button>
                    </div>
                </div>
                {branches.loading
                    ?
                    <img src={loader} alt="loading" />
                    :
                    <Table
                        branches={branches.data}
                        handleEdit={handleEdit}
                        handleDelete={handleOpenQuestion}
                    />
                }
            </div>
        </>
    )
}

export default Branches;