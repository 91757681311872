import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import Dropdown from '../../../partials/Dropdown';
import { useCurrencyContext } from '../../../../_providers/Currency';

function CreatePartnerInvoice(props, ref) {

    const currencies = useCurrencyContext();

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        loading: false,
        onSave: null
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: () => {
            setPopup(prev => ({
                ...prev,
                loading: false,
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));


    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'invoices/create/partner';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave();
                }

                document.getElementById('create-partner-invoice').reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="create-partner-invoice" className={`${popup.modal ? 'show' : ''} popup-primary small`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.addInvoice')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.client')}
                        </label>

                        <Dropdown
                            inputIdName="partner_id"
                            url="partners/all"
                            inputClassName={validations && validations.partner_id ? 'validate' : ''}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container x2">
                    <div className="col">
                        <label>
                            {trans('labels.priceWithoutTax')}
                        </label>

                        <input
                            type="number"
                            min="0"
                            step="0.01"
                            name="price"
                            className={validations && validations.price ? 'validate' : ''}
                        />
                    </div>

                    <div className="col">
                        <label>
                            {trans('labels.currency')}
                        </label>

                        <select
                            name="currency_id"
                            className={validations && validations.currency_id ? 'validate' : ''}
                        >
                            <option value=""></option>
                            {currencies.map(t =>
                                <option key={'c-' + t.id} value={t.id}>{t.name}</option>
                            )}
                        </select>
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.reason')}
                        </label>

                        <input
                            type="text"
                            name="description"
                            className={validations && validations.description ? 'validate' : ''}
                        />
                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(CreatePartnerInvoice);