import React, { useState, useRef } from 'react'
import Menu from '@material-ui/core/Menu'
import { useHistory } from 'react-router-dom'
import { trans } from '../../../../_providers/Translation';

//images
import menuIcon from '../../../../assets/img/icons/table-menu.png'
import editIcon from '../../../../assets/img/icons/edit.png'
import deleteIcon from '../../../../assets/img/icons/delete.png'

//modals
import Question from '../../../modals/messages/Question'
import Api from '../../../../_helpers/Api'

function Index(props) {

    let history = useHistory()
    //refs
    const questionModalRef = useRef(null)


    const [table, setTable] = useState({
        options: null
    })

    const handleTableMenu = e => {
        e.stopPropagation();

        setTable(table => {
            return {
                ...table,
                options: e.currentTarget
            }
        })
    }

    const handleCloseMenu = (e = null) => {
        if (e) {
            e.stopPropagation();
        }

        setTable(table => {
            return {
                ...table,
                options: null
            }
        })
    }

    const handleEdit = () => {
        history.push(`/reservations/edit/${props.id}`)
    }

    const handleDelete = e => {
        let modal = questionModalRef.current;

        handleCloseMenu(e);

        modal.open();

        modal.onSuccess(() => {
            handleDestroy();
        });
    }

    const handleDestroy = () => {
        Api.post(`reservations/delete`, {
            id: props.id
        }).then(res => {
            props.handleRefresh(props.masterId);
        })
    }


    return (
        <>
            <Question
                ref={questionModalRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
            />


            <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />

            <Menu
                anchorEl={table.options}
                keepMounted
                open={Boolean(table.options)}
                onClose={handleCloseMenu}
            >
                <div className="menu-option" onClick={handleEdit}>
                    <img src={editIcon} alt="edit" />
                    {trans('buttons.view')}
                </div>


                <div className="menu-option" onClick={handleDelete}>
                    <img src={deleteIcon} alt="delete" />
                    {trans('buttons.delete')}
                </div>
            </Menu>
        </>
    )
}

export default Index;
