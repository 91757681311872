import React, { useRef } from 'react';

//partials
import Options from './Options';
import Question from '../../../modals/messages/Question';

//images
import checkIcon from '../../../../assets/img/icons/check.png'
import closeIcon from '../../../../assets/img/icons/close.png'
import NoDataFound from '../../../partials/NoDataFound';
import { useAuthDataContext } from '../../../../_providers/Auth';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation'

function Table(props) {

    const auth = useAuthDataContext();

    const repeatModalRef = useRef(null);

    const handleStopRepeat = id => {
        let modal = repeatModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            Api.post('text-messages/stop-repeat', {
                id: id
            }).then(res => {
                if (res.data.success) {
                    props.refreshData();
                }
            });
        });
    }

    return (
        <>

            <Question
                ref={repeatModalRef}
                mainMessage={trans('headings.question1')}
            />

            {props.data.length === 0
                ?
                <NoDataFound />
                :
                <div className="table-scroller">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: '40px' }}>ID</th>
                                <th className="center">{trans('labels.type')}</th>
                                <th>{trans('labels.createdBy')}</th>
                                <th>{trans('labels.heading')}</th>
                                <th>{trans('labels.dateSent')}</th>
                                <th className="center">{trans('labels.receivers')}</th>
                                <th className="center">{trans('labels.repeat')}</th>
                                <th className="options">{trans('labels.options')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.map(sms =>
                                <tr key={'sms-' + sms.id}>
                                    <td style={{ width: '40px' }}>{sms.id}</td>
                                    <td className="center">
                                        <label className="btn" style={{ margin: 0 }}>
                                            {sms.method.toUpperCase()}
                                        </label>
                                    </td>
                                    <td>{sms.author ? `${sms.author.name} ${sms.author.lastname}` : ''}</td>
                                    <td>
                                        {sms.subject}
                                    </td>
                                    <td>
                                        {sms.send_at_dmy}
                                    </td>
                                    <td className="center">
                                        {sms.receivers_count}
                                    </td>
                                    <td className="center">
                                        {Number(sms.repeat) === 1 || (sms.parent && Number(sms.parent.repeat) === 1)
                                            ?
                                            <img src={checkIcon} height="12" />
                                            :
                                            <img src={closeIcon} height="12" />
                                        }
                                        {sms.parent_id &&
                                            <span style={{ display: 'block', fontSize: '10px', marginTop: '5px' }}>родител ID #{sms.parent_id}</span>
                                        }
                                    </td>

                                    <td>
                                        <Options
                                            id={sms.id}
                                            data={sms}
                                            handleEdit={props.handleEdit}
                                            handleDelete={props.handleDelete}
                                            handleStopRepeat={handleStopRepeat}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            }
        </>
    )
}

export default Table;