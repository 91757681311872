import React, { forwardRef, useImperativeHandle } from 'react';
import Api from '../../../../_helpers/Api';
import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Question from '../../../modals/messages/Question'
import AddOrEdit from './AddOrEdit'
import NoDataFound from '../../../partials/NoDataFound';
import Options from './Options';
import { plural, trans } from '../../../../_providers/Translation';
import Pagination from '../../../Pagination';

function Index(props, ref) {

    const modalRef = useRef(null)
    const deleteModalRef = useRef(null)

    const [data, setData] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        filter: {},
        setFilter: false,
        update: false,
    });

    useImperativeHandle(ref, () => ({
        add: () => {
            handleAdd();
        }
    }));

    useEffect(() => {
        loadTypes();
    }, [data.update]);

    const loadTypes = () => {
        setData(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'contractors/subscriptions/types?';

        Object.entries(data.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setData(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items || [],
                    total: res.data.total,
                    pages: res.data.pages,
                }));
            }).catch(err => {
                setData(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    // create
    const handleAdd = () => {
        modalRef.current.add();
    }

    const handleCreate = () => {
        setData(prev => ({
            ...prev,
            update: Math.random().toString()
        }))
    }

    // update
    const handleEdit = id => {
        modalRef.current.edit(id)
    }

    const handleUpdate = row => {

        let updated = data.data.map(i => {
            if (i.id == row.id) {
                i = row
            }
            return i
        });

        setData(prev => ({
            ...prev,
            data: updated,
        }))
    }

    // delete
    const handleDestroy = id => {

        let url = 'contractors/subscriptions/types/delete';

        Api.post(url, {
            id: id
        }).then(res => {
            if (res.data.success) {
                let filtered = data.data.filter(i => {
                    return i.id !== id
                })

                setData(prev => ({
                    ...prev,
                    data: filtered
                }))

                deleteModalRef.current.close()
            }
        })
    }

    // Question modal
    const handleDelete = id => {
        deleteModalRef.current.open(id);

        deleteModalRef.current.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handlePage = page => {
        setData(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            update: Math.random().toString()
        }));
    }

    const getPeriodText = type => {
        let period = type.period || 1;
        let text;

        switch (type.period_type) {
            case 1:
                text = plural('plural_periods.day', period);
                break;
            case 2:
                text = plural('plural_periods.week', period);
                break;
            case 3:
                text = plural('plural_periods.month', period);
                break;
            case 4:
                text = plural('plural_periods.year', period);
                break;
        }

        return period + ' ' + text;
    }

    return (
        <>
            <AddOrEdit
                ref={modalRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={deleteModalRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
            />


            {data.loading
                ?
                <Skeleton height={45} count={10} />
                :
                data.data.length === 0
                    ?
                    <NoDataFound />
                    :
                    <>
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            {trans('tables.type')}
                                        </th>
                                        <th>
                                            {trans('tables.price')}
                                        </th>
                                        <th>
                                            {trans('labels.period')}
                                        </th>
                                        <th className="options">
                                            {trans('tables.options')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.data.map(t =>
                                        <tr key={t.id}>
                                            <td>{t.translation?.name || t.name}</td>
                                            <td>{t.price.toFixed(2)}</td>
                                            <td>{t.period_text}</td>
                                            <td className="options">
                                                <Options
                                                    id={t.id}
                                                    data={t}
                                                    handleEdit={handleEdit}
                                                    handleDelete={handleDelete}
                                                />

                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {!data.loading
                            ?
                            <Pagination
                                pages={data.pages}
                                page={data.filter.page}
                                handlePage={handlePage}
                            />
                            :
                            ''
                        }


                    </>
            }

        </>
    )
}

export default forwardRef(Index);