import React from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import NoDataFound from '../../../partials/NoDataFound';
import TableRow from './TableRow';
import { trans } from '../../../../_providers/Translation';

function Table(props) {

    const renderCard = (data, index) => {
        return (
            <TableRow
                key={data.id}
                index={index}
                id={data.id}
                tab={props.tab}
                data={data}
                state={props.data}
                moveCard={props.moveCard}
                handleEdit={props.handleEdit}
                handleAccept={props.handleAccept}
                handleDecline={props.handleDecline}
                handleDelete={props.handleDelete}
                handleShowSettings={props.handleShowSettings}
                changePriority={props.changePriority}
            />
        )
    }

    return (

        props.data.length === 0
            ?
            <NoDataFound />
            :
            <>
                <div className="table-scroller">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: '50px' }}>ID</th>
                                <th>{trans('tables.name')}</th>
                                {props.tab === 'sent' &&
                                    <th>{trans('tables.priority')}</th>
                                }
                                <th>{trans('tables.email')}</th>
                                <th>{trans('tables.phone')}</th>
                                <th>{trans('tables.address')}</th>
                                <th>{trans('tables.status')}</th>
                                <th>{trans('tables.maxReservations')}</th>
                                <th className="center">{trans('tables.profie')}</th>
                                <th className="options">{trans('tables.options')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <DndProvider backend={HTML5Backend}>
                                {props.data.map((t, i) => renderCard(t, i))}
                            </DndProvider>
                        </tbody>
                    </table>
                </div>
            </>
    )
}

export default Table;