import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';

//images
import closeIcon from '../../../../../assets/img/icons/close.png';
import { trans } from '../../../../../_providers/Translation';

function Index(props, ref) {

    const history = useHistory();

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
    });

    useImperativeHandle(ref, () => ({
        show: () => {
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }


    const handleAddAddressReservation = () => {
        history.push('/reservations/address/add');
    }

    const handleAddServiceReservation = () => {
        history.push('/reservations/service/add');
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <div className={`${popup.modal ? 'show' : ''} popup-primary small`}>
                <div className="row">
                    <button className="button button-add" onClick={handleAddAddressReservation} style={{ width: '100%' }}>
                        {trans('buttons.addAddressReservation')}
                    </button>
                </div>
                <div className="row" style={{ marginBottom: 0 }}>
                    <button className="button button-add" onClick={handleAddServiceReservation} style={{ width: '100%' }}>
                        {trans('buttons.addServiceReservation')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Index);