import React, { useEffect, useState, useRef } from 'react';
import { trans } from '../../../_providers/Translation';

import Api from '../../../_helpers/Api';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import { useNestedState } from '../../../_helpers/NestedState';
import { useValidation } from '../../../_helpers/Validation';
import { useAuthDataContext } from '../../../_providers/Auth';
import { useLanguageContext } from '../../../_providers/Language';

//modals
import '../../../assets/css/invoices.css'
import moment from 'moment';
import Dropdown from '../../partials/Dropdown';

import '../../../assets/css/invoices.css'
import Axios from 'axios';
import Input from '../../partials/Input';
import Error from '../../modals/messages/Error';

let timeout;
let calculationRequestTimeout;

function CustomInvoice() {

    const languages = useLanguageContext();
    const auth = useAuthDataContext();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();

    const formRef = useRef(null);
    const errorModalRef = useRef(null);

    const editMode = params.id ? true : false;

    const [state, setState] = useNestedState({
        loading: false,
        dataLoaded: false,
        data: {
            client: null,
            provider: null,
        },
        nextNumberRequest: null,
        nextNumbersRequest: null,
        calculationRequest: null,
    });

    const [validations, setValidations] = useValidation();

    useEffect(() => {
        loadData();
    }, [params.id]);

    useEffect(() => {
        if (!state.dataLoaded) {
            return;
        }

        if (!state.data.provider) {
            Api.get('invoices/default-provider-data')
                .then(res => {
                    setState(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            provider: res.data,
                            vat: res.data.vat
                        }
                    }));
                });
        }
    }, [state.dataLoaded, params.id, auth]);

    // ако няма добавен ред за аркитули, винаги да създава 1 празен ред
    useEffect(() => {
        if (!state.data.rows || (state.data.rows && state.data.rows.length === 0)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: [
                        {

                        }
                    ]
                }
            }));
        }
    }, [state.data.rows]);

    const loadData = () => {
        setState(prev => ({
            ...prev,
            dataLoaded: params.id ? false : true
        }));

        if (!params.id) {
            return;
        }

        Api.get('invoices/show', {
            params: {
                id: params.id
            }
        })
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }));

            })
            .catch(error => {
                let _err = error.response;

                if (_err && _err.status && _err.status === 404) {
                    // todo
                }
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    dataLoaded: true,
                }));
            })
    }

    const handleInputChange = e => {

        let name = e.target.name;
        let value = e.target.value;

        setState(name, value, 'data');
    }

    const handleCustomerChange = data => {
        if (data?.id) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    client: data
                }
            }));
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    client: {
                        company_name: data.name
                    }
                }
            }));
        }
    }

    const handlePriceInputChange = e => {
        handleInputChange(e);

        clearTimeout(calculationRequestTimeout);

        calculationRequestTimeout = setTimeout(() => {
            calculatePrice();
        }, 500);
    }

    const handleDateChange = (date, name = 'date') => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name + '_ymd']: moment(date).format('YYYY-MM-DD')
            }
        }));
    }

    const calculatePrice = () => {
        if (state.calculationRequest) {
            state.calculationRequest.cancel();
        }

        let calculationRequest = Axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            calculationRequest: calculationRequest
        }));

        let form = formRef.current;

        if (!form) {
            return;
        }

        let data = new FormData(form);

        Api.post('invoices/calculate-price', data, {
            cancelToken: calculationRequest.token
        }).then(res => {

            let data = res.data.document;
            let rows = res.data.rows;

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    ...data,
                    rows: prev.data.rows.map((row, index) => {
                        return { ...row, ...rows[index] }
                    })
                }
            }));
        }).finally(() => {
            setState(prev => ({
                ...prev,
                calculated: true
            }));
        })
    }

    const handleAddRow = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({})
            }
        }));
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.filter((row, i) => i !== index)
            }
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'invoices/create/custom';

        let data = new FormData(e.target);

        if (params.id) {
            data.append('id', params.id);
        }

        Api.post(url, data)
            .then(res => {
                // history.push('/invoices');
                history.goBack();

                // if (res.data.id) {
                //     history.push('/invoices/edit/' + res.data.id);
                // }
            })
            .catch(error => {

                let _err = error.response;

                if (_err && _err.status && _err.status === 422) {
                    // console.log(_err.data.errors);
                    setValidations(_err.data.errors);
                } else {
                    let modal = errorModalRef.current;

                    modal.open();
                }

            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    const handleCancel = e => {
        e.preventDefault();

        history.goBack();
        // history.push('/invoices');
    }

    const getTypeId = () => {
        return Number(state?.data?.type_id);
    }

    return (
        <>

            <Error
                mainMessage={trans('headings.error3')}
                ref={errorModalRef}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {editMode ? trans('invoice.editDocument') : trans('invoice.newDocument')}
                        </h1>
                        {/* <p className="page-subheading">
                            {trans('subheadings.invoices')}
                        </p> */}
                    </div>
                    <div className="right">

                    </div>
                </div>

                <form ref={formRef} autoComplete="off" onSubmit={handleSave}>
                    <div className="panel-wrapper row">

                        {/* left */}
                        <div className="panel">
                            <div className="head">
                                {trans('invoice.clientData')}
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="client[company_name]">
                                        {trans('invoice.name')}
                                    </label>
                                    <Dropdown
                                        inputId="client[company_name]"
                                        inputName="client[company_name]"
                                        inputValue={state.data?.client?.company_name || ''}
                                        inputIdName="client[id]"
                                        inputIdValue={state.data?.client?.id || ''}
                                        url="invoices/find-customer"
                                        renderInputText={data => {
                                            return data.company_name || data.full_name;
                                        }}
                                        renderText={data => {
                                            return (
                                                <div style={{ fontSize: '12px' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={data.avatar} alt="" style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px', objectFit: 'cover' }} />
                                                        <div>
                                                            <div>
                                                                {data.company_name || data.full_name}
                                                            </div>
                                                            <div style={{ marginTop: '5px', fontSize: '11px', color: '#999' }}>
                                                                <div>
                                                                    {trans('labels.eikEgn')}: {data.eikegn || trans('messages.notSelected')}
                                                                </div>
                                                                <div>
                                                                    {trans('random.phone')}: {data.phone_formatted || trans('messages.notSelected')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                        onChange={handleCustomerChange}
                                        error={Boolean(validations && validations.client && validations.client.company_name && (validations.client.company_name[0] || validations.client.company_name))}
                                        helperText={validations && validations.client && validations.client.company_name && (validations.client.company_name[0] || validations.client.company_name)}
                                    />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col">
                                    <label htmlFor="client[eikegn]">
                                        {trans('invoice.eikEgn')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="client[eikegn]"
                                        id="client[eikegn]"
                                        value={state.data.client?.eikegn || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.client && validations.client.eikegn && (validations.client.eikegn[0] || validations.client.eikegn))}
                                        helperText={validations && validations.client && validations.client.eikegn && (validations.client.eikegn[0] || validations.client.eikegn)}
                                    />
                                </div>

                                <div className="col">
                                    <label htmlFor="client[vat_number]">
                                        {trans('invoice.vatNumber')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="client[vat_number]"
                                        id="client[vat_number]"
                                        value={state.data.client?.vat_number || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.client && validations.client.vat_number && (validations.client.vat_number[0] || validations.client.vat_number))}
                                        helperText={validations && validations.client && validations.client.vat_number && (validations.client.vat_number[0] || validations.client.vat_number)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="client[mol]">
                                        {trans('invoice.mol')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="client[mol]"
                                        id="client[mol]"
                                        value={state.data.client?.mol || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.client && validations.client.mol && (validations.client.mol[0] || validations.client.mol))}
                                        helperText={validations && validations.client && validations.client.mol && (validations.client.mol[0] || validations.client.mol)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="client[address]">
                                        {trans('invoice.address')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="client[address]"
                                        id="client[address]"
                                        value={state.data.client?.address || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.client && validations.client.address && (validations.client.address[0] || validations.client.address))}
                                        helperText={validations && validations.client && validations.client.address && (validations.client.address[0] || validations.client.address)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="client[phone]">
                                        {trans('invoice.phone')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="client[phone]"
                                        id="client[phone]"
                                        value={state.data.client?.phone || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.client && validations.client.phone && (validations.client.phone[0] || validations.client.phone))}
                                        helperText={validations && validations.client && validations.client.phone && (validations.client.phone[0] || validations.client.phone)}
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="client[accounting_email]">
                                        {trans('invoice.email')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="client[accounting_email]"
                                        id="client[accounting_email]"
                                        value={state.data.client?.accounting_email || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.client && validations.client.accounting_email && (validations.client.accounting_email[0] || validations.client.accounting_email))}
                                        helperText={validations && validations.client && validations.client.accounting_email && (validations.client.accounting_email[0] || validations.client.accounting_email)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="client[website]">
                                        {trans('invoice.website')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="client[website]"
                                        id="client[website]"
                                        value={state.data.client?.website || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.client && validations.client.website && (validations.client.website[0] || validations.client.website))}
                                        helperText={validations && validations.client && validations.client.website && (validations.client.website[0] || validations.client.website)}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* middle */}
                        <div className="panel" style={{ width: '25%' }}>
                            <div className="head">
                                {trans('invoice.document')}
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('invoice.type')}
                                    </label>
                                    <select
                                        name="type_id"
                                        value={state.data.type_id || 2}
                                        onChange={handleInputChange}
                                    >
                                        <option value={1}>
                                            {trans('invoice.proforma')}
                                        </option>
                                        <option value={2}>
                                            {trans('invoice.invoice')}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>
                                        №
                                    </label>
                                    <Input
                                        type="text"
                                        name="no"
                                        value={state.data.no || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.no && (validations.no[0] || validations.no))}
                                        helperText={validations && validations.no && (validations.no[0] || validations.no)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('invoice.date')}
                                    </label>
                                    <Input
                                        type="date"
                                        name="date"
                                        max={moment().utc().format('YYYY-MM-DD')}
                                        value={state.data.date_ymd || moment().utc().format('YYYY-MM-DD')}
                                        onChange={e => handleDateChange(e.target.value)}
                                        error={Boolean(validations && validations.date && (validations.date[0] || validations.date))}
                                        helperText={validations && validations.date && (validations.date[0] || validations.date)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('invoice.payment_date')}
                                    </label>
                                    <Input
                                        type="date"
                                        name="payment_date"
                                        max={moment().utc().format('YYYY-MM-DD')}
                                        value={state.data.payment_date_ymd || moment().utc().format('YYYY-MM-DD')}
                                        onChange={e => handleDateChange(e.target.value, 'payment_date')}
                                        error={Boolean(validations && validations.payment_date && (validations.payment_date[0] || validations.payment_date))}
                                        helperText={validations && validations.payment_date && (validations.payment_date[0] || validations.payment_date)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('invoice.lang')}
                                    </label>
                                    <select
                                        name="lang_id"
                                        value={state.data.lang_id || auth.langId()}
                                        onChange={handleInputChange}
                                    >
                                        <option value="" disabled></option>
                                        {languages.map(lang =>
                                            <option key={lang.id} value={lang.id}>{lang.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* right */}
                        <div className="panel">
                            <div className="head">
                                {trans('invoice.providerData')}
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="provider[company_name]">
                                        {trans('invoice.name')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="provider[company_name]"
                                        id="provider[company_name]"
                                        value={state.data.provider?.company_name || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.provider && validations.provider.company_name && (validations.provider.company_name[0] || validations.provider.company_name))}
                                        helperText={validations && validations.provider && validations.provider.company_name && (validations.provider.company_name[0] || validations.provider.company_name)}
                                    />

                                    <input type="hidden" name="provider[id]" value={state.data?.provider?.id || ''} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="provider[eikegn]">
                                        {trans('invoice.eikEgn')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="provider[eikegn]"
                                        id="provider[eikegn]"
                                        value={state.data.provider?.eikegn || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.provider && validations.provider.eikegn && (validations.provider.eikegn[0] || validations.provider.eikegn))}
                                        helperText={validations && validations.provider && validations.provider.eikegn && (validations.provider.eikegn[0] || validations.provider.eikegn)}
                                    />
                                </div>

                                <div className="col">
                                    <label htmlFor="provider[vat_number]">
                                        {trans('invoice.vatNumber')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="provider[vat_number]"
                                        id="provider[vat_number]"
                                        value={state.data.provider?.vat_number || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.provider && validations.provider.vat_number && (validations.provider.vat_number[0] || validations.provider.vat_number))}
                                        helperText={validations && validations.provider && validations.provider.vat_number && (validations.provider.vat_number[0] || validations.provider.vat_number)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="client[mol]">
                                        {trans('invoice.mol')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="provider[mol]"
                                        id="provider[mol]"
                                        value={state.data.provider?.mol || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.provider && validations.provider.mol && (validations.provider.mol[0] || validations.provider.mol))}
                                        helperText={validations && validations.provider && validations.provider.mol && (validations.provider.mol[0] || validations.provider.mol)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="provider[address]">
                                        {trans('invoice.address')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="provider[address]"
                                        id="provider[address]"
                                        value={state.data.provider?.address || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.provider && validations.provider.address && (validations.provider.address[0] || validations.provider.address))}
                                        helperText={validations && validations.provider && validations.provider.address && (validations.provider.address[0] || validations.provider.address)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="provider[phone]">
                                        {trans('invoice.phone')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="provider[phone]"
                                        id="provider[phone]"
                                        value={state.data.provider?.phone || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.provider && validations.provider.phone && (validations.provider.phone[0] || validations.provider.phone))}
                                        helperText={validations && validations.provider && validations.provider.phone && (validations.provider.phone[0] || validations.provider.phone)}
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="provider[accounting_email]">
                                        {trans('invoice.email')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="provider[accounting_email]"
                                        id="provider[accounting_email]"
                                        value={state.data.provider?.accounting_email || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.provider && validations.provider.accounting_email && (validations.provider.accounting_email[0] || validations.provider.accounting_email))}
                                        helperText={validations && validations.provider && validations.provider.accounting_email && (validations.provider.accounting_email[0] || validations.provider.accounting_email)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="provider[website]">
                                        {trans('invoice.website')}
                                    </label>
                                    <Input
                                        type="text"
                                        name="provider[website]"
                                        id="provider[website]"
                                        value={state.data.provider?.website || ''}
                                        onChange={handleInputChange}
                                        error={Boolean(validations && validations.provider && validations.provider.website && (validations.provider.website[0] || validations.provider.website))}
                                        helperText={validations && validations.provider && validations.provider.website && (validations.provider.website[0] || validations.provider.website)}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div id="articles" className="panel-wrapper row">
                            <div className="panel">
                                <div className="head">
                                    {trans('invoice.articles')}
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                №
                                            </th>
                                            <th>
                                                {trans('invoice.name')}
                                            </th>
                                            <th>
                                                {trans('invoice.qty')}
                                            </th>
                                            <th>
                                                {trans('invoice.unit')}
                                            </th>
                                            <th>
                                                {trans('invoice.unitPrice')}
                                            </th>
                                            <th>
                                                {trans('invoice.value')}
                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{Array.isArray(state.data.rows)
                                        ?
                                        state.data.rows.map((row, index) => (
                                            <tr key={'row-' + index}>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    <Input
                                                        type="text"
                                                        name={`rows[${index}][name]`}
                                                        value={row.name || ''}
                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].name && (validations.rows[index].name[0] || validations.rows[index].name))}
                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].name && (validations.rows[index].name[0] || validations.rows[index].name)}
                                                        onChange={handleInputChange}
                                                    />

                                                </td>
                                                <td>
                                                    <Input
                                                        type="number"
                                                        min="0"
                                                        step="0.001"
                                                        name={`rows[${index}][quantity]`}
                                                        value={row.quantity || 1}
                                                        onChange={handlePriceInputChange}
                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity))}
                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="text"
                                                        name={`rows[${index}][unit]`}
                                                        value={row.unit || ''}
                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].unit && (validations.rows[index].unit[0] || validations.rows[index].unit.name))}
                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].unit && (validations.rows[index].unit[0] || validations.rows[index].unit.name)}
                                                        onChange={handleInputChange}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="number"
                                                        step="0.01"
                                                        name={`rows[${index}][price]`}
                                                        value={row.price || ''}
                                                        onChange={handlePriceInputChange}
                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].price && (validations.rows[index].price[0] || validations.rows[index].price))}
                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].price && (validations.rows[index].price[0] || validations.rows[index].price)}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="number"
                                                        step="0.01"
                                                        name={`rows[${index}][total_price]`}
                                                        value={row.total_price ? row.total_price.toFixed(2) : ''}
                                                        onChange={handleInputChange}
                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].total_price && (validations.rows[index].total_price[0] || validations.rows[index].total_price))}
                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].total_price && (validations.rows[index].total_price[0] || validations.rows[index].total_price)}
                                                        readOnly
                                                    />
                                                </td>
                                                <td>
                                                    <a
                                                        className="remove"
                                                        onClick={e => handleRemoveRow(e, index)}
                                                    >

                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        ''
                                    }</tbody>
                                </table>

                                <button
                                    className="button button-add"
                                    onClick={handleAddRow}
                                >
                                    {trans('invoice.addNewArticle')}
                                </button>
                            </div>
                        </div>

                        <div id="payment" className="panel-wrapper row">
                            <div className="panel">
                                <div className="head">
                                    {trans('invoice.paymentAmount')}
                                </div>

                                {/* <div className="row">
                                    <div className="col">
                                        <label>
                                            Сума
                                        </label>
                                        <Input
                                            type="number"
                                            value={state.data.total_price ? state.data.total_price.toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            Отстъпка (%)
                                        </label>
                                        <Input
                                            type="number"
                                            name="discount"
                                            value={state.data.discount || ''}
                                            onChange={handlePriceInputChange}
                                        />
                                    </div>

                                    <div className="col">
                                        <label>
                                            Отстъпка (сума)
                                        </label>
                                        <Input
                                            type="number"
                                            value={state.data.discount_price ? state.data.discount_price.toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                </div> */}

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('invoice.net')}
                                        </label>
                                        <Input
                                            type="number"
                                            value={state.data.net_price ? state.data.net_price.toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('invoice.vatPercent')}
                                        </label>
                                        <Input
                                            type="number"
                                            name="vat"
                                            value={state.data.vat || ''}
                                            onChange={handlePriceInputChange}
                                        />
                                    </div>

                                    <div className="col">
                                        <label>
                                            {trans('invoice.vatValue')}
                                        </label>
                                        <Input
                                            type="number"
                                            value={state.data.vat_price ? state.data.vat_price.toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('invoice.gross')}
                                        </label>
                                        <Input
                                            type="number"
                                            value={state.data.total_gross_price ? state.data.total_gross_price.toFixed(2) : ''}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row buttons-holder">
                        <button className="button" disabled={state.loading} onClick={handleCancel}>
                            {trans('buttons.cancel')}
                        </button>
                        <button className="button button-primary" disabled={state.loading}>
                            {trans('buttons.save')}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CustomInvoice;