import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';

//images
import menuIcon from '../../../../../assets/img/icons/table-menu.png';
import editIcon from '../../../../../assets/img/icons/edit.png';
import deleteIcon from '../../../../../assets/img/icons/delete.png';
import { trans } from '../../../../../_providers/Translation';

function Index(props) {

    const [table, setTable] = useState({
        options: null
    });

    const handleTableMenu = e => {

        setTable(table => {
            return {
                ...table,
                options: e.currentTarget
            }
        });
    };

    const handleCloseMenu = () => {
        setTable(table => {
            return {
                ...table,
                options: null
            }
        });
    };

    const handleEdit = id => {
        handleCloseMenu();

        props.handleEdit(id);
    }

    const handleDelete = id => {
        handleCloseMenu();

        props.handleDelete(id);
    }

    const handleEvents = id => {
        handleCloseMenu();

        props.handleShowEvents(id);
    }

    return (
        <>
            <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />

            <Menu
                id={'menu-' + props.id}
                anchorEl={table.options}
                keepMounted
                open={Boolean(table.options)}
                onClose={handleCloseMenu}
            >
                <div className="menu-option" onClick={() => handleEvents(props.id)}>
                    <img src={editIcon} alt="edit" />
                    {trans('options.events')}
                </div>
                <div className="menu-option" onClick={() => handleEdit(props.id)}>
                    <img src={editIcon} alt="edit" />
                    {trans('options.edit')}
                </div>
                <div className="menu-option" onClick={() => handleDelete(props.id)}>
                    <img src={deleteIcon} alt="delete" />
                    {trans('options.delete')}
                </div>
            </Menu>
        </>
    )
}

export default Index;
