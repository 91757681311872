import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd'
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { TYPE } from '../../../../_constants/Reservation';
import { trans } from '../../../../_providers/Translation';

function TableRow(props) {
    let index = props.index;
    let id = props.id;
    let state = props.state;
    let data = props.data;

    let style = {
        cursor: 'grab',
    };

    const handleChange = (item) => {
        let dragId = item.id;

        let ids = [];

        state.map(c => {
            ids.push(c.id)
        });

        // console.log(ids);

        props.changePriority(ids);
    }

    const isAddress = () => {
        return Number(data.res_type_id) === TYPE.ADDRESS;
    }

    const isOnSiteRepair = () => {
        return Number(data.res_type_id) === TYPE.ON_SITE_REPAIR;
    }

    const ref = useRef(null)

    const [, drop] = useDrop({
        accept: 'card',
        drop(item) {
            handleChange(item)
        },
        hover(item, monitor) {

            ReactTooltip.hide();

            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            props.moveCard(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        item: { type: 'card', id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1

    drag(drop(ref));

    return (
        <React.Fragment key={'res-' + data.id}>
            <div ref={ref} style={{ ...style, opacity, marginBottom: 10 + 'px' }}>
                <Link
                    to={`/reservations/edit/` + data.id}
                    // target="_blank"
                    style={{
                        padding: '3px 5px',
                        borderRadius: '3px',
                        color: data.text_color,
                        background: data.color,
                        display: 'inline-block',
                        width: '75px',
                        overflow: 'hidden',
                        textAlign: 'center'
                    }}
                    data-tip
                    data-for={'sct-' + data.id}
                >
                    <span>
                        {isAddress() ? (data.full_address || '').substr(0, 6) : trans('labels.service')}
                    </span>
                </Link>
            </div>
            <ReactTooltip
                id={'sct-' + data.id}
                effect="float"
                backgroundColor={data.color}
                textColor={data.text_color}
                arrowColor="transparent"
            >
                {isAddress() ? data.full_address : trans('labels.onSiteRepair')}
            </ReactTooltip>
        </React.Fragment>
    )
}

export default TableRow;