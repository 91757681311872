import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import Api from '../../_helpers/Api'
import closeIcon from '../../assets/img/icons/close.png'
import Skeleton from 'react-loading-skeleton';
import { trans } from '../../_providers/Translation'
import NoDataFound from '../partials/NoDataFound';

function IncommingCall(props, ref) {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        loading: false,
        data: [],
        phone: null,
    });

    useImperativeHandle(ref, () => ({
        open: phone => {
            handleOpen(phone)
        },
        close: () => {
            handleClose()
        }
    }))

    const loadData = phone => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'reservations/find/phone?phone=' + String(phone);

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    loading: false
                }));
            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    const handleOpen = phone => {

        loadData(phone);

        setState(prev => ({
            ...prev,
            overlay: true,
            phone: phone
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)

    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 50)

        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    }

    const handleShow = id => {
        window.open('/reservations/edit/' + id);
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <div className={`${state.modal ? 'show' : ''} popup-primary`}>
                <h2 className="head">
                    {trans('headings.ingommingCalls')} {state.phone}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleClose} />
                </h2>

                {state.loading
                    ?
                    <Skeleton height={45} count={5} />
                    :
                    state.data.length === 0
                        ?
                        <NoDataFound />
                        :
                        <>
                            <div className="table-scroller">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>{trans('tables.number')}</th>
                                            <th>{trans('tables.setTo')}</th>
                                            <th>{trans('tables.client')}</th>
                                            <th>{trans('tables.visitDate')}</th>
                                            <th>{trans('tables.visitHour')}</th>
                                            <th>{trans('tables.initialStatus')}</th>
                                            <th>{trans('tables.finalStatus')}</th>
                                            <th>{trans('tables.finalPrice')}</th>
                                            <th>{trans('tables.paidPrice')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.data.map(r =>
                                            <tr
                                                key={'rr-' + r.id}
                                                className="pointer"
                                            >
                                                <td onClick={() => handleShow(r.id)}>
                                                    {r.id}
                                                </td>
                                                <td onClick={() => handleShow(r.id)}>
                                                    <Link
                                                        to={`/reservations/edit/${r.id}`}
                                                        className="btn"
                                                        style={{ background: r.color, color: r.text_color }}
                                                    >
                                                        {r.reference_code}
                                                    </Link>
                                                </td>
                                                <td onClick={() => handleShow(r.id)}>

                                                    {r.performer
                                                        ?
                                                        r.performer.type === 'App\\Employee'
                                                            ? `${r.performer.name} ${r.performer.lastname}`
                                                            : `${r.performer.name}`
                                                        :
                                                        '-'
                                                    }

                                                </td>
                                                <td onClick={() => handleShow(r.id)}>
                                                    {r.customer.name} {r.customer.lastname} {r.contractor && `(${r.contractor.name})`}
                                                </td>
                                                <td onClick={() => handleShow(r.id)}>
                                                    {r.visit_date
                                                        ? `${r.visit_date_dmy}`
                                                        : '-'
                                                    }
                                                </td>
                                                <td onClick={() => handleShow(r.id)}>
                                                    {r.visitperiod
                                                        ? `${r.visitperiod.period}`
                                                        : '-'
                                                    }
                                                </td>
                                                <td onClick={() => handleShow(r.id)}>
                                                    {r.initialstatus && r.initialstatus.translation.name}
                                                </td>
                                                <td onClick={() => handleShow(r.id)}>
                                                    {r.finalstatus && r.finalstatus.translation.name}
                                                </td>
                                                <td onClick={() => handleShow(r.id)}>
                                                    {r.total_price_formatted}
                                                    {r.currency ? r.currency.name : ''}
                                                </td>
                                                <td onClick={() => handleShow(r.id)}>
                                                    {r.paid_price_formatted}
                                                    {r.currency ? r.currency.name : ''}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>

                }

                <div className="footer align-center">
                    <button onClick={handleClose}>
                        {trans('buttons.close')}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default React.memo(forwardRef(IncommingCall), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});