import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef, useCallback } from 'react'
import L, { LatLng, marker } from "leaflet";
import 'leaflet-routing-machine'
import '../../../../assets/L.Routing.OpenRouteService'

//images
import closeIcon from '../../../../assets/img/icons/close.png'
import markerImg from '../../../../assets/img/leeflet/marker-icon.png';
import redMarkerImg from '../../../../assets/img/leeflet/marker-icon-red.png';

import '../../../../assets/css/modals/employees.css'
import '../../../../assets/css/leeflet.css';
import '../../../../assets/css/leaflet-routing-machine.css';
import { Maps } from '../../../../_config/maps';
import Skeleton from 'react-loading-skeleton';
import Axios from 'axios';
import loader from '../../../../assets/img/loader.svg';
import Api from '../../../../_helpers/Api';
import { useHistory } from 'react-router-dom';
import Dropdown from '../../../partials/Dropdown';
import { trans } from '../../../../_providers/Translation'
import '../../../../assets/Leaflet.fullscreen';
import '../../../../assets/css/leaflet.fullscreen.css';
import { Loader } from '@googlemaps/js-api-loader';

let timeout;

let cc = {};

const markerIcon = L.icon({
    iconUrl: markerImg,
    // shadowUrl: 'leaf-shadow.png',
    iconSize: [25, 41], // size of the icon
});

const redMarkerIcon = L.icon({
    iconUrl: redMarkerImg,
    // shadowUrl: 'leaf-shadow.png',
    iconSize: [25, 41], // size of the icon
});

const router = new L.Routing.openrouteservice(Maps.openrouteservice);

const googleMapsLoader = new Loader({
    apiKey: Maps.google,
    version: "weekly",
    libraries: ["places"],
});

function Address(props, ref) {

    const history = useHistory();
    const inputRef = useRef(null);

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        loading: false,
        loadingNearest: false,
        selected: null,
        lat: null,
        lon: null,
        place_id: null,
        data: [],
        dataLoaded: false,
        reservations: [],
        nearest: [],
        employees: [],
        nearestEmployees: [],
        onSave: null,
        showNearest: true,
        selectedEmployee: null,
    });

    const [google, setGoogle] = useState({
        AutocompleteService: null,
        Geocoder: null,
        places: null,
    });

    const initMarkers = {
        reservation: null,
        reservations: []
    }

    const [map, setMap] = useState(null);
    const [layerGroup, setLayerGroup] = useState(null);
    const [markers, setMarkers] = useState(initMarkers);

    useImperativeHandle(ref, () => ({
        open: () => {
            handleOpen()
        },
        close: () => {
            handleClose()
        },
        onSave: fn => {
            setState(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }))

    useEffect(() => {

        let m = L.map("address-map", {
            center: [42.70060440808085, 23.318481445312504],
            zoom: 5,
            layers: [
                L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                })
            ],
            fullscreenControl: true,
        });

        setLayerGroup(L.layerGroup().addTo(m));

        setMap(m);

    }, []);


    // местополежение на резервацията
    useEffect(() => {
        if (map) {

            if (state.lat && state.lon) {

                // console.log(props.params.lon)

                let marker = L.marker([state.lat, state.lon], {
                    icon: redMarkerIcon,
                });

                marker.bindPopup(trans('random.choosedLocation'), {
                    autoClose: false,
                    closeOnClick: false
                }).openPopup()

                setMarkers(prev => ({
                    ...prev,
                    reservation: marker
                }));

            } else {
                setMarkers(prev => ({
                    ...prev,
                    reservation: null
                }));
            }
        }

    }, [map, state.lat, state.lon]);

    // създадени резервации за периода
    useEffect(() => {
        if (Array.isArray(props.reservations)) {
            setState(prev => ({
                ...prev,
                reservations: props.reservations
            }));
        }
    }, [props.reservations]);

    // техници от създадените резервации за периода
    useEffect(() => {
        if (Array.isArray(props.employees)) {
            setState(prev => ({
                ...prev,
                employees: props.employees
            }));
        }
    }, [props.employees]);

    // сетване на маркерите
    useEffect(() => {
        let _markers = [];

        if (state.showNearest) {

            let reservations = state.nearest;

            if (state.selectedEmployee) {
                reservations = reservations.filter(r => {
                    return Number(r.employee_id) === Number(state.selectedEmployee);
                });
            }

            reservations.map(r => {
                let marker = L.marker([r.lat, r.lon], {
                    icon: markerIcon,
                    employee_id: r.employee_id
                });

                let content = L.DomUtil.create('div');

                let name = '';

                name += `${trans('random.technician')}: ` + (r.employee ? `${r.employee.name} ${r.employee.lastname}` : '-');
                name += '<br/>';
                name += `${trans('random.date')}: ` + r.visit_date_dmy;
                name += '<br/>';
                name += `${trans('random.hour')}: ` + (r.visitperiod ? r.visitperiod.period : '-');
                name += '<br/>';
                name += `${trans('random.address')}: ` + r.full_address;
                name += '<br/>';
                name += `${trans('random.refNo')} ` + r.reference_code;
                name += '<br/>';
                name += `${trans('random.client')}: ` + (r.customer ? `${r.customer.name} ${r.customer.lastname}` : '-');
                name += '<br/>';
                name += `${trans('random.phone')}: ` + (r.phone ? r.phone : '-');
                name += '<br/>';
                name += `${trans('random.category')}: ` + (r.appliancecategory && r.appliancecategory ? r.appliancecategory.name : '-');
                name += '<br/>';
                name += `${trans('random.make')}: ` + (r.appliancebrand ? r.appliancebrand.name : '-');
                name += '<br/>';
                name += `${trans('random.model')}: ` + (r.appliancemodel ? r.appliancemodel.name : '-');
                name += '<br/>';
                name += `${trans('random.distance')}: ` + r.distance.toFixed(2) + ' км.';

                name += '<button data-employee-id="' + r.employee_id + '" data-date="' + r.visit_date + '" data-visit-period-id="' + r.visit_period_id + '" class="add button-add button-extra-small" style="margin-top: 5px;">' + trans('buttons.add') + '</button>';

                content.innerHTML = name;

                L.DomEvent.addListener(content.querySelector('.add'), 'click', e => {
                    handleAddReservation(e);
                });

                marker.bindPopup(content, {
                    autoClose: false,
                    closeOnClick: false,
                });

                _markers.push(marker);

            });

        } else {

            let reservations = state.reservations;

            if (state.selectedEmployee) {
                reservations = reservations.filter(r => {
                    return Number(r.employee_id) === Number(state.selectedEmployee);
                });
            }

            reservations.map(r => {
                let marker = L.marker([r.lat, r.lon], {
                    icon: markerIcon,
                    employee_id: r.employee_id
                });

                let name = '';

                name += `${trans('random.technician')}: ` + (r.employee ? `${r.employee.name} ${r.employee.lastname}` : '-');
                name += '<br/>';
                name += `${trans('random.date')}: ` + r.visit_date_dmy;
                name += '<br/>';
                name += `${trans('random.hour')}: ` + (r.visitperiod ? r.visitperiod.period : '-');
                name += '<br/>';
                name += `${trans('random.address')}: ` + r.full_address;
                name += '<br/>';
                name += `${trans('random.refNo')} ` + r.reference_code;
                name += '<br/>';
                name += `${trans('random.client')}: ` + (r.customer ? `${r.customer.name} ${r.customer.lastname}` : '-');
                name += '<br/>';
                name += `${trans('random.phone')}: ` + (r.phone ? r.phone : '-');
                name += '<br/>';
                name += `${trans('random.category')}: ` + (r.appliancecategory && r.appliancecategory ? r.appliancecategory.name : '-');
                name += '<br/>';
                name += `${trans('random.make')}: ` + (r.appliancebrand ? r.appliancebrand.name : '-');
                name += '<br/>';
                name += `${trans('random.model')}: ` + (r.appliancemodel ? r.appliancemodel.name : '-');

                marker.bindPopup(name, {
                    autoClose: false,
                    closeOnClick: false,
                });

                _markers.push(marker);

            });
        }

        setMarkers(prev => ({
            ...prev,
            reservations: _markers
        }));

    }, [state.reservations, state.nearest, state.selectedEmployee, state.showNearest]);

    // сетване на маркерите
    useEffect(() => {
        if (map && layerGroup) {
            layerGroup.clearLayers();

            let allMarkers = getAllMarkers();

            if (allMarkers.length) {
                allMarkers.forEach(m => {
                    m.addTo(layerGroup);
                });
            }

        }
    }, [markers]);

    // избор на адрес
    useEffect(() => {
        handleSelect(props.selected);
    }, [props.selected]);

    // търсене на адрес
    useEffect(() => {
        findLocations();

        return () => {
            clearTimeout(timeout);
        }
    }, [state.selected, state.showNearest]);

    // търсене на близки резервации
    useEffect(() => {
        if (state.showNearest && state.lat && state.lon) {

            setState(prev => ({
                ...prev,
                loadingNearest: true
            }));

            let url = 'reservations/nearest-employees'
                + '?lat=' + state.lat
                + '&lon=' + state.lon
                + '&date=' + props.filter.start
                + '&end=' + props.filter.end
                + '&category_id=' + props.filter.category_id;

            Api.get(url)
                .then(res => {
                    if (res.data.reservations) {
                        setState(prev => ({
                            ...prev,
                            nearest: res.data.reservations,
                            nearestEmployees: res.data.employees,
                            selectedEmployee: res.data.reservations[0] ? res.data.reservations[0].employee_id : null,
                        }));
                    }
                })
                .finally(() => {
                    setState(prev => ({
                        ...prev,
                        loadingNearest: false
                    }));
                });
        }
    }, [state.showNearest, state.lat, state.lon, props.filter.start, props.filter.end]);

    useEffect(() => {
        if (!state.showNearest) {

            setMarkers(prev => ({
                ...prev,
                reservation: null,
            }));

            setState(prev => ({
                ...prev,
                nearest: [],
                nearestEmployees: [],
                data: [],
                lat: null,
                lon: null,
            }));
        }
    }, [state.showNearest]);

    // показване на маркерите и пътя
    useEffect(() => {

        if (!map) {
            return;
        }

        if (state.showNearest) {

            if (!markers.reservation) {
                return;
            }

            let marker1 = markers.reservation;

            let employeeMarkers = getEmployeeMarkers(state.selectedEmployee);

            if (employeeMarkers.length === 0) {
                return;
            }

            let groupArray = [marker1];

            employeeMarkers.map((marker2, index) => {

                // най-близкият маркер до избраната локация е първият елемент
                if (index === 0) {
                    // marker2.openPopup();
                    marker2.fire('click');
                }

                let coords1 = marker1.getLatLng();
                let coords2 = marker2.getLatLng();

                getRoute(coords1, coords2, (route) => {

                    if (!route) {
                        return;
                    }

                    let line = L.Routing.line(route).addTo(layerGroup);
                });

                groupArray.push(marker2);

            });

            let group = new L.featureGroup(groupArray);

            console.log('fit to markers')

            map.fitBounds(group.getBounds(), {
                // padding: [150, 200]
            });
        } else {

            let markers;

            if (state.selectedEmployee) {
                markers = getEmployeeMarkers(state.selectedEmployee);
            } else {
                markers = getAllMarkers();
            }

            if (markers.length) {
                let group = new L.featureGroup(markers);

                map.fitBounds(group.getBounds(), {
                    // padding: [150, 200]
                });
            }
        }
    }, [map, state.showNearest, state.selectedEmployee, markers.reservations]);

    // при показване на попъпа
    useEffect(() => {
        if (state.overlay && state.modal) {
            if (map) {
                map.invalidateSize();
            }
        }
    }, [map, state.overlay, state.modal]);

    useEffect(() => {
        googleMapsLoader.load()
            .then(google => {
                setGoogle({
                    AutocompleteService: new google.maps.places.AutocompleteService(),
                    Geocoder: new google.maps.Geocoder(),
                    maps: google.maps
                });
            });
    }, []);

    const getEmployeeMarkers = id => {
        return markers.reservations.filter(marker => {
            return Number(marker.options.employee_id) === Number(id);
        });
    }

    const handleAddReservation = e => {
        let employeeId = e.target.getAttribute('data-employee-id');
        let date = e.target.getAttribute('data-date');
        let periodId = e.target.getAttribute('data-visit-period-id');

        if (employeeId && date && periodId) {
            date = date.split(' ')[0];

            let url = '/reservations/address/add'
                + '?employee_id=' + employeeId
                + '&date=' + date
                + '&visit_period_id=' + periodId
                + '&address=' + state.selected
                + '&lat=' + state.lat
                + '&lon=' + state.lon
                + '&redirect=schedule';

            // history.push(url);
            window.location = url;
        }
    }

    const handleOpen = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)

    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 50)
    }

    const getAllMarkers = () => {

        let array = [];

        if (markers.reservation) {
            array.push(markers.reservation);
        }

        markers.reservations.forEach(m => {
            array.push(m);
        });

        return array;
    }

    const handleSelect = (name) => {
        setState(prev => ({
            ...prev,
            selected: name,
        }));
    }

    const handleSelectLatLon = (lat, lon, place_id = null) => {
        setState(prev => ({
            ...prev,
            lat: lat,
            lon: lon,
            place_id: place_id
        }));
    }

    const handleSelectEmployee = id => {
        setState(prev => ({
            ...prev,
            selectedEmployee: id
        }));
    }

    const getRoute = (fromLatLon, toLatLon, callback) => {

        let key = String(fromLatLon.lat) + String(fromLatLon.lng) + String(toLatLon.lat) + String(toLatLon.lng);

        if (cc[key]) {
            return callback(cc[key]);
        }

        let waypoints = [];

        waypoints.push({ latLng: fromLatLon });
        waypoints.push({ latLng: toLatLon });

        router.route(waypoints, (err, routes) => {
            if (err) {
                console.log(err);
            } else {
                cc[key] = routes[0];

                return callback(routes[0]);
            }
        });

        return callback(null);
    }

    const handleSearch = e => {
        let name = e.target.value;

        setState(prev => ({
            ...prev,
            selected: name,
        }));

        if (typeof props.handleSearch === 'function') {
            props.handleSearch('search', name);
        }
    }

    const findLocations = () => {

        if (!state.selected || !state.showNearest) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            // findInOSM(state.selected);
            findInGoogle(state.selected);
        }, 900);
    }

    const findInOSM = (name) => {
        Axios.get('https://nominatim.openstreetmap.org/search', {
            params: {
                q: name,
                limit: 50,
                format: 'json'
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data
            }));
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false
            }))
        });
    }

    const findInGoogle = async (name) => {
        if (!google.AutocompleteService) {
            return;
        }

        // let sessionToken = new google.maps.places.AutocompleteSessionToken();

        // console.log(sessionToken);

        google.AutocompleteService.getQueryPredictions({ input: name }, async (predictions, status) => {

            if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
                setState(prev => ({
                    ...prev,
                    data: [],
                    loading: false,
                }));

                return;
            }

            predictions = predictions.map((prediction) => {
                prediction.display_name = prediction.description;

                return prediction;
            });

            // predictions = predictions.filter(p => p.geocode.length > 0);

            // console.log(predictions);

            setState(prev => ({
                ...prev,
                data: predictions,
                loading: false,
            }));
        });
    }

    const handleSelectGooglePlace = place => {
        decodeGooglePlace(place, (results) => {
            let geocode = results[0];

            let lat = geocode.geometry.location.lat();
            let lon = geocode.geometry.location.lng();

            handleSelectLatLon(lat, lon, place.place_id);

        });
    }

    const decodeGooglePlace = (place, callback) => {
        if (!google.Geocoder) {
            return;
        }

        google.Geocoder
            .geocode({ placeId: place.place_id })
            .then(({ results }) => {
                return callback(results);
            });
    };

    // const setMarker = e => {
    //     if (state.showNearest && e.latlng) {
    //         handleSelectLatLon(e.latlng.lat, e.latlng.lng)
    //     }
    // }

    const setMarker = useCallback(e => {
        if (state.showNearest && e.latlng) {
            handleSelectLatLon(e.latlng.lat, e.latlng.lng)
        }
    }, [state.showNearest]);

    useEffect(() => {
        if (map) {
            map.on('click', setMarker);
        }

        return () => {
            if (map) {
                map.off('click', setMarker);
            }
        }

    }, [map, setMarker]);

    const toggleShowNearest = bool => {
        setState(prev => ({
            ...prev,
            showNearest: bool
        }));
    }

    const handleCategory = data => {

        let val = data.id || '';

        if (data.id || data.name == '') {
            props.handleSearch('category_id', val);
        }

    }

    return (
        <>

            <div className={`${state.overlay ? 'visible' : ''} overlay`}>

                <div className={`${state.modal ? 'show' : ''} popup-primary`}>

                    <h2 className="head">
                        {trans('headings.schedule')}
                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleClose} />
                    </h2>

                    <div className="filter-standard" style={{ paddingTop: 0 }}>
                        <div className="col">
                            <label>
                                {trans('filters.startDate')}
                            </label>
                            <input
                                name="start"
                                type="date"
                                value={props.filter.start}
                                onChange={e => props.handleSearch(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <label>
                                {trans('filters.endDate')}
                            </label>
                            <input
                                // name="end"
                                type="date"
                                value={props.filter.end}
                                // onChange={e => props.handleSearch(e.target.name, e.target.value)}
                                disabled
                            />
                        </div>

                        <div className="col">
                            <label>
                                {trans('filters.category')}
                            </label>

                            <Dropdown
                                inputIdValue={Number(props.filter.category_id)}
                                url="appliances/categories/all"
                                renderText={data => {
                                    return data.name
                                }}
                                onChange={data => {
                                    handleCategory(data)
                                }}
                            />
                        </div>

                        <div className="col" style={{ justifyContent: 'center' }}>
                            <label className="flex">
                                <input
                                    type="radio"
                                    checked={state.showNearest}
                                    onClick={() => toggleShowNearest(true)}
                                />

                                <span>{trans('messages.showEmployees')}</span>
                            </label>
                        </div>

                        <div className="col" style={{ justifyContent: 'center' }}>
                            <label className="flex">
                                <input
                                    type="radio"
                                    checked={!state.showNearest}
                                    onClick={() => toggleShowNearest(false)}
                                />

                                <span>{trans('messages.showAllReservations')}</span>
                            </label>
                        </div>
                    </div>

                    <div className="alert info">
                        {state.showNearest
                            ?
                            <>
                                {trans('messages.showNearest1')}
                            </>
                            :
                            <>
                                {trans('messages.showNearest2')}
                            </>
                        }
                    </div>

                    <div id="map-wrapper" style={{ height: '45vh' }}>
                        <div className="left">
                            <input
                                ref={inputRef}
                                type="search"
                                name="search"
                                value={state.selected ? state.selected : ''}
                                onChange={handleSearch}
                                style={{
                                    width: '100%'
                                }}
                            />
                            {state.loading
                                ?
                                <img className="loader" src={loader} />
                                :
                                <ul>
                                    {state.data.map(e =>
                                        <li
                                            key={'e-' + e.place_id}
                                            // onClick={() => handleSelectLatLon(e.lat, e.lon, e.place_id)}
                                            onClick={() => handleSelectGooglePlace(e)}
                                            // className={String(e.lat) === String(state.lat) && String(e.lon) === String(state.lon) ? 'active' : ''}
                                            className={e.place_id === state.place_id ? 'active' : ''}
                                        >
                                            <span>
                                                {e.display_name}
                                            </span>

                                        </li>
                                    )}

                                </ul>
                            }
                        </div>

                        <div className="right">
                            <div
                                id="address-map"
                                className="map"
                                style={state.loadingNearest
                                    ?
                                    { filter: 'blur(3px)', pointerEvents: 'none' }
                                    :
                                    {}
                                }
                            >

                            </div>

                            <div className="left options">
                                <ul>
                                    {state[state.showNearest ? 'nearestEmployees' : 'employees'].map(e =>
                                        <li
                                            key={'e-' + e.id}
                                            className={Number(e.id) === Number(state.selectedEmployee) ? 'active' : ''}
                                            onClick={() => handleSelectEmployee(e.id)}
                                            style={{ display: 'flex', alignItems: 'center' }}
                                        >
                                            <span>
                                                {e.name} {e.lastname}
                                            </span>
                                            <div className="badge">{e.reservations_count || '0'}</div>
                                        </li>
                                    )}
                                </ul>
                            </div>

                        </div>

                    </div>

                    <div className="footer align-center">
                        <button onClick={handleClose}>
                            {trans('buttons.close')}
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default React.memo(forwardRef(Address), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});