import React, { useState, useRef } from 'react'
import Menu from '@material-ui/core/Menu'
import { useHistory } from 'react-router-dom'
import { trans } from '../../../../_providers/Translation';

//images
import menuIcon from '../../../../assets/img/icons/table-menu.png'
import editIcon from '../../../../assets/img/icons/edit.png'
import deleteIcon from '../../../../assets/img/icons/delete.png'
import mailIcon from '../../../../assets/img/icons/single-mail.png'

//modals
import Question from '../../../modals/messages/Question'
import Success from '../../../modals/messages/Success'
import Error from '../../../modals/messages/Error'
import Api from '../../../../_helpers/Api'

function Index(props) {

    let history = useHistory()
    //refs
    const questionModalRef = useRef(null)
    const reportSuccessModalRef = useRef(null)
    const reportFailModalRef = useRef(null)
    const confirmationSuccessModalRef = useRef(null);
    const confirmationFailModalRef = useRef(null);

    const [table, setTable] = useState({
        options: null
    })

    const handleTableMenu = e => {
        e.stopPropagation();

        setTable(table => {
            return {
                ...table,
                options: e.currentTarget
            }
        })
    }

    const handleCloseMenu = (e = null) => {
        if (e) {
            e.stopPropagation();
        }

        setTable(table => {
            return {
                ...table,
                options: null
            }
        })
    }

    const handleEdit = () => {
        history.push('/reservations/edit/' + props.id);
    }

    const handleDelete = e => {
        let modal = questionModalRef.current;

        handleCloseMenu(e);

        modal.open();

        modal.onSuccess(() => {
            handleDestroy();
        });
    }

    const handleDestroy = () => {
        Api.post(`reservations/delete`, {
            id: props.id
        }).then(res => {
            props.handleRefresh(props.masterId);
        })
    }

    const handleHistory = e => {
        history.push('/reservations/edit/' + props.id + '?tab=history');
    }

    const handleSendReport = e => {
        handleCloseMenu(e);

        Api.post('reservations/send-report', {
            id: props.id
        }).then(res => {
            if (res.data.success) {

                reportSuccessModalRef.current.open();

                reportSuccessModalRef.current.onClose(() => {
                    props.handleRefresh(props.masterId);
                });

            } else {
                reportFailModalRef.current.open();
            }
        }).catch(err => {
            reportFailModalRef.current.open();
        });
    }

    const handleSendConfirmation = e => {
        handleCloseMenu(e);

        Api.post('reservations/send-confirmation-notification', {
            id: props.id
        }).then(res => {
            if (res.data.success) {
                confirmationSuccessModalRef.current.open();
            } else {
                confirmationFailModalRef.current.open();
            }
        }).catch(err => {
            confirmationFailModalRef.current.open();
        })
    }

    return (
        <>
            <Question
                ref={questionModalRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
            />

            <Success
                ref={reportSuccessModalRef}
                mainMessage={trans('headings.success14')}
            />

            <Error
                ref={reportFailModalRef}
                mainMessage={trans('headings.error1')}
            />

            <Success
                ref={confirmationSuccessModalRef}
                mainMessage={trans('headings.success3')}
            />

            <Error
                ref={confirmationFailModalRef}
                mainMessage={trans('headings.error2')}
            />

            <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />

            <Menu
                anchorEl={table.options}
                keepMounted
                open={Boolean(table.options)}
                onClose={handleCloseMenu}
            >
                <div className="menu-option" onClick={handleEdit}>
                    <img src={editIcon} alt="edit" />
                    {trans('buttons.view')}
                </div>

                <div className="menu-option" onClick={handleHistory}>
                    <img src={editIcon} alt="edit" />
                    {trans('buttons.history')}
                </div>

                {/* {props.data.can_send_confirmation &&
                    <div className="menu-option" onClick={handleSendConfirmation}>
                        <img src={mailIcon} alt="edit" />
                        Изпрати известие за потвърждение
                    </div>
                } */}

                {props.data.can_send_report &&
                    <div className="menu-option" onClick={handleSendReport}>
                        <img src={mailIcon} alt="edit" />
                        {trans('buttons.sendReport')} {props.data.send_reports > 0 ? `(${props.data.send_reports})` : ''}
                    </div>
                }
                <div className="menu-option" onClick={handleDelete}>
                    <img src={deleteIcon} alt="delete" />
                    {trans('buttons.delete')}
                </div>
            </Menu>
        </>
    )
}

export default Index;
