import React, { useEffect } from 'react';
import { trans } from '../../../_providers/Translation'

function Storage(props) {

    return (
        <table>
            <thead>
                <tr>
                    <th>{trans('tables.activity')}</th>
                    <th>{trans('tables.date')}</th>
                    <th>{trans('tables.employee')}</th>
                    <th>{trans('tables.category')}</th>
                    <th>{trans('tables.name')}</th>
                    <th>{trans('tables.partNumber')}</th>
                    <th>{trans('tables.quantity')}</th>
                    <th>{trans('tables.location')}</th>
                    <th>{trans('tables.reservation')}</th>
                    <th>{trans('tables.supplier')}</th>
                    <th>{trans('tables.price')}</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((h, i) => (
                    <tr key={i} className={[h.actual ? 'actual' : h.selected ? 'selected' : '']}>
                        <td>
                            {h.event}
                        </td>
                        <td>{h.date}</td>
                        <td>{h.causer && `${h.causer.name} ${h.causer.lastname}`}</td>
                        <td>{h.subject.part && h.subject.part.category ? h.subject.part.category.name : '-'}</td>
                        <td>{h.subject.part ? h.subject.part.name : '-'}</td>
                        <td>{h.subject.part ? h.subject.part.number : '-'}</td>
                        <td>{h.subject.quantity}</td>
                        <td>
                            {h.subject.position ? h.subject.position.translation.name : '-'}
                            <br />
                            {h.subject.van && h.subject.van.reg_no}

                            {h.subject.van && h.subject.van.employee && `(${h.subject.van.employee.name})`}
                        </td>
                        <td>
                            {h.subject.reservation
                                ?
                                <a href={'/reservations/edit/' + h.subject.reservation_id} target="_blank">
                                    {h.subject.reservation.reference_code}
                                </a>
                                : '-'
                            }
                        </td>

                        <td>{h.subject.supplier ? h.subject.supplier.name : '-'}</td>
                        <td>{h.subject.net_price_format} {h.subject.currency && h.subject.currency.name}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default Storage;