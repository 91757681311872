import React, { useRef } from 'react';
import loader from '../../../../assets/img/loader.svg';
import { useHistory, useLocation } from 'react-router-dom';
import UpcomingReservations from '../../../modals/UpcomingReservations';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createDragDropManager } from 'dnd-core';
import TableRow from './TableRow';
import { fromInfo, trans } from '../../../../_providers/Translation'
import { useAuthDataContext } from '../../../../_providers/Auth';
import Help from '../../../partials/Help';

export const manager = createDragDropManager(HTML5Backend)

function Table(props) {

    const auth = useAuthDataContext();
    const history = useHistory();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const upcomingReservationsRef = useRef(null);

    const getReservations = (employeeId, time, periodId) => {

        let reservations = props.data.reservations.filter(r => {
            let match = Number(r.employee_id) === Number(employeeId) &&
                String(r.visit_date_timestamp) === String(time) &&
                Number(r.visit_period_id) === Number(periodId);

            return match;
        });

        return reservations;
    }

    const handleAddReservation = (employeeId, time, periodId) => {

        let date = new Date(Number(time + '000'));

        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, 0);
        let day = date.getDate().toString().padStart(2, 0);


        let fulldate = [year, month, day].join('-');

        let url = '/reservations/address/add'
            + '?employee_id=' + employeeId
            + '&date=' + fulldate
            + '&visit_period_id=' + periodId
            + '&redirect=schedule';

        history.push(url);
    }


    const handleShowReservations = (id, date) => {
        upcomingReservationsRef.current.open(id, date);
    }

    const tdDayStyle = {
        width: '11%'
    };

    const moveCard = (dragIndex, hoverIndex, reservations) => {
        let dragCard = reservations[dragIndex];
        let hoverCard = reservations[hoverIndex];

        props.moveCard(dragCard, hoverCard);
    }

    const renderCard = (data, index, employeeId, time, periodId) => {
        let reservations = getReservations(employeeId, time, periodId);

        return (
            <TableRow
                key={data.id}
                index={index}
                id={data.id}
                data={data}
                state={reservations}
                moveCard={(dragIndex, hoverIndex) => moveCard(dragIndex, hoverIndex, reservations)}
                changePriority={props.changePriority}
            />
        )
    }

    const handleShowAppliances = id => {
        // console.log(id)

        let el1 = document.querySelector('.employee-appliances[data-id="' + id + '"]');
        let el2 = document.querySelector('.employee-appliances-all[data-id="' + id + '"]');

        // console.log(el1);
        // console.log(el2);

        if (el1 && el2) {
            el1.style.display = 'none';
            el2.style.display = 'initial';
        }
    }

    const handleHideAppliances = id => {
        // console.log(id)

        let el1 = document.querySelector('.employee-appliances[data-id="' + id + '"]');
        let el2 = document.querySelector('.employee-appliances-all[data-id="' + id + '"]');

        // console.log(el1);
        // console.log(el2);

        if (el1 && el2) {
            el1.style.display = 'initial';
            el2.style.display = 'none';
        }
    }

    const hasFreeDay = (employee, date) => {
        // console.log(employee);
        // console.log(date)

        return Boolean(employee.freedays.find(day => day.date === date));
    }

    return (
        <>

            {props.loading
                ?
                <img src={loader} alt="loading" />
                :
                props.data.employees.map(e =>
                    <React.Fragment key={'emp-' + e.id}>
                        <div style={{ position: 'relative' }}>
                            <Help
                                style={{ position: 'absolute', top: '50%', left: '-20px' }}
                                position="left-center"
                            >
                                {fromInfo('schedule.upcomingReservations')}
                            </Help>
                            <div className="table-scroller">
                                <table className="schedule">
                                    <thead>
                                        <tr>
                                            <th className="head" colSpan="8">
                                                {e.name} {e.lastname}
                                                {e.appliances.length
                                                    ?
                                                    <span style={{ fontWeight: 'normal', marginLeft: '5px' }}>
                                                        (
                                                        <span className="employee-appliances" data-id={e.id}>

                                                            {e.appliances.map(a => a.name).filter((e, i) => e !== '' && i < 3).join(', ')}

                                                            {e.appliances.length > 3 &&
                                                                <span style={{
                                                                    padding: '3px 5px',
                                                                    background: '#bdd1b5',
                                                                    borderRadius: '3px',
                                                                    marginLeft: '5px',
                                                                    cursor: 'pointer',
                                                                    fontSize: '14px',
                                                                }}
                                                                    onClick={ev => handleShowAppliances(e.id)}
                                                                >
                                                                    ...
                                                                </span>
                                                            }

                                                        </span>

                                                        <span className="employee-appliances-all" data-id={e.id} style={{ display: 'none' }}>
                                                            {e.appliances.map(a => a.name).filter((e, i) => e !== '').join(', ')}

                                                            <span style={{
                                                                padding: '3px 5px',
                                                                background: '#bdd1b5',
                                                                borderRadius: '3px',
                                                                marginLeft: '5px',
                                                                cursor: 'pointer',
                                                                fontSize: '14px',
                                                            }}
                                                                onClick={ev => handleHideAppliances(e.id)}
                                                            >
                                                                ...
                                                            </span>
                                                        </span>
                                                        )
                                                    </span>
                                                    :
                                                    ''
                                                }
                                            </th>
                                        </tr>
                                        <tr>
                                            <th style={{ width: '23%' }}>
                                                {trans('tables.visitTime')}
                                            </th>
                                            {Object.values(props.data.dates).map(d =>
                                                <th key={'thd-' + d.date} style={{ ...tdDayStyle, backgroundColor: hasFreeDay(e, d.date_ymd) ? '#ffdd7e' : '' }}>
                                                    {d.date} {d.day}
                                                    <br />
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {hasFreeDay(e, d.date_ymd)
                                                            ?
                                                            <span>{trans('labels.freeDay')}</span>
                                                            :

                                                            <a
                                                                className="show-map"
                                                                onClick={() => handleShowReservations(e.id, d.date_ymd)}
                                                            >
                                                                {trans('tables.map')}
                                                            </a>
                                                        }
                                                        {/* <span style={{ margin: '0 5px', background: '#ccc', width: '1px', height: '8px', display: 'inline-block' }}></span>
                                                    <a
                                                        className="show-map"
                                                        onClick={() => handleReorder(e.id)}
                                                    >
                                                        подреждане
                                                    </a> */}
                                                    </div>
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {props.data.periods.map(p =>
                                            <tr key={'p-' + p.id}>
                                                <td>
                                                    {p.period}
                                                </td>
                                                {Object.entries(props.data.dates).map((date, i) =>
                                                    <td key={'tdd-' + date[0]} style={{ ...tdDayStyle }}>
                                                        <DndProvider manager={manager} key={1}>
                                                            {getReservations(e.id, date[0], p.id).map((r, i) =>
                                                                renderCard(r, i, e.id, date[0], p.id)
                                                            )}
                                                        </DndProvider>

                                                        {!hasFreeDay(e, date[1].date_ymd)
                                                            ?
                                                            <button className="button-add button-extra-small" onClick={() => handleAddReservation(e.id, date[0], p.id)}>
                                                                {trans('buttons.add')}
                                                            </button>
                                                            :
                                                            ''
                                                        }
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                    </React.Fragment>
                )

            }

            <UpcomingReservations
                ref={upcomingReservationsRef}
            />

        </>
    )
}

export default Table;