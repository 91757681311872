import React, { useEffect, useState, useRef, useCallback } from 'react';
import Api from '../../../_helpers/Api';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import loader from '../../../assets/img/loader.svg';
import { useAuthDataContext } from '../../../_providers/Auth';
import Table from './partials/Table';
import Filter from './partials/Filter';
import update from 'immutability-helper';
import Address from './partials/Address';
import { fromInfo, trans } from '../../../_providers/Translation'
import Help from '../../partials/Help';

let timeout;

function Schedule(props) {

    const auth = useAuthDataContext();
    const location = useLocation();
    const history = useHistory();
    const params = useParams();

    const searchParams = new URLSearchParams(location.search);

    const addressModalRef = useRef(null);

    const [data, setData] = useState({
        loading: true,
        data: [],
        filter: {
            start: '',
            end: '',
            employees: [],
            search: ''
        },
        update: false,
        dragId: null,
        dropId: null,
    });

    useEffect(() => {

        setData(prev => ({
            ...prev,
            filter: {
                start: searchParams.get('start') || '',
                // end: searchParams.get('end') || '',
                employees: searchParams.getAll('employees[]') || [],
                search: searchParams.get('search') || '',
                group_id: searchParams.get('group_id') || '',
                category_id: searchParams.get('category_id') || '',
            },
            update: new Date().getTime(),
        }));

    }, [location.search])

    useEffect(() => {

        if (data.setFilter) {

            Object.entries(data.filter).map(filter => {

                if (Array.isArray(filter[1])) {
                    let name = filter[0] + '[]';

                    searchParams.delete(name);

                    filter[1].map(v => {
                        searchParams.append(name, v);
                    });

                } else {
                    searchParams.set(filter[0], filter[1]);
                }

            });

            history.push('?' + searchParams.toString());
        }

    }, [data.setFilter]);


    useEffect(() => {
        loadData();
    }, [data.update]);

    // read
    const loadData = () => {

        setData(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'reservations/schedule?' + searchParams.toString();

        Api.get(url)
            .then(res => {
                setData(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data,
                    filter: {
                        ...prev.filter,
                        start: res.data.start,
                        employees: res.data.employees_ids,
                        end: res.data.end
                    },
                }));
            }).catch(err => {
                setData(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleEmployees = ids => {
        setData(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                employees: ids,
                group_id: null
            },
            setFilter: new Date().getTime()
        }));
    }

    const handleGroup = id => {
        setData(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                employees: id === '' ? [] : prev.filter.employees,
                group_id: id
            },
            setFilter: new Date().getTime()
        }));
    }

    const handleSearch = (key, val) => {

        clearTimeout(timeout);

        setData(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            }
        }));

        timeout = setTimeout(() => {
            setData(prev => ({
                ...prev,
                setFilter: new Date().getTime()
            }));
        }, 300);

    }

    const moveCard = useCallback(
        (dragCard, hoverCard) => {

            let obj = [...data.data.reservations];

            let dragIndex = obj.findIndex(r => Number(r.id) === Number(dragCard.id));
            let hoverIndex = obj.findIndex(r => Number(r.id) === Number(hoverCard.id));

            setData(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    reservations: update(data.data.reservations, {
                        $splice: [
                            [dragIndex, 1],
                            [hoverIndex, 0, dragCard],
                        ],
                    })
                },
                dragId: dragCard.id,
                dropId: hoverCard.id
            }))
        },
        [data.data]
    )

    const changePriority = ids => {
        // Api.post('reservations/set-priority', { ids: [data.dragId, data.dropId] });
        Api.post('reservations/set-priority', { ids: ids });
    }

    const showMap = () => {
        addressModalRef.current.open();
    }

    return (
        <>

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.schedule')} <Help modalStyle={{ maxWidth: '800px' }}>{fromInfo('schedule')}</Help>
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.schedule')}
                        </p>
                    </div>

                </div>

                <Address
                    ref={addressModalRef}
                    selected={data.filter.search}
                    filter={data.filter}
                    reservations={data.data.reservations}
                    employees={data.data.employees}
                    handleSearch={handleSearch}
                />

                <Filter
                    data={data.filter}
                    handleSearch={handleSearch}
                    handleGroup={handleGroup}
                    handleEmployees={handleEmployees}
                    showMap={showMap}
                />

                <Table
                    data={data.data}
                    loading={data.loading}
                    moveCard={moveCard}
                    changePriority={changePriority}
                />

            </div>
        </>
    )
}

export default Schedule;