import React, { createContext, useContext, useMemo, useState } from 'react';
import Api from '../_helpers/Api';

export const LanguageContext = createContext({});

const LanguageProvider = props => {

    const [languages, setLanguages] = useState([]);

    useMemo(() => {
        Api.get('languages')
            .then(res => {
                setLanguages(res.data);
            });
    }, []);

    const data = useMemo(() => (languages), [languages]);

    return <LanguageContext.Provider value={data} {...props} />;
}

export const useLanguageContext = () => useContext(LanguageContext);

export default LanguageProvider;