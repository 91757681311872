import React, { useEffect, useState, useRef } from 'react'
import Table from './partials/Table'
import Api from '../../../_helpers/Api'
import Pagination from '../../Pagination'
import { useHistory, useLocation } from 'react-router-dom'
import { fromInfo, trans } from '../../../_providers/Translation'

//modals
import AddOrEdit from './partials/AddOrEdit'
import Question from '../../modals/messages/Question'
import Filter from './partials/Filter'
import PartsPages from '../../partials/PartsPages'
import Help from '../../partials/Help'

let timeout;

function Parts() {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const modalAddOrEditRef = useRef(null)
    const modalQuestionRef = useRef(null)

    const [parts, setParts] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setParts(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                category_id: searchParams.get('category_id') || '',
                name: searchParams.get('name') || '',
                number: searchParams.get('number') || '',
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {
        if (parts.setFilter) {
            let url = '?page=' + parts.page;

            Object.entries(parts.filter).map(filter => {
                if (filter[1]) {
                    url += '&' + filter[0] + '=' + filter[1];
                }
            });
            history.push(url);
        }
    }, [parts.setFilter]);


    useEffect(() => {
        if (parts.update) {
            loadParts()
        }
    }, [parts.update])

    //read
    const loadParts = () => {
        setParts(prev => ({
            ...prev,
            loading: true
        }))

        let url = 'parts/all?page=' + parts.page;

        Object.entries(parts.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setParts(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }))
            })
            .catch(() => {
                setParts(prev => ({
                    ...prev,
                    loading: false
                }))
            })
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add()
    }

    const handleCreate = part => {
        // let data = partners.data.concat(partner)

        // setPartners(prev => ({
        //     ...prev,
        //     data: data
        // }))
        setParts(prev => ({
            ...prev,
            update: Math.random().toString()
        }))
    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id)
    }

    const handleUpdate = part => {
        let data = parts.data.map(i => {
            if (i.id == part.id) {
                i = part
            }

            return i
        })

        setParts(prev => ({
            ...prev,
            data: data
        }))
    }

    // delete
    const handleDelete = id => {
        Api.post('parts/delete', {
            id: id
        })
            .then(res => {
                if (res.data.success) {
                    // let data = parts.data.filter(i => {
                    //     return i.id !== id
                    // })
                    setParts(prev => ({
                        ...prev,
                        update: Math.random().toString()
                    }))
                }
            })
    }

    // Question modal
    const handleOpenQuestion = id => {
        modalQuestionRef.current.open(id)
    }

    // Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setParts(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },

        }));

        timeout = setTimeout(() => {
            setParts(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const handlePage = page => {
        setParts(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    return (
        <>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
                callback={handleDelete}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.parts')} <Help>{fromInfo('parts')}</Help>
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.parts')}
                        </p>
                    </div>
                    <div className="right">
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.create')}
                        </button>
                    </div>
                </div>

                <PartsPages />

                <Filter
                    filter={parts.filter}
                    handleSearch={handleSearch}
                />

                <Table
                    parts={parts.data}
                    loading={parts.loading}
                    handleEdit={handleEdit}
                    handleDelete={handleOpenQuestion}
                />

                {!parts.loading
                    ?
                    <Pagination
                        pages={parts.pages}
                        page={parts.page}
                        handlePage={handlePage}
                    />
                    :
                    ''
                }
            </div>
        </>
    )
}

export default Parts