import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import checkIcon from '../../../../assets/img/icons/check.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import Api from '../../../../_helpers/Api';
import { useNotificationsContext } from '../../../../_providers/Notifications';
import { trans } from '../../../../_providers/Translation';

function SetPaymentMethod(props) {

    const notifications = useNotificationsContext();
    const tipRef = useRef(null);

    const tip = 'tip-' + Math.random().toString();

    const [state, setState] = useState({
        selected: null,
        original: null,
    });

    useEffect(() => {
        const selected = props.selected;

        setState(prev => ({
            ...prev,
            selected: selected,
            original: selected
        }));
    }, [props.selected]);

    const handleChange = e => {
        let val = e.target.value;

        let selected = props.methods.find(e => Number(e.id) === Number(val));

        setState(prev => ({
            ...prev,
            selected: selected
        }));
    }

    const handleSave = () => {
        setState(prev => ({
            ...prev,
            original: prev.selected
        }));

        Api.post('reservations/payments/set', {
            payment_id: props.paymentId,
            payment_method_id: state.selected?.id || ''
        }).then(res => {
            if (res.data.id) {
                notifications.add(trans('messages.paymentStatsChanged'));
            }
        });

        handleHide();
    }

    const handleShow = () => {
        document.getElementById(tip).classList.add('show');
    }

    const handleHide = () => {
        // ReactTooltip.hide();
        document.getElementById(tip).classList.remove('show');
    }


    return (
        <>
            <a
                data-tip
                data-for={tip}
                data-event="click"
                // data-eventOff="click"
                style={{ borderBottom: '1px dashed', cursor: 'pointer' }}
                onClick={handleShow}
            >
                {state.original
                    ?
                    state.original.translation
                        ?
                        state.original.translation.name
                        :
                        state.original.name
                    :
                    '-'
                }
            </a>
            <ReactTooltip
                ref={tipRef}
                id={tip}
                effect="solid"
                type="light"
                place="top"
                border
                borderColor="#cccccc"
                className="payment-tooltip"
                // globalEventOff='click'
                scrollHide={false}
                delayShow={300}
                delayHide={0}
                clickable
                isCapture
            >

                <div className="wrap">
                    <select
                        value={state.selected ? state.selected.id : ''}
                        onChange={handleChange}
                    >
                        <option value="">{trans('labels.select')}</option>
                        {props.methods.map(m =>
                            <option
                                key={'m-' + m.id}
                                value={m.id}
                            >
                                {m.translation && m.translation.name}
                            </option>
                        )}

                    </select>

                    <button className="ok" onClick={handleSave}>
                        <img src={checkIcon} height="14" />
                    </button>
                    <button className="close" onClick={handleHide}>
                        <img src={closeIcon} height="14" />
                    </button>
                </div>
            </ReactTooltip>
        </>
    )
}

export default SetPaymentMethod;