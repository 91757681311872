import React, { useEffect, useState } from 'react';
import Options from './Options';
import loader from '../../../../assets/img/loader.svg';
import { Link } from 'react-router-dom';
import notFound from '../../../../assets/img/not-found.png'
import Skeleton from 'react-loading-skeleton';
import Api from '../../../../_helpers/Api';
import checkIcon from '../../../../assets/img/icons/check.png';
import ReactTooltip from 'react-tooltip';
import { trans } from '../../../../_providers/Translation'

function TableOfficeStock(props) {

    return (

        props.parts.length === 0
            ?
            <div className="not-found">
                <img src={notFound} alt="Not Found" />
                <p>
                    {trans('messages.noResultsFound')}.
                </p>
            </div>
            :
            <>
                <div className="table-scroller">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>{trans('tables.name')}</th>
                                <th>{trans('tables.supplier')}</th>
                                <th>{trans('tables.partNumber')}</th>
                                <th className="center">{trans('tables.quantity')}</th>
                                <th>{trans('tables.storage')}</th>
                                <th>{trans('tables.row')}</th>
                                <th>{trans('tables.box')}</th>
                                <th>{trans('tables.category')}</th>
                                <th>{trans('tables.grossPrice')}</th>
                                <th>{trans('tables.status')}</th>
                                <th>{trans('tables.addedOn')}</th>
                                <th className="center">{trans('tables.image')}</th>
                                <th className="options">{trans('tables.options')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.parts.map(p =>
                                <tr key={'p-' + p.id}>
                                    <td>
                                        {p.id}
                                    </td>
                                    {/* <td>{p.part.category.name}</td> */}

                                    <td>
                                        {p.part.name}
                                    </td>
                                    <td>
                                        {p.supplier ? p.supplier.name : ''}
                                        <br />
                                        {p.po_number ? `PO: ${p.po_number}` : ''}
                                    </td>

                                    <td>
                                        {p.part.number}
                                    </td>
                                    <td className="center">
                                        {p.quantity}
                                    </td>


                                    <td>
                                        {p.storage?.name}
                                    </td>
                                    <td>
                                        {p.shelve}
                                    </td>
                                    <td>
                                        {p.box}
                                    </td>

                                    <td>
                                        {p.part.category
                                            ?
                                            p.part.category.name
                                            :
                                            ''
                                        }
                                    </td>

                                    <td>{p.gross_price_format} {p.currency.name}</td>

                                    <td>
                                        {p.in_stock
                                            ?
                                            trans('random.inStockSec')
                                            :
                                            trans('random.noStock')
                                        }
                                    </td>

                                    <td>{p.add_date}</td>

                                    <td className="center">
                                        <img data-tip data-for={'img-' + p.id} src={p.picture} alt="" height={25} />
                                        <ReactTooltip
                                            id={'img-' + p.id}
                                            effect="solid"
                                            border={true}
                                            borderColor="#ccc"
                                            backgroundColor="white"
                                            arrowColor="white"
                                        >
                                            <img src={p.picture} alt="" style={{ maxHeight: '200px' }} />
                                        </ReactTooltip>
                                    </td>

                                    <td>
                                        <Options
                                            id={p.id}
                                            deletable={p.deletable}
                                            editable={p.editable}
                                            transferable={p.transferable}
                                            canAcceptTransfer={p.can_accept_transfer}
                                            handleEdit={props.handleEdit}
                                            handleDelete={props.handleDelete}
                                            handleTransfer={props.handleTransfer}
                                            handleHistory={props.handleHistory}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </div>


            </>
    )
}

export default TableOfficeStock;