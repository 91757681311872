import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { trans } from '../../../../_providers/Translation'

function FilterTabs(props) {

    const [tabs, setTabs] = useState({
        ripple: true
    })

    const handleTabs = (e, newValue) => {
        props.handleTab(newValue);
    };

    return (
        <div className="small-tabs">
            <Tabs
                value={props.tab}
                onChange={handleTabs}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs"
            >
                <Tab value="team" label={trans('tabs.tofellow')} disableRipple={tabs.ripple} />
                <Tab value="advert" label={trans('tabs.advertising')} disableRipple={tabs.ripple} />
            </Tabs>
        </div>
    )
}

export default FilterTabs;