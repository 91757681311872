import React, { useEffect } from 'react';
import { trans } from '../../../_providers/Translation'

function Expense(props) {

    return (
        <table>
            <thead>
                <tr>
                    <th>{trans('tables.activity')}</th>
                    <th>{trans('tables.date')}</th>
                    <th>{trans('tables.employee')}</th>
                    <th>{trans('tables.expenseDate')}</th>
                    <th>{trans('tables.category')}</th>
                    <th>{trans('tables.ddsExpense')}</th>
                    <th>{trans('tables.netSum')}</th>
                    <th>{trans('tables.tax')}</th>
                    <th>{trans('tables.grossSum')}</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((h, i) => (
                    <tr key={i} className={[h.actual ? 'actual' : h.selected ? 'selected' : '']}>
                        <td>
                            {h.event}
                        </td>
                        <td>{h.date}</td>
                        <td>{h.causer && `${h.causer.name} ${h.causer.lastname}`}</td>
                        <td>{h.subject.date_dmy || '-'}</td>
                        <td>
                            {h.subject.category
                                ? h.subject.category.name
                                : '-'
                            }
                        </td>
                        <td>
                            {h.subject.vat_expense ? trans('labels.yes') : trans('labels.no')}
                        </td>
                        <td>
                            {h.subject.price_formatted} {h.subject.currency ? h.subject.currency.name : ''}
                        </td>
                        <td>
                            {h.subject.vat} ({h.subject.vat_price_formatted} {h.subject.currency ? h.subject.currency.name : ''})
                            </td>
                        <td>
                            {h.subject.gross_price_formatted} {h.subject.currency ? h.subject.currency.name : ''}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default Expense;