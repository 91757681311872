import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Chart from 'react-google-charts';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

function EmployeesReservationsChart() {

    const defHeight = 240;

    const [state, setState] = useState({
        loading: true,
        chart: null,
        height: defHeight,
        isVertical: true,
    });

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        Api.get('reservations/compared-data')
            .then(res => {

                let label = [trans('random.employee'), trans('random.finishedResrvations'), trans('random.currentReservations'), trans('random.total')];

                let chart = [
                    label
                ];

                res.data.employees.map(e => {
                    let arr = [
                        `${e.name} ${e.lastname}`,
                        res.data.results[e.id].completed,
                        res.data.results[e.id].not_completed,
                        res.data.results[e.id].total,
                    ];

                    chart.push(arr);
                });

                let employees = res.data.employees.length;

                let isVertical = true;
                let height = defHeight;

                if (employees > 6) {
                    isVertical = false;
                }

                if (!isVertical) {
                    height = employees * 80;
                }

                setState(prev => ({
                    ...prev,
                    chart: chart,
                    height: height,
                    // isVertical: isVertical,
                    isVertical: true
                }))
            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }))
            });

    }

    return (
        <div className="col current-data-chart">
            <h3>
                {trans('random.workSixMonths')}
            </h3>
            <div className="chart">
                {state.loading
                    ?
                    <Skeleton height={defHeight} count={1} />
                    :
                    <div style={{ height: state.height }}>
                        <Chart
                            width={'100%'}
                            height={'100%'}
                            chartType="Bar"
                            className="animated-chart-vertical animated-chart-start"
                            loader={'...'}
                            data={state.chart}
                            options={{
                                chartArea: {
                                    left: 20
                                },
                                hAxis: {
                                    title: trans('random.reservationsCount'),
                                    minValue: 0,
                                },
                                // Material design options
                                chart: {
                                    // title: 'Company Performance',
                                    // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                                },
                                legend: {
                                    position: 'none',
                                    // lines: 1
                                    // alignment: 'end'
                                },
                                colors: ['#67c9f0', '#c73c65', '#0ED8A6'],
                                bars: state.isVertical ? 'vertical' : 'horizontal',
                            }}
                            chartEvents={[
                                {
                                    eventName: "ready",
                                    callback: ({ chartWrapper, google }) => {
                                        const chartEl = chartWrapper.getChart().container;
                                        setTimeout(() => {
                                            chartEl.classList.remove('animated-chart-start')
                                        }, 100)
                                    },
                                }
                            ]}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default EmployeesReservationsChart;