import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import { useHistory } from 'react-router-dom';
import { trans } from '../../../../_providers/Translation';
import NoDataFound from '../../../partials/NoDataFound';

function History(props, ref) {

    const history = useHistory();

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        id: null,
        data: null,
        loading: true,
        update: false,
    });

    useImperativeHandle(ref, () => ({
        show: (id) => {
            setPopup(prev => ({
                ...prev,
                id: id,
                loading: true,
                update: Math.random().toString()
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // извлича текущите данни, за да се попълнят полетата
    useEffect(() => {

        if (popup.id) {
            Api.get(`storage/history?id=${popup.id}`)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data,
                        loading: false
                    }));
                });
        }

    }, [popup.id, popup.update]);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const showPartHistory = id => {
        setPopup(prev => ({
            ...prev,
            id: id,
            loading: true,
            update: Math.random().toString()
        }));
    }

    const showReservation = id => {
        history.push('/reservations/edit/' + id);
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <div className={`${popup.modal ? 'show' : ''} popup-primary`}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.partHistory')} (ID {popup.id})
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                {popup.loading
                    ?
                    <img src={loader} alt="loading" />
                    :
                    popup.data.history.length === 0
                        ?
                        <NoDataFound />
                        :
                        <div className="popup-container">

                            <div className="table-scroller">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{trans('tables.activity')}</th>
                                            <th>{trans('tables.date')}</th>
                                            <th>{trans('tables.employee')}</th>
                                            <th>{trans('tables.category')}</th>
                                            <th>{trans('tables.name')}</th>
                                            <th>{trans('tables.partNumber')}</th>
                                            <th className="center">{trans('tables.newQuantity')}</th>
                                            <th className="center">{trans('tables.image')}</th>
                                            <th>{trans('tables.location')}</th>
                                            <th>{trans('tables.storage')}</th>
                                            <th>{trans('tables.row')}</th>
                                            <th>{trans('tables.box')}</th>
                                            <th>{trans('tables.supplier')}</th>
                                            <th>{trans('tables.newPrice')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {popup.data.history.map((h, i) => (

                                            <tr key={'ph-' + i}>
                                                <td>
                                                    {h.action.translation.name}

                                                    {h.action.action === 'transfer'
                                                        ?
                                                        <>
                                                            <br />
                                                            {popup.data.parent_id
                                                                ?
                                                                <span className="transfer-label" onClick={() => showPartHistory(h.related_id)}>
                                                                    {h.related_id} {'>'} {h.storage_id}
                                                                </span>
                                                                :
                                                                <span className="transfer-label" onClick={() => showPartHistory(h.related_id)}>
                                                                    {h.storage_id} {'>'} {h.related_id}
                                                                </span>
                                                            }
                                                        </>
                                                        :
                                                        h.action.action === 'attach_to_reservation'
                                                            ?
                                                            <>
                                                                <br />
                                                                {h.log.reservation ?

                                                                    <span className="reservation-label" onClick={() => showReservation(h.log.reservation_id)}>
                                                                        {h.log.reservation.reference_code}
                                                                    </span>
                                                                    :
                                                                    ''}
                                                            </>
                                                            :
                                                            ''
                                                    }

                                                </td>
                                                <td>{h.date}</td>
                                                <td>{h.employee && `${h.employee.name} ${h.employee.lastname}`}</td>
                                                <td>{h.log.part.category.name}</td>
                                                <td>{h.log.part.name}</td>
                                                <td>{h.log.part.number}</td>
                                                <td className="center">{h.log.quantity}</td>
                                                <td className="center">
                                                    <img src={h.log.image ? h.log.picture : h.log.part.picture} alt="" height={20} />
                                                </td>
                                                <td>
                                                    {h.log.position.translation.name}
                                                    <br />
                                                    {h.log.van && h.log.van.reg_no}

                                                    {h.log.van && h.log.van.employee && `(${h.log.van.employee.name})`}
                                                </td>
                                                <td>
                                                    {h.log.storage?.name}
                                                </td>
                                                <td>
                                                    {h.log.shelve}
                                                </td>
                                                <td>
                                                    {h.log.box}
                                                </td>
                                                <td>{h.log.supplier ? h.log.supplier.name : ''}</td>
                                                <td>{h.log.gross_price_format} {h.log.currency.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>

                }


                <div className="footer align-center">
                    <button className="button" onClick={handleHide}>
                        {trans('buttons.close')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(History);