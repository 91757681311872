import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function FilterTabs(props) {

    const [tabs, setTabs] = useState({
        ripple: true
    })

    return (
        <div className={props.smallTabs ? 'small-tabs' : ''}>

            <Tabs
                value={props.tab}
                onChange={(e, id) => props.handleTab(id)}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs"
            >
                {props.tabs.map(t =>
                    <Tab key={t.id} value={t.id} label={<div>{t.name} {props.tab === t.id && t.help}</div>} disableRipple={tabs.ripple} />
                )}
            </Tabs>

        </div>
    )
}

export default FilterTabs;