import React, { useEffect, useRef, useState } from 'react';
import { useAuthDataContext } from '../../_providers/Auth';
import { useNotificationsContext } from '../../_providers/Notifications';
import { useVoiceCallContext } from '../../_providers/VoiceCall';
import notificationIcon from '../../assets/img/header/icons/notification.png';
import IncommingCall from '../modals/IncommingCall';
import closeIcon from '../../assets/img/icons/close.png';
import { trans } from '../../_providers/Translation'


function NotificationsContainer(props) {

    const auth = useAuthDataContext();
    const notifications = useNotificationsContext();
    const calls = useVoiceCallContext();

    const callModalRef = useRef(null);

    const position = auth.setting('notification_position_id');

    let className = '';
    switch (position) {
        case 'bottom_left':
            className = 'bottom-left';
            break;
        case 'bottom_right':
            className = 'bottom-right';
            break;
    }

    useEffect(() => {

        let wrapper = document.querySelector('.instant-notification-wrapper');
        let notification = notifications.latest;

        if (notification) {

            let el = document.createElement('li');

            el.setAttribute('class', 'notification');

            el.innerHTML = `
            <h3>
                <img src=${notificationIcon} alt="icon" />
                ${notification.text}
                <img class="close" src=${closeIcon} />
            </h3>`;

            el.querySelector('.close').addEventListener('click', () => {
                el.remove();
            });

            wrapper.prepend(el);

            // setTimeout(() => {
            //     el.remove();
            // }, 10000);

        }

    }, [notifications.latest]);

    const handleShowCaller = (callId, phone) => {
        let modal = callModalRef.current;

        modal.open(phone);

        calls.close(callId)
    }

    return (
        <>

            <IncommingCall
                ref={callModalRef}
            />

            <ul className={['instant-notification-wrapper', className].join(' ')}>
                {calls.list.map(c =>
                    <li key={'call-' + c.id} className={`notification pointer ${c.customer ? 'red' : ''}`} onClick={() => handleShowCaller(c.call_id, c.caller_id)}>
                        <h3>
                            <img src={notificationIcon} alt="icon" />
                            {c.caller_id} ({c.customer ? `${c.customer.name} ${c.customer.lastname}` : trans('random.newCustomer')}) {trans('random.incomingCall')}...
                        </h3>
                    </li>
                )}
            </ul>
        </>
    )

}

export default NotificationsContainer;