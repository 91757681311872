import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import moment from 'moment';
import { trans } from '../../../../_providers/Translation';
import Api from '../../../../_helpers/Api';
import Success from '../../../modals/messages/Success';

function PreviewWarranty(props, ref) {

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        data: null,
        onUpdate: null,
    });

    const successModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (data) => {
            handleShow(data);
        },
        hide: () => {
            handleHide();
        },
        onUpdate: fn => {
            setState(prev => ({
                ...prev,
                onUpdate: fn
            }));
        }
    }));

    const handleShow = (data = null) => {
        setState(prev => ({
            ...prev,
            data: data
        }));

        show();
    }

    const handleHide = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            data: null,
        }));

        hide().then(() => {
            if (typeof props.onClose === 'function') {
                props.onClose();
            }
        });

    }

    const show = async () => {
        return new Promise((resolve, reject) => {
            setState(prev => ({
                ...prev,
                overlay: true,
            }));

            setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    overlay: true,
                    modal: true
                }));

                return resolve();
            }, 50);
        });
    }

    const hide = async () => {
        return new Promise((resolve, reject) => {
            setState(prev => ({
                ...prev,
                modal: false,
                loading: false,
            }));

            setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    overlay: false,
                    modal: false,
                }));

                return resolve();
            }, 50);
        });
    }

    const loadData = () => {
        if (!state.data?.id) {
            return;
        }

        Api.get(`contractors/warranties/find?id=${state.data.id}`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                }));
            });
    }

    const handleActivateSubscription = () => {
        let id = state.data?.id;

        Api.post('contractors/warranties/activate', {
            id: id
        }).then(res => {
            loadData();

            let modal = successModalRef.current;

            hide().then(() => {
                modal.open();
            });

            modal.onClose(() => {
                show();
            });

            if (typeof state.onUpdate === 'function') {
                state.onUpdate();
            }
        });
    }

    const handleRenewSubscription = () => {
        let id = state.data?.id;

        Api.post('contractors/warranties/renew', {
            id: id
        }).then(res => {
            loadData();

            let modal = successModalRef.current;

            hide().then(() => {
                modal.open();
            });

            modal.onClose(() => {
                show();
            });

            if (typeof state.onUpdate === 'function') {
                state.onUpdate();
            }
        });
    }

    return (
        <>
            <Success
                ref={successModalRef}
                mainMessage={trans('messages.success')}
            />

            <div className={`${state.overlay ? 'visible' : ''} overlay`}>
                <div className={`${state.modal ? 'show' : ''} popup-primary small`}>

                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />

                        {trans('headings.warranty')} {state?.data?.ref}
                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('tables.client')}
                            </label>
                            <input
                                type="text"
                                value={state?.data?.contractor?.name || ''}
                                disabled
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('tables.appliance')}
                            </label>
                            <input
                                type="text"
                                value={state?.data?.appliancecategory?.name || ''}
                                disabled
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container x2">
                        <div className="col">
                            <label>
                                {trans('tables.activatedAt')}
                            </label>
                            <input
                                type="date"
                                value={state?.data?.activated_at ? moment(state?.data?.activated_at).format('YYYY-MM-DD') : ''}
                                disabled
                            />
                        </div>
                        <div className="col">
                            <label>
                                {trans('tables.expiresAt')}
                            </label>
                            <input
                                type="date"
                                value={state?.data?.expires_at ? moment(state?.data?.expires_at).format('YYYY-MM-DD') : ''}
                                disabled
                            />
                        </div>
                    </div>

                    <br />

                    {state.data?.id
                        ?
                        state.data?.is_activated
                            ?
                            ''
                            :
                            <button
                                className="button-primary button-small"
                                onClick={handleActivateSubscription}
                                style={{
                                    width: '100%'
                                }}
                            >
                                {trans('buttons.activate')}
                            </button>
                        :
                        ''
                    }

                    <div className="footer align-center">
                        {state.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="button" onClick={handleHide}>
                                    {trans('buttons.close')}
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )

}

export default forwardRef(PreviewWarranty);