import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams, Link, useLocation } from 'react-router-dom'
import Api from '../../../../_helpers/Api'
import { trans } from '../../../../_providers/Translation';
import * as Constants from '../../../../_config/app';

//images
import clientDataIcon from '../../../../assets/img/icons/client-data.png'
import forRepairIcon from '../../../../assets/img/icons/for-repair.png'
import visitationIcon from '../../../../assets/img/icons/visitation.png'
import infoIcon from '../../../../assets/img/icons/info-black.png'
import loader from '../../../../assets/img/loader.svg'
import carIcon from '../../../../assets/img/icons/car.png'

//modals
import Success from '../../../modals/messages/Success'
import Error from '../../../modals/messages/Error'
import Question from '../../../modals/messages/Question'
import Dropdown from '../../../partials/Dropdown'
import Skeleton from 'react-loading-skeleton'
import Employees from './modals/Employees'
import { useAuthDataContext } from '../../../../_providers/Auth'
import Address from './modals/Address'
import ReactTooltip from 'react-tooltip'

let timeout;

function TaskInfo(props) {

    const auth = useAuthDataContext();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();

    const editMode = params.id ? true : false;

    const setOriginal = props.setOriginal;
    const isAddress = props.isAddress;
    const isOnSiteRepair = props.isOnSiteRepair;
    const getResTypeId = props.getResTypeId;
    const getResType = props.getResType;

    const searchParams = new URLSearchParams(location.search)

    const redirect = searchParams.get('redirect') || false

    //refs
    const replicateModalRef = useRef(null)
    const continueModalRef = useRef(null)
    const reclamationModalRef = useRef(null)
    const successModalRef = useRef(null)
    const employeesModalRef = useRef(null)
    const inputModelsRef = useRef(null)
    const reportModalRef = useRef(null)
    const successMailModalRef = useRef(null)
    const errorModalRef = useRef(null)
    const addressModalRef = useRef(null);
    const applianceModalRef = useRef(null)
    const workDayModalRef = useRef(null)

    const initialValidations = {}

    let hasPerformer = Boolean(props.data.performer);
    let hasId = Boolean(props.data.id);

    const [task, setTask] = useState({
        validations: initialValidations,
        loading: false,
        dataLoaded: hasId ? false : true,
        contractors: {
            types: [],
            all: []
        },
        subscriptions: [],
        warranties: [],
        appliances: {
            categories: [],
            originalCategories: [],
            brands: [],
            models: [],
        },
        employees: {
            all: []
        },
        reservations: {
            statuses: [],
            visitperiods: [],
            types: []
        },
        data: {

        },
        canSendReport: false,
        tabsCount: {},
    });

    let contractorId = task.data.contractor ? task.data.contractor.id : '';
    let contractorTypeId = task.data.contractor ? task.data.contractor.type_id : '';

    const settings = props.settings;

    useEffect(() => {
        Api.get('partners/categories/all')
            .then(res => {
                setTask(task => ({
                    ...task,
                    appliances: {
                        ...task.appliances,
                        categories: res.data,
                        originalCategories: res.data,
                        brands: [],
                        models: []
                    }
                }))
            });

        Api.get('reservations/statuses')
            .then(res => {
                setTask(task => ({
                    ...task,
                    reservations: {
                        ...task.reservations,
                        statuses: res.data
                    }
                }))
            });

        Api.get('reservations/visitperiods')
            .then(res => {
                setTask(task => ({
                    ...task,
                    reservations: {
                        ...task.reservations,
                        visitperiods: res.data
                    }
                }))
            });

        Api.get('reservations/types')
            .then(res => {
                setTask(task => ({
                    ...task,
                    reservations: {
                        ...task.reservations,
                        types: res.data
                    }
                }))
            });

    }, [])

    useEffect(() => {

        if (typeof props.data === 'object') {
            setTask(task => ({
                ...task,
                data: props.data,
                loading: false,
                dataLoaded: true,
            }))
        }

    }, [props.data])

    useEffect(() => {
        if (task.data?.id) {
            setTask(task => ({
                ...task,
                dataLoaded: true,
            }))
        }
    }, [task.data?.id])

    useEffect(() => {
        setTask(task => ({
            ...task,
            tabsCount: props.tabsCount
        }))
    }, [props.tabsCount])

    useEffect(() => {
        let categoryId = task.data?.appliancecategory?.id;

        if (categoryId) {
            let items = task.appliances.categories;

            let exists = items.filter(c => {
                return Number(c.id) === Number(categoryId);
            }).length;

            if (!exists) {
                items.push(task.data.appliancecategory);

                setTask(prev => ({
                    ...prev,
                    appliances: {
                        ...prev.appliances,
                        categories: items
                    }
                }));
            }
        }
    }, [task.appliances.categories, task.data.appliancecategory])

    useEffect(() => {

        let catId = task.data?.appliancecategory?.id;

        if (!catId) {
            return;
        }

        Api.get(`partners/brands/all?category_id=${catId}`)
            .then(res => {
                setTask(task => ({
                    ...task,
                    appliances: {
                        ...task.appliances,
                        brands: res.data,
                    }
                }))
            })
    }, [task.data.appliancecategory])

    useEffect(() => {
        let brandId = task.data?.appliancebrand?.id;

        if (brandId) {
            let items = task.appliances.brands;

            let exists = items.filter(c => {
                return Number(c.id) === Number(brandId);
            }).length;

            if (!exists) {
                items.push(task.data.appliancebrand);

                setTask(prev => ({
                    ...prev,
                    appliances: {
                        ...prev.appliances,
                        brands: items
                    }
                }));
            }
        }
    }, [task.appliances.brands, task.data.appliancebrand])

    useEffect(() => {

        // let catId = task.data.appliancecategory.id;
        // let brandId = task.data.appliancebrand ? task.data.appliancebrand.id : null;

        // if (!catId || !brandId) {
        //     return;
        // }

        // Api.get(`appliances/models/all?category_id=${catId}&brand_id=${brandId}`)
        //     .then(res => {
        //         setTask(task => ({
        //             ...task,
        //             appliances: {
        //                 ...task.appliances,
        //                 models: res.data
        //             }
        //         }))
        //     })


        // ако са заредени категориите и марките да нулира полето за модел при промяна
        if (task.appliances.categories.length && task.appliances.brands.length) {
            if (inputModelsRef.current) {
                // inputModelsRef.current.reset();
            }
        }

    }, [task.data.appliancecategory, task.data.appliancebrand])

    useEffect(() => {
        let contractor = task.contractors.all.find(c => Number(c.id) === Number(task.data?.contractor?.id));

        let subscriptions = (function () {
            let subscriptions = contractor && Array.isArray(contractor.activesubscriptions) ? contractor.activesubscriptions : [];

            // console.log(subscriptions);

            let current = task.data.subscription;

            if (current) {
                let currentExists = subscriptions.find(s => s.id === current.id);

                if (!currentExists) {
                    subscriptions.push(current);
                }
            }

            return subscriptions;
        })();

        let warranties = (function () {
            let warranties = contractor && Array.isArray(contractor.activewarranties) ? contractor.activewarranties : [];

            // console.log(warranties);

            let current = task.data.warranty;

            if (current) {
                let currentExists = warranties.find(s => s.id === current.id);

                if (!currentExists) {
                    warranties.push(current);
                }
            }

            return warranties;
        })();

        // console.log(contractor);

        setTask(prev => ({
            ...prev,
            subscriptions: subscriptions,
            warranties: warranties,
        }));
    }, [task.data.contractor, task.contractors.all])

    useEffect(() => {
        Api.get('contractors/types')
            .then(res => {

                let items = res.data || [];

                if (props.data.contractor && props.data.contractor.type) {
                    let exists = items.filter(c => {
                        return Number(c.id) === Number(props.data.contractor.type_id);
                    }).length;

                    if (!exists) {
                        items.push(props.data.contractor.type);
                    }
                }

                setTask(task => ({
                    ...task,
                    contractors: {
                        ...task.contractors,
                        types: items
                    }
                }))
            });
    }, [props.data.contractor]);

    useEffect(() => {
        if (contractorTypeId) {

            let url = 'contractors/with-appliances?type_id=' + contractorTypeId;

            if (!hasId) {
                url += '&active=1';
            }

            Api.get(url)
                .then(res => {

                    let items = res.data.items || res.data;

                    if (contractorId) {
                        let exists = items.filter(c => {
                            return Number(c.id) === Number(contractorId);
                        }).length;

                        if (!exists) {
                            items.push(task.data.contractor);
                        }
                    }

                    setTask(task => ({
                        ...task,
                        contractors: {
                            ...task.contractors,
                            all: items
                        },
                    }))
                })
        }

    }, [contractorTypeId])

    useEffect(() => {
        if (task.reservations.visitperiods && task.reservations.visitperiods.length === 0) {
            return;
        }

        let visitperiod = task.reservations.visitperiods.filter(p => Number(p.id) === Number(task.data.visit_period_id))[0];

        setTask(prev => ({
            ...prev,
            data: {
                ...prev.data,
                visitperiod: visitperiod
            }
        }))

    }, [task.data.visit_period_id, task.reservations.visitperiods])

    // useEffect(() => {
    //     console.log(task.data?.performer)
    // }, [task.data?.performer]);

    // useEffect(() => {
    //     let region = task.data.region;
    //     let town = task.data.town;
    //     let postcode = task.data.postcode;
    //     let street = task.data.address;
    //     let no = task.data.address_no;

    //     if (!region || !town || !street) {
    //         return;
    //     }

    //     let full = [];

    //     if (no) {
    //         full.push(no);
    //     }

    //     full.push(street);

    //     clearTimeout(timeout);

    //     timeout = setTimeout(() => {
    //         Axios.get('https://nominatim.openstreetmap.org/search', {
    //             params: {
    //                 street: full.join(' '),
    //                 county: region,
    //                 city: town,
    //                 postalcode: postcode,
    //                 format: 'json'
    //             }
    //         }).then(res => {
    //             let lat = 0;
    //             let lon = 0;

    //             if (res.data.length) {
    //                 let result = res.data[0];

    //                 if (result.lat) {
    //                     lat = result.lat;
    //                     lon = result.lon;
    //                 }
    //             }

    //             setTask(prev => ({
    //                 ...prev,
    //                 data: {
    //                     ...prev.data,
    //                     lat: lat,
    //                     lon: lon
    //                 }
    //             }));
    //         });
    //     }, 300);
    // }, [task.data.region, task.data.town, task.data.postcode, task.data.address, task.data.address_no])

    useEffect(() => {
        if (props.addMode) {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                calculatePrice();
            }, 300);
        }
    }, [
        task.data.contractor,
        task.data.lat,
        task.data.lon,
        task.data.appliancecategory,
        task.data.appliancebrand,
        task.data.visit_date_ymd,
        task.data.performer_id,
        task.data.performer_type,
        task.data.initial_status_id,
        task.data.final_status_id,
        task.data.postcode,
        task.data.calculate_labour_price,
        task.data.customer_fault,
        task.data.is_warranty,
        task.data.is_subscription,
        task.data.work_hours,
    ]);


    useEffect(() => {
        if (!props.addMode) {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                calculatePrice();
            }, 300);
        }
    }, [
        task.data.contractor,
        task.data.appliancecategory,
        task.data.appliancebrand,
        task.data.postcode,
        task.data.initial_status_id,
        task.data.calculate_labour_price,
        task.data.customer_fault,
        task.data.is_warranty,
        task.data.is_subscription,
        task.data.work_hours,
    ]);

    const calculatePrice = () => {

        let url = 'reservations/calculate-price';

        let form = document.getElementById('reservation');

        if (!form) {
            return;
        }

        let data = new FormData(form);

        if (task.data.id) {
            data.append('id', task.data.id)
        }

        data.append('res_type_id', getResTypeId());

        Api.post(url, data)
            .then(res => {
                Object.entries(res.data).map(p => {
                    setTask(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            [p[0]]: p[1]
                        }
                    }));
                });
            });
    }

    const handleContractorsAll = e => {
        let id = e.target.value

        setTask(task => ({
            ...task,
            data: {
                ...task.data,
                contractor: {
                    // ...task.data.contractor,
                    type_id: id,
                    id: null
                }
            }
        }))
    }

    const handleSelectedContractor = e => {
        let val = e.target.value

        let selected = task.contractors.all.filter(each => {
            return Number(each.id) === Number(val);
        })[0];

        setTask(prev => ({
            ...prev,
            data: {
                ...prev.data,
                contractor: selected,
                contractor_id: selected?.id || null,
                subscription: null,
                is_subscription: false,
                email: selected.email,
                invoice_name: selected.charge ? selected.name : prev.invoice_name,
                invoice_eikegn: selected.charge ? selected.eik : prev.invoice_eikegn,
                invoice_vat_number: selected.charge ? selected.vat_number : prev.invoice_vat_number,
                invoice_mol: selected.charge ? selected.mol : prev.invoice_vat_number,
                invoice_address: selected.charge ? selected.address : prev.invoice_address,
            },
        }));
    }

    const handleSelectSubscription = e => {
        let id = e.target.value;

        let contractor = task?.data?.contractor;
        // let subscriptions = contractor?.activesubscriptions || [];
        let subscriptions = task.subscriptions;

        let selected = subscriptions.find(s => Number(s.id) === Number(id));

        setTask(task => ({
            ...task,
            data: {
                ...task.data,
                subscription: selected || null,
                subscription_id: selected?.id || null,
                is_subscription: contractor?.charge ? true : false,
                appliancecategory: selected?.appliancecategory || {},
                appliancebrand: selected?.appliancebrand || {},
                appliancemodel: selected?.appliancemodel || {},
                serial_no: selected?.serial_no || null,
                product_code: selected?.product_code || null,
            },
        }));
    }

    const handleSelectWarranty = e => {
        let id = e.target.value;

        let contractor = task?.data?.contractor;
        // let warranties = contractor?.activewarranties || [];
        let warranties = task.warranties;

        let selected = warranties.find(s => Number(s.id) === Number(id));

        setTask(task => ({
            ...task,
            data: {
                ...task.data,
                warranty: selected || null,
                warranty_id: selected?.id || null,
                is_warranty: true,
                appliancecategory: selected?.appliancecategory || {},
                appliancebrand: selected?.appliancebrand || {},
                appliancemodel: selected?.appliancemodel || {},
                serial_no: selected?.serial_no || null,
                product_code: selected?.product_code || null,
            },
        }));
    }

    const handleSelectDevice = e => {
        let id = e.target.value

        let selected = task.appliances.categories.find(c => Number(c.id) === Number(id));

        setTask(task => ({
            ...task,
            data: {
                ...task.data,
                appliancecategory: {
                    ...task.data.appliancecategory,
                    ...selected,
                    id: id,
                },
                appliancebrand: {},
                appliancemodel: {}
            },
            appliances: {
                ...task.appliances,
                brands: [],
                models: []
            }
        }));

        if (inputModelsRef.current) {
            inputModelsRef.current.reset();
        }

    }

    const handleSelectBrand = e => {
        let id = e.target.value

        setTask(task => ({
            ...task,
            data: {
                ...task.data,
                appliancebrand: {
                    ...task.data.appliancebrand,
                    id: id,
                },
                appliancemodel: {}
            },
            appliances: {
                ...task.appliances,
                models: []
            }
        }));

        if (inputModelsRef.current) {
            inputModelsRef.current.reset();
        }

    }

    const handleSelectModel = e => {
        let id = e.target.value

        setTask(task => ({
            ...task,
            data: {
                ...task.data,
                appliancemodel: {
                    ...task.data.appliancemodel,
                    id: id,
                }
            }
        }))
    }

    const handleSubmit = e => {
        e.preventDefault();
    }

    const handleSave = (e, redirect = false) => {
        e.preventDefault();

        // дали техника може да ремонтира избрания уред
        // и дали е в работното му време
        if (task.data?.performer?.type === 'App\\Employee') {
            if (typeof task.data.performer.can_repair_appliance !== 'undefined' && !task.data.performer.can_repair_appliance) {
                let modal = applianceModalRef.current;

                modal.open();

                return;
            }

            // else if (typeof task.data.performer.in_work_day !== 'undefined' && !task.data.performer.in_work_day) {
            //     let modal = workDayModalRef.current;

            //     modal.open();

            //     return;
            // }
        }

        setTask(task => ({
            ...task,
            validations: initialValidations,
            loading: true,
        }))

        let url = editMode ? 'reservations/edit' : 'reservations/add'

        let form = document.getElementById('reservation');

        let data = new FormData(form)

        if (task.data.id) {
            data.append('id', task.data.id)
        }

        data.append('res_type_id', getResTypeId());

        Api.post(url, data)
            .then(res => {

                let modal = successModalRef.current;

                modal.open();
                modal.onClose(() => {

                    if (task.canSendReport) {
                        let reportModal = reportModalRef.current;

                        reportModal.open();

                        reportModal.onSuccess(() => {
                            sendReport();
                        });

                        reportModal.onClose(() => {
                            finishSaving(res.data, redirect);
                        })
                    } else {
                        finishSaving(res.data, redirect);
                    }

                });

            })
            .catch(error => {
                let _err = error.response

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data)) {
                        setTask(task => ({
                            ...task,
                            validations: {
                                ...task.validations,
                                [input]: true
                            },
                        }))
                    }
                } else if (_err && _err.status && _err.status == 423) {
                    errorModalRef.current.open(_err.data.error);
                } else {
                    errorModalRef.current.open();
                }
            })
            .finally(() => {
                setTask(task => ({
                    ...task,
                    loading: false,
                }))
            });
    }

    const handleSaveExit = e => {
        e.preventDefault();

        let url = redirect;

        if (!url) {
            url = 'reservations';

            if (params.id) {
                url += '?id=' + params.id;
            }
        }

        handleSave(e, url);
    }

    const finishSaving = (data, redirect = false) => {
        if (redirect) {
            return history.push('/' + redirect);
        }

        if (!hasId) {
            return history.push('/reservations/edit/' + data.id);
        }

        setTask(task => ({
            ...task,
            loading: false,
            data: data
        }));

        setOriginal(data);

    }

    const sendReport = () => {
        Api.post('reservations/send-report', {
            id: task.data.id
        }).then(res => {
            if (res.data.success) {
                setTask(prev => ({
                    ...prev,
                    canSendReport: false
                }));
            }
        });
    }

    const handleChangePerformer = e => {
        let id = e ? e.id : '';

        setTask(prev => ({
            ...prev,
            data: {
                ...prev.data,
                performer_id: id,
                performer: e
            }
        }));

    }

    const handleChangeAddress = (name, lat, lon) => {
        setTask(prev => ({
            ...prev,
            data: {
                ...prev.data,
                address: name,
                lat: lat ? Number(lat) : null,
                lon: lon ? Number(lon) : null
            }
        }));
    }

    const handleChangeInitialStatus = e => {
        let id = Number(e.target.value)

        setTask(task => ({
            ...task,
            data: {
                ...task.data,
                initial_status_id: id
            }
        }));

    }

    const handleChangeFinalStatus = e => {
        let id = Number(e.target.value)

        setTask(task => ({
            ...task,
            data: {
                ...task.data,
                final_status_id: id
            },
            canSendReport: true
        }));
    }

    const handleChangePeriod = e => {
        let id = Number(e.target.value)

        setTask(task => ({
            ...task,
            data: {
                ...task.data,
                visit_period_id: id
            }
        }))
    }

    const handleChangeDate = e => {
        let val = e.target.value;

        setTask(task => ({
            ...task,
            data: {
                ...task.data,
                visit_date_ymd: val
            }
        }))
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setTask(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }

        }))
    }

    const handleTypeChange = data => {

        let inSeconds = 0;
        let inMins = 0;

        if (data.id) {
            inSeconds = data.time;
            inMins = data.minutes;
        } else {
            let parse = data.name.match(/\d+/);

            if (parse) {
                inSeconds = parse[0] * 60;
                inMins = data.name;
            }
        }

        setTask(prev => ({
            ...prev,
            data: {
                ...prev.data,
                type_id: data.id || '',
                exec_time: inSeconds, // в секунди
                exec_time_in_minutes: inMins
            }
        }));
    }

    const showEmployeesSelect = () => {
        employeesModalRef.current.open();
    }

    const showAddressMap = () => {
        addressModalRef.current.open();
    }

    const handleCheckboxChange = e => {
        let name = e.target.name;

        setTask(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: !prev.data[name]
            }
        }))
    }

    const handleCustomerInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setTask(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customer: {
                    [name]: val
                }
            }
        }));
    }

    const handleCustomerChange = data => {
        setTask(prev => ({
            ...prev,
            data: {
                ...prev.data,
                customer: {
                    name: data.name,
                }
            }
        }));

        if (data.id) {
            setTask(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    customer: {
                        lastname: data.lastname,
                    },
                    phone: !prev.data.phone ? data.phone : prev.data.phone,
                    email: !prev.data.email ? data.email : prev.data.email,
                    // region: !prev.data.region ? data.region : prev.data.region,
                    // town: !prev.data.town ? data.town : prev.data.town,
                    postcode: !prev.data.postcode ? data.postcode : prev.data.postcode,
                    address: !prev.data.address ? data.address : prev.data.address,
                    // address_no: !prev.data.address_no ? data.address_no : prev.data.address_no,
                    lat: !prev.data.lat ? data.lat : prev.data.lat,
                    lon: !prev.data.lon ? data.lon : prev.data.lon,
                    invoice_name: !prev.data.invoice_name ? `${data.name} ${data.lastname}` : prev.data.invoice_name,
                    invoice_address: !prev.data.invoice_address ? data.address : prev.data.invoice_address
                }
            }));
        }
    }

    const handleCreate = () => {
        window.location = '/reservations/' + getResType() + '/add';
    }

    const handleContinue = () => {
        Api.post('reservations/continue', {
            id: task.data.id
        }).then(res => {
            if (res.data.id) {
                history.push('/reservations/edit/' + res.data.id);

                continueModalRef.current.open();
            }
        }).catch(err => {
            errorModalRef.current.open();
        });
    }

    const handleReplicate = () => {
        Api.post('reservations/replicate', {
            id: task.data.id
        }).then(res => {
            if (res.data.id) {
                history.push('/reservations/edit/' + res.data.id);

                replicateModalRef.current.open();
            }
        }).catch(err => {
            errorModalRef.current.open();
        });
    }

    const handleReclamation = () => {
        Api.post('reservations/reclamation', {
            id: task.data.id
        }).then(res => {
            if (res.data.id) {
                history.push('/reservations/edit/' + res.data.id);

                reclamationModalRef.current.open();
            }
        }).catch(err => {
            errorModalRef.current.open();
        });
    }

    const handleSendReport = () => {
        Api.post('reservations/send-report', {
            id: task.data.id
        }).then(res => {
            if (res.data.success) {
                successMailModalRef.current.open();
            } else {
                errorModalRef.current.open();
            }
        }).catch(err => {
            errorModalRef.current.open();
        });
    }

    const handleSendConfirmation = () => {
        Api.post('reservations/send-confirmation', {
            id: task.data.id
        }).then(res => {
            if (res.data.success) {
                successMailModalRef.current.open();
            } else {
                errorModalRef.current.open();
            }
        }).catch(err => {
            errorModalRef.current.open();
        });
    }

    const handleSendCompletion = () => {
        Api.post('reservations/send-completion-details', {
            id: task.data.id
        }).then(res => {
            if (res.data.success) {
                successMailModalRef.current.open();
            } else {
                errorModalRef.current.open();
            }
        }).catch(err => {
            errorModalRef.current.open();
        });
    }

    const handleSendSms = () => {
        Api.post('reservations/send-sms-details', {
            id: task.data.id
        }).then(res => {
            if (res.data.success) {
                successMailModalRef.current.open();
            } else {
                errorModalRef.current.open();
            }
        }).catch(err => {
            errorModalRef.current.open();
        });
    }

    const handleShowPdfOrder = () => {
        window.open(Constants.App.api + 'reservations/pdf-order?id=' + task.data.id + '&token=' + auth.token(), '_blank');
    }

    const allowSubscription = () => {
        let contractor = task?.data?.contractor;

        if (contractor) {
            if (contractor?.type?.is_subscription) {
                return true;
            }
        }

        return false;
    }

    const allowWarranty = () => {
        return task.warranties.length > 0;
    }

    const showLabourPriceType = () => {
        switch (task.data?.labour_price_type) {
            case 'hourly':
                return trans('taskInfo.pricing.labour.hourly');
            case 'fixed':
                return trans('taskInfo.pricing.labour.fixed');
            case 'diagnostic_and_labour':
                return trans('taskInfo.pricing.labour.diagnostic_and_labour');
        }
    }

    return (
        <>

            <Success
                mainMessage={trans('headings.success5')}
                secondaryMessage={trans('subheadings.success5')}
                ref={successModalRef}
            />

            <Success
                mainMessage={trans('headings.success6')}
                ref={successMailModalRef}
            />

            {/* Reclamation,continue,replicate modals */}
            <Success
                mainMessage={trans('headings.reclamation_success')}
                ref={reclamationModalRef}
            />

            <Success
                mainMessage={trans('headings.replicate_success')}
                ref={replicateModalRef}
            />

            <Success
                mainMessage={trans('headings.continue_success')}
                ref={continueModalRef}
            />
            {/* END */}

            <Error
                mainMessage={trans('headings.error3')}
                ref={errorModalRef}
            />

            <Error
                ref={applianceModalRef}
                mainMessage={trans('headings.success10')}
                secondaryMessage={trans('subheadings.chooseAnother')}
            />

            <Error
                ref={workDayModalRef}
                mainMessage={trans('messages.selectedEmployeeOnADayOff')}
                secondaryMessage={trans('subheadings.chooseAnother')}
            />

            <Question
                ref={reportModalRef}
                mainMessage={trans('headings.question5')}
            />

            <Employees
                ref={employeesModalRef}
                selected={task.data.performer ? task.data.performer.id : ''}
                type={task.data.performer ? task.data.performer.type : ''}
                hasPerformer={hasPerformer}
                canReassign={task.data.can_reassign}
                params={{
                    id: task.data.id,
                    appliance_category_id: task.data.appliancecategory ? task.data.appliancecategory.id : null,
                    lat: task.data.lat,
                    lon: task.data.lon,
                    visit_date: task.data.visit_date_ymd,
                    visit_period_from: task.data.visitperiod ? task.data.visitperiod.from : '',
                    visit_period_to: task.data.visitperiod ? task.data.visitperiod.to : '',
                    exec_time: task.data.exec_time
                }}
                onSave={handleChangePerformer}
            />

            <Address
                ref={addressModalRef}
                selected={task.data.address}
                params={{
                    lat: task.data.lat,
                    lon: task.data.lon,
                }}
                onSave={handleChangeAddress}
            />

            {props.loading
                ?
                <div className="panel-wrapper row">
                    <div className="panel">
                        <Skeleton height={35} className="row" count={8} />
                    </div>
                    <div className="panel">
                        <Skeleton height={35} className="row" count={8} />
                    </div>
                    <div className="panel">
                        <Skeleton height={35} className="row" count={8} />
                    </div>
                </div>
                :

                <div>
                    {hasId
                        ?
                        <div className="row" style={{ marginBottom: '10px' }}>
                            <div className="filter-standard" style={{ width: '100%', marginLeft: 'auto', padding: '0', overflow: 'auto' }}>
                                <div className="col auto">
                                    <div className="row" style={{ marginBottom: '0', flexWrap: 'nowrap' }}>
                                        <button className="button-small" onClick={handleCreate} style={{ minWidth: 'fit-content' }}>
                                            {trans('buttons.new')}
                                        </button>
                                        {task.data.can_make_reclamation &&
                                            <button className="button-small" onClick={handleReclamation} style={{ minWidth: 'fit-content' }}>
                                                {trans('buttons.reclamation')}
                                            </button>
                                        }
                                        {task.data.can_make_continuation &&
                                            <button className="button-small" onClick={handleContinue} style={{ minWidth: 'fit-content' }}>
                                                {trans('buttons.continuation')}
                                            </button>
                                        }
                                        <button className="button-small" onClick={handleReplicate} style={{ minWidth: 'fit-content' }}>
                                            {trans('buttons.dublicate')}
                                        </button>
                                    </div>
                                </div>
                                <div className="col auto right">
                                    <div className="row" style={{ flexWrap: 'nowrap' }}>
                                        <button className="button-small" onClick={handleSendSms} style={{ minWidth: 'fit-content' }}>
                                            {trans('buttons.sendMessage')}
                                        </button>
                                        {task.data.can_send_confirmation &&
                                            <button className="button-small" onClick={handleSendConfirmation} style={{ minWidth: 'fit-content' }}>
                                                {trans('buttons.sendNotification')}
                                            </button>
                                        }
                                        {task.data.can_send_completion_message &&
                                            <button className="button-small" onClick={handleSendCompletion} style={{ minWidth: 'fit-content' }}>
                                                {trans('buttons.sendNotification2')}
                                            </button>
                                        }
                                        {task.data.can_send_report &&
                                            <button className="button-small" onClick={handleSendReport} style={{ minWidth: 'fit-content' }}>
                                                {trans('buttons.sentReport')}
                                            </button>
                                        }
                                        {task.data.can_make_pdf_order &&
                                            <button className="button-small" onClick={handleShowPdfOrder} style={{ minWidth: 'fit-content' }}>
                                                {trans('buttons.viewInPdf')}
                                            </button>
                                        }
                                        {/* <button className="button-small" onClick={handleSave}>
                                    Приложи промените
                                </button>
                                <button className="button-small" onClick={handleSaveExit}>
                                    Запазвне &amp; Изход
                                </button> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                        :
                        ''
                    }

                    {task.data.is_reassigned && auth.partnerId() != task.data.partner_id ?
                        <div className="alert warning">
                            {trans('messages.reservationPriority')} <b>{task.data.partner.name}</b>
                        </div>
                        :
                        ''
                    }

                    {(task?.data?.appliancecategory?.partner_id && task?.data?.appliancecategory?.partner_id != auth.partnerId() && task?.data?.partner_id != auth.partnerId()) &&
                        <div className="alert danger">
                            {trans('random.changeCategory')}
                        </div>
                    }

                    {task.data.is_child ?
                        <div className="alert info">
                            <Link to={`/reservations/edit/${task.data.parent_id}`}>
                                {String(task.data.reclamation) === '1' ? trans('buttons.reclamation') : trans('buttons.continuation')} {trans('random.reservationWithRefNumber')} {task.data.parent ? task.data.parent.reference_code : ''}
                            </Link>
                        </div>
                        :
                        task.data.children && task.data.children.length
                            ?
                            <div className="alert info">
                                {trans('random.reservationIncludes')} {task.data.children.length} {task.data.children.length == 1 ? trans('random.continuation') : trans('random.continuation2')}:
                                <ul>
                                    {task.data.children.map(c =>
                                        <li>
                                            <Link to={`/reservations/edit/${c.id}`}>
                                                <b>{c.initialstatus && c.initialstatus.translation ? c.initialstatus.translation.name : ''} {trans('random.refNo')} {c.reference_code}</b>
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            :
                            ''
                    }

                    {/* {task.data.is_subscription && task.data.subscription ?
                        <div className="alert info">
                            Резервация към абонамент с реф. №: <b>{task?.data?.subscription?.ref || 'n/a'}</b>
                        </div>
                        :
                        ''
                    } */}

                    <form id="reservation" autoComplete="off" onSubmit={handleSubmit}>

                        <div className="panel-wrapper row">

                            <div className="panel">
                                <div className="head">
                                    <img src={clientDataIcon} alt="client" />
                                    {trans('random.clientData')}
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="name">
                                            {trans('labels.name')}*
                                        </label>

                                        <Dropdown
                                            inputId="name"
                                            inputName="name"
                                            inputValue={task.data && task.data.customer ? task.data.customer.name : ''}
                                            inputIdName="customer_id"
                                            inputIdValue={task.data && task.data.customer_id ? task.data.customer_id : ''}
                                            inputPlaceholder={trans('random.searchByNamePhone')}
                                            inputClassName={task.validations.name ? 'validate' : ''}
                                            inputDisabled={task.data.is_child || !task.data.can_modify}
                                            url="customers/search"
                                            renderInputText={data => {
                                                return data.name
                                            }}
                                            renderText={data => {
                                                return (
                                                    <div style={{ fontSize: '12px' }}>
                                                        {data.name} {data.lastname}
                                                        <br />
                                                        <span>
                                                            {trans('random.phone')} {data.phone_formatted || trans('messages.notSelected')}
                                                        </span>
                                                    </div>
                                                )
                                            }}
                                            onChange={handleCustomerChange}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="lastname">
                                            {trans('labels.lastname')}*
                                        </label>
                                        <input
                                            type="text"
                                            className={task.validations.lastname ? 'validate' : ''}
                                            name="lastname"
                                            id="lastname"
                                            value={task.data && task.data.customer ? task.data.customer.lastname : ''}
                                            disabled={task.data.is_child || !task.data.can_modify}
                                            onChange={handleCustomerInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.typeClient')}
                                        </label>
                                        <select
                                            value={task.data && task.data.contractor ? task.data.contractor.type_id : ''}
                                            name="type"
                                            onChange={handleContractorsAll}
                                            disabled={task.data.is_child || !task.data.can_modify}
                                        >
                                            <option value="" selected>
                                                {trans('random.person')}
                                            </option>
                                            {task.contractors.types.map(type =>
                                                <option key={'ct-' + type.id} value={type.id}>
                                                    {type.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    {task.data && task.data.contractor && task.data.contractor.type_id
                                        ?
                                        <div className="col">
                                            <label htmlFor="contractor_id">
                                                {trans('labels.contractor')}
                                            </label>
                                            <select
                                                value={task.data && task.data.contractor && task.data.contractor.id ? task.data.contractor.id : ''}
                                                className={task.validations.contractor_id ? 'validate' : ''}
                                                name="contractor_id"
                                                id="contractor_id"
                                                onChange={handleSelectedContractor}
                                                disabled={task.data.is_child || !task.data.can_modify}
                                            >
                                                <option value="" disabled>

                                                </option>
                                                {task.contractors.all && task.contractors.all.map(each =>
                                                    <option key={'contractor-' + each.id} value={each.id}>
                                                        {each.name}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                        :
                                        ''
                                    }
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.clientNo')}
                                        </label>
                                        <input
                                            type="text"
                                            name="purchase_order"
                                            value={task.data && task.data.purchase_order ? task.data.purchase_order : ''}
                                            disabled={task.data.is_child || !task.data.can_modify}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col">
                                        <label htmlFor="region">
                                            Област*
                                        </label>
                                        <input
                                            value={task.data && task.data.region ? task.data.region : ''}
                                            type="text"
                                            className={task.validations.region ? 'validate' : ''}
                                            name="region"
                                            id="region"
                                            onChange={handleInputChange}
                                            disabled={task.data.is_child || !task.data.can_modify}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="town">
                                            Населено място*
                                    </label>
                                        <input
                                            value={task.data && task.data.town ? task.data.town : ''}
                                            type="text"
                                            className={task.validations.town ? 'validate' : ''}
                                            name="town"
                                            id="town"
                                            onChange={handleInputChange}
                                            disabled={task.data.is_child || !task.data.can_modify}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="postcode">
                                            Пощенски код*
                                    </label>
                                        <input
                                            value={task.data && task.data.postcode ? task.data.postcode : ''}
                                            type="text"
                                            className={task.validations.postcode ? 'validate' : ''}
                                            name="postcode"
                                            id="postcode"
                                            onChange={handleInputChange}
                                            disabled={task.data.is_child || !task.data.can_modify}
                                        />
                                    </div>

                                    <div className="col">
                                        <div className="row">

                                            <div className="col" style={{ marginRight: '5px' }}>
                                                <label htmlFor="address">
                                                    Улица*
                                            </label>
                                                <input
                                                    value={task.data && task.data.address ? task.data.address : ''}
                                                    type="text"
                                                    className={task.validations.address ? 'validate' : ''}
                                                    name="address"
                                                    id="address"
                                                    onChange={handleInputChange}
                                                    disabled={task.data.is_child || !task.data.can_modify}
                                                />
                                            </div>


                                            <div className="col" style={{ width: '80px' }}>
                                                <label htmlFor="address_no">
                                                    №
                                            </label>
                                                <input
                                                    value={task.data && task.data.address_no ? task.data.address_no : ''}
                                                    type="text"
                                                    className={task.validations.address_no ? 'validate' : ''}
                                                    name="address_no"
                                                    id="address_no"
                                                    onChange={handleInputChange}
                                                    disabled={task.data.is_child || !task.data.can_modify}

                                                />
                                            </div>

                                        </div>
                                    </div> 
                                </div>*/}

                                {isAddress() &&
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="address">
                                                {trans('labels.address')}*
                                            </label>

                                            <input
                                                type="text"
                                                name="address"
                                                className={task.validations.address || task.validations.lat || task.validations.lon ? 'validate' : ''}
                                                value={task.data.address ? task.data.address : ''}
                                                onClick={showAddressMap}
                                                disabled={task.data.is_child || !task.data.can_modify}
                                                readOnly
                                            />
                                        </div>

                                        <div className="col">
                                            <label htmlFor="postcode">
                                                {trans('labels.postCode')}
                                            </label>
                                            <div style={{ display: 'flex' }}>
                                                <input
                                                    value={task.data && task.data.postcode ? task.data.postcode : ''}
                                                    type="text"
                                                    className={task.validations.postcode ? 'validate' : ''}
                                                    name="postcode"
                                                    id="postcode"
                                                    onChange={handleInputChange}
                                                    disabled={task.data.is_child || !task.data.can_modify}
                                                />
                                                {task.data.congestion_price > 0
                                                    ?
                                                    <div style={{ background: '#f0ad4e', width: '40px', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderTopRightRadius: '3px', borderBottomRightRadius: '3px' }}>
                                                        <img
                                                            src={carIcon}
                                                            height={18}
                                                            data-tip
                                                            data-for="congestion_tooltip"
                                                        />
                                                        <ReactTooltip
                                                            effect="solid"
                                                            id="congestion_tooltip"
                                                        >
                                                            {trans('messages.fallsInCongestionCharge')}
                                                        </ReactTooltip>
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>
                                        </div>

                                    </div>
                                }


                                {/* <div className="row">

                                    <div className="col">
                                        <label htmlFor="address">
                                            Адрес*
                                        </label>

                                        

                                        <input
                                            value={task.data && task.data.address ? task.data.address : ''}
                                            type="text"
                                            className={task.validations.address ? 'validate' : ''}
                                            name="address"
                                            id="address"
                                            onChange={handleInputChange}
                                            disabled={task.data.is_child || !task.data.can_modify}
                                        />
                                    </div>
                                </div> */}


                                {isAddress() &&
                                    <>
                                        <input type="hidden" name="lat" value={task.data?.lat || ''} />
                                        <input type="hidden" name="lon" value={task.data?.lon || ''} />
                                    </>
                                }

                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="phone">
                                            {trans('labels.phone')}*
                                        </label>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ background: '#f2f2f2', height: '100%', display: 'flex', alignItems: 'center', fontSize: '12px', padding: '0 5px', borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px' }}>
                                                {auth.phoneCode()}
                                            </div>
                                            <input
                                                value={task.data && task.data.phone ? task.data.phone : ''}
                                                type="text"
                                                className={task.validations.phone ? 'validate' : ''}
                                                name="phone"
                                                id="phone"
                                                disabled={task.data.is_child || !task.data.can_modify}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="email">
                                            {trans('labels.email')}
                                        </label>
                                        <input
                                            value={task.data && task.data.email ? task.data.email : ''}
                                            type="text"
                                            className={task.validations.email ? 'validate' : ''}
                                            name="email"
                                            id="email"
                                            disabled={task.data.is_child || !task.data.can_modify}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <hr />
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="invoice_name">
                                            {trans('labels.nameForInvoice')}
                                        </label>
                                        <input
                                            value={task.data && task.data.invoice_name ? task.data.invoice_name : ''}
                                            type="text"
                                            name="invoice_name"
                                            id="invoice_name"
                                            disabled={task.data.is_child || !task.data.can_modify}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="invoice_eikegn">
                                            {trans('labels.eikEgn')}
                                        </label>
                                        <input
                                            value={task.data && task.data.invoice_eikegn ? task.data.invoice_eikegn : ''}
                                            type="text"
                                            name="invoice_eikegn"
                                            id="invoice_eikegn"
                                            disabled={task.data.is_child || !task.data.can_modify}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="invoice_vat_number">
                                            {trans('labels.vatNo')}
                                        </label>
                                        <input
                                            value={task.data && task.data.invoice_vat_number ? task.data.invoice_vat_number : ''}
                                            type="text"
                                            name="invoice_vat_number"
                                            id="invoice_vat_number"
                                            disabled={task.data.is_child || !task.data.can_modify}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="invoice_mom">
                                            {trans('labels.mol')}
                                        </label>
                                        <input
                                            value={task.data && task.data.invoice_mol ? task.data.invoice_mol : ''}
                                            type="text"
                                            name="invoice_mol"
                                            id="invoice_mol"
                                            disabled={task.data.is_child || !task.data.can_modify}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="invoice_address">
                                            {trans('labels.address')}
                                        </label>
                                        <input
                                            value={task.data && task.data.invoice_address ? task.data.invoice_address : ''}
                                            type="text"
                                            name="invoice_address"
                                            id="invoice_address"
                                            disabled={task.data.is_child || !task.data.can_modify}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="panel">
                                <div className="head">
                                    <img src={forRepairIcon} alt="repair" />
                                    {trans('random.itemToFix')}
                                </div>
                                {allowSubscription()
                                    ?
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="subscription_id">
                                                {trans('labels.subscription')}
                                            </label>
                                            <select
                                                value={task.data && task.data.subscription && task.data.subscription.id ? task.data.subscription.id : ''}
                                                className={task.validations.subscription_id ? 'validate' : ''}
                                                name="subscription_id"
                                                id="subscription_id"
                                                onChange={handleSelectSubscription}
                                                disabled={task.data.is_child || !task.data.can_modify || !task.data.can_modify_appliance}
                                            >
                                                <option value="">
                                                    {trans('random.select')}
                                                </option>
                                                {task.subscriptions.map(subscription =>
                                                    <option key={'s-' + subscription.id} value={subscription.id}>
                                                        {subscription.ref} ({[subscription?.appliancecategory?.name, subscription?.appliancebrand?.name, subscription?.appliancemodel?.name].filter(e => e).join(', ')})
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }
                                {allowWarranty()
                                    ?
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="warranty_id">
                                                {trans('labels.warranty')}
                                            </label>
                                            <select
                                                value={task.data && task.data.warranty && task.data.warranty.id ? task.data.warranty.id : ''}
                                                className={task.validations.warranty_id ? 'validate' : ''}
                                                name="warranty_id"
                                                id="warranty_id"
                                                onChange={handleSelectWarranty}
                                                disabled={task.data.is_child || !task.data.can_modify || !task.data.can_modify_appliance}
                                            >
                                                <option value="">
                                                    {trans('random.select')}
                                                </option>
                                                {task.warranties.map(warranty =>
                                                    <option key={'s-' + warranty.id} value={warranty.id}>
                                                        {warranty.ref} ({[warranty?.appliancecategory?.name, warranty?.appliancebrand?.name, warranty?.appliancemodel?.name].filter(e => e).join(', ')})
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }
                                <div className="row">
                                    <div className="col">
                                        <div className="custom-input">
                                            <label htmlFor="appliance_category_id">
                                                {trans('labels.category')}*
                                            </label>
                                            <select
                                                value={task.data && task.data.appliancecategory && task.data.appliancecategory.id ? task.data.appliancecategory.id : ''}
                                                className={task.validations.appliance_category_id ? 'validate' : ''}
                                                name="appliance_category_id"
                                                id="appliance_category_id"
                                                onChange={handleSelectDevice}
                                                disabled={task.data.is_child || !task.data.can_modify || !task.data.can_modify_appliance}
                                            >
                                                <option value="">
                                                    {trans('random.select')}
                                                </option>
                                                {task.appliances.categories.map(category =>
                                                    <option key={'c-' + category.id} value={category.id}>
                                                        {category.name}
                                                    </option>
                                                )}
                                            </select>
                                            {/* {(task?.data?.appliancecategory?.partner_id && task?.data?.appliancecategory?.partner_id != auth.partnerId() && task?.data?.partner_id != auth.partnerId()) &&
                                                <div className="helper-text">
                                                    {trans('random.changeCategory')}
                                                </div>
                                            } */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="appliance_brand_id">
                                            {trans('labels.make')}
                                        </label>
                                        <select
                                            value={task.data.appliancebrand && task.data.appliancebrand.id ? task.data.appliancebrand.id : ''}
                                            className={task.validations.appliance_brand_id ? 'validate' : ''}
                                            name="appliance_brand_id"
                                            id="appliance_brand_id"
                                            onChange={handleSelectBrand}
                                            disabled={task.data.is_child || !task.data.can_modify || !task.data.can_modify_appliance}
                                        >
                                            <option value="">
                                                {!task.data?.appliancecategory?.id ? trans('random.typeSelect') : trans('random.select')}
                                            </option>
                                            {task.appliances.brands.map(brand =>
                                                <option key={'b-' + brand.id} value={brand.id}>
                                                    {brand.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="appliance_model_id">
                                            {trans('labels.model')}
                                        </label>

                                        <Dropdown
                                            ref={inputModelsRef}
                                            inputName="appliance_model"
                                            inputIdName="appliance_model_id"
                                            inputValue={task.data.appliancemodel && task.data.appliancemodel.name ? task.data.appliancemodel.name : ''}
                                            inputIdValue={task.data.appliancemodel && task.data.appliancemodel.id ? Number(task.data.appliancemodel.id) : ''}
                                            inputClassName={task.validations.appliance_model_id ? 'validate' : ''}
                                            inputDisabled={task.data.is_child || !task.data.can_modify || !task.data.can_modify_appliance}
                                            url="appliances/models/all"
                                            params={{
                                                category_id: task.data.appliancecategory && task.data.appliancecategory.id ? Number(task.data.appliancecategory.id) : null,
                                                brand_id: task.data.appliancebrand && task.data.appliancebrand.id ? Number(task.data.appliancebrand.id) : null,
                                            }}
                                            requiredParams={['category_id', 'brand_id']}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="serial_no">
                                            {trans('labels.serialNo')}
                                        </label>
                                        <input
                                            value={task.data && task.data.serial_no ? task.data.serial_no : ''}
                                            type="text"
                                            name="serial_no"
                                            id="serial_no"
                                            disabled={task.data.is_child || !task.data.can_modify}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="product_code">
                                            {trans('labels.productCode')}
                                        </label>
                                        <input
                                            type="text"
                                            name="product_code"
                                            id="product_code"
                                            value={task.data && task.data.product_code ? task.data.product_code : ''}
                                            disabled={task.data.is_child || !task.data.can_modify}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="description">
                                            {trans('labels.problemDescription')}
                                        </label>
                                        <textarea
                                            value={task.data && task.data.description ? task.data.description : ''}
                                            name="description"
                                            id="description"
                                            disabled={task.data.is_child || !task.data.can_modify}
                                            onChange={handleInputChange}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="report">
                                            {trans('labels.employeeReport')}
                                        </label>
                                        <textarea
                                            value={task.data && task.data.report ? task.data.report : ''}
                                            name="report"
                                            id="report"
                                            disabled={!task.data.can_modify}
                                            onChange={handleInputChange}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="head">
                                    <img src={visitationIcon} alt="visitation" />
                                    {trans('random.visitAndPrice')}
                                </div>

                                <div className="row">
                                    {/* Само при редакция */}
                                    <div className="col">
                                        <label htmlFor="reference_code">
                                            {trans('labels.refNo')}
                                        </label>
                                        <input
                                            type="text"
                                            name="reference_code"
                                            disabled
                                            value={task.data.reference_code}
                                            id="reference_code"
                                        />
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="visit_date">
                                            {trans('labels.visitDate')}*
                                        </label>
                                        <input
                                            type="date"
                                            value={task.data ? task.data.visit_date_ymd : ''}
                                            name="visit_date"
                                            id="visit_date"
                                            className={task.validations.visit_date ? 'validate' : ''}
                                            onChange={handleChangeDate}
                                            disabled={!task.data.can_modify}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="visit_period_id">
                                            {trans('labels.visitHour')}*
                                        </label>
                                        <select
                                            value={task.data && task.data.visit_period_id ? task.data.visit_period_id : ''}
                                            name="visit_period_id"
                                            id="visit_period_id"
                                            className={task.validations.visit_period_id ? 'validate' : ''}
                                            onChange={handleChangePeriod}
                                            disabled={!task.data.can_modify}
                                        >
                                            <option value="" selected>
                                                {trans('random.select')}
                                            </option>
                                            {task.reservations.visitperiods.map(visitperiod =>
                                                <option value={visitperiod.id} key={'vp-' + visitperiod.id}>
                                                    {visitperiod.period}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.typeAndTime')}
                                        </label>
                                        <Dropdown
                                            inputValue={task.data.exec_time_in_minutes}
                                            inputIdValue={task.data.type_id}
                                            inputIdName="type_id"
                                            inputDisabled={!task.data.can_modify}
                                            filter={true}
                                            data={task.reservations.types}
                                            // renderInputText={data => data.minutes}
                                            renderText={data => (
                                                `${data.name} ${data.minutes} мин.`
                                            )}
                                            onChange={handleTypeChange}
                                        />

                                        <input type="hidden" name="exec_time" value={task.data.exec_time} readOnly />
                                    </div>
                                    {(task.data.labour_price_type === 'hourly' || task.data.labour_price_type === 'category_hourly') &&
                                        <div className="col">
                                            <label>
                                                {trans('labels.workHours')}
                                            </label>
                                            <input
                                                value={task.data?.work_hours || ''}
                                                type="number"
                                                step="1"
                                                name="work_hours"
                                                // disabled={task.data.is_child || !task.data.can_modify}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    }
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="performer">
                                            {trans('labels.setTo')}*
                                        </label>

                                        <input
                                            id="performer"
                                            type="text"
                                            value={task.data && task.data.performer
                                                ?
                                                task.data.performer.type === 'App\\Employee'
                                                    ?
                                                    task.data.performer.name && `${task.data.performer.name} ${task.data.performer.lastname}`
                                                    :
                                                    `${task.data.performer.name}`
                                                : ''}
                                            onClick={showEmployeesSelect}
                                            readOnly
                                            disabled={!task.data.can_modify}
                                            className={task.validations.performer_id ? 'validate' : ''}
                                        />

                                        <input
                                            type="hidden"
                                            name="performer_id"
                                            value={task.data && task.data.performer ? task.data.performer.id : ''}
                                        />

                                        <input
                                            type="hidden"
                                            name="performer_type"
                                            value={task.data && task.data.performer ? task.data.performer.type : ''}
                                        />

                                    </div>
                                    <div className="col">
                                        <label htmlFor="created_dmy">
                                            {trans('labels.creationDate')}
                                        </label>
                                        <input type="text" value={task.data.created_dmy} name="created_dmy" disabled id="created_dmy" />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="initial_status_id">
                                            {trans('labels.initialStatus')}*
                                        </label>
                                        <select
                                            value={task.data && task.data.initial_status_id ? task.data.initial_status_id : ''}
                                            name="initial_status_id"
                                            id="initial_status_id"
                                            className={task.validations.initial_status_id ? 'validate' : ''}
                                            onChange={handleChangeInitialStatus}
                                            disabled={!task.data.can_modify || !task.data.can_modify_initial_status}
                                        >
                                            <option disabled>
                                                {trans('random.select')}
                                            </option>
                                            {task.reservations.statuses[1] && task.reservations.statuses[1].map(status =>
                                                <option
                                                    key={'s-' + status.id}
                                                    value={status.id}
                                                    disabled={!status.selectable}
                                                >
                                                    {status?.translation?.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    {/* Само при редакция */}
                                    <div className="col">
                                        <label htmlFor="final_status_id">
                                            {trans('labels.finalStatus')}
                                        </label>
                                        <select
                                            value={task.data && task.data.final_status_id ? task.data.final_status_id : ''}
                                            name="final_status_id"
                                            id="final_status_id"
                                            className={task.validations.final_status_id ? 'validate' : ''}
                                            onChange={handleChangeFinalStatus}
                                            disabled={!hasId || !task.data.can_modify || !task.data.initial_status_id}
                                        >
                                            <option value="" disabled>
                                                {trans('random.select')}
                                            </option>
                                            {task.reservations.statuses[2] && task.reservations.statuses[2].map(status =>
                                                <option value={status.id} key={'s-' + status.id}>
                                                    {status.translation?.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <hr className="total-hr" />

                                <div className="total-price">
                                    <div className="row">
                                        <div className="col">
                                            <ul>
                                                <li>
                                                    {trans('random.paidGross')}
                                                    <span>
                                                        {task.data.paid_gross_price_formatted || '0.00'} {task.data.currency ? task.data.currency.name : ''}
                                                    </span>
                                                </li>
                                                <li>
                                                    {trans('random.leftToPayGross')}
                                                    <span>
                                                        {task.data.unpaid_gross_price_formatted || '0.00'} {task.data.currency ? task.data.currency.name : ''}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row space-between col">
                                        <div className={['left', (Number(task.data.transport_price) > 0 && Number(task.data.diagnostic_price) > 0) || (Number(task.data.transport_price) === 0 && Number(task.data.diagnostic_price) === 0) ? 'x2' : ''].join(' ')}>

                                            {/* цена за диагностика */}
                                            {task.data.diagnostic_price > 0 &&
                                                <div className="row col">
                                                    <div className="col">
                                                        <label className="flex">
                                                            {trans('labels.diagnosticPrice')}
                                                        </label>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            name="diagnostic_price_formatted"
                                                            value={task.data.diagnostic_price_formatted || '0.00'}
                                                            onChange={handleInputChange}
                                                            disabled={!task.data.can_modify}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <h4 className="flex space-between">
                                                            <span>{trans('random.dds')}</span> <span>{task.data.diagnostic_vat_price_formatted || '0.00'}</span>
                                                        </h4>
                                                        <h4 className="flex space-between">
                                                            <span>{trans('random.net')}</span> <span>{task.data.diagnostic_net_price_formatted || '0.00'}</span>
                                                        </h4>
                                                        {task.data.diagnostic_payment
                                                            ?
                                                            <>
                                                                <div className="payment-label" data-tip data-for="diagnostic-payment">
                                                                    {task.data.diagnostic_payment.paymentmethod && task.data.diagnostic_payment.paymentmethod.translation && task.data.diagnostic_payment.paymentmethod.translation.name}
                                                                </div>
                                                                <ReactTooltip
                                                                    id="diagnostic-payment"
                                                                    effect="solid"
                                                                >
                                                                    {trans('random.marketFrom')} {task.data.diagnostic_payment.employee ? `${task.data.diagnostic_payment.employee.name} ${task.data.diagnostic_payment.employee.lastname}` : trans('random.theEmployee')} {trans('random.on')} {task.data.diagnostic_payment.created_at_dmy}
                                                                </ReactTooltip>
                                                            </>
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                </div>
                                            }

                                            {/* цена за труд */}
                                            <div className="row col">
                                                <div className="col">
                                                    <label className="flex">
                                                        <input
                                                            type="checkbox"
                                                            name="calculate_labour_price"
                                                            value="1"
                                                            checked={task.data.calculate_labour_price ? true : false}
                                                            style={{ width: 'auto', margin: 0, marginRight: '5px' }}
                                                            disabled={!task.data.can_modify}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        {trans('labels.labourPrice')} <img data-tip data-for="labour-charge" className="info-icon" src={infoIcon} />
                                                    </label>
                                                    <ReactTooltip
                                                        id="labour-charge"
                                                        effect="solid"
                                                    >
                                                        {trans('labels.labourPrice')}: {task.data.labour_clean_price_formatted || '0.00'} {task.data.currency && task.data.currency.name}
                                                        <br />
                                                        {trans('labels.congestionPrice')}: {task.data.congestion_price_formatted || '0.00'} {task.data.currency && task.data.currency.name}
                                                    </ReactTooltip>

                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        name="labour_price_formatted"
                                                        value={task.data.labour_price_formatted || '0.00'}
                                                        onChange={handleInputChange}
                                                        disabled={!task.data.can_modify}
                                                    />
                                                    {/* <label className="flex" style={{ marginTop: '5px' }}>
                                                        <input
                                                            type="checkbox"
                                                            name="calculate_labour_price"
                                                            value="1"
                                                            checked={task.data.calculate_labour_price ? true : false}
                                                            style={{ width: 'auto', margin: 0 }}
                                                            disabled={!task.data.can_modify}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <span>{trans('labels.calculatePrice')}</span>
                                                    </label> */}
                                                </div>
                                                <div className="col">
                                                    <h4 className="flex space-between">
                                                        <span>{trans('random.dds')}</span> <span>{task.data.labour_vat_price_formatted || '0.00'}</span>
                                                    </h4>
                                                    <h4 className="flex space-between">
                                                        <span>{trans('random.net')}</span> <span>{task.data.labour_net_price_formatted || '0.00'}</span>
                                                    </h4>
                                                    {task.data.labour_payment
                                                        ?
                                                        <>
                                                            <div className="payment-label" data-tip data-for="labour-payment">
                                                                {task.data.labour_payment.paymentmethod && task.data.labour_payment.paymentmethod.translation && task.data.labour_payment.paymentmethod.translation.name}
                                                            </div>
                                                            <ReactTooltip
                                                                id="labour-payment"
                                                                effect="solid"
                                                            >
                                                                {trans('random.marketFrom')} {task.data.labour_payment.employee ? `${task.data.labour_payment.employee.name} ${task.data.labour_payment.employee.lastname}` : trans('random.theEmployee')} {trans('random.on')} {task.data.labour_payment.created_at_dmy}
                                                            </ReactTooltip>
                                                        </>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            </div>

                                            {/* транспортни разходи */}
                                            {task.data.transport_price > 0 &&
                                                <div className="row col">
                                                    <div className="col">
                                                        <label>
                                                            {trans('labels.transportPrice')}
                                                        </label>

                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            name="transport_price_formatted"
                                                            value={task.data.transport_price_formatted || '0.00'}
                                                            onChange={handleInputChange}
                                                            disabled={!task.data.can_modify}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <h4 className="flex space-between">
                                                            <span>{trans('random.dds')}</span> <span>{task.data.transport_vat_price_formatted || '0.00'}</span>
                                                        </h4>
                                                        <h4 className="flex space-between">
                                                            <span>{trans('random.net')}</span> <span>{task.data.transport_net_price_formatted || '0.00'}</span>
                                                        </h4>
                                                        {task.data.transport_payment
                                                            ?
                                                            <>
                                                                <div className="payment-label" data-tip data-for="transport-payment">
                                                                    {task.data.transport_payment.paymentmethod && task.data.transport_payment.paymentmethod.translation && task.data.transport_payment.paymentmethod.translation.name}
                                                                </div>
                                                                <ReactTooltip
                                                                    id="transport-payment"
                                                                    effect="solid"
                                                                >
                                                                    {trans('random.marketFrom')} {task.data.transport_payment.employee ? `${task.data.transport_payment.employee.name} ${task.data.transport_payment.employee.lastname}` : trans('random.theEmployee')} {trans('random.on')} {task.data.transport_payment.created_at_dmy}
                                                                </ReactTooltip>
                                                            </>
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                </div>
                                            }

                                            {/* цена за части */}
                                            <div className="row col">
                                                <div className="col">
                                                    <label className="flex">
                                                        {trans('labels.partsPrice')} <img data-tip data-for="parts-charge" className="info-icon" src={infoIcon} />
                                                    </label>
                                                    <ReactTooltip
                                                        id="parts-charge"
                                                        effect="solid"
                                                    >
                                                        {task.data.parts_price > 0
                                                            ?
                                                            trans('messages.includesPartsAndStock')
                                                            :
                                                            trans('messages.includesStockParts')
                                                        }
                                                    </ReactTooltip>
                                                </div>
                                                <div className="col">
                                                    <input type="text" disabled value={task.data.parts_price_formatted || '0.00'} />
                                                </div>
                                                <div className="col">
                                                    <h4 className="flex space-between">
                                                        <span>{trans('random.dds')}</span> <span>{task.data.parts_vat_price_formatted || '0.00'}</span>
                                                    </h4>
                                                    <h4 className="flex space-between">
                                                        <span>{trans('random.net')}</span> <span>{task.data.parts_net_price_formatted || '0.00'}</span>
                                                    </h4>
                                                    {task.data.parts_payment
                                                        ?
                                                        <>
                                                            <div className="payment-label" data-tip data-for="parts-payment">
                                                                {task.data.parts_payment.paymentmethod && task.data.parts_payment.paymentmethod.translation && task.data.parts_payment.paymentmethod.translation.name}
                                                            </div>
                                                            <ReactTooltip
                                                                id="parts-payment"
                                                                effect="solid"
                                                            >
                                                                {trans('random.marketFrom')} {task.data.parts_payment.employee ? `${task.data.parts_payment.employee.name} ${task.data.parts_payment.employee.lastname}` : trans('random.theEmployee')} {trans('random.on')} {task.data.parts_payment.created_at_dmy}
                                                            </ReactTooltip>
                                                        </>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            </div>

                                            <div className="row" style={{ margin: 0 }}>
                                                {String(task.data.reclamation) === '1'
                                                    ?
                                                    <div className="row" style={{ marginRight: '5px' }}>
                                                        <label htmlFor="customer_fault">
                                                            {trans('labels.byVin')}
                                                        </label>
                                                        <input
                                                            type="checkbox"
                                                            id="customer_fault"
                                                            name="customer_fault"
                                                            value="1"
                                                            checked={task.data.customer_fault ? true : false}
                                                            onChange={handleCheckboxChange}
                                                            disabled={!task.data.can_modify}
                                                        />

                                                    </div>
                                                    :
                                                    ''
                                                }

                                                {task?.data?.warranty
                                                    ?
                                                    <input type="hidden" name="is_warranty" value={task.data.is_warranty ? 1 : 0} />
                                                    :
                                                    ''
                                                }

                                                {task?.data?.subscription
                                                    ?
                                                    <div className="row" style={{ margin: 0 }}>
                                                        <label htmlFor="is_subscription" tyle={{ margin: 0 }}>
                                                            {trans('labels.repairSubscription')}
                                                        </label>
                                                        <input
                                                            type="checkbox"
                                                            id="is_subscription"
                                                            name="is_subscription"
                                                            value="1"
                                                            checked={task.data.is_subscription ? true : false}
                                                            onChange={handleCheckboxChange}
                                                            disabled={!task.data.can_modify}
                                                        />

                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>

                                        </div>

                                        {/* обща цена */}
                                        <div className="right" style={{ position: 'relative' }}>
                                            <p>
                                                {trans('random.total')}
                                            </p>
                                            <h3>
                                                {task.data.total_price_formatted || '0.00'} {task.data.currency ? task.data.currency.name : ''}
                                            </h3>
                                            <div>
                                                <h4>
                                                    {trans('random.dds')} {task.data.total_vat_price_formatted || '0.00'}
                                                </h4>
                                                <h4>
                                                    {trans('random.net')} {task.data.total_net_price_formatted || '0.00'}
                                                </h4>

                                            </div>

                                            {task.data.total_payment
                                                ?
                                                <>
                                                    <div>
                                                        <div className="payment-label" data-tip data-for="total-payment" style={{ position: 'relative' }}>
                                                            {task.data.total_payment.paymentmethod && task.data.total_payment.paymentmethod.translation && task.data.total_payment.paymentmethod.translation.name}
                                                        </div>
                                                        <ReactTooltip
                                                            id="total-payment"
                                                            effect="solid"
                                                        >
                                                            {trans('random.marketFrom')} {task.data.total_payment.employee ? `${task.data.total_payment.employee.name} ${task.data.total_payment.employee.lastname}` : trans('random.theEmployee')} {trans('random.on')} {task.data.total_payment.created_at_dmy}
                                                        </ReactTooltip>
                                                    </div>
                                                </>
                                                :
                                                ''
                                            }

                                        </div>
                                    </div>

                                    {showLabourPriceType() &&
                                        <p style={{ fontSize: '12px' }}>
                                            {showLabourPriceType()}
                                        </p>
                                    }
                                </div>

                            </div>

                        </div>

                        <div className="row buttons-holder">
                            {task.loading ?
                                <img src={loader} alt="loading" />
                                :
                                <>
                                    <button className="button button-default" onClick={() => history.go(-1)}>
                                        {trans('buttons.cancel')}
                                    </button>
                                    <button className="button button-primary" onClick={handleSave}>
                                        {trans('buttons.save')}
                                    </button>
                                    <button className="button button-primary" onClick={handleSaveExit}>
                                        {trans('buttons.save')} &amp; {trans('buttons.exit')}
                                    </button>
                                </>
                            }
                        </div>
                    </form>
                </div>
            }
        </>
    )
}

export default React.memo(TaskInfo, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});