import React from 'react';
import { useMessagesContext } from '../../_providers/Messages';
import MessagePanel from './MessagePanel';

function MessagesContainer(props) {

    const messages = useMessagesContext();

    return (
        messages.openChats.map((user, i) =>
            <MessagePanel
                key={user.chatId}
                number={i}
                user={user}
            />
        )
    )

}

export default MessagesContainer;