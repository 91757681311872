import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd'
import Options from './Options';
import { Link } from 'react-router-dom';
import checkIcon from '../../../../assets/img/icons/check.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import { trans } from '../../../../_providers/Translation';

function TableRow(props) {
    let index = props.index;
    let data = props.data;
    let id = props.id;
    let state = props.state;

    let style = {
        padding: '0.5rem 1rem',
        marginBottom: '.5rem',
        backgroundColor: 'white',
        cursor: 'grab',
    };

    const handleChange = () => {
        let ids = [];

        state.map(c => {
            ids.push(c.related_partner_id)
        });

        props.changePriority(ids);
    }

    const ref = useRef(null)

    const [, drop] = useDrop({
        accept: 'card',
        drop(item) {
            handleChange()
        },
        hover(item, monitor) {

            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            props.moveCard(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        item: { type: 'card', id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1

    if (props.tab === 'sent') {
        drag(drop(ref));
    }

    return (
        <tr ref={ref} key={'partner-' + data.id} style={{ ...style, opacity }}>
            <td style={{ width: '50px' }}>{data.id}</td>
            <td>
                {props.tab === 'sent'
                    ?
                    <Link to={'/relationships/' + data.id}>
                        {data.name}
                    </Link>
                    :
                    data.name
                }
            </td>
            {props.tab === 'sent' &&
                <td>
                    {data.position}
                </td>
            }
            <td>{data.email}</td>
            <td>{data.phone}</td>
            <td>{data.address}</td>
            <td>
                {data.status === 1
                    ? trans('tables.awaitingConfirmation')
                    : data.status === 2
                        ? trans('tables.awaitConfirmation')
                        : data.status === 3
                            ? trans('tables.confirmed')
                            : trans('tables.canceled')
                }
            </td>
            <td>{data.max_reservations || trans('tables.unlimited')}</td>
            <td className="center">
                <img src={data.visible ? checkIcon : closeIcon} height="12" />
            </td>
            <td>
                <Options
                    id={data.id}
                    data={data}
                    handleEdit={props.handleEdit}
                    handleAccept={props.handleAccept}
                    handleDecline={props.handleDecline}
                    handleDelete={props.handleDelete}
                    handleShowSettings={props.handleShowSettings}
                />
            </td>
        </tr>
    )
}

export default TableRow;