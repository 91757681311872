import React, { useEffect, useState, useRef } from 'react'
import Table from './partials/Table'
import Api from '../../../_helpers/Api'
//modals
import AddOrEdit from './partials/AddOrEdit'
import Question from '../../modals/messages/Question'

import loader from '../../../assets/img/loader.svg'
import { useLocation, useHistory } from 'react-router-dom'
import { trans } from '../../../_providers/Translation';
import Pagination from '../../Pagination'

function Admins() {
    const location = useLocation()
    const history = useHistory()

    const searchParams = new URLSearchParams(location.search)

    const modalAddOrEditRef = useRef(null)
    const modalQuestionRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: searchParams.get('page') || 1,
        update: false
    })

    useEffect(() => {
        loadData()
    }, [state.update])

    //read
    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }))

        let url = 'admins/all'

        url += '?page=' + state.page

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data,
                    // total: res.data.total,
                    // pages: res.data.pages
                }))
            })
            .catch(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }))
            })
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add()
    }

    const handleCreate = user => {

        // let data = state.data.concat(user)

        // setState(prev => ({
        //     ...prev,
        //     data: data
        // }))

        setState(prev => ({
            ...prev,
            update: Math.random().toString()
        }))

    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id)
    }

    const handleUpdate = user => {

        let data = state.data.map(i => {
            if (i.id == user.id) {
                i = user
            }
            return i
        })

        setState(prev => ({
            ...prev,
            data: data,
        }))
    }

    // delete
    const handleDelete = id => {
        let modal = modalQuestionRef.current;

        modal.open(id);

        modal.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleDestroy = id => {
        Api.post('appliances/state/delete', {
            id: id
        })
            .then(res => {
                if (res.data.success) {
                    let data = state.data.filter(i => {
                        return i.id !== id
                    })
                    setState(prev => ({
                        ...prev,
                        data: data
                    }))
                    modalQuestionRef.current.close()
                }
            })
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1 }))
    }

    return (
        <>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.users')}
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.users')}
                        </p>
                    </div>
                    <div className="right">
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.addUsers')}
                        </button>
                    </div>
                </div>
                {state.loading
                    ?
                    <img src={loader} alt="loading" />
                    :
                    <>
                        <Table
                            data={state.data}
                            loading={state.loading}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                        />
                        <Pagination
                            pages={state.pages}
                            page={state.page}
                            handlePage={handlePage}
                        />
                    </>
                }
            </div>
        </>
    )
}

export default Admins