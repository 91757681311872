import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import { useAuthDataContext } from '../../../../_providers/Auth';

import ImageModal from './ImageModal';
import Api from '../../../../_helpers/Api';
import { validate, trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';


function EditModal(props, ref) {
    const imageRef = useRef(null)

    const auth = useAuthDataContext();
    const partner = auth.data.user.partner_id;

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        loading: false,
        onSave: null,
        data: [],
        image: [],
        logo: [],
        edit: false,
        editId: null,
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({

        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
            }));

            handleShow();
        },
        edit: (data) => {

            setPopup(prev => ({
                ...prev,
                loading: false,
                edit: Math.random().toString(),
                editId: data.id,
                data: data,
                logo: [data],
                image: [data]
            }));

            handleShow();
        },

        hide: () => {
            handleHide();
        },

        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();
        setPopup(prev => ({
            ...prev,
            data: [],
            image: [],
            logo: [],
            modal: false,
            loading: false,
        }));

        document.getElementById('logo').value = '';
        document.getElementById('image').value = '';

        setValidations(null);

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = (e, id) => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = "website/products/update";
        } else {
            url = 'website/products/create';
        }

        let data = new FormData(e.target);

        if (popup.edit) {
            data.append('id', popup.editId);
            data.append('partner_id', partner);
        } else {
            data.append('partner_id', partner);
        }

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave();
                }

                setValidations(null);

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: err }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }
    // #Image
    const handleAttachImage = (e) => {
        let attachments = Array.from(e.target.files)
        attachments.map((t, i) => {
            let tmppath = URL.createObjectURL(e.target.files[i]);
            attachments[i] = { t, tmppath }
        })
        setPopup(prev => ({
            ...prev,
            image: attachments
        }))
    }

    //Logo
    const handleAttachLogo = (e) => {
        let attachments = Array.from(e.target.files)
        attachments.map((t, i) => {
            let tmppath = URL.createObjectURL(e.target.files[i]);
            attachments[i] = { t, tmppath }
        })
        setPopup(prev => ({
            ...prev,
            logo: attachments
        }))
    }

    const FileSize = size => {
        return size / 1000
    }

    const handleOpenLogo = (e) => {
        e.preventDefault();
        let input = document.getElementById(`logo`);
        input.click();
    }

    const handleOpenImage = (e) => {
        e.preventDefault();
        let input = document.getElementById(`image`);
        input.click();
    }

    const openModal = image => {
        console.log(image)
        let modal = imageRef.current;

        modal.show(image);
    }


    return (

        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary large`} onSubmit={handleSave}>

                {popup.edit ?
                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />
                        {trans('headings.editAppliance')}
                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>
                    :
                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />
                        {trans('headings.addAppliance')}
                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>
                }
                {validations && validations.logo || validations && validations.image ?
                    <div className="alert danger">
                        {Object.entries(validations).map(v =>
                            v[1].map(r =>
                                v[0] === 'image' || v[0] === 'logo' ? <p>{validate(v[0], r)}</p> : ''
                            )
                        )}
                    </div> : ''
                }

                <br />

                <ImageModal
                    ref={imageRef}
                />

                <div className="row">
                    <div className="col">
                        <label htmlFor="name">
                            {trans('labels.name')}
                        </label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            value={popup.data && popup.data.name ?
                                popup.data.name
                                :
                                ''
                            }
                            className={validations && validations.name ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="text1">
                            {trans('labels.text1')}
                        </label>
                        <input
                            id="text1"
                            type="text"
                            name="text1"
                            value={popup.data && popup.data.text1 ?
                                popup.data.text1
                                :
                                ''
                            }
                            onChange={handleInputChange}
                        />
                    </div>

                </div>

                <div className="row">

                    <div className="col">
                        <label htmlFor="text2">
                            {trans('labels.text2')}
                        </label>
                        <input
                            id="text2"
                            type="text"
                            name="text2"
                            value={popup.data && popup.data.text2 ?
                                popup.data.text2
                                :
                                ''
                            }
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="text3">
                            {trans('labels.text3')}
                        </label>
                        <input
                            id="text3"
                            type="text"
                            name="text3"
                            value={popup.data && popup.data.text3 ?
                                popup.data.text3
                                :
                                ''
                            }
                            onChange={handleInputChange}
                        />
                    </div>

                </div>

                <br />
                {/* SNIMKI */}
                <div className='popup-container x2'>
                    {/* #1 */}
                    <div className='col'>
                        <label>
                            {trans('labels.logo')}
                        </label>
                        <div>
                            <input
                                id='logo'
                                type="file"
                                className='webImage'
                                onChange={e => handleAttachLogo(e)}
                                accept="image/*"
                                name='logo'
                                hidden
                            />
                            <button className='button button-add' onClick={e => handleOpenLogo(e)}>
                                {trans('buttons.add')}
                            </button>
                        </div>

                        <div style={{ overflow: 'auto', fontSize: '13px', maxHeight: '160px', marginTop: '5px' }}>
                            {popup.logo.map((a, i) =>
                                <div key={'logo-' + i}>
                                    <div className='row' style={{ alignItems: 'center' }}>
                                        {a.logo_url && <img src={a.logo_url} onClick={() => openModal(a.logo_url)} className='imageWeb' />}
                                        {a.tmppath && a.tmppath && <img src={a.tmppath} onClick={() => openModal(a.tmppath)} className='imageWeb' />}
                                        <div style={{ paddingRight: '10px', marginLeft: 'auto', textAlign: 'right' }}>
                                            {a.t && a.t.size ? Math.round(FileSize(a.t.size)) + ' KB' : ''}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* #2 */}
                    <div className='col'>
                        <label> {trans('labels.image')}</label>
                        <div>
                            <input
                                id='image'
                                type="file"
                                className='webImage'
                                onChange={e => handleAttachImage(e)}
                                accept="image/*"
                                name='image'
                                hidden
                            />
                            <button className='button button-add' onClick={e => handleOpenImage(e)}>
                                {trans('buttons.add')}
                            </button>
                        </div>

                        <div style={{ overflow: 'auto', fontSize: '13px', maxHeight: '160px', marginTop: '5px' }}>
                            {popup.image.map((a, i) =>
                                <div key={'image-' + i}>
                                    <div className='row' style={{ alignItems: 'center' }}>
                                        {a.image_url && <img src={a.image_url} onClick={() => openModal(a.image_url)} className='imageWeb' />}
                                        {a.tmppath && a.tmppath && <img src={a.tmppath} onClick={() => openModal(a.tmppath)} className='imageWeb' />}
                                        <div style={{ paddingRight: '10px', marginLeft: 'auto', textAlign: 'right' }}>
                                            {a.t && a.t.size ? Math.round(FileSize(a.t.size)) + ' KB' : ''}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label htmlFor="description">
                            {trans('labels.description')}
                        </label>
                        <textarea
                            id="description"
                            type="text"
                            name="description"
                            value={popup.data && popup.data.description ?
                                popup.data.description
                                :
                                ''
                            }
                            className={validations && validations.description ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>


                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>

                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(EditModal);