import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useAuthDataContext } from './Auth'
import { useSocketContext } from './Socket'

export const VoiceCallContext = createContext({})

const VoiceCallProvider = props => {

    const auth = useAuthDataContext();
    const listener = useSocketContext();
    const pid = auth.partnerId();

    const [state, setState] = useState({
        list: [
            // {
            //     id: 1,
            //     call_id: 1,
            //     caller_id: '+35988888888',
            // }
        ]
    });

    const stateRef = useRef(state.list);

    const setStateRef = data => {
        stateRef.current = data;

        setState({
            list: data
        });
    }

    const close = id => {
        let data = stateRef.current;

        let filtered = data.filter(c => Number(c.call_id) !== Number(id));

        setStateRef(filtered);
    }

    useMemo(() => {

        if (pid) {

            listener.private(`voicecall.${pid}`)
                .listen('IncommingCall', e => {
                    console.log(e);

                    let data = stateRef.current.concat(e.call);

                    setStateRef(data);
                })
                .listen('HangupCall', e => {
                    console.log(e);

                    let data = stateRef.current;

                    let filtered = data.filter(c => c.call_id !== e.call.call_id);

                    setStateRef(filtered);
                });

        }

    }, []);

    const data = useMemo(() => ({
        ...state, close
    }), [state]);

    return <VoiceCallContext.Provider value={data} {...props} />
}

export const useVoiceCallContext = () => useContext(VoiceCallContext);

export default VoiceCallProvider;