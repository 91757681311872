import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import deleteIcon from '../../../../assets/img/icons/delete-red.png';

import { trans } from '../../../../_providers/Translation';
import NoDataFound from '../../../partials/NoDataFound';
import Pagination from '../../../Pagination';
import moment from 'moment';
import AddOrEditEmployeeFreeDay from './AddOrEditEmployeeFreeDay';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Question from '../../../modals/messages/Question';

const localizer = momentLocalizer(moment);

function EmployeeFreeDays(props, ref) {

    const start = new Date();

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        id: null,
        loading: false,
        dataLoading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {
            start_date: moment(start).startOf('month').format('YYYY-MM-DD'),
            end_date: moment(start).endOf('month').format('YYYY-MM-DD'),
        }
    });

    const [events, setEvents] = useState([]);

    const addOrEditModalRef = useRef(null);
    const viewModalRef = useRef(null);
    const deleteModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        show: (id) => {
            handleShow(id);
        },
        hide: () => {
            handleHide();
        }
    }));


    useEffect(() => {
        if (!popup.id) {
            return;
        }

        setPopup(prev => ({
            ...prev,
            dataLoading: true,
        }));


        let params = {
            employee_id: popup.id,
            page: popup.page,
        };

        Object.entries(popup.filter).map(filter => {
            params[filter[0]] = filter[1];
        });

        Api.get('partners/employees/free-days', {
            params: params
        }).then(res => {
            setPopup(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                dataLoading: false,
            }));

            if (res.data.pages > 0 && res.data.pages < popup.page) {
                refresh(res.data.pages);
            }
        });
    }, [popup.id, popup.page, popup.refresh]);


    // useEffect(() => {
    //     let evnts = [];

    //     popup.data.map(event => {
    //         evnts.push({
    //             title: '',
    //             start: new Date(event.date),
    //             end: new Date(event.date),
    //             allDay: false,
    //             ...event
    //         });
    //     });

    //     setEvents(evnts);

    // }, [popup.data])


    const handleShow = (id) => {
        setPopup(prev => ({
            ...prev,
            id: id
        }));

        show();
    }

    const handleHide = e => {
        if (e) {
            e.preventDefault();
        }

        setPopup(prev => ({
            ...prev,
            id: null,
            data: [],
            edit: false,
        }));

        hide();
    }

    const show = async () => {
        return new Promise((resolve, reject) => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
            }));

            setTimeout(() => {
                setPopup(prev => ({
                    ...prev,
                    overlay: true,
                    modal: true
                }));

                return resolve();
            }, 50);
        });
    }

    const hide = async () => {
        return new Promise((resolve, reject) => {
            setPopup(prev => ({
                ...prev,
                modal: false,
                loading: false,
            }));

            setTimeout(() => {
                setPopup(prev => ({
                    ...prev,
                    overlay: false,
                    modal: false,
                }));

                return resolve();
            }, 50);
        });
    }

    const handlePage = page => {
        setPopup(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    const handleAdd = () => {

        let modal = addOrEditModalRef.current;

        hide().then(() => {
            modal.add(popup.id);
        });

    }

    const handleDelete = id => {
        let modal = deleteModalRef.current;

        hide().then(() => {
            modal.open();
        })

        modal.onSuccess(() => {
            handleDestroy(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const handleDestroy = id => {
        Api.post('partners/employees/free-days/remove', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh();
            }
        })
    }

    const refresh = (page) => {
        setPopup(prev => ({
            ...prev,
            page: page ? page : prev.page,
            refresh: new Date()
        }));
    }

    const onSave = (data) => {
        refresh();
    }


    const handleRangeChange = range => {

        // console.log(range)

        let start;
        let end;

        if (Array.isArray(range)) {
            start = moment(range[0]).format('YYYY-MM-DD');
            end = moment(range[range.length - 1]).format('YYYY-MM-DD');
        } else if (typeof range === 'object') {
            start = moment(range.start).format('YYYY-MM-DD');
            end = moment(range.end).format('YYYY-MM-DD');
        }


        if (start && end) {
            setPopup(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    start_date: start,
                    end_date: end
                },
                page: 1,
                refresh: new Date().getTime()
            }));
        }
    }

    const customDayPropGetter = date => {
        let isFreeDay = getFreeDay(date);

        if (isFreeDay)
            return {
                className: 'free-day',
            }
        else return {}
    }

    const getFreeDay = date => {
        return popup.data.find(e => moment(e.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'));
    }

    const eventPropGetter = (event, start, end) => {
        //
    }

    const handleSelectEvent = data => {
        // viewModalRef.current.open(data);
    }

    const onSelectSlot = data => {
        console.log(data)

        let freeDay = getFreeDay(data.start);

        console.log(freeDay)

        if (freeDay) {
            handleDelete(freeDay.id);
        }
    }

    return (
        <>
            <AddOrEditEmployeeFreeDay
                ref={addOrEditModalRef}
                employeeId={popup.id}
                onClose={show}
                onSave={onSave}
            />

            <Question
                ref={deleteModalRef}
                mainMessage={trans('headings.confirmDelete')}
            />

            <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
                <div className={`${popup.modal ? 'show' : ''} popup-primary large`}>
                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />

                        {trans('headings.freeDays')}

                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>

                    {/* {popup.dataLoading
                        ?
                        <img src={loader} alt="loading" />
                        :
                        popup.data.length === 0
                            ?
                            <NoDataFound />
                            : */}
                    <div className="scrollable" style={{ maxHeight: '70vh' }}>
                        {/* <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                {trans('tables.date')}
                                            </th>
                                            <th className="options">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {popup.data.map(i =>
                                            <tr key={'r-' + i.id}>
                                                <td>
                                                    {moment(i.date).format('DD MMM YYYY (dddd)')}
                                                </td>
                                                <td>
                                                    <a
                                                        onClick={() => handleRemove(i.id)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <img
                                                            src={deleteIcon}
                                                        />
                                                    </a>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                <Pagination
                                    pages={popup.pages}
                                    page={popup.page}
                                    handlePage={handlePage}
                                /> */}
                        <div style={{ height: '60vh' }}>
                            <Calendar
                                localizer={localizer}
                                defaultDate={start}
                                events={events}
                                views={['month']}
                                messages={{
                                    next: trans('calendar.next'),
                                    previous: trans('calendar.previous'),
                                    today: trans('calendar.today'),
                                    month: trans('calendar.month'),
                                    week: trans('calendar.week'),
                                    day: trans('calendar.day')
                                }}
                                popup
                                selectable
                                startAccessor="start"
                                endAccessor="end"
                                onRangeChange={handleRangeChange}
                                dayPropGetter={customDayPropGetter}
                                eventPropGetter={eventPropGetter}
                                onSelectEvent={handleSelectEvent}
                                onSelectSlot={onSelectSlot}
                            />
                        </div>
                    </div>

                    {/* } */}

                    <div className="footer align-center">
                        {popup.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="button" onClick={handleHide}>
                                    {trans('buttons.close')}
                                </button>

                                <button className="button button-add" onClick={handleAdd}>
                                    {trans('buttons.add')}
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(EmployeeFreeDays);