import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import Input from '../../../partials/Input';
import loader from '../../../../assets/img/loader.svg'
import { trans } from '../../../../_providers/Translation';
import Api from '../../../../_helpers/Api';
import { cloneDeep } from 'lodash';

function Status(props) {
    const listRef = useRef(null);

    const initialData = {
        data: [
            {
                id: 'pending',
                name: trans('random.pendding')
            },
            {
                id: 'closed',
                name: trans('random.closed')
            }
        ],
        initialStatus: [],
        finalStatus: [],
        showResults: false,
        loading: false,
        timeout: null,
    };

    const [data, setData] = useState(initialData);

    const [selected, setSelected] = useState({
        status: [],
        initialStatus: [],
        finalStatus: [],
    });

    const [focused, setFocused] = useState(0);

    useEffect(() => {
        Api.get('reservations/statuses')
            .then(res => {
                setData(prev => ({
                    ...prev,
                    initialStatus: res.data[1],
                    finalStatus: res.data[2]
                }))
            })
    }, []);

    useEffect(() => {
        if (Array.isArray(props.status)) {
            setSelected(prev => ({
                ...prev,
                status: props.status.filter(c => c),
            }));
        }
    }, [props.status]);

    useEffect(() => {
        if (Array.isArray(props.initialStatus)) {
            setSelected(prev => ({
                ...prev,
                initialStatus: props.initialStatus.filter(c => c),
            }));
        }
    }, [props.initialStatus]);

    useEffect(() => {
        if (Array.isArray(props.finalStatus)) {
            setSelected(prev => ({
                ...prev,
                finalStatus: props.finalStatus.filter(c => c),
            }));
        }
    }, [props.finalStatus]);

    const handleClickAway = () => {
        hideResults();
    }

    const handleKeyPress = e => {
        e.preventDefault();
        e.stopPropagation();

        showResults();

        let action;

        if (e.keyCode == 40) {
            action = 'down';
        } else if (e.keyCode == 38) {
            action = 'up';
        } else if (e.keyCode == 13) {
            action = 'enter';
        }

        if (action) {
            if (action === 'down' || action === 'up') {

                let min = 0;
                let max = data.original.length - 1;

                let position;

                if (action === 'down') {
                    position = focused + 1;
                } else {
                    position = focused - 1;
                }

                if (position < min || position > max) {
                    return;
                }

                setFocused(position);

                setTimeout(() => {
                    const selectedFocus = listRef.current.querySelector('.focus');

                    if (selectedFocus) {
                        const offsetHeight = selectedFocus.offsetHeight;
                        const offsetTop = selectedFocus.offsetTop;

                        const scroll = listRef.current.scrollTop;
                        const height = listRef.current.offsetHeight;

                        if (offsetTop + offsetHeight > scroll + height) {
                            listRef.current.scrollTop = scroll + offsetHeight;
                        } else if (offsetTop < scroll) {
                            listRef.current.scrollTop = scroll - offsetHeight;
                        }
                    }

                }, 50);

            } else if (action === 'enter') {

                // let selectedFocus = data.original[focused];

                const selectedFocus = listRef.current.querySelector('.focus');

                if (selectedFocus) {
                    selectedFocus.click();
                }

            }
        }
    }

    const handleBlur = e => {
        return;


    }

    const showResults = () => {
        setData(prev => ({
            ...prev,
            showResults: true
        }));
    }

    const hideResults = () => {
        setData(prev => ({
            ...prev,
            showResults: false
        }));
    }

    const handleBasicResult = data => {
        let obj = [...selected.status];

        if (obj.indexOf(String(data.id)) > -1) {
            obj = obj.filter(id => String(id) !== String(data.id));
        } else {
            obj = obj.concat(String(data.id));
        }

        setSelected(prev => ({
            ...prev,
            status: obj
        }));

        props.onBasicStatusChange(obj);
    }

    const handleInitialStatus = data => {
        let obj = [...selected.initialStatus];

        if (obj.indexOf(String(data.id)) > -1) {
            obj = obj.filter(id => String(id) !== String(data.id));
        } else {
            obj = obj.concat(String(data.id));
        }

        setSelected(prev => ({
            ...prev,
            initialStatus: obj
        }));

        props.onInitialStatusChange(obj);
    }

    const handleFinalStatus = data => {
        let obj = [...selected.finalStatus];

        if (obj.indexOf(String(data.id)) > -1) {
            obj = obj.filter(id => String(id) !== String(data.id));
        } else {
            obj = obj.concat(String(data.id));
        }

        setSelected(prev => ({
            ...prev,
            finalStatus: obj
        }));

        props.onFinalStatusChange(obj);
    }

    const renderItem = ({ e, name, isSelected, onClick }) => {
        return (
            <li
                key={e.id}
                data-id={e.id}
                data-name={name}
                onClick={event => onClick(e)}
                className={[isSelected ? 'active' : '', 'multiple'].join(' ')}
                style={{
                    whiteSpace: 'nowrap'
                }}
            >

                <>
                    <svg className="selected" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                    </svg>
                    <svg className="not-selected" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                    </svg>
                </>

                {name}
            </li>
        )
    }

    const getInputValue = () => {
        let text = [];

        selected.status.map(id => {
            let status = data.data.find(status => status.id == id);

            if (status) {
                text.push(status.name)
            }
        });

        selected.initialStatus.map(id => {
            let status = data.initialStatus.find(status => status.id == id);

            if (status) {
                text.push(status.translation?.name)
            }
        });

        selected.finalStatus.map(id => {
            let status = data.finalStatus.find(status => status.id == id);

            if (status) {
                text.push(status.translation?.name)
            }
        });

        return text.join(', ');
    }

    const heading = {
        fontSize: '10px',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        background: '#cecece'
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="dropdown-input" onKeyUp={handleKeyPress}>
                <Input autoComplete="off" role="presentation"
                    type="text"
                    // name={inputName}
                    // className={inputClassName}
                    value={getInputValue()}
                    onFocus={showResults}
                    onBlur={handleBlur}
                    // onChange={handleChange}
                    // onKeyUp={handleChange}
                    error={props.error}
                    helperText={props.helperText}
                    readOnly
                />
                <div
                    ref={listRef}
                    className={['dropdown', data.showResults && (data.data.length || data.loading) ? 'show' : ''].join(' ')}
                    style={{
                        display: 'flex',
                        width: 'auto'
                    }}
                >
                    <ul>
                        <li style={heading}>
                            {trans('random.summarizedStatus')}
                        </li>
                        {data.data.map((e, i) =>
                            <React.Fragment key={e.id}>
                                {renderItem({
                                    e: e,
                                    name: e.name,
                                    isSelected: selected.status.indexOf(String(e.id)) > -1,
                                    onClick: handleBasicResult
                                })}
                            </React.Fragment>
                        )}
                    </ul>
                    <ul>
                        <li style={heading}>
                            {trans('random.initialStatus')}
                        </li>
                        {data.initialStatus.map((e, i) =>
                            <React.Fragment key={e.id}>
                                {renderItem({
                                    e: e,
                                    name: e.translation?.name,
                                    isSelected: selected.initialStatus.indexOf(String(e.id)) > -1,
                                    onClick: handleInitialStatus
                                })}
                            </React.Fragment>
                        )}
                    </ul>
                    <ul>
                        <li style={heading}>
                            {trans('random.finalStatus')}
                        </li>
                        {data.finalStatus.map((e, i) =>
                            <React.Fragment key={e.id}>
                                {renderItem({
                                    e: e,
                                    name: e.translation?.name,
                                    isSelected: selected.finalStatus.indexOf(String(e.id)) > -1,
                                    onClick: handleFinalStatus
                                })}
                            </React.Fragment>
                        )}
                    </ul>
                </div>
            </div>
        </ClickAwayListener>
    )
}

export default Status;