import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Designs from './partials/Designs';
import Api from '../../../_helpers/Api';
import Pages from './partials/Pages';
import FilterTabs from './FilterTabs';
import { useAuthDataContext } from '../../../_providers/Auth';
import loader from '../../../assets/img/loader.svg';
import { trans } from '../../../_providers/Translation';
import DNS from './modals/DNS';

function Index() {

    const location = useLocation();
    const auth = useAuthDataContext();
    const history = useHistory();
    const params = useParams();

    const tabs = ['design', 'pages'];
    const tab = new URLSearchParams(location.search).get('tab');

    const DNSModalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        initialLoading: true,
        update: false,
        startWebsite: false,
        partner: []
    });

    useEffect(() => {
        handlePartner();
    }, [])

    const [partner, setPartner] = useState({
        loading: true,
        data: [],
        update: false,
        tab: tabs.indexOf(tab) > -1 ? tab : tabs[0],
    });

    const handleStartWebsite = () => {
        setState(prev => ({
            ...prev,
            startWebsite: true
        }))
    }

    const handlePartner = () => {
        Api.get('/partners/find?id=' + auth.user().partner_id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    partner: res.data,
                    loading: false
                }))
            })
    }

    const handleTab = newValue => {

        history.push(`?tab=${newValue}`);

        setPartner(prev => ({
            ...prev,
            tab: newValue
        }))
    }

    const showDomainInfo = () => {
        DNSModalRef.current.show();
    }

    return (
        <>
            <DNS
                ref={DNSModalRef}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.website')}
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.website')}
                        </p>
                    </div>

                    <div className="right">
                        <button className="button button-primary" onClick={showDomainInfo}>
                            {trans('random.DNSInfo')}
                        </button>
                    </div>
                </div>

                {state.loading
                    ?
                    <img class='loader' src={loader} />
                    :
                    state.partner && !state.partner.design_id && !state.startWebsite ?
                        <div className="welcome">
                            <h2>
                                {trans('headings.website')}
                            </h2>
                            <p>
                                {trans('random.websiteDesc')}
                            </p>
                            <button
                                onClick={handleStartWebsite}
                                className="button-add"
                            >
                                {trans('buttons.create')}
                            </button>
                        </div>
                        :
                        <>

                            <FilterTabs
                                tab={partner.tab}
                                handleTab={handleTab}
                                design_id={state.partner.design_id}
                            />

                            {partner.tab === 'design'
                                ?
                                <Designs
                                    handlePartner={handlePartner}
                                />
                                :
                                partner.tab === 'page'
                                    ?
                                    <Pages />
                                    :
                                    ''
                            }

                        </>
                }
            </div>
        </>

    )

}

export default Index