import React, { useEffect, useRef, useState } from 'react';
import Api from '../../_helpers/Api';
import deleteIcon from '../../assets/img/icons/delete.png';
import { trans } from '../../_providers/Translation';

function AppliancesCategoriesPricing(props) {

    const tableRef = useRef(null);
    const inputName = props.name || 'prices';
    const brandsInputName = props.brandsName || 'prices_brands';

    const [state, setState] = useState({
        prices: [],
        folded: true,
    });

    const [brands, setBrands] = useState([]);

    useEffect(() => {
        if (Array.isArray(props.prices)) {
            setState(prev => ({
                ...prev,
                prices: props.prices
            }));
        }
    }, [props.prices]);

    useEffect(() => {
        Api.get('appliances/brands/all')
            .then(res => {
                setBrands(res.data.items);
            });
    }, []);

    const handleCategoryPriceChange = (e, cid) => {
        let val = e.target.value;

        let prices = [...state.prices];

        prices.map(c => {
            if (Number(c.id) === Number(cid)) {
                c.price_formatted = val;
            }

            return c;
        });

        setState(prev => ({
            ...prev,
            prices: prices
        }));
    }

    const handleBrandPriceChange = (e, cid, bid) => {
        let val = e.target.value;

        let prices = [...state.prices];

        prices.map(c => {
            if (Number(c.id) === Number(cid)) {
                c.brands_prices.map(b => {
                    if (Number(b.brand_id) === Number(bid)) {
                        b.price_formatted = val;
                    }

                    return b;
                });
            }

            return c;
        });

        setState(prev => ({
            ...prev,
            prices: prices
        }));
    }

    const getCategoryPrice = cid => {
        let prices = state.prices;

        let category = prices.filter(c => {
            return Number(c.id) === Number(cid);
        })[0];

        if (category) {
            return category.price_formatted;
        }

        return 0;
    }

    const getBrandPrice = (cid, bid) => {
        let prices = state.prices;

        let category = prices.filter(c => {
            return Number(c.id) === Number(cid);
        })[0];

        if (category) {
            let brands = category.brands_prices;

            let brand = brands.filter(b => {
                return Number(b.brand_id) === Number(bid);
            })[0];

            if (brand) {
                return brand.price_formatted;
            }
        }

        return 0;
    }

    const handleToggleRow = rowId => {

        // tableRef.current.querySelectorAll('.collapsable-table tr.active').forEach(el => {
        //     el.classList.remove('active');
        // });

        let rows = tableRef.current.querySelectorAll(`tr[data-row="${rowId}"]`)

        for (let i = 0; i < rows.length; i++) {
            if (rows[i].classList.contains('active')) {
                rows[i].classList.remove('active');
            } else {
                rows[i].classList.add('active')
            }
        }
    }

    const getNotSelectedBrands = cid => {
        let prices = state.prices;

        let category = prices.filter(c => {
            return Number(c.id) === Number(cid);
        })[0];

        let options = [...brands];

        if (category) {
            let brands = category.brands_prices;

            brands.map(b => {
                options = options.filter(s => {
                    return Number(s.id) !== Number(b.brand_id);
                });
            })
        }

        return options;
    }

    const handleAddBrandPrice = e => {
        let id = Number(e.target.value);
        let cid = Number(e.target.getAttribute('data-cid'));

        let brand = brands.filter(b => {
            return b.id === id;
        })[0];

        let brandPrice = {
            id: Math.random(),
            brand_id: id,
            price: 0,
            price_formatted: 0,
            brand: brand
        };

        let prices = [...state.prices];

        prices.map(c => {
            if (Number(c.id) === cid) {
                c.brands_prices = c.brands_prices.concat(brandPrice);
            }

            return c;
        });

        setState(prev => ({
            ...prev,
            prices: prices
        }));

    }

    const handleRemoveBrandPrice = (cid, bid) => {
        let prices = [...state.prices];

        prices.map(c => {
            if (Number(c.id) === Number(cid)) {
                c.brands_prices = c.brands_prices.filter(b => {
                    return Number(b.brand_id) !== Number(bid);
                });
            }

            return c;
        });

        setState(prev => ({
            ...prev,
            prices: prices
        }));
    }

    const handleInputClick = e => {
        e.stopPropagation();
    }

    const toggleFolded = () => {
        setState(prev => ({
            ...prev,
            folded: !prev.folded
        }));
    }

    return (
        <table ref={tableRef} className="collapsable-table">
            <thead onClick={toggleFolded}>
                <tr>
                    <th>
                        {trans('tables.category')}
                    </th>
                    <th style={{ width: '80px' }}>
                        {trans('tables.price')}
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr style={!state.folded ? { display: 'none' } : {}}>
                    <td colSpan="2" style={{ textAlign: 'center' }} onClick={toggleFolded}>
                        {trans('tables.clickToViewCategories')}
                    </td>
                </tr>
                {state.prices.map(c =>
                    <React.Fragment key={'price-' + c.id}>
                        <tr data-row={c.id} style={state.folded ? { display: 'none' } : {}}>
                            <td style={{ padding: '5px 20px' }} onClick={e => handleToggleRow(c.id)}>
                                <span>
                                    {c.name}
                                    {c.default_price_formatted
                                        ?
                                        <>
                                            <br />
                                            {c.default_price_formatted} {c.currency ? c.currency.name : ''}
                                        </>
                                        :
                                        ''
                                    }
                                </span>
                            </td>
                            <td style={{ padding: '5px 20px', width: '80px' }} onClick={e => handleToggleRow(c.id)}>
                                <input
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    name={`${inputName}[${c.id}]`}
                                    value={getCategoryPrice(c.id)}
                                    onClick={handleInputClick}
                                    onChange={e => handleCategoryPriceChange(e, c.id)}
                                    style={{
                                        width: '80px',
                                        height: '25px'
                                    }}
                                />
                            </td>
                        </tr>
                        {Array.isArray(c.brands_prices) &&
                            <tr className="collapsable" data-row={c.id} style={state.folded ? { display: 'none' } : {}}>
                                <td colSpan="2">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    {trans('tables.make')}
                                                </th>
                                                <th style={{ width: '80px' }}>
                                                    {trans('tables.price')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {c.brands_prices.map(b =>
                                                <tr key={'cbp-' + b.id}>
                                                    <td>
                                                        <img
                                                            src={deleteIcon}
                                                            alt="del"
                                                            style={{ verticalAlign: 'middle', marginRight: '3px' }}
                                                            onClick={() => handleRemoveBrandPrice(c.id, b.brand.id)}
                                                        />
                                                        {b.brand.name}:
                                                    </td>
                                                    <td style={{ width: '80px' }}>
                                                        <input
                                                            type="number"
                                                            step="0.01"
                                                            min="0"
                                                            name={`${brandsInputName}[${c.id}][${b.brand.id}]`}
                                                            value={getBrandPrice(c.id, b.brand.id)}
                                                            onChange={e => handleBrandPriceChange(e, c.id, b.brand.id)}
                                                            style={{
                                                                width: '80px',
                                                                height: '25px'
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td style={{ background: 'ghostwhite' }}>
                                                    {trans('tables.addPrice')}
                                                </td>
                                                <td style={{ background: 'ghostwhite', padding: '5px 20px' }}>
                                                    <select
                                                        value=""
                                                        data-cid={c.id}
                                                        onChange={handleAddBrandPrice}
                                                        style={{
                                                            width: '80px',
                                                            height: '25px'
                                                        }}
                                                    >
                                                        <option value=""></option>
                                                        {getNotSelectedBrands(c.id).map(b =>
                                                            <option
                                                                key={'add-b-' + b.id}
                                                                value={b.id}
                                                            >
                                                                {b.name}
                                                            </option>
                                                        )}
                                                    </select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>

                            </tr>
                        }
                    </React.Fragment>
                )}
            </tbody>

        </table>
    )
}

export default AppliancesCategoriesPricing;