import React, { useState, useEffect } from 'react';
import '../../assets/css/header.css'

//modules
import Messages from './modules/Messages';
import Notifications from './modules/Notifications';
import Employees from './modules/Employees';

import { useAuthDataContext } from '../../_providers/Auth';

//images
import brand from '../../assets/img/header/brand.png';
import chat from '../../assets/img/header/icons/chat.png';
import notification from '../../assets/img/header/icons/notification.png';
import mail from '../../assets/img/header/icons/mail.png';
import settings from '../../assets/img/header/icons/settings.png';
import profile from '../../assets/img/header/icons/profile.png';
import hamburger from '../../assets/img/header/menu.png';
import { useNotificationsContext } from '../../_providers/Notifications';
import { useMessagesContext } from '../../_providers/Messages';
import { useHistory } from 'react-router-dom';
import { trans } from '../../_providers/Translation';

function Header() {

    const auth = useAuthDataContext();
    const messages = useMessagesContext();
    const notifications = useNotificationsContext();
    const history = useHistory();

    const handleLogout = () => auth.logout()

    const handleMobileMenu = () => {
        let menu = document.querySelector('aside')

        if (menu.classList.contains('visable-aside')) {
            menu.classList.remove('visable-aside')
        } else {
            menu.classList.add('visable-aside')
        }
    }

    const handleShowSettings = () => {
        history.push('/profile/settings');
    }

    document.querySelector('body').addEventListener('click', e => {
        if (e.target.classList.contains('visable-aside')) handleMobileMenu()
    })

    return (
        <header>
            <div className="wrapper">
                <img src={brand} alt="euSystem" className="brand" />
                <img
                    src={hamburger}
                    alt="menu"
                    className="mobile-menu"
                    onClick={handleMobileMenu}
                />
                <nav>
                    <ul className="header-menu">
                        {!auth.isAdmin() &&
                            <li>
                                <div className="icon">

                                    <img src={profile} alt="profile" />
                                </div>
                                <Employees />
                            </li>
                        }
                        {auth.access('livechat') &&
                            <li>
                                <div className="icon">
                                    {messages.count > 0 &&
                                        <span>
                                            {messages && messages.count > 99 ? '99+' : messages.count}
                                        </span>
                                    }
                                    <img src={chat} alt="chat" />
                                </div>
                                <Messages />
                            </li>
                        }
                        <li>
                            <div className="icon">
                                {notifications.count > 0 &&
                                    <span>
                                        {notifications && notifications.count > 99 ? '99+' : notifications.count}
                                    </span>
                                }
                                <img src={notification} alt="notification" />
                            </div>
                            <Notifications />
                        </li>
                        <li onClick={handleShowSettings}>
                            <div className="icon">
                                <img src={settings} alt="settings" />
                            </div>
                        </li>
                        <li className="profile">
                            <div className="icon profile">
                                <img src={profile} alt="profile" />
                            </div>
                            <div className="show messages-module">
                                <div className="head">
                                    {trans('labels.profile')}
                                </div>
                                <div className="body">
                                    <div className="user">
                                        <img src={auth?.data?.user?.avatar} alt="" />
                                        <span>
                                            {auth?.data?.user?.name} {auth?.data?.user?.lastname}
                                        </span>
                                        {auth?.data?.user?.role
                                            ?
                                            <span>
                                                ({auth?.data?.user?.role?.translation?.name})
                                            </span>
                                            :
                                            ''
                                        }
                                    </div>
                                    <button className="button-primary button-small" onClick={handleLogout}>
                                        {trans('buttons.exit')}
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header;