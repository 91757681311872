import React, { useRef, useState, useEffect } from 'react';
import { useAuthDataContext } from '../../../../_providers/Auth';
import Options from '../WebPageOptions';
import OptionsModal from '../modals/WebPageOptions';
import Api from '../../../../_helpers/Api';
import editIcon from '../../../../assets/img/icons/edit-yellow.png';
import Skeleton from 'react-loading-skeleton';
import Modal from '../modals/WebSites';
import AddEditModal from '../modals/AddEditModal';
import Question from '../../../modals/messages/Question'

import secEditIcon from '../../../../assets/img/icons/edit.png';
import deleteIcon from '../../../../assets/img/icons/delete-red.png';
import checkIcon from '../../../../assets/img/icons/check.png';
import attentionIcon from '../../../../assets/img/icons/attention-icon.png';
import { trans } from '../../../../_providers/Translation';

function Pages() {

    const auth = useAuthDataContext();
    const modalRef = useRef(null);
    const modalOptionsRef = useRef(null);
    const modalAddOrEditRef = useRef(null);
    const questionModalRef = useRef(null);

    const [tabs, setTabs] = useState([
        {
            id: 1,
            name: trans('tabs.homePage')
        },
        {
            id: 2,
            name: trans('tabs.cookiePage')
        },
        {
            id: 3,
            name: trans('tabs.termsPage')
        },
        {
            id: 4,
            name: trans('tabs.politicsPage')
        }

    ]);

    const [state, setState] = useState({
        loading: true,
        update: false,
        data: [],
        pages: [],
        products: [],
        item: [],
        tab: tabs[0].id,
        index: 0
    })

    const [section, setSection] = useState({
        data: []
    })

    useEffect(() => {
        loadSettings();
        loadSection(state.tab);
        loadProducts();
    }, [state.update]);

    const loadSettings = () => {
        let array = [];
        Api.get('website/pages?partner_id=' + auth.user().partner_id)
            .then(res => {
                res.data.forEach(e => {
                    let object = {
                        id: e.page.id,
                        name: e.page.name
                    }
                    array.push(object);
                });
                setTabs(
                    array
                );

                setState(prev => ({
                    ...prev,
                    data: res.data
                }));
                loadPages(res.data);
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const loadPages = (data) => {
        Api.get('website/find')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    pages: res.data
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleEdit = (id, section_id) => {
        let modal = modalOptionsRef.current;

        modal.edit(id, section_id);

        modal.onSave(data => {
            handleUpdate();
        });
    }

    const showModal = () => {
        let modal = modalRef.current;

        modal.show(state.data, state.pages);

        modal.onSave(() => {
            handleUpdate();
        });
    }

    const editModal = (data) => {
        let update = modalAddOrEditRef.current;

        update.edit(data);

        update.onSave(() => {
            handleUpdate();
        });
    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const handleTab = id => {

        let index = findTabIndex(id);

        setState(prev => ({
            ...prev,
            tab: id,
            loading: true,
            index: index
        }));

        loadSection(id);

    }

    const loadSection = (id) => {
        console.log(id)
        Api.get('website/section/get?&page_id=' + id + '&partner_id=' + auth.user().partner_id)
            .then(res => {
                setSection(prev => ({
                    ...prev,
                    data: res.data,
                }))
                setState(prev => ({
                    ...prev,
                    loading: false
                }))
            });
    }

    const findTabIndex = id => {
        let index = tabs.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    const checkData = (data) => {
        let dat = data.content_type.map(t => {
            return t.content_section.length != 0;
        });

        if (dat.includes(false)) {
            return false;
        } else {
            return true
        }
    }

    const loadProducts = () => {
        Api.get('website/products/get-partner?partner_id=' + auth.user().partner_id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    products: res.data
                }))
            })
    }

    const loadItem = (id) => {
        Api.get('website/products/get-id?id=' + id)
            .then(res => {
                editModal(res.data)
            })
    }

    const showAddModal = () => {
        let create = modalAddOrEditRef.current;

        create.add();

        create.onSave(() => {
            handleUpdate();
        });
    }

    const handleDelete = id => {
        let modal = questionModalRef.current;

        modal.open();

        modal.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleDestroy = (id) => {
        Api.post(`website/products/delete?id=${id}`)
            .then(res => {
                handleUpdate();
            })
    }

    return (
        <>

            {state.loading
                ?
                <Skeleton height={35} count={10} />
                :
                <>

                    <Modal
                        ref={modalRef}
                    />

                    <OptionsModal
                        ref={modalOptionsRef}
                        handleRefresh={handleUpdate}
                    />

                    <AddEditModal
                        ref={modalAddOrEditRef}
                        handleRefresh={handleUpdate}
                    />

                    <Question
                        ref={questionModalRef}
                        mainMessage={trans('questions.delete')}
                    />

                    <div id="settings">

                        <div className="tabs">
                            {tabs.map(t =>
                                typeof t.access === 'undefined' || t.access
                                    ?
                                    <div
                                        key={'tab-' + t.id}
                                        className={['tab', state.tab === t.id ? 'active' : ''].join(' ')}
                                        onClick={() => handleTab(t.id)}
                                    >
                                        {t.name}
                                    </div>
                                    :
                                    ''
                            )}
                        </div>


                        <div style={{ width: '100%' }}>
                            {/* <div className="head">
                                <h3 style={{ textAlign: 'center' }}>Страници към уеб сайт
                                </h3>
                                <div className='right'>
                                    <button onClick={showModal} className="button button-add">
                                        Добавяне
                                    </button>
                                </div>
                            </div> */}
                            {section.data.map(t =>
                                <div className="table-scroller" style={{ marginBottom: '10px' }}>
                                    <table className="settings">
                                        <thead>
                                            <tr>
                                                <td style={{ fontSize: '18px', width: '30%' }}>
                                                    {t.translation.name}
                                                    {checkData(t) ? <img src={checkIcon} title={trans('random.websiteContentOk')} /> : <img src={attentionIcon} title={trans('random.websiteContentErr')} />}
                                                </td>
                                                <td style={{ textAlign: 'end' }} className="options">
                                                    <Options
                                                        id={state.tab}
                                                        section_id={t.id}
                                                        handleEdit={handleEdit}
                                                        handleUpdate={handleUpdate}
                                                    />
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {t.content_type.map(c =>
                                                <tr key={c.id}>
                                                    <td colSpan='2' style={{ width: '100%' }}>
                                                        {c.translation.name}
                                                        <div className='row' style={{ flexWrap: 'wrap', marginBottom: '0px' }}>
                                                            {
                                                                c.content.name == 'title' ?
                                                                    <>
                                                                        <div className='col'>
                                                                            {c.content_section.map(item =>
                                                                                <input
                                                                                    type='text'
                                                                                    style={{ backgroundColor: 'white' }}
                                                                                    value={item.content}
                                                                                    disabled
                                                                                />
                                                                            )}

                                                                        </div>
                                                                    </>

                                                                    : c.content.name == 'text' ?
                                                                        <>
                                                                            <div className='col'>
                                                                                {c.content_section.map(item =>

                                                                                    <textarea
                                                                                        type='text'
                                                                                        style={{ backgroundColor: 'white' }}
                                                                                        value={item.content}
                                                                                        disabled
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                        : ''}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <br />
                            {state.tab == 1 &&
                                <>
                                    <div className="head">
                                        <h3 style={{ textAlign: 'center' }}>
                                            {trans('headings.appliances')}
                                        </h3>
                                        <div className='right'>
                                            <button onClick={showAddModal} className="button button-add">
                                                {trans('buttons.add')}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="table-scroller">
                                        <table className="settings">
                                            <thead>
                                                <tr>
                                                    <td style={{ fontSize: '14px', width: '30%' }}>
                                                        {trans('labels.name')}
                                                    </td>
                                                    <td style={{ textAlign: 'end' }} className="options">
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.products.map(item =>
                                                    <tr>
                                                        <td style={{ fontSize: '14px', width: '100%' }}>
                                                            <div className='row' style={{ flexWrap: 'wrap', marginBottom: '0px' }}>
                                                                {item.name}
                                                            </div>
                                                        </td>
                                                        <td className="options">
                                                            <a onClick={() => loadItem(item.id)}>
                                                                <img class='pointer' src={secEditIcon} alt="" />
                                                            </a>
                                                        </td>
                                                        <td className="options">
                                                            <a onClick={() => handleDelete(item.id)}>
                                                                <img class='pointer' src={deleteIcon} alt="" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </>}
                        </div>
                    </div>
                </>
            }
        </>
    )
}
export default Pages