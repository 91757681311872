import React, { useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function FilterMails(props) {

    const [tabs, setTabs] = useState({
        ripple: true
    })

    return (
        <div className="small-tabs">
            <Tabs
                value={props.tab}
                onChange={(e, id) => props.handleTab(id)}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs"
            >
                {props.tabs.map(t =>
                    <Tab
                        key={t.id}
                        value={String(t.id)}
                        label={t.name}
                        disableRipple={tabs.ripple}
                    />
                )}
            </Tabs>
        </div>
    )
}

export default FilterMails;