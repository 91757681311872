import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Api from '../../../../_helpers/Api';
import loader from '../../../../assets/img/loader.svg';
import Chart from "react-google-charts";
import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../../_providers/Translation';

let timeout;

function Index(props) {

    const params = useParams();
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const [state, setState] = useState({
        filter: {

        },
        setFilter: false,
        update: false,
    });

    const [reservations, setReservations] = useState({
        loading: true,
        data: [],
        chart: null
    });

    const [incomes, setIncomes] = useState({
        loading: true,
        data: [],
        chart: null
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    // useEffect(() => {
    //     console.log(state.filter);
    // }, [state.filter]);

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadReservations();
            loadIncomes();
        }
    }, [state.update]);

    const loadReservations = () => {

        setReservations(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'contractors/stats/reservations' + '?id=' + params.id;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {

                let label = trans('calendar.month');

                let chart = [
                    [label, trans('tabs.reservations')],
                ];

                res.data.dates.map(d => {
                    let arr = [
                        d.label, d.total
                    ];

                    chart.push(arr);
                })

                setReservations(prev => ({
                    ...prev,
                    data: res.data,
                    chart: chart
                }))
            })
            .finally(() => {
                setReservations(prev => ({
                    ...prev,
                    loading: false
                }));
            })
    }

    const loadIncomes = () => {

        setIncomes(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'contractors/stats/incomes' + '?id=' + params.id;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {

                let label = trans('calendar.month');

                let chart = [
                    [label, trans('random.income')],
                ];

                res.data.dates.map(d => {
                    let arr = [
                        d.label, d.total
                    ];

                    chart.push(arr);
                })

                setIncomes(prev => ({
                    ...prev,
                    data: res.data,
                    chart: chart
                }))
            })
            .finally(() => {
                setIncomes(prev => ({
                    ...prev,
                    loading: false
                }));
            })
    }

    const refreshData = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    //Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
            page: 1,
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const getColumns = () => {
        let arr = [];

        incomes.data.dates.map((m, i) => {
            arr.push({
                type: 'NumberFormat',
                column: i + 1,
                options: {
                    // prefix: '$',
                    negativeParens: false,
                }
            })
        });

        return arr;
    }

    const style = {
        padding: '10px',
        background: '#fff'
    }

    return (
        <>
            <div className="row">
                <div className="col" style={style}>
                    {reservations.loading
                        ?
                        <Skeleton height={400} />
                        :
                        <Chart
                            width={'100%'}
                            height={'400px'}
                            chartType="Bar"
                            className="animated-chart-vertical animated-chart-start"
                            loader={'...'}
                            data={reservations.chart}
                            options={{
                                // Material design options
                                chart: {
                                    title: trans('random.reservationsCount'),
                                    subtitle: trans('random.forLastYear'),
                                },
                                legend: {
                                    position: 'none'
                                }
                            }}
                            chartEvents={[
                                {
                                    eventName: "ready",
                                    callback: ({ chartWrapper, google }) => {
                                        const chartEl = chartWrapper.getChart().container;
                                        setTimeout(() => {
                                            chartEl.classList.remove('animated-chart-start')
                                        }, 100)
                                    },
                                }
                            ]}

                        />
                    }

                </div>
                <div className="col" style={style}>
                    {incomes.loading
                        ?
                        <Skeleton height={400} />
                        :
                        <Chart
                            width={'100%'}
                            height={'400px'}
                            chartType="Bar"
                            className="animated-chart-vertical animated-chart-start"
                            loader={'...'}
                            data={incomes.chart}
                            options={{
                                // Material design options
                                chart: {
                                    title: trans('headings.incomes'),
                                    subtitle: trans('random.forLastYear') + (` ${incomes.data.currency} `),
                                },
                                legend: {
                                    position: 'none'
                                }
                            }}
                            chartEvents={[
                                {
                                    eventName: "ready",
                                    callback: ({ chartWrapper, google }) => {
                                        const chartEl = chartWrapper.getChart().container;
                                        setTimeout(() => {
                                            chartEl.classList.remove('animated-chart-start')
                                        }, 100)
                                    },
                                }
                            ]}
                            formatters={getColumns()}
                        />
                    }
                </div>
            </div>


        </>
    )
}

export default Index;