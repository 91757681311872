import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import Dropdown from '../../../partials/Dropdown';
import Error from '../../../modals/messages/Error';
import EditPayee from './EditPayee';

function Upload(props, ref) {

    const formRef = useRef(null);
    const inputFileRef = useRef(null);
    const errorModalRef = useRef(null);
    const parseModalRef = useRef(null);
    const editPayeeModalRef = useRef(null);
    const categoryErrorModalRef = useRef(null);

    const defaultData = {
        hasUploadedFile: false,
        showSaveButton: false,
        transactions: []
    }

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: defaultData,
        loading: false,
    });

    const [categories, setCategories] = useState([]);

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: () => {

            setPopup(prev => ({
                ...prev,
                loading: false,
                data: defaultData
            }));

            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    useEffect(() => {
        Api.get('expenses/categories/all?paginate=0')
            .then(res => {
                setCategories(res.data.items);
            });
    }, []);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        if (e) {
            e.preventDefault();
        }

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleUpload = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'banktransactions/upload';

        let data = new FormData();

        data.append('file', e.target.files[0]);

        Api.post(url, data)
            .then(res => {

                if (res.data.length) {
                    setPopup(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            showSaveButton: true,
                            transactions: res.data
                        }
                    }));
                }

            })
            .catch(error => {
                let _err = error.response;

                if (_err && _err.status) {
                    if (_err.status == 422) {
                        for (let [input, err] of Object.entries(_err.data.errors)) {
                            setValidations(prevState => {
                                return { ...prevState, [input]: true }
                            });
                        }
                    } else if (_err.status == 415) {
                        let modal = parseModalRef.current;

                        handleHide();

                        modal.open();
                        modal.onClose(() => {
                            handleShow();
                        });
                    }
                }

            })
            .finally(() => {
                setPopup(prev => ({
                    ...prev,
                    loading: false,
                    data: {
                        ...prev.data,
                        hasUploadedFile: true
                    }
                }));
            });
    }

    const handleSave = e => {
        e.preventDefault();

        let form = e.target;

        let expenses = form.querySelectorAll('select');

        let hasMissingCategory = false;

        for (let i = 0; i < expenses.length; i++) {
            let el = expenses[i];

            if (!el.value) {
                hasMissingCategory = true;

                el.classList.add('validate');
            }
        }

        if (hasMissingCategory) {
            let modal = categoryErrorModalRef.current;

            handleHide();

            modal.open();
            modal.onClose(() => {
                handleShow();
            });

            return;
        }

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        let url = 'banktransactions/save';

        let data = new FormData(form);

        data.append('file', inputFileRef.current.files[0]);

        Api.post(url, data)
            .then(res => {
                if (res.data.success) {
                    handleHide();
                    props.refreshData();
                }
            })
            .catch(err => {
                handleHide();

                let modal = errorModalRef.current;

                modal.open();
                modal.onClose(() => {
                    handleShow();
                })
            });
    }

    const handleInputChange = (e, index, isCheckbox = false) => {
        let name = e.target.name.split('[')[0];
        let val = e.target.value;

        let transactions = [...popup.data.transactions];

        let row = transactions[index];

        if (!row.match) {
            row.match = {};
        }

        if (isCheckbox) {
            row.match[name] = !row.match[name];
        } else {
            row.match[name] = val;
        }

        transactions[index] = row;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                transactions: transactions
            }
        }));
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.value = '';
        inputFileRef.current.click();
    }

    const highlight = (string, search) => {
        // let invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
        let invalid = /[()\[\]{}*\\]+/g;

        string = string.replace(invalid, '');
        search = search.replace(invalid, '');

        let regex = new RegExp(search, 'gi');

        let str = string.replace(regex, str => {
            return '<b style="color: #0088ff">' + str + '</b>';
        });

        return str;
    }

    const createMarkup = str => {
        return { __html: str };
    }

    const handleEditPayee = (index) => {
        let modal = editPayeeModalRef.current;

        let input = formRef.current.querySelector('[name="match[' + index + '][name]"]');

        let text;

        if (input) {
            text = input.value;
        }

        handleHide();

        modal.show(text);

        modal.onSave(newText => {
            let transactions = [...popup.data.transactions];
            let row = transactions[index];

            if (!row.match) {
                row.match = {};
            }

            row.match.name = newText;

            if (!row.payee) {
                row.payee = newText;
            }

            transactions[index] = row;

            setPopup(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    transactions: transactions
                }
            }));

        });

        modal.onClose(() => {
            handleShow();
        });
    }

    return (
        <>
            <Error
                ref={parseModalRef}
                mainMessage={trans('headings.error4')}
            />
            <Error
                ref={errorModalRef}
                mainMessage={trans('headings.error3')}
            />
            <Error
                ref={categoryErrorModalRef}
                mainMessage={trans('headings.error5')}
            />
            <EditPayee
                ref={editPayeeModalRef}
            />
            <div className={`${popup.overlay ? 'visible' : ''} overlay`}>

                <form ref={formRef} className={[popup.modal ? 'show' : '', 'popup-primary', !popup.data.showSaveButton ? 'large' : ''].join(' ')} onSubmit={handleSave}>
                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />

                        {trans('headings.uploadFile')}

                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>

                    <div className="popup-container">
                        <button
                            className="button-small"
                            onClick={handleShowAttach}
                        >
                            {trans('buttons.upload')}
                        </button>

                        <input ref={inputFileRef} type="file" onChange={handleUpload} hidden />
                    </div>

                    <br />

                    {popup.data.hasUploadedFile
                        ?
                        popup.data.transactions.length
                            ?
                            <div className="scrollable" style={{ maxHeight: '70vh' }}>
                                <div className="popup-container">
                                    <div className="col">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        {trans('tables.type')}
                                                    </th>
                                                    <th>
                                                        {trans('tables.operationDate')}
                                                    </th>
                                                    {/* <th>
                                                        {trans('tables.netSum')}
                                                    </th> */}
                                                    <th>
                                                        {trans('tables.grossSum')}
                                                    </th>
                                                    <th>
                                                        {trans('tables.orderer')}
                                                    </th>
                                                    <th>
                                                        {trans('tables.info')}
                                                    </th>
                                                    <th>
                                                        {trans('tables.category')}
                                                    </th>
                                                    <th className="center">
                                                        {trans('tables.ddsExpense')}
                                                    </th>
                                                    <th className="center">
                                                        {trans('tables.noDocNeeded')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {popup.data.transactions.map((t, i) =>
                                                    <tr key={'tr-' + i}>
                                                        <td>
                                                            <label className="btn" style={{ background: t.expense ? '#d9534f' : '#5cb85c' }}>
                                                                {t.expense ? trans('tables.expense') : trans('tables.income')}
                                                            </label>
                                                        </td>
                                                        <td>
                                                            {t.val}
                                                        </td>
                                                        {/* <td>
                                                            {t.expense ? '-' : ''}{t.net} {t.currency}
                                                        </td> */}
                                                        <td>
                                                            {t.expense ? '-' : ''}{t.amount} {t.currency}
                                                        </td>
                                                        <td>
                                                            <span dangerouslySetInnerHTML={createMarkup(
                                                                t.expense && t.match
                                                                    ?
                                                                    highlight(t.payee, t.match.name)
                                                                    :
                                                                    t.payee
                                                            )} />

                                                            {t.expense
                                                                ?
                                                                <>
                                                                    <br />
                                                                    <a
                                                                        href="javascript: void(0)"
                                                                        style={{ fontSize: '10px' }}
                                                                        onClick={() => handleEditPayee(i)}
                                                                    >
                                                                        {trans('headings.autoFind')}
                                                                    </a>
                                                                </>
                                                                :
                                                                ''
                                                            }

                                                            <input type="hidden" name={`match[${i}][id]`} value={t.match ? t.match.id : ''} />
                                                            <input type="hidden" name={`match[${i}][name]`} value={t.match ? t.match.name : t.payee} />
                                                        </td>
                                                        <td>
                                                            {t.info}
                                                        </td>
                                                        <td>
                                                            {t.expense
                                                                ?
                                                                <select
                                                                    name={`category_id[${i}]`}
                                                                    value={t.match ? t.match.category_id : ''}
                                                                    onChange={e => handleInputChange(e, i)}
                                                                >
                                                                    <option value=""></option>
                                                                    {categories.map(c =>
                                                                        <option key={'c-' + c.id} value={c.id}>
                                                                            {c.name}
                                                                        </option>
                                                                    )}

                                                                </select>
                                                                :
                                                                ''
                                                            }
                                                        </td>
                                                        <td className="center">
                                                            {t.expense
                                                                ?
                                                                <input
                                                                    type="checkbox"
                                                                    name={`vat_expense[${i}]`}
                                                                    value="1"
                                                                    checked={t.match ? t.match.vat_expense ? true : false : false}
                                                                    onChange={e => handleInputChange(e, i, true)}
                                                                />
                                                                :
                                                                ''
                                                            }
                                                        </td>
                                                        <td className="center">
                                                            {t.expense
                                                                ?
                                                                <input
                                                                    type="checkbox"
                                                                    name={`receipt[${i}]`}
                                                                    value="0"
                                                                    checked={t.match ? !t.match.receipt ? true : false : false}
                                                                    onChange={e => handleInputChange(e, i, true)}
                                                                />
                                                                :
                                                                ''
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                        :
                        <p style={{ fontSize: '12px' }}>
                            {trans('messages.noFile')}
                        </p>
                    }

                    <div className="footer align-center">
                        {popup.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="button" onClick={handleHide}>
                                    {trans('buttons.cancel')}
                                </button>
                                {popup.data.showSaveButton
                                    ?
                                    <button className="button button-primary" type="submit">
                                        {trans('buttons.save')}
                                    </button>
                                    :
                                    ''
                                }
                            </>
                        }
                    </div>
                </form>
            </div>
        </>
    )
}

export default forwardRef(Upload);