import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useLanguageContext } from '../../../../_providers/Language';

function Langs(props) {

    const languages = useLanguageContext();

    const [tabs, setTabs] = useState({
        tab: 1,
        ripple: true
    })
    const handleTabs = (e, newValue) => {
        props.callbackValue(newValue);
    };

    return (
        <Tabs
            value={props.tab}
            onChange={handleTabs}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs"
        >

            {languages.map(lang =>
                <Tab key={lang.id} value={lang.id} label={lang.name} disableRipple={tabs.ripple} />
            )}
        </Tabs>
    )
}

export default Langs;