import React, { useEffect, useRef, useState } from 'react';
import PageTabs from './partials/PageTabs';
import { useHistory, useLocation } from 'react-router-dom';
import { trans } from '../../../_providers/Translation';
import TextMessages from './text-messages/Index';
import PartStatusSms from './parts/Index';
import ReservationStatusSms from './reservations/Index';
import Help from '../../partials/Help';
import HelpInfo from './partials/HelpInfo';

function Index(props) {

    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search)

    const tabs = [
        {
            id: 'text-messages',
            name: trans('headings.sms-messages'),
            component: TextMessages,
            hasRef: true
        },
        {
            id: 'parts',
            name: trans('headings.partStatusSms'),
            component: PartStatusSms,
            hasRef: true
        },
        {
            id: 'reservations',
            name: trans('headings.reservationStatusSms'),
            component: ReservationStatusSms,
            hasRef: true
        },

    ];

    const tab = searchParams.get('tab') ? searchParams.get('tab') : tabs[0].id;

    const componentRef = useRef(null);

    const [state, setState] = useState({
        tab: '',
        index: null,
        loading: true,
        data: null
    });

    useEffect(() => {

        setState(prev => ({
            ...prev,
            loading: true,
            tab: tab,
            index: findTabIndex(tab)
        }));

    }, [location.search]);

    const handleTab = id => {

        let index = findTabIndex(id);

        setState(prev => ({
            ...prev,
            tab: id,
            index: index
        }));

        history.push('?tab=' + id);
    }

    const findTabIndex = id => {
        let index = tabs.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    const handleAdd = () => {
        let modal = componentRef.current;

        if (modal) {
            modal.add();
        }
    }

    return (
        <div className="wrapper">
            <div className="top-part">
                <div className="left">
                    <h1 className="page-heading">
                        {trans('headings.sms-messages')} <Help modalStyle={{ maxWidth: '800px' }}><HelpInfo /></Help>
                    </h1>
                    <p className="page-subheading">
                        {trans('subheadings.sms-messages')}
                    </p>
                </div>
                {tabs[state.index] && tabs[state.index].hasRef
                    ?
                    <div className="right">
                        <button
                            className="button-add"
                            onClick={handleAdd}
                        >
                            {trans('buttons.add')}
                        </button>
                    </div>
                    :
                    ''
                }
            </div>

            <PageTabs
                tabs={tabs}
                tab={state.tab}
                handleTab={handleTab}
            />

            {tabs[state.index] &&
                React.createElement(tabs[state.index].component, {
                    ref: componentRef
                })
            }

        </div>
    )
}

export default Index;