import React, { useState, useRef, useEffect } from 'react';
import loader from '../../../assets/img/loader.svg';

import '../../../assets/css/login.css';
import Api from '../../../_helpers/Api';
import { useAuthDataContext } from '../../../_providers/Auth';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import ActivationError from '../../modals/messages/ActivationError';
import { useLanguageContext } from '../../../_providers/Language';
import { trans } from '../../../_providers/Translation';

function Index() {

    const auth = useAuthDataContext();
    const languages = useLanguageContext();
    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const loginToken = searchParams.get('token');

    // if (auth.isLogged && !loginToken) {
    //     history.replace('/');
    // }

    const activationErrorModalRef = useRef(null);

    const [lang, setLang] = useState('en');

    const [loading, setLoading] = useState(false);

    const [validation, setValidation] = useState(false);

    useEffect(() => {
        if (loginToken) {
            loginViaToken();
        }
    }, []);

    const handleLogin = e => {

        if (e) {
            e.preventDefault();
        }

        setValidation(false);
        setLoading(true);

        let data = new FormData(e.target);

        let url;

        if (params.role === 'admin') {
            url = 'admins/login';
        } else {
            url = 'partners/login';
        }

        Api.post(url, data)
            .then(res => {

                if (res.status === 200 && res.data.user) {
                    onLogin(res.data);
                }

            }).catch(err => {

                if (err.response && err.response.status) {
                    if (err.response.status === 403) {
                        activationErrorModalRef.current.open();
                    }
                }

                setValidation(true);

            }).finally(() => {
                setLoading(false);
            });

    }

    const loginViaToken = () => {

        auth.logout(false);

        let url = 'partners/login-via-token';

        Api.post(url, {
            token: loginToken
        })
            .then(res => {

                if (res.status === 200 && res.data.user) {
                    onLogin(res.data);
                }

            }).catch(err => {

                if (err.response && err.response.status) {
                    if (err.response.status === 403) {
                        activationErrorModalRef.current.open();
                    }
                }

            })
    }

    const onLogin = (data) => {
        auth.login(data);

        let redirectUrl = searchParams.get('redirect') || '/';

        // ако е партньор и е управител и не е попълнил нещо от настройките
        // да го пратим на страницата с разяснения
        if (data?.user?.role?.name === 'manager') {
            auth.loadNeccesarySettings(false)
                .then(settings => {

                    let redirect = false;

                    Object.entries(settings).map(e => {
                        if (e[1].required && !e[1].filled) {
                            redirect = true;
                        }
                    });

                    if (redirect) {
                        history.replace('/company?tab=neccesary-settings');
                    } else {
                        history.replace(redirectUrl);
                    }

                });
        } else {
            history.replace(redirectUrl);
        }
    }

    const handleLangChange = e => {
        let val = e.target.value;

        setLang(val);
    }

    const onStorageUpdate = e => {
        const { key, newValue } = e;

        if (key === 'auth') {
            window.location = '/';
        }
    }

    useEffect(() => {
        window.addEventListener("storage", onStorageUpdate);
        return () => {
            window.removeEventListener("storage", onStorageUpdate);
        };
    }, []);

    return (
        <>
            <ActivationError
                ref={activationErrorModalRef}
            />

            {loginToken
                ?
                <img src={loader} alt="loading" />
                :
                <section id="login">

                    <div className="head">
                        {trans('login.head', {}, lang)}
                    </div>

                    <select
                        value={lang}
                        onChange={handleLangChange}
                    >
                        {languages.map(lang =>
                            <option key={lang.id} value={lang.code}>{lang.name}</option>
                        )}
                    </select>

                    <form onSubmit={handleLogin}>

                        <div className="tabs">
                            <div>
                                <Link to="/login/admin" className={params.role === 'admin' ? 'active' : ''}>
                                    {trans('login.admin', {}, lang)}
                                </Link>
                            </div>
                            <div>
                                <Link to="/login/partners" className={params.role === 'partners' ? 'active' : ''}>
                                    {trans('login.partners', {}, lang)}
                                </Link>
                            </div>
                        </div>

                        <div className="form">
                            <div className="row">
                                <label> {trans('login.email', {}, lang)}</label>
                                <input type="text" name="email" placeholder={trans('login.email', {}, lang)} className={validation ? 'validate' : ''} />
                            </div>
                            <div className="row">
                                <label> {trans('login.password', {}, lang)}</label>
                                <input type="password" name="password" placeholder={trans('login.password', {}, lang)} className={validation ? 'validate' : ''} />
                            </div>

                            {loading
                                ?
                                <img src={loader} alt="loading" />
                                :
                                <button>
                                    {trans('login.button', {}, lang)}
                                </button>
                            }
                        </div>
                    </form>

                </section>
            }
        </>
    )
}

export default Index;
