import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import Api from '../../../../_helpers/Api'
import closeIcon from '../../../../assets/img/icons/close.png'
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../../../partials/NoDataFound';
import { trans } from '../../../../_providers/Translation';

function EmployeeIncomes(props, ref) {

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        loading: false,
        data: [],
        filter: {
            start: '',
            end: '',
            employeeId: '',
        }
    });

    useImperativeHandle(ref, () => ({
        open: (employeeId, start, end) => {

            setState(prev => ({
                ...prev,
                filter: {
                    employeeId: employeeId,
                    start: start,
                    end: end
                }
            }));

            handleOpen()
        },
        close: () => {
            handleClose()
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.filter]);

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'accounts/incomes-by-employee'
            + '?employee_id=' + state.filter.employeeId
            + '&start=' + state.filter.start
            + '&end=' + state.filter.end

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    loading: false
                }));
            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    const handleOpen = () => {

        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)

    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 50)
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>

            <div className={`${state.modal ? 'show' : ''} popup-primary`}>

                <h2 className="head">
                    {trans('headings.incomes')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleClose} />
                </h2>

                {state.loading
                    ?
                    <Skeleton height={45} count={5} />
                    :
                    state.data.length === 0
                        ?
                        <NoDataFound />
                        :
                        <>
                            <div className="scrollable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="center" style={{ width: '60px' }}>
                                                ID
                                            </th>
                                            <th>
                                                {trans('tables.paymentDate')}
                                            </th>
                                            <th>
                                                {trans('tables.price')}
                                            </th>
                                            <th>
                                                {trans('tables.paymentMethod')}
                                            </th>
                                            <th>
                                                {trans('tables.refNo')}
                                            </th>
                                            <th>
                                                {trans('tables.address')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.data.map(d =>
                                            <tr key={d.id}>
                                                <td className="center" style={{ width: '60px' }}>
                                                    {d.id}
                                                </td>
                                                <td>
                                                    {d.paid_at_dmy}
                                                </td>
                                                <td>
                                                    {d.total_gross_price_formatted} {d.currency && d.currency.name}
                                                </td>
                                                <td>
                                                    {d.paymentmethod
                                                        ?
                                                        d.paymentmethod.translation
                                                            ? d.paymentmethod.translation.name
                                                            : d.paymentmethod.name
                                                        :
                                                        ''
                                                    }
                                                </td>
                                                <td>
                                                    {d.reservation
                                                        ?
                                                        <a
                                                            href={'/reservations/edit/' + d.reservation_id} target="_blank"
                                                            className="btn"
                                                            style={{ background: d.reservation.color, color: d.reservation.text_color }}
                                                        >
                                                            {d.reservation.reference_code}
                                                        </a>
                                                        :
                                                        ''
                                                    }
                                                </td>
                                                <td>
                                                    {d.reservation && d.reservation.full_address}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>

                }

                <div className="footer align-center">
                    <button onClick={handleClose}>
                        {trans('buttons.close')}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default React.memo(forwardRef(EmployeeIncomes), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});