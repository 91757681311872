import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import Api from '../../../../../_helpers/Api'
import { useParams } from 'react-router-dom'
import Pagination from '../../../../Pagination';
import { trans } from '../../../../../_providers/Translation';

//images
import docIcon from '../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../assets/img/icons/close.png';
import loader from '../../../../../assets/img/loader.svg';

function View(props, ref) {
    let params = useParams().id

    const [viewTodo, setViewTodo] = useState({
        overlay: false,
        modal: false,
        data: {},
        loadingButtons: false,
    })

    useImperativeHandle(ref, () => ({
        open: (data) => {

            console.log(data)

            setViewTodo(viewTodo => ({
                ...viewTodo,
                overlay: true,
                data: data,
            }))

            setTimeout(() => {
                setViewTodo(viewTodo => ({
                    ...viewTodo,
                    modal: true,
                }))
            }, 50);

        },
        close: () => handleCancel()
    }))

    const handleCancel = e => {
        if (e) e.preventDefault()

        setViewTodo(viewTodo => ({
            ...viewTodo,
            modal: false,
        }))

        setTimeout(() => {
            setViewTodo(viewTodo => ({
                ...viewTodo,
                overlay: false,
                data: {},
            }))
        }, 50)
    }

    const handleSend = e => {
        e.preventDefault();

        props.handleUpdate(e)
        handleCancel()
    }

    return (
        <form className={`${viewTodo.overlay ? 'visible' : ''} overlay`} onSubmit={handleSend}>
            <div className={`${viewTodo.modal ? 'show' : ''} popup-primary large`}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.editComment')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleCancel} />
                </h2>
                <div className="popup-container-read-only">
                    <div className="col">
                        <label>
                            {trans('labels.comments')}
                        </label>
                        <textarea
                            name="comment"
                            defaultValue={viewTodo.data.comment}
                        ></textarea>
                        <input type="hidden" name="id" value={viewTodo.data.id} />
                    </div>
                </div>
                <div className="footer align-center">
                    {viewTodo.loadingButtons ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.edit')}
                            </button>
                            <button onClick={handleCancel}>
                                {trans('buttons.cancel')}
                            </button>
                        </>
                    }
                </div>
            </div>
        </form>
    )
}

export default forwardRef(View)