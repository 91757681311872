import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Api from '../../../../_helpers/Api';
import Pagination from '../../../Pagination';
import loader from '../../../../assets/img/loader.svg';
import NoDataFound from '../../../partials/NoDataFound';
import Options from '../../reservation/partials/Options';
import Filter from './Filter';
import checkIcon from '../../../../assets/img/icons/check.png';
import { trans } from '../../../../_providers/Translation'

let timeout;

function Index(props) {

    const params = useParams();
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const status = searchParams.get('status') || '';
    const date = searchParams.get('date') || '';
    const deadline = searchParams.get('deadline') || '';
    const search = searchParams.get('search') || '';

    const [state, setState] = useState({
        loading: true,
        page: 1,
        data: [],
        total: 0,
        pages: 0,
        filter: {

        },
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                status: status,
                date: date,
                deadline: deadline,
                search: search
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    // useEffect(() => {
    //     console.log(state.filter);
    // }, [state.filter]);

    useEffect(() => {
        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'contractors/reservations?id=' + params.id + '&page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {

                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                }))
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            })
    }

    const refreshData = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    //Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
            page: 1,
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: Math.random().toString() }));
    }

    const handleShow = id => {
        history.push('/reservations/edit/' + id);
    }

    return (
        <>

            <Filter
                filter={state.filter}
                handleSearch={handleSearch}
            />

            {state.loading
                ?
                <img src={loader} alt="loading" />
                :
                state.data.length === 0
                    ?
                    <NoDataFound />
                    :
                    <div className="collapsable-table">
                        <table className="collapsable active">
                            <thead>
                                <tr>
                                    <th>
                                        ID
                                    </th>
                                    <th>
                                        {trans('tables.number')}
                                    </th>
                                    <th>
                                        {trans('tables.address')}
                                    </th>
                                    <th>
                                        {trans('tables.visitDate')}
                                    </th>
                                    <th>
                                        {trans('tables.visitHour')}
                                    </th>
                                    <th>
                                        {trans('tables.initialStatus')}
                                    </th>
                                    <th>
                                        {trans('tables.finalStatus')}
                                    </th>
                                    <th className="right">
                                        {trans('tables.finalPrice')}
                                    </th>
                                    <th className="right">
                                        {trans('tables.paidPrice')}
                                    </th>
                                    <th className="center">
                                        {trans('tables.sentReport')}
                                    </th>
                                    <th className="options">
                                        {trans('tables.options')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.data.map(r =>
                                    <tr
                                        key={'r-' + r.id}
                                        style={{ color: r.color }}
                                    >
                                        <td onClick={() => handleShow(r.id)}>
                                            {r.id}
                                        </td>
                                        <td onClick={() => handleShow(r.id)}>
                                            <Link
                                                to={`/reservations/edit/${r.id}`}
                                                className="btn"
                                                style={{ background: r.color, color: r.text_color }}
                                            >
                                                {r.reference_code}
                                            </Link>
                                        </td>
                                        <td onClick={() => handleShow(r.id)}>
                                            {r.full_address}
                                        </td>
                                        <td onClick={() => handleShow(r.id)}>
                                            {r.visit_date
                                                ? `${r.visit_date_dmy}`
                                                : '-'
                                            }
                                        </td>
                                        <td onClick={() => handleShow(r.id)}>
                                            {r.visitperiod
                                                ? `${r.visitperiod.period}`
                                                : '-'
                                            }
                                        </td>
                                        <td onClick={() => handleShow(r.id)}>
                                            {r.initialstatus && r.initialstatus.translation.name}
                                        </td>
                                        <td onClick={() => handleShow(r.id)}>
                                            {r.finalstatus && r.finalstatus.translation.name}
                                        </td>
                                        <td className="right" onClick={() => handleShow(r.id)}>
                                            {r.total_price_formatted}
                                            {r.currency ? r.currency.name : ''}
                                        </td>
                                        <td className="right" onClick={() => handleShow(r.id)}>
                                            {r.paid_price_formatted}
                                            {r.currency ? r.currency.name : ''}
                                        </td>
                                        <td className="center">
                                            {r.send_reports
                                                ?
                                                <img src={checkIcon} alt="" />
                                                :
                                                ''
                                            }
                                        </td>
                                        <td>
                                            <Options
                                                id={r.id}
                                                data={r}
                                                masterId={r.id}
                                                handleRefresh={refreshData}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
            }

            {!state.loading
                ?
                <Pagination
                    pages={state.pages}
                    page={state.page}
                    handlePage={handlePage}
                />
                :
                ''
            }
        </>
    )
}

export default Index;