import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { trans } from '../../../../_providers/Translation'

function SecondaryTabs(props) {

    const [tabs, setTabs] = useState({
        ripple: true
    })

    const handleTabs = (e, newValue) => {
        props.handleTab(newValue);
    };

    return (
        <Tabs
            value={props.tab}
            onChange={handleTabs}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
        >
            <Tab value="all" label={trans('tabs.all')} disableRipple={tabs.ripple} />
            <Tab value="awaiting" label={trans('tabs.waiting')} disableRipple={tabs.ripple} />
            <Tab value="completed" label={trans('tabs.completed')} disableRipple={tabs.ripple} />
            <Tab value="fail" label={trans('tabs.notCompleted')} disableRipple={tabs.ripple} />
            <Tab value="calendar" label={trans('tabs.calendar')} disableRipple={tabs.ripple} />
        </Tabs>
    )
}

export default SecondaryTabs;