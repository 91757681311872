import React, { useRef, useState, useEffect } from 'react';
import { useAuthDataContext } from '../../../_providers/Auth';
import Api from '../../../_helpers/Api';
import Skeleton from 'react-loading-skeleton';
import editIcon from '../../../assets/img/icons/edit-yellow.png';
import PaymentsModal from './modals/Payments';
import NotificationsModal from './modals/Notifications';
import PreferencesModal from './modals/Preferences';
import '../../../assets/css/settings.css';
import CompanyModal from './modals/Company';
import OthersModal from './modals/Others';
import { trans } from '../../../_providers/Translation';

function Index() {

    const paymentsModalRef = useRef(null);
    const preferencesModalRef = useRef(null);
    const notificationsModalRef = useRef(null);
    const companyModalRef = useRef(null);
    const othersModalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: [],
        update: false,
    });

    useEffect(() => {
        loadSettings();
    }, [state.update]);

    const loadSettings = () => {
        Api.get('settings/all')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const showModal = modal => {
        modal.show(state.data);

        modal.onSave(settings => {
            handleUpdate(settings);
        });
    }

    const showCompanyModal = () => {
        showModal(companyModalRef.current);
    }

    const showPreferencesModal = () => {
        showModal(preferencesModalRef.current);
    }

    const showPaymentsModal = () => {
        showModal(paymentsModalRef.current);
    }

    const showNotificationsModal = () => {
        showModal(notificationsModalRef.current);
    }

    const showOthersModal = () => {
        showModal(othersModalRef.current);
    }

    return (
        <>
            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.settings')}
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.settings')}
                        </p>
                    </div>

                </div>


                {state.loading
                    ?
                    <>
                        <Skeleton height={35} count={8} />
                        <br />
                        <br />
                        <Skeleton height={35} count={2} />
                        <br />
                        <br />
                        <Skeleton height={35} count={8} />
                        <br />
                        <br />
                        <Skeleton height={35} count={1} />
                        <br />
                        <br />
                        <Skeleton height={35} count={3} />

                    </>
                    :
                    <>
                        <CompanyModal
                            ref={companyModalRef}
                        />

                        <PreferencesModal
                            ref={preferencesModalRef}
                        />

                        <PaymentsModal
                            ref={paymentsModalRef}
                        />

                        <NotificationsModal
                            ref={notificationsModalRef}
                        />

                        <OthersModal
                            ref={othersModalRef}
                        />

                        <div id="settings">

                            <div style={{ width: '100%' }}>
                                <div className="head">
                                    <h4>{trans('labels.companyData')}</h4>
                                    <a onClick={showCompanyModal}>
                                        <img src={editIcon} alt="" />
                                    </a>
                                </div>

                                <div className="table-scroller">
                                    <table className="settings">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.name')}</b>
                                                </td>
                                                <td>
                                                    {state.data.company_name ? state.data.company_name : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.eik')}</b>
                                                </td>
                                                <td>
                                                    {state.data.company_eik ? state.data.company_eik : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.address')}</b>
                                                </td>
                                                <td>
                                                    {state.data.company_address ? state.data.company_address : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.email')}</b>
                                                </td>
                                                <td>
                                                    {state.data.company_email ? state.data.company_email : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.phone')}</b>
                                                </td>
                                                <td>
                                                    {state.data.company_phone ? state.data.company_phone : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.website')}</b>
                                                </td>
                                                <td>
                                                    {state.data.company_website ? state.data.company_website : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.vatNo')}</b>
                                                </td>
                                                <td>
                                                    {state.data.vat_number ? state.data.vat_number : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.mol')}</b>
                                                </td>
                                                <td>
                                                    {state.data.mol ? state.data.mol : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.invoiceDeadline')}</b>
                                                </td>
                                                <td>
                                                    {state.data.invoice_deadline ? state.data.invoice_deadline : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.invoiceTerms')}</b>
                                                </td>
                                                <td>
                                                    {state.data.invoice_terms ? state.data.invoice_terms : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <br />

                                <div className="head">
                                    <h4>{trans('labels.terms')}</h4>
                                    <a onClick={showPreferencesModal}>
                                        <img src={editIcon} alt="" />
                                    </a>
                                </div>

                                <div className="table-scroller">
                                    <table className="settings">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.language')}</b>
                                                </td>
                                                <td>
                                                    {state.data.language ? state.data.language.name : 'Не е посочено'}
                                                </td>
                                                <td>
                                                    {trans('labels.mainLanguage')}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.currency')}</b>
                                                </td>
                                                <td>
                                                    {state.data.currency ? state.data.currency.name : 'Не е посочено'}
                                                </td>
                                                <td>
                                                    {trans('labels.mainCurrency')}
                                                </td>
                                            </tr>

                                            {/* <tr>
                                            <td>
                                                <b>Часова зона</b>
                                            </td>
                                            <td>
                                                {state.data.tz ? state.data.tz : 'Не е посочено'}
                                            </td>
                                            <td>

                                            </td>
                                        </tr> */}
                                        </tbody>
                                    </table>
                                </div>

                                <br />

                                <div className="head">
                                    <h4>{trans('labels.payments')}</h4>
                                    <a onClick={showPaymentsModal}>
                                        <img src={editIcon} alt="" />
                                    </a>
                                </div>

                                <div className="table-scroller">
                                    <table className="settings">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.tax')}</b>
                                                </td>
                                                <td>
                                                    {state.data.vat ? state.data.vat : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.incomeTax')}</b>
                                                </td>
                                                <td>
                                                    {state.data.profit_tax ? state.data.profit_tax : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans('labels.bank')}</b>
                                                </td>
                                                <td>
                                                    {state.data.bank_name ? state.data.bank_name : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>IBAN</b>
                                                </td>
                                                <td>
                                                    {state.data.bank_iban ? state.data.bank_iban : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>SWIFT</b>
                                                </td>
                                                <td>
                                                    {state.data.bank_swift ? state.data.bank_swift : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>ePay Client ID</b>
                                                </td>
                                                <td>
                                                    {state.data.epay_client_id ? state.data.epay_client_id : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>ePay Secret</b>
                                                </td>
                                                <td>
                                                    {state.data.epay_client_secret ? state.data.epay_client_secret : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>PayPal Client ID</b>
                                                </td>
                                                <td>
                                                    {state.data.paypal_client_id ? state.data.paypal_client_id : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>PayPal Secret</b>
                                                </td>
                                                <td>
                                                    {state.data.paypal_client_secret ? state.data.paypal_client_secret : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <br />

                                <div className="head">
                                    <h4>{trans('headings.notifications')}</h4>
                                    <a onClick={showNotificationsModal}>
                                        <img src={editIcon} alt="" />
                                    </a>
                                </div>

                                <div className="table-scroller">
                                    <table className="settings">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <b>{trans("labels.periodNotifications")}</b>
                                                </td>
                                                <td>
                                                    {state.data.notifications_storage_period ? state.data.notifications_storage_period : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                                <br />

                                <div className="head">
                                    <h4>{trans('headings.otherSettings')}</h4>
                                    <a onClick={showOthersModal}>
                                        <img src={editIcon} alt="" />
                                    </a>
                                </div>

                                <div className="table-scroller">
                                    <table className="settings">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <b>{trans("labels.proformaStartNumber")}</b>
                                                </td>
                                                <td>
                                                    {state.data.proforma_start_number ? state.data.proforma_start_number : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans("labels.invoiceStartNumber")}</b>
                                                </td>
                                                <td>
                                                    {state.data.invoice_start_number ? state.data.invoice_start_number : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>{trans("labels.creditNoteStartNumber")}</b>
                                                </td>
                                                <td>
                                                    {state.data.credit_note_start_number ? state.data.credit_note_start_number : 'Не е посочено'}
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </>
                }

            </div>

        </>

    )

}

export default Index