import React, { useEffect } from 'react';
import { trans } from '../../../_providers/Translation'

function OrderedPart(props) {

    return (
        <table>
            <thead>
                <tr>
                    <th>{trans('tables.activity')}</th>
                    <th>{trans('tables.date')}</th>
                    <th>{trans('tables.employee')}</th>
                    <th>{trans('tables.category')}</th>
                    <th>{trans('tables.name')}</th>
                    <th>{trans('tables.partNumber')}</th>
                    <th className="center">{trans('tables.quantity')}</th>
                    <th>{trans('tables.supplier')}</th>
                    <th>{trans('tables.poNumber')}</th>
                    <th>{trans('tables.status')}</th>
                    <th>{trans('tables.grossPrice')}</th>
                    <th>{trans('tables.comment')}</th>
                    <th>{trans('tables.givenAt')}</th>
                    <th>{trans('tables.acceptedAt')}</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((h, i) => (
                    <tr key={i} className={[h.actual ? 'actual' : h.selected ? 'selected' : '']}>
                        <td>
                            {h.event}
                        </td>
                        <td>{h.date}</td>
                        <td>{h.causer && `${h.causer.name} ${h.causer.lastname}`}</td>
                        <td>{h.subject.part && h.subject.part.category ? h.subject.part.category.name : '-'}</td>
                        <td>{h.subject.name ? h.subject.name : '-'}</td>
                        <td>{h.subject.number ? h.subject.number : '-'}</td>
                        <td className="center">{h.subject.quantity}</td>
                        <td>{h.subject.supplier ? h.subject.supplier.name : '-'}</td>
                        <td>{h.subject.po_number ? h.subject.po_number : '-'}</td>
                        <td>{h.subject.status ? h.subject.status.name : '-'}</td>
                        <td>{h.subject.gross_price_format} {h.subject.currency && h.subject.currency.name}</td>
                        <td>{h.subject.comment || '-'}</td>
                        <td>{h.subject.given ? h.subject.given_date_dmy : '-'}</td>
                        <td>{h.subject.accepted ? h.subject.accept_date_dmy : '-'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default OrderedPart;