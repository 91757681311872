import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import { trans } from '../../../../_providers/Translation';

function Company(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: null,
        loading: false,
        onSave: null
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: data => {
            setPopup(prev => ({
                ...prev,
                loading: false,
                data: data
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'settings/edit';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave();
                }

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name.split('[')[1].replace(']', '');
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary small`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('labels.companyData')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="scrollable" style={{ maxHeight: '70vh' }}>
                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.name')}
                            </label>

                            <input
                                type="text"
                                name="data[company_name]"
                                value={popup.data ? popup.data.company_name : ''}
                                className={validations && validations.company_name ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.eik')}
                            </label>

                            <input
                                type="number"
                                name="data[company_eik]"
                                value={popup.data ? popup.data.company_eik : ''}
                                className={validations && validations.company_eik ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.address')}
                            </label>

                            <input
                                type="text"
                                name="data[company_address]"
                                value={popup.data ? popup.data.company_address : ''}
                                className={validations && validations.company_address ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.email')}
                            </label>

                            <input
                                type="text"
                                name="data[company_email]"
                                value={popup.data ? popup.data.company_email : ''}
                                className={validations && validations.company_email ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.phone')}
                            </label>

                            <input
                                type="text"
                                name="data[company_phone]"
                                value={popup.data ? popup.data.company_phone : ''}
                                className={validations && validations.company_phone ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.website')}
                            </label>

                            <input
                                type="text"
                                name="data[company_website]"
                                value={popup.data ? popup.data.company_website : ''}
                                className={validations && validations.company_website ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.vatNo')}
                            </label>

                            <input
                                type="text"
                                name="data[vat_number]"
                                value={popup.data ? popup.data.vat_number : ''}
                                className={validations && validations.vat_number ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.mol')}
                            </label>

                            <input
                                type="text"
                                name="data[mol]"
                                value={popup.data ? popup.data.mol : ''}
                                className={validations && validations.mol ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.invoiceDeadline')}
                            </label>

                            <input
                                type="text"
                                name="data[invoice_deadline]"
                                value={popup.data ? popup.data.invoice_deadline : ''}
                                className={validations && validations.invoice_deadline ? 'validate' : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.invoiceTerms')}
                            </label>

                            <textarea
                                name="data[invoice_terms]"
                                value={popup.data && popup.data.invoice_terms ? popup.data.invoice_terms : ''}
                                className={validations && validations.invoice_terms ? 'validate' : ''}
                                onChange={handleInputChange}
                            >
                            </textarea>
                        </div>
                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Company);