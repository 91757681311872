import React, { useState, useEffect, useRef } from 'react';
import loader from '../../../../assets/img/loader.svg';
import Api from '../../../../_helpers/Api';
import AddOrEditGroup from './AddOrEditGroup';
import { useAuthDataContext } from '../../../../_providers/Auth';
import NoDataFound from '../../../partials/NoDataFound';
import Options from './GroupsOptions';
import { trans } from '../../../../_providers/Translation';

let timeout;

function Groups(props) {

    const auth = useAuthDataContext();
    const partnerId = props.id;
    const modalAddOrEditRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: [],
        update: false,
    });

    useEffect(() => {
        loadData();
    }, [state.update]);

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'partners/groups/all?partner_id=' + partnerId;

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data,
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });

    }

    const handleAdd = () => {
        modalAddOrEditRef.current.add();
    }

    const handleCreate = van => {
        setState(prev => ({
            ...prev,
            update: Math.random().toString()
        }));

    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id);
    }

    const handleUpdate = group => {
        setState(prev => ({
            ...prev,
            update: Math.random().toString()
        }));
    }

    // delete
    const handleDelete = id => {
        Api.post('partners/groups/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                setState(prev => ({
                    ...prev,
                    update: Math.random().toString()
                }));
            }
        });
    }

    const handleSearch = (key, val) => {

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                [key]: val,
                page: 1,
                update: Math.random().toString()
            }));
        }, 300);

    }


    return (

        <>
            <AddOrEditGroup
                ref={modalAddOrEditRef}
                partnerId={partnerId}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <div className="top-part">
                <div className="left">

                </div>
                <div className="right">
                    {(auth.isAdmin() || auth.isManager()) &&
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.add')}
                        </button>
                    }
                </div>
            </div>

            {state.loading
                ?
                <img src={loader} alt="loading" />
                :
                state.data.length === 0
                    ?
                    <NoDataFound />
                    :
                    <>

                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{trans('labels.name')}</th>
                                        <th>{trans('labels.technicians')}</th>
                                        <th className="options">{trans('labels.options')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.map(e =>
                                        <tr key={'e' + e.id}>
                                            <td>{e.name}</td>
                                            <td>{e.selectedemployees_count}</td>
                                            <td className="options">
                                                <Options
                                                    id={e.id}
                                                    handleEdit={handleEdit}
                                                    handleDelete={handleDelete}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </>
            }
        </>

    )
}

export default Groups;