import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../../assets/css/calendar.css';
import moment from 'moment';
import { trans } from '../../../../_providers/Translation';
import Api from '../../../../_helpers/Api';
import { useHistory, useLocation } from 'react-router-dom';
import Year from './Year';
import PreviewSubscription from '../list/PreviewSubscription';
import PreviewWarranty from '../list/PreviewWarranty';

const localizer = momentLocalizer(moment);

localizer.formats.yearHeaderFormat = 'YYYY'

let timeout;

function Event({ event }) {
    return (
        <span>
            {event.title}
        </span>
    )
}

function EventWrapper(props) {
    return (
        <div className={props.event.event_type === 'activation' ? 'green' : 'red'}>{props.children}</div>
    )
}

function Index(props, ref) {

    const viewSubscriptionModalRef = useRef(null);
    const viewWarrantyModalRef = useRef(null);

    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const start = searchParams.get('start_date') ? new Date(searchParams.get('start_date')) : new Date();
    // const start = new Date();

    const [state, setState] = useState({
        loading: true,
        data: [],
        filter: {

        },
        setFilter: false,
        update: false,
    });

    const [subscriptions, setSubscriptions] = useState([]);
    const [warranties, setWarranties] = useState([]);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                start_date: searchParams.get('start_date') || moment(start).startOf('month').format('YYYY-MM-DD'),
                end_date: searchParams.get('end_date') || moment(start).endOf('month').format('YYYY-MM-DD'),
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            // searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    useEffect(() => {
        let events = [];

        subscriptions.map(event => {
            if (event.activated_at) {
                let title = `${event?.contractor?.name} (Активиране на абонамент ${event.ref})`;

                events.push({
                    title: title,
                    start: moment(event.activated_at).startOf('day'),
                    end: moment(event.activated_at).endOf('day'),
                    event_type: 'activation',
                    allDay: false,
                    object: 'subscription',
                    ...event
                });
            }

            if (event.expires_at) {
                let title = `${event?.contractor?.name} (Изтичане на абонамент ${event.ref})`;

                events.push({
                    title: title,
                    start: moment(event.expires_at).startOf('day'),
                    end: moment(event.expires_at).endOf('day'),
                    event_type: 'expiration',
                    allDay: false,
                    object: 'subscription',
                    ...event
                });
            }
        });

        warranties.map(event => {
            if (event.activated_at) {
                let title = `${event?.contractor?.name} (Активиране на гаранция ${event.ref})`;

                events.push({
                    title: title,
                    start: moment(event.activated_at).startOf('day'),
                    end: moment(event.activated_at).endOf('day'),
                    event_type: 'activation',
                    allDay: false,
                    object: 'warranty',
                    ...event
                });
            }

            if (event.expires_at) {
                let title = `${event?.contractor?.name} (Изтичане на гаранция ${event.ref})`;

                events.push({
                    title: title,
                    start: moment(event.expires_at).startOf('day'),
                    end: moment(event.expires_at).endOf('day'),
                    event_type: 'expiration',
                    allDay: false,
                    object: 'warranty',
                    ...event
                });
            }
        });

        setState(prev => ({
            ...prev,
            data: events,
            // total: res.data.total,
            // pages: res.data.pages
        }));
    }, [subscriptions, warranties]);

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let p1 = loadSubscriptions();
        let p2 = loadWarranties();

        Promise.all([p1, p2]).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false
            }));
        });
    }

    const loadSubscriptions = async () => {
        let url = 'contractors/subscriptions/all?';

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        return Api.get(url)
            .then(res => {
                setSubscriptions(res.data.data);
            });
    }

    const loadWarranties = async () => {
        let url = 'contractors/warranties/all?';

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        return Api.get(url)
            .then(res => {
                setWarranties(res.data.data);
            });
    }

    const handleRangeChange = range => {

        // console.log(range)

        let start;
        let end;

        if (Array.isArray(range)) {
            start = moment(range[0]).format('YYYY-MM-DD');
            end = moment(range[range.length - 1]).format('YYYY-MM-DD');
        } else if (typeof range === 'object') {
            start = moment(range.start).format('YYYY-MM-DD');
            end = moment(range.end).format('YYYY-MM-DD');
        }

        if (start && end) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    start_date: start,
                    end_date: end
                },
                page: 1,
                setFilter: new Date().getTime()
            }));
        }
    }

    const handleSelectEvent = data => {
        if (data.object === 'subscription') {
            let modal = viewSubscriptionModalRef.current;

            if (!modal) {
                return;
            }

            modal.open(data);

            modal.onUpdate(() => {
                loadData();
            });
        }

        else if (data.object === 'warranty') {
            let modal = viewWarrantyModalRef.current;

            if (!modal) {
                return;
            }

            modal.open(data);

            modal.onUpdate(() => {
                loadData();
            });
        }
    }

    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            }
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const customDayPropGetter = date => {
        if (date.getDate() === 7 || date.getDate() === 15)
            return {
                className: 'special-day',
                // style: {
                //     border: 'solid 3px ' + (date.getDate() === 7 ? '#faa' : '#afa'),
                // },
            }
        else return {}
    }

    // const customSlotPropGetter = date => {
    //     if (date.getDate() === 7 || date.getDate() === 15)
    //         return {
    //             className: 'special-day',
    //         }
    //     else return {}
    // }

    return (
        <>
            <PreviewSubscription
                ref={viewSubscriptionModalRef}
            />

            <PreviewWarranty
                ref={viewWarrantyModalRef}
            />

            <div style={{ height: '800px' }}>
                <Calendar
                    localizer={localizer}
                    defaultDate={start}
                    events={state.data}
                    // events={[]}
                    toolbar={true}
                    views={{
                        month: true,
                        agenda: true,
                        // year: Year
                    }}
                    messages={{
                        next: trans('calendar.next'),
                        previous: trans('calendar.previous'),
                        today: trans('calendar.today'),
                        month: trans('calendar.month'),
                        week: trans('calendar.week'),
                        day: trans('calendar.day'),
                        year: trans('calendar.year'),
                        agenda: trans('calendar.agenda'),
                        date: trans('calendar.date'),
                        time: trans('calendar.time'),
                        event: trans('calendar.event'),
                        noEventsInRange: trans('calendar.noEventsInRange')
                    }}
                    components={{
                        event: Event,
                        eventWrapper: EventWrapper,
                    }}
                    popup
                    startAccessor="start"
                    endAccessor="end"
                    onRangeChange={handleRangeChange}
                    onSelectEvent={handleSelectEvent}
                    dayPropGetter={customDayPropGetter}
                // slotPropGetter={customSlotPropGetter}
                // onSelectEvent={handleSelectEvent}
                />
            </div>
        </>
    )
}

export default forwardRef(Index);