import React from 'react';
import Options from './Options';
import { trans } from '../../../../_providers/Translation'

function Problems(props) {

    return (
        <>
            <div className="table-scroller">
                <table>
                    <thead>
                        <tr>
                            <th>{trans('tables.category')}</th>
                            <th>{trans('tables.make')}</th>
                            <th>{trans('tables.model')}</th>
                            <th>{trans('tables.problem')}</th>
                            <th>{trans('tables.solution')}</th>
                            <th className="options">{trans('tables.options')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map(problem =>
                            <tr key={'problem-' + problem.id}>
                                <td>{problem.category && problem.category.name}</td>
                                <td>{problem.brand.name}</td>
                                <td>
                                    {problem.models}
                                </td>
                                <td>
                                    {problem.problem}
                                </td>
                                <td>
                                    {problem.solution}
                                </td>
                                <td className="options">
                                    <Options
                                        id={problem.id}
                                        handleEdit={props.handleEdit}
                                        handleDelete={props.handleDelete}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        </>
    )
}

export default Problems;