import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { trans } from '../../../_providers/Translation';

//images
import questionIcon from '../../../assets/img/modals/messages/question.png'
import loader from '../../../assets/img/loader.svg'

function Question(props, ref) {
    const [question, setQuestion] = useState({
        overlay: false,
        modal: false,
        params: null,
        onSuccess: null,
        onClose: null,
    })

    const handleAccept = () => {

        handleClose();

        if (props.callback && typeof props.callback === 'function') {
            return props.callback(...question.params);
        } else if (question.onSuccess && typeof question.onSuccess === 'function') {
            return question.onSuccess();
        }

    }

    const handleClose = () => {

        setQuestion(question => ({
            ...question,
            modal: false,
        }));

        setTimeout(() => {
            setQuestion(question => ({
                ...question,
                overlay: false,
            }));
        }, 100);

        if (typeof question.onClose === 'function') {
            question.onClose();
        }
    }

    useImperativeHandle(ref, () => ({
        open: (...params) => {
            setQuestion(question => ({
                ...question,
                overlay: true,
                params: params,
            }));

            setTimeout(() => {
                setQuestion(question => ({
                    ...question,
                    modal: true,
                }))
            }, 50);
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setQuestion(question => ({
                ...question,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setQuestion(question => ({
                ...question,
                onClose: fn
            }))
        }
    }))

    return (
        <div className={`${question.overlay ? 'visible' : ''} overlay`}>
            <div className={`box question ${question.modal ? 'show' : ''}`}>
                <img src={questionIcon} alt="question" />
                <h3>
                    {props.mainMessage}
                </h3>
                <p>
                    {props.secondaryMessage}
                </p>
                <div className="buttons">
                    {question.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button onClick={handleClose}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button-primary" onClick={handleAccept}>
                                {trans('buttons.yes')}
                            </button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Question);