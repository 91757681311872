import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import SecondaryTab from './SecondaryTab';
import Api from '../../../../_helpers/Api';
import MultiSelect from "react-multi-select-component";


//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import { useLanguageContext } from '../../../../_providers/Language';
import Dropdown from '../../../partials/Dropdown';
import { trans } from '../../../../_providers/Translation'

function AddOrEditProblem(props, ref) {

    const languages = useLanguageContext();

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: null,
        loading: false,
        key: 1,
        tab: 1,
    });

    const [models, setModels] = useState({
        data: [],
        options: [],
        selected: []
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: []
            }));
            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                loading: false
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            Api.get(`appliances/problems/find?id=${popup.editId}`)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data,
                        tab: 1
                    }));

                    // handleBrand(res.data.brand.id);

                    // let selected = [];

                    // res.data.models.map(m => {
                    //     selected.push({
                    //         label: m.name,
                    //         value: m.id
                    //     });
                    // });

                    // setModels(prev => ({ ...prev, selected: selected }));

                });
        } else {
            // setModels(prev => ({ ...prev, data: [], options: [], selected: [] }));
        }

    }, [popup.edit]);

    useEffect(() => {

        if (languages.length) {
            setPopup(prev => ({ ...prev, tab: languages[0].id }));
        }

    }, [languages]);


    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true,
            key: Math.random().toString()
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();


        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'appliances/problems/edit';
        } else {
            url = 'appliances/problems/add';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    props.handleUpdate(res.data)
                } else {
                    props.handleCreate(res.data);
                }

                handleHide(e);

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const tabsValue = newValue => {
        setPopup(popup => ({
            ...popup,
            tab: newValue,
        }))
    }

    // const handleBrand = id => {
    //     if (id > 0) {

    //         Api.get(`appliances/models/all?brand_id=${id}`)
    //             .then(res => {

    //                 let options = [];

    //                 res.data.map(m => {
    //                     options.push({
    //                         label: m.name,
    //                         value: m.id
    //                     });
    //                 });

    //                 setModels(prev => ({ ...prev, data: res.data, options: options }));
    //             }).catch(err => {
    //                 setModels(prev => ({ ...prev, data: [], options: [] }));
    //             });
    //     } else {
    //         setModels(prev => ({ ...prev, data: [], options: [] }));
    //     }

    // }

    // const handleModel = model => {
    //     setModels(prev => ({ ...prev, selected: model }));
    // }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }

        }))
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary large`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {popup.edit ? trans('headings.editProblem') : trans('headings.addProblem')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container x3">

                    <div className="col">
                        <label>
                            {trans('labels.category')}
                        </label>
                        <Dropdown
                            key={popup.key}
                            inputName="category"
                            inputIdName="category_id"
                            inputValue={popup.data && popup.data.category ? popup.data.category.name : ''}
                            inputIdValue={popup.data && popup.data.category_id ? Number(popup.data.category_id) : ''}
                            inputClassName={validations && validations.category_id ? 'validate' : ''}
                            url="appliances/categories/all"
                            renderText={data => {
                                return data.name
                            }}
                        // onChange={data => handleBrand(data.id)}
                        />
                    </div>

                    <div className="col">
                        <label>
                            {trans('labels.make')}
                        </label>
                        <Dropdown
                            key={popup.key}
                            inputName="brand"
                            inputIdName="brand_id"
                            inputValue={popup.data && popup.data.brand ? popup.data.brand.name : ''}
                            inputIdValue={popup.data && popup.data.brand_id ? Number(popup.data.brand_id) : ''}
                            inputClassName={validations && validations.brand_id ? 'validate' : ''}
                            url="appliances/brands/all"
                            renderText={data => {
                                return data.name
                            }}
                        // onChange={data => handleBrand(data.id)}
                        />
                    </div>

                    <div className="col">
                        <label>
                            {trans('labels.model')}
                        </label>
                        <input
                            type="text"
                            name="models"
                            className={validations && validations.models ? 'validate' : ''}
                            value={popup.data && popup.data.models ? popup.data.models : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">

                    <div className="col">
                        <label htmlFor="">
                            {trans('labels.problem')}
                        </label>
                        <textarea
                            type="text"
                            name="problem"
                            className={validations && validations.problem ? 'validate' : ''}
                            value={popup.data && popup.data.problem ?
                                popup.data.problem
                                :
                                ''
                            }
                            onChange={handleInputChange}
                        >
                        </textarea>

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label htmlFor="">
                            {trans('labels.solution')}
                        </label>
                        <textarea
                            type="text"
                            name="solution"
                            className={validations && validations.solution ? 'validate' : ''}
                            value={popup.data && popup.data.solution ?
                                popup.data.solution
                                :
                                ''
                            }
                            onChange={handleInputChange}
                        >
                        </textarea>

                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div >
    )
}

export default forwardRef(AddOrEditProblem);