import React, { useEffect, useState, useRef, useMemo } from 'react';
import Table from './partials/Table';
import Filter from './partials/Filter';
import { fromInfo, trans } from '../../../_providers/Translation'

import Api from '../../../_helpers/Api';
import { useHistory, useLocation, Link } from 'react-router-dom';
import Pagination from '../../Pagination';

//modals
import AddOrEditManual from './partials/AddOrEditManual';
import Question from '../../modals/messages/Question';
import FilterTabs from './partials/FilterTabs';
import AddOrEditMessage from './partials/AddOrEditMessage';
import AddOrEditProblem from './partials/AddOrEditProblem';
import Help from '../../partials/Help';

let timeout;

function Manuals() {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const tabs = ['manuals', 'errors', 'problems'];
    const tab = searchParams.get('tab');

    const modalAddOrEditRef = useRef(null);
    const modalQuestionRef = useRef(null);

    const [manuals, setManuals] = useState({
        loading: true,
        tab: tabs.indexOf(tab) > -1 ? tab : tabs[0],
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
    });

    useEffect(() => {

        setManuals(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            tab: searchParams.get('tab') || tabs[0],
            filter: {
                ...prev.filter,
                category_id: searchParams.get('category_id') || '',
                brand_id: searchParams.get('brand_id') || '',
                model: searchParams.get('model') || '',
                search: searchParams.get('search') || '',
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (manuals.setFilter) {
            searchParams.set('page', manuals.page);
            searchParams.set('tab', manuals.tab);

            Object.entries(manuals.filter).map(filter => {
                searchParams.set(filter[0], filter[1]);
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [manuals.setFilter]);

    useEffect(() => {
        if (manuals.update) {
            loadManuals();
        }
    }, [manuals.update]);


    const loadManuals = () => {

        setManuals(prev => ({
            ...prev,
            loading: true
        }));

        let url;

        if (manuals.tab == 'manuals') {
            url = `appliances/manuals/all`;
        } else if (manuals.tab == 'errors') {
            url = `appliances/errors/all`;
        } else if (manuals.tab == 'problems') {
            url = `appliances/problems/all`;
        }

        url += '?page=' + manuals.page;

        Object.entries(manuals.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setManuals(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }));
            }).catch(err => {
                setManuals(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleCategory = id => {
        setManuals(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                category_id: id,
                brand_id: '',
                model_id: ''
            },
            setFilter: Math.random().toString()
        }));
    }

    const handleBrand = id => {
        setManuals(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                brand_id: id,
                model_id: ''
            },
            setFilter: Math.random().toString()
        }));
    }

    const handleModel = id => {
        setManuals(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                model_id: id
            },
            setFilter: Math.random().toString()
        }));
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add();
    }

    const handleCreate = manual => {

        // let data = manuals.data.concat(manual);

        // setManuals(prev => ({
        //     ...prev,
        //     data: data
        // }));

        setManuals(prev => ({
            ...prev,
            update: Math.random().toString()
        }));

    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id);
    }

    const handleUpdate = manual => {

        let data = manuals.data.map(i => {
            if (i.id == manual.id) i = manual;
            return i;
        });

        setManuals(prev => ({
            ...prev,
            data: data
        }));
    }

    // delete
    const handleDelete = id => {

        let url;

        if (manuals.tab === 'manuals') {
            url = 'appliances/manuals/delete';
        } else if (manuals.tab === 'errors') {
            url = 'appliances/errors/delete';
        } else if (manuals.tab === 'problems') {
            url = 'appliances/problems/delete';
        }

        Api.post(url, {
            id: id
        }).then(res => {
            if (res.data.success) {
                // let data = manuals.data.filter(i => {
                //     return i.id !== id;
                // });
                setManuals(prev => ({
                    ...prev,
                    update: Math.random().toString()
                }));
            }
        });
    }

    // Question modal
    const handleOpenQuestion = id => {
        modalQuestionRef.current.open(id);
    }

    const handleTab = newValue => {

        // history.push(`?tab=${newValue}`);

        setManuals(prev => ({
            ...prev,
            tab: newValue,
            page: 1,
            filter: {
                ...prev.filter,
                // category_id: '',
                // brand_id: '',
                // model: '',
                search: '',
            },
            setFilter: Math.random().toString(),
            loading: true
        }))
    }

    const handleSearch = (key, val) => {

        clearTimeout(timeout);

        setManuals(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setManuals(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);

    }

    const handlePage = page => {
        setManuals(prev => ({ ...prev, page: page.selected + 1, setFilter: Math.random().toString() }));
    }

    let formComponent;

    switch (manuals.tab) {
        case 'manuals':
            formComponent = <AddOrEditManual
                ref={modalAddOrEditRef}
                tab={manuals.tab}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />
            break;
        case 'errors':
            formComponent = <AddOrEditMessage
                ref={modalAddOrEditRef}
                tab={manuals.tab}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />
            break;
        case 'problems':
            formComponent = <AddOrEditProblem
                ref={modalAddOrEditRef}
                tab={manuals.tab}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />
            break;
    }

    return (
        <>

            {formComponent}

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
                callback={handleDelete}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.manuals')} <Help>{fromInfo('manuals')}</Help>
                        </h1>
                        <p className="page-subheading">
                            {trans('headings.manuals')}
                        </p>
                    </div>
                    <div className="right">
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.add')}
                        </button>
                    </div>
                </div>

                {searchParams.get('reservation_id') &&

                    <Link
                        to={`/reservations/edit/${searchParams.get('reservation_id')}`}
                        className="btn"
                        style={{ background: '#08f' }}
                    >
                        {trans('buttons.toReservation')} {searchParams.get('reference_code')}
                    </Link>

                }

                <>
                    <Filter
                        tab={manuals.tab}
                        filter={manuals.filter}
                        handleCategory={handleCategory}
                        handleBrand={handleBrand}
                        handleModel={handleModel}
                        handleSearch={handleSearch}
                    />

                    <FilterTabs
                        tab={manuals.tab}
                        handleTab={handleTab}
                    />

                    <Table
                        tab={manuals.tab}
                        manuals={manuals.data}
                        loading={manuals.loading}
                        handleEdit={handleEdit}
                        handleDelete={handleOpenQuestion}
                    />

                    {!manuals.loading
                        ?
                        <Pagination
                            pages={manuals.pages}
                            page={manuals.page}
                            handlePage={handlePage}
                        />
                        :
                        ''
                    }

                </>

            </div>
        </>
    )
}

export default Manuals;