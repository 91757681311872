import React, { useState, useRef } from 'react'
import Menu from '@material-ui/core/Menu'

//images
import groupMailIcon from '../../assets/img/icons/group-mail.png'
import editIcon from '../../assets/img/icons/edit.png'
import deleteIcon from '../../assets/img/icons/delete.png'
import menuIcon from '../../assets/img/icons/table-menu.png'
import checkIcon from '../../assets/img/icons/check.png'
import notPaidIcon from '../../assets/img/icons/not-paid.png'
import paidIcon from '../../assets/img/icons/paid.png'
import createIcon from '../../assets/img/icons/create.png'
import convertIcon from '../../assets/img/icons/convert.png'
import signIcon from '../../assets/img/icons/sign.png'
import sendIcon from '../../assets/img/icons/send.png'
import regenerateIcon from '../../assets/img/icons/regenerate.png'
import paypalIcon from '../../assets/img/icons/paypal.png'

import Api from '../../_helpers/Api'
import Question from '../modals/messages/Question'
import Success from '../modals/messages/Success'
import InvoiceSign from './InvoiceSign'
import { useAuthDataContext } from '../../_providers/Auth'
import InvoicePayment from './InvoicePayment'
import InvoicePaymentLink from './InvoicePaymentLink'
import { trans } from '../../_providers/Translation';
import InvoiceAttributes from './InvoiceAttributes'
import { useHistory } from 'react-router'

function InvoiceOptions(props) {

    const auth = useAuthDataContext();
    const history = useHistory();

    const invoice = props.invoice;

    const questionModalRef = useRef(null)
    const successSendModalRef = useRef(null)
    const questionConvertModalRef = useRef(null)
    const signModalRef = useRef(null)
    const paymentModalRef = useRef(null)
    const questionRegenerateModalRef = useRef(null)
    const editModalRef = useRef(null)
    const sendPaymentLinkModalRef = useRef(null)

    const [menu, setMenu] = useState({
        options: null,
    })

    const handleTableMenu = e => {
        setMenu(menu => {
            return {
                ...menu,
                options: e.currentTarget
            }
        })
    }

    const handleClose = () => {
        setMenu(menu => {
            return {
                ...menu,
                options: null
            }
        })
    }

    const handlePaid = () => {
        handleClose();

        let modal = paymentModalRef.current;

        modal.open(invoice.id);
        modal.onSuccess(() => {
            props.refreshData();
        });
    }

    const handleUnpaid = () => {
        handleClose();

        Api.post('invoices/mark-as-unpaid', {
            id: invoice.id
        }).then(res => {
            props.refreshData();
        });
    }

    const handleInvoice = () => {
        handleClose();

        Api.post('invoices/create/invoice', {
            doc_id: invoice.id
        }).then(res => {
            props.refreshData();
        });
    }

    const handleDelete = () => {
        handleClose();

        let modal = questionModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            destroy();
        })
    }

    const destroy = () => {
        Api.post('invoices/delete', {
            id: invoice.id,
        }).then(() => {

            props.refreshData();

            if (typeof props.handleDelete === 'function') {
                props.handleDelete();
            }
        })
    }

    const handleSend = () => {
        handleClose();

        Api.post('invoices/send', {
            ids: [invoice.id],
        }).then(() => {

            let modal = successSendModalRef.current;

            modal.open();
            modal.onClose(() => {
                props.refreshData();
            });
        })
    }

    const handleSendPaymentLink = () => {
        handleClose();

        let modal = sendPaymentLinkModalRef.current;

        modal.open(invoice);
        modal.onSuccess(() => {
            props.refreshData();
        });
    }

    const handleSign = () => {
        handleClose();

        let modal = signModalRef.current;

        modal.open(invoice.id);
        modal.onSuccess(data => {

            if (data.signed_url) {
                window.open(data.signed_url);
            }

            props.refreshData();
        });
    }

    const handleConvert = () => {
        handleClose();

        let modal = questionConvertModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            convert();
        });
    }

    const convert = () => {
        Api.post('invoices/create/creditnote', {
            id: invoice.id
        }).then(res => {
            props.refreshData();
        });
    }

    const handleRegenerate = () => {
        handleClose();

        let modal = questionRegenerateModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            regenerate();
        });
    }

    const regenerate = () => {
        Api.post('invoices/regenerate', {
            id: invoice.id
        }).then(res => {

            if (res.data.signed_url) {
                window.open(res.data.signed_url);
            }

            props.refreshData();
        });
    }

    const handleEdit = () => {
        history.push('/invoices/edit/' + invoice.id);
    }

    return (
        <>

            <Question
                ref={questionModalRef}
                mainMessage={trans('headings.confirmDelete')}
            />

            <Question
                ref={questionConvertModalRef}
                mainMessage={trans('headings.question9')}
            />

            <Question
                ref={questionRegenerateModalRef}
                mainMessage={trans('headings.question15')}
            />

            <Success
                ref={successSendModalRef}
                mainMessage={trans('headings.success2')}
            />

            <InvoiceSign
                ref={signModalRef}
            />

            <InvoicePayment
                ref={paymentModalRef}
            />

            <InvoicePaymentLink
                ref={sendPaymentLinkModalRef}
            />

            <InvoiceAttributes
                ref={editModalRef}
            />

            <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />

            <Menu
                id="simple-menu"
                anchorEl={menu.options}
                keepMounted
                open={Boolean(menu.options)}
                onClose={handleClose}
            >

                {invoice.payable &&
                    <>
                        {invoice.is_paid
                            ?
                            <div className="menu-option" onClick={handleUnpaid}>
                                <img src={notPaidIcon} alt="complete" />
                                {trans('buttons.markAsUnpaid')}
                            </div>
                            :
                            <div className="menu-option" onClick={handlePaid}>
                                <img src={paidIcon} alt="complete" />
                                {trans('buttons.markAsPaid2')}
                            </div>
                        }

                    </>
                }

                {invoice.allow_invoice &&
                    <div className="menu-option" onClick={handleInvoice}>
                        <img src={createIcon} alt="complete" />
                        {trans('buttons.createInvoice')}
                    </div>
                }

                {invoice.allow_convert &&
                    <div className="menu-option" onClick={handleConvert}>
                        <img src={convertIcon} alt="complete" />
                        {trans('buttons.convertToCreditNot')}
                    </div>
                }

                {invoice.allow_send &&
                    <div className="menu-option" onClick={handleSend}>
                        <img src={sendIcon} alt="complete" />
                        {trans('buttons.sendToClien')}
                    </div>
                }

                {invoice.allow_send_payment_link && auth?.data?.user?.partner?.has_paypal &&
                    <div className="menu-option" onClick={handleSendPaymentLink}>
                        <img src={paypalIcon} alt="complete" />
                        {trans('buttons.sendPaymentLink')}
                    </div>
                }

                <div className="menu-option" onClick={handleSign}>
                    <img src={signIcon} alt="complete" />
                    {trans('buttons.sign')}
                </div>

                <div className="menu-option" onClick={handleRegenerate}>
                    <img src={regenerateIcon} alt="complete" />
                    {trans('buttons.regenerate')}
                </div>

                {invoice.editable &&
                    <div className="menu-option" onClick={handleEdit}>
                        <img src={editIcon} alt="complete" />
                        {trans('buttons.edit')}
                    </div>
                }

                {invoice.deletable &&
                    <div className="menu-option" onClick={handleDelete}>
                        <img src={deleteIcon} alt="complete" />
                        {trans('buttons.delete')}
                    </div>
                }

            </Menu>
        </>

    )
}

export default InvoiceOptions