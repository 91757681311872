import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import Api from '../../../../../_helpers/Api';
import { trans } from '../../../../../_providers/Translation';

//images
import docIcon from '../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../assets/img/icons/close.png';
import loader from '../../../../../assets/img/loader.svg';
import Dropdown from '../../../../partials/Dropdown';
import { id } from 'date-fns/locale';
import ReactTooltip from 'react-tooltip';
import { useAuthDataContext } from '../../../../../_providers/Auth';

let timeout;
let cache = [];

function AddEditPart(props, ref) {

    const auth = useAuthDataContext();

    const reservation = props.reservation || null;

    const initialPopupData = {
        part: {
            category_id: reservation ? reservation.appliance_category_id : ''
        },
        number: null,
        profit: 0,
        total_used_quantity: 0,
        usages: []
    };

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        id: null,
        data: initialPopupData,
        edit: false,
        loading: false,
        dataLoading: false,
        categoryId: null,
        onSave: null,
        onCancel: null,
    });

    const [statuses, setStatuses] = useState([]);

    const [availability, setAvailability] = useState({
        loading: false,
        data: [],
        initial: true,
        cacheKey: null,
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: (name, number) => {
            handleCreate(name, number);
        },
        edit: id => {
            handleEdit(id);
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        },
        onCancel: fn => {
            setPopup(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    useEffect(() => {
        if (reservation) {
            setPopup(prev => ({
                ...prev,
                categoryId: reservation.appliance_category_id
            }))
        }
    }, [reservation]);

    useEffect(() => {
        if (popup.edit && popup.id) {

            cache = [];

            setPopup(prev => ({
                ...prev,
                dataLoading: true,
            }));

            Api.get('ordered-parts/find?id=' + popup.id)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            ...res.data
                        },
                        dataLoading: false
                    }));
                });
        }

    }, [popup.edit]);

    useEffect(() => {
        Api.get('storage/statuses')
            .then(res => {
                setStatuses(res.data);
            });
    }, []);

    useEffect(() => {
        if (!popup.edit) {
            let def = statuses.find(s => Number(s.default) === 1);

            if (def) {
                setPopup(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        status_id: def.id
                    },
                }));
            }
        }
    }, [statuses, popup.edit]);

    useEffect(() => {
        let diff = Number(popup.data.price) - Number(popup.data.delivery_price);

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                profit: diff.toFixed(2)
            }
        }));

    }, [popup.data.delivery_price, popup.data.price]);

    useEffect(() => {

        let number = popup.data.number;

        if (number && number.length) {

            let cacheKey = number.replace(/[^a-zA-Z0-9]/g, '');

            if (typeof cache[cacheKey] !== 'undefined') {

                setAvailability(prev => ({
                    ...prev,
                    loading: false,
                    cacheKey: cacheKey,
                    data: cache[cacheKey]
                }));

                return;
            }

            // if (!availability.initial) {
            //     setPopup(prev => ({
            //         ...prev,
            //         data: {
            //             ...prev.data,
            //             usages: [],
            //             total_used_quantity: 0
            //         }
            //     }));
            // }

            setAvailability(prev => ({
                ...prev,
                loading: true,
                cacheKey: null,
                data: []
            }));

            clearTimeout(timeout);

            timeout = setTimeout(() => {
                Api.get('ordered-parts/availability?number=' + number)
                    .then(res => {
                        setAvailability(prev => ({
                            ...prev,
                            loading: false,
                            data: res.data,
                            cacheKey: cacheKey,
                            initial: false
                        }));

                    });
            }, 300);
        } else {
            setAvailability(prev => ({
                ...prev,
                loading: false,
                data: []
            }));
        }

    }, [popup.data.number]);

    useEffect(() => {
        if (Number(popup.data.quantity) < Number(popup.data.total_used_quantity)) {

            let lastUsage = popup.data.usages[popup.data.usages.length - 1];

            if (!availability.initial && lastUsage) {
                handleDecrementUsage(null, lastUsage);
            }

        }
    }, [availability, popup.data.quantity, popup.data.total_used_quantity]);

    useEffect(() => {
        if (availability.cacheKey && availability.data.length) {
            cache[availability.cacheKey] = availability.data;
        }
    }, [availability.cacheKey, availability.data]);

    const handleCreate = (name, number) => {

        let data = initialPopupData;

        data.id = null;
        data.name = name;
        data.number = number;

        setPopup(prev => ({
            ...prev,
            data: data,
        }));


        handleShow();
    }

    const handleShow = () => {

        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleEdit = id => {

        setPopup(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime(),
        }));

        handleShow();
    }

    const handleHide = e => {
        e.preventDefault();

        handleClose();

        if (typeof popup.onCancel === 'function') {
            popup.onCancel();
        }
    }

    const handleClose = () => {
        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();


        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'ordered-parts/edit';
        } else {
            url = 'ordered-parts/add';
        }

        let data = new FormData(e.target);

        if (popup.edit) {
            data.append('id', popup.data.id);
        } else {
            data.append('reservation_id', reservation.id);
        }

        Api.post(url, data)
            .then(res => {

                if (typeof popup.onSave === 'function') {
                    popup.onSave(res.data);
                }

                handleClose();

                document.querySelectorAll('.parts-form').forEach(f => {
                    f.reset();
                });

                setPopup(prev => ({
                    ...prev,
                    data: initialPopupData
                }));

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {
                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handlePartChange = data => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                number: data.number || ''
            }
        }));
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleDecrementUsage = (e, usage) => {
        if (e) e.preventDefault();

        let used = Object.assign({}, usage);

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                total_used_quantity: prev.data.total_used_quantity - 1
            }
        }));

        // usage
        let usageData = [...popup.data.usages];
        let usedIndex = usageData.findIndex(s => Number(s.storage.id) === Number(used.storage.id));

        used.quantity--;

        usageData[usedIndex] = used;

        if (Number(used.quantity) === 0) {
            usageData.splice(usedIndex, 1);
        }

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                usages: usageData
            }
        }));

        // stock
        let stockData = [...availability.data];
        let stockIndex = stockData.findIndex(s => Number(s.id) === Number(used.storage.id));

        let stock = used.storage;

        if (stockData[stockIndex]) {
            stock = stockData[stockIndex];
        }

        stock.quantity++;

        if (stockData[stockIndex]) {
            stockData[stockIndex] = stock;
        } else {
            stockData.push(stock);
        }

        setAvailability(prev => ({
            ...prev,
            data: stockData
        }));
    }

    const handleDecrementStorage = (e, storage) => {
        e.preventDefault();

        let stock = Object.assign({}, storage);
        let maxQty = popup.data.quantity || 1;
        let usedQty = popup.data.total_used_quantity || 0;

        if (stock.quantity == 0) {
            return;
        }

        if (usedQty >= maxQty) {
            return;
        }

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                total_used_quantity: prev.data.total_used_quantity + 1
            }
        }));

        // stock
        let stockData = [...availability.data];
        let stockIndex = stockData.findIndex(s => Number(s.id) === Number(stock.id));

        stock.quantity--;

        stockData[stockIndex] = stock;

        setAvailability(prev => ({
            ...prev,
            data: stockData
        }));

        // usage
        let usageData = [...popup.data.usages];
        let usedIndex = usageData.findIndex(s => Number(s.storage.id) === Number(stock.id));

        let used = {
            id: null,
            quantity: 0,
            storage: stock
        };

        if (usageData[usedIndex]) {
            used = usageData[usedIndex];
        }

        used.quantity++;

        if (usageData[usedIndex]) {
            usageData[usedIndex] = used;
        } else {
            usageData.push(used);
        }

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                usages: usageData
            }
        }));

    }

    const handleDeliveryPriceChange = e => {
        let deliveryPrice = e.target.value;
        let price = null;
        let profit = null;

        let profitThreshold = getPartsPriceProfitThreshold();

        if (profitThreshold) {
            if (deliveryPrice < profitThreshold) {
                profit = getPartsPriceProfitBelowThreshold();
            } else if (deliveryPrice >= profitThreshold) {
                profit = getPartsPriceProfitAboveThreshold();
            }
        }

        if (profit) {
            price = calcPrice(deliveryPrice, profit);
        }

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                delivery_price: deliveryPrice,
                price: price !== null ? price : prev.data.price,
            }
        }));
    }

    const getPartsPriceProfitThreshold = () => {
        return auth.partner().settings.parts_price_profit_threshold;
    }

    const getPartsPriceProfitBelowThreshold = () => {
        return auth.partner().settings.parts_price_profit_below_threshold;
    }

    const getPartsPriceProfitAboveThreshold = () => {
        return auth.partner().settings.parts_price_profit_above_threshold;
    }

    const calcPrice = (price, profit) => {
        return (Number(price) + ((Number(profit) * Number(price)) / 100)).toFixed(2);
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form className={[popup.modal ? 'show' : '', 'popup-primary', popup.edit ? 'extra-large' : 'medium', 'parts-form'].join(' ')} autoComplete="off" onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {popup.edit ? trans('headings.editPart') : trans('headings.addPart')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                {popup.edit && popup.dataLoading
                    ?
                    <img src={loader} className="loader" />
                    :
                    <div className={['popup-container', popup.edit ? 'x2' : ''].join(' ')}>

                        {/* col1 */}
                        <div className="col">
                            <div className="row">

                                <div className="col">
                                    <label htmlFor="">
                                        {trans('labels.name')}
                                    </label>
                                    {/* <Dropdown
                            inputName="name"
                            inputIdName="part_id"
                            inputValue={popup.data && popup.data.part ? popup.data.part.name : ''}
                            inputIdValue={popup.data && popup.data.part && popup.data.part.id ? Number(popup.data.part.id) : ''}
                            inputClassName={validations && validations.name ? 'validate' : ''}
                            inputPlaceholder="Търсене по име или номер"
                            url="parts/search"
                            params={{
                                category_id: Number(popup.categoryId),
                            }}
                            requiredParams={['category_id']}
                            loadDataOnFocus={true}
                            renderInputText={data => {
                                return data.name
                            }}
                            renderText={data => {
                                return <div>{data.name} <br /> <span style={{ fontSize: '10px' }}>{data.number}</span></div>
                            }}
                            onChange={handlePartChange}
                        /> */}
                                    <input
                                        type="text"
                                        name="name"
                                        className={validations && validations.name ? 'validate' : ''}
                                        value={popup.data && popup.data.name ? popup.data.name : ''}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="col">
                                    <label>
                                        {trans('labels.number')}
                                    </label>
                                    <input
                                        type="text"
                                        name="number"
                                        className={validations && validations.number ? 'validate' : ''}
                                        value={popup.data && popup.data.number ? popup.data.number : ''}
                                        onChange={handleInputChange}
                                    />
                                </div>

                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('labels.supplier')}
                                    </label>
                                    <Dropdown
                                        key={'supplier-' + popup.data.id}
                                        inputName="supplier"
                                        inputIdName="supplier_id"
                                        inputValue={popup.data && popup.data.supplier && popup.data.supplier.name ? popup.data.supplier.name : ''}
                                        inputIdValue={popup.data && popup.data.supplier ? Number(popup.data.supplier.id) : ''}
                                        inputClassName={validations && validations.supplier_id ? 'validate' : ''}
                                        url="suppliers/all"
                                        renderText={data => {
                                            return data.name
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('labels.invoiceNumber')}
                                    </label>
                                    <input
                                        type="text"
                                        name="po_number"
                                        className={validations && validations.po_number ? 'validate' : ''}
                                        value={popup.data && popup.data.po_number ? popup.data.po_number : ''}
                                        onChange={handleInputChange}
                                    />
                                </div>

                            </div>

                            <div className="row">

                                <div className="col">
                                    <label>
                                        {trans('labels.deliveryPrice')}
                                    </label>
                                    <input
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        name="delivery_price"
                                        className={validations && validations.delivery_price ? 'validate' : ''}
                                        value={popup.data && popup.data.delivery_price ? popup.data.delivery_price : ''}
                                        onChange={handleDeliveryPriceChange}
                                    />
                                </div>

                                <div className="col">
                                    <label>
                                        {trans('labels.salePrice')}
                                    </label>
                                    <input
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        name="price"
                                        className={validations && validations.price ? 'validate' : ''}
                                        value={popup.data && popup.data.price ? popup.data.price : ''}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="col">
                                    <label>
                                        {trans('labels.profit')}
                                    </label>
                                    <input
                                        type="number"
                                        value={popup.data ? popup.data.profit : ''}
                                        disabled
                                    />
                                </div>

                                <div className="col">
                                    <label>
                                        {trans('labels.quantity')}
                                    </label>
                                    <input
                                        type="number"
                                        min="1"
                                        step="1"
                                        name="quantity"
                                        className={validations && validations.quantity ? 'validate' : ''}
                                        value={popup.data && popup.data.quantity ? popup.data.quantity : ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className="row">

                                <div className="col">
                                    <label>
                                        {trans('labels.status')}
                                    </label>

                                    <Dropdown
                                        inputIdName="status_id"
                                        inputIdValue={popup.data && popup.data.status_id ? Number(popup.data.status_id) : ''}
                                        inputClassName={validations && validations.status_id ? 'validate' : ''}
                                        url="storage/statuses"
                                        // data={statuses}
                                        renderInputText={data => {
                                            return data.translation ? data.translation.name : data.name;
                                        }}
                                        renderText={data => {
                                            return (
                                                <div>
                                                    <span style={{
                                                        width: '5px',
                                                        height: '15px',
                                                        display: 'inline-block',
                                                        borderRadius: '2px',
                                                        marginRight: '5px',
                                                        background: data.color
                                                    }}>
                                                    </span>

                                                    <span>{data.translation ? data.translation.name : data.name}</span>
                                                </div>
                                            )
                                        }}
                                        filterable={false}
                                    />

                                </div>

                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('labels.comment')}
                                    </label>
                                    <input
                                        type="text"
                                        name="comment"
                                        className={validations && validations.comment ? 'validate' : ''}
                                        value={popup.data && popup.data.comment ? popup.data.comment : ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('labels.description')}
                                    </label>
                                    <textarea
                                        name="description"
                                        className={validations && validations.description ? 'validate' : ''}
                                        value={popup.data && popup.data.description ? popup.data.description : ''}
                                        onChange={handleInputChange}
                                    >

                                    </textarea>
                                </div>
                            </div>

                        </div>

                        {popup.edit
                            ?
                            <div className="col">
                                <div className="scrollable" style={{ padding: '0 10px 0 0', maxHeight: '800px' }}>
                                    <div className="row">
                                        <div className="col">
                                            <label>
                                                {trans('labels.usedQuantity')}
                                            </label>
                                            {popup.data.usages.length === 0
                                                ?
                                                <div className="alert warning">
                                                    {trans('messages.noUsedQuantity')}
                                                </div>
                                                :
                                                <div className="table-scroller">
                                                    <table className="part-availability">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '40px' }}>
                                                                    ID
                                                                </th>
                                                                <th style={{ width: '40%' }}>
                                                                    {trans('tables.location')}
                                                                </th>
                                                                <th className="center" style={{ width: '20%' }}>
                                                                    {trans('tables.quantity')}
                                                                </th>
                                                                <th>

                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {popup.data.usages.map(s =>
                                                                <tr key={'u-' + s.id}>
                                                                    <td style={{ width: '40px' }}>
                                                                        {s.storage && s.storage.id}
                                                                    </td>
                                                                    <td style={{ width: '40%' }}>
                                                                        {s.storage && s.storage.position
                                                                            ?
                                                                            s.storage.position.name === 'office'
                                                                                ?
                                                                                [
                                                                                    s.storage.position.translation && s.storage.position.translation.name,
                                                                                    s.storage.shelve ? 'Рафт ' + s.storage.shelve : '',
                                                                                    s.storage.box ? 'Кутия ' + s.storage.box : ''
                                                                                ].filter(el => el !== '').join(', ')
                                                                                :
                                                                                s.storage.position.name === 'van'
                                                                                    ?
                                                                                    [
                                                                                        s.storage.position.translation && s.storage.position.translation.name,
                                                                                        s.storage.van ? s.storage.van.reg_no : '',
                                                                                        s.storage.employee ? `${s.storage.employee.name} ${s.storage.employee.lastname}` : ''
                                                                                    ].filter(el => el !== '').join(', ')
                                                                                    :
                                                                                    ''
                                                                            :
                                                                            ''
                                                                        }
                                                                    </td>
                                                                    <td className="center" style={{ width: '20%' }}>
                                                                        {s.quantity}
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            data-tip
                                                                            data-for={'u-' + s.storage.id}
                                                                            className="quantity decrement"
                                                                            onClick={e => handleDecrementUsage(e, s)}
                                                                            style={{ marginLeft: 'auto' }}
                                                                        >
                                                                            -
                                                                        </button>
                                                                        <ReactTooltip
                                                                            id={'u-' + s.storage.id}
                                                                            effect="solid"
                                                                        >
                                                                            {trans('buttons.backToStock')}
                                                                        </ReactTooltip>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {popup.data.usages.map(u =>
                                        <input key={'ui-' + u.storage.id} type="hidden" name={`storage_id[${u.storage.id}]`} value={u.quantity} />
                                    )}

                                    <div className="row">
                                        <div className="col">
                                            <label>
                                                {trans('labels.quantity')}
                                            </label>
                                            {!popup.data.number || popup.data.number.length === 0
                                                ?
                                                <div className="alert warning">
                                                    {trans('messages.inserPartNo')}
                                                </div>
                                                :
                                                availability.loading
                                                    ?
                                                    <img src={loader} alt="loading" />
                                                    :
                                                    availability.data.length === 0
                                                        ?
                                                        <div className="alert warning">
                                                            {trans('messages.noPartsInStock')}
                                                        </div>
                                                        :
                                                        <div className="table-scroller">
                                                            <table className="part-availability">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: '40px' }}>
                                                                            ID
                                                                        </th>
                                                                        <th style={{ width: '40%' }}>
                                                                            {trans('tables.location')}
                                                                        </th>
                                                                        <th className="center" style={{ width: '20%' }}>
                                                                            {trans('tables.quantity')}
                                                                        </th>
                                                                        <th className="right">
                                                                            {trans('tables.price')}
                                                                        </th>
                                                                        <th>

                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {availability.data.map(s =>
                                                                        <tr key={'a-' + s.id}>
                                                                            <td style={{ width: '40px' }}>
                                                                                {s.id}
                                                                            </td>
                                                                            <td>
                                                                                {s.position
                                                                                    ?
                                                                                    s.position.name === 'office'
                                                                                        ?
                                                                                        [
                                                                                            s.position.translation && s.position.translation.name,
                                                                                            s.shelve ? 'Рафт ' + s.shelve : '',
                                                                                            s.box ? 'Кутия ' + s.box : ''
                                                                                        ].filter(el => el !== '').join(', ')
                                                                                        :
                                                                                        s.position.name === 'van'
                                                                                            ?
                                                                                            [
                                                                                                s.position.translation && s.position.translation.name,
                                                                                                s.van ? s.van.reg_no : '',
                                                                                                s.employee ? `${s.employee.name} ${s.employee.lastname}` : ''
                                                                                            ].filter(el => el !== '').join(', ')
                                                                                            :
                                                                                            ''
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </td>
                                                                            <td className="center">
                                                                                {s.quantity}
                                                                            </td>
                                                                            <td className="right">
                                                                                {s.gross_price_format} {s.currency && s.currency.name}
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    data-tip
                                                                                    data-for={'s-' + s.id}
                                                                                    className={['quantity decrement', Number(s.quantity) === 0 || popup.data.total_used_quantity >= popup.data.quantity ? 'disabled' : ''].join(' ')}
                                                                                    onClick={e => handleDecrementStorage(e, s)}
                                                                                    style={{ marginLeft: 'auto' }}
                                                                                >
                                                                                    -
                                                                                </button>
                                                                                <ReactTooltip
                                                                                    id={'s-' + s.id}
                                                                                    effect="solid"
                                                                                >
                                                                                    {trans('buttons.removeFromStock')}
                                                                                </ReactTooltip>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                        }

                    </div>
                }
                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddEditPart);