import React from 'react';
import loader from '../../assets/img/circle-loader.svg'

function PageLoader(props) {

    const show = Boolean(props.show) || false;

    return (
        <div className={['overlay', show ? 'visible' : ''].join(' ')}>
            <img src={loader} alt="loading" />
        </div>
    )
}

export default PageLoader;