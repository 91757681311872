import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Api from '../../_helpers/Api'
import closeIcon from '../../assets/img/icons/close.png'
import Skeleton from 'react-loading-skeleton';
import Storage from './activity-templates/Storage';
import Pagination from '../../components/Pagination';
import Part from './activity-templates/Part';
import Reservation from './activity-templates/Reservation';
import Invoice from './activity-templates/Invoice';
import Expense from './activity-templates/Expense';
import { trans } from '../../_providers/Translation'
import NoDataFound from '../partials/NoDataFound';
import OrderedPart from './activity-templates/OrderedPart';

function Activity(props, ref) {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        loading: false,
        id: null,
        subjectId: null,
        subjectType: null,
        data: [],
        pages: 0,
        page: 1,
        total: 0,
        filter: {},
        template: '',
        setFilter: false,
        update: false,
    });

    const templates = {
        storage: <Storage data={state.data} />,
        part: <Part data={state.data} />,
        reservation: <Reservation data={state.data} />,
        invoice: <Invoice data={state.data} />,
        expense: <Expense data={state.data} />,
        orderedpart: <OrderedPart data={state.data} />
    }

    useImperativeHandle(ref, () => ({
        open: (id, type) => {
            handleOpen(id, type)
        },
        close: () => {
            handleClose()
        }
    }))

    // useEffect(() => {
    //     setState(prev => ({
    //         ...prev,
    //         page: searchParams.get('page') || 1,
    //         // filter: {
    //         //     ...prev.filter,
    //         // },
    //         update: new Date().getTime()
    //     }))

    // }, [location.search])

    // useEffect(() => {

    //     if (state.setFilter) {
    //         searchParams.set('page', state.page);

    //         Object.entries(state.filter).map(filter => {
    //             if (filter[1]) {
    //                 searchParams.set(filter[0], filter[1]);
    //             }
    //         });

    //         let url = '?' + searchParams.toString();

    //         history.push(url);
    //     }

    // }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);


    const loadData = () => {

        if (!state.id && !state.subjectId) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'activities/history';

        if (state.id) {
            url += '?id=' + state.id;
        } else if (state.subjectId) {
            url += '?subject_id=' + state.subjectId + '&subject_type=' + state.subjectType;
        }

        url += '&page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                    template: res.data.subject_type,
                    loading: false
                }));
            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    const handleOpen = (id, type) => {

        let subjectId = null;
        let subjectType = null;

        if (type) {
            subjectId = id;
            subjectType = type;
            id = null;
        }

        setState(prev => ({
            ...prev,
            overlay: true,
            id: id,
            subjectId: subjectId,
            subjectType: subjectType,
            update: new Date().getTime()
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)

    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 50)

        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, update: new Date().getTime() }));
    }

    const render = () => {
        if (state.template) {
            if (templates[state.template]) {
                return templates[state.template];
            }

            console.log('Template not found ' + state.template);
        }
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>

            <div className={`${state.modal ? 'show' : ''} popup-primary`}>


                <h2 className="head">
                    {trans('headings.viewActivity')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleClose} />
                </h2>

                {state.loading
                    ?
                    <Skeleton height={45} count={5} />
                    :
                    state.data.length === 0
                        ?
                        <NoDataFound />
                        :
                        <>
                            <div className="table-scroller">
                                {render()}
                            </div>

                            <Pagination
                                pages={state.pages}
                                page={state.page}
                                handlePage={handlePage}
                            />
                        </>

                }

                <div className="footer align-center">
                    <button onClick={handleClose}>
                        {trans('buttons.close')}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default React.memo(forwardRef(Activity), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});