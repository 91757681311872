import React, { useEffect } from 'react';
import loader from '../../../../assets/img/loader.svg';
import { useAuthDataContext } from '../../../../_providers/Auth';
import { trans } from '../../../../_providers/Translation';

function Info(props) {

    const auth = useAuthDataContext();

    return (

        props.loading
            ?
            <img src={loader} alt="loading" />
            :
            <>
                <div className="table-scroller">
                    <table>
                        <tbody>
                            <tr>
                                <td>{trans('labels.name')}</td>
                                <td>{props.partner.name}</td>
                            </tr>
                            <tr>
                                <td>{trans('labels.email')}</td>
                                <td>{props.partner.email}</td>
                            </tr>
                            <tr>
                                <td>{trans('labels.phone')}</td>
                                <td>{props.partner.phone}</td>
                            </tr>
                            <tr>
                                <td>{trans('labels.address')}</td>
                                <td>
                                    {props.partner.address}

                                    {props.partner.lat && props.partner.lon
                                        ? ` (г.ш. ${props.partner.lat}, г.д. ${props.partner.lon})`
                                        : ''
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>{trans('labels.workDays')}</td>
                                <td>{Array.isArray(props.partner.work_days_text) ? props.partner.work_days_text.join(', ') : ''}</td>
                            </tr>
                            <tr>
                                <td>{trans('labels.logo')}</td>
                                <td>
                                    {props.partner.image && props.partner.logo
                                        ?
                                        <img src={props.partner.logo} style={{ maxHeight: '50px', objectFit: 'contain' }} />
                                        :
                                        ''
                                    }
                                </td>
                            </tr>
                            {auth.isAdmin() &&
                                <>
                                    <tr>
                                        <td>{trans('labels.active')}</td>
                                        <td>{props.partner.active ? trans('labels.yes') : trans('labels.no')}</td>
                                    </tr>
                                    <tr>
                                        <td>{trans('labels.superpartner')}</td>
                                        <td>{props.partner.superpartner ? trans('labels.yes') : trans('labels.no')}</td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </>
    )
}

export default Info;