import React from 'react';
import Api from '../../../../_helpers/Api';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../../../partials/NoDataFound';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ReservationOptions from '../../reservation/partials/Options';
import checkIcon from '../../../../assets/img/icons/check.png';
import Pagination from '../../../Pagination';
import { fromInfo, trans } from '../../../../_providers/Translation';
import Help from '../../../partials/Help';

function Index(props) {

    const [state, setState] = useState({
        reservations: {
            loading: true,
            data: [],
            pages: 0,
            page: 1,
            update: false
        },
        contractors: {
            loading: true,
            data: [],
            pages: 0,
            page: 1,
            statuses: [],
            update: false
        },
        invoices: {
            loading: true,
            data: [],
            pages: 0,
            page: 1,
            update: false
        },
        reports: {
            loading: true,
            data: [],
            pages: 0,
            page: 1,
            update: false
        },
        unpaid: {
            loading: true,
            data: [],
            totals: null,
            currency: null
        }
    });

    useEffect(() => {
        loadUnpaidInvoices();
    }, []);

    useEffect(() => {
        loadReservations();
    }, [state.reservations.update]);

    useEffect(() => {
        loadContractors();
    }, [state.contractors.update]);

    useEffect(() => {
        loadSentInvoices();
    }, [state.invoices.update]);

    useEffect(() => {
        loadSentReports();
    }, [state.reports.update]);

    const loadReservations = () => {
        Api.get('contractors/reservations-with-changed-status?page=' + state.reservations.page)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    reservations: {
                        ...prev.reservations,
                        loading: false,
                        data: res.data.items,
                        pages: res.data.pages,
                    }
                }));
            });
    }

    const loadContractors = () => {
        Api.get('contractors/parts-statuses?page=' + state.contractors.page)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    contractors: {
                        ...prev.contractors,
                        loading: false,
                        data: res.data.items,
                        statuses: res.data.statuses,
                        pages: res.data.pages,
                    }
                }));
            });
    }

    const loadSentInvoices = () => {
        Api.get('contractors/mails?type=invoices&page=' + state.invoices.page)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    invoices: {
                        ...prev.invoices,
                        loading: false,
                        data: res.data.items,
                        pages: res.data.pages,
                    }
                }));
            });
    }

    const loadSentReports = () => {
        Api.get('contractors/mails?type=reports&page=' + state.reports.page)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    reports: {
                        ...prev.reports,
                        loading: false,
                        data: res.data.items,
                        pages: res.data.pages,
                    }
                }));
            });
    }

    const loadUnpaidInvoices = () => {
        Api.get('contractors/unpaid-invoices')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    unpaid: {
                        loading: false,
                        data: res.data.contractors,
                        totals: res.data.totals,
                        currency: res.data.currency
                    }
                }));
            });
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            contractors: {
                ...prev.contractors,
                page: page.selected + 1,
                update: new Date().getTime()
            }
        }));
    }

    const handleReservationsPage = page => {
        setState(prev => ({
            ...prev,
            reservations: {
                ...prev.reservations,
                page: page.selected + 1,
                update: new Date().getTime()
            }
        }));
    }

    const handleInvoicesPage = page => {
        setState(prev => ({
            ...prev,
            invoices: {
                ...prev.invoices,
                page: page.selected + 1,
                update: new Date().getTime()
            }
        }));
    }

    const handleReportsPage = page => {
        setState(prev => ({
            ...prev,
            reports: {
                ...prev.reports,
                page: page.selected + 1,
                update: new Date().getTime()
            }
        }));
    }

    return (
        <div id="dashboard">
            <div className="pannel">
                <h3>
                    {trans('random.lastReservationsWithChangedStatus')} <Help>{fromInfo('contractors.latestReservations')}</Help>
                </h3>
                {state.reservations.loading
                    ?
                    <Skeleton height={45} count={10} />
                    :
                    state.reservations.data.length === 0
                        ?
                        <div className="alert warning">{trans('messages.noResultsFound')}</div>
                        :
                        <>
                            <div className="table-scroller">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                ID
                                            </th>
                                            <th>
                                                {trans('tables.refNo')}
                                            </th>
                                            <th>
                                                {trans('tables.contractor')}
                                            </th>
                                            <th>
                                                {trans('tables.address')}
                                            </th>
                                            <th>
                                                {trans('tables.status')}
                                            </th>
                                            <th>
                                                {trans('tables.lastStatus')}
                                            </th>
                                            <th>
                                                {trans('tables.employee')}
                                            </th>
                                            <th className="center">
                                                {trans('tables.sentReport')}
                                            </th>
                                            <th className="options">
                                                {trans('tables.options')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.reservations.data.map(r =>
                                            <tr key={'r-' + r.id}>
                                                <td>
                                                    {r.id}
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/reservations/edit/${r.id}`}
                                                        className="btn"
                                                        style={{ background: r.color, color: r.text_color }}
                                                        data-tip
                                                        data-for={'tooltip-r-' + r.id}
                                                    >
                                                        {r.reference_code}
                                                    </Link>
                                                    <ReactTooltip
                                                        id={'tooltip-r-' + r.id}
                                                        effect="solid"
                                                    // backgroundColor={r.color}
                                                    // arrowColor={r.color}
                                                    // textColor={r.text_color}
                                                    >
                                                        {r.color_desc}
                                                    </ReactTooltip>
                                                </td>
                                                <td>
                                                    {r.contractor && r.contractor.name}
                                                </td>
                                                <td>
                                                    {r.full_address}
                                                </td>
                                                <td>
                                                    {r.finalstatus && r.finalstatus.translation && r.finalstatus.translation.name}
                                                </td>
                                                <td>
                                                    {r.prevfinalstatus && r.prevfinalstatus.translation && r.prevfinalstatus.translation.name}
                                                </td>
                                                <td>
                                                    {r.employee
                                                        ? `${r.employee.name} ${r.employee.lastname}`
                                                        : ''
                                                    }
                                                </td>
                                                <td className="center">
                                                    {r.send_reports
                                                        ?
                                                        <img src={checkIcon} alt="" />
                                                        :
                                                        ''
                                                    }
                                                </td>
                                                <td className="options">
                                                    <ReservationOptions
                                                        id={r.id}
                                                        data={r}
                                                        masterId={r.id}
                                                        handleRefresh={loadReservations}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                pages={state.reservations.pages}
                                page={state.reservations.page}
                                handlePage={handleReservationsPage}
                            />
                        </>
                }
            </div>

            <div className="pannel">
                <h3>
                    {trans('headings.contractors')} <Help>{fromInfo('contractors.parts')}</Help>
                </h3>
                {state.contractors.loading
                    ?
                    <Skeleton height={45} count={10} />
                    :
                    state.contractors.data.length === 0
                        ?
                        <div className="alert warning">{trans('messages.noResultsFound')}</div>
                        :
                        <>
                            <div className="table-scroller">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '40px' }}>
                                                ID
                                            </th>
                                            <th style={{ width: '20%' }}>
                                                {trans('tables.name')}
                                            </th>
                                            {state.contractors.statuses.map(s =>
                                                <th key={'sth-' + s.id} className="center">
                                                    {s.translation ? s.translation.name : s.name}
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.contractors.data.map(r =>
                                            <tr key={'r-' + r.id}>
                                                <td style={{ width: '40px' }}>
                                                    {r.id}
                                                </td>
                                                <td style={{ width: '20%' }}>
                                                    <Link to={'/contractors/' + r.id}>
                                                        {r.name}
                                                    </Link>
                                                </td>
                                                {state.contractors.statuses.map(s =>
                                                    <td key={'std-' + s.id} className="center">
                                                        {r.statuses[s.id] > 0 ? r.statuses[s.id] : ''}
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                pages={state.contractors.pages}
                                page={state.contractors.page}
                                handlePage={handlePage}
                            />

                        </>
                }
            </div>

            <div className="pannel">
                <h3>
                    {trans('random.sentInvoices')} <Help>{fromInfo('contractors.sentInvoices')}</Help>
                </h3>
                {state.invoices.loading
                    ?
                    <Skeleton height={45} count={10} />
                    :
                    state.invoices.data.length === 0
                        ?
                        <div className="alert warning">{trans('messages.noResultsFound')}</div>
                        :
                        <>
                            <div className="table-scroller">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20%' }}>
                                                {trans('tables.name')}
                                            </th>
                                            <th>
                                                {trans('tables.to')}
                                            </th>
                                            <th>
                                                {trans('tables.topic')}
                                            </th>
                                            <th>
                                                {trans('tables.refNo')}
                                            </th>
                                            <th>
                                                {trans('tables.info')}
                                            </th>
                                            <th>
                                                {trans('tables.employee')}
                                            </th>
                                            <th>
                                                {trans('tables.date')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.invoices.data.map(r =>
                                            <tr key={'si-' + r.id}>
                                                <td>
                                                    <Link to={'/contractors/' + r.contractor_id + '?tab=mails&type=invoices'}>
                                                        {r.contractor && r.contractor.name}
                                                    </Link>

                                                </td>
                                                <td>
                                                    {r.to}
                                                </td>
                                                <td>
                                                    {r.subject}
                                                </td>
                                                <td>
                                                    {r.reservation
                                                        ?
                                                        <a
                                                            href={'/reservations/edit/' + r.reservation_id} target="_blank"
                                                            className="btn"
                                                            style={{ background: r.reservation.color, color: r.reservation.text_color }}
                                                        >
                                                            {r.reservation.reference_code}
                                                        </a>
                                                        :
                                                        '-'
                                                    }
                                                </td>
                                                <td>
                                                    {r.url
                                                        ?
                                                        <a href={r.url} target="_blank">
                                                            {trans('buttons.view')}
                                                        </a>
                                                        :
                                                        r.text
                                                    }
                                                </td>
                                                <td>
                                                    {r.employee
                                                        ?
                                                        `${r.employee.name} ${r.employee.lastname}`
                                                        :
                                                        '-'
                                                    }
                                                </td>
                                                <td>
                                                    {r.date}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                pages={state.invoices.pages}
                                page={state.invoices.page}
                                handlePage={handleInvoicesPage}
                            />

                        </>
                }
            </div>

            <div className="pannel">
                <h3>
                    {trans('random.sentReports')} <Help>{fromInfo('contractors.sentReports')}</Help>
                </h3>
                {state.reports.loading
                    ?
                    <Skeleton height={45} count={10} />
                    :
                    state.reports.data.length === 0
                        ?
                        <div className="alert warning">{trans('messages.noResultsFound')}</div>
                        :
                        <>
                            <div className="table-scroller">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20%' }}>
                                                {trans('tables.name')}
                                            </th>
                                            <th>
                                                {trans('tables.to')}
                                            </th>
                                            <th>
                                                {trans('tables.topic')}
                                            </th>
                                            <th>
                                                {trans('tables.refNo')}
                                            </th>
                                            <th>
                                                {trans('tables.info')}
                                            </th>
                                            <th>
                                                {trans('tables.employee')}
                                            </th>
                                            <th>
                                                {trans('tables.date')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.reports.data.map(r =>
                                            <tr key={'sr-' + r.id}>
                                                <td>
                                                    <Link to={'/contractors/' + r.contractor_id + '?tab=mails&type=reports'}>
                                                        {r.contractor && r.contractor.name}
                                                    </Link>
                                                </td>
                                                <td>
                                                    {r.to}
                                                </td>
                                                <td>
                                                    {r.subject}
                                                </td>
                                                <td>
                                                    {r.reservation
                                                        ?
                                                        <a
                                                            href={'/reservations/edit/' + r.reservation_id} target="_blank"
                                                            className="btn"
                                                            style={{ background: r.reservation.color, color: r.reservation.text_color }}
                                                        >
                                                            {r.reservation.reference_code}
                                                        </a>
                                                        :
                                                        '-'
                                                    }
                                                </td>
                                                <td>
                                                    {r.url
                                                        ?
                                                        <a href={r.url} target="_blank">
                                                            {trans('buttons.view')}
                                                        </a>
                                                        :
                                                        r.text
                                                    }
                                                </td>
                                                <td>
                                                    {r.employee
                                                        ?
                                                        `${r.employee.name} ${r.employee.lastname}`
                                                        :
                                                        '-'
                                                    }
                                                </td>
                                                <td>
                                                    {r.date}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                pages={state.reports.pages}
                                page={state.reports.page}
                                handlePage={handleReportsPage}
                            />

                        </>
                }
            </div>

            <div className="pannel">
                <h3>
                    {trans('random.unpaidInvoices')} <Help>{fromInfo('contractors.unpaidInvoices')}</Help>
                </h3>
                {state.unpaid.loading
                    ?
                    <Skeleton height={45} count={10} />
                    :
                    state.unpaid.data.length === 0
                        ?
                        <div className="alert warning">{trans('messages.noResultsFound')}</div>
                        :
                        <>
                            <div className="table-scroller">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                {trans('tables.name')}
                                            </th>
                                            <th className="right">
                                                {trans('tables.netPrice')}
                                            </th>
                                            <th className="right">
                                                {trans('tables.tax')}
                                            </th>
                                            <th className="right">
                                                {trans('tables.gross')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.unpaid.data.map(c =>
                                            <tr key={'uc-' + c.id}>
                                                <td>
                                                    <Link to={'/contractors/' + c.id + '?tab=invoices&status=unpaid'}>
                                                        {c.name}
                                                    </Link>
                                                </td>
                                                <td className="right">
                                                    {c.total_price} {state.unpaid.currency}
                                                </td>
                                                <td className="right">
                                                    {c.vat_price} {state.unpaid.currency}
                                                </td>
                                                <td className="right">
                                                    {c.gross_price} {state.unpaid.currency}
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td>
                                                {trans('tables.total')}
                                            </td>
                                            <td className="right">
                                                <b>{state.unpaid.totals.price} {state.unpaid.currency}</b>
                                            </td>
                                            <td className="right">
                                                <b>{state.unpaid.totals.vat} {state.unpaid.currency}</b>
                                            </td>
                                            <td className="right">
                                                <b>{state.unpaid.totals.gross} {state.unpaid.currency}</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default Index;