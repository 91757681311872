import React, { useRef, useState, useEffect } from 'react';
import { useAuthDataContext } from '../../../../../_providers/Auth';
import Api from '../../../../../_helpers/Api';
import editIcon from '../../../../../assets/img/icons/edit-yellow.png';
import Modal from './modals/Notifications';
import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../../../_providers/Translation';

function Notifications(props) {

    const auth = useAuthDataContext();

    const modalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: [],
        update: false,
    });

    useEffect(() => {
        loadSettings();
    }, [state.update]);

    const loadSettings = () => {
        Api.get('partners/settings/all?id=' + auth.user().partner_id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const showModal = () => {
        let modal = modalRef.current;

        modal.show(state.data);

        modal.onSave(() => {
            handleUpdate();

            if (typeof props.handleRefresh === 'function') {
                props.handleRefresh();
            }
        });
    }

    return (
        <div style={{ width: '100%' }}>
            <div className="head">
                <h4>{trans('headings.notificationsSettings')}</h4>
                <a onClick={showModal}>
                    <img src={editIcon} alt="" />
                </a>
            </div>

            {state.loading
                ?
                <Skeleton height={35} count={3} />
                :
                <>
                    <Modal
                        ref={modalRef}
                    />

                    <div className="table-scroller">
                        <table className="settings">
                            <tbody>
                                <tr>
                                    <td>
                                        <b>{trans('labels.customersOperator')}</b>
                                    </td>
                                    <td>
                                        {state.data.customersoperator ? `${state.data.customersoperator.name} ${state.data.customersoperator.lastname}` : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('labels.allowInvoicePaidNotify')}</b>
                                    </td>
                                    <td>
                                        {typeof state.data.allow_invoice_paid_notify !== 'undefined'
                                            ?
                                            !state.data.allow_invoice_paid_notify
                                                ? trans('labels.no')
                                                : trans('labels.yes')
                                            : trans('labels.yes')
                                        }
                                    </td>
                                    <td>
                                        {trans('random.allowInvoicePaidNotify')}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('labels.storageMinQty')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.storage_min_quantity) >= 0 ? state.data.storage_min_quantity : trans('labels.notSpecified')}
                                    </td>
                                    <td>
                                        {trans('random.storageMinQty')}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('labels.allowStorageQtyNotify')}</b>
                                    </td>
                                    <td>
                                        {typeof state.data.allow_storage_quantity_notify !== 'undefined'
                                            ?
                                            !state.data.allow_storage_quantity_notify
                                                ? trans('labels.no')
                                                : trans('labels.yes')
                                            : trans('labels.yes')
                                        }
                                    </td>
                                    <td>
                                        {trans('random.allowStorageQtyNotify')}
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <b>{trans('labels.allowStorageSuggestionNotify')}</b>
                                    </td>
                                    <td>
                                        {typeof state.data.allow_storage_suggestion_notify !== 'undefined'
                                            ?
                                            !state.data.allow_storage_suggestion_notify
                                                ? trans('labels.no')
                                                : trans('labels.yes')
                                            : trans('labels.yes')
                                        }
                                    </td>
                                    <td>
                                        {trans('random.allowStorageSuggestionNotify')}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('labels.allowClientAutoEmails')}</b>
                                    </td>
                                    <td>
                                        {typeof state.data.allow_client_auto_emails !== 'undefined'
                                            ?
                                            !state.data.allow_client_auto_emails
                                                ? trans('labels.no')
                                                : trans('labels.yes')
                                            : trans('labels.yes')
                                        }
                                    </td>
                                    <td>
                                        {trans('random.allowClientAutoEmails')}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('labels.allowUpcomingReservationNotify')}</b>
                                    </td>
                                    <td>
                                        {typeof state.data.allow_upcoming_reservation_notify !== 'undefined'
                                            ?
                                            !state.data.allow_upcoming_reservation_notify
                                                ? trans('labels.no')
                                                : trans('labels.yes')
                                            : trans('labels.yes')
                                        }
                                    </td>
                                    <td>
                                        {trans('random.allowUpcomingReservationNotify')}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('labels.allowDelayedReservationNotify')}</b>
                                    </td>
                                    <td>
                                        {typeof state.data.allow_delayed_reservation_notify !== 'undefined'
                                            ?
                                            !state.data.allow_delayed_reservation_notify
                                                ? trans('labels.no')
                                                : trans('labels.yes')
                                            : trans('labels.yes')
                                        }
                                    </td>
                                    <td>
                                        {trans('random.allowDelayedReservationNotify')}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('labels.allowInvoiceReminderNotify')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.allow_invoice_payment_reminder_notify) > 0 ? state.data.allow_invoice_payment_reminder_notify : trans('labels.notSpecified')}
                                    </td>
                                    <td>
                                        {trans('random.allowInvoiceReminderNotify')}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('labels.visitNotificationSms')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.visit_notification_time) > 0 ? state.data.visit_notification_time : trans('labels.notSpecified')}
                                    </td>
                                    <td>
                                        {trans('random.visitNotificationSms')}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('labels.allowReassignNotify')}</b>
                                    </td>
                                    <td>
                                        {typeof state.data.allow_reassign_sms_notify !== 'undefined'
                                            ?
                                            !state.data.allow_reassign_sms_notify
                                                ? trans('labels.no')
                                                : trans('labels.yes')
                                            : trans('labels.yes')
                                        }
                                    </td>
                                    <td>
                                        {trans('random.allowReassignNotify')}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('labels.preferredSmsChannel')}</b>
                                    </td>
                                    <td style={{ textTransform: 'uppercase' }}>
                                        {state.data.preferred_sms_channel_name || trans('labels.notSpecified')}
                                    </td>
                                    <td>
                                        {trans('random.preferredSmsChannel')}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('labels.notificationsStoragePeriod')}</b>
                                    </td>
                                    <td>
                                        {state.data.notifications_storage_period >= 0 ? state.data.notifications_storage_period : trans('labels.notSpecified')}
                                    </td>
                                    <td>
                                        {trans('random.notificationsStoragePeriod')}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </div>
    )

}

export default Notifications