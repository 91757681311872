import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';

//partials
import Filter from './Filter';
import Table from './Table';
import FilterTabs from './FilterTabs';
import AddOrEdit from './AddOrEdit';
import { useHistory, useLocation } from 'react-router-dom';
import Api from '../../../../_helpers/Api';
import Pagination from '../../../Pagination';
import Question from '../../../modals/messages/Question';
import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../../_providers/Translation'

let timeout;

function Index(props, ref) {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const modalRef = useRef(null);
    const deleteModalRef = useRef(null);

    const tabs = ['team', 'advert'];

    const [state, setState] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
    });

    useImperativeHandle(ref, () => ({
        add: () => {
            handleAdd();
        }
    }));

    useEffect(() => {

        let status = searchParams.get('status');

        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                status: tabs.indexOf(status) > -1 ? status : tabs[0],
                method: searchParams.get('method') || '',
                date: searchParams.get('date') || '',
                deadline: searchParams.get('deadline') || '',
                text: searchParams.get('text') || '',
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                if (filter[1]) {
                    searchParams.set(filter[0], filter[1]);
                }
            });

            history.push('?' + searchParams.toString());
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData()
        }
    }, [state.update]);


    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'text-messages/get?type=' + state.filter.status + '&page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleAdd = () => {
        modalRef.current.add(state.filter.status);
    }

    const handleCreate = data => {
        refreshData();
    }

    // update
    const handleEdit = id => {
        modalRef.current.edit(id);
    }

    const handleUpdate = newData => {
        refreshData();
    }

    // delete
    const handleDelete = id => {

        deleteModalRef.current.open(id);

        deleteModalRef.current.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleDestroy = id => {
        Api.post('text-messages/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                let data = state.data.filter(i => {
                    return i.id !== id;
                });
                setState(prev => ({
                    ...prev,
                    data: data
                }));
            }
        });
    }

    const handleSearch = (key, val, interval = 300) => {

        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
            page: 1,
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                setFilter: new Date().getTime()
            }));
        }, interval);

    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    const handleTab = tab => {

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                status: tab
            },
            page: 1,
            setFilter: new Date().getTime()
        }));
    }

    const refreshData = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }))
    }

    return (
        <>

            <AddOrEdit
                ref={modalRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={deleteModalRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
            />

            <Filter
                data={state.filter}
                handleSearch={handleSearch}
            />

            <FilterTabs
                tab={state.filter.status}
                handleTab={handleTab}
            />

            {state.loading
                ?
                <Skeleton count={10} height={45} />
                :
                <>
                    <Table
                        data={state.data}
                        loading={state.loading}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        refreshData={refreshData}
                    />

                    <Pagination
                        pages={state.pages}
                        page={state.page}
                        handlePage={handlePage}
                    />

                </>
            }

        </>
    )
}

export default forwardRef(Index);