import React, { forwardRef, useImperativeHandle } from 'react';
import Api from '../../../../_helpers/Api';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Filter from './Filter';
import Skeleton from 'react-loading-skeleton';
import SendMessage from './SendMessage';
import Question from '../../../modals/messages/Question'
import Pagination from '../../../Pagination'
import AddOrEdit from './AddOrEdit'
import NoDataFound from '../../../partials/NoDataFound';
import Options from './ListOptions';
import { trans } from '../../../../_providers/Translation';
import closeIcon from '../../../../assets/img/icons/close.png';
import checkIcon from '../../../../assets/img/icons/check.png';

let timeout;

function Index(props, ref) {

    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search)

    const modalAddOrEditRef = useRef(null)
    const modalQuestionRef = useRef(null)
    const modalSendMessageRef = useRef(null)

    const page = searchParams.get('page') || 1;
    const name = searchParams.get('name') || '';
    const typeId = searchParams.get('type_id') || '';

    const [data, setData] = useState({
        loading: true,
        data: [],
        types: [],
        total: 0,
        pages: 0,
        page: page,
        filter: {},
        setFilter: false,
        update: false,
    });

    useImperativeHandle(ref, () => ({
        add: () => {
            handleAdd();
        }
    }));

    useEffect(() => {
        setData(prev => ({
            ...prev,
            page: page,
            filter: {
                ...prev.filter,
                name: name,
                type_id: typeId
            },
            update: new Date().getTime()
        }))
    }, [page, name, typeId])

    useEffect(() => {
        if (data.setFilter) {
            searchParams.set('page', data.page);

            Object.entries(data.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [data.setFilter]);

    useEffect(() => {
        if (data.update) {
            loadContractors();
        }
    }, [data.update]);

    useEffect(() => {
        loadTypes();
    }, []);

    // read
    const loadContractors = () => {

        setData(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'contractors/all?page=' + data.page;

        Object.entries(data.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setData(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    pages: res.data.pages,
                    total: res.data.total
                }));
            }).catch(err => {
                setData(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const loadTypes = () => {
        setData(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'contractors/types';

        Api.get(url)
            .then(res => {
                setData(prev => ({
                    ...prev,
                    loading: false,
                    //data: res.data,
                    types: res.data
                }));
            }).catch(err => {
                setData(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    //Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setData(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setData(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add();
    }

    const handleCreate = () => {
        setData(prev => ({
            ...prev,
            update: Math.random().toString()
        }))
    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id)
    }

    // const handleEditType = id => {
    //     modalAddOrEditTypeRef.current.edit(id)
    // }

    const handleUpdate = row => {

        let updated = data.data.map(i => {
            if (i.id == row.id) {
                i = row
            }
            return i
        });

        setData(prev => ({
            ...prev,
            data: updated,
        }))
    }

    // delete
    const handleDestroy = id => {

        let url = 'contractors/delete';

        Api.post(url, {
            id: id
        }).then(res => {
            if (res.data.success) {
                let filtered = data.data.filter(i => {
                    return i.id !== id
                })

                setData(prev => ({
                    ...prev,
                    data: filtered
                }))

                modalQuestionRef.current.close()
            }
        })
    }

    // Question modal
    const handleDelete = id => {
        modalQuestionRef.current.open(id);

        modalQuestionRef.current.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handlePage = page => {
        setData(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }))
    }

    const handleSendMessage = id => {
        modalSendMessageRef.current.open(id);
    }

    return (
        <>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
            />

            <SendMessage
                ref={modalSendMessageRef}
            />

            <Filter
                filter={data.filter}
                types={data.types}
                handleSearch={handleSearch}
            />

            {data.loading
                ?
                <Skeleton height={45} count={10} />
                :
                data.data.length === 0
                    ?
                    <NoDataFound />
                    :
                    <>
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            ID
                                        </th>
                                        <th style={{ width: '10%' }}>
                                            {trans('tables.name')}
                                        </th>
                                        <th style={{ width: '5%' }}>
                                            {trans('tables.type')}
                                        </th>
                                        <th style={{ width: '10%' }}>
                                            {trans('tables.address')}
                                        </th>
                                        <th style={{ width: '5%' }}>
                                            {trans('tables.postCode')}
                                        </th>
                                        <th style={{ width: '10%' }}>
                                            {trans('labels.phone')}
                                        </th>
                                        <th style={{ width: '10%' }}>
                                            {trans('labels.email')}
                                        </th>
                                        <th style={{ width: '10%' }}>
                                            {trans('tables.email2')}
                                        </th>
                                        <th className="center" style={{ width: '150px' }}>
                                            {trans('labels.sendReport')}
                                        </th>
                                        <th className="center" style={{ width: '150px' }}>
                                            {trans('labels.taxReservation')}
                                        </th>
                                        {/* <th className="center" style={{ width: '150px' }}>
                                            {trans('labels.repairWarranty')}
                                        </th> */}
                                        <th className="center" style={{ width: '150px' }}>
                                            {trans('labels.active')}
                                        </th>
                                        <th className="options">
                                            {trans('tables.options')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.data.map(c =>
                                        <tr key={c.id}>
                                            <td>
                                                {c.id}
                                            </td>
                                            <td style={{ width: '10%' }}>
                                                <Link to={'contractors/' + c.id}>
                                                    {c.name}
                                                </Link>
                                            </td>
                                            <td style={{ width: '5%' }}>
                                                {c.type ? (c.type?.translation?.name || c.type.name) : ''}
                                            </td>
                                            <td style={{ width: '10%' }}>
                                                {c.address}
                                            </td>
                                            <td style={{ width: '5%' }}>
                                                {c.postcode}
                                            </td>
                                            <td style={{ width: '10%' }}>
                                                {c.phone}
                                            </td>
                                            <td style={{ width: '10%' }}>
                                                {c.email}
                                            </td>
                                            <td style={{ width: '10%' }}>
                                                {c.accounting_email}
                                            </td>
                                            <td className="center" style={{ width: '150px' }}>
                                                {c.send_reports
                                                    ?
                                                    <img src={checkIcon} height="12" />
                                                    :
                                                    <img src={closeIcon} height="12" />
                                                }
                                            </td>
                                            <td className="center" style={{ width: '150px' }}>
                                                {c.charge
                                                    ?
                                                    <img src={checkIcon} height="12" />
                                                    :
                                                    <img src={closeIcon} height="12" />
                                                }
                                            </td>
                                            {/* <td className="center" style={{ width: '150px' }}>
                                                {c.repair_warranty
                                                    ?
                                                    <img src={checkIcon} height="12" />
                                                    :
                                                    <img src={closeIcon} height="12" />
                                                }
                                            </td> */}
                                            <td className="center" style={{ width: '150px' }}>
                                                {c.active
                                                    ?
                                                    <img src={checkIcon} height="12" />
                                                    :
                                                    <img src={closeIcon} height="12" />
                                                }
                                            </td>
                                            <td className="options">
                                                <Options
                                                    id={c.id}
                                                    handleEdit={handleEdit}
                                                    handleDelete={handleDelete}
                                                    handleSendMessage={handleSendMessage}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            pages={data.pages}
                            page={data.page}
                            handlePage={handlePage}
                        />
                    </>
            }

        </>
    )
}

export default forwardRef(Index);