import React from 'react'
import { trans } from '../../../../_providers/Translation';

function Filter(props) {

    const handleSelectMobileFilter = e => {
        let selectedValue = e.target.value
        let fields = [...document.querySelectorAll('.filter-standard .col')]
        fields.map(field => {
            if (field.getAttribute('data-id') == selectedValue) field.style.display = 'flex'
            else if (field.getAttribute('data-id')) field.style.display = 'none'
        })
    }

    return (
        <form className="filter-standard">
            <div className="col select-filter-col">
                <label>
                    {trans('filters.filterBy')}
                </label>
                <select onChange={handleSelectMobileFilter}>
                    <option value="1" selected>
                        {trans('random.search')}
                    </option>
                    <option value="5">
                        {trans('random.active')}
                    </option>
                    <option value="6">
                        {trans('random.superPartners')}
                    </option>
                </select>
            </div>

            <div className="col" data-id="1">
                <label>
                    {trans('labels.search')}
                </label>
                <input
                    type="search"
                    name="search"
                    onKeyUp={(e) => props.handleSearch(e.target.name, e.target.value)}
                    onChange={(e) => props.handleSearch(e.target.name, e.target.value)}
                    value={props.filter.search}
                />
            </div>


            <div className="col" data-id="5" style={{ justifyContent: 'center' }}>
                <label className="flex">
                    <input
                        id="search-active"
                        type="checkbox"
                        name="active"
                        onChange={(e) => props.handleSearch(e.target.name, Number(e.target.checked))}
                        checked={Number(props.filter.active) === 1 ? true : false}
                    />
                    <span>
                        {trans('labels.active')}
                    </span>
                </label>
            </div>

            <div className="col" data-id="6" style={{ justifyContent: 'center' }}>
                <label className="flex">
                    <input
                        id="search-superpartner"
                        type="checkbox"
                        name="superpartner"
                        onChange={(e) => props.handleSearch(e.target.name, Number(e.target.checked))}
                        checked={Number(props.filter.superpartner) === 1 ? true : false}
                    />
                    <span>
                        {trans('labels.superPartner')}
                    </span>
                </label>

            </div>

        </form>
    )
}

export default Filter