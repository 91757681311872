import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { trans } from '../../../_providers/Translation';

//images
import loader from '../../../assets/img/loader.svg';
import { useNotificationsContext } from '../../../_providers/Notifications';

function Notifications(props) {

    const notifications = useNotificationsContext();

    const ref = useRef(null);

    return (
        <div className={`show messages-module`}>
            <div className="head">
                {trans('headings.notifications')}
            </div>
            <div className="body">
                <div className="scroll" ref={ref}>
                    {notifications.list.length === 0
                        ?
                        <p className="no-data">{trans('messages.noNotifications')}</p>
                        :
                        <InfiniteScroll
                            element="ul"
                            initialLoad={false}
                            pageStart={notifications.page}
                            loadMore={notifications.load}
                            hasMore={notifications.hasMore}
                            loader={<img key="loader" src={loader} alt="loading" />}
                            useWindow={false}
                            getScrollParent={() => ref.current}
                        >

                            {notifications.list.map(el =>
                                <li key={el.id}>
                                    <h3>

                                        <time className="timer" data-date={el.created_at}>
                                            {el.date_text}
                                        </time>
                                    </h3>
                                    <p>
                                        {el.text || '-'}
                                    </p>
                                </li>
                            )}

                        </InfiniteScroll>
                    }
                </div>
            </div>

        </div >
    )
}

export default Notifications;