import React from 'react';
import { fromInfo } from '../../../../_providers/Translation';

function HelpInfo() {
    return (
        <div>
            {fromInfo('todo')}
        </div>
    )
}

export default HelpInfo;