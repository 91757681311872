import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Api from '../../_helpers/Api';
import docIcon from '../../assets/img/icons/document.png';
import closeIcon from '../../assets/img/icons/close.png';
import loader from '../../assets/img/loader.svg';
import { trans } from '../../_providers/Translation';
import { useAuthDataContext } from '../../_providers/Auth';
import Success from '../modals/messages/Success';
import Error from '../modals/messages/Error';

function InvoicePaymentLink(props, ref) {

    const auth = useAuthDataContext();

    let initialData = {
        via: undefined,
        to: undefined,
        // phone: '',
        // email: ''
    }

    const [state, setState] = useState({
        invoice: null,
        data: initialData,
        overlay: false,
        modal: false,
        loading: false,
        onSuccess: null,
    });

    const [validations, setValidations] = useState(null);

    const successModalRef = useRef(null);
    const attentionModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: invoice => {
            handleOpen(invoice)
        },
        close: () => {
            handleClose()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }));

    useEffect(() => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                phone: state.invoice?.customer?.phone_formatted || state.invoice?.client?.phone_formatted,
                email: state.invoice?.customer?.accounting_email || state.invoice?.client?.accounting_email,
            }
        }))
    }, [state.invoice]);

    useEffect(() => {
        if (typeof state.data.via === 'undefined') {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    via: auth?.data?.user?.partner?.allow_sms ? 'sms' : 'mail'
                }
            }))
        }
    }, [state.blur, auth?.data?.user?.partner?.allow_sms])

    useEffect(() => {
        if (state.data.via === 'sms' && state.data.phone) {
            if (typeof state.data.to === 'undefined') {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        to: state.data.phone
                    }
                }))
            }
        } else if (state.data.via === 'mail' && state.data.email) {
            if (typeof state.data.to === 'undefined') {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        to: state.data.email
                    }
                }))
            }
        }
    }, [state.data.to, state.data.via, state.data.phone, state.data.email]);

    const handleOpen = (invoice) => {
        setState(prev => ({
            ...prev,
            invoice: invoice,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            invoice: null,
            data: initialData,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 50)

        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    }

    const handleViaChange = e => {
        const value = e.target.value
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                via: value,
                to: undefined
            }
        }))
    }

    const handleChange = e => {
        const value = e.target.value
        const name = e.target.name

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value,
            }
        }))
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let data = new FormData(e.target);

        Api.post('invoices/send-payment-link', data)
            .then(res => {
                if (res.data.success) {
                    handleClose();

                    if (res.data.success) {
                        let modal = successModalRef.current;

                        modal.open();
                        modal.onClose(() => {
                            if (typeof state.onSuccess === 'function') {
                                state.onSuccess();
                            }
                        });
                    } else {
                        attentionModalRef.current.open();
                    }

                }
            })
            .catch(error => {

                setState(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                } else {
                    attentionModalRef.current.open();
                }

            });
    }

    return (
        <>
            <Success
                ref={successModalRef}
                mainMessage={trans('messages.success')}
            />
            <Error
                ref={attentionModalRef}
                mainMessage={trans('messages.fail')}
            />

            <div className={`${state.overlay ? 'visible' : ''} overlay`} onSubmit={handleSave}>

                <form className={`${state.modal ? 'show' : ''} popup-primary small`}>

                    <input type="hidden" name="id" value={state?.invoice?.id || ''} />

                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />
                        {trans('headings.invoicePayment')}

                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleClose} />
                    </h2>


                    <div className="popup-container">
                        <div className="col">
                            <label htmlFor="via">
                                {trans('labels.via')}
                            </label>
                            <select
                                name="via"
                                id="via"
                                value={state.data.via || ''}
                                className={validations?.via ? 'validate' : ''}
                                onChange={handleViaChange}
                            >
                                {auth?.data?.user?.partner?.allow_sms &&
                                    <option value="sms">
                                        Viber / WhatsApp / SMS
                                    </option>
                                }
                                <option value="mail">
                                    {trans('labels.email')}
                                </option>
                            </select>
                        </div>
                    </div>

                    <br />

                    <div className="popup-container">
                        <div className="col">
                            <label htmlFor="to">
                                {state.data.via === 'sms' ? trans('labels.number') : trans('labels.email')}
                            </label>
                            <input
                                type="text"
                                name="to"
                                id="to"
                                value={state.data.to || ''}
                                className={validations?.to ? 'validate' : ''}
                                onChange={handleChange}
                            />
                        </div>
                    </div>


                    <div className="footer align-center">
                        {state.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="button" onClick={handleClose}>
                                    {trans('buttons.cancel')}
                                </button>
                                <button className="button button-primary" type="submit">
                                    {trans('buttons.save')}
                                </button>
                            </>
                        }
                    </div>
                </form>
            </div>
        </>
    )
}

export default forwardRef(InvoicePaymentLink);