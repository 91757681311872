import React, { useState, useEffect } from 'react'
import LatestReservations from './partials/LatestReservations'
import { Link, useHistory } from 'react-router-dom'
import { useAuthDataContext } from '../../../_providers/Auth';
import { fromInfo, trans } from '../../../_providers/Translation';

//partials
import Location from './partials/Location'
import Reservation from './partials/Reservation'
import ActivityLog from './partials/ActivityLog'
import CurrentDataChart from './partials/CurrentDataChart'
import Api from '../../../_helpers/Api'
import Finances from './partials/Finances'
import EmployeesReservationsChart from './partials/EmployeesReservationsChart'
import viberIcon from '../../../assets/img/icons/sms/viber.png'
import whatsAppIcon from '../../../assets/img/icons/sms/whatsapp.png'
import smsIcon from '../../../assets/img/icons/sms/sms.png'
import Help from '../../partials/Help';

function Dashboard() {
    const auth = useAuthDataContext();
    const history = useHistory()

    const handleNewTask = () => history.push('/reservation/add')

    return (
        <div id="dashboard" className="wrapper">
            <div className="top-part">
                <div className="left">
                    <h1 className="page-heading">
                        {trans('headings.dashboard')}
                    </h1>
                    <p className="page-subheading">
                        {trans('subheadings.dashboard')}
                    </p>
                </div>
                <div className="right" style={{ display: 'flex' }}>
                    <Link to="/reservations/address/add">
                        <button className="button button-add">
                            {trans('buttons.addAddressReservation')}
                        </button>
                    </Link>
                    <Link to="/reservations/service/add" style={{ marginLeft: '10px' }}>
                        <button className="button button-add">
                            {trans('buttons.addServiceReservation')}
                        </button>
                    </Link>
                </div>
            </div>
            <div className="pannel x2">
                <div className="col full-width">
                    <Reservation />
                </div>
                <div className="col activity-log sms" style={{ position: 'relative' }}>
                    <div className="logs pannel" style={{
                        // backgroundColor: '#C51CD9',
                        // color: 'white',

                        // fontSize: '18px',
                        margin: 0
                    }}>
                        <div>
                            <img src={viberIcon} alt="" />
                            <span>
                                Viber
                                <br />
                                {auth.partner().viber_count}
                            </span>
                        </div>
                        <div>
                            <img src={whatsAppIcon} alt="" />
                            <span>
                                WhatsApp
                                <br />
                                {auth.partner().whatsapp_count}
                            </span>
                        </div>
                        <div>
                            <img src={smsIcon} alt="" />
                            <span>
                                SMS
                                <br />
                                {auth.partner().sms_count}
                            </span>
                        </div>

                        <a href="https://eusystem.eu/sms-packets" target="_blank">
                            {trans('buttons.getMessagesPackage')}
                        </a>

                    </div>
                    <Help
                        position="bottom-right"
                        style={{ position: 'absolute', top: '-9px', right: '-5px' }}
                    >
                        {fromInfo('dashboard.sms')}
                    </Help>
                </div>
            </div>
            <div className="pannel x2">
                <Location />
                <ActivityLog />
            </div>

            <LatestReservations />

            <div className="pannel x2">
                <EmployeesReservationsChart />

                <Finances />
            </div>

            <div className="pannel x2">
                <CurrentDataChart />
            </div>

        </div>
    )
}

export default Dashboard;