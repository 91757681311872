import React, { useEffect, useState, useRef } from 'react'
import Api from '../../../_helpers/Api'
import Pagination from '../../Pagination'
import { useHistory, useLocation } from 'react-router-dom'
import { trans, plural, fromInfo } from '../../../_providers/Translation';
import Results from './partials/BonusPolicyResult';
import Periods from './partials/BonusPolicyPeriods';
import Question from '../../modals/messages/Question';
import Skeleton from 'react-loading-skeleton'
import Modal from './partials/BonusPolicy';
import NoDataFound from '../../partials/NoDataFound';
import Options from './partials/Options';
import Help from '../../partials/Help';

let timeout;

function BonusPolicies() {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const modalRef = useRef(null);
    const periodsModalRef = useRef(null);
    const resultsModalRef = useRef(null);
    const deleteModalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                searchParams.set(filter[0], filter[1]);
            });

            history.push('?' + searchParams.toString());
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'partners/bonuspolicies/all?page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                if (res.data.pages > 0 && state.page > res.data.pages) {
                    return handlePage({
                        selected: res.data.pages - 1
                    });
                }

                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                    loading: false
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleAdd = () => {
        let modal = modalRef.current;

        modal.open();

        modal.onSave(data => {
            setState(prev => ({
                ...prev,
                data: [data, ...prev.data]
            }));
        });

    }

    const handleEdit = id => {
        let modal = modalRef.current;

        modal.edit(id);

        modal.onSave(data => {
            handleUpdate();
        });

    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const handleDelete = id => {
        let modal = deleteModalRef.current;

        modal.open();

        modal.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleDestroy = id => {
        Api.post('partners/bonuspolicies/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                handleUpdate();
            }
        });
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    const handleShowPeriods = id => {
        periodsModalRef.current.open(id);
    }

    const handleShowResult = (id, periodId) => {
        let modal = resultsModalRef.current;

        modal.open(id, periodId);

        modal.onClose(() => {
            handleShowPeriods(id);
        })
    }

    return (
        <>
            <Question
                ref={deleteModalRef}
                mainMessage={trans('questions.delete')}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.bonusPolicies')} <Help>{fromInfo('bonus')}</Help>
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.bonusPolicies')}
                        </p>
                    </div>
                    <div className="right">
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.add')}
                        </button>
                    </div>
                </div>


                {state.loading
                    ?
                    <Skeleton height={45} count={10} />
                    :
                    <>
                        <Modal
                            ref={modalRef}
                        />

                        <Periods
                            ref={periodsModalRef}
                            handleShowResult={handleShowResult}
                        />

                        <Results
                            ref={resultsModalRef}
                        />

                        {state.data.length === 0
                            ?
                            <NoDataFound />
                            :
                            <>
                                <table className="settings">
                                    <thead>
                                        <tr>
                                            <th>
                                                {trans('labels.type')}
                                            </th>
                                            <th className="right">
                                                {trans('labels.from')}
                                            </th>
                                            <th className="right">
                                                {trans('labels.to')}
                                            </th>
                                            <th className="right">
                                                {trans('labels.period')}
                                            </th>
                                            <th className="options">
                                                {trans('labels.options')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.data.map(t =>
                                            <tr key={t.id}>
                                                <td>
                                                    {Number(t.type_id) === 1 ? trans('labels.policyByPoints') : trans('labels.policyByReservations')}
                                                </td>
                                                <td className="right">
                                                    {t.from_dmy}
                                                </td>
                                                <td className="right">
                                                    {t.to_dmy}
                                                </td>
                                                <td className="right">
                                                    {plural('periods.' + t.repeat_period, t.repeat_after, {
                                                        count: t.repeat_after
                                                    })}
                                                </td>

                                                <td className="options">
                                                    <Options
                                                        id={t.id}
                                                        handleEdit={handleEdit}
                                                        handleDelete={handleDelete}
                                                        handleShowResult={handleShowPeriods}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>


                                <Pagination
                                    pages={state.pages}
                                    page={state.page}
                                    handlePage={handlePage}
                                />

                            </>
                        }
                    </>
                }

            </div>
        </>
    )
}

export default BonusPolicies