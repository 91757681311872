import React from 'react';
import notFound from '../../assets/img/not-found.png'
import { trans } from '../../_providers/Translation';

function NoDataFound(props) {
    return (
        <div className="not-found">
            <img src={notFound} alt="Not Found" />
            <p>
               {trans('messages.noResultsFound')}
            </p>
        </div>
    )
}

export default NoDataFound;