import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import FilterTabs from './partials/FilterTabs';
import { useHistory, useLocation } from 'react-router-dom';
import Types from './types/Index';
import SubscriptionTypes from './subscription-types/Index';
import Calendar from './calendar/Index';
import { trans } from '../../../_providers/Translation';
import { useAuthDataContext } from '../../../_providers/Auth';

function TypesIndex(props, ref) {

    const auth = useAuthDataContext();
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search)

    const originalTabs = [
        {
            id: 'companies',
            name: trans('tabs.contractors'),
            component: Types,
            hasRef: true
        },
        {
            id: 'subscriptions',
            name: trans('tabs.subscriptions'),
            component: SubscriptionTypes
        },
    ];

    const tab = searchParams.get('types') ? searchParams.get('types') : originalTabs[0].id;

    const [tabs, setTabs] = useState(originalTabs);

    const [state, setState] = useState({
        tab: tab,
        index: null,
        loading: true,
        data: null
    });

    const componentRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleAdd();
        }
    }));

    // тотално изтрещял
    useEffect(() => {
        let newTabs = originalTabs;

        if (state.tab !== 'companies') {
            newTabs.push({
                id: 'calendar',
                name: trans('tabs.calendar'),
                component: Calendar
            });
        }

        setTabs(newTabs);
    }, [state.tab])

    useEffect(() => {

        setState(prev => ({
            ...prev,
            loading: true,
            tab: tab,
            index: findTabIndex(tab)
        }));

    }, []);

    const handleTab = id => {

        let index = findTabIndex(id);

        setState(prev => ({
            ...prev,
            tab: id,
            index: index
        }));

        searchParams.set('types', id);

        history.push('?' + searchParams.toString());
    }

    const findTabIndex = id => {
        let index = tabs.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    const handleAdd = () => {
        let modal = componentRef.current;

        if (modal) {
            modal.add();
        }
    }

    return (
        <>

            <FilterTabs
                tabs={tabs}
                tab={state.tab}
                handleTab={handleTab}
                smallTabs
            />

            {tabs[state.index] &&
                React.createElement(tabs[state.index].component, {
                    ref: componentRef
                })
            }

        </>
    )
}

export default forwardRef(TypesIndex);