import React, { useState, useEffect, useRef } from 'react'
import Api from '../../../../_helpers/Api'
import { useParams, useHistory } from 'react-router-dom'
import { useAuthDataContext } from '../../../../_providers/Auth'
import { trans } from '../../../../_providers/Translation';

//images
import loader from '../../../../assets/img/loader.svg'

function History(props) {
    const auth = useAuthDataContext()
    const params = useParams();

    const [state, setState] = useState({
        loading: true,
        data: null
    });

    useEffect(() => {
        Api.get('reservations/history?id=' + params.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    loading: false,
                }));
            });
    }, []);

    return (
        state.loading ?
            <img src={loader} alt="loading" />
            :
            <>
                <div className="pannel">
                    <h3>

                        {trans('random.creationDate')}
                    </h3>
                    <div className="table-scroller">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {trans('tables.createdBy')}
                                    </th>
                                    <th>
                                        {trans('tables.date')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {state.data.created_by
                                            ? `${state.data.created_by.name} ${state.data.created_by.lastname}`
                                            : '-'
                                        }
                                    </td>
                                    <td>
                                        {state.data.created_date}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="pannel">
                    <h3>
                        SMS/Email
                    </h3>
                    {state.data.sentsmsmails.length === 0
                        ?
                        <div className="alert warning">
                            {trans('messages.noSavedInfo')}
                        </div>
                        :
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            {trans('tables.to')}
                                        </th>
                                        <th>
                                            {trans('tables.topic')}
                                        </th>
                                        <th>
                                            {trans('tables.date')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.sentsmsmails.map(r =>
                                        <tr key={'ssm-' + r.id}>
                                            <td>
                                                {r.to}
                                            </td>
                                            <td>
                                                {r.subject}
                                            </td>
                                            <td>
                                                {r.date}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div className="pannel">
                    <h3>
                        {trans('random.employeeReport')}
                    </h3>
                    {state.data.reports.length === 0
                        ?
                        <div className="alert warning">
                            {trans('messages.noSavedInfo')}
                        </div>
                        :
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            {trans('tables.date')}
                                        </th>
                                        <th>
                                            {trans('tables.report')}
                                        </th>
                                        <th>
                                            {trans('tables.employee')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {state.data.reports.map(r =>
                                        <tr key={'r-' + r.id}>
                                            <td>
                                                {r.date_dmy}
                                            </td>
                                            <td>
                                                {r.report}
                                            </td>
                                            <td>
                                                {r.employee
                                                    ? `${r.employee.name} ${r.employee.lastname}`
                                                    : '-'
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div className="pannel">
                    <h3>
                        {trans('random.payments')}
                    </h3>
                    {state.data.payments.length === 0
                        ?
                        <div className="alert warning">
                            {trans('messages.noSavedInfo')}
                        </div>
                        :
                        <div className="table-scroller">
                            <table className="invoices">
                                <thead>
                                    <tr>
                                        <th>
                                            ID
                                        </th>
                                        <th>
                                            {trans('tables.type')}
                                        </th>
                                        <th>
                                            {trans('tables.price')}
                                        </th>
                                        <th>
                                            {trans('tables.documentNumber')}
                                        </th>
                                        <th>
                                            {trans('tables.date')}
                                        </th>
                                        <th>
                                            {trans('tables.paymentMethod')}
                                        </th>
                                        <th>
                                            {trans('tables.employee')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.payments.map(i =>
                                        <tr key={'i-' + i.id}>
                                            <td>
                                                {i.id}
                                            </td>
                                            <td>
                                                {i.type}
                                            </td>
                                            <td>
                                                {i.price_formatted} {i.currency && i.currency.name}
                                            </td>
                                            <td>
                                                {i.invoice
                                                    ?
                                                    <a href={i.invoice.full_url + '&token=' + auth.token()} target="_blank">
                                                        {i.invoice.number}
                                                    </a>
                                                    :
                                                    '-'
                                                }
                                            </td>
                                            <td>
                                                {i.created_at_dmy}
                                            </td>
                                            <td>
                                                {i.paymentmethod
                                                    ?
                                                    i.paymentmethod.translation.name
                                                    :
                                                    ''
                                                }
                                            </td>
                                            <td>
                                                {i.employee
                                                    ?
                                                    `${i.employee.name} ${i.employee.lastname}`
                                                    :
                                                    '-'
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>

                <div className="pannel">
                    <h3>
                        {trans('random.sentInvoices')}
                    </h3>
                    {state.data.sentinvoices.length === 0
                        ?
                        <div className="alert warning">
                            {trans('messages.noSavedInfo')}
                        </div>
                        :
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.to')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.topic')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.info')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.employee')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.date')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.sentinvoices.map(r =>
                                        <tr key={'si-' + r.id}>
                                            <td style={{ width: '20%' }}>
                                                {r.to}
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {r.subject}
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {r.url
                                                    ?
                                                    <a href={r.url} target="_blank">
                                                        {r.text}
                                                    </a>
                                                    :
                                                    r.text
                                                }
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {r.employee
                                                    ?
                                                    `${r.employee.name} ${r.employee.lastname}`
                                                    :
                                                    '-'
                                                }
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {r.date}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>

                <div className="pannel">
                    <h3>
                        {trans('random.sentReports')}
                    </h3>
                    {state.data.sentreports.length === 0
                        ?
                        <div className="alert warning">
                            {trans('messages.noSavedInfo')}
                        </div>
                        :
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.to')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.topic')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.info')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.employee')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.date')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.sentreports.map(r =>
                                        <tr key={'sr-' + r.id}>
                                            <td style={{ width: '20%' }}>
                                                {r.to}
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {r.subject}
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {r.url
                                                    ?
                                                    <a href={r.url} target="_blank">
                                                        {r.text}
                                                    </a>
                                                    :
                                                    r.text
                                                }
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {r.employee
                                                    ?
                                                    `${r.employee.name} ${r.employee.lastname}`
                                                    :
                                                    '-'
                                                }
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {r.date}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>

                <div className="pannel">
                    <h3>
                        {trans('random.sentOffers')}
                    </h3>
                    {state.data.sentquotes.length === 0
                        ?
                        <div className="alert warning">
                            {trans('messages.noSavedInfo')}
                        </div>
                        :
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.to')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.topic')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.info')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.employee')}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {trans('tables.date')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.sentquotes.map(r =>
                                        <tr key={'sq-' + r.id}>
                                            <td style={{ width: '20%' }}>
                                                {r.to}
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {r.subject}
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {r.url
                                                    ?
                                                    <a href={r.url} target="_blank">
                                                        {r.text}
                                                    </a>
                                                    :
                                                    r.text
                                                }
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {r.employee
                                                    ?
                                                    `${r.employee.name} ${r.employee.lastname}`
                                                    :
                                                    '-'
                                                }
                                            </td>
                                            <td style={{ width: '20%' }}>
                                                {r.date}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </>
    )
}

export default History;