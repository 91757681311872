import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useAuthDataContext } from '../../../../../_providers/Auth';
import Api from '../../../../../_helpers/Api';
import editIcon from '../../../../../assets/img/icons/edit-yellow.png';
import Modal from './modals/StorageStatus';
import Skeleton from 'react-loading-skeleton';
import Options from './StorageStatusesOptions';
import { StorageStatusRow } from './StorageStatusRow.js';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import NoDataFound from '../../../../partials/NoDataFound'
import { trans } from '../../../../../_providers/Translation';

function StorageStatuses(props) {

    const auth = useAuthDataContext();

    const modalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: [],
        update: false,
        list: []
    });

    let list = [];

    useEffect(() => {
        loadSettings();
    }, [state.update]);

    const loadSettings = () => {
        Api.post('storage/statuses?partner_id=' + auth.partnerId())
            .then(res => {

                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data,
                    list: list
                }));

            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleAdd = () => {
        let modal = modalRef.current;

        modal.open();

        modal.onSave(data => {
            setState(prev => ({
                ...prev,
                data: prev.data.concat(data)
            }));

            if (typeof props.handleRefresh === 'function') {
                props.handleRefresh();
            }
        });

    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const handleEdit = id => {
        let modal = modalRef.current;

        modal.edit(id);

        modal.onSave(data => {
            handleUpdate();

            if (typeof props.handleRefresh === 'function') {
                props.handleRefresh();
            }
        });
    }

    const handleDelete = id => {
        Api.post('storage/statuses/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                handleUpdate();

                if (typeof props.handleRefresh === 'function') {
                    props.handleRefresh();
                }
            }
        });
    }

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = state.data[dragIndex]
            setState(prev => ({
                ...prev,
                data: update(state.data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                }),
            }))
        },
        [state.data],
    )

    const renderCard = (data, index) => {
        return (
            <StorageStatusRow
                key={data.id}
                index={index}
                id={data.id}
                data={data}
                state={state.data}
                moveCard={moveCard}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
            />
        )
    }

    return (
        <div style={{ width: '100%' }}>
            <Modal
                ref={modalRef}
            />

            <div className="head">
                <h4>{trans('headings.storageStatuses')}</h4>

                <button
                    className="button button-add button-small"
                    onClick={handleAdd}
                >
                    {trans('buttons.add')}
                </button>
            </div>

            {state.loading
                ?
                <Skeleton height={35} count={10} />
                :
                <>
                    {state.data.length === 0
                        ?
                        <NoDataFound />
                        :
                        <div className="table-scroller">
                            <table className="settings">
                                <thead>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            {trans('labels.name')}
                                        </td>
                                        {/* <td>
                                        Тип
                                    </td>
                                    <td>
                                        Описание
                                    </td> */}
                                        <td className="center">
                                            {trans('labels.options')}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <DndProvider backend={HTML5Backend}>
                                        {state.data.map((t, i) => renderCard(t, i))}
                                    </DndProvider>
                                </tbody>
                            </table>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default StorageStatuses