import React, { useRef } from 'react';
import Options from './Options';

//modals
import Question from '../../../../modals/messages/Question';
import Error from '../../../../modals/messages/Error';
import EditPart from '../modals/AddEditPart';

//images
import loader from '../../../../../assets/img/loader.svg';
import ReactTooltip from 'react-tooltip';
import Api from '../../../../../_helpers/Api';
import checkIcon from '../../../../../assets/img/icons/check.png';
import crossIcon from '../../../../../assets/img/icons/close.png';
import { trans } from '../../../../../_providers/Translation'

function Table(props) {

    //refs
    const questionModalRef = useRef(null);
    const errorModalRef = useRef(null);
    const editPartModalRef = useRef(null);

    const reservation = props.reservation;

    const handleDeletePart = id => {
        let modal = questionModalRef.current;

        modal.open(id);

        modal.onSuccess(() => {
            props.handleDeletePart(id);
        })
    }

    const handleEditPart = id => {

        let modal = editPartModalRef.current;

        modal.edit(id);

        modal.onSave(part => {
            props.handleUpdatePart(part);
        })

    }

    const handleTransferAll = e => {
        let date = document.getElementById('give-date').value;

        let ids = [];

        document.querySelectorAll('.give-cb').forEach(i => {
            if (i.checked) {
                ids.push(i.value);
            }
        });

        if (ids.length) {
            Api.post('ordered-parts/transfer', {
                ids: ids,
                date: date
            }).then(res => {
                props.refreshData();
            });
        }

    }

    const handleGiveChange = e => {
        let c = e.target.checked;

        document.querySelectorAll('.give-cb').forEach(i => {
            i.checked = c;
        });
    }

    return (

        props.loading
            ?
            <img src={loader} alt="loading" />
            :
            <>
                <Question
                    ref={questionModalRef}
                    mainMessage={trans('headings.question12')}
                />

                <Error
                    ref={errorModalRef}
                    mainMessage={trans('headings.error3')}
                />

                <EditPart
                    ref={editPartModalRef}
                    reservation={reservation}
                />

                {props.data.length == 0
                    ?
                    <div className="alert warning">
                        {trans('messages.noResultsFound')}
                    </div>
                    :
                    <>
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>{trans('tables.name')}</th>
                                        <th>{trans('tables.partNumber')}</th>
                                        <th className="center">{trans('tables.location')}</th>
                                        <th className="center">{trans('tables.quantity')}</th>
                                        <th>{trans('tables.status')}</th>
                                        <th>{trans('tables.grossPrice')}</th>
                                        <th>
                                            <input
                                                type="checkbox"
                                                onChange={handleGiveChange}
                                            />
                                        </th>
                                        {props.reservation.can_modify && props.reservation.can_modify_parts &&
                                            <th className="options">{trans('tables.options')}</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data.map(row =>
                                        <tr key={'p-' + row.id}>
                                            <td>
                                                {row.id}
                                            </td>
                                            <td>
                                                {row.name}
                                            </td>
                                            <td>
                                                {row.number || '-'}
                                            </td>
                                            <td className="center">
                                                {row.total_quantity > 0 ? trans('random.inStock') : trans('random.noStock')}
                                            </td>
                                            <td className="center">
                                                {row.quantity}
                                            </td>

                                            <td>
                                                {row.status && row.status.name}

                                            </td>

                                            <td>
                                                {row.gross_price_format} {row.currency.name}
                                            </td>

                                            <td>
                                                {row.transferable
                                                    ?
                                                    <input
                                                        type="checkbox"
                                                        className="give-cb"
                                                        value={row.id}
                                                    />
                                                    :
                                                    row.given
                                                        ?
                                                        <>
                                                            <img
                                                                src={checkIcon}
                                                                alt=""
                                                                height="8"
                                                                data-tip
                                                                data-for={'tooltip-p-' + row.id}
                                                            />
                                                            <ReactTooltip
                                                                id={'tooltip-p-' + row.id}
                                                                effect="solid"
                                                            >
                                                                <div style={{ textAlign: 'center' }}>
                                                                    {trans('random.givenTo')}: {row.given_date_dmy}
                                                                    <br />
                                                                    {trans('random.accepted')}: {row.accept_date ? trans('labels.yes') : trans('labels.no')}
                                                                    <br />
                                                                    {trans('random.acceptedOn')}: {row.accept_date_dmy || 'n/a'}
                                                                </div>
                                                            </ReactTooltip>
                                                        </>
                                                        :
                                                        ''
                                                }
                                            </td>
                                            {props.reservation.can_modify && props.reservation.can_modify_parts &&
                                                <td>
                                                    <Options
                                                        id={row.id}
                                                        editable={true}
                                                        deletable={row.deletable}
                                                        handleDeletePart={handleDeletePart}
                                                        handleEditPart={handleEditPart}
                                                    />
                                                </td>
                                            }
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>
                        <br />
                        <div className="actions">
                            <input
                                id="give-date"
                                type="datetime-local"
                            />
                            <button
                                onClick={handleTransferAll}
                            >
                                {trans('random.markUp')}
                            </button>
                        </div>
                    </>
                }


            </>

    )
}

export default Table;