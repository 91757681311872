import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { trans } from '../../../../_providers/Translation'

function PreviewTabs(props) {

    const [tabs, setTabs] = useState({
        ripple: true
    })

    const handleTabs = (e, newValue) => {
        props.handleTab(newValue);
    };

    return (
        <div className="small-tabs">
            <Tabs
                value={props.tab}
                onChange={handleTabs}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs"
            >
                <Tab value="text" label={trans('labels.text')} disableRipple={tabs.ripple} />
                <Tab value="preview" label={trans('labels.preview')} disableRipple={tabs.ripple} />
            </Tabs>
        </div>
    )
}

export default PreviewTabs;