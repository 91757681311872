import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import Success from '../../../modals/messages/Success';
import Error from '../../../modals/messages/Error';
import Dropdown from '../../../partials/Dropdown';

function AddOrEditEmployeeFreeDay(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        loading: false,
        data: null,
        id: null,
        edit: false,
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    useEffect(() => {
        if (popup.edit) {
            loadData();
        }
    }, [popup.edit]);

    const loadData = () => {
        if (!popup.id) {
            return;
        }

        Api.get(`contractors/subscriptions/find?id=${popup.id}`)
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    data: res.data,
                }));
            });
    }

    const handleShow = (id = null) => {
        setPopup(prev => ({
            ...prev,
            id: id,
            edit: id ? new Date() : null,
        }));

        show();
    }

    const handleHide = e => {
        if (e) {
            e.preventDefault();
        }

        setPopup(prev => ({
            ...prev,
            id: null,
            data: null,
            edit: false,
        }));

        setValidations(null);

        hide().then(() => {
            if (typeof props.onClose === 'function') {
                props.onClose();
            }
        });

    }

    const show = async () => {
        return new Promise((resolve, reject) => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
            }));

            setTimeout(() => {
                setPopup(prev => ({
                    ...prev,
                    overlay: true,
                    modal: true
                }));

                return resolve();
            }, 50);
        });
    }

    const hide = async () => {
        return new Promise((resolve, reject) => {
            setPopup(prev => ({
                ...prev,
                modal: false,
                loading: false,
            }));

            setTimeout(() => {
                setPopup(prev => ({
                    ...prev,
                    overlay: false,
                    modal: false,
                }));

                return resolve();
            }, 50);
        });
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'partners/employees/free-days/' + (popup.id ? 'edit' : 'add');

        let data = new FormData(e.target);

        data.append('employee_id', props.employeeId);

        if (popup.id) {
            data.append('id', popup.id);
        }

        Api.post(url, data)
            .then(res => {

                handleHide();

                if (typeof props.onSave === 'function') {
                    props.onSave(res.data);
                }

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }


    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleFromDateChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                from_date: val,
                to_date: !prev.data?.to_date || prev.data?.to_date < val ? val : prev.data?.to_date,
            }
        }));
    }

    const handleToDateChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                to_date: prev.data?.from_date > val ? prev.data?.to_date : val,
            }
        }));
    }

    return (
        <>

            <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
                <form className={`${popup.modal ? 'show' : ''} popup-primary small`} onSubmit={handleSave}>

                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />

                        {trans('headings.freeDays')}

                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>

                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.fromDate')}
                            </label>
                            <input
                                type="date"
                                name="from_date"
                                value={popup.data?.from_date || ''}
                                onChange={handleFromDateChange}
                            />
                        </div>
                        <div className="col">
                            <label>
                                {trans('labels.toDate')}
                            </label>
                            <input
                                type="date"
                                name="to_date"
                                value={popup.data?.to_date || ''}
                                onChange={handleToDateChange}
                            />
                        </div>
                    </div>

                    <div className="footer align-center">
                        {popup.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="button" onClick={handleHide}>
                                    {trans('buttons.cancel')}
                                </button>
                                <button className="button button-primary" type="submit">
                                    {trans('buttons.save')}
                                </button>
                            </>
                        }
                    </div>
                </form>
            </div>
        </>
    )
}

export default forwardRef(AddOrEditEmployeeFreeDay);