import React, { useRef } from 'react';
import editIcon from '../../../../assets/img/icons/edit-yellow.png';
import { useAuthDataContext } from '../../../../_providers/Auth';
import Modal from './modals/Auth';
import { trans } from '../../../../_providers/Translation';

function Auth(props) {

    const auth = useAuthDataContext();

    const modalRef = useRef(null);

    const showModal = () => {
        let modal = modalRef.current;

        modal.show();
    }

    return (
        <div style={{ width: '100%' }}>
            <div className="head">
                <h4>{trans('headings.auth')}</h4>
                <a onClick={showModal}>
                    <img src={editIcon} alt="" />
                </a>
            </div>

            <Modal
                ref={modalRef}
            />
            <div className="table-scroller" style={{ overflow: 'hidden' }}>
                <table className="settings">

                    <tbody>
                        <tr>
                            <td>
                                <b>{trans('tables.email')}</b>
                            </td>
                            <td>
                                {auth.data.user.email}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>{trans('tables.password')}</b>
                            </td>
                            <td>
                                **********
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )

}

export default Auth