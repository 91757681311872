import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../../../_helpers/Api';

//images
import docIcon from '../../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../../assets/img/icons/close.png';
import loader from '../../../../../../assets/img/loader.svg';
import { useCurrencyContext } from '../../../../../../_providers/Currency';
import AppliancesCategoriesPricing from '../../../../../partials/AppliancesCategoriesPricing';
import infoIcon from '../../../../../../assets/img/icons/info-black.png';
import ReactTooltip from 'react-tooltip';
import { trans } from '../../../../../../_providers/Translation';

function Pricing(props, ref) {

    const currencies = useCurrencyContext();

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: {},
        transportPriceTypes: [],
        labourPriceTypes: [],
        labourPrices: {},
        loading: false,
        onSave: null
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: data => {
            setPopup(prev => ({
                ...prev,
                loading: false,
                data: data
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('settings/transport-price-types')
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    transportPriceTypes: res.data
                }));
            });

        Api.get('settings/labour-price-types')
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    labourPriceTypes: res.data
                }));
            });
    }, []);

    useEffect(() => {

        Api.get('partners/categories/prices')
            .then(res => {
                setPopup(prev => ({
                    ...prev,
                    labourPrices: res.data
                }));
            });

    }, []);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'partners/settings/edit';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave();
                }

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleCurrencyChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        let currency = currencies.find(c => Number(c.id) === Number(val));

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val,
                currency: currency
            }
        }));
    }

    const handleLabourPriceChange = e => {
        let name = e.target.name.split('[')[1].replace(']', '');
        let val = e.target.value;

        if (name.length) {

            setPopup(prev => ({
                ...prev,
                labourPrices: {
                    ...prev.labourPrices,
                    [name]: {
                        ...prev.labourPrices[name],
                        default: val
                    }
                }

            }));
        } else {

            setPopup(prev => ({
                ...prev,
                labourPrices: {
                    ...prev.labourPrices,
                    default: val
                }
            }));
        }


    }

    const handleCheckboxChange = e => {
        let name = e.target.name;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: !prev.data[name]
            }
        }));
    }

    const selectedLabourPriceIsFixed = () => {
        let type = getSelectedLabourPrice();

        if (type) {
            return type.is_fixed;
        }

        return null;
    }

    const selectedLabourPriceIsDiagnosticAndLabour = () => {
        let type = getSelectedLabourPrice();

        if (type) {
            return type.is_diagnostic_and_labour;
        }

        return null;
    }

    const selectedLabourPriceIsHourly = () => {
        let type = getSelectedLabourPrice();

        if (type) {
            return type.is_hourly;
        }

        return null;
    }

    const selectedLabourPriceIsCategory = () => {
        let type = getSelectedLabourPrice();

        if (type) {
            return type.is_category;
        }

        return null;
    }

    const getSelectedLabourPrice = () => {
        if (popup.data && popup.data.labour_price_type_id) {
            let type = popup.labourPriceTypes.filter(t => Number(t.id) === Number(popup.data.labour_price_type_id))[0];

            if (type) {
                return type;
            }
        }

        return null;
    }

    const selectedTransportPriceIsFixed = () => {
        let type = getSelectedTransportPrice();

        if (type) {
            return type.is_fixed;
        }

        return null;
    }

    const getSelectedTransportPrice = () => {
        return popup.transportPriceTypes.filter(t => Number(t.id) === Number(popup.data.transport_price_type_id))[0];
    }

    const getPartsPriceProfitThreshold = () => {
        return popup.data.parts_price_profit_threshold;
    }

    const hasPartsPriceProfitThreshold = () => {
        if (getPartsPriceProfitThreshold() !== null) {
            return true
        }

        return false;
    }

    const createMarkup = str => {
        return { __html: str };
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary large`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.pricing')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>


                <div className="popup-container x2">
                    {/* col1 */}
                    <div className="col">

                        <div className="row">
                            <div className="col">
                                <label>
                                    {trans('labels.currency')}
                                </label>

                                <select
                                    name="currency_id"
                                    value={popup.data ? popup.data.currency_id : ''}
                                    className={validations && validations.currency_id ? 'validate' : ''}
                                    onChange={handleCurrencyChange}
                                >
                                    <option value="" disabled></option>
                                    {currencies.map(t =>
                                        <option key={'c-' + t.id} value={t.id}>{t.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col">
                                <label>
                                    {trans('labels.dds')} %
                                </label>

                                <input
                                    type="number"
                                    min="0"
                                    max="100"
                                    step="0.01"
                                    name="vat"
                                    value={popup.data ? popup.data.vat : ''}
                                    className={validations && validations.vat ? 'validate' : ''}
                                    onChange={handleInputChange}
                                />

                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    {trans('labels.incomeTax')} %
                                </label>

                                <input
                                    type="number"
                                    min="0"
                                    max="100"
                                    step="0.01"
                                    name="profit_tax"
                                    value={popup.data ? popup.data.profit_tax : ''}
                                    className={validations && validations.profit_tax ? 'validate' : ''}
                                    onChange={handleInputChange}
                                />

                            </div>
                        </div>

                        {hasPartsPriceProfitThreshold() &&
                            <>
                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.partsPriceProfitThreshold')}
                                        </label>

                                        <input
                                            type="number"
                                            min="0"
                                            // max="100"
                                            step="1"
                                            name="parts_price_profit_threshold"
                                            value={popup.data ? popup.data.parts_price_profit_threshold : ''}
                                            className={validations && validations.parts_price_profit_threshold ? 'validate' : ''}
                                            onChange={handleInputChange}
                                        />

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.partsPriceProfitBelow', {
                                                price: getPartsPriceProfitThreshold() + ' ' + popup.data?.currency?.name
                                            })} %
                                        </label>

                                        <input
                                            type="number"
                                            min="0"
                                            // max="100"
                                            step="1"
                                            name="parts_price_profit_below_threshold"
                                            value={popup.data ? popup.data.parts_price_profit_below_threshold : ''}
                                            className={validations && validations.parts_price_profit_below_threshold ? 'validate' : ''}
                                            onChange={handleInputChange}
                                        />

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.partsPriceProfitAbove', {
                                                price: getPartsPriceProfitThreshold() + ' ' + popup.data?.currency?.name
                                            })} %
                                        </label>

                                        <input
                                            type="number"
                                            min="0"
                                            // max="100"
                                            step="1"
                                            name="parts_price_profit_above_threshold"
                                            value={popup.data ? popup.data.parts_price_profit_above_threshold : ''}
                                            className={validations && validations.parts_price_profit_above_threshold ? 'validate' : ''}
                                            onChange={handleInputChange}
                                        />

                                    </div>

                                </div>
                            </>
                        }

                        <div className="row">
                            <div className="col">
                                <label>
                                    {trans('labels.transportPriceType')}
                                </label>

                                <select
                                    name="transport_price_type_id"
                                    value={popup.data ? popup.data.transport_price_type_id : ''}
                                    className={validations && validations.transport_price_type_id ? 'validate' : ''}
                                    onChange={handleInputChange}
                                >
                                    {popup.transportPriceTypes.map(t =>
                                        <option key={'t-' + t.id} value={t.id}>{t.translation.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    {selectedTransportPriceIsFixed() ? trans('labels.transportPriceFixedPrice') : trans('labels.transportPriceStandard')}
                                </label>

                                <input
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    name="transport_price"
                                    value={popup.data ? popup.data.transport_price : ''}
                                    className={validations && validations.transport_price ? 'validate' : ''}
                                    onChange={handleInputChange}
                                />

                            </div>
                        </div>

                        <div className="row">
                            <label className="flex">
                                <input
                                    type="checkbox"
                                    name="recharge"
                                    value="1"
                                    checked={popup.data && popup.data.recharge ? true : false}
                                    onChange={handleCheckboxChange}
                                />
                                <span>
                                    {trans('labels.recharge')}
                                    <img className="info-icon" data-tip data-for="show-info" src={infoIcon} />
                                    <ReactTooltip
                                        id="show-info"
                                        place="top"
                                        effect="solid"
                                    >
                                        <span dangerouslySetInnerHTML={createMarkup(trans('random.rechargeInfo2'))} />
                                    </ReactTooltip>
                                </span>
                            </label>
                        </div>
                    </div>

                    {/* col2 */}
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <label>
                                    {trans('labels.labourPriceType')}
                                </label>

                                <select
                                    name="labour_price_type_id"
                                    value={popup.data ? popup.data.labour_price_type_id : ''}
                                    className={validations && validations.labour_price_type_id ? 'validate' : ''}
                                    onChange={handleInputChange}
                                >
                                    {popup.labourPriceTypes.map(t =>
                                        <option key={'lt-' + t.id} value={t.id}>{t.translation.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        {/* фиксирана */}
                        <div style={{ display: selectedLabourPriceIsFixed() ? 'block' : 'none' }}>
                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('labels.labourPriceStandard')}
                                    </label>

                                    <input
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        name="prices[3][]"
                                        value={popup.labourPrices && popup.labourPrices[3] ? popup.labourPrices[3].default : ''}
                                        className={validations && validations.labour_price_standard ? 'validate' : ''}
                                        onChange={handleLabourPriceChange}
                                    />

                                </div>
                            </div>

                            {/* <label>
                                    По категории и марки
                                </label> */}
                            <div className="scrollable" style={{ maxHeight: '200px', padding: '0 10px 0 0' }}>
                                <AppliancesCategoriesPricing
                                    prices={popup.labourPrices[3] ? popup.labourPrices[3].categories : []}
                                    name="prices[3]"
                                    brandsName="prices_brands[3]"
                                />
                            </div>

                        </div>

                        {/* диагностика и труд */}
                        <div style={{ display: selectedLabourPriceIsDiagnosticAndLabour() ? 'block' : 'none' }}>
                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('labels.labourPriceDiagnostic')}
                                    </label>

                                    <input
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        name="prices[4][]"
                                        value={popup.labourPrices && popup.labourPrices[4] ? popup.labourPrices[4].default : ''}
                                        className={validations && validations.labour_price_diagnostic ? 'validate' : ''}
                                        onChange={handleLabourPriceChange}
                                    />

                                </div>
                            </div>

                            {/* <label>
                                        По категории и марки
                                    </label> */}
                            {/* <div className="scrollable" style={{ maxHeight: '200px', padding: '0 10px 0 0' }}> */}
                            <AppliancesCategoriesPricing
                                prices={popup.labourPrices[4] ? popup.labourPrices[4].categories : []}
                                name="prices[4]"
                                brandsName="prices_brands[4]"
                            />
                            {/* </div> */}

                            <hr />

                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('labels.labourPriceContinue')}
                                    </label>

                                    <input
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        name="prices[5][]"
                                        value={popup.labourPrices && popup.labourPrices[5] ? popup.labourPrices[5].default : ''}
                                        className={validations && validations.labour_price ? 'validate' : ''}
                                        onChange={handleLabourPriceChange}
                                    />

                                </div>
                            </div>

                            {/* <label>
                                        По категории и марки
                                    </label> */}
                            {/* <div className="scrollable" style={{ maxHeight: '200px', padding: '0 10px 0 0' }}> */}
                            <AppliancesCategoriesPricing
                                prices={popup.labourPrices[5] ? popup.labourPrices[5].categories : []}
                                name="prices[5]"
                                brandsName="prices_brands[5]"
                            />
                            {/* </div> */}

                            <hr />

                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('labels.labourPriceInstall')}
                                    </label>

                                    <input
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        name="prices[7][]"
                                        value={popup.labourPrices && popup.labourPrices[7] ? popup.labourPrices[7].default : ''}
                                        className={validations && validations.labour_price_installation ? 'validate' : ''}
                                        onChange={handleLabourPriceChange}
                                    />

                                </div>
                            </div>

                            <div className="row"></div>

                            {/* <label>
                                        По категории и марки
                                    </label> */}
                            {/* <div className="scrollable" style={{ maxHeight: '200px', padding: '0 10px 0 0' }}> */}
                            <AppliancesCategoriesPricing
                                prices={popup.labourPrices[7] ? popup.labourPrices[7].categories : []}
                                name="prices[7]"
                                brandsName="prices_brands[7]"
                            />
                            {/* </div> */}
                        </div>


                        {/* почасово */}
                        <div style={{ display: selectedLabourPriceIsHourly() ? 'block' : 'none' }}>
                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('labels.labourPriceHourlyStandard')}
                                    </label>

                                    <input
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        name="prices[1][]"
                                        value={popup.labourPrices && popup.labourPrices[1] ? popup.labourPrices[1].default : ''}
                                        // className={validations && validations.labour_price_hourly ? 'validate' : ''}
                                        onChange={handleLabourPriceChange}
                                    />

                                </div>
                            </div>

                            {/* <label>
                                        По категории и марки
                                </label> */}
                            <div className="scrollable" style={{ maxHeight: '200px', padding: '0 10px 0 0' }}>
                                <AppliancesCategoriesPricing
                                    prices={popup.labourPrices[1] ? popup.labourPrices[1].categories : []}
                                    name="prices[1]"
                                    brandsName="prices_brands[1]"
                                />
                            </div>

                        </div>

                    </div>
                </div>


                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Pricing);