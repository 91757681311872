import React, { useEffect } from 'react';
import { trans } from '../../../_providers/Translation'

function Reservation(props) {

    return (
        <table>
            <thead>
                <tr>
                    <th>{trans('tables.activity')}</th>
                    <th>{trans('tables.date')}</th>
                    <th>{trans('tables.employee')}</th>
                    <th>{trans('tables.setTo')}</th>
                    <th>{trans('tables.client')}</th>
                    <th>{trans('tables.visitDate')}</th>
                    <th>{trans('tables.visitHour')}</th>
                    <th>{trans('tables.type')}</th>
                    <th>{trans('tables.initialStatus')}</th>
                    <th>{trans('tables.finalStatus')}</th>
                    <th>{trans('tables.finalPrice')}</th>
                    <th>{trans('tables.paidPrice')}</th>
                    <th>{trans('tables.parts')}</th>
                    <th>{trans('tables.comments')}</th>
                    <th>{trans('tables.todos')}</th>
                    <th>{trans('tables.images')}</th>
                    <th>{trans('tables.invoices')}</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((h, i) => (
                    <tr key={i} className={[h.actual ? 'actual' : h.selected ? 'selected' : '']}>
                        <td>
                            {h.event}
                        </td>
                        <td>{h.date}</td>
                        <td>{h.causer && `${h.causer.name} ${h.causer.lastname}`}</td>
                        <td>
                            {h.subject.performer
                                ?
                                h.subject.performer.type === 'App\\Employee'
                                    ? `${h.subject.performer.name} ${h.subject.performer.lastname}`
                                    : `${h.subject.performer.name}`
                                : '-'}
                        </td>
                        <td>
                            {h.subject.customer
                                ? `${h.subject.customer.name} ${h.subject.customer.lastname} ${h.subject.contractor ? `(${h.subject.contractor.name})` : ''}`
                                : '-'
                            }
                        </td>
                        <td>
                            {h.subject.visit_date_dmy}
                        </td>
                        <td>
                            {h.subject.visitperiod
                                ? h.subject.visitperiod.period
                                : '-'
                            }
                        </td>
                        <td>
                            {h.subject.type
                                ? h.subject.type.name
                                : '-'
                            }
                        </td>
                        <td>
                            {h.subject.initialstatus && h.subject.initialstatus.translation
                                ? `${h.subject.initialstatus.translation.name}`
                                : '-'
                            }
                        </td>
                        <td>
                            {h.subject.finalstatus && h.subject.finalstatus.translation
                                ? `${h.subject.finalstatus.translation.name}`
                                : '-'
                            }
                        </td>
                        <td>
                            {h.subject.total_price_formatted} {h.subject.currency ? h.subject.currency.name : ''}
                        </td>
                        <td>
                            {h.subject.paid_price_formatted} {h.subject.currency ? h.subject.currency.name : ''}
                        </td>
                        <td>
                            {h.subject.parts_count || 0}
                        </td>
                        <td>
                            {h.subject.comments_count || 0}
                        </td>
                        <td>
                            {h.subject.todos_count || 0}
                        </td>
                        <td>
                            {h.subject.images_count || 0}
                        </td>
                        <td>
                            {h.subject.invoices_count || 0}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default Reservation;