import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import MultiSelect from "react-multi-select-component";

function AddOrEdit(props, ref) {

    const defaultData = {
        selectedPerms: []
    };

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: defaultData,
        perms: [],
        loading: false,
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: defaultData
            }));
            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                data: defaultData,
                loading: false
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            Api.get(`admins/find?id=${popup.editId}`)
                .then(res => {

                    let data = res.data;
                    let perms = res.data.perms || [];
                    let selectedPerms = [];

                    perms.map(m => {
                        selectedPerms.push({
                            label: m.translation ? m.translation.name : '',
                            value: m.id
                        });
                    });

                    data.selectedPerms = selectedPerms;

                    setPopup(prev => ({
                        ...prev,
                        data: data,
                    }));

                });
        }

    }, [popup.edit]);

    useEffect(() => {
        Api.get('profiles/permissions/available/admin')
            .then(res => {

                let data = [];

                res.data.map(m => {
                    data.push({
                        label: m.translation ? m.translation.name : m.name,
                        value: m.id
                    });
                });

                setPopup(prev => ({
                    ...prev,
                    perms: data
                }));
            });
    }, []);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();


        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'admins/edit';
        } else {
            url = 'admins/add';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    props.handleUpdate(res.data)
                } else {
                    props.handleCreate(res.data);
                }

                handleHide(e);

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handlePermChange = data => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                selectedPerms: data
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary medium`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {popup.edit ? trans('headings.edit-user') : trans('headings.add-user')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container x2">
                    <div className="col">
                        <label htmlFor="name">
                            {trans('labels.name')}
                        </label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            value={popup.data && popup.data.name ?
                                popup.data.name
                                :
                                ''
                            }
                            className={validations && validations.name ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="lastname">
                            {trans('labels.lastname')}
                        </label>
                        <input
                            id="lastname"
                            type="text"
                            name="lastname"
                            value={popup.data && popup.data.lastname ?
                                popup.data.lastname
                                :
                                ''
                            }
                            className={validations && validations.lastname ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container x2">
                    <div className="col">
                        <label htmlFor="email">
                            {trans('labels.email')}
                        </label>
                        <input
                            id="email"
                            type="text"
                            name="email"
                            value={popup.data && popup.data.email ?
                                popup.data.email
                                :
                                ''
                            }
                            className={validations && validations.email ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="phone">
                            {trans('labels.phone')}
                        </label>
                        <input
                            id="phone"
                            type="text"
                            name="phone"
                            value={popup.data && popup.data.phone ?
                                popup.data.phone
                                :
                                ''
                            }
                            className={validations && validations.phone ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container x2">
                    <div className="col">
                        <label htmlFor="password">
                            {trans('labels.password')}
                        </label>
                        <input
                            id="password"
                            type="password"
                            name="password"
                            className={validations && validations.password ? 'validate' : ''}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="password_confirmation">
                            {trans('labels.confirmPassword')}
                        </label>
                        <input
                            id="password_confirmation"
                            type="password"
                            name="password_confirmation"
                            className={validations && validations.password_confirmation ? 'validate' : ''}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.accessTo')}
                        </label>

                        <MultiSelect
                            options={popup.perms}
                            value={popup.data.selectedPerms}
                            onChange={handlePermChange}
                            hasSelectAll={false}
                            disableSearch={true}
                            className="dropdown-top"
                            overrideStrings={{
                                "selectSomeItems": trans('labels.choose'),
                                "allItemsAreSelected": trans('labels.allSelected'),
                                "selectAll": trans('labels.selectAll'),
                                "search": trans('labels.search')
                            }}
                        />

                        {popup.data.selectedPerms.map(s =>
                            <input key={'m-' + s.value} type="hidden" name="perms[]" value={s.value} />
                        )}
                    </div>
                </div>


                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);