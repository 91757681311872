import React, { useState, useEffect } from 'react';
import Api from '../../../../_helpers/Api';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router-dom';
import { trans } from '../../../../_providers/Translation';
import { useAuthDataContext } from '../../../../_providers/Auth';

function LatestReservations() {

    const auth = useAuthDataContext();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [state, setState] = useState({
        loading: true,
        data: []
    });

    useEffect(() => {
        Api.get('reservations/latest')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }));
            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }, []);

    const handleShowUpcomingReservations = performer => {

        if (!auth.module('gps')) {
            return;
        }

        if (performer && performer.type === 'App\\Employee') {
            searchParams.set('show_upcoming_reservations', performer.id);

            let url = '?' + searchParams.toString();

            history.replace(url);
        }

    }

    return (
        <div className="pannel">
            <h3>
                {trans('random.lastReservations')}
            </h3>
            {state.loading
                ?
                <Skeleton height={55} count={5} />
                :
                state.data.length === 0
                    ?
                    <div className="alert info">
                        {trans('messages.noReservationsYet')}
                    </div>
                    :
                    <div className="table-scroller">
                        <table className="table-primary">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>{trans('tables.number')}</th>
                                    <th>{trans('tables.setTo')}</th>
                                    <th>{trans('tables.client')}</th>
                                    <th>{trans('tables.initialStatus')}</th>
                                    <th>{trans('tables.finalStatus')}</th>
                                    <th style={{ textAlign: 'right' }}>{trans('tables.finalPrice')}</th>
                                    <th style={{ textAlign: 'right' }}>{trans('tables.paidPrice')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.data.map(row =>
                                    <tr key={'r-' + row.id}>
                                        <td>
                                            {row.id}
                                        </td>
                                        <td>
                                            <a
                                                href={'/reservations/edit/' + row.id} target="_blank"
                                                className="btn"
                                                style={{ background: row.color, color: row.text_color }}
                                            >
                                                {row.reference_code}
                                            </a>
                                        </td>
                                        <td>

                                            <a href="#" onClick={() => handleShowUpcomingReservations(row.performer)}>
                                                {row.performer
                                                    ?
                                                    row.performer.type === 'App\\Employee'
                                                        ? `${row.performer.name} ${row.performer.lastname}`
                                                        : `${row.performer.name}`
                                                    :
                                                    '-'
                                                }
                                            </a>

                                        </td>
                                        <td>
                                            {row.customer?.name} {row.customer?.lastname} {row.contractor && `(${row.contractor.name})`}
                                        </td>
                                        <td>
                                            {row.initialstatus && row.initialstatus.translation.name}
                                        </td>
                                        <td>
                                            {row.finalstatus && row.finalstatus.translation.name}
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                            {row.total_price_formatted}
                                            {row.currency ? row.currency.name : ''}
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                            {row.paid_price_formatted}
                                            {row.currency ? row.currency.name : ''}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    )
}

export default LatestReservations;