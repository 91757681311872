import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { fromInfo, trans } from '../../../_providers/Translation';
import FilterTabs from './partials/FilterTabs';
import List from './List';
import Stats from './Stats';
import Help from '../../partials/Help';

function Index() {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const tabs = [
        {
            id: 'list',
            name: trans('tabs.list'),
            component: List
        },
        {
            id: 'stats',
            name: trans('tabs.stats'),
            component: Stats
        },
    ];

    const tab = searchParams.get('tab') ? searchParams.get('tab') : tabs[0].id;

    const componentRef = useRef(null);

    const [state, setState] = useState({
        tab: tabs[0].id,
        index: 0,
        loading: true,
        data: null
    });

    useEffect(() => {

        if (tab != state.tab) {
            setState(prev => ({
                ...prev,
                loading: true,
                tab: tab,
                index: findTabIndex(tab)
            }));
        }

    }, [location.search]);

    const handleTab = id => {

        let index = findTabIndex(id);

        setState(prev => ({
            ...prev,
            tab: id,
            index: index
        }));

        history.push('?tab=' + id);
    }

    const findTabIndex = id => {
        let index = tabs.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    return (
        <>

            <div className="wrapper">

                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.voiceCalls')} <Help>{fromInfo('voicecalls')}</Help>
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.voiceCalls')}
                        </p>
                    </div>
                    <div className="right">

                    </div>
                </div>

                <FilterTabs
                    tabs={tabs}
                    tab={state.tab}
                    handleTab={handleTab}
                />

                {React.createElement(tabs[state.index].component, {
                    ref: componentRef
                })}

            </div>

        </>
    )

}

export default Index;