import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';
import editIcon from '../../../../assets/img/icons/edit-yellow.png';
import Modal from './AddOrEdit';
import Skeleton from 'react-loading-skeleton';
import Options from './Options';
import Pagination from '../../../Pagination';
import { useHistory, useLocation } from 'react-router-dom';
import NoDataFound from '../../../partials/NoDataFound';
import Question from '../../../modals/messages/Question';
import { trans } from '../../../../_providers/Translation';

function Index(props, ref) {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const deleteModalRef = useRef(null);
    const modalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        placeholders: {},
        filter: {},
        setFilter: false,
        update: false,
    });

    useImperativeHandle(ref, () => ({
        add: () => {
            handleAdd();
        }
    }));

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                searchParams.set(filter[0], filter[1]);
            });

            history.push('?' + searchParams.toString());
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    const loadData = () => {
        let url = 'parts-status-sms/all?page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                    placeholders: res.data.placeholders,
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleAdd = () => {
        let modal = modalRef.current;

        modal.open();

        modal.onSave(data => {
            setState(prev => ({
                ...prev,
                data: [data, ...prev.data]
            }));
        });

    }

    const handleEdit = id => {
        let modal = modalRef.current;

        modal.edit(id);

        modal.onSave(data => {
            handleUpdate();
        });

    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const handleDelete = id => {
        let modal = deleteModalRef.current;

        modal.open(id);
        modal.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleDestroy = id => {
        Api.post('parts-status-sms/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                handleUpdate();
            }
        });
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    const createMarkup = str => {
        return { __html: str };
    }

    return (
        <>
            <Question
                ref={deleteModalRef}
                mainMessage={trans('questions.delete')}
            />

            <Modal
                ref={modalRef}
                placeholders={state.placeholders}
            />

            {state.loading
                ?
                <Skeleton height={45} count={10} />
                :
                <>
                    <div className="alert info">
                        {trans('random.partsSms')}
                    </div>

                    {state.data.length === 0
                        ?
                        <NoDataFound />
                        :
                        <>
                            <div className="table-scroller">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '100%' }}>
                                                {trans('labels.status')}
                                            </th>
                                            <th className="options">
                                                {trans('labels.options')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.data.map(t =>
                                            <tr key={t.id}>
                                                <td style={{ width: '100%' }}>
                                                    {t.status && t.status.translation ? t.status.translation.name : t.status.name}
                                                </td>

                                                <td className="options">
                                                    <Options
                                                        id={t.id}
                                                        handleEdit={handleEdit}
                                                        handleDelete={handleDelete}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                pages={state.pages}
                                page={state.page}
                                handlePage={handlePage}
                            />
                        </>
                    }
                </>
            }
        </>
    )

}

export default forwardRef(Index);