import React, { useEffect, useState, useRef } from 'react'

function Ckeditor(props) {

    let value = props.value;

    const toolbarRef = useRef(null);
    const editorRef = useRef(null);
    const className = Math.random().toString().substring(3);

    const [editor, setEditor] = useState(null);
    const [data, setData] = useState('');

    useEffect(() => {
        if (typeof value !== 'undefined') {
            setData(value);
        }
    }, [value]);

    useEffect(() => {

        if (editor) {
            return;
        }

        window.DecoupledDocumentEditor
            .create(editorRef.current, {
                toolbar: {
                    items: [
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        '|',
                        'alignment',
                        '|',
                        'numberedList',
                        'bulletedList',
                        '|',
                        'indent',
                        'outdent',
                        '|'
                    ]
                },
                language: 'bg',
                licenseKey: '',

            }).then(instance => {


                // Set a custom container for the toolbar.
                toolbarRef.current.appendChild(instance.ui.view.toolbar.element);
                document.querySelector('.ck-toolbar').classList.add('ck-reset_all');

                instance.model.document.on('change', (e) => {

                    // console.log('change', editor.getData())
                    setData(instance.getData());
                })

                setEditor(instance);

            }).catch(error => {
                console.warn('Build id: 4kvi2rj5yrxy-mveog3r06b7r');
                console.error(error);
            });

        return () => {

            if (editor) {

                editor.destroy();

                // Remove the toolbar from DOM.
                // window.editor.ui.view.toolbar.element.remove();

                // Remove the editable from DOM.
                // window.editor.ui.view.editable.element.remove();

                setEditor(null);

                console.log('Editor was destroyed');

                document.querySelectorAll('.document-editor__toolbar-' + className).forEach(e => {
                    e.innerHTML = '';
                });

                toolbarRef.current.innerHTML = '';

                toolbarRef.current.remove();
                editorRef.current.remove();

            }
        }

    }, []);

    useEffect(() => {
        if (typeof value !== 'undefined') {

            if (editor) {
                // console.log(value)
                editor.setData(value);
                // console.log(window.editor.getData());
            }
        }

    }, [value, props.adkey, editor]);


    return (
        <>
            <div ref={toolbarRef} className={['document-editor__toolbar', className].join('-')}></div>
            <div ref={editorRef} className={'editor'}></div>

            <textarea name={props.name} value={data} hidden readOnly />
        </>
    )
}

export default React.memo(Ckeditor, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});