import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Api from '../../_helpers/Api';
import docIcon from '../../assets/img/icons/document.png';
import closeIcon from '../../assets/img/icons/close.png';
import loader from '../../assets/img/loader.svg';
import { trans } from '../../_providers/Translation';

function InvoicePayment(props, ref) {

    const [state, setState] = useState({
        id: null,
        methods: [],
        overlay: false,
        modal: false,
        loading: false,
        onSuccess: null,
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        open: id => {
            handleOpen(id)
        },
        close: () => {
            handleClose()
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        }
    }));

    const handleOpen = (id) => {

        if (state.methods.length === 0) {
            let url = 'payments/methods';

            Api.get(url)
                .then(res => {
                    setState(prev => ({
                        ...prev,
                        methods: res.data
                    }));
                });
        }

        setState(prev => ({
            ...prev,
            id: id,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)

    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            id: null,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 50)

        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let data = new FormData(e.target);

        Api.post('invoices/mark-as-paid', data)
            .then(res => {
                if (res.data.success) {
                    handleClose();

                    if (typeof state.onSuccess === 'function') {
                        state.onSuccess();
                    }
                }
            })
            .catch(error => {

                setState(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`} onSubmit={handleSave}>

            <form className={`${state.modal ? 'show' : ''} popup-primary small`}>
                {Array.isArray(state.id)
                    ?
                    state.id.map(id =>
                        <input key={'pid' + id} type="hidden" name="id[]" value={id} />
                    )
                    :
                    <input type="hidden" name="id" value={state.id} />
                }

                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.invoicePayment')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleClose} />
                </h2>


                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.method')}
                        </label>

                        <select
                            name="method_id"
                            className={validations && validations.method_id ? 'validate' : ''}
                        >
                            {state.methods.map(el =>
                                <option key={el.id} value={el.id}>
                                    {el.translation ? el.translation.name : el.name}
                                </option>
                            )}

                        </select>
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.paymentDate')}
                        </label>

                        <input
                            type="date"
                            name="date"
                            className={validations && validations.date ? 'validate' : ''}
                        />
                    </div>
                </div>


                <div className="footer align-center">
                    {state.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleClose}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(InvoicePayment);