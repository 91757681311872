import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../../../_helpers/Api';

//images
import docIcon from '../../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../../assets/img/icons/close.png';
import loader from '../../../../../../assets/img/loader.svg';
import deleteIcon from '../../../../../../assets/img/icons/delete-red.png';
import { trans } from '../../../../../../_providers/Translation';
import NoDataFound from '../../../../../partials/NoDataFound';

function VisitPeriods(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: [],
        loading: false,
        onSave: null
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: data => {
            setPopup(prev => ({
                ...prev,
                loading: false,
                data: data
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'reservations/visitperiods/set';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave();
                }

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const findIndex = id => {
        let index = popup.data.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    const handleInputChange = (e, id, key) => {
        let val = e.target.value;

        let index = findIndex(id);

        let period = popup.data[index];

        if (period) {
            let periods = popup.data;

            period[key] = val;

            periods[index] = period;

            setPopup(prev => ({
                ...prev,
                data: periods
            }));
        }

    }

    const handleAdd = e => {
        e.preventDefault();

        let period = {
            id: Math.random().toString().substring(3),
            was_recently_created: true,
            from: '',
            to: ''
        };

        setPopup(prev => ({
            ...prev,
            data: prev.data.concat(period)
        }));
    }

    const handleRemove = id => {
        let filtered = popup.data.filter(p => {
            return String(p.id) !== String(id);
        });

        setPopup(prev => ({
            ...prev,
            data: filtered
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary small`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.visitPeriods')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                {popup.data.length === 0
                    ?
                    <NoDataFound />
                    :

                    <div className="scrollable" style={{ padding: 0 }}>
                        {popup.data.map(p =>
                            <div key={p.id} className="row">
                                <input type="hidden" name="period_id[]" value={p.id} />

                                <div className="col" style={{ paddingRight: 10 }}>
                                    <label>
                                        {trans('labels.from')}
                                    </label>
                                    <input
                                        type="time"
                                        name="from[]"
                                        value={p.from}
                                        onChange={e => handleInputChange(e, p.id, 'from')}
                                    />
                                </div>
                                <div className="col" style={{ paddingRight: 10 }}>
                                    <label>
                                        {trans('labels.to')}
                                    </label>
                                    <input
                                        type="time"
                                        name="to[]"
                                        value={p.to}
                                        onChange={e => handleInputChange(e, p.id, 'to')}
                                    />
                                </div>
                                <div className="col auto" style={{
                                    paddingTop: '23px'
                                }}>
                                    <a
                                        href="javascript:void(0)"
                                        onClick={() => handleRemove(p.id)}
                                    >
                                        <img
                                            src={deleteIcon}
                                        />
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                }

                <br />
                <button className="button-small" onClick={handleAdd}>
                    {trans('buttons.add')}
                </button>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(VisitPeriods);