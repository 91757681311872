import React, { useRef, useState, useEffect } from 'react';
import { useAuthDataContext } from '../../../../../_providers/Auth';
import Api from '../../../../../_helpers/Api';
import editIcon from '../../../../../assets/img/icons/edit-yellow.png';
import Modal from './modals/Others';
import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../../../_providers/Translation';

function Others(props) {

    const auth = useAuthDataContext();

    const modalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: [],
        update: false,
    });

    useEffect(() => {
        loadSettings();
    }, [state.update]);

    const loadSettings = () => {
        Api.get('partners/settings/all?id=' + auth.partnerId())
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const showModal = () => {
        let modal = modalRef.current;

        modal.show(state.data);

        modal.onSave(() => {
            handleUpdate();

            if (typeof props.handleRefresh === 'function') {
                props.handleRefresh();
            }
        });
    }

    const createMarkup = str => {
        return { __html: str };
    }

    return (
        <div style={{ width: '100%' }}>
            <div className="head">
                <h4>{trans('headings.otherSettings')}</h4>
                <a onClick={showModal}>
                    <img src={editIcon} alt="" />
                </a>
            </div>

            {state.loading
                ?
                <Skeleton height={35} count={5} />
                :
                <>
                    <Modal
                        ref={modalRef}
                    />
                    <div className="table-scroller">
                        <table className="settings">

                            <tbody>
                                <tr>
                                    <td>
                                        <b>{trans('labels.vatNo')}</b>
                                    </td>
                                    <td>
                                        {state.data.vat_number ? state.data.vat_number : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.mol')}</b>
                                    </td>
                                    <td>
                                        {state.data.mol ? state.data.mol : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.proformaStartNumber')}</b>
                                    </td>
                                    <td>
                                        {state.data.proforma_start_number ? state.data.proforma_start_number : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.invoiceStartNumber')}</b>
                                    </td>
                                    <td>
                                        {state.data.invoice_start_number ? state.data.invoice_start_number : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.invoiceDeadline')}</b>
                                    </td>
                                    <td>
                                        {state.data.invoice_deadline ? state.data.invoice_deadline : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.invoiceTerms')}</b>
                                    </td>
                                    <td colSpan="2">
                                        {state.data.invoice_terms ? state.data.invoice_terms : trans('labels.notSpecified')}
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.warrantyTerms')}</b>
                                    </td>
                                    <td colSpan="2">
                                        {state.data.warranty_terms
                                            ?
                                            <div dangerouslySetInnerHTML={createMarkup(state.data.warranty_terms)} />
                                            :
                                            trans('labels.notSpecified')
                                        }
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.reservationTerms')}</b>
                                    </td>
                                    <td colSpan="2">
                                        {state.data.reservation_terms
                                            ?
                                            <div dangerouslySetInnerHTML={createMarkup(state.data.reservation_terms)} />
                                            :
                                            trans('labels.notSpecified')
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.companySignature')}</b>
                                    </td>
                                    <td colSpan="2">
                                        {state.data.company_signature
                                            ?
                                            <div dangerouslySetInnerHTML={createMarkup(state.data.company_signature)} />
                                            :
                                            trans('labels.notSpecified')
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.allowAutoReassign')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.allow_auto_reassign) === 1
                                            ?
                                            trans('labels.yes')
                                            :
                                            trans('labels.no')
                                        }
                                    </td>
                                    <td>
                                        {trans('random.allowAutoReassign')}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('labels.allowSubscriptionsPage')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.has_subscriptions_page) === 1
                                            ?
                                            trans('labels.yes')
                                            :
                                            trans('labels.no')
                                        }
                                    </td>
                                    <td>
                                        {trans('random.allowSubscriptionsPage')}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </div>
    )

}

export default Others