import React from 'react';

export function useWindowSize() {
    const [windowSize, setWindowSize] = React.useState({
        width: null,
        height: null
    });

    const changeWindowSize = () => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }

    React.useEffect(() => {
        window.addEventListener("resize", () => changeWindowSize);

        return () => {
            window.removeEventListener("resize", () => changeWindowSize);
        };
    }, []);

    return windowSize;
}
