import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import deleteIcon from '../../../../assets/img/icons/delete-red.png';
import { plural, trans } from '../../../../_providers/Translation';

function BonusPolicy(props, ref) {

    const initialData = {
        type_id: 1,
        status_rules: [
            {
                id: Math.random().toString().substring(3)
            }
        ],
        email_rules: [

        ],
        rewards: [
            {
                id: Math.random().toString().substring(3)
            }
        ],
    }

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        id: null,
        data: initialData,
        loading: false,
        dataLoading: false,
        update: false,
        onSave: null
    });

    const [statuses, setStatuses] = useState({});

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        edit: id => {
            setPopup(prev => ({
                ...prev,
                id: id,
                update: new Date().getTime()
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    useEffect(() => {
        if (popup.id) {

            setPopup(prev => ({
                ...prev,
                dataLoading: true,
            }))

            Api.get('partners/bonuspolicies/find?id=' + popup.id)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data,
                        dataLoading: false,
                    }))
                });
        }
    }, [popup.update]);

    useEffect(() => {
        Api.get('reservations/statuses')
            .then(res => {
                setStatuses(res.data);
            });
    }, []);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false,
                id: null,
                data: initialData
            }));
        }, 50);

        setValidations(null);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'partners/bonuspolicies/' + (popup.id ? 'edit' : 'add');

        let data = new FormData(e.target);

        if (popup.id) {
            data.append('id', popup.id);
        }

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave(res.data);
                }

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleDateChange = (e) => {
        let name = e.target.name + '_ymd';
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handleNestedInputChange = (e, id) => {
        let name = e.target.name.replaceAll(']', '').split('[');
        let val = e.target.value;

        let data = popup.data[name[0]];

        let index = data.findIndex(r => String(r.id) === String(id));

        if (data[index]) {
            data[index][name[1]] = val;
        }

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name[0]]: data
            }
        }));
    }

    const getRepeatNumbers = () => {
        let n = [];

        for (let i = 1; i <= 31; i++) {
            n.push(i);
        }

        return n;
    }

    const handleAddRule = e => {
        e.preventDefault();

        let rule = {
            id: Math.random().toString().substring(3),
        };

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                status_rules: prev.data.status_rules.concat(rule)
            }
        }));
    }

    const handleRemoveRule = id => {
        let rules = [...popup.data.status_rules];

        rules = rules.filter(r => {
            return String(id) !== String(r.id);
        });

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                status_rules: rules
            }
        }));
    }

    const handleAddReward = e => {
        e.preventDefault();

        let reward = {
            id: Math.random().toString().substring(3),
        };

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rewards: prev.data.rewards.concat(reward)
            }
        }));
    }

    const handleRemoveReward = id => {
        let rewards = [...popup.data.rewards];

        rewards = rewards.filter(r => {
            return String(id) !== String(r.id);
        });

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rewards: rewards
            }
        }));
    }

    const getEmailRate = rate => {
        let rules = popup.data.email_rules;

        let row = rules.find(r => Number(r.email_rate) === Number(rate));

        if (row) {
            return row.points;
        }

        return '';
    }

    const handleChangeEmailRule = (e, rate) => {
        let val = e.target.value;
        let rules = [...popup.data.email_rules];

        let index = rules.findIndex(r => Number(r.email_rate) === Number(rate));

        let row;

        if (rules[index]) {
            rules[index].points = val;
        } else {
            row = {
                id: Math.random().toString().substring(3),
                email_rate: rate,
                points: val
            };

            rules.push(row);
        }

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                email_rules: rules
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form className={`${popup.modal ? 'show' : ''} popup-primary extra-large`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {popup.id ? trans('headings.editBonusPolicy') : trans('headings.addBonusPolicy')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                {popup.dataLoading
                    ?
                    <img src={loader} alt="loading" />
                    :
                    <div className="popup-container x2">

                        <div className="col">
                            {/* <div className="row">
                        <div className="col">
                            <label>
                                Тип
                            </label>
                            <select
                                name="type_id"
                                className={validations && validations.type_id ? 'validate' : ''}
                                value={popup.data.type_id ? popup.data.type_id : ''}
                                onChange={handleInputChange}
                            >
                                <option value="1">
                                    Най-добър служител
                                </option>
                                <option value="2">
                                    Изпълнен таргет
                                </option>
                            </select>

                        </div>
                    </div>

                    <br /> */}

                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('labels.from')}
                                    </label>
                                    <input
                                        type="date"
                                        name="from"
                                        className={validations && validations.from ? 'validate' : ''}
                                        value={popup.data.from_ymd ? popup.data.from_ymd : ''}
                                        onChange={handleDateChange}
                                    />
                                </div>

                                <div className="col">
                                    <label>
                                        {trans('labels.to')}
                                    </label>
                                    <input
                                        type="date"
                                        name="to"
                                        className={validations && validations.to ? 'validate' : ''}
                                        value={popup.data.to_ymd ? popup.data.to_ymd : ''}
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </div>

                            <br />

                            <div className="row">
                                <div className="col">
                                    <label>
                                        {trans('labels.repeatPeriod')}
                                    </label>
                                    <select
                                        name="repeat_after"
                                        className={validations && validations.repeat_after ? 'validate' : ''}
                                        value={popup.data && popup.data.repeat_after ? popup.data.repeat_after : ''}
                                        onChange={handleInputChange}
                                    >
                                        {getRepeatNumbers().map((n, i) => (
                                            <option key={'rn-' + i} value={n}>
                                                {n}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col">
                                    <label>
                                        {trans('labels.period')}
                                    </label>
                                    <select
                                        name="repeat_period"
                                        className={validations && validations.repeat_period ? 'validate' : ''}
                                        value={popup.data && popup.data.repeat_period ? popup.data.repeat_period : ''}
                                        onChange={handleInputChange}
                                    >
                                        <option value={1}>
                                            {plural('plural_periods.day', popup.data.repeat_after || 1)}
                                        </option>
                                        <option value={2}>
                                            {plural('plural_periods.week', popup.data.repeat_after || 1)}
                                        </option>
                                        <option value={3}>
                                            {plural('plural_periods.month', popup.data.repeat_after || 1)}
                                        </option>
                                        <option value={4}>
                                            {plural('plural_periods.year', popup.data.repeat_after || 1)}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            {!popup.id
                                ?
                                <div className="row">
                                    <div className="col">
                                        <label>
                                            {trans('labels.type')}
                                        </label>
                                        <select
                                            name="type_id"
                                            value={popup.data && popup.data.type_id ? popup.data.type_id : ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value={1}>
                                                {trans('labels.policyByPoints')}
                                            </option>
                                            <option value={2}>
                                                {trans('labels.policyByReservations')}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                :
                                ''
                            }

                        </div>

                        <div className="col">
                            <div className="scrollable" style={{ padding: '0 10px 0 0', maxHeight: '70vh' }}>
                                {validations && validations.rewards
                                    ?
                                    <div className="alert danger">
                                        {trans('messages.policyNoTarget')}
                                    </div>
                                    :
                                    ''
                                }

                                {Number(popup.data.type_id) === 1
                                    ?
                                    <>
                                        <div className="heading">
                                            {trans('labels.rules')}
                                        </div>
                                        <br />
                                        <div className="scrollable" style={{ padding: '0 10px 0 0', maxHeight: '150px' }}>
                                            {popup.data.status_rules.map((r, i) =>
                                                <React.Fragment key={'rule-' + r.id}>

                                                    <div className="popup-container x4">
                                                        <div className="col" style={{ width: '100%' }}>
                                                            <label>
                                                                {trans('labels.initialStatus')}
                                                            </label>
                                                            <select
                                                                name="status_rules[initial_status_id][]"
                                                                value={r.initial_status_id ? r.initial_status_id : ''}
                                                                onChange={e => handleNestedInputChange(e, r.id)}
                                                            >
                                                                {statuses[1] && statuses[1].map(s =>
                                                                    <option key={'is-' + s.id + r.id} value={s.id}>
                                                                        {s.translation ? s.translation.name : s.name}
                                                                    </option>
                                                                )}
                                                            </select>
                                                        </div>

                                                        <div className="col" style={{ width: '100%' }}>
                                                            <label>
                                                                {trans('labels.finalStatus')}
                                                            </label>
                                                            <select
                                                                name="status_rules[final_status_id][]"
                                                                value={r.final_status_id ? r.final_status_id : ''}
                                                                onChange={e => handleNestedInputChange(e, r.id)}
                                                            >
                                                                {statuses[2] && statuses[2].map(s =>
                                                                    <option key={'fs-' + s.id + r.id} value={s.id}>
                                                                        {s.translation ? s.translation.name : s.name}
                                                                    </option>
                                                                )}
                                                            </select>
                                                        </div>

                                                        <div className="col" style={{ width: '100%' }}>
                                                            <label>
                                                                {trans('labels.points')}
                                                            </label>
                                                            <input
                                                                type="number"
                                                                step="1"
                                                                name="status_rules[points][]"
                                                                value={r.points ? r.points : ''}
                                                                onChange={e => handleNestedInputChange(e, r.id)}
                                                                className={validations && validations['status_rules.points.' + i] ? 'validate' : ''}
                                                            />
                                                        </div>

                                                        <div className="col auto" style={{
                                                            width: '30px',
                                                            minWidth: '30px',
                                                            paddingTop: '23px'
                                                        }}>
                                                            {i > 0
                                                                ?
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    onClick={() => handleRemoveRule(r.id)}
                                                                >
                                                                    <img
                                                                        src={deleteIcon}
                                                                    />
                                                                </a>
                                                                :
                                                                ''
                                                            }
                                                        </div>

                                                        <input type="hidden" name="status_rules[id][]" value={r.id} />
                                                    </div>
                                                    {i !== popup.data.status_rules.length - 1
                                                        ?
                                                        <br />
                                                        :
                                                        ''
                                                    }
                                                </React.Fragment>
                                            )}
                                        </div>

                                        <br />
                                        <div className="row">
                                            <button className="button-small" onClick={handleAddRule}>
                                                {trans('buttons.addRule')}
                                            </button>
                                        </div>

                                        <div className="heading">
                                            {trans('labels.policyRatePoints')}
                                        </div>
                                        <br />
                                        <div className="popup-container x5">
                                            <div className="col">
                                                <label>
                                                    {trans('labels.bad')}
                                                </label>
                                                <input
                                                    type="number"
                                                    step="1"
                                                    name="email_rate[1]"
                                                    value={getEmailRate(1)}
                                                    onChange={e => handleChangeEmailRule(e, 1)}
                                                    className={validations && validations['email_rate.1'] ? 'validate' : ''}
                                                />
                                            </div>
                                            <div className="col">
                                                <label>
                                                    {trans('labels.average')}
                                                </label>
                                                <input
                                                    type="number"
                                                    step="1"
                                                    name="email_rate[2]"
                                                    value={getEmailRate(2)}
                                                    onChange={e => handleChangeEmailRule(e, 2)}
                                                    className={validations && validations['email_rate.2'] ? 'validate' : ''}
                                                />
                                            </div>
                                            <div className="col">
                                                <label>
                                                    {trans('labels.good')}
                                                </label>
                                                <input
                                                    type="number"
                                                    step="1"
                                                    name="email_rate[3]"
                                                    value={getEmailRate(3)}
                                                    onChange={e => handleChangeEmailRule(e, 3)}
                                                    className={validations && validations['email_rate.3'] ? 'validate' : ''}
                                                />
                                            </div>
                                            <div className="col">
                                                <label>
                                                    {trans('labels.veryGood')}
                                                </label>
                                                <input
                                                    type="number"
                                                    step="1"
                                                    name="email_rate[4]"
                                                    value={getEmailRate(4)}
                                                    onChange={e => handleChangeEmailRule(e, 4)}
                                                    className={validations && validations['email_rate.4'] ? 'validate' : ''}
                                                />
                                            </div>
                                            <div className="col">
                                                <label>
                                                    {trans('labels.excellent')}
                                                </label>
                                                <input
                                                    type="number"
                                                    step="1"
                                                    name="email_rate[5]"
                                                    value={getEmailRate(5)}
                                                    onChange={e => handleChangeEmailRule(e, 5)}
                                                    className={validations && validations['email_rate.5'] ? 'validate' : ''}
                                                />
                                            </div>
                                        </div>

                                        <br />
                                    </>
                                    :
                                    ''
                                }

                                <div className="heading">
                                    {trans('labels.rewards')}
                                </div>
                                <br />

                                {popup.data.rewards.length === 0
                                    ?
                                    <div className="alert warning" style={{ marginBottom: '0' }}>
                                        {trans('messages.policyMissTarget')}
                                    </div>
                                    :
                                    <div className="scrollable" style={{ padding: '0 10px 0 0', maxHeight: '150px' }}>

                                        {popup.data.rewards.map((r, i) =>
                                            <React.Fragment key={'reward-' + r.id}>
                                                <div className="popup-container x4">

                                                    {Number(popup.data.type_id) === 1
                                                        ?
                                                        <div className="col">
                                                            <label>
                                                                {trans('labels.points')}
                                                            </label>
                                                            <input
                                                                type="number"
                                                                step="1"
                                                                name="rewards[points][]"
                                                                value={r.points ? r.points : ''}
                                                                onChange={e => handleNestedInputChange(e, r.id)}
                                                                className={validations && validations['rewards.points.' + i] ? 'validate' : ''}
                                                            />
                                                        </div>
                                                        :
                                                        <div className="col">
                                                            <label>
                                                                {trans('labels.reservations')}
                                                            </label>
                                                            <input
                                                                type="number"
                                                                step="1"
                                                                name="rewards[completed_reservations][]"
                                                                value={r.completed_reservations ? r.completed_reservations : ''}
                                                                onChange={e => handleNestedInputChange(e, r.id)}
                                                                className={validations && validations['rewards.completed_reservations.' + i] ? 'validate' : ''}
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col">
                                                        <label>
                                                            {trans('labels.reward')}
                                                        </label>
                                                        <input
                                                            type="number"
                                                            step="1"
                                                            name="rewards[reward][]"
                                                            value={r.reward ? r.reward : ''}
                                                            onChange={e => handleNestedInputChange(e, r.id)}
                                                            className={validations && validations['rewards.reward.' + i] ? 'validate' : ''}
                                                        />
                                                    </div>

                                                    <div className="col auto" style={{
                                                        width: '30px',
                                                        minWidth: '30px',
                                                        paddingTop: '23px'
                                                    }}>
                                                        {i > 0
                                                            ?
                                                            <a
                                                                href="javascript:void(0)"
                                                                onClick={() => handleRemoveReward(r.id)}
                                                            >
                                                                <img
                                                                    src={deleteIcon}
                                                                />
                                                            </a>
                                                            :
                                                            ''
                                                        }
                                                    </div>

                                                    <input type="hidden" name="rewards[id][]" value={r.id} />
                                                </div>
                                                {i !== popup.data.rewards.length - 1
                                                    ?
                                                    <br />
                                                    :
                                                    ''
                                                }
                                            </React.Fragment>
                                        )}
                                    </div>
                                }

                                <br />
                                <div className="row">
                                    <button className="button-small" onClick={handleAddReward}>
                                        {trans('buttons.addTarget')}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                }

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(BonusPolicy);