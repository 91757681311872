import React, { useEffect, useState, useRef } from 'react';
import { fromInfo, trans } from '../../../_providers/Translation';

import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import Api from '../../../_helpers/Api';
import loader from '../../../assets/img/loader.svg';
import FilterTabs from './partials/FilterTabs';
import Pagination from '../../Pagination';
import AddOrEdit from './partials/AddOrEditExpense';
import Question from '../../modals/messages/Question';
import Options from './partials/ExpenseOptions';
import Filter from './partials/FilterExpenses';
import NoDataFound from '../../partials/NoDataFound';
import checkIcon from '../../../assets/img/icons/check.png';
import Help from '../../partials/Help';

let timeout;

function Expenses() {

    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const modalAddOrEditRef = useRef(null)
    const modalQuestionRef = useRef(null)

    const [state, setState] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: searchParams.get('page') || 1,
        missingReceipt: 0,
        filter: {},
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                start_date: searchParams.get('start_date') || '',
                end_date: searchParams.get('end_date') || '',
                category_id: Number(searchParams.get('category_id')) || '',
                text: searchParams.get('text') || '',
                price: searchParams.get('price') || '',
                gross_price: searchParams.get('gross_price') || '',
                with_missing_receipt: Number(searchParams.get('with_missing_receipt')) || 0,
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
            loadMissingReceipt();

        }
    }, [state.update]);

    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'expenses/all?page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    pages: res.data.pages,
                    total: res.data.total,
                    loading: false
                }));

                if (res.data.items.length === 0 && state.filter.with_missing_receipt === 1) {
                    handleSearch('with_missing_receipt', 0);
                }
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const loadMissingReceipt = () => {
        let url = 'expenses/missing-receipt';

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    missingReceipt: res.data,
                }));
            });
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add()
    }

    const handleCreate = () => {
        setState(prev => ({
            ...prev,
            update: Math.random().toString()
        }))
    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id)
    }

    const handleUpdate = row => {

        // let updated = state.data.map(i => {
        //     if (i.id == row.id) {
        //         i = row
        //     }
        //     return i
        // });

        // setState(prev => ({
        //     ...prev,
        //     data: updated,
        // }))

        setState(prev => ({
            ...prev,
            update: new Date().getTime(),
        }))
    }


    // delete
    const handleDestroy = id => {

        let url = 'expenses/delete';

        Api.post(url, {
            id: id
        }).then(res => {
            if (res.data.success) {
                // let filtered = state.data.filter(i => {
                //     return i.id !== id
                // })

                setState(prev => ({
                    ...prev,
                    // data: filtered
                    setFilter: new Date().getTime()
                }))

            }
        })
    }

    // Question modal
    const handleDelete = id => {
        modalQuestionRef.current.open(id);

        modalQuestionRef.current.onSuccess(() => {
            handleDestroy(id);
        });
    }

    const handleSearch = (key, val, delay = 300) => {

        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                setFilter: new Date().getTime(),
                page: 1
            }));
        }, delay);

    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }))
    }


    return (
        <>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.question10')}
            />

            <div className="wrapper">

                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.expenses')} <Help>{fromInfo('expenses')}</Help>
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.expenses')}
                        </p>
                    </div>
                    <div className="right">
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.add')}
                        </button>
                    </div>
                </div>

                <FilterTabs
                    tab=""
                />

                <Filter
                    filter={state.filter}
                    missingReceipt={state.missingReceipt}
                    handleSearch={handleSearch}
                />

                {state.loading
                    ?
                    <img className="loader" src={loader} />
                    :
                    state.data.length === 0
                        ?
                        <NoDataFound />
                        :
                        <>
                            <div className="table-scroller">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="center">
                                                ID
                                            </th>
                                            <th className="center">
                                                {trans('tables.document2')}
                                            </th>
                                            <th>
                                                {trans('tables.date')}
                                            </th>
                                            <th>
                                                {trans('tables.category')}
                                            </th>
                                            <th className="center">
                                                {trans('tables.ddsExpense')}
                                            </th>
                                            <th>
                                                {trans('tables.netSum')}
                                            </th>
                                            <th>
                                                {trans('labels.dds')}
                                            </th>
                                            <th>
                                                {trans('tables.grossSum')}
                                            </th>
                                            <th className="center">
                                                {trans('tables.files')}
                                            </th>
                                            <th className="options">
                                                {trans('tables.options')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.data.map(e =>
                                            <tr key={'e-' + e.id}>
                                                <td className="center">
                                                    {e.id}
                                                </td>
                                                <td className="center">
                                                    {e.receipt
                                                        ?
                                                        <img src={checkIcon} />
                                                        :
                                                        ''
                                                    }
                                                </td>
                                                <td>
                                                    {e.date_dmy}
                                                </td>
                                                <td>
                                                    {e.category ? (e.category.name || '') : ''}
                                                </td>
                                                <td className="center">
                                                    {e.vat_expense
                                                        ?
                                                        <img src={checkIcon} />
                                                        :
                                                        ''
                                                    }
                                                </td>
                                                <td>
                                                    {e.price_formatted} {e.currency ? e.currency.name : ''}
                                                </td>
                                                <td>
                                                    {e.vat}% ({e.vat_price_formatted} {e.currency ? e.currency.name : ''})
                                                </td>
                                                <td>
                                                    {e.gross_price_formatted} {e.currency ? e.currency.name : ''}
                                                </td>
                                                <td className="center">
                                                    {e.files_count}
                                                </td>
                                                <td className="options">
                                                    <Options
                                                        id={e.id}
                                                        handleEdit={handleEdit}
                                                        handleDelete={handleDelete}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <Pagination
                                pages={state.pages}
                                page={state.page}
                                handlePage={handlePage}
                            />
                        </>
                }
            </div>
        </>
    )
}

export default Expenses;