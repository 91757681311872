import React from 'react';
import { trans } from '../../../../_providers/Translation';

function Table(props) {
    return (
        <table>
            <thead>
                <tr>
                    <th>
                        {trans('tables.from')}
                    </th>
                    <th>
                        {trans('tables.to')}
                    </th>
                    <th>
                        {trans('tables.topic')}
                    </th>
                    <th>
                        {trans('tables.refNo')}
                    </th>
                    <th>
                        {trans('tables.info')}
                    </th>
                    <th>
                        {trans('tables.employee')}
                    </th>
                    <th>
                        {trans('tables.date')}
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.data.map(r =>
                    <tr key={r.id}>
                        <td>
                            {r.from}
                        </td>
                        <td>
                            {r.to}
                        </td>
                        <td>
                            {r.subject}
                        </td>
                        <td>
                            {r.reservation
                                ?
                                <a
                                    href={'/reservations/edit/' + r.reservation_id} target="_blank"
                                    className="btn"
                                    style={{ background: r.reservation.color, color: r.reservation.text_color }}
                                >
                                    {r.reservation.reference_code}
                                </a>
                                :
                                '-'
                            }
                        </td>
                        <td>
                            {r.url
                                ?
                                <a href={r.url} target="_blank">
                                    {trans('buttons.view')}
                                </a>
                                :
                                r.text
                            }
                        </td>
                        <td>
                            {r.employee
                                ?
                                `${r.employee.name} ${r.employee.lastname}`
                                :
                                '-'
                            }
                        </td>
                        <td>
                            {r.date}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}

export default Table;