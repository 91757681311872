import React, { useEffect, useState, useRef } from 'react';
import Info from './partials/Info';
import Api from '../../../_helpers/Api';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import loader from '../../../assets/img/loader.svg';
import FilterTabs from './partials/FilterTabs';
import Employees from './partials/Employees';
import { useAuthDataContext } from '../../../_providers/Auth';
import Settings from './partials/Settings';
import AddOrEdit from '../partners/partials/AddOrEdit';
import Groups from './partials/Groups';
import NeccesarySettings from './partials/NeccesarySettings';
import { trans } from '../../../_providers/Translation';

function Partner(props) {

    const auth = useAuthDataContext();
    const location = useLocation();
    const history = useHistory();
    const params = useParams();

    const editModalRef = useRef(null);

    let tabs = ['info', 'employees', 'groups', 'settings', 'neccesary-settings'];

    if (auth.isAdmin()) {
        tabs = ['info', 'employees'];
    }

    const tab = new URLSearchParams(location.search).get('tab');

    const isMyCompany = location.pathname === '/company' ? true : false;

    const id = isMyCompany ? auth.partnerId() : params.id;

    const [state, setState] = useState({
        loading: true,
        data: [],
        update: false,
        tabs: tabs,
        tab: tabs.indexOf(tab) > -1 ? tab : tabs[0],
        myCompany: isMyCompany,
        showNeccesarySettings: false
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            tab: tabs.indexOf(tab) > -1 ? tab : tabs[0]
        }))
    }, [location.search])

    useEffect(() => {
        const settings = auth.neccesarySettings;

        let showSettingsTab = false;

        Object.entries(settings).map(e => {
            if (!e[1].filled) {
                showSettingsTab = true;
            }
        });

        setState(prev => ({
            ...prev,
            showNeccesarySettings: showSettingsTab
        }));

    }, [auth.neccesarySettings])

    useEffect(() => {
        loadData(id);

        if (isMyCompany) {
            auth.loadNeccesarySettings();
        }
    }, [state.update]);

    //read
    const loadData = (id) => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'partners/find?id=' + id;

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleTab = newValue => {

        history.push(`?tab=${newValue}`);

        setState(prev => ({
            ...prev,
            tab: newValue
        }))
    }

    const handleEdit = () => {
        editModalRef.current.edit(id);
    }

    const handleUpdate = () => {
        loadData(id);
    }

    const handleRefresh = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const handleRefreshNeccessarySettings = () => {
        if (isMyCompany) {
            auth.loadNeccesarySettings();
        }
    }

    return (
        <>
            <AddOrEdit
                ref={editModalRef}
                handleUpdate={handleRefresh}
            />

            <div className="wrapper">

                {state.loading
                    ?
                    <img src={loader} alt="loading" />
                    :
                    <>
                        <div className="top-part">
                            <div className="left">
                                <h1 className="page-heading">
                                    {state.data.name}
                                </h1>
                                <p className="page-subheading">
                                    {trans('subheadings.info')}
                                </p>
                            </div>

                            {state.tab === 'info' && auth.isManager() &&
                                <div className="right">
                                    <button onClick={handleEdit}>
                                        {trans('buttons.edit')}
                                    </button>
                                </div>
                            }
                        </div>

                        <FilterTabs
                            tab={state.tab}
                            state={state.data}
                            showSettingsTab={state.showNeccesarySettings}
                            handleTab={handleTab}
                        />

                        {state.tab === 'neccesary-settings'
                            ?
                            <NeccesarySettings
                                partner={state.data}
                                loading={state.loading}
                                handleRefresh={handleRefreshNeccessarySettings}
                            />
                            :
                            state.tab === 'info'
                                ?
                                <Info
                                    partner={state.data}
                                    loading={state.loading}
                                    handleRefresh={handleRefreshNeccessarySettings}
                                />
                                :
                                state.tab === 'employees'
                                    ?
                                    <Employees
                                        id={id}
                                        partner={state.data}
                                        handleRefresh={handleRefreshNeccessarySettings}
                                    />
                                    :
                                    state.tab === 'groups'
                                        ?
                                        <Groups
                                            id={id}
                                            partner={state.data}
                                            handleRefresh={handleRefreshNeccessarySettings}
                                        />
                                        :
                                        state.tab === 'settings'
                                            ?
                                            <Settings
                                                id={id}
                                                partner={state.data}
                                                handleRefresh={handleRefreshNeccessarySettings}
                                            />
                                            :
                                            ''}
                    </>
                }


            </div>
        </>
    )
}

export default Partner;