import React from 'react';
import Options from './Options';
import loader from '../../../../assets/img/loader.svg';
import { trans } from '../../../../_providers/Translation'

function Table(props) {

    return (

        props.loading
            ?
            <img src={loader} alt="loading" />
            :

            <>
                <div className="table-scroller">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: '50px' }}>ID</th>
                                <th style={{ width: '80px', textAlign: 'center' }}>{trans('tables.type')}</th>
                                <th>{trans('tables.count')}</th>
                                {/* <th>{trans('tables.price')}</th>
                                <th>{trans('labels.currency')}</th> */}
                                <th className="options">{trans('tables.options')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.packages.map(pack =>
                                <tr key={'license-' + pack.id}>
                                    <td style={{ width: '50px' }}>{pack.id}</td>
                                    <td style={{ width: '80px', textAlign: 'center' }}>{pack.type}</td>
                                    <td>{pack.count}</td>
                                    {/* <td>{pack.price}</td>
                                    <td>{pack.currency.name}</td> */}
                                    <td>
                                        <Options
                                            id={pack.id}
                                            handleEdit={props.handleEdit}
                                            handleDelete={props.handleDelete}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </>
    )
}

export default Table;