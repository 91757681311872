import React from 'react';
import Options from './Options';
import { trans } from '../../../../_providers/Translation'

function Messages(props) {

    return (
        <>
            <div className="table-scroller">
                <table>
                    <thead>
                        <tr>
                            <th>{trans('tables.category')}</th>
                            <th>{trans('tables.make')}</th>
                            <th>{trans('tables.model')}</th>
                            <th>{trans('tables.message')}</th>
                            <th>{trans('tables.description')}</th>
                            <th className="options">{trans('tables.options')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map(message =>
                            <tr key={'message-' + message.id}>
                                <td>{message.category && message.category.name}</td>
                                <td>{message.brand.name}</td>
                                <td>
                                    {message.models}
                                </td>
                                <td>
                                    {message.message}
                                </td>
                                <td>
                                    {message.description}
                                </td>
                                <td className="options">
                                    <Options
                                        id={message.id}
                                        handleEdit={props.handleEdit}
                                        handleDelete={props.handleDelete}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        </>
    )
}

export default Messages;