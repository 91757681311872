import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useAuthDataContext } from './Auth';
import Api from '../_helpers/Api';

export const TranslationContext = createContext({});

let translations = {};
let lang = 'bg';
let info = {};

const TranslationProvider = props => {

    const auth = useAuthDataContext();

    const [state, setState] = useState({
        info: {},
    });

    useMemo(() => {
        Api.get('trans').then(res => {
            info = res.data;
        })
    }, [auth]);

    useMemo(() => {
        if (auth.lang()) {
            lang = auth.lang();
        }

        loadTranslationFile(lang);
    }, [auth]);

    const fromInfo = () => {
        return 'asd';
    }

    const value = {
        fromInfo
    }

    return <TranslationContext.Provider value={value} {...props} />;
}

export const useTransContext = () => useContext(TranslationContext);


const loadTranslationFile = lang => {
    if (!translations[lang]) {
        translations[lang] = require('../_langs/' + lang + '.json');
    }
}

const getTranslation = (obj, string) => {
    let parts = string.split('.');
    let newObj = obj[parts[0]];

    if (!newObj) {
        return null;
    }

    if (parts[1]) {
        parts.splice(0, 1);

        let newString = parts.join('.');

        return getTranslation(newObj, newString);
    }

    return newObj;
}

export const trans = (key, params = {}, forceLang = null) => {

    let trans = key;

    let file = forceLang || lang;

    if (file) {
        loadTranslationFile(file);

        if (translations[file]) {
            trans = getTranslation(translations[file], key);

            if (!trans) {
                return key;
            }

            let placeholders = Object.entries(params);

            if (placeholders.length) {
                placeholders.forEach(data => {
                    trans = trans.replaceAll('{' + data[0] + '}', data[1]);
                });
            }
        }
    }

    return trans;
}

export const plural = (key, qty = 0, params = {}) => {
    let string = trans(key, params);
    let strings = string.split('|');
    let array = {};

    for (let i = 0; i < strings.length; i++) {
        let str = strings[i];

        array[i] = {
            min: Infinity,
            max: Infinity,
            str: str
        };

        str = str.replace(/\[.*?\]/g, (match) => {
            let range = match.replace('[', '').replace(']', '').split(',');
            let min = range[0] ? Number(range[0]) : 0;
            let max = range[1] ? Number(range[1]) : min;

            array[i] = {
                min: min,
                max: max
            };

            return '';
        });

        str = str.trim();

        array[i]['str'] = str

    }

    array = Object.values(array);

    let match;

    for (let i = 0; i < array.length; i++) {
        let el = array[i];

        if (qty >= el['min'] && qty <= el['max']) {
            match = el['str'];
            break;
        }
    }

    if (match) {
        return match;
    }

    return array[array.length - 1]['str'];
}

export const validate = (attribute, rule) => {
    let attributeName = trans('validation.attributes.' + attribute);

    if (!attributeName) {
        attributeName = attribute;
    }

    let text = trans(rule, {
        attribute: attributeName
    });

    return text;
}

export const fromHtml = (file) => {
    let html = require('../_langs/' + file + '.html');

    console.log(html);

    return <div dangerouslySetInnerHTML={{ __html: html }} />
}

export const fromInfo = key => {

    // let file = require('../_langs/' + lang + '/info.json');

    // let html = file[key];

    let html = info[key] || key;

    return <div dangerouslySetInnerHTML={{ __html: html }} />
}

// export const useTranslationContext = () => useContext(TranslationContext);

export default TranslationProvider;