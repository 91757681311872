import React from 'react';

import loader from '../../../../assets/img/loader.svg';
import Manuals from './Manuals';
import Messages from './Messages';
import Problems from './Problems';
import { trans } from '../../../../_providers/Translation';
import NoDataFound from '../../../partials/NoDataFound';

function Table(props) {

    return (
        <>
            {props.loading ?
                <img src={loader} alt="loading" />
                :
                props.manuals.length === 0
                    ?
                    <NoDataFound />
                    :
                    props.tab === 'manuals'
                        ? <Manuals
                            data={props.manuals}
                            handleEdit={props.handleEdit}
                            handleDelete={props.handleDelete}
                        />
                        : props.tab === 'errors'
                            ? <Messages
                                data={props.manuals}
                                handleEdit={props.handleEdit}
                                handleDelete={props.handleDelete}
                            />
                            :
                            props.tab === 'problems'
                                ? <Problems
                                    data={props.manuals}
                                    handleEdit={props.handleEdit}
                                    handleDelete={props.handleDelete}
                                />
                                :
                                ''

            }
        </>
    )
}

export default Table;