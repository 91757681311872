import React, { useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dropdown from '../../../partials/Dropdown';
import { trans } from '../../../../_providers/Translation';

function Filter(props) {

    const [tabs, setTabs] = useState({
        ripple: true
    })

    const handleCategory = data => {

        let val = data.id || '';

        if (data.id || data.name == '') {
            props.handleSearch('category_id', val);
        }

    }

    const handleSupplier = data => {

        let val = data.id || '';

        if (data.id || data.name === '') {
            props.handleSearch('supplier_id', val);
        }

    }

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    return (
        <>
            <div className="filter-standard">
                <div className="col auto">
                    <label>
                        {trans('filters.category')}
                    </label>
                    <Dropdown
                        inputIdValue={Number(props.filter.category_id)}
                        url="partners/categories/all"
                        renderText={data => {
                            return data.name
                        }}
                        onChange={data => {
                            handleCategory(data);
                        }}
                    />
                </div>

                <div className="col auto">
                    <label>
                        {trans('labels.supplier')}
                    </label>
                    <Dropdown
                        inputIdValue={Number(props.filter.supplier_id)}
                        url="suppliers/all"
                        renderText={data => {
                            return data.name
                        }}
                        onChange={data => {
                            handleSupplier(data);
                        }}
                    />
                </div>

                <div className="col auto">
                    <label>
                        {trans('labels.search')}
                    </label>
                    <input
                        type="search"
                        name="name"
                        onKeyUp={handleSearch}
                        onChange={handleSearch}
                        value={props.filter.name}
                        placeholder={trans('random.searchPlaceholder')}
                    />
                </div>
            </div>

            <div className="small-tabs">
                <Tabs
                    value={props.tab}
                    onChange={(e, id) => props.handleTab(id)}
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs"
                >
                    {props.tabs.map(t =>
                        <Tab
                            key={t.id}
                            value={String(t.id)}
                            label={[t.translation ? t.translation.name : t.name, '(' + t.count + ')'].join(' ')}
                            disableRipple={tabs.ripple}
                        />
                    )}
                </Tabs>
            </div>
        </>
    )
}

export default Filter;