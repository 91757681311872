import React, { useEffect, useState, useRef } from 'react';
import Table from './partials/Table';
import Api from '../../../_helpers/Api';
import { trans } from '../../../_providers/Translation'

//modals
import AddOrEdit from './partials/AddOrEdit';
import Question from '../../modals/messages/Question';

import Filter from './partials/Filter';
import { useHistory, useLocation } from 'react-router-dom';

let timeout;

function Licenses() {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const modalAddOrEditRef = useRef(null);
    const modalQuestionRef = useRef(null);

    const [licenses, setLicenses] = useState({
        loading: true,
        data: [],
        filter: {},
        setFilter: false,
        update: false,
    });

    const [branches, setBranches] = useState([]);

    useEffect(() => {

        setLicenses(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                branch_id: searchParams.get('branch_id') || '',
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (licenses.setFilter) {
            // searchParams.set('page', licenses.page);

            Object.entries(licenses.filter).map(filter => {
                searchParams.set(filter[0], filter[1]);
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [licenses.setFilter]);

    useEffect(() => {
        if (licenses.update) {
            loadLicenses();
        }
    }, [licenses.update]);

    useEffect(() => {
        Api.get('branches/all')
            .then(res => {
                setBranches(res.data)
            });
    }, []);

    //read
    const loadLicenses = () => {

        setLicenses(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'licenses/all';

        Object.entries(licenses.filter).map(filter => {
            url += (url.indexOf('?') > - 1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setLicenses(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setLicenses(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add();
    }

    const handleCreate = license => {

        // let data = partners.data.concat(partner);

        // setPartners(prev => ({
        //     ...prev,
        //     data: data
        // }));

        setLicenses(prev => ({
            ...prev,
            update: Math.random().toString()
        }));

    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id);
    }

    const handleUpdate = license => {

        let data = licenses.data.map(i => {
            if (i.id == license.id) i = license;
            return i;
        });

        setLicenses(prev => ({
            ...prev,
            data: data
        }));
    }

    // delete
    const handleDelete = id => {
        let modal = modalQuestionRef.current;

        modal.open(id);

        modal.onSuccess(() => {
            handleDestroy(id);
        })
    }

    const handleDestroy = id => {
        Api.post('licenses/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                let data = licenses.data.filter(i => {
                    return i.id !== id;
                });

                setLicenses(prev => ({
                    ...prev,
                    data: data
                }));
            }
        });
    }

    const handleSearch = (key, val) => {

        clearTimeout(timeout);

        setLicenses(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setLicenses(prev => ({
                ...prev,
                // page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);

    }

    return (
        <>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.licenses')}
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.licenses')}
                        </p>
                    </div>
                    <div className="right">
                        <button className="button button-add" onClick={handleAdd}>
                            {trans('buttons.create')}
                        </button>
                    </div>
                </div>

                <Filter
                    filter={licenses.filter}
                    branches={branches}
                    handleSearch={handleSearch}
                />

                <Table
                    licenses={licenses.data}
                    loading={licenses.loading}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                />

            </div>
        </>
    )
}

export default Licenses;