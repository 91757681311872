import React, { useEffect, useState, useRef, useMemo } from 'react';
import Table from '../../partials/InvoicesTable';
import Filter from './partials/Filter';
import { fromInfo, trans } from '../../../_providers/Translation';

import Api from '../../../_helpers/Api';
import { useHistory, useLocation, Link } from 'react-router-dom';
import Pagination from '../../Pagination';

//modals
import FilterTabs from './partials/FilterTabs';
import '../../../assets/css/invoices.css'
import { useAuthDataContext } from '../../../_providers/Auth';
import CreatePartnerInvoice from './partials/CreatePartnerInvoice';
import Help from '../../partials/Help';

let timeout;

function Invoices() {

    const auth = useAuthDataContext();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const tabs = ['all', 'paid', 'unpaid'];
    const clientTabs = ['company', 'private'];
    const tab = searchParams.get('status');
    const clientTab = searchParams.get('client');

    const addPartnerInvoiceRef = useRef(null);

    const [invoices, setInvoices] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setInvoices(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                status: tabs.indexOf(tab) > -1 ? tab : tabs[0],
                client: clientTabs.indexOf(clientTab) > -1 ? clientTab : clientTabs[0],
                type_id: searchParams.get('type_id') || '',
                start_date: searchParams.get('start_date') || '',
                end_date: searchParams.get('end_date') || '',
                search: searchParams.get('search') || '',
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (invoices.setFilter) {
            searchParams.set('page', invoices.page);

            Object.entries(invoices.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [invoices.setFilter]);

    useEffect(() => {
        if (invoices.update) {
            loadInvoices();
        }
    }, [invoices.update]);


    const loadInvoices = () => {

        setInvoices(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'invoices/all' + '?page=' + invoices.page;

        Object.entries(invoices.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setInvoices(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }));
            }).catch(err => {
                setInvoices(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const refreshData = () => {
        setInvoices(prev => ({
            ...prev,
            update: new Date().getTime(),
            page: 1
        }));
    }

    const handleTab = val => {

        setInvoices(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                status: val,
            },
            page: 1,
            setFilter: Math.random().toString(),
            loading: true
        }))
    }

    const handleClientTab = val => {

        setInvoices(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                client: val,
            },
            page: 1,
            setFilter: Math.random().toString(),
            loading: true
        }))
    }

    const handleSearch = (key, val, delay = 300) => {

        clearTimeout(timeout);

        setInvoices(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            }
        }));

        timeout = setTimeout(() => {
            setInvoices(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, delay);

    }

    const handlePage = page => {
        setInvoices(prev => ({ ...prev, page: page.selected + 1, setFilter: Math.random().toString() }));
    }

    const handleCreate = () => {
        // let modal = addPartnerInvoiceRef.current;

        // modal.show();
        // modal.onSave(() => {
        //     refreshData();
        // });

        history.push('invoices/add');
    }

    return (
        <>
            {/* <CreatePartnerInvoice
                ref={addPartnerInvoiceRef}
            /> */}

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.invoices')} {!auth.isAdmin() && <Help>{fromInfo('invoices')}</Help>}
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.invoices')}
                        </p>
                    </div>
                    <div className="right">

                        <button
                            className="button-add"
                            onClick={handleCreate}
                        >
                            {trans('buttons.create')}
                        </button>

                    </div>
                </div>

                <>
                    <Filter
                        filter={invoices.filter}
                        handleSearch={handleSearch}
                    />

                    <FilterTabs
                        tab={invoices.filter.status}
                        client={invoices.filter.client}
                        handleTab={handleTab}
                        handleClientTab={handleClientTab}
                    />

                    <Table
                        tab={invoices.filter.status}
                        invoices={invoices.data}
                        loading={invoices.loading}
                        canModify={true}
                        refreshData={refreshData}
                        showReservation={!auth.isAdmin()}
                        showPaidAll={invoices.filter.status === 'unpaid'}
                        showUnpaidAll={invoices.filter.status === 'paid'}
                    // handleDelete={handleOpenQuestion}
                    />

                    {!invoices.loading
                        ?
                        <Pagination
                            pages={invoices.pages}
                            page={invoices.page}
                            handlePage={handlePage}
                        />
                        :
                        ''
                    }

                </>

            </div>
        </>
    )
}

export default Invoices;