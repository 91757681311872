import React from 'react';
import Options from './Options';
import notFound from '../../../../assets/img/not-found.png'
import ReactTooltip from 'react-tooltip';
import { trans } from '../../../../_providers/Translation';

function TableVanStockSummary(props) {

    return (

        props.parts.length === 0
            ?
            <div className="not-found">
                <img src={notFound} alt="Not Found" />
                <p>
                    {trans('messages.noResultsFound')}.
                </p>
            </div>
            :
            <>
                <div className="table-scroller">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>{trans('tabs.van')}</th>
                                <th>{trans('tables.name')}</th>
                                <th>{trans('tables.partNumber')}</th>
                                <th className="center">{trans('tables.quantity')}</th>
                                <th>{trans('tables.category')}</th>
                                <th>{trans('tables.avgGrossPrice')}</th>
                                <th>{trans('tables.addedOn')}</th>
                                <th className="center">{trans('tables.image')}</th>
                                <th className="options">
                                    {/* {trans('tables.options')} */}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.parts.map(p =>
                                <tr key={'p-' + p.id}>
                                    <td>
                                        {p.id}
                                    </td>

                                    <td>
                                        {p.van && p.van.reg_no}
                                        {p.van && p.van.employee && ` (${p.van.employee.name} ${p.van.employee.lastname})`}
                                    </td>

                                    <td>
                                        {p.part.name}
                                    </td>

                                    <td>
                                        {p.part.number}
                                    </td>
                                    <td className="center">
                                        {p.quantity}
                                    </td>

                                    <td>
                                        {p.part.category
                                            ?
                                            p.part.category.name
                                            :
                                            ''
                                        }
                                    </td>

                                    <td>{p.gross_price_format} {p.currency.name}</td>

                                    <td>{p.given_date_dmy}</td>

                                    <td className="center">
                                        <img data-tip data-for={'img-' + p.id} src={p.picture} alt="" height={25} />
                                        <ReactTooltip
                                            id={'img-' + p.id}
                                            effect="solid"
                                            border={true}
                                            borderColor="#ccc"
                                            backgroundColor="white"
                                            arrowColor="white"
                                        >
                                            <img src={p.picture} alt="" style={{ maxHeight: '200px' }} />
                                        </ReactTooltip>
                                    </td>

                                    <td>

                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </div>


            </>
    )
}

export default TableVanStockSummary;