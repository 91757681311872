import React, { useRef, useState, useEffect } from 'react';
import { useAuthDataContext } from '../../../../../_providers/Auth';
import Api from '../../../../../_helpers/Api';
import editIcon from '../../../../../assets/img/icons/edit-yellow.png';
import Modal from './modals/Payments';
import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../../../_providers/Translation';

function Payments(props) {

    const auth = useAuthDataContext();

    const modalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: [],
        update: false,
    });

    useEffect(() => {
        loadSettings();
    }, [state.update]);

    const loadSettings = () => {
        Api.get('partners/settings/all?id=' + auth.user().partner_id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const showModal = () => {
        let modal = modalRef.current;

        modal.show(state.data);

        modal.onSave(() => {
            handleUpdate();

            if (typeof props.handleRefresh === 'function') {
                props.handleRefresh();
            }
        });
    }

    return (
        <div style={{ width: '100%' }}>
            <div className="head">
                <h4>{trans('headings.paymentsSettings')}</h4>
                <a onClick={showModal}>
                    <img src={editIcon} alt="" />
                </a>
            </div>

            {state.loading
                ?
                <Skeleton height={35} count={10} />
                :
                <>
                    <Modal
                        ref={modalRef}
                    />

                    <div className="table-scroller">
                        <table className="settings">
                            <tbody>
                                <tr>
                                    <td>
                                        <b>{trans('labels.bank')}</b>
                                    </td>
                                    <td>
                                        {state.data.bank_name ? state.data.bank_name : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>IBAN</b>
                                    </td>
                                    <td>
                                        {state.data.bank_iban ? state.data.bank_iban : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>SWIFT</b>
                                    </td>
                                    <td>
                                        {state.data.bank_swift ? state.data.bank_swift : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Sort Code</b>
                                    </td>
                                    <td>
                                        {state.data.bank_sort_code ? state.data.bank_sort_code : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Account number</b>
                                    </td>
                                    <td>
                                        {state.data.bank_account_number ? state.data.bank_account_number : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>ePay Client ID</b>
                                    </td>
                                    <td>
                                        {state.data.epay_client_id ? state.data.epay_client_id : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>ePay Secret</b>
                                    </td>
                                    <td>
                                        {state.data.epay_client_secret ? state.data.epay_client_secret : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('random.epay_url')}</b>
                                    </td>
                                    <td>
                                        {props.partner.epay_receive_url}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>PayPal Client ID</b>
                                    </td>
                                    <td>
                                        {state.data.paypal_client_id ? state.data.paypal_client_id : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>PayPal Secret</b>
                                    </td>
                                    <td>
                                        {state.data.paypal_client_secret ? state.data.paypal_client_secret : trans('labels.notSpecified')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </div>
    )

}

export default Payments