import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router-dom';
import Api from '../../../../_helpers/Api';
import Dropdown from '../../../partials/Dropdown';
import { fromInfo, trans } from '../../../../_providers/Translation'
import Help from '../../../partials/Help';

function Filter(props) {

    const history = useHistory();

    const [state, setState] = useState({
        loadStatuses: true,
        statuses: []
    });

    useEffect(() => {

        if (!props.showStatuses) {
            return;
        }

        let url = 'ordered-parts/statuses?true';

        Object.entries(props.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    statuses: res.data,
                    loadStatuses: false
                }));
            });
    }, [props.filter, props.update, props.showStatuses]);

    const handleCategory = data => {

        let val = data.id || '';

        if (data.id || data.name == '') {
            props.handleSearch('category_id', val);
        }

    }

    const handleSupplier = data => {

        let val = data.id || '';

        if (data.id || data.name === '') {
            props.handleSearch('supplier_id', val);
        }

    }

    const handleStorage = data => {

        let val = data.id || '';

        if (data.id || data.name === '') {
            props.handleSearch('storage_id', val);
        }

    }

    const handleBox = data => {

        let val = data.name || '';

        props.handleSearch('box', val);

    }

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    const handleFilter = (e, k, v) => {
        e.preventDefault();

        if (k === 'customer_type') {
            if (v === '') {
                props.handleSearch('status_id', '');
            }
        }

        props.handleSearch(k, v);
    }

    const handlePosition = (e, v) => {
        e.preventDefault();

        history.push('/parts/storage/' + v);
    }

    const handleVan = data => {

        let val = data.id || '';

        if (data.id || data.name === '') {
            props.handleSearch('van_id', val);
        }

    }

    const handleStatusChange = (id, customerType) => {

        props.handleSearch('customer_type', customerType);

        if (props.filter.status_id && props.filter.customer_type === customerType && Number(props.filter.status_id) === id) {
            props.handleSearch('status_id', '');
        } else {
            props.handleSearch('status_id', id);
        }

    }

    const toggleSummaryData = bool => {
        props.handleSearch('summary', Number(bool), 0);
    }

    const handleSelectMobileFilter = e => {
        let selectedValue = e.target.value
        let fields = [...document.querySelectorAll('.filter-standard .col')]
        fields.map(field => {
            if (field.getAttribute('data-id') == selectedValue) field.style.display = 'flex'
            else if (field.getAttribute('data-id')) field.style.display = 'none'
        })
    }

    return (
        <form className="filter-standard schedule-filter">

            <div className="col full-width">

                <div className="row">

                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <label>
                                        {trans('labels.location')}
                                    </label>
                                </div>
                                <div className="row">
                                    {/* <button
                                        className={props.filter.stock === '' ? 'active' : ''}
                                        onClick={e => handlePosition(e, '')}
                                    >
                                        Използвани
                                    </button> */}
                                    <div style={{ position: 'relative' }}>
                                        <button
                                            className={[props.filter.stock === 'office' ? 'active' : '', 'green'].join(' ')}
                                            onClick={e => handlePosition(e, 'office')}
                                        >
                                            {trans('labels.inStorage')}
                                        </button>
                                        <Help
                                            style={{ position: 'absolute', top: '-9px', right: 0 }}
                                        >
                                            {fromInfo('parts.inStockButton')}
                                        </Help>
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <button
                                            className={[props.filter.stock === 'van' ? 'active' : '', 'yellow'].join(' ')}
                                            onClick={e => handlePosition(e, 'van-used')}
                                        >
                                            {trans('labels.inVan')}
                                        </button>
                                        <Help
                                            style={{ position: 'absolute', top: '-9px', right: 0 }}
                                        >
                                            {fromInfo('parts.inVanButton')}
                                        </Help>
                                    </div>

                                    {props.filter.stock === 'van'
                                        ?
                                        <Dropdown
                                            inputIdName="van_id"
                                            inputIdValue={Number(props.filter.van_id)}
                                            inputPlaceholder={trans('labels.van')}
                                            url="vans/all"
                                            renderText={data => {
                                                return `${data.reg_no} ${data.employee ? `(${data.employee.name})` : ''}`
                                            }}
                                            onChange={handleVan}
                                        />
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {props.filter.stock === 'van'
                        ?
                        <div className="col auto">
                            <div className="row">
                                <div className="col">
                                    <div className="row">
                                        <label>
                                            &nbsp;
                                        </label>
                                    </div>
                                    <div className="row">
                                        <button
                                            className={[props.filter.instock === '0' ? 'active' : ''].join(' ')}
                                            onClick={e => handlePosition(e, 'van-used')}
                                        >
                                            {trans('labels.used')}

                                        </button>
                                        <button
                                            className={[props.filter.instock === '1' ? 'active' : ''].join(' ')}
                                            onClick={e => handlePosition(e, 'van')}
                                        >
                                            {trans('labels.notUsed')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                    }

                    <div className="col">
                        {props.showCustomerType
                            ?
                            <div className="row">
                                <div className="col auto right">
                                    <div className="row">
                                        <label>
                                            {trans('labels.forClientReservation')}
                                        </label>
                                    </div>
                                    <div className="row">
                                        <button
                                            className={props.filter.customer_type === '' ? 'active' : ''}
                                            onClick={e => handleFilter(e, 'customer_type', '')}
                                        >
                                            {trans('buttons.all')}
                                        </button>
                                        <div style={{ position: 'relative' }}>
                                            <button
                                                className={props.filter.customer_type === 'private' ? 'active' : ''}
                                                onClick={e => handleFilter(e, 'customer_type', 'private')}
                                            >
                                                {trans('buttons.individual')}
                                            </button>
                                            <Help
                                                style={{ position: 'absolute', top: '-9px', right: 0 }}
                                                position="bottom-right"
                                            >
                                                {fromInfo('parts.privateCustomerTypeButton')}
                                            </Help>
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <button
                                                className={props.filter.customer_type === 'company' ? 'active' : ''}
                                                onClick={e => handleFilter(e, 'customer_type', 'company')}
                                            >
                                                {trans('labels.contractorCompany')}
                                            </button>
                                            <Help
                                                style={{ position: 'absolute', top: '-9px', right: 0 }}
                                                position="bottom-right"
                                            >
                                                {fromInfo('parts.companyCustomerTypeButton')}
                                            </Help>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                        }
                    </div>

                </div>

                {/* status */}
                {props.showStatuses
                    ?
                    <div className="status-filter">
                        <div className="wrap green">
                            <div className="col auto">
                                <label style={{
                                    whiteSpace: 'nowrap'
                                }}>
                                    {trans('labels.forIndividuals')}
                                </label>
                            </div>
                            <div className="col auto right" style={{
                                overflowX: 'auto',
                                paddingBottom: '10px'
                            }}>
                                <div className="row no-margin">
                                    {state.loadStatuses
                                        ?
                                        <Skeleton count={5} width={80} height={20} style={{ borderRadius: '12px' }} />
                                        :
                                        state.statuses.map(s =>
                                            <a
                                                key={'s1-' + s.id}
                                                onClick={() => handleStatusChange(s.id, 'private')}
                                                className={props.filter.status_id && props.filter.customer_type === 'private' && Number(props.filter.status_id) === s.id ? 'active' : ''}
                                                style={{ borderBottom: '1px solid ' + s.color }}
                                            >
                                                {s.translation ? s.translation.name : s.name} {s.private > 0 ? `(${s.private})` : ''}
                                            </a>


                                        )}
                                </div>
                            </div>
                        </div>

                        <div className="wrap blue">
                            <div className="col auto">
                                <label style={{
                                    whiteSpace: 'nowrap'
                                }}>
                                    {trans('labels.forContractCompany')}
                                </label>
                            </div>
                            <div className="col auto right" style={{
                                overflowX: 'auto',
                                paddingBottom: '10px'
                            }}>
                                <div className="row no-margin">
                                    {state.loadStatuses
                                        ?
                                        <Skeleton count={5} width={80} height={20} style={{ borderRadius: '12px' }} />
                                        :
                                        state.statuses.map(s =>
                                            <a
                                                key={'s2-' + s.id}
                                                onClick={() => handleStatusChange(s.id, 'company')}
                                                className={props.filter.status_id && props.filter.customer_type === 'company' && Number(props.filter.status_id) === s.id ? 'active' : ''}
                                                style={{ borderBottom: '1px solid ' + s.color }}
                                            >
                                                {s.translation ? s.translation.name : s.name} {s.company > 0 ? `(${s.company})` : ''}
                                            </a>

                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
                }

                <div className="row">
                    <div className="col select-filter-col">
                        <label>
                            {trans('filters.filterBy')}
                        </label>
                        <select onChange={handleSelectMobileFilter}>
                            <option value="1" selected>
                                {trans('filters.category')}
                            </option>
                            <option value="2">
                                {trans('labels.supplier')}
                            </option>
                            <option value="3">
                                {trans('labels.search')}
                            </option>
                            {props.showDates ?
                                <>
                                    <option value="4">
                                        {trans('labels.dateFrom')}
                                    </option>
                                    <option value="5">
                                        {trans('labels.finalDate')}
                                    </option>
                                </>
                                :
                                ''
                            }
                        </select>
                    </div>
                    <div className="col auto" data-id="1">
                        <label>
                            {trans('labels.category')}
                        </label>
                        <Dropdown
                            inputIdValue={Number(props.filter.category_id)}
                            url="partners/categories/all"
                            renderText={data => {
                                return data.name
                            }}
                            onChange={data => {
                                handleCategory(data);
                            }}
                        />
                    </div>

                    <div className="col auto" data-id="2">
                        <label>
                            {trans('labels.supplier')}

                        </label>
                        <Dropdown
                            inputIdValue={Number(props.filter.supplier_id)}
                            url="suppliers/all"
                            renderText={data => {
                                return data.name
                            }}
                            onChange={data => {
                                handleSupplier(data);
                            }}
                        />
                    </div>

                    <div className="col auto" data-id="3">
                        <label>
                            {trans('labels.search')}
                        </label>
                        <input
                            type="search"
                            name="name"
                            onKeyUp={handleSearch}
                            onChange={handleSearch}
                            value={props.filter.name}
                        />
                    </div>

                    {props.showDates &&
                        <>
                            <div className="col auto" data-id="4">
                                <label>
                                    {trans('labels.dateFrom')}
                                </label>
                                <input
                                    type="date"
                                    name="start"
                                    onKeyUp={handleSearch}
                                    onChange={handleSearch}
                                    value={props.filter.start}
                                />
                            </div>
                            <div className="col auto" data-id="5">
                                <label>
                                    {trans('labels.finalDate')}
                                </label>
                                <input
                                    type="date"
                                    name="end"
                                    onKeyUp={handleSearch}
                                    onChange={handleSearch}
                                    value={props.filter.end}
                                />
                            </div>

                        </>
                    }

                    {props.filter.stock === 'office' &&
                        <>
                            <div className="col auto">
                                <label>
                                    {trans('labels.storage')}
                                </label>
                                <Dropdown
                                    inputIdValue={Number(props.filter.storage_id)}
                                    url="storages/all"
                                    onChange={data => {
                                        handleStorage(data);
                                    }}
                                />
                            </div>
                            <div className="col auto">
                                <label>
                                    {trans('labels.box')}
                                </label>
                                <Dropdown
                                    inputValue={String(props.filter.box)}
                                    url="storage/boxes"
                                    onChange={data => {
                                        handleBox(data);
                                    }}
                                />
                            </div>
                        </>
                    }

                    {props.showSummary &&
                        <>
                            <div className="col auto" data-id="summary" style={{ justifyContent: 'center' }}>
                                <div style={{ display: 'flex' }}>
                                    <div className="col">
                                        <label className="flex">
                                            <input
                                                type="radio"
                                                checked={Number(props.filter.summary) === 0}
                                                onClick={() => toggleSummaryData(false)}
                                            />

                                            <span>{trans('random.inDetail')}</span>
                                        </label>
                                    </div>

                                    <div className="col">
                                        <label className="flex">
                                            <input
                                                type="radio"
                                                checked={Number(props.filter.summary) === 1}
                                                onClick={() => toggleSummaryData(true)}
                                            />

                                            <span>{trans('random.summarized')}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {/* <div className='col auto'>
                        <label>
                            Сортиране
                        </label>
                        <select name='sort' onChange={handleSearch}>
                            <option value=''></option>
                            <option value='PriceAsc'>Цена / Възх</option>
                            <option value='PriceDesc'>Цена / Низх</option>
                            <option value='NameAsc'>Име / Възх</option>
                            <option value='NameDesc'>Име / Низх</option>
                        </select>
                    </div> */}
                </div>
            </div>
        </form>
    )
}

export default Filter;