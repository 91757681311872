import React from 'react';
import { renderToString } from 'react-dom/server'
import loader from '../../../../assets/img/loader.svg';
import { useAuthDataContext } from '../../../../_providers/Auth';
import { trans } from '../../../../_providers/Translation';
import checkIcon from '../../../../assets/img/icons/circle-tick.png'
import circleIcon from '../../../../assets/img/icons/circle.png'
import { useHistory } from 'react-router-dom'

function NeccesarySettings(props) {

    const history = useHistory();
    const auth = useAuthDataContext();

    const settings = auth.neccesarySettings;

    const btnStyle = {
        // background: 'rgb(61 179 158)',
        background: '#0088ff',
        color: '#fff',
        fontWeight: '500',
        fontSize: '13px',
    };

    const getIcon = (check) => {
        return <img src={check ? checkIcon : circleIcon} height="20" />
    }

    const star = <span style={{ color: 'red' }}>*</span>

    const createMarkup = str => {
        return { __html: str };
    }

    return (

        props.loading
            ?
            <img src={loader} alt="loading" />
            :
            <>
                <h2 style={{ textAlign: 'center', fontWeight: 'normal', color: '#2a3e55' }}>
                    {trans('settings.welcome')}
                </h2>
                <h4 style={{ textAlign: 'center', margin: '10px auto', fontWeight: 500, color: '#2a3e55' }}>
                    {trans('settings.info')}
                    {/* <br />
                    <span dangerouslySetInnerHTML={createMarkup(trans('settings.info2', {
                        star: renderToString(star)
                    }))} /> */}
                </h4>
                {/* <div className="alert info">
                    {trans('settings.info')}
                    <br />
                    <span dangerouslySetInnerHTML={createMarkup(trans('settings.info2', {
                        star: renderToString(star)
                    }))} />
                </div> */}
                <div className="table-scroller">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.logo?.filled && settings?.workDays?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.main')}</b> {settings?.logo?.required && star}
                                    <br />
                                    {trans('settings.main_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }}>
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=main')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.employees?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.employees')}</b> {settings?.employees?.required && star}
                                    <br />
                                    {trans('settings.employees_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }} >
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=employees')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.vans?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.vans')}</b> {settings?.vans?.required && star}
                                    <br />
                                    {trans('settings.vans_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }}>
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=settings&sub=vans')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.appliances?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.appliances')}</b> {settings?.appliances?.required && star}
                                    <br />
                                    {trans('settings.appliances_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }}>
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=settings&sub=categories')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.brands?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.brands')}</b> {settings?.brands?.required && star}
                                    <br />
                                    {trans('settings.brands_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }}>
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=settings&sub=brands')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.pricing?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.pricing')}</b>  {settings?.pricing?.required && star}
                                    <br />
                                    {trans('settings.pricing_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }}>
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=settings&sub=pricing')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.visitPeriods?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.visitPeriods')}</b>  {settings?.visitPeriods?.required && star}
                                    <br />
                                    {trans('settings.visitPeriods_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }}>
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=settings&sub=visitperiods')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.reservationTypes?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.reservationTypes')}</b>  {settings?.reservationTypes?.required && star}
                                    <br />
                                    {trans('settings.reservationTypes_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }}>
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=settings&sub=reservationstypes')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.partStatus?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.partStatus')}</b>  {settings?.partStatus?.required && star}
                                    <br />
                                    {trans('settings.partStatus_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }}>
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=settings&sub=storagestatuses')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.payments?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.payments')}</b>  {settings?.payments?.required && star}
                                    <br />
                                    {trans('settings.payments_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }}>
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=settings&sub=payments')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.notifications?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.notifications')}</b>  {settings?.notifications?.required && star}
                                    <br />
                                    {trans('settings.notifications_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }}>
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=settings&sub=notifications')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.addresses?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.addresses')}</b>  {settings?.addresses?.required && star}
                                    <br />
                                    {trans('settings.addresses_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }}>
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=settings&sub=addresses')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50px', padding: '5px 20px' }}>
                                    {getIcon(settings?.other?.filled)}
                                </td>
                                <td>
                                    <b>{trans('settings.other')}</b>  {settings?.other?.required && star}
                                    <br />
                                    {trans('settings.other_info')}
                                </td>
                                <td style={{ textAlign: 'right', padding: '5px 20px' }}>
                                    <a className="btn" style={btnStyle} onClick={e => history.push('/company?tab=settings&sub=others')}>
                                        {trans('settings.button')}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
    )
}

export default NeccesarySettings;