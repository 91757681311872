import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NotReviewed from './/NotReviewed'
import { trans } from '../../../../_providers/Translation';

//style
import '../../../../assets/css/dashboard.css';

//images
import beltIcon from '../../../../assets/img/icons/belt.png'
import Api from '../../../../_helpers/Api';
import Skeleton from 'react-loading-skeleton';

function Reservation(props) {

    const [state, setState] = useState({
        loading: true,
        latest: {},
        data: [],
        total: 0
    });

    const modalNotReviewed = useRef(null);

    useEffect(() => {
        Api.get(`reservations/not-reviewed`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.data,
                    latest: res.data.data[0],
                    total: res.data.total,
                    loading: false
                }));
            });
    }, []);

    const handleView = id => {
        modalNotReviewed.current.show(id);
    }

    return (
        <>
            <NotReviewed
                ref={modalNotReviewed}
                data={state.data}
            />

            {state.loading
                ?
                <Skeleton height={130} className="pannel" />
                :
                <div className="pannel reservation">
                    {state.total === 0
                        ?
                        <div className="second-col">
                            <h4>{trans('random.noNewReservations')}</h4>
                        </div>
                        :
                        <>
                            <div className="first-col">
                                <div className="belt">
                                    <span>
                                        {state.total}
                                    </span>
                                    <img src={beltIcon} alt="belt" />
                                </div>
                                <h3>
                                    {trans('random.newReservation')}
                                </h3>
                            </div>
                            <div className="second-col">
                                <h4>
                                    {trans('random.client')}
                                </h4>
                                <p>
                                    {state.latest.customer.name}
                                </p>
                                <h4>
                                    {trans('random.address')}
                                </h4>
                                <p>
                                    {state.latest.address}
                                </p>
                            </div>
                            <div className="second-col third-col">
                                <h4>
                                    {trans('random.problem')}
                                </h4>
                                <p>
                                    {state.latest.description}
                                </p>
                            </div>

                            <div className="fourth-col">
                                <Link onClick={handleView}>
                                    {trans('buttons.viewDetails')}
                                </Link>
                            </div>
                        </>
                    }
                </div>
            }
        </>

    )
}

export default Reservation;