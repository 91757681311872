import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../_helpers/Api';
import { useAuthDataContext } from '../../../../_providers/Auth';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import { trans } from '../../../../_providers/Translation';

function Transfer(props, ref) {

    const auth = useAuthDataContext();

    const partnerId = auth.data.user.partner_id;

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        id: null,
        data: null,
        loading: false,
        update: false,
    });

    const [vans, setVans] = useState({
        loading: true,
        data: [],
    });


    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: (id) => {
            setPopup(prev => ({
                ...prev,
                id: id,
                loading: false,
                update: Math.random().toString()
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // извлича текущите данни, за да се попълнят полетата
    useEffect(() => {

        if (popup.id) {
            Api.get(`storage/find-part?id=${popup.id}`)
                .then(res => {

                    let data = res.data;

                    data.max_quantity = data.quantity;

                    setPopup(prev => ({
                        ...prev,
                        data: data,
                    }));
                });
        }

    }, [popup.update]);

    useEffect(() => {
        loadVans();
    }, []);

    const loadVans = () => {
        let url = 'vans/all?paginate=0';

        Api.get(url)
            .then(res => {
                setVans(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data,
                }));
            }).catch(err => {
                setVans(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();


        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'storage/transfer-part';

        let data = new FormData(e.target);

        data.append('id', popup.id);

        Api.post(url, data)
            .then(res => {

                props.handleRefresh();

                handleHide(e);

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = Number(e.target.value);

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form className={`${popup.modal ? 'show' : ''} popup-primary large`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.transferPart')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container x2">
                    <div className="col">
                        <label>
                            {trans('labels.part')}
                        </label>
                        <input
                            type="text"
                            value={popup.data && popup.data.part.name ? popup.data.part.name : ''}
                            disabled={true}
                        />
                    </div>

                    <div className="col">
                        <label>
                            {trans('labels.partNumber')}
                        </label>
                        <input
                            type="text"
                            value={popup.data && popup.data.part && popup.data.part.number ? popup.data.part.number : ''}
                            disabled={true}
                        />
                    </div>

                </div>

                <br />

                <div className="popup-container x2">
                    <div className="col">
                        <label>
                            {trans('labels.quantity')}
                        </label>
                        <input
                            id="quantity"
                            type="number"
                            min="1"
                            max={popup.data && popup.data.max_quantity}
                            step="1"
                            name="quantity"
                            value={popup.data && popup.data.quantity ? popup.data.quantity : 1}
                            className={validations && validations.quantity ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col">
                        <label>
                            {trans('labels.van')}
                        </label>
                        <select
                            name="van_id"
                            className={validations && validations.van_id ? 'validate' : ''}
                        >
                            {vans.data.map(v => (
                                <option key={'van-' + v.id} value={v.id}>{v.reg_no} {v.employee && `(${v.employee.name})`}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Transfer);