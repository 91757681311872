import React from 'react';
import { trans } from '../../../../_providers/Translation'

function Filter(props) {

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    const handleDate = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    return (
        <form className="filter-standard schedule-filter">
            <div className="col">
                <label htmlFor="method">
                    {trans('filters.type')}
                </label>
                <select
                    id="method"
                    name="method"
                    value={props.data.method}
                    onChange={handleSearch}
                >
                    <option value="">
                        {trans('labels.all')}
                    </option>
                    <option value="sms">
                        SMS
                    </option>
                    <option value="email">
                        Email
                    </option>
                </select>
            </div>
            <div className="col">
                <label htmlFor="from">
                    {trans('filters.from')}
                </label>
                <input
                    id="from"
                    type="date"
                    name="date"
                    value={props.data.date}
                    onChange={handleDate}
                />
            </div>
            <div className="col ">
                <label htmlFor="to">
                    {trans('filters.to')}
                </label>
                <input
                    id="to"
                    type="date"
                    name="deadline"
                    value={props.data.deadline}
                    onChange={handleDate}
                />
            </div>

            <div className="col">
                <label htmlFor="search">{trans('filters.search')}</label>
                <input
                    id="search"
                    type="search"
                    name="text"
                    value={props.data.text}
                    onChange={handleSearch}
                />
            </div>
        </form>
    )
}

export default Filter;