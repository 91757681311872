import React, { useEffect } from 'react'
import Dropdown from '../../../partials/Dropdown';
import { plural, trans } from '../../../../_providers/Translation';

function FilterExpenses(props) {

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    const handleDropdownChange = data => {
        let val = data.id || '';

        if (data.id || data.name === '') {
            props.handleSearch('category_id', val);
        }
    }

    const toggleMissingReceipts = () => {
        if (props.filter.with_missing_receipt) {
            props.handleSearch('with_missing_receipt', 0);
        } else {
            props.handleSearch('with_missing_receipt', 1);
        }
    }

    const handleSelectMobileFilter = e => {
        let selectedValue = e.target.value
        let fields = [...document.querySelectorAll('.filter-standard .col')]
        fields.map(field => {
            if (field.getAttribute('data-id') == selectedValue) field.style.display = 'flex'
            else if (field.getAttribute('data-id')) field.style.display = 'none'
        })
    }

    return (
        <>
            <div className="filter-standard">
                <div className="col select-filter-col">
                    <label>
                        {trans('filters.filterBy')}
                    </label>
                    <select onChange={handleSelectMobileFilter}>
                        <option value="1">
                            {trans('filters.search')}
                        </option>
                        <option value="2">
                            {trans('filters.startDate')}
                        </option>
                        <option value="3">
                            {trans('filters.endDate')}
                        </option>
                        {/* <option value="4">
                            {trans('tables.netSum')}
                        </option>
                        <option value="4">
                            {trans('tables.grossSum')}
                        </option> */}
                        <option value="6">
                            {trans('filters.category')}
                        </option>
                    </select>
                </div>

                <div className="col" data-id="1">
                    <label htmlFor="text">
                        {trans('labels.search')}
                    </label>
                    <input
                        type="search"
                        name="text"
                        onChange={handleSearch}
                        value={props.filter.text || ''}
                        placeholder={[trans('tables.description'), trans('tables.price')].join(', ')}
                    />
                </div>

                <div className="col" data-id="2">
                    <label htmlFor="start_date">
                        {trans('labels.from')}
                    </label>
                    <input
                        type="date"
                        name="start_date"
                        onChange={handleSearch}
                        value={props.filter.start_date || ''}
                    />
                </div>

                <div className="col" data-id="3">
                    <label htmlFor="end">
                        {trans('labels.to')}
                    </label>
                    <input
                        type="date"
                        name="end_date"
                        onChange={handleSearch}
                        value={props.filter.end_date || ''}
                    />
                </div>

                {/* <div className="col" data-id="4">
                    <label htmlFor="price">
                        {trans('tables.netSum')}
                    </label>
                    <input
                        type="number"
                        name="price"
                        step="0.01"
                        onChange={handleSearch}
                        value={props.filter.price || ''}
                    />
                </div>

                <div className="col" data-id="5">
                    <label htmlFor="gross_price">
                        {trans('tables.grossSum')}
                    </label>
                    <input
                        type="number"
                        name="gross_price"
                        step="0.01"
                        onChange={handleSearch}
                        value={props.filter.gross_price || ''}
                    />
                </div>
 */}

                <div className="col" data-id="6">
                    <label htmlFor="">
                        {trans('labels.category')}
                    </label>
                    <Dropdown
                        inputIdName="category_id"
                        inputIdValue={props.filter.category_id ? props.filter.category_id : ''}
                        url="expenses/categories/all"
                        params={{
                            id: props.filter?.category_id,
                            limit: 100
                        }}
                        onChange={handleDropdownChange}
                    />
                </div>

                {props.missingReceipt > 0
                    ?
                    <div className="col" data-id="4" style={{ marginTop: 'auto' }}>
                        <button
                            className={['yellow', props.filter.with_missing_receipt ? 'active' : ''].join(' ')}
                            onClick={toggleMissingReceipts}
                        >
                            {plural('expenses.missing_receipt', props.missingReceipt, {
                                count: props.missingReceipt
                            })}
                        </button>
                    </div>
                    :
                    ''
                }

            </div>
        </>
    )
}

export default React.memo(FilterExpenses, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})