import React, { useRef, useState, useEffect } from 'react';
import editIcon from '../../../../assets/img/icons/edit-yellow.png';
import Modal from './modals/Preferences';
import Skeleton from 'react-loading-skeleton';
import { useLanguageContext } from '../../../../_providers/Language';
import { trans } from '../../../../_providers/Translation';

function Preferences(props) {

    const modalRef = useRef(null);

    const showModal = () => {
        let modal = modalRef.current;

        modal.show(props.data);

        modal.onSave(settings => {
            props.handleUpdate(settings);
        });
    }

    return (
        <div style={{ width: '100%' }}>


            <div className="head">
                <h4>{trans('headings.preferences')}</h4>
                <a onClick={showModal}>
                    <img src={editIcon} alt="" />
                </a>
            </div>

            <Modal
                ref={modalRef}
            />

            {props.loading
                ?
                <Skeleton height={35} count={2} />
                :
                <>

                    <div className="table-scroller" style={{ overflow: 'hidden' }}>
                        <table className="settings">

                            <tbody>
                                <tr>
                                    <td>
                                        <b>{trans('random.language')}</b>
                                    </td>
                                    <td>
                                        {props.data.language ? props.data.language.name : trans('messages.notSelected')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{trans('random.timeZone')}</b>
                                    </td>
                                    <td>
                                        {props.data.timezone ? props.data.timezone : trans('messages.notSelected')}
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </div>
    )

}

export default Preferences