import React from 'react';
import Options from './Options';
import { trans } from '../../../../_providers/Translation'

function Manuals(props) {

    return (
        <>
            <div className="table-scroller">
                <table>
                    <thead>
                        <tr>
                            <th>{trans('tables.category')}</th>
                            <th>{trans('tables.make')}</th>
                            <th>{trans('tables.model')}</th>
                            <th>{trans('tables.file')}</th>
                            <th className="options">{trans('tables.options')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map(manual =>
                            <tr key={'manual-' + manual.id}>
                                <td>{manual.category ? manual.category.name : ''}</td>
                                <td>{manual.brand.name}</td>
                                <td>
                                    {manual.models}
                                </td>
                                <td>
                                    {Array.isArray(manual.files) &&
                                        manual.files.map(file =>
                                            <a key={'f-' + file.id} className="btn" href={file.file_url} style={{ margin: '0 3px' }}>
                                                {file.filename}
                                            </a>
                                        )
                                    }
                                </td>

                                <td className="options">
                                    <Options
                                        id={manual.id}
                                        handleEdit={props.handleEdit}
                                        handleDelete={props.handleDelete}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        </>
    )
}

export default Manuals;