import React, { useEffect, useRef, useState } from 'react'
import Dropdown from '../../../partials/Dropdown'
import { trans } from '../../../../_providers/Translation'

function Filter(props) {

    const [categoriesUrl, setCategoriesUrl] = useState(null);
    const [brandsUrl, setBrandsUrl] = useState(null);

    const brandsRef = useRef(null);

    useEffect(() => {
        let cUrl;
        let bUrl;

        switch (props.tab) {
            case 'manuals':
                cUrl = 'appliances/categories/manuals-count';
                bUrl = 'appliances/brands/manuals-count';
                break;
            case 'errors':
                cUrl = 'appliances/categories/errors-count';
                bUrl = 'appliances/brands/errors-count';
                break;
            case 'problems':
                cUrl = 'appliances/categories/problems-count';
                bUrl = 'appliances/brands/problems-count';
                break;
        }

        setCategoriesUrl(cUrl);
        setBrandsUrl(bUrl);
    }, [props.tab]);

    const handleCategory = data => {
        let val = data.id || ''

        if (data.id || data.name === '') {
            props.handleCategory(val)
        }

        brandsRef.current.reset();
    }

    const handleBrand = data => {
        let val = data.id || ''

        if (data.id || data.name === '') {
            props.handleBrand(val)
        }
    }

    const handleModel = data => {
        let val = data.id || ''

        if (data.id || data.name === '') {
            props.handleModel(val)
        }
    }

    const handleSelectMobileFilter = e => {
        let selectedValue = e.target.value
        let fields = [...document.querySelectorAll('.filter-standard .col')]
        fields.map(field => {
            if (field.getAttribute('data-id') == selectedValue) field.style.display = 'flex'
            else if (field.getAttribute('data-id')) field.style.display = 'none'
        })
    }


    return (
        <form className="filter-standard">
            <div className="col select-filter-col">
                <label>
                    {trans('filters.filterBy')}
                </label>
                <select onChange={handleSelectMobileFilter}>
                    <option value="1" selected>
                        {trans('filters.category')}
                    </option>
                    <option value="2" selected>
                        {trans('filters.make')}
                    </option>
                    <option value="3">
                        {trans('filters.model')}
                    </option>
                    <option value="4">
                        {trans('filters.text')}
                    </option>
                </select>
            </div>

            <div className="col" data-id="1">
                <label htmlFor="category">
                    {trans('filters.category')}
                </label>
                <Dropdown
                    key={categoriesUrl}
                    inputIdName="category_id"
                    inputIdValue={Number(props.filter.category_id)}
                    url={categoriesUrl}
                    renderText={data => {
                        return `${data.name} (${data.manuals_count || data.errormessages_count || data.problems_count || 0})`
                    }}
                    onChange={data => handleCategory(data)}
                />
            </div>

            <div className="col" data-id="2">
                <label htmlFor="brand">
                    {trans('filters.make')}
                </label>
                <Dropdown
                    ref={brandsRef}
                    key={brandsUrl}
                    inputIdName="brand_id"
                    inputIdValue={Number(props.filter.brand_id)}
                    url={brandsUrl}
                    params={{
                        category_id: Number(props.filter.category_id) || ''
                    }}
                    renderText={data => {
                        return `${data.name} (${data.manuals_count || data.errormessages_count || data.problems_count || 0})`
                    }}
                    onChange={data => handleBrand(data)}
                />
            </div>

            <div className="col" data-id="3">
                <label htmlFor="model">
                    {trans('filters.model')}
                </label>
                <input
                    type="search"
                    name="model"
                    onChange={e => props.handleSearch(e.target.name, e.target.value)}
                    value={props.filter.model ? props.filter.model : ''}
                />
            </div>

            {props.tab !== 'manuals'
                ?
                <div className="col" data-id="3">
                    <label htmlFor="model">
                        {trans('filters.text')}
                    </label>
                    <input
                        type="search"
                        name="search"
                        onChange={e => props.handleSearch(e.target.name, e.target.value)}
                        value={props.filter.search ? props.filter.search : ''}
                    />
                </div>
                :
                ''
            }

        </form>
    )
}

export default React.memo(Filter, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})