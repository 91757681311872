import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import { trans } from '../../../../_providers/Translation'

//images
import menuIcon from '../../../../assets/img/icons/table-menu.png';
import editIcon from '../../../../assets/img/icons/edit.png';
import deleteIcon from '../../../../assets/img/icons/delete.png';
import singleMailIcon from '../../../../assets/img/icons/single-mail.png';
import groupMailIcon from '../../../../assets/img/icons/group-mail.png';

function Index(props) {

    const [table, setTable] = useState({
        options: null
    });

    const handleTableMenu = e => {

        setTable(table => {
            return {
                ...table,
                options: e.currentTarget
            }
        });
    };

    const handleCloseMenu = () => {
        setTable(table => {
            return {
                ...table,
                options: null
            }
        });
    };

    const handleEdit = id => {
        handleCloseMenu();

        props.handleEdit(id);
    }

    const handleDelete = id => {
        handleCloseMenu();

        props.handleDelete(id);
    }

    const handleComplete = id => {
        handleCloseMenu();

        props.handleComplete(id);
    }

    const handleTogglePause = id => {
        handleCloseMenu();

        props.handleTogglePause(id);
    }

    const handleView = data => {
        handleCloseMenu();

        props.handleView(data);
    }

    const handleStopRepeat = id => {
        handleCloseMenu();

        props.handleStopRepeat(id);
    }

    return (
        <>
            <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />
            <Menu
                id={'menu-' + props.id}
                anchorEl={table.options}
                keepMounted
                open={Boolean(table.options)}
                onClose={handleCloseMenu}
            >

                {!props.completed && props.canMarkAsCompleted &&
                    <div className="menu-option" onClick={() => handleComplete(props.id)}>
                        <img src={singleMailIcon} alt="single mail" />
                        {trans('buttons.finish')}
                    </div>
                }

                <div className="menu-option" onClick={() => handleView(props.data)}>
                    <img src={groupMailIcon} alt="group mail" />
                    {trans('buttons.view')}
                </div>

                {!props.completed &&
                    < div className="menu-option" onClick={() => handleTogglePause(props.id)}>
                        <img src={editIcon} alt="group mail" />
                        {props.paused ? trans('buttons.removePause') : trans('buttons.pause')}
                    </div>
                }

                {Number(props.data.repeat) === 1 || (props.data.parent && Number(props.data.parent.repeat) === 1)
                    ?
                    <div className="menu-option" onClick={() => handleStopRepeat(props.id)}>
                        <img src={editIcon} alt="edit" />
                        {trans('buttons.noRepeat')}
                    </div>
                    :
                    ''
                }

                <div className="menu-option" onClick={() => handleEdit(props.id)}>
                    <img src={editIcon} alt="edit" />
                    {trans('buttons.edit')}
                </div>

                <div className="menu-option" onClick={() => handleDelete(props.id)}>
                    <img src={deleteIcon} alt="delete" />
                    {trans('buttons.delete')}
                </div>
            </Menu>
        </>
    )
}

export default Index;
