import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import L, { LatLng, marker } from "leaflet";
import 'leaflet-routing-machine'
import '../../../../../assets/L.Routing.OpenRouteService'
import { trans } from '../../../../../_providers/Translation';

//images
import closeIcon from '../../../../../assets/img/icons/close.png'
import markerImg from '../../../../../assets/img/leeflet/marker-icon.png';

import '../../../../../assets/css/modals/employees.css'
import '../../../../../assets/css/leeflet.css';
import '../../../../../assets/css/leaflet-routing-machine.css';
import { Maps } from '../../../../../_config/maps';
import Skeleton from 'react-loading-skeleton';
import Axios from 'axios';
import loader from '../../../../../assets/img/loader.svg';
import { Loader } from '@googlemaps/js-api-loader';

let timeout;

let cc = {};

const markerIcon = L.icon({
    iconUrl: markerImg,
    // shadowUrl: 'leaf-shadow.png',
    iconSize: [25, 41], // size of the icon
});

const router = new L.Routing.openrouteservice(Maps.openrouteservice);

// const control = L.Routing.control({
//     router: router,
//     waypoints: [
//         // L.latLng(43.409022, 24.6180123),
//         // L.latLng(43.29449, 26.6729),
//         L.latLng(43.0820584, 25.6321312),
//         L.latLng(43.0820584, 25.6321312)
//     ],
//     autoRoute: false,
//     // geocoder: L.Control.Geocoder.nominatim(),
//     routeWhileDragging: false,
//     // reverseWaypoints: true,
//     showAlternatives: false,
//     fitSelectedRoutes: true,
//     altLineOptions: {
//         styles: [
//             { color: 'black', opacity: 0.15, weight: 9 },
//             { color: 'white', opacity: 0.8, weight: 6 },
//             { color: 'blue', opacity: 0.5, weight: 2 }
//         ]
//     }
// });

const googleMapsLoader = new Loader({
    apiKey: Maps.google,
    version: "weekly",
    libraries: ["places"],
});

function Address(props, ref) {

    const inputRef = useRef(null);

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        loading: false,
        selected: null,
        lat: null,
        lon: null,
        place_id: null,
        data: [],
        dataLoaded: false,
        onSave: null
    });

    const [google, setGoogle] = useState({
        AutocompleteService: null,
        Geocoder: null,
        places: null,
    });

    const initMarkers = {
        reservation: null
    }

    const [map, setMap] = useState(null);
    const [layerGroup, setLayerGroup] = useState(null);
    const [markers, setMarkers] = useState(initMarkers);

    useImperativeHandle(ref, () => ({
        open: () => {
            handleOpen()
        },
        close: () => {
            handleClose()
        },
        onSave: fn => {
            setState(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }))

    useEffect(() => {

        let m = L.map("address-map", {
            center: [42.70060440808085, 23.318481445312504],
            zoom: 5,
            layers: [
                L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                })
            ]
        });

        setLayerGroup(L.layerGroup().addTo(m));

        setMap(m);

    }, []);

    useEffect(() => {
        if (map) {
            map.on('click', setMarker);
        }
    }, [map]);

    // местополежение на резервацията
    useEffect(() => {
        if (map) {

            if (state.lat && state.lon) {

                // console.log(props.params.lon)

                let marker = L.marker([state.lat, state.lon], {
                    icon: markerIcon,
                });

                marker.bindPopup(trans('random.reservationLocation'), {
                    autoClose: false,
                    closeOnClick: false
                }).openPopup()

                map.setView(marker.getLatLng(), 20);

                setMarkers(prev => ({
                    ...prev,
                    reservation: marker
                }));

                console.log(marker)

            }
        }

    }, [map, state.lat, state.lon]);

    // сетване на маркерите и пътя
    useEffect(() => {
        if (map && layerGroup) {
            layerGroup.clearLayers();

            let allMarkers = getAllMarkers();

            if (allMarkers.length) {
                allMarkers.forEach(m => {
                    m.addTo(layerGroup);
                });
            }

        }
    }, [markers]);

    useEffect(() => {
        handleSelect(props.selected, props.params.lat, props.params.lon, selected => {
            // saveSelected(selected, props.params.lat, props.params.lon);
        });
    }, [props.selected, props.params.lat, props.params.lon]);

    // при показване на попъпа
    useEffect(() => {
        if (state.overlay && state.modal) {
            fitToMarkers();
        }
    }, [state.overlay, state.modal, markers]);

    useEffect(() => {
        googleMapsLoader.load()
            .then(google => {
                setGoogle({
                    AutocompleteService: new google.maps.places.AutocompleteService(),
                    Geocoder: new google.maps.Geocoder(),
                    maps: google.maps
                });
            });
    }, []);

    const handleOpen = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)

    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 50)
    }

    const fitToMarkers = () => {
        if (map) {
            map.invalidateSize();

            inputRef.current.focus();

            // използва се за повече от 1 маркер
            // let allMarkers = getAllMarkers();

            // if (allMarkers.length > 1) {

            //     let group = new L.featureGroup(allMarkers);

            //     clearTimeout(timeout);

            //     console.log('fit')

            //     // timeout = setTimeout(() => {
            //     map.fitBounds(group.getBounds());
            //     // }, 100);
            // }
        }
    }

    const getAllMarkers = () => {

        let array = [];

        if (markers.reservation) {
            array.push(markers.reservation);
        }

        return array;
    }

    const handleSelect = (name, lat, lon, callback = null) => {

        setState(prev => ({
            ...prev,
            selected: name,
            lat: lat,
            lon: lon
        }));

        if (typeof callback === 'function') {
            setTimeout(() => {
                callback(name);
            });
        }
    }

    const handleSelectLatLon = (lat, lon, place_id = null) => {
        setState(prev => ({
            ...prev,
            lat: lat,
            lon: lon,
            place_id: place_id
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        saveSelected(state.selected, state.lat, state.lon);

        handleClose();
    }

    const saveSelected = (selected, lat, lon) => {
        if (typeof props.onSave === 'function') {
            props.onSave(selected, lat, lon);
        } else if (typeof state.onSave === 'function') {
            state.onSave(selected, lat, lon);
        }
    }

    const getRoute = (fromLatLon, toLatLon, callback) => {

        let key = String(fromLatLon.lat) + String(fromLatLon.lng) + String(toLatLon.lat) + String(toLatLon.lng);

        if (cc[key]) {
            return callback(cc[key]);
        }

        let waypoints = [];

        waypoints.push({ latLng: fromLatLon });
        waypoints.push({ latLng: toLatLon });

        router.route(waypoints, (err, routes) => {
            if (err) {
                console.log(err);
            } else {
                cc[key] = routes[0];

                return callback(routes[0]);
            }
        });

        return callback(null);
    }

    const handleSearch = e => {
        let name = e.target.value;

        setState(prev => ({
            ...prev,
            selected: name,
            loading: true,
        }));

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            // findInOSM(name);
            findInGoogle(name);
        }, 900);
    }

    const findInOSM = (name) => {
        Axios.get('https://nominatim.openstreetmap.org/search', {
            params: {
                q: name,
                limit: 50,
                format: 'json'
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data
            }));
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false
            }))
        });
    }

    const findInGoogle = async (name) => {
        if (!google.AutocompleteService) {
            return;
        }

        // let sessionToken = new google.maps.places.AutocompleteSessionToken();

        // console.log(sessionToken);

        google.AutocompleteService.getQueryPredictions({ input: name }, async (predictions, status) => {

            if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
                setState(prev => ({
                    ...prev,
                    data: [],
                    loading: false,
                }));

                return;
            }

            predictions = predictions.map((prediction) => {
                prediction.display_name = prediction.description;

                return prediction;
            });

            // predictions = predictions.filter(p => p.geocode.length > 0);

            console.log(predictions);

            setState(prev => ({
                ...prev,
                data: predictions,
                loading: false,
            }));
        });
    }

    const handleSelectGooglePlace = place => {
        decodeGooglePlace(place, (results) => {
            let geocode = results[0];

            let lat = geocode.geometry.location.lat();
            let lon = geocode.geometry.location.lng();

            handleSelectLatLon(lat, lon, place.place_id);

        });
    }

    const decodeGooglePlace = (place, callback) => {
        if (!google.Geocoder) {
            return;
        }

        google.Geocoder
            .geocode({ placeId: place.place_id })
            .then(({ results }) => {
                return callback(results);
            });
    };

    const setMarker = e => {
        if (e.latlng) {
            handleSelectLatLon(e.latlng.lat, e.latlng.lng)
        }
    }

    return (
        <>

            <div className={`${state.overlay ? 'visible' : ''} overlay`}>

                <div className={`${state.modal ? 'show' : ''} popup-primary`}>

                    <h2 className="head">
                        {trans('headings.address')}
                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleClose} />
                    </h2>

                    <div className="alert info">
                        {trans('messages.addressInfo')}
                    </div>
                    <div id="map-wrapper">
                        <div className="left">
                            <input
                                ref={inputRef}
                                type="search"
                                value={state.selected ? state.selected : ''}
                                onChange={handleSearch}
                                style={{
                                    width: '100%'
                                }}
                            />
                            {state.loading
                                ?
                                <img className="loader" src={loader} />
                                :
                                <ul>
                                    {state.data.map(e =>
                                        <li
                                            key={'e-' + e.place_id}
                                            // onClick={() => handleSelectLatLon(e.lat, e.lon, e.place_id)}
                                            onClick={() => handleSelectGooglePlace(e)}
                                            // className={String(e.lat) === String(state.lat) && String(e.lon) === String(state.lon) ? 'active' : ''}
                                            className={e.place_id === state.place_id ? 'active' : ''}
                                        >
                                            <span>
                                                {e.display_name}
                                            </span>

                                        </li>
                                    )}

                                </ul>
                            }
                        </div>

                        <div className="right">
                            <div id="address-map" className="map"></div>
                        </div>

                    </div>

                    <div className="footer align-center">
                        <button onClick={handleClose}>
                            {trans('buttons.cancel')}
                        </button>
                        <button className="button-primary" onClick={handleSave}>
                            {trans('buttons.save')}
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default React.memo(forwardRef(Address), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});