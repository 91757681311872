import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../../../_helpers/Api';

//images
import docIcon from '../../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../../assets/img/icons/close.png';
import loader from '../../../../../../assets/img/loader.svg';
import Ckeditor from '../../../../../partials/Ckeditor';
import { trans } from '../../../../../../_providers/Translation';

function Others(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: null,
        loading: false,
        onSave: null
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: data => {
            setPopup(prev => ({
                ...prev,
                loading: false,
                data: data
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'partners/settings/edit';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave();
                }

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = (e, onlyDigits = false) => {
        let name = e.target.name;
        let val = e.target.value;

        if (onlyDigits) {
            val = val.replace(/[^0-9]/g, '');
        }

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary medium`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.otherSettings')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.vatNo')}
                        </label>

                        <input
                            type="text"
                            name="vat_number"
                            value={popup.data && popup.data.vat_number ? popup.data.vat_number : ''}
                            className={validations && validations.vat_number ? 'validate' : ''}
                            onChange={handleInputChange}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.mol')}
                        </label>

                        <input
                            type="text"
                            name="mol"
                            value={popup.data && popup.data.mol ? popup.data.mol : ''}
                            className={validations && validations.mol ? 'validate' : ''}
                            onChange={handleInputChange}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.proformaStartNumber')}
                        </label>

                        <input
                            type="text"
                            name="proforma_start_number"
                            value={popup.data && popup.data.proforma_start_number ? popup.data.proforma_start_number : ''}
                            className={validations && validations.proforma_start_number ? 'validate' : ''}
                            onChange={e => handleInputChange(e, true)}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.invoiceStartNumber')}
                        </label>

                        <input
                            type="text"
                            name="invoice_start_number"
                            value={popup.data && popup.data.invoice_start_number ? popup.data.invoice_start_number : ''}
                            className={validations && validations.invoice_start_number ? 'validate' : ''}
                            onChange={e => handleInputChange(e, true)}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.invoiceDeadline')}
                        </label>

                        <input
                            type="text"
                            name="invoice_deadline"
                            value={popup.data && popup.data.invoice_deadline ? popup.data.invoice_deadline : ''}
                            className={validations && validations.invoice_deadline ? 'validate' : ''}
                            onChange={handleInputChange}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.invoiceTerms')}
                        </label>

                        <textarea
                            name="invoice_terms"
                            value={popup.data && popup.data.invoice_terms ? popup.data.invoice_terms : ''}
                            className={validations && validations.invoice_terms ? 'validate' : ''}
                            onChange={handleInputChange}
                        >
                        </textarea>
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.warrantyTerms')}
                        </label>

                        <Ckeditor
                            adkey="warranty_terms"
                            name="warranty_terms"
                            value={popup.data && popup.data.warranty_terms ? popup.data.warranty_terms : ''}
                        />

                        {/* <textarea
                            name="warranty_terms"
                            value={popup.data && popup.data.warranty_terms ? popup.data.warranty_terms : ''}
                            className={validations && validations.warranty_terms ? 'validate' : ''}
                            onChange={handleInputChange}
                        >
                        </textarea> */}
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.reservationTerms')}
                        </label>

                        <Ckeditor
                            adkey="reservation_terms"
                            name="reservation_terms"
                            value={popup.data && popup.data.reservation_terms ? popup.data.reservation_terms : ''}
                        />

                        {/* <textarea
                            name="reservation_terms"
                            value={popup.data && popup.data.reservation_terms ? popup.data.reservation_terms : ''}
                            className={validations && validations.reservation_terms ? 'validate' : ''}
                            onChange={handleInputChange}
                        >
                        </textarea> */}
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.companySignature')}
                        </label>

                        <Ckeditor
                            adkey="company_signature"
                            name="company_signature"
                            value={popup.data && popup.data.company_signature ? popup.data.company_signature : ''}
                        />

                        {/* <textarea
                            name="company_signature"
                            value={popup.data && popup.data.company_signature ? popup.data.company_signature : ''}
                            className={validations && validations.company_signature ? 'validate' : ''}
                            onChange={handleInputChange}
                        >
                        </textarea> */}
                    </div>
                </div>

                <br />
                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.allowAutoReassign')}
                        </label>
                        <select
                            name="allow_auto_reassign"
                            value={popup.data && typeof popup.data.allow_auto_reassign !== 'undefined' ? popup.data.allow_auto_reassign : 1}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>

                <br />
                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.allowSubscriptionsPage')}
                        </label>
                        <select
                            name="has_subscriptions_page"
                            value={popup.data && typeof popup.data.has_subscriptions_page !== 'undefined' ? popup.data.has_subscriptions_page : 1}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Others);