import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Api from '../../../../_helpers/Api';
import Pagination from '../../../Pagination';
import Filter from '../../invoices/partials/Filter';
import loader from '../../../../assets/img/loader.svg';
import NoDataFound from '../../../partials/NoDataFound';
import Options from './ReservationOptions';
import FilterReservations from './FilterReservations';
import Success from '../../../modals/messages/Success'
import { trans } from '../../../../_providers/Translation';

let timeout;

function Reservations(props) {

    const params = useParams();
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const status = searchParams.get('status') || '';
    const date = searchParams.get('date') || '';
    const deadline = searchParams.get('deadline') || '';
    const search = searchParams.get('search') || '';

    const selectRef = useRef(null);
    const successSendModalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        page: 1,
        data: [],
        total: 0,
        pages: 0,
        filter: {

        },
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                status: status,
                date: date,
                deadline: deadline,
                search: search
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    // useEffect(() => {
    //     console.log(state.filter);
    // }, [state.filter]);

    useEffect(() => {
        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'partners/relationships/reservations?related_partner_id=' + params.id + '&page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {

                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                }))
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            })
    }

    const refreshData = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    // Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
            page: 1,
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: Math.random().toString() }));
    }

    const handleShow = id => {
        history.push('/reservations/edit/' + id);
    }

    const handleAction = () => {
        let action = selectRef.current.value;
        let selected = [];

        document.querySelectorAll('.selected:checked').forEach(el => {
            selected.push(Number(el.value));
        })

        switch (action) {
            case 'send':
                send(selected);
                break;
        }
    }

    const send = ids => {
        Api.post('reservations/send-reassign-info', {
            related_partner_id: params.id,
            ids: ids,
        }).then(() => {
            let modal = successSendModalRef.current;

            modal.open();
            modal.onClose(() => {
                if (typeof props.refreshData === 'function') {
                    props.refreshData();
                }
            });
        })
    }


    return (
        <>

            <Success
                ref={successSendModalRef}
                mainMessage={trans('headings.reservationSent')}
            />

            <FilterReservations
                filter={state.filter}
                handleSearch={handleSearch}
            />

            {state.loading
                ?
                <img src={loader} alt="loading" />
                :
                state.data.length === 0
                    ?
                    <NoDataFound />
                    :
                    <>
                        <div className="collapsable-table">
                            <table className="collapsable active">
                                <thead>
                                    <tr>
                                        <th style={{
                                            width: '20px'
                                        }}></th>
                                        <th>
                                            ID
                                        </th>
                                        <th>
                                            {trans('tables.number')}
                                        </th>
                                        <th>
                                            {trans('tables.address')}
                                        </th>
                                        <th>
                                            {trans('tables.visitDate')}
                                        </th>
                                        <th>
                                            {trans('tables.visitHour')}
                                        </th>
                                        <th>
                                            {trans('tables.initialStatus')}
                                        </th>
                                        <th>
                                            {trans('tables.finalStatus')}
                                        </th>
                                        <th className="right">
                                            {trans('tables.tax2')}
                                        </th>
                                        <th className="options">
                                            {trans('tables.options')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.map(r =>
                                        <tr
                                            key={'r-' + r.id}
                                            style={{ color: r.color }}
                                        >
                                            <td style={{
                                                width: '20px'
                                            }}>
                                                <input
                                                    type="checkbox"
                                                    className="selected"
                                                    name="selected[]"
                                                    value={r.id}
                                                />
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                {r.id}
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                <Link
                                                    to={`/reservations/edit/${r.id}`}
                                                    className="btn"
                                                    style={{ background: r.color, color: r.text_color }}
                                                >
                                                    {r.reference_code}
                                                </Link>
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                {r.full_address}
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                {r.visit_date
                                                    ? `${r.visit_date_dmy}`
                                                    : '-'
                                                }
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                {r.visitperiod
                                                    ? `${r.visitperiod.period}`
                                                    : '-'
                                                }
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                {r.initialstatus && r.initialstatus.translation.name}
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                {r.finalstatus && r.finalstatus.translation.name}
                                            </td>
                                            <td className="right" onClick={() => handleShow(r.id)}>
                                                {r.reassign_price_formatted}
                                                {r.currency ? r.currency.name : ''}
                                            </td>
                                            <td>
                                                <Options
                                                    id={r.id}
                                                    data={r}
                                                    masterId={r.id}
                                                    handleRefresh={refreshData}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <br />
                        <div className="actions">
                            <select
                                ref={selectRef}
                            >
                                <option></option>
                                <option value="send">Изпрати на изпълнителя</option>
                            </select>
                            <button
                                onClick={handleAction}
                            >
                                Изпълни за избраните
                            </button>
                        </div>
                    </>
            }

            {!state.loading
                ?
                <Pagination
                    pages={state.pages}
                    page={state.page}
                    handlePage={handlePage}
                />
                :
                ''
            }
        </>
    )
}

export default Reservations;