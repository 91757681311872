import React from 'react'
import { trans } from '../../../../_providers/Translation';

function Filter(props) {

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    const handleSelectMobileFilter = e => {
        let selectedValue = e.target.value
        let fields = [...document.querySelectorAll('.filter-standard .col')]
        fields.map(field => {
            if (field.getAttribute('data-id') == selectedValue) field.style.display = 'flex'
            else if (field.getAttribute('data-id')) field.style.display = 'none'
        })
    }

    return (
        <form className="filter-standard">
            <div className="col select-filter-col">
                <label>
                    {trans('filters.filterBy')}
                </label>
                <select onChange={handleSelectMobileFilter}>
                    <option value="1">
                        {trans('filters.startDate')}
                    </option>
                    <option value="2">
                        {trans('filters.endDate')}
                    </option>
                    <option value="3">
                        {trans('filters.search')}
                    </option>
                </select>
            </div>

            <div className="col" data-id="1">
                <label>
                    {trans('invoice.type')}
                </label>
                <select
                    name="type_id"
                    value={props.filter.type_id || ''}
                    onChange={handleSearch}
                >
                    <option value=""></option>
                    <option value={1}>
                        {trans('invoice.proforma')}
                    </option>
                    <option value={2}>
                        {trans('invoice.invoice')}
                    </option>
                </select>
            </div>

            <div className="col" data-id="1">
                <label htmlFor="start_date">
                    {trans('labels.from')}
                </label>
                <input
                    type="date"
                    name="start_date"
                    onChange={handleSearch}
                    defaultValue={props.filter.start_date}
                />
            </div>

            <div className="col" data-id="2">
                <label htmlFor="end_date">
                    {trans('labels.to')}
                </label>
                <input
                    type="date"
                    name="end_date"
                    onChange={handleSearch}
                    defaultValue={props.filter.end_date}
                />
            </div>

            <div className="col" data-id="3">
                <label htmlFor="customer">
                    {trans('labels.search')}
                </label>
                <input
                    type="search"
                    name="search"
                    placeholder={trans('random.clientDocNo')}
                    className="field-big"
                    value={props.filter.search}
                    onChange={handleSearch}
                />
            </div>
        </form>
    )
}

export default React.memo(Filter, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})