import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../../../_helpers/Api';
import Pagination from '../../../../../Pagination';

//images
import docIcon from '../../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../../assets/img/icons/close.png';
import loader from '../../../../../../assets/img/loader.svg';
import VanEvent from './VanEvent';
import VanEventOptions from './VanEventOptions';
import { trans } from '../../../../../../_providers/Translation';
import NoDataFound from '../../../../../partials/NoDataFound';

function VanEvents(props, ref) {

    const modalAddOrEditRef = useRef(null);

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        loading: true,
        update: false,
        vanId: null
    });

    useImperativeHandle(ref, () => ({
        show: (vanId) => {
            setPopup(prev => ({
                ...prev,
                loading: true,
                data: [],
                update: Math.random().toString(),
                vanId: vanId
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    useEffect(() => {

        if (popup.vanId) {
            Api.get(`vans/events/all?van_id=${popup.vanId}&page=${popup.page}`)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data.items,
                        total: res.data.total,
                        pages: res.data.pages,
                        loading: false
                    }));
                });

        }

    }, [popup.update, popup.page]);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {

        if (e) {
            e.preventDefault();
        }

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handlePage = page => {
        setPopup(prev => ({ ...prev, page: page.selected + 1 }));
    }

    // create
    const handleAddEvent = e => {
        modalAddOrEditRef.current.add();

        handleHide(e);
    }

    const handleCreate = () => {
        setPopup(prev => ({
            ...prev,
            update: Math.random().toString()
        }));

        handleShow();
    }

    // update
    const handleEdit = id => {

        modalAddOrEditRef.current.edit(id);

        handleHide();
    }

    const handleUpdate = event => {

        let data = popup.data.map(i => {
            if (i.id == event.id) i = event;
            return i;
        });

        setPopup(prev => ({
            ...prev,
            data: data
        }));

        handleShow();
    }

    // delete
    const handleDelete = id => {
        Api.post('vans/events/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                setPopup(prev => ({
                    ...prev,
                    update: Math.random().toString()
                }));
            }
        });
    }

    return (
        <>
            <VanEvent
                ref={modalAddOrEditRef}
                vanId={popup.vanId}
                handleShow={handleShow}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
            />

            <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
                <div className={`${popup.modal ? 'show' : ''} popup-primary large`}>
                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />

                        {trans('headings.events')}

                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>

                    {popup.loading
                        ?
                        <img src={loader} alt="loading" />
                        :
                        popup.data.length === 0
                            ?
                            <NoDataFound />
                            :
                            <>

                                <div className="table-scroller">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>{trans('labels.date')}</th>
                                                <th>{trans('labels.title')}</th>
                                                <th>{trans('labels.description')}</th>
                                                <th className="options">{trans('labels.options')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {popup.data.map(e =>
                                                <tr key={'e-' + e.id}>
                                                    <td>{e.date_formatted}</td>
                                                    <td>{e.text}</td>
                                                    <td>{e.description}</td>
                                                    <td>
                                                        <VanEventOptions
                                                            id={e.id}
                                                            handleEdit={handleEdit}
                                                            handleDelete={handleDelete}
                                                        />
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>

                                <Pagination
                                    pages={popup.pages}
                                    page={popup.page}
                                    handlePage={handlePage}
                                />
                            </>
                    }

                    <div className="footer align-center">
                        <button onClick={handleHide}>
                            {trans('buttons.cancel')}
                        </button>
                        <button className="button button-primary" onClick={handleAddEvent}>
                            {trans('buttons.add')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(VanEvents);