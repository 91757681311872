import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Api from '../../../_helpers/Api';
import loader from '../../../assets/img/loader.svg';
import Chart from "react-google-charts";
import Skeleton from 'react-loading-skeleton';
import { fromInfo, trans } from '../../../_providers/Translation';
import Filter from './partials/FilterStats';
import PartsPages from '../../partials/PartsPages';
import PartsStats from './partials/PartsStats';
import Help from '../../partials/Help';

let timeout;

function Stats(props) {

    const params = useParams();
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const [state, setState] = useState({
        loading: true,
        data: {
            dates: [],
            categories: []
        },
        filter: {},
        setFilter: false,
        update: false,
        summary: true,
        allowDetails: false,
    });

    const partsRef = useRef(null);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                start: searchParams.get('start') || '',
                end: searchParams.get('end') || '',
                group: searchParams.get('group') || '',
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    // useEffect(() => {
    //     console.log(state.filter);
    // }, [state.filter]);

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    useEffect(() => {

        if (!state.data.dates) {
            return;
        }

        let label = state.data.group === 'month' ? trans('random.month') : trans('random.year');

        let chartOrdered;
        let chartStock;

        // обобщена 
        if (state.summary) {
            // ordered
            chartOrdered = [
                [label, trans('random.income')],
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label, d.ordered_gross
                ];

                chartOrdered.push(arr);
            });

            // stock
            chartStock = [
                [label, trans('random.income')],
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label, d.stock_gross
                ];

                chartStock.push(arr);
            });
        }
        // по категории
        else {
            let chartArr = [label];

            state.data.categories.map(c => {
                chartArr.push(c.name)
            });

            // ordered
            chartOrdered = [
                chartArr
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label,
                ];

                state.data.categories.map(c => {
                    arr.push(c.dates[d.date].ordered_gross);
                });

                chartOrdered.push(arr);
            });

            // stock
            chartStock = [
                chartArr
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label,
                ];

                state.data.categories.map(c => {
                    arr.push(c.dates[d.date].stock_gross);
                });

                chartStock.push(arr);
            });

            console.log(chartOrdered);
        }

        setState(prev => ({
            ...prev,
            chartOrdered: chartOrdered,
            chartStock: chartStock,
        }))

    }, [state.data, state.summary])

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'accounts/parts-with-invoices';

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {

                // let label = trans('calendar.month');

                // let chart = [
                //     [label, trans('tabs.reservations')],
                // ];

                // res.data.dates.map(d => {
                //     let arr = [
                //         d.label, d.ordered
                //     ];

                //     chart.push(arr);
                // })

                setState(prev => ({
                    ...prev,
                    data: res.data,
                    // chart: chart,
                    filter: {
                        ...prev.filter,
                        group: res.data.group,
                        start: res.data.start,
                        end: res.data.end
                    },
                    loading: false,
                    summary: res.data.categories.length === 0 ? true : prev.summary,
                    allowDetails: res.data.categories.length === 0 ? false : true
                }));
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            })
    }

    const handleSearch = (key, val, delay = 300) => {

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
                setFilter: new Date().getTime()
            }));
        }, delay);

    }

    const toggleSummaryData = val => {
        setState(prev => ({
            ...prev,
            summary: val
        }));
    }

    const getColumns = () => {
        let arr = [];

        state.data.categories.map((m, i) => {
            arr.push({
                type: 'NumberFormat',
                column: i + 1,
                options: {
                    // prefix: '$',
                    negativeParens: false,
                }
            })
        });

        return arr;
    }

    const calcHeight = () => {
        let height = 50 + state.data.categories.length * 40;

        if (height < 400) {
            height = 400;
        }

        return height;
    }

    const showPartsFromChart = (tab, row, col) => {

        let date = state.data.dates[row];
        let category = state.data.categories[col - 1];

        if (state.summary) {
            if (date) {
                showParts(null, date.start_date, date.end_date, tab);
            }
        } else {
            if (date && category) {
                showParts(category.id, date.start_date, date.end_date, tab);
            }
        }
    }

    const showParts = (categoryId, start, end, tab = null) => {
        partsRef.current.open(categoryId, start, end, tab);
    }

    const style = {
        padding: '10px',
        background: '#fff'
    }

    return (
        <>
            <PartsStats
                ref={partsRef}
            />

            <div className="wrapper">

                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.stats')} <Help>{fromInfo('parts.stats')}</Help>
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.partsIncomes')}
                        </p>
                    </div>
                    <div className="right">

                    </div>
                </div>

                <PartsPages />

                <Filter
                    allowDetails={state.allowDetails}
                    filter={state.filter}
                    summary={state.summary}
                    handleSearch={handleSearch}
                    toggleSummaryData={toggleSummaryData}
                />

                <br />


                {state.loading
                    ?
                    <img className="loader" src={loader} />
                    :
                    <>
                        <div className="row">
                            <div className="col" style={style}>
                                {state.loading
                                    ?
                                    <Skeleton height={400} />
                                    :
                                    <Chart
                                        width={'100%'}
                                        // height={'400px'}
                                        height={calcHeight() + 'px'}
                                        chartType="Bar"
                                        className="animated-chart-vertical animated-chart-start clickable-chart"
                                        loader={'...'}
                                        data={state.chartOrdered}
                                        options={{
                                            // Material design options
                                            chart: {
                                                title: trans('headings.orderedPartsWithInvoices'),
                                                subtitle: state.data.currency,
                                            },
                                            legend: {
                                                position: state.summary ? 'none' : 'left',
                                                textStyle: {
                                                    fontSize: 12
                                                }
                                            },
                                            allowHtml: true,
                                            showRowNumber: true,
                                        }}
                                        chartEvents={[
                                            {
                                                eventName: "ready",
                                                callback: ({ chartWrapper, google }) => {
                                                    const chartEl = chartWrapper.getChart().container;
                                                    setTimeout(() => {
                                                        chartEl.classList.remove('animated-chart-start')
                                                    }, 100)
                                                },
                                            },
                                            {
                                                eventName: "select",
                                                callback: ({ chartWrapper, google }) => {
                                                    const chartEl = chartWrapper.getChart();
                                                    const selection = chartEl.getSelection()[0];

                                                    // console.log(selection);

                                                    if (selection) {
                                                        showPartsFromChart('ordered', selection.row, selection.column);
                                                    }

                                                },
                                            }
                                        ]}
                                        formatters={getColumns()}
                                    />
                                }

                            </div>
                            <div className="col" style={style}>
                                {state.loading
                                    ?
                                    <Skeleton height={400} />
                                    :
                                    <Chart
                                        width={'100%'}
                                        // height={'400px'}
                                        height={calcHeight() + 'px'}
                                        chartType="Bar"
                                        className="animated-chart-vertical animated-chart-start clickable-chart"
                                        loader={'...'}
                                        data={state.chartStock}
                                        options={{
                                            // Material design options
                                            chart: {
                                                title: trans('headings.stockPartsWithInvoices'),
                                                subtitle: state.data.currency,
                                            },
                                            legend: {
                                                position: state.summary ? 'none' : 'left'
                                            },
                                            allowHtml: true,
                                            showRowNumber: true,
                                        }}
                                        chartEvents={[
                                            {
                                                eventName: "ready",
                                                callback: ({ chartWrapper, google }) => {
                                                    const chartEl = chartWrapper.getChart().container;
                                                    setTimeout(() => {
                                                        chartEl.classList.remove('animated-chart-start')
                                                    }, 100)
                                                },
                                            },
                                            {
                                                eventName: "select",
                                                callback: ({ chartWrapper, google }) => {
                                                    const chartEl = chartWrapper.getChart();
                                                    const selection = chartEl.getSelection()[0];

                                                    // console.log(selection);

                                                    if (selection) {
                                                        showPartsFromChart('stock', selection.row, selection.column);
                                                    }

                                                },
                                            }
                                        ]}

                                        formatters={getColumns()}
                                    />
                                }
                            </div>
                        </div>

                        <br />
                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            {trans('tables.category')}
                                        </th>
                                        {state.data.dates.map((d, i) =>
                                            <th key={'th-' + i} className="right">
                                                {d.label}
                                            </th>
                                        )}
                                        <th className="right">
                                            {trans('tables.total')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.categories.map(c =>
                                        <tr key={'c-' + c.id}>
                                            <td>
                                                {c.name}
                                            </td>
                                            {state.data.dates.map((d, i) =>
                                                <td
                                                    key={'td-' + i}
                                                    className="right clickable"
                                                    onClick={() => showParts(c.id, d.start_date, d.end_date)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {c.dates[d.date].gross_formatted}
                                                </td>
                                            )}
                                            <td className="right">
                                                {c.gross_formatted}
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td>
                                            {trans('tables.total')}
                                        </td>
                                        {state.data.dates.map((d, i) =>
                                            <td
                                                key={'totals-' + i}
                                                className="right clickable"
                                                onClick={() => showParts(null, d.start_date, d.end_date)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {d.gross_formatted}
                                            </td>
                                        )}
                                        <td className="right">
                                            {state.data.gross_formatted}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </>
                }
            </div>

        </>
    )
}

export default Stats;