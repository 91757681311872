import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import { useAuthDataContext } from '../../../../_providers/Auth';

function AddOrEdit(props, ref) {

    const auth = useAuthDataContext();

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: null,
        loading: false,
        fromExist: true
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: []
            }));
            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                data: null,
                loading: false
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            Api.get(`partners/find?id=${popup.editId}`)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data,
                    }));
                });
        }

    }, [popup.edit]);


    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            data: [],
            checked: false,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();


        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'partners/relationships/edit';
        } else {
            url = 'partners/relationships/add';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('related_partner_id', popup.editId);
        }

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    props.handleRefresh();
                } else {
                    props.handleCreate(res.data);
                }

                handleHide(e);

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary large`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {popup.edit ? trans('headings.addRelationships') : trans('headings.editRelationships')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container">
                    <div className="col">

                        <label htmlFor="name">
                            {trans('labels.name')}
                        </label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            value={popup.data && popup.data.name ?
                                popup.data.name
                                :
                                ''
                            }
                            className={validations && validations.name ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>

                </div>

                <br />

                <div className="popup-container x2">
                    <div className="col">
                        <label htmlFor="email">
                            {trans('labels.splitEmail')}
                        </label>
                        <input
                            id="email"
                            type="text"
                            name="email"
                            value={popup.data && popup.data.email ?
                                popup.data.email
                                :
                                ''
                            }
                            className={validations && validations.email ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="accounting_email">
                            {trans('labels.splitEmail2')}
                        </label>
                        <input
                            id="accounting_email"
                            type="text"
                            name="accounting_email"
                            value={popup.data && popup.data.accounting_email ?
                                popup.data.accounting_email
                                :
                                ''
                            }
                            className={validations && validations.accounting_email ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>

                </div>

                <br />

                <div className="popup-container x2">
                    <div className="col">
                        <label htmlFor="phone">
                            {trans('labels.phone')}
                        </label>
                        <input
                            id="phone"
                            type="text"
                            name="phone"
                            value={popup.data && popup.data.phone ?
                                popup.data.phone
                                :
                                ''
                            }
                            className={validations && validations.phone ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="address">
                            {trans('labels.address')}
                        </label>
                        <input
                            id="address"
                            type="text"
                            name="address"
                            value={popup.data && popup.data.address ?
                                popup.data.address
                                :
                                ''
                            }
                            className={validations && validations.address ? 'validate' : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>


                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);