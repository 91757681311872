import React, { useRef } from 'react';
import { trans } from '../../../../_providers/Translation'

//partials
import View from '../partials/modals/View';
import Options from './Options';
import Question from '../../../modals/messages/Question';

//images
import loader from '../../../../assets/img/loader.svg';
import checkIcon from '../../../../assets/img/icons/check.png'
import closeIcon from '../../../../assets/img/icons/close.png'
import NoDataFound from '../../../partials/NoDataFound';
import { useAuthDataContext } from '../../../../_providers/Auth';
import Api from '../../../../_helpers/Api';

function Table(props) {

    const auth = useAuthDataContext();

    const viewModalRef = useRef(null);
    const repeatModalRef = useRef(null);

    const handleView = data => {
        viewModalRef.current.open(data);
    }

    const handleStopRepeat = id => {
        let modal = repeatModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            Api.post('todos/stop-repeat', {
                id: id
            }).then(res => {
                if (res.data.success) {
                    props.refreshData();
                }
            });
        });
    }

    return (
        <>

            <View
                ref={viewModalRef}
                handleRead={props.handleRead}
            />

            <Question
                ref={repeatModalRef}
                mainMessage={trans('headings.question1')}
            />

            {props.loading
                ?
                <img src={loader} alt="loading" />
                :
                props.data.length === 0
                    ?
                    <NoDataFound />
                    :
                    <div className="table-scroller">
                        <table>
                            <thead>
                                <tr>
                                    <th className="todo-check"></th>
                                    <th>ID</th>
                                    <th>{trans('tables.createdBy')}</th>
                                    <th>{trans('tables.setTo')}</th>
                                    {!auth.isAdmin() &&
                                        <th>{trans('tables.reservation')}</th>
                                    }
                                    <th>{trans('tables.heading')}</th>
                                    <th>{trans('tables.datePriority')}</th>
                                    <th>{trans('tables.deadline')}</th>
                                    <th className="center">{trans('tables.repeat')}</th>
                                    <th className="center">{trans('tables.reminders')}</th>
                                    <th className="options">{trans('tables.options')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.map(todo =>
                                    <tr key={'todo-' + todo.id}>
                                        <td className="todo-check">{todo.completed_at && <img src={checkIcon} alt="completed" />}</td>
                                        <td>{todo.id}</td>
                                        <td>{todo.author ? `${todo.author.name} ${todo.author.lastname}` : ''}</td>
                                        <td>
                                            {todo.tags.map(tag => {
                                                return tag.employee ? `${tag.employee.name} ${tag.employee.lastname}` : ''
                                            }).filter(each => each !== '').join(', ')}
                                        </td>
                                        {!auth.isAdmin() &&
                                            <td>
                                                {todo.reservation
                                                    ?
                                                    <a
                                                        href={'/reservations/edit/' + todo.reservation_id} target="_blank"
                                                        className="btn"
                                                        style={{ background: todo.reservation.color, color: todo.reservation.text_color }}
                                                    >
                                                        {todo.reservation.reference_code}
                                                    </a>
                                                    :
                                                    ''
                                                }
                                            </td>
                                        }
                                        <td>
                                            {todo.title}
                                        </td>
                                        <td>
                                            {todo.date_dmy}
                                            <p className="priority-holder">
                                                <span className={`priority ${todo.priority.name}`}></span>
                                                {todo.priority ? todo.priority.translation.name : ''}
                                            </p>
                                        </td>
                                        <td>{todo.deadline_dmy}</td>
                                        <td className="center">
                                            {Number(todo.repeat) === 1 || (todo.parent && Number(todo.parent.repeat) === 1)
                                                ?
                                                <img src={checkIcon} height="12" />
                                                :
                                                <img src={closeIcon} height="12" />
                                            }
                                            {todo.parent_id &&
                                                <span style={{ display: 'block', fontSize: '10px', marginTop: '5px' }}>{trans('tables.parent')} ID #{todo.parent_id}</span>
                                            }
                                        </td>
                                        <td className="center">
                                            {todo.alarms.length}
                                        </td>
                                        <td>
                                            <Options
                                                id={todo.id}
                                                data={todo}
                                                read={todo.read_at}
                                                completed={todo.completed_at}
                                                canMarkAsCompleted={todo.can_mark_as_completed}
                                                paused={todo.paused_at}
                                                handleEdit={props.handleEdit}
                                                handleDelete={props.handleDelete}
                                                handleRead={props.handleRead}
                                                handleComplete={props.handleComplete}
                                                handleTogglePause={props.handleTogglePause}
                                                handleView={handleView}
                                                handleStopRepeat={handleStopRepeat}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

            }
        </>
    )
}

export default Table;