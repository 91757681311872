import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import FilterTabs from './partials/FilterTabs'
import Api from '../../../_helpers/Api'
import { trans } from '../../../_providers/Translation';

//partials
import TaskInfo from './partials/TaskInfo'
import Parts from './partials/parts/Index'
import Images from './partials/Images'
import Comments from './partials/Comments'
import Todo from './partials/Todo'
import Invoices from './partials/Invoices'
import History from './partials/History'
import loader from '../../../assets/img/loader.svg'
import { TYPE } from '../../../_constants/Reservation';
import Help from '../../partials/Help';

function CreateEdit() {
    const params = useParams()
    const history = useHistory()
    const location = useLocation()

    const id = params.id

    const addMode = id ? false : true;
    const editMode = id ? true : false;

    const searchParams = new URLSearchParams(location.search);

    const tabs = ['info', 'parts', 'images', 'todo', 'comments', 'invoices', 'history']

    const tab = searchParams.get('tab')
    const urlIndicator = searchParams.get('indicator')

    const [reservation, setReservation] = useState({
        tab: tabs.indexOf(tab) > -1 ? tab : tabs[0],
        data: {
            can_modify: true,
            can_modify_appliance: true,
            can_modify_initial_status: true,
            can_reassign: true,
            contractor: {},
            customer: {},
            appliancecategory: {},
            appliancebrand: {},
            appliancemodel: {},
            diagnostic_price: 0,
            transport_price: 0,
            labour_price: 0,
            parts_price: 0,
            calculate_labour_price: true
        },
        tabsCount: {
            commentsCount: 0,
            imagesCount: 0,
            partsCount: 0,
            todosCount: 0,
            manualsCount: 0,
            invoicesCount: 0
        },
        update: false,
        loading: editMode ? true : false,
    });

    const [settings, setSettings] = useState({
        data: null,
        loading: true,
    });

    useEffect(() => {

        let employeeId = searchParams.get('employee_id');
        let date = searchParams.get('date');
        let periodId = searchParams.get('visit_period_id');
        let address = searchParams.get('address');
        let lat = searchParams.get('lat');
        let lon = searchParams.get('lon');

        setReservation(prev => ({
            ...prev,
            tab: tabs.indexOf(tab) > -1 ? tab : tabs[0],
        }))

        if (employeeId) {
            setReservation(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    performer: {
                        id: employeeId,
                        type: 'App\\Employee'
                    }
                }
            }))
        }

        // YYYY-mm-dd
        if (date) {
            setReservation(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    visit_date_ymd: date
                }
            }))
        }

        if (periodId) {
            setReservation(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    visit_period_id: periodId
                }
            }))
        }

        if (address) {
            setReservation(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    address: address
                }
            }))
        }

        if (lat && lon) {
            setReservation(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    lat: Number(lat),
                    lon: Number(lon)
                }
            }))
        }

    }, [location.search]);

    useEffect(() => {
        if (editMode) {

            setReservation(prev => ({
                ...prev,
                loading: true
            }));

            Api.get(`reservations/find?id=${id}`)
                .then(res => {
                    setReservation(reservation => ({
                        ...reservation,
                        data: res.data,
                        tabsCount: {
                            commentsCount: res.data.comments_count,
                            imagesCount: res.data.images_count,
                            partsCount: res.data.parts_count,
                            todosCount: res.data.todos_count,
                            manualsCount: res.data.manuals_count,
                            invoicesCount: res.data.invoices_count
                        }
                    }))
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        history.push('/reservations')
                    }
                })
                .finally(() => {
                    setReservation(prev => ({
                        ...prev,
                        loading: false
                    }));
                })
        }
    }, [id, urlIndicator, reservation.update]);

    useEffect(() => {
        loadSettings();
    }, []);

    const loadSettings = (retry = 0) => {
        Api.get('partners/settings/pricing')
            .then(res => {
                setSettings(prev => ({
                    ...prev,
                    data: res.data,
                    loading: false
                }));
            })
            .catch(err => {
                if (retry < 3) {
                    loadSettings(retry + 1);
                }
            });
    }

    const handleTab = newValue => {
        history.push(`?tab=${newValue}`)

        setReservation(reservation => ({
            ...reservation,
            tab: newValue
        }))
    }


    const setOriginal = data => {
        setReservation(prev => ({
            ...prev,
            data: data
        }));
    }

    const refreshData = () => {
        setReservation(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const calculatePrice = () => {
        Api.get('reservations/calculate-price?id=' + id)
            .then(res => {
                Object.entries(res.data).map(p => {
                    setReservation(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            [p[0]]: p[1]
                        }
                    }));
                });
            });
    }

    const isAddress = () => {
        if (Number(reservation.data.res_type_id) === TYPE.ADDRESS) {
            return true;
        }

        return params.type === 'address';
    }

    const isOnSiteRepair = () => {
        if (Number(reservation.data.res_type_id) === TYPE.ON_SITE_REPAIR) {
            return true;
        }

        return params.type === 'service';
    }

    const getResTypeId = () => {
        if (isAddress()) {
            return TYPE.ADDRESS;
        }

        if (isOnSiteRepair()) {
            return TYPE.ON_SITE_REPAIR;
        }
    }

    const getResType = () => {
        if (isAddress()) {
            return 'address';
        }

        if (isOnSiteRepair()) {
            return 'service';
        }
    }

    return (
        <div className="wrapper">
            <div className="top-part">
                <div className="left">
                    <h1 className="page-heading">
                        {addMode
                            ?
                            isAddress()
                                ?
                                trans('headings.addAddressReservation')
                                :
                                isOnSiteRepair()
                                    ?
                                    trans('headings.addServiceReservation')
                                    :
                                    trans('headings.addReservation')
                            :
                            isAddress()
                                ?
                                trans('headings.editAddressReservation')
                                :
                                isOnSiteRepair()
                                    ?
                                    trans('headings.editServiceReservation')
                                    :
                                    trans('headings.editReservation')}
                    </h1>
                    <p className="page-subheading">
                        {trans('subheadings.createEditReservations')}
                    </p>
                </div>
            </div>

            {<FilterTabs
                editMode={editMode}
                tab={reservation.tab}
                loading={reservation.loading}
                reservationId={id}
                referenceCode={reservation.data.reference_code}
                categoryId={reservation.data.appliance_category_id}
                brandId={reservation.data.appliance_brand_id}
                modelId={reservation.data.appliance_model_id}
                model={reservation.data.appliancemodel ? reservation.data.appliancemodel.name : ''}
                tabsCount={reservation.tabsCount}
                handleTab={handleTab}
            />}

            <div style={{ display: reservation.tab === 'info' ? 'block' : 'none' }}>
                <TaskInfo
                    addMode={addMode}
                    loading={reservation.loading || settings.loading}
                    data={reservation.data}
                    settings={settings.data}
                    tabsCount={reservation.tabsCount}
                    setOriginal={setOriginal}
                    isAddress={isAddress}
                    isOnSiteRepair={isOnSiteRepair}
                    getResTypeId={getResTypeId}
                    getResType={getResType}
                />
            </div>

            {(reservation.loading || settings.loading) && reservation.tab !== 'info'
                ?
                <img src={loader} alt="loading" />
                :
                reservation.tab === 'parts' ?
                    <Parts
                        data={reservation.data}
                        refreshData={refreshData}
                        calculatePrice={calculatePrice}
                    />
                    :
                    reservation.tab === 'images' ?
                        <Images
                            data={reservation.data}
                            refreshData={refreshData}
                        />
                        :
                        reservation.tab === 'todo' ?
                            <Todo
                                data={reservation.data}
                                refreshData={refreshData}
                            />
                            :
                            reservation.tab === 'comments' ?
                                <Comments
                                    data={reservation.data}
                                    refreshData={refreshData}
                                />
                                :
                                reservation.tab === 'invoices' ?
                                    <Invoices
                                        data={reservation.data}
                                        refreshData={refreshData}
                                    />
                                    :
                                    reservation.tab === 'history' ?
                                        <History
                                            data={reservation.data}
                                            refreshData={refreshData}
                                        />
                                        :
                                        ''
            }
        </div>
    )
}

export default CreateEdit