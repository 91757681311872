import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { trans } from '../../../../_providers/Translation'

function FilterTabs(props) {
    const [tabs, setTabs] = useState({
        ripple: true
    })

    const handleTabs = (e, newValue) => {
        props.handleTab(newValue);
    };

    return (
        <Tabs
            value={props.tab}
            onChange={handleTabs}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
        >
            <Tab value="manuals" label={trans('tabs.manuals')} disableRipple={tabs.ripple} />
            <Tab value="errors" label={trans('tabs.errors')} disableRipple={tabs.ripple} />
            <Tab value="problems" label={trans('tabs.problems')} disableRipple={tabs.ripple} />
        </Tabs>
    )
}

export default FilterTabs;