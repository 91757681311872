import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';

//images
import loader from '../../../../assets/img/loader.svg';
import { trans } from '../../../../_providers/Translation';

function ImageModal(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        img: null,
        loading: false,
        onSave: null,
    });

    useImperativeHandle(ref, () => ({
        show: (picref) => {
            setPopup(prev => ({
                ...prev,
                img: picref
            }))
            handleShow();

        },
        hide: () => {
            handleHide();
        }
    }));

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form>
                <div className='popup-container small'>
                    <div className='col'>
                        <img className='imageModal' src={popup.img} />
                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img className='imageWeb' src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.back')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(ImageModal);