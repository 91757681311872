import React, { useState, useImperativeHandle, useRef } from 'react';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import Api from '../../../../_helpers/Api';
import loader from '../../../../assets/img/loader.svg';
import Dropdown from '../../../partials/Dropdown';
import Success from '../../../modals/messages/Success';
import Error from '../../../modals/messages/Error';
import { trans } from '../../../../_providers/Translation';

function Send(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        loading: false,
        id: null,
    });

    const [validations, setValidations] = useState(null);

    const receiverRef = useRef(null);
    const successModalRef = useRef(null);
    const errorModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        show: (id) => {
            handleShow(id);
        },
        hide: () => {
            handleHide();
        }
    }));

    const handleShow = (id) => {

        setPopup(prev => ({
            ...prev,
            overlay: true,
            id: id
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        if (e) {
            e.preventDefault();
        }

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setValidations(null)

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {

        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'voicecall/send';

        let data = new FormData(e.target);

        data.append('id', popup.id);

        Api.post(url, data)
            .then(res => {

                handleHide();

                if (res.data.success) {
                    receiverRef.current.reset();
                    successModalRef.current.open();
                } else {
                    errorModalRef.current.open();
                }

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                } else {
                    handleHide();

                    errorModalRef.current.open();
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    return (
        <>
            <Success
                ref={successModalRef}
                mainMessage={trans('messages.sendRecordOk')}
            />

            <Error
                ref={errorModalRef}
                mainMessage={trans('messages.sendRecordErr')}
            />

            <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
                <form className={`${popup.modal ? 'show' : ''} popup-primary small`} autoComplete="off" onSubmit={handleSave}>
                    <h2 className="head">
                        <img src={docIcon} alt="document" className="document-icon" />

                        {trans('headings.sendCallRecord')}

                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                    </h2>


                    <div className="popup-container">
                        <div className="col">
                            <label>
                                {trans('labels.sendTo')}
                            </label>

                            <Dropdown
                                ref={receiverRef}
                                inputName="customer"
                                inputIdName="customer_id"
                                url={'customers/all'}
                                inputPlaceholder={trans('labels.findUser')}
                                inputClassName={validations && validations.customer_id ? 'validate' : ''}
                                renderText={data => {
                                    return <span>{data.full_name} ({(data.email2 || trans('labels.noEmailSpecified'))})</span>
                                }}
                                renderInputText={data => {
                                    return `${data.full_name}`
                                }}
                            />
                        </div>

                    </div>


                    <div className="footer align-center">
                        {popup.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="button" onClick={handleHide}>
                                    {trans('buttons.cancel')}
                                </button>
                                <button className="button button-primary" type="submit">
                                    {trans('buttons.send')}
                                </button>
                            </>
                        }
                    </div>
                </form>
            </div>
        </>
    )
}

export default React.forwardRef(Send);