import React, { useEffect } from 'react';
import { trans } from '../../../_providers/Translation'

function Invoice(props) {

    return (
        <table>
            <thead>
                <tr>
                    <th>{trans('tables.activity')}</th>
                    <th>{trans('tables.date')}</th>
                    <th>{trans('tables.employee')}</th>
                    <th>{trans('tables.type')}</th>
                    <th>{trans('tables.client')}</th>
                    <th>{trans('tables.reservation')}</th>
                    <th>{trans('tables.sent')}</th>
                    <th>{trans('tables.paid')}</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((h, i) => (
                    <tr key={i} className={[h.actual ? 'actual' : h.selected ? 'selected' : '']}>
                        <td>
                            {h.event}
                        </td>
                        <td>{h.date}</td>
                        <td>{h.causer && `${h.causer.name} ${h.causer.lastname}`}</td>
                        <td>{h.subject.type || '-'}</td>
                        <td>
                            {h.subject.customer
                                ?
                                h.subject.customer_type === 'App\\Partner'
                                    ?
                                    h.subject.customer.name
                                    :
                                    `${h.subject.customer.name} ${h.subject.customer.lastname}`
                                :
                                '-'
                            }
                        </td>
                        <td>
                            {h.subject.reservation
                                ? <a href={'/reservations/edit/' + h.subject.reservation_id} target="_blank">{h.subject.reservation.reference_code}</a>
                                : '-'
                            }
                        </td>
                        <td>
                            {h.subject.sent
                                ? `${h.subject.sent} / ${h.subject.sent_at_dmy}`
                                : '-'
                            }
                        </td>
                        <td>
                            {h.subject.is_paid
                                ? `${h.subject.paid_at_dmy}`
                                : '-'
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default Invoice;