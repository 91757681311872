import React from 'react';
import Options from './Options';
import { trans } from '../../../../_providers/Translation'

function Table(props) {

    return (
        <>
            <div className="table-scroller">
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '50px' }}>ID</th>
                            <th>{trans('tables.name')}</th>
                            <th className="options">{trans('tables.options')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.branches.map(branch =>
                            <tr key={'branch-' + branch.id}>
                                <td style={{ width: '50px' }}>{branch.id}</td>
                                <td>{branch.translation ? branch.translation.name : ''}</td>
                                <td>
                                    <Options
                                        branchId={branch.id}
                                        handleEdit={props.handleEdit}
                                        handleDelete={props.handleDelete}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Table;