import React, { useRef, useState, useEffect } from 'react';
import { useAuthDataContext } from '../../../../../_providers/Auth';
import Api from '../../../../../_helpers/Api';
import editIcon from '../../../../../assets/img/icons/edit-yellow.png';
import Modal from './modals/ReservationStatus';
import Skeleton from 'react-loading-skeleton';
import Options from './ReservationStatusesOptions';
import NoDataFound from '../../../../partials/NoDataFound';
import { trans } from '../../../../../_providers/Translation';

function ReservationStatuses(props) {

    const auth = useAuthDataContext();

    const modalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: {
            1: [],
            2: []
        },
        update: false,
    });

    useEffect(() => {
        loadSettings();
    }, [state.update]);

    const loadSettings = () => {
        Api.get('reservations/statuses')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleAdd = () => {
        let modal = modalRef.current;

        modal.open();

        modal.onSave(data => {
            // setState(prev => ({
            //     ...prev,
            //     data: {
            //         ...prev.data,
            //         [data.type_id]: prev.data[data.type_id].concat(data)
            //     }
            // }));

            handleUpdate();

            if (typeof props.handleRefresh === 'function') {
                props.handleRefresh();
            }
        });

    }

    const handleEdit = id => {
        let modal = modalRef.current;

        modal.edit(id);

        modal.onSave(data => {
            handleUpdate();

            if (typeof props.handleRefresh === 'function') {
                props.handleRefresh();
            }
        });

    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const handleDelete = id => {
        Api.post('reservations/statuses/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                handleUpdate();
            }
        });
    }

    return (
        <div style={{ width: '100%' }}>
            <Modal
                ref={modalRef}
            />

            <div className="head">
                <h4>{trans('headings.reservationStatuses')}</h4>

                <button
                    className="button button-add button-small"
                    onClick={handleAdd}
                >
                    {trans('buttons.add')}
                </button>
            </div>

            {state.loading
                ?
                <Skeleton height={35} count={10} />
                :
                <>
                    <div className="head">
                        <h4>
                            {trans('labels.initialStatus')}
                        </h4>
                    </div>
                    {state.data[1].length === 0
                        ?
                        <div className="alert warning">
                            {trans('messages.noSavedInfo')}
                        </div>
                        :
                        <div className="table-scroller">
                            <table className="settings">
                                <thead>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            {trans('labels.name')}
                                        </td>
                                        <td className="options center">
                                            {trans('labels.options')}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(state.data[1] || []).map(t =>
                                        <tr key={t.id}>
                                            <td style={{ width: '100%' }}>
                                                <span className="color" style={{ background: t.color }}></span>
                                                {t?.translation?.name}
                                            </td>
                                            <td className="options center">
                                                {(t.allow_edit || t.allow_delete) &&
                                                    <Options
                                                        id={t.id}
                                                        data={t}
                                                        handleEdit={handleEdit}
                                                        handleDelete={handleDelete}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }

                    <br />

                    <div className="head">
                        <h4>
                            {trans('labels.finalStatus')}
                        </h4>
                    </div>
                    {state.data[2].length === 0
                        ?
                        <div className="alert warning">
                            {trans('messages.noSavedInfo')}
                        </div>
                        :
                        <div className="table-scroller">
                            <table className="settings">
                                <thead>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            {trans('labels.name')}
                                        </td>
                                        <td className="options center">
                                            {trans('labels.options')}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(state.data[2] || []).map(t =>
                                        <tr key={t.id}>
                                            <td style={{ width: '100%' }}>
                                                <span className="color" style={{ background: t.color }}></span>
                                                {t?.translation?.name}
                                            </td>
                                            <td className="options center">
                                                {(t.allow_edit || t.allow_delete) &&
                                                    <Options
                                                        id={t.id}
                                                        data={t}
                                                        handleEdit={handleEdit}
                                                        handleDelete={handleDelete}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default ReservationStatuses