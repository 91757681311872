import React, { useRef, useState, useEffect } from 'react';
import { useAuthDataContext } from '../../../../../_providers/Auth';
import Api from '../../../../../_helpers/Api';
import editIcon from '../../../../../assets/img/icons/edit-yellow.png';
import Modal from './modals/MobileApp';
import Skeleton from 'react-loading-skeleton';
import { plural, trans } from '../../../../../_providers/Translation';
import moment from 'moment';

function MobileApp(props) {

    const auth = useAuthDataContext();

    const modalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        data: [],
        update: false,
    });

    useEffect(() => {
        loadSettings();
    }, [state.update]);

    const loadSettings = () => {
        Api.get('partners/settings/app?id=' + auth.partnerId())
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const handleUpdate = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const showModal = () => {
        let modal = modalRef.current;

        modal.show(state.data);

        modal.onSave(() => {
            handleUpdate();

            if (typeof props.handleRefresh === 'function') {
                props.handleRefresh();
            }
        });
    }

    const createMarkup = str => {
        return { __html: str };
    }

    return (
        <div style={{ width: '100%' }}>
            <div className="head">
                <h4>{trans('headings.mobileApp')}</h4>
                <a onClick={showModal}>
                    <img src={editIcon} alt="" />
                </a>
            </div>

            {state.loading
                ?
                <Skeleton height={35} count={5} />
                :
                <>
                    <Modal
                        ref={modalRef}
                    />
                    <div className="table-scroller">
                        <table className="settings">

                            <tbody>
                                <tr>
                                    <td style={{ width: '40%' }}>
                                        <b>{trans('settings.mobileApp.strictReservationFinalStatus')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.strict_reservation_final_status) === 1
                                            ?
                                            trans('labels.yes')
                                            :
                                            trans('labels.no')
                                        }
                                    </td>
                                    <td>

                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('settings.mobileApp.strictReservationImages')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.strict_reservation_images_count) > 0
                                            ?
                                            trans('labels.yes')
                                            :
                                            trans('labels.no')
                                        }
                                    </td>
                                    <td>
                                        {Number(state.data.strict_reservation_images_count) > 0 &&
                                            <span>
                                                {plural('settings.mobileApp.count', state.data.strict_reservation_images_count, {
                                                    count: state.data.strict_reservation_images_count
                                                })}
                                            </span>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('settings.mobileApp.strictReservationParts')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.strict_reservation_parts_extended.length) > 0 &&
                                            <span>
                                                {state.data.strict_reservation_parts_extended.map(s => s.name).join(', ')}
                                            </span>
                                        }
                                    </td>
                                    <td>

                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('settings.mobileApp.strictReservationReport')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.strict_reservation_report) > 0
                                            ?
                                            trans('labels.yes')
                                            :
                                            trans('labels.no')
                                        }
                                    </td>
                                    <td>
                                        {Number(state.data.strict_reservation_report) > 0 &&
                                            <span>
                                                {plural('settings.mobileApp.symbols', state.data.strict_reservation_report, {
                                                    count: state.data.strict_reservation_report
                                                })}
                                            </span>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <b>{trans('settings.mobileApp.strictReservationModel')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.strict_reservation_model) > 0
                                            ?
                                            trans('labels.yes')
                                            :
                                            trans('labels.no')
                                        }
                                    </td>
                                    <td>
                                        {Number(state.data.strict_reservation_model) > 0 &&
                                            <span>
                                                {plural('settings.mobileApp.symbols', state.data.strict_reservation_model, {
                                                    count: state.data.strict_reservation_model
                                                })}
                                            </span>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ width: '40%' }}>
                                        <b>{trans('settings.mobileApp.strictReservationPayments')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.strict_reservation_payments) === 1
                                            ?
                                            trans('labels.yes')
                                            :
                                            trans('labels.no')
                                        }
                                    </td>
                                    <td>

                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ width: '40%' }}>
                                        <b>{trans('settings.mobileApp.allowReservationPaymentChange')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.allow_reservation_payment_change) === 1
                                            ?
                                            trans('labels.yes')
                                            :
                                            trans('labels.no')
                                        }
                                    </td>
                                    <td>

                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ width: '40%' }}>
                                        <b>{trans('settings.mobileApp.strictReservationInvoiceSending')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.strict_reservation_invoice_sending) === 1
                                            ?
                                            trans('labels.yes')
                                            :
                                            trans('labels.no')
                                        }
                                    </td>
                                    <td>

                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ width: '40%' }}>
                                        <b>{trans('settings.mobileApp.strictTodoCompletion')}</b>
                                    </td>
                                    <td>
                                        {Number(state.data.strict_todo_completion) === 1
                                            ?
                                            trans('labels.yes')
                                            :
                                            trans('labels.no')
                                        }
                                    </td>
                                    <td>

                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ width: '40%' }}>
                                        <b>{trans('settings.mobileApp.strictReservationCheckFromDate')}</b>
                                    </td>
                                    <td>
                                        {state.data.strict_reservation_check_from_date
                                            ?
                                            moment(state.data.strict_reservation_check_from_date).format('DD.MM.YYYY')
                                            :
                                            trans('labels.notSpecified')
                                        }
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </div>
    )

}

export default MobileApp