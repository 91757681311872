import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../../../_helpers/Api';

//images
import docIcon from '../../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../../assets/img/icons/close.png';
import loader from '../../../../../../assets/img/loader.svg';
import { trans } from '../../../../../../_providers/Translation';

function Categories(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        id: null,
        data: null,
        prices: null,
        loading: false,
        update: false,
        onSave: null
    });

    const [labourPriceTypes, setLabourPriceTypes] = useState([]);

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        edit: id => {
            handleShow(id);
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    useEffect(() => {
        if (popup.id) {
            Api.get('appliances/categories/show-edit-data?id=' + popup.id)
                .then(res => {
                    let data = res.data.category;

                    if (res.data.prices) {
                        data.pricing = true;
                    }

                    setPopup(prev => ({
                        ...prev,
                        data: data,
                        prices: res.data.prices,
                    }))
                });
        }
    }, [popup.update]);

    useEffect(() => {
        Api.get('settings/labour-price-types')
            .then(res => {
                setLabourPriceTypes(res.data);
            });
    }, []);

    const handleShow = id => {
        setPopup(prev => ({
            ...prev,
            overlay: true,
            id: id,
            data: null,
            update: new Date().getTime()
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);

        setValidations(null);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'appliances/categories/' + (popup.id ? 'edit' : 'add');

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.id) {
            data.append('id', popup.id);
        }

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave();
                }

                document.getElementById("form").reset();

                setPopup(prev => ({
                    ...prev,
                    data: null
                }));

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    const handlePriceInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            prices: {
                ...prev.prices,
                [name]: val
            }
        }));
    }

    const handleCheckboxChange = e => {
        let name = e.target.name;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: !prev.data.pricing,
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary small`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.categories')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.name')}
                        </label>

                        <input
                            type="text"
                            name="name"
                            value={popup.data ? popup.data.name : ''}
                            className={validations && validations.name ? 'validate' : ''}
                            onChange={handleInputChange}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.image')}
                        </label>

                        <input
                            type="file"
                            name="image"
                            className={validations && validations.image ? 'validate' : ''}
                            onChange={handleInputChange}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.labourPriceType')}
                        </label>

                        <select
                            name="labour_price_type_id"
                            value={popup.data?.labour_price_type_id || ''}
                            className={validations && validations.labour_price_type_id ? 'validate' : ''}
                            onChange={handleInputChange}
                        >
                            <option value=""></option>

                            {labourPriceTypes.map(type =>
                                <option key={type.id} value={type.id}>{type?.translation?.name}</option>
                            )}
                        </select>
                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form >
        </div >
    )
}

export default forwardRef(Categories);