import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { trans } from '../../../../_providers/Translation';

function FilterStats(props) {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    const handleGroup = (e, val) => {
        e.preventDefault();

        props.handleSearch('group', val);
    }

    const handleSelectMobileFilter = e => {
        let selectedValue = e.target.value
        let fields = [...document.querySelectorAll('.filter-standard .col')]
        fields.map(field => {
            if (field.getAttribute('data-id') == selectedValue) field.style.display = 'flex'
            else if (field.getAttribute('data-id')) field.style.display = 'none'
        })
    }

    return (
        <>
            <form className="filter-standard schedule-filter">
                <div className="col select-filter-col">
                    <label>
                        {trans('filters.filterBy')}
                    </label>
                    <select onChange={handleSelectMobileFilter}>

                        <option value="1">
                            {trans('filters.startDate')}
                        </option>
                        <option value="2">
                            {trans('filters.endDate')}
                        </option>

                    </select>
                </div>

                <div className="col" data-id="1">
                    <label htmlFor="start">
                        {trans('labels.from')}
                    </label>
                    <input
                        type="date"
                        name="start"
                        onChange={handleSearch}
                        defaultValue={props.filter.start}
                    />
                </div>

                <div className="col" data-id="2">
                    <label htmlFor="end">
                        {trans('labels.to')}
                    </label>
                    <input
                        type="date"
                        name="end"
                        onChange={handleSearch}
                        defaultValue={props.filter.end}
                    />
                </div>

                <div className="col" data-id="3" style={{ justifyContent: 'center' }}>
                    <div style={{ display: 'flex' }}>
                        {props.allowDetails
                            ?
                            <div className="col">
                                <label className="flex">
                                    <input
                                        type="radio"
                                        checked={!props.summary}
                                        onClick={() => props.toggleSummaryData(false)}
                                    />

                                    <span>{trans('random.inDetail')}</span>
                                </label>
                            </div>
                            :
                            ''
                        }

                        <div className="col">
                            <label className="flex">
                                <input
                                    type="radio"
                                    checked={props.summary}
                                    onClick={() => props.toggleSummaryData(true)}
                                />

                                <span>{trans('random.summarized')}</span>
                            </label>
                        </div>
                    </div>
                </div>


                <div className="group">
                    <label>
                        {trans('labels.groupBy')}
                    </label>
                    <button
                        className={props.filter.group === 'month' ? 'active' : ''}
                        onClick={e => handleGroup(e, 'month')}
                    >
                        {trans('buttons.month')}
                    </button>
                    <button
                        className={props.filter.group === 'year' ? 'active' : ''}
                        onClick={e => handleGroup(e, 'year')}
                    >
                        {trans('buttons.year')}
                    </button>
                </div>
            </form>
        </>
    )
}

export default React.memo(FilterStats, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})