import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { trans } from '../../../../_providers/Translation';

function Filter(props) {

    const [tabs, setTabs] = useState({
        ripple: true
    })

    return (
        <div className="small-tabs">
            <Tabs
                value={props.tab}
                onChange={(e, id) => props.handleTab(id)}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs"
            >
                <Tab value="all" label={trans('tabs.all')} disableRipple={tabs.ripple} />
                <Tab value="paid" label={trans('tabs.paid')} disableRipple={tabs.ripple} />
                <Tab value="unpaid" label={trans('tabs.unpaid')} disableRipple={tabs.ripple} />
            </Tabs>
        </div>
    )
}

export default Filter;