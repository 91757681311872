import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import SecondaryTab from './SecondaryTab';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation'

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import loader from '../../../../assets/img/loader.svg';
import { useLanguageContext } from '../../../../_providers/Language';
import MultiSelect from "react-multi-select-component";

function AddOrEdit(props, ref) {

    const languages = useLanguageContext();

    const defaultData = {
        selectedModules: []
    };

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: defaultData,
        loading: false,
        tab: languages[0] ? languages[0].id : 0,
    });

    const [modules, setModules] = useState([]);

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: defaultData
            }));
            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                data: defaultData,
                loading: false
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            Api.get(`branches/find?id=${popup.editId}`)
                .then(res => {

                    let data = res.data;
                    let modules = res.data.modules || [];
                    let selectedModules = [];

                    modules.map(m => {
                        selectedModules.push({
                            label: m.translation ? m.translation.name : '',
                            value: m.id
                        });
                    });

                    data.selectedModules = selectedModules;

                    setPopup(prev => ({
                        ...prev,
                        data: data,
                        tab: languages[0] ? languages[0].id : 0,
                    }));
                });
        }

    }, [popup.edit]);

    useEffect(() => {

        if (languages.length) {
            setPopup(prev => ({ ...prev, tab: languages[0].id }));
        }

    }, [languages]);

    useEffect(() => {
        Api.get('modules/all')
            .then(res => {
                let data = [];

                res.data.map(m => {
                    data.push({
                        label: m.translation ? m.translation.name : m.name,
                        value: m.id
                    });
                });

                setModules(data);
            });
    }, []);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();


        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'branches/edit';
        } else {
            url = 'branches/add';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        Api.post(url, data)
            .then(res => {

                if (popup.edit) {
                    props.handleUpdate(res.data)
                } else {
                    props.handleCreate(res.data);
                }

                handleHide(e);

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const tabsValue = newValue => {
        setPopup(popup => ({
            ...popup,
            tab: newValue,
        }))
    }

    const handleModulesChange = data => {
        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                selectedModules: data
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary large`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {popup.edit ? trans('headings.editBranch') : trans('headings.addBranch')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>
                <SecondaryTab
                    callbackValue={tabsValue}
                />
                <div className="popup-container">
                    <div className="col">

                        {languages.map(lang =>
                            <div key={'l' + lang.id} className="row field-primary" style={{ display: popup.tab === lang.id ? 'block' : 'none' }}>
                                <label>
                                    {trans('labels.name')}
                                </label>
                                <input
                                    type="text"
                                    name={`lang[${lang.id}]`}
                                    defaultValue={popup.edit && popup.data && popup.data.langs && popup.data.langs[lang.id] ?
                                        popup.data.langs[lang.id].name
                                        :
                                        ''
                                    }
                                    className={validations && validations[`lang.${lang.id}`] ? 'validate' : ''}
                                />
                            </div>
                        )}

                    </div>
                </div>

                <br />


                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.modulesIncluded')}
                        </label>

                        <MultiSelect
                            options={modules}
                            value={popup.data.selectedModules}
                            onChange={handleModulesChange}
                            hasSelectAll={false}
                            disableSearch={true}
                            overrideStrings={{
                                "selectSomeItems": trans('labels.select'),
                                "allItemsAreSelected": trans('labels.allSelected'),
                                "selectAll": trans('labels.selectAll'),
                                "search": trans('labels.search')
                            }}
                        />

                        {popup.data.selectedModules.map(s =>
                            <input key={'m-' + s.value} type="hidden" name="modules[]" value={s.value} />
                        )}
                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);