import React from 'react'
import { useLocation } from 'react-router-dom'
import Dropdown from '../../../partials/Dropdown'
import { trans } from '../../../../_providers/Translation'

function Filter(props) {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)

    const handleCategory = data => {

        let val = data.id || '';

        if (data.id || data.name == '') {
            props.handleSearch('category_id', val);
        }

    }

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    const handleSelectMobileFilter = e => {
        let selectedValue = e.target.value
        let fields = [...document.querySelectorAll('.filter-standard .col')]
        fields.map(field => {
            if (field.getAttribute('data-id') == selectedValue) field.style.display = 'flex'
            else if (field.getAttribute('data-id')) field.style.display = 'none'
        })
    }

    return (
        <form className="filter-standard">
            <div className="col select-filter-col">
                <label>
                    {trans('filters.filterBy')}
                </label>
                <select onChange={handleSelectMobileFilter}>
                    <option value="1" selected>
                        {trans('filters.category')}
                    </option>
                    <option value="2">
                        {trans('filters.name')}
                    </option>
                    <option value="3">
                        {trans('filters.partNumber')}
                    </option>
                </select>
            </div>

            <div className="col" data-id="1">
                <label>
                    {trans('labels.category')}
                </label>
                <Dropdown
                    inputIdValue={Number(props.filter.category_id)}
                    url="partners/categories/all"
                    renderText={data => {
                        return data.name
                    }}
                    onChange={data => {
                        handleCategory(data);
                    }}
                />
            </div>

            <div className="col" data-id="2">
                <label>
                    {trans('labels.name')}
                </label>
                <input
                    type="search"
                    name="name"
                    onKeyUp={handleSearch}
                    onChange={handleSearch}
                    value={props.filter.name}
                />
            </div>

            <div className="col" data-id="3">
                <label>
                    {trans('labels.partNumber')}
                </label>
                <input
                    type="search"
                    name="number"
                    onKeyUp={handleSearch}
                    onChange={handleSearch}
                    value={props.filter.number}
                />
            </div>

        </form>
    )
}

export default Filter;