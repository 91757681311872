import React, { useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import infoIcon from '../../assets/img/icons/info-black-2.png';
// import infoIcon from '../../assets/img/icons/info-blue.png';
import HelpModal from './HelpModal';

function Help(props) {

    const [state, setState] = useState({
        open: false,
        x: -1000,
        y: -1000,
    });

    const ref = useRef(null);

    const handleMouseEnter = e => {

        if (state.open) {
            return;
        }

        let x = e.pageX;
        let y = e.pageY;

        setState(prev => ({
            ...prev,
            x: x,
            y: y,
        }));
    }

    const handleClick = (e) => {
        // let x = e.clientX;
        // let y = e.clientY;

        let rect = ref.current.getBoundingClientRect();

        // console.log(rect)

        // let x = e.pageX;
        // let y = e.pageY;

        let x = rect.left + window.scrollX;
        let y = rect.top + window.scrollY;

        // console.log(e.pageX, e.pageY)
        // console.log(e.clientX, e.clientY)
        // console.log(e.screenX, e.screenY)

        // console.log(x, y);

        setState(prev => ({
            ...prev,
            open: !prev.open,
            x: x,
            y: y,
        }));
    }

    const handleClickAway = () => {
        setState(prev => ({
            ...prev,
            open: false
        }));
    }

    return (
        <div className="help-info-wrapper" style={props.style || {}}>
            <ClickAwayListener
                onClickAway={handleClickAway}
            >
                {/* <span>
                    помощ:
                </span> */}
                <img
                    ref={ref}
                    className="icon"
                    src={infoIcon}
                    alt=""
                    title="Натиснете за информация"
                    onClick={handleClick}
                    onMouseEnter={handleMouseEnter}
                />

                <HelpModal
                    isOpen={state.open && state.x > 0 && state.y > 0}
                    x={state.x}
                    y={state.y}
                    position={props.position}
                    style={props.modalStyle}
                >
                    {props.children}
                </HelpModal>
            </ClickAwayListener>
        </div>
    )
}

export default Help;