import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function FilterTabs(props) {

    const [tabs, setTabs] = useState({
        ripple: true
    })

    return (
        <Tabs
            value={props.tab}
            onChange={(e, id) => props.handleTab(id)}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
        >
            {props.tabs.map(t =>
                <Tab key={t.id} value={t.id} label={t.name} disableRipple={tabs.ripple} />
            )}
        </Tabs>
    )
}

export default FilterTabs;