import React, { useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory, useLocation } from 'react-router-dom';
import { trans } from '../../_providers/Translation'


function PartsPages(props) {

    const history = useHistory();
    const location = useLocation();

    const path = location.pathname;

    const [tabs, setTabs] = useState({
        selected: null
    });

    useEffect(() => {
        setTabs(prev => ({
            ...prev,
            selected: segment(1)
        }))
    }, [location]);

    const segment = index => {
        let path = location.pathname;
        let array = path.replace('/', '').split('/');
        let segment = array[index] || '';

        return segment;
    }

    const handleTabs = (e, val) => {

        setTabs(prev => ({
            ...prev,
            selected: val
        }));

        history.push('/parts/' + val);
    }

    return (
        <Tabs
            value={tabs.selected}
            onChange={handleTabs}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs"
        >
            <Tab value="storage" label={trans('tabs.parts')} disableRipple={true} />
            <Tab value="most-used" label={trans('tabs.оftenUsed')} disableRipple={true} />
            <Tab value="list" label={trans('tabs.list')} disableRipple={true} />
            <Tab value="suppliers" label={trans('tabs.suppliers')} disableRipple={true} />
            <Tab value="stats" label={trans('tabs.stats')} disableRipple={true} />
        </Tabs>
    )
}

export default PartsPages;