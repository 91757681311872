import React from 'react';
import Options from './Options';
import loader from '../../../../assets/img/loader.svg';
import { trans } from '../../../../_providers/Translation'
import NoDataFound from '../../../partials/NoDataFound';

function Table(props) {

    return (

        props.loading
            ?
            <img src={loader} alt="loading" />
            :
            props.licenses.length === 0
                ?
                <NoDataFound />
                :
                <>
                    <div className="table-scroller">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '50px' }}>ID</th>
                                    <th>{trans('tables.name')}</th>
                                    <th>{trans('tables.branch')}</th>
                                    <th style={{ textAlign: 'center' }}>{trans('tables.maxEmployees')}</th>
                                    <th className="options">{trans('tables.options')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.licenses.map(license =>
                                    <tr key={'license-' + license.id}>
                                        <td style={{ width: '50px' }}>{license.id}</td>
                                        <td>{license.translation ? license.translation.name : ''}</td>
                                        <td>{license.branch && license.branch.translation ? license.branch.translation.name : ''}</td>

                                        <td style={{ textAlign: 'center' }}>{license.max_employees}</td>

                                        <td>
                                            <Options
                                                id={license.id}
                                                handleEdit={props.handleEdit}
                                                handleDelete={props.handleDelete}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
    )
}

export default Table;