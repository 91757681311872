import React, { useState, useImperativeHandle, forwardRef, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Api from '../../../../../_helpers/Api'
import L, { LatLng, marker } from "leaflet";
import 'leaflet-routing-machine'
import '../../../../../assets/L.Routing.OpenRouteService'

//images
import docIcon from '../../../../../assets/img/icons/document.png'
import closeIcon from '../../../../../assets/img/icons/close.png'
import markerImg from '../../../../../assets/img/leeflet/marker-icon.png';

import '../../../../../assets/css/modals/employees.css'
import '../../../../../assets/css/leeflet.css';
import '../../../../../assets/css/leaflet-routing-machine.css';
import { Maps } from '../../../../../_config/maps';
import Skeleton from 'react-loading-skeleton';
import Error from '../../../../modals/messages/Error';
import { trans } from '../../../../../_providers/Translation'

let timeout;

let cc = {};

const markerIcon = L.icon({
    iconUrl: markerImg,
    // shadowUrl: 'leaf-shadow.png',
    iconSize: [25, 41], // size of the icon
});

const router = new L.Routing.openrouteservice(Maps.openrouteservice);

// const control = L.Routing.control({
//     router: router,
//     waypoints: [
//         // L.latLng(43.409022, 24.6180123),
//         // L.latLng(43.29449, 26.6729),
//         L.latLng(43.0820584, 25.6321312),
//         L.latLng(43.0820584, 25.6321312)
//     ],
//     autoRoute: false,
//     // geocoder: L.Control.Geocoder.nominatim(),
//     routeWhileDragging: false,
//     // reverseWaypoints: true,
//     showAlternatives: false,
//     fitSelectedRoutes: true,
//     altLineOptions: {
//         styles: [
//             { color: 'black', opacity: 0.15, weight: 9 },
//             { color: 'white', opacity: 0.8, weight: 6 },
//             { color: 'blue', opacity: 0.5, weight: 2 }
//         ]
//     }
// });

function Employees(props, ref) {

    const history = useHistory()

    const applianceModalRef = useRef(null)
    const workDayModalRef = useRef(null)

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        loading: false,
        data: [],
        partners: [],
        dataLoaded: false,
        selected: null,
        recommendation: null,
        onSave: null
    });

    const initMarkers = {
        reservation: null,
        employees: []
    };

    const [map, setMap] = useState(null);
    const [layerGroup, setLayerGroup] = useState(null);
    const [markers, setMarkers] = useState(initMarkers);

    useImperativeHandle(ref, () => ({
        open: () => {
            handleOpen()
        },
        close: () => {
            handleClose()
        },
        onSave: fn => {
            setState(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }))

    useEffect(() => {

        let m = L.map("map", {
            center: [42.70060440808085, 23.318481445312504],
            zoom: 5,
            layers: [
                L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                })
            ]
        });

        setLayerGroup(L.layerGroup().addTo(m));

        setMap(m);

    }, []);

    // при промяна на параметрите махаме маркерите на техниците, за да се създадат наново
    useEffect(() => {
        setMarkers(prev => ({
            ...prev,
            employees: initMarkers.employees
        }));
    }, [props.params]);

    // местополжение на техниците
    useEffect(() => {
        if (map) {

            let _markers = [];

            if (state.data.length) {
                state.data.map(e => {
                    if (e.lat && e.lon) {
                        let marker = L.marker([e.lat, e.lon], {
                            icon: markerIcon,
                            employee_id: e.id
                        });

                        _markers.push(marker);

                        let name = `<p>${e.name} ${e.lastname}</p>`;

                        if (e.previous_reservation) {
                            name += '<p>';
                            name += trans('random.expectsReservation');
                            name += ` ${e.previous_reservation.reference_code}`;
                            name += ` ${e.previous_reservation.full_address}`
                            name += '</p>'
                        }

                        marker.bindPopup(name, {
                            autoClose: false,
                            closeOnClick: false
                        });
                    }

                });

                // let marker = L.marker([43.08205840, 25.63213120], {
                //     icon: markerIcon
                // });

                // _markers.push(marker);

                setMarkers(prev => ({
                    ...prev,
                    employees: _markers
                }));

            }
        }
    }, [map, state.data]);

    // местополежение на резервацията
    useEffect(() => {
        if (map) {

            if (props.params.lat && props.params.lon) {

                let marker = L.circle([props.params.lat, props.params.lon], {
                    color: 'red',
                    fillColor: '#f03',
                    fillOpacity: 0.5,
                    radius: 50
                });

                marker.bindPopup(trans('random.reservationLocation'), {
                    autoClose: false,
                    closeOnClick: false
                }).openPopup()

                setMarkers(prev => ({
                    ...prev,
                    reservation: marker
                }));

            }
        }

    }, [map, props.params.lat, props.params.lon]);

    // сетване на маркерите и пътя
    useEffect(() => {
        // if (!state.modal) {
        //     return;
        // }

        if (map && layerGroup) {
            layerGroup.clearLayers();

            let allMarkers = getAllMarkers();

            if (allMarkers.length) {
                allMarkers.forEach(m => {
                    m.addTo(layerGroup);
                });
            }

            if (markers.reservation) {

                let reservationCoords = markers.reservation.getLatLng();

                markers.employees.map(m => {

                    let employeeCoords = m.getLatLng();

                    getRoute(employeeCoords, reservationCoords, (route) => {

                        if (!route) {
                            return;
                        }

                        let line = L.Routing.line(route).addTo(layerGroup);

                        let distance = route.summary.totalDistance / 1000;
                        let mins = route.summary.totalTime / 60;
                    })

                    // router.route(waypoints, (err, routes) => {

                    //     if (err) {
                    //         console.log(err);
                    //     } else {
                    //         let route = routes[0];

                    //         console.log(route);

                    //         let line = L.Routing.line(route).addTo(layerGroup);

                    //         let distance = route.summary.totalDistance / 1000;
                    //         let mins = route.summary.totalTime / 60;
                    //     }
                    // });
                })

            }

        }
    }, [markers]);

    // при показване на попъпа
    useEffect(() => {
        if (state.overlay && state.modal) {
            fitToMarkers();
        }
    }, [state.overlay, state.modal, markers]);

    useEffect(() => {
        handleSelect(props.selected, props.type, selected => {
            saveSelected(selected);
        });
    }, [props.selected, state.data, state.overlay, state.modal]);

    // техници
    useEffect(() => {
        loadData();
    }, [props.params]);

    // партньори
    useEffect(() => {
        if (props.canReassign) {
            loadPartners();
        }
    }, [props.params.visit_date, props.canReassign]);

    useEffect(() => {
        let employees = state.data;
        let partners = state.partners;

        let recommendation;

        for (let i = 0; i < employees.length; i++) {
            let e = employees[i];

            if (e.reservations_limit_reached === 0) {
                recommendation = e;
                break;
            }
        }

        if (!recommendation) {
            for (let i = 0; i < partners.length; i++) {
                let e = partners[i];

                if (e.reservations_limit_reached === 0) {
                    recommendation = e;
                    break;
                }
            }
        }

        setState(prev => ({
            ...prev,
            recommendation: recommendation
        }));

    }, [state.data, state.partners]);

    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true,
            dataLoaded: false,
        }));

        let url = 'reservations/employees';

        Api.get(url, {
            params: props.params
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
            }));
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                dataLoaded: true
            }));
        });
    }

    const loadPartners = () => {

        let url = 'reservations/partners';

        Api.get(url, {
            params: props.params
        }).then(res => {
            setState(prev => ({
                ...prev,
                partners: res.data,
            }));
        });
    }

    const handleOpen = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)

    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(prev => ({
            ...prev,
            modal: false,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 50)
    }

    const fitToMarkers = () => {
        if (map) {
            map.invalidateSize();

            let allMarkers = getAllMarkers();

            if (allMarkers.length) {
                let group = new L.featureGroup(allMarkers);

                clearTimeout(timeout);

                console.log('fit')

                timeout = setTimeout(() => {
                    map.fitBounds(group.getBounds(), {
                        padding: [100, 100]
                    });
                }, 50);
            }
        }
    }

    const getAllMarkers = () => {

        let array = [];

        if (markers.reservation) {
            array.push(markers.reservation);
        }

        markers.employees.map(m => {
            array.push(m);
        });

        return array;
    }

    const handleSelect = (id, type, callback = null) => {

        let stateKey = type === 'App\\Employee' ? 'data' : 'partners'
        let selected = state[stateKey].filter(e => Number(e.id) === Number(id))[0];

        if (!selected) {
            return;
        }

        if (type === 'App\\Employee') {
            // фокус от маркера на служителя до маркера на резервацията
            if (markers.reservation) {

                let marker1 = markers.reservation;
                let marker2 = getEmployeeMarker(id);

                markers.employees.forEach(m => {
                    m.closePopup();
                });

                if (marker1) {
                    marker1.openPopup();

                    if (marker2) {
                        marker2.openPopup();

                        let allMarkers = [marker1, marker2];

                        let group = new L.featureGroup(allMarkers);

                        map.fitBounds(group.getBounds(), {
                            padding: [100, 100]
                        });
                    }
                }

            }
        } else {
            if (markers.reservation) {
                let marker1 = markers.reservation;

                markers.employees.forEach(m => {
                    m.closePopup();
                });

                if (marker1) {
                    marker1.openPopup();

                    map.fitBounds(new L.featureGroup([marker1]).getBounds(), {
                        padding: [100, 100]
                    });
                }
            }
        }

        setState(prev => ({
            ...prev,
            selected: selected && selected.id ? selected : null
        }));

        if (typeof callback === 'function') {
            setTimeout(() => {
                callback(selected);
            });
        }
    }

    const handleSave = e => {
        e.preventDefault();

        if (state.selected) {
            if (state.selected.type === 'App\\Employee') {
                if (!state.selected.can_repair_appliance) {
                    handleClose();

                    let modal = applianceModalRef.current;

                    modal.open();
                    modal.onClose(() => {
                        handleOpen();
                    });

                    return;
                } else if (!state.selected.in_work_day) {
                    handleClose();

                    let modal = workDayModalRef.current;

                    modal.open();
                    modal.onClose(() => {
                        handleOpen();
                    });

                    return;
                }
            }
        }

        saveSelected(state.selected);

        handleClose();
    }

    const saveSelected = selected => {
        if (typeof props.onSave === 'function') {
            props.onSave(selected);
        } else if (typeof state.onSave === 'function') {
            state.onSave(selected);
        }
    }

    const getEmployeeMarker = id => {
        return markers.employees.filter(marker => {
            return marker.options.employee_id === id;
        })[0];
    }

    const getRoute = (fromLatLon, toLatLon, callback) => {

        let key = String(fromLatLon.lat) + String(fromLatLon.lng) + String(toLatLon.lat) + String(toLatLon.lng);

        if (cc[key]) {
            return callback(cc[key]);
        }

        let waypoints = [];

        waypoints.push({ latLng: fromLatLon });
        waypoints.push({ latLng: toLatLon });

        router.route(waypoints, (err, routes) => {
            if (err) {
                console.log(err);
            } else {
                cc[key] = routes[0];

                return callback(routes[0]);
            }
        });

        return callback(null);
    }

    const getRecommendationName = () => {
        if (state.recommendation.type === 'App\\Partner') {
            return state.recommendation.name;
        }

        return `${state.recommendation.name} ${state.recommendation.lastname}`;
    }

    return (
        <>
            <Error
                ref={applianceModalRef}
                mainMessage={trans('headings.success10')}
                secondaryMessage={trans('subheadings.chooseAnother')}
            />

            <Error
                ref={workDayModalRef}
                mainMessage={trans('messages.selectedEmployeeOnADayOff')}
                secondaryMessage={trans('subheadings.chooseAnother')}
            />

            <div className={`${state.overlay ? 'visible' : ''} overlay`}>

                <div className={`${state.modal ? 'show' : ''} popup-primary`}>

                    <h2 className="head">
                        {trans('headings.chooseContractor')}
                        <img src={closeIcon} alt="close" className="close-icon" onClick={handleClose} />
                    </h2>

                    {!props.hasPerformer && state.recommendation &&
                        <div className="alert info">
                            {trans('random.recomendedChoose')} {getRecommendationName()} {trans('random.recomendedChoose2')}
                        </div>
                    }

                    <div id="map-wrapper">
                        <div className="left">
                            {state.loading
                                ?
                                <Skeleton
                                    style={{
                                        minHeight: '400px',
                                    }}
                                />
                                :
                                <ul>
                                    {state.data.map(e =>
                                        <li
                                            key={'e-' + e.id}
                                            onClick={() => handleSelect(e.id, e.type)}
                                            className={state.selected && (state.selected.id === e.id && state.selected.type === e.type) ? 'active' : ''}
                                        >
                                            <span>
                                                {e.name} {e.lastname}
                                            </span>
                                            <div className="extra">
                                                <>
                                                    <p>
                                                        <span>{trans('random.canRepair')}</span>
                                                        <span>{e.can_repair_appliance ? trans('labels.yes') : trans('labels.no')}</span>
                                                    </p>

                                                    <p>
                                                        <span>{trans('random.distanceInKM')}:</span>
                                                        <span>
                                                            {e.distance > -1
                                                                ?
                                                                e.distance.toFixed(2)
                                                                :
                                                                '-'
                                                            }
                                                        </span>
                                                    </p>

                                                    <p>
                                                        <span>{trans('random.freeHour')}:</span>
                                                        <span>{!e.reservations_limit_reached ? trans('labels.yes') : trans('labels.no')}</span>
                                                    </p>

                                                    <p>
                                                        <span>{trans('random.inWorkingTime')}:</span>
                                                        <span>{e.in_work_time ? trans('labels.yes') : trans('labels.no')}</span>
                                                    </p>

                                                    <p>
                                                        <span>{trans('random.canBeFinish')}:</span>
                                                        <span>{e.can_complete_in_work_time ? trans('labels.yes') : trans('labels.no')}</span>
                                                    </p>

                                                    {e.has_overlap_reservations
                                                        ?
                                                        <div>
                                                            <p>
                                                                {trans('random.resConflict')}:
                                                            </p>
                                                            <ul className="overlaps">
                                                                {e.overlap_reservations.map((r, i) =>

                                                                    <li key={'o-' + r.id}>
                                                                        <a href={'/reservations/edit/' + r.id} target="_blank">
                                                                            <span>{i + 1}.</span>
                                                                            <span>{r.reference_code}</span>
                                                                            <span>{r.full_address}</span>
                                                                        </a>
                                                                    </li>

                                                                )}
                                                            </ul>
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                </>

                                            </div>
                                        </li>
                                    )}
                                    {props.canReassign && state.partners.length
                                        ?
                                        <>
                                            <h5 className="title">{trans('headings.relationships')}</h5>
                                            {state.partners.map(e =>
                                                <li
                                                    key={'p-' + e.id}
                                                    onClick={() => handleSelect(e.id, e.type)}
                                                    className={state.selected && (state.selected.id === e.id && state.selected.type === e.type) ? 'active' : ''}
                                                >
                                                    <span>
                                                        {e.name}
                                                    </span>
                                                    <div className="extra">
                                                        <p>
                                                            <span>{trans('random.reachedLimit')}:</span>
                                                            <span>{e.reservations_limit_reached ? trans('labels.yes') : trans('labels.no')}</span>
                                                        </p>
                                                        <p>
                                                            <span>{trans('random.leftForDay')}:</span>
                                                            <span>{!e.max_reservations ? '∞' : e.balance}</span>
                                                        </p>
                                                    </div>
                                                </li>
                                            )}
                                        </>
                                        :
                                        ''
                                    }
                                </ul>
                            }
                        </div>

                        <div className="right">
                            <div id="map" className="map"></div>
                        </div>

                    </div>

                    <div className="footer align-center">
                        <button onClick={handleClose}>
                            {trans('buttons.cancel')}
                        </button>
                        <button className="button-primary" onClick={handleSave}>
                            {trans('buttons.save')}
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default React.memo(forwardRef(Employees), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});